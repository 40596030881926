import { ROLE_ID_SUPER_ADMIN } from "../constants/common/RoleConstants";
import LoggedUser from "../models/common/LoggedUser";

const verifyPageAccess = (
  loggedUser: LoggedUser | null,
  routeId: number
): boolean => {
  if (loggedUser?.userRoleId === ROLE_ID_SUPER_ADMIN) return true;
  return (
    loggedUser?.userAllowedPages?.find(
      (allowedPage) => allowedPage.id === routeId
    ) !== undefined
  );
};

export default verifyPageAccess;
