import { createContext } from "react";
import { useContext } from "react";
import usePilihProdukDetailEnabled from "../services/usePilihProdukDetailEnabled";

type _Value = {
  state: {
    isEditable: boolean;
  };
  actions: {
    setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

const _PilihProdukContext = createContext<_Value>({
  state: {
    isEditable: false,
  },
  actions: {
    setIsEditable: () => {},
  },
});

/**
 * A context looker made for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * Call only under [PilihProdukDetailEnabledProvider] component.
 *
 * @returns useContext
 *
 */
function usePilihProdukDetail(): _Value {
  return useContext(_PilihProdukContext);
}

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{
  initialValue: boolean;
}>;

/**
 * A logic provider made for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * Used to provide [usePilihProdukDetailEnabled()]'s hook variables and
 * functions to this component's children. Inside the children component, use
 * [usePilihProdukDetail()] hook to refer here.
 *
 * @param children - this component accept a single JSX.Element child
 * component.
 *
 * @returns JSX.Element
 *
 */
const PilihProdukDetailEnabledProvider = ({
  children,
  initialValue,
}: _InternalProps): JSX.Element => {
  const { isEditable, setIsEditable } =
    usePilihProdukDetailEnabled(initialValue);

  const value: _Value = {
    state: { isEditable },
    actions: { setIsEditable },
  };

  return (
    <_PilihProdukContext.Provider value={value}>
      {children}
    </_PilihProdukContext.Provider>
  );
};

export { PilihProdukDetailEnabledProvider, usePilihProdukDetail };
