import { createContext, useContext } from "react";
import useFilter from "../services/useFilter";
import BiayaMurniFilterModel from "../models/BiayaMurniFilterModel";
import ErrorResponse from "../../../models/common/ErrorResponse";

type _Value = {
  states: {
    filterValues: BiayaMurniFilterModel | null;
    isLoading: boolean;
    error: ErrorResponse | null;
  };
  actions: {
    saveFilter: (filterValues: BiayaMurniFilterModel) => Promise<void>;
  };
};

const BiayaMurniFilterContext = createContext<_Value>({
  states: {
    filterValues: null,
    isLoading: false,
    error: null,
  },
  actions: {
    saveFilter: () => Promise.prototype,
  },
});

function useBiayaMurniFilterContext(): _Value {
  return useContext(BiayaMurniFilterContext);
}

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{}>;

const BiayaMurniFilterContextProvider = ({
  children,
}: _InternalProps): JSX.Element => {
  const { filterValues, isLoading, saveFilter, error } = useFilter();

  const value: _Value = {
    states: { filterValues, isLoading, error },
    actions: { saveFilter },
  };

  return (
    <BiayaMurniFilterContext.Provider value={value}>
      {children}
    </BiayaMurniFilterContext.Provider>
  );
};

export { BiayaMurniFilterContextProvider, useBiayaMurniFilterContext };
