/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import AxiosBaseClient from "../../config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import { PaginatedListDistributorParam } from "./DistributorParamType";
import DistributorDataModel from "../../../models/Distributor/DistributorDataModel";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const DistributorProvider = {
  getPaginatedListDistributor: async (
    params: PaginatedListDistributorParam
  ) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/distributor",
        {
          params: params,
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const distributorData: DistributorDataModel[] =
            response.data.data.records.map((json: any) => {
              return {
                id: json["id"],
                distributorName: json["distributor_name"],
              };
            });
          return Promise.resolve(distributorData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getListDistributor: async () => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/distributor/all"
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const distributorData: DistributorDataModel[] =
            response.data.data.map((json: any) => {
              return {
                id: json["id"],
                distributorName: json["distributor_name"],
              };
            });
          return Promise.resolve(distributorData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
};

export default DistributorProvider;
