import { Box, useMediaQuery, useTheme, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import StatusChip from "../../../../../components/StatusChip/StatusChip";
import DetailApprovalKlaimSalesContext from "../../contexts/DetailApprovalKlaimSalesContext";
import { getStatusColor } from "../../../utils/getStatusColor";

const DetailApprovalKlaimSalesTitle: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { detailKlaimSalesData: record } = useContext(
    DetailApprovalKlaimSalesContext
  );

  return (
    <Box flexDirection={"column"}>
      <Box
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        gap={10}
      >
        <PageTitle label={record!.claimUserName} />
        {isDesktopView && (
          <StatusChip
            status={getStatusColor(record!.status)}
            text={record!.status.toUpperCase()}
          />
        )}
      </Box>

      <Text
        color={theme.colors.exodus.darkGrey}
        fontWeight={"semibold"}
        fontSize={isDesktopView ? "24px" : "16px"}
        textAlign={"left"}
      >
        CLAIM-{record!.claimSalesListId}
      </Text>
      {!isDesktopView && (
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <StatusChip
            status={getStatusColor(record!.status)}
            text={record!.status.toUpperCase()}
          />
        </Box>
      )}
    </Box>
  );
};

export default DetailApprovalKlaimSalesTitle;
