import { Box, Text, Button, useTheme, Flex } from "@chakra-ui/react";
import { MdOutlineWarning } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const darkGrey = theme.colors.exodus.darkGrey;

  return (
    <Box textAlign={"center"} paddingY={2} width={"100%"}>
      <Flex direction={"column"} alignItems={"center"}>
        <MdOutlineWarning style={{ fontSize: "64px", color: darkGrey }} />
        <Text
          fontSize={"20px"}
          fontWeight={"semibold"}
          color={darkGrey}
          mt={5}
          mb={4}
        >
          Halaman yang anda tuju tidak ditemukan
        </Text>
        <Button
          colorScheme="buttonPrimary"
          variant={"solid"}
          onClick={() => navigate("/")}
        >
          Kembali ke Dashboard
        </Button>
      </Flex>
    </Box>
  );
};

export default PageNotFound;
