class GetListSalesDistributorModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: GetListSalesDistributorData;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = new GetListSalesDistributorData(json["data"]);
  }
}

class GetListSalesDistributorData {
  metadata!: GetListSalesDistributorMetadata;
  records!: GetListSalesDistributorRecords[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    var temp: GetListSalesDistributorRecords[] = [];

    if (json["records"] !== null && json["records"]?.length !== 0) {
      for (let i = 0; i < json?.records?.length; i++) {
        temp.push(new GetListSalesDistributorRecords(json.records[i]));
      }
    }

    this.metadata = new GetListSalesDistributorMetadata(json["metadata"]);
    this.records = temp;
  }
}

class GetListSalesDistributorMetadata {
  totalData!: number;
  totalDataPerPage!: number;
  currentPage!: number;
  previousPage!: number;
  totalPage!: number;
  nextPageUrl!: string;
  previousPageUrl!: string;
  firstPageUrl!: string;
  lastPageUrl!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.totalData = json["total_data"];
    this.totalDataPerPage = json["total_data_per_page"];
    this.currentPage = json["current_page"];
    this.previousPage = json["previous_page"];
    this.totalPage = json["total_page"];
    this.nextPageUrl = json["next_page_url"];
    this.previousPageUrl = json["previous_page_url"];
    this.firstPageUrl = json["first_page_url"];
    this.lastPageUrl = json["last_page_url"];
  }
}

class GetListSalesDistributorRecords {
  salesHeaderId!: number;
  outletBridgingId!: number;
  nomorInvoice!: string;
  salesPeriode!: number;
  distributorId!: number;
  distributorName!: string;
  outletIdDistributor!: string;
  outletId!: number;
  outletName!: string;
  outletCode!: string;
  outletSectorType!: string;
  project!: string;
  total!: number;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.salesHeaderId = json["sales_header_id"];
    this.outletBridgingId = json["outlet_bridging_id"];
    this.nomorInvoice = json["nomor_invoice"];
    this.salesPeriode = json["sales_period"];
    this.distributorId = json["distributor_id"];
    this.distributorName = json["distributor_name"];
    this.outletIdDistributor = json["outlet_id_distributor"];
    this.outletId = json["outlet_id"];
    this.outletName = json["outlet_name"];
    this.outletCode = json["outlet_code"];
    this.outletSectorType = json["outlet_sector_type"];
    this.project = json["project"];
    this.total = json["total"];
  }
}

export {
  GetListSalesDistributorModelPack,
  GetListSalesDistributorData,
  GetListSalesDistributorMetadata,
  GetListSalesDistributorRecords,
};
