import { useEffect, useState } from "react";
import DashboardDataProvider from "../dataProvider/DashboardDataProvider";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import {
  TargetSalesMonthlyModelData,
  TargetSalesMonthlyModelPack,
} from "../model/TargetSalesMonthly";
import DateExodus from "../../../models/DateExodus";
import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";
import {
  ROLE_ID_ASM,
  ROLE_ID_SUPER_ADMIN,
} from "../../../constants/common/RoleConstants";
import dayjs from "dayjs";
import IsTypeOfErrorResponse from "../../../utils/IsTypeOfErrorResponse";
import ProjectType from "../../../models/common/ProjectType";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const useDashboardTargetSales = (project: ProjectType | null) => {
  const [data, setData] = useState<TargetSalesMonthlyModelData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const getData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await DashboardDataProvider.getTargetSalesList(
        new DateExodus(dayjs().startOf("month").unix()).toJSON(),
        project
      );
      if (response.status === SUCCESS_GET_STATUS) {
        if (response["message"] === SUCCESS_MESSAGE) {
          const responseData: TargetSalesMonthlyModelPack = response;
          setData(responseData.data);
        }
      } else {
        setError(response.data["code"]);
      }
    } catch (error: any) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error.message);
        return;
      }
      setError(UNKNOWN_ERROR_RESPONSE.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      loggedUser?.userRoleId === ROLE_ID_ASM ||
      loggedUser?.userRoleId === ROLE_ID_SUPER_ADMIN
    )
      getData();
  }, [project]);

  return { data, error, loading };
};

export default useDashboardTargetSales;
