import React, { useContext } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Spinner,
  Stack,
  useTheme,
  Text,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import Pagination from "../../../../../../components/Pagination/Pagination";
import { ListApprovalKunjunganModelRecords } from "../../../../models/ApprovalListModel";
import HorizontalAccordionDetail from "../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import StatusChip from "../../../../../../components/StatusChip/StatusChip";
import ExportExcelButton from "../../../../../../components/CustomButtons/ExportExcelButton";
import FilterApprovalRealisasiKunjunganContext from "../../context/FilterApprovalRealisasiKunjunganContext";
import LihatDetailButtonApprovalRealisasi from "../LihatDetailButtonApprovalRealisasi";
import useExportApprovalKunjungan from "../../services/useExportApprovalRealisasi";
import { RealisasiStatus } from "../../models/ApprovalRealisasiStatus";
import MetadataApprovalRealisasiContext from "../../context/MetadataApprovalRealisasiContext";

const MobileApprovalRealisasiList: React.FC = () => {
  const theme = useTheme();
  const { exportData } = useExportApprovalKunjungan();
  const {
    filterValues,
    approvalListData,
    errorList,
    loadingList,
    metadataList,
  } = useContext(FilterApprovalRealisasiKunjunganContext);
  const {
    metadataValues,
    handleFirstPage,
    handleLimitChange,
    handleNextPage,
    handlePrevPage,
  } = useContext(MetadataApprovalRealisasiContext);

  const getStatusColor = (status: string) => {
    if (status === RealisasiStatus.Approved) {
      return "processed";
    } else if (status === RealisasiStatus.Pending) {
      return "pending";
    } else {
      return "pending";
    }
  };

  const generatePeriodeRealisasi = (): string => {
    return `${filterValues.periode?.start_period.getStringDateFormat(
      "D MMMM YYYY"
    )} - ${filterValues.periode?.end_period.getStringDateFormat(
      "D MMMM YYYY"
    )}`;
  };

  if (loadingList) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (errorList) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={errorList.toString()} />
      </Box>
    );
  }

  return approvalListData.length > 0 ? (
    <Stack
      justifyContent={"space-between"}
      alignItems={"stretch"}
      minHeight={"80vh"}
    >
      <Box marginTop="24px" marginBottom="15px">
        <Accordion allowToggle>
          {approvalListData.map((item: ListApprovalKunjunganModelRecords) => (
            <AccordionItem key={item.id}>
              <AccordionButton display="flex" justifyContent="space-between">
                <Box textAlign="left">
                  <Text
                    fontSize={14}
                    fontWeight="bold"
                    color={theme.colors.exodus.primaryBlue}
                  >
                    {item.userName.length < 15
                      ? `${item.userName}`
                      : `${item.userName.slice(0, 15)}...`}
                  </Text>
                  <Text fontSize={14} color={theme.colors.exodus.darkGrey}>
                    {item.nip}
                  </Text>
                </Box>
                <Box gap={1}>
                  <StatusChip
                    status={getStatusColor(item.approvalRealisasiStatus)}
                    text={item.approvalRealisasiStatus.toUpperCase()}
                  />
                  <AccordionIcon />
                </Box>
              </AccordionButton>
              <AccordionPanel marginX="16px">
                {item.project && (
                  <HorizontalAccordionDetail
                    leftText="Project"
                    rightSide={item.project.label}
                    size="small"
                    isRowColored
                  />
                )}
                <HorizontalAccordionDetail
                  leftText="Jabatan"
                  rightSide={`${item.roleLabel}`}
                  size="small"
                />
                <HorizontalAccordionDetail
                  leftText="Periode Realisasi Kunjungan"
                  rightSide={generatePeriodeRealisasi()}
                  size="small"
                  isRowColored
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    paddingTop: "10px",
                  }}
                >
                  <LihatDetailButtonApprovalRealisasi
                    id={item.id}
                    userId={item.userId}
                    isDisabled={
                      item.id === 0 ||
                      item.approvalRealisasiStatus === RealisasiStatus.Pending
                    }
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <Box
          display={"flex"}
          justifyContent={"center"}
          width={"100%"}
          paddingTop={5}
        >
          <Pagination
            handleOnLimitChange={handleLimitChange}
            handleOnBack={handlePrevPage}
            handleOnNext={handleNextPage}
            page={metadataValues?.currentPage}
            limit={metadataValues?.limit}
            totalPage={metadataList?.totalPage}
            totalData={metadataList?.totalData}
            showLimit
            showNavigateFirstPage
            showCurrentPageDetail
            handleOnNavigateFirstPage={handleFirstPage}
          />
        </Box>
      </Box>

      <Stack
        spacing={1}
        sx={{
          position: "sticky",
          pt: 2,
          pb: 2,
          bottom: 0,
          backgroundColor: theme.colors.exodus.background,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ExportExcelButton
          onClick={() => exportData()}
          isDisabled={approvalListData.length === 0}
        />
      </Stack>
    </Stack>
  ) : (
    <Box
      marginTop="24px"
      marginBottom="15px"
      display={"flex"}
      justifyContent={"start"}
    >
      <DataNotFound />
    </Box>
  );
};

export default MobileApprovalRealisasiList;
