import React from "react";
import InputKlaimSalesProvider from "../dataProviders/InputKlaimSalesProvider";
import InputKlaimSalesStoredDataModel from "../models/InputKlaimSalesStoredDataModel";

const usePageState = () => {
  const [activeStepIdx, setActiveStepIdx] = React.useState(-1);
  const [isActionButtonHidden, setIsActionButtonHidden] =
    React.useState<boolean>(false);
  const maxStepIdx: number = 3;

  const handleNext = () => {
    if (activeStepIdx < maxStepIdx) {
      setActiveStepIdx((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStepIdx > 0) {
      setActiveStepIdx((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const setSavedStepIdx = async () => {
    if (activeStepIdx === -1) {
      const data: InputKlaimSalesStoredDataModel | null =
        await InputKlaimSalesProvider.getSavedInputKlaimSalesData();

      if (data && data.step) {
        setActiveStepIdx(data.step);
        await InputKlaimSalesProvider.setSaveStepKlaimSales({
          step: data.step,
        });
      } else {
        setActiveStepIdx(0);
        // await InputKlaimSalesProvider.setSaveStepKlaimSales({ step: 0 });
      }
    } else {
      await InputKlaimSalesProvider.setSaveStepKlaimSales({
        step: activeStepIdx,
      });
    }
  };

  const resetActiveStepIdx = () => {
    setActiveStepIdx(0);
  };

  const handleFailSubmit = () => {
    setActiveStepIdx(1);
  };

  return {
    activeStepIdx,
    maxStepIdx,
    handleNext,
    handleBack,
    setSavedStepIdx,
    resetActiveStepIdx,
    isActionButtonHidden,
    setIsActionButtonHidden,
    handleFailSubmit,
    setActiveStepIdx,
  };
};

export default usePageState;
