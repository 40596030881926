import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import InputRencanaKunjunganContext from "../context/InputRencanaKunjunganContext";
import useUpdateStatusRencanaKunjungan from "../../../services/useUpdateStatusRencanaKunjungan";
import UpdateStatusRencanaKunjunganBody from "../../../models/UpdateStatusRencanaKunjunganModel";

type Props = {
  keterangan?: string;
  openConfirmationDialog: boolean;
  onClose: () => void;
};

const DialogKonfimasiSubmitRencanaKunjungan: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();
  const navigate = useNavigate();

  const { selectedPeriode, key, setKey } = useContext(
    InputRencanaKunjunganContext
  );

  const { updateStatusRencanaKunjungan, loading } =
    useUpdateStatusRencanaKunjungan();

  const handleUpdateStatusRencanaKunjungan = () => {
    let submittedData: UpdateStatusRencanaKunjunganBody;

    if (props.keterangan) {
      submittedData = {
        status: "Request",
        description: props.keterangan.trim(),
      };
    } else {
      submittedData = {
        status: "Request",
      };
    }

    updateStatusRencanaKunjungan(selectedPeriode!.header_id, submittedData, {
      onSuccess: () => {
        toast({
          title: "Berhasil Submit Rencana Kunjungan",
          status: "success",
        });
        navigate("/rencana-kunjungan", { replace: true });
        setKey(key + 1);
        props.onClose();
      },
      onError: (errorMessage: string) => {
        toast({
          title: `Gagal Submit Rencana Kunjungan`,
          description: `${errorMessage}`,
          status: "error",
        });
      },
    });
  };

  return (
    <Modal
      isOpen={props.openConfirmationDialog}
      onClose={props.onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          Submit Draft Rencana Kunjungan
        </ModalHeader>
        <ModalBody>
          Apakah Anda yakin mau Submit Draft Rencana Kunjungan ini ?
        </ModalBody>
        <ModalFooter
          flexDirection={!isDesktopView ? "column" : "row-reverse"}
          gap={!isDesktopView ? 0 : 5}
        >
          <Button
            isLoading={loading}
            width="100%"
            colorScheme="buttonPrimary"
            onClick={() => handleUpdateStatusRencanaKunjungan()}
          >
            Confirm
          </Button>
          <Button
            isDisabled={loading}
            onClick={props.onClose}
            width="100%"
            variant="outline"
            colorScheme="buttonSecondary"
            marginTop={!isDesktopView ? "12px" : 0}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DialogKonfimasiSubmitRencanaKunjungan;
