import { useState } from "react";
import { FakturByCodeModelData } from "../models/FakturByCodeModel";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import InputKlaimSalesProvider from "../dataProviders/InputKlaimSalesProvider";
import { FakturByCodeParam } from "../dataProviders/InputKlaimSalesParamType";

type CallResultHandler = {
  onSuccess: () => void;
  onError: (error: ErrorResponse) => void;
};

const useGetFakturByCode = () => {
  const [fakturData, setData] = useState<FakturByCodeModelData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorResponse, setError] = useState<ErrorResponse | null>(null);

  const getFakturByCode = async (
    data: FakturByCodeParam,
    handler: CallResultHandler
  ) => {
    try {
      setError(null);
      setLoading(true);

      const response: FakturByCodeModelData | undefined =
        await InputKlaimSalesProvider.getFakturByCode({
          outlet_id: data.outlet_id,
          invoice: data.invoice,
        });

      setData(response!);
      setLoading(false);
      handler.onSuccess();
    } catch (error) {
      setError(error as ErrorResponse);
      setLoading(false);
      handler.onError(error as ErrorResponse);
    }
  };

  return { getFakturByCode, fakturData, loading, errorResponse };
};

export default useGetFakturByCode;
