import { useTheme } from "@chakra-ui/react";
import saveAs from "file-saver";
import ExcelJS from "exceljs";
import DateExodus from "../../../../../models/DateExodus";
import SalesTargetFilterAndPaginationModel from "../../models/SalesTargetFilterAndPaginationModel";
import SalesTargetProvider from "../../dataProviders/SalesTargetProvider";
import {
  AllListTargetModelPack,
  ListSalesTargetRecords,
} from "../../models/SalesTargetModelPack";
import IsTypeOfErrorResponse from "../../../../../utils/IsTypeOfErrorResponse";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../../constants/common/ErrorConstants";

type AppliedFilter = {
  key: string;
  value: any;
};

const useExportSalesTarget = (
  filterValues: SalesTargetFilterAndPaginationModel | null
) => {
  const theme = useTheme();

  const fetchAllSalesTarget = async () => {
    try {
      if (
        !filterValues ||
        !filterValues.periodeKuartalEnd ||
        !filterValues.periodeKuartalStart
      ) {
        return Promise.reject(
          "Gagal Mengunduh Excel. Silahkan pilih periode yang sesuai"
        );
      }

      const response: AllListTargetModelPack | undefined =
        await SalesTargetProvider.getListAll({
          startDate: new DateExodus(filterValues.periodeKuartalStart),
          endDate: new DateExodus(filterValues.periodeKuartalEnd),
          userId: filterValues.bawahan?.id,
          project: filterValues.project,
        });

      return Promise.resolve(response!.data);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        return Promise.reject(error.message);
      } else {
        return Promise.reject(UNKNOWN_ERROR_RESPONSE.message);
      }
    }
  };

  const generateMonthsName = () => {
    if (filterValues && filterValues.idKuartal) {
      if (filterValues.idKuartal === 1) {
        return ["Januari", "Februari", "Maret"];
      } else if (filterValues.idKuartal === 2) {
        return ["April", "Mei", "Juni"];
      } else if (filterValues.idKuartal === 3) {
        return ["Juli", "Agustus", "September"];
      } else if (filterValues.idKuartal === 4) {
        return ["Oktober", "November", "Desember"];
      }
    }

    return [];
  };

  const exportData = async (onErrorToast: (error: string) => void) => {
    try {
      const salesTargetData: ListSalesTargetRecords[] =
        await fetchAllSalesTarget();
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet();

      worksheet.columns = [
        { key: "A", width: 25 },
        { key: "B", width: 25 },
        { key: "C", width: 25 },
        { key: "D", width: 28 },
        { key: "E", width: 30 },
        { key: "F", width: 28 },
        { key: "G", width: 28 },
        { key: "H", width: 28 },
        { key: "I", width: 28 },
        { key: "J", width: 25 },
        { key: "K", width: 25 },
      ];

      const rowHeaderPosition = 1;
      const rowHeader = worksheet.getRow(rowHeaderPosition);
      const year = new DateExodus(filterValues!.periodeTahun!)
        .getDateObject()
        .getFullYear();

      rowHeader.alignment = {
        horizontal: "center",
      };

      rowHeader.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: theme.colors.exodus.primaryBlue.slice(1),
        },
      };

      rowHeader.getCell(1).value = "Project";
      rowHeader.getCell(2).value = "Nama GT";
      rowHeader.getCell(3).value = "Kode GT";
      rowHeader.getCell(4).value = "Nama FF";
      rowHeader.getCell(5).value = "NIP";
      rowHeader.getCell(6).value = "Tanggal Input";
      rowHeader.getCell(7).value = `${generateMonthsName()[0]} ${year}`;
      rowHeader.getCell(8).value = `${generateMonthsName()[1]} ${year}`;
      rowHeader.getCell(9).value = `${generateMonthsName()[2]} ${year}`;
      rowHeader.getCell(10).value = "Diinput Oleh";
      rowHeader.getCell(11).value = "Diapprove Oleh";

      for (let i = 1; i <= 11; i++) {
        rowHeader.getCell(i).font = {
          size: 14,
          bold: true,
          color: { argb: theme.colors.exodus.white.slice(1) }, //Remove the '#' from the Hex value
        };
      }

      for (let i = 0; i < salesTargetData.length; i++) {
        const row = worksheet.getRow(rowHeaderPosition + 1 + i);
        row.alignment = {
          wrapText: true,
          horizontal: "center",
        };

        row.getCell(1).value = salesTargetData[i].project?.label ?? "-";
        row.getCell(2).value =
          salesTargetData[i].userZoneName !== null &&
          salesTargetData[i].userZoneName !== undefined
            ? salesTargetData[i].userZoneName
            : "";
        row.getCell(3).value =
          salesTargetData[i].userZoneCode !== null &&
          salesTargetData[i].userZoneCode !== undefined
            ? salesTargetData[i].userZoneCode
            : "";
        row.getCell(4).value =
          salesTargetData[i].fieldForceIdentity !== null
            ? salesTargetData[i].fieldForceIdentity?.fieldForceName
            : "VACANT";
        row.getCell(5).value =
          salesTargetData[i].fieldForceIdentity !== null
            ? salesTargetData[i].fieldForceIdentity?.fieldForceNip
            : "";
        row.getCell(6).value =
          salesTargetData[i].targetSalesList.length !== 0
            ? salesTargetData[
                i
              ].targetSalesList[0].createdAt.getStringDateFormat("D MMM YYYY")
            : "";
        row.getCell(7).value =
          salesTargetData[i].targetSalesList.length !== 0
            ? salesTargetData[i].targetSalesList[0]?.valueTarget
            : "";
        row.getCell(8).value =
          salesTargetData[i].targetSalesList.length !== 0
            ? salesTargetData[i].targetSalesList[1]?.valueTarget
            : "";
        row.getCell(9).value =
          salesTargetData[i].targetSalesList.length !== 0
            ? salesTargetData[i].targetSalesList[2]?.valueTarget
            : "";
        row.getCell(10).value =
          salesTargetData[i].targetSalesList.length !== 0
            ? salesTargetData[i].targetSalesList[0]?.createdBy?.createdByName
            : "";
        row.getCell(11).value =
          salesTargetData[i].targetSalesList.length !== 0
            ? salesTargetData[i].targetSalesList[0]?.approvedBy?.approvedByNama
            : "";
      }

      if (
        filterValues &&
        ((filterValues.jabatan && filterValues.bawahan) || filterValues.project)
      ) {
        const worksheetFilter = workbook.addWorksheet();
        worksheetFilter.name = "Filter";

        const appliedFilter: AppliedFilter[] = [];

        if (filterValues.project) {
          appliedFilter.push({
            key: "Project",
            value: filterValues.project.label,
          });
        }

        if (filterValues.jabatan && filterValues.bawahan) {
          appliedFilter.push({
            key: "Jabatan",
            value: filterValues.jabatan.label,
          });
          appliedFilter.push({
            key: "Nama",
            value: filterValues.bawahan.name,
          });
        }

        worksheetFilter.columns = [
          { key: "A", width: 14 },
          { key: "B", width: 14 },
        ];

        for (let i = 0; i < appliedFilter.length; i++) {
          const row = worksheetFilter.getRow(i + 1);

          row.getCell(1).value = appliedFilter[i].key;
          row.getCell(2).value = appliedFilter[i].value;
        }
      }

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `Target Sales.xlsx`);
    } catch (error) {
      onErrorToast(JSON.stringify(error) as string);
    }
  };

  return { exportData };
};

export default useExportSalesTarget;
