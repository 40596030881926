import { Box, FormControl, useMediaQuery } from "@chakra-ui/react";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import { useFormContext } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import ProjectType from "../../../../../../models/common/ProjectType";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../redux/hooks";
import FilterApprovalRealisasiKunjunganContext from "../../context/FilterApprovalRealisasiKunjunganContext";
import theme from "../../../../../../layout/theme/theme";

const ApprovalRealisasiProjectFormField = () => {
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { filterValues } = useContext(FilterApprovalRealisasiKunjunganContext);

  const [selectedProject, setSelectedProject] = useState<
    ProjectType | null | undefined
  >(filterValues?.project);

  useEffect(() => {
    setValue("project", selectedProject);
  }, [selectedProject]);

  return (
    <Box sx={{ width: "100%", marginBottom: !isDesktopView ? 0 : "16px" }}>
      <FormControl width="100%" isInvalid={!!errors.status}>
        <FormInputLabel title="Project" />
        <CustomSelect
          {...register("project")}
          options={loggedUser?.projects ?? []}
          isDisabled={loggedUser ? loggedUser.projects.length <= 1 : false}
          isClearable
          getOptionValue={(data: ProjectType) => data.value}
          getOptionLabel={(data: ProjectType) => data.label}
          onChange={(data: ProjectType | null) => {
            setValue("project", data ? data : null);
            setSelectedProject(data);
          }}
          value={selectedProject}
          placeholder="Project"
        />
      </FormControl>
    </Box>
  );
};

export default ApprovalRealisasiProjectFormField;
