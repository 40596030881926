import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ClearAllButton from "../../../../../components/CustomButtons/ClearAllButton";
import ApprovalRealisasiStatusFormField from "./filterForm/ApprovalRealisasiStatusFormField";
import ApprovalRealisasiKunjunganFilterValues from "../models/ApprovalRealisasiKunjunganFilterValues";
import FilterApprovalRealisasiKunjunganContext from "../context/FilterApprovalRealisasiKunjunganContext";
import ApprovalRealisasiRoleBawahanFormField from "./filterForm/ApprovalRealisasiRoleBawahanFormField";
import useSaveApprovalRealisasiFilter from "../services/useSaveApprovalRealisasiFilter";
import MetadataApprovalRealisasiContext from "../context/MetadataApprovalRealisasiContext";
import ApprovalRealisasiProjectFormField from "./filterForm/ApprovalRealisasiProjectFormField";

type Props = {
  open: boolean;
  onClose: () => void;
};

const DialogFilterApprovalRealisasiKunjungan: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const { saveData } = useSaveApprovalRealisasiFilter();
  const { filterValues, setFilterValues } = useContext(
    FilterApprovalRealisasiKunjunganContext
  );
  const { setMetadataValues } = useContext(MetadataApprovalRealisasiContext);

  const form = useForm({
    defaultValues: {
      role: null,
      user: null,
      realisasi_periode: filterValues.periode,
      realisasi_status: null,
      project: null,
    },
  });

  /*==================================== Functions ====================================*/

  const handleSubmitFilter = () => {
    const updatedFilter = new ApprovalRealisasiKunjunganFilterValues({
      bawahan: form.getValues("user"),
      role: form.getValues("role"),
      periode: filterValues.periode,
      status: form.getValues("realisasi_status"),
      project: form.getValues("project"),
    });
    const updateMetadata = {
      currentPage: 1,
      limit: 10,
    };

    setMetadataValues(updateMetadata);
    setFilterValues(updatedFilter);
    saveData(updatedFilter);
    props.onClose();
  };

  const resetForm = () => {
    form.reset();
    handleSubmitFilter();
  };

  const closeFilter = () => {
    form.reset();
    props.onClose();
  };

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={props.open}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          <Flex direction="row" justifyContent="space-between">
            <Text>Filter Data</Text>
            <ClearAllButton onClick={resetForm} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmitFilter)}>
              <ApprovalRealisasiProjectFormField />

              <ApprovalRealisasiRoleBawahanFormField />

              <Flex marginTop={!isDesktopView ? 0 : "16px"}>
                <ApprovalRealisasiStatusFormField />
              </Flex>
              <Flex
                direction={!isDesktopView ? "column" : "row-reverse"}
                gap={!isDesktopView ? 0 : 5}
                marginTop="48px"
                marginBottom="20px"
              >
                <Button type="submit" width="100%" colorScheme="buttonPrimary">
                  Submit
                </Button>
                <Button
                  onClick={closeFilter}
                  width="100%"
                  variant="outline"
                  colorScheme="buttonSecondary"
                  marginTop={!isDesktopView ? "12px" : 0}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DialogFilterApprovalRealisasiKunjungan;
