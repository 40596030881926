import { Stack, useTheme, Text, Box, Button, Icon } from "@chakra-ui/react";
import { IoMdEye } from "react-icons/io";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";

type OutletCardBiayaBoxSmallBreakpointProps = {
  title: string;
  firstText: string;
  secondText: string;
  thirdText: string;
  firstValue: string;
  secondValue: string;
  thirdValue: number | undefined;
  handleOnClick: () => void;
};

const BiayaDasarBoxSmallBreakpoint = ({
  title,
  firstText,
  secondText,
  thirdText,
  firstValue,
  secondValue,
  thirdValue,
  handleOnClick,
}: OutletCardBiayaBoxSmallBreakpointProps) => {
  const theme = useTheme();

  const formattedThirdValue = CurrencyIdrFormatter.convertToRp(thirdValue ?? 0);

  return (
    <Stack
      sx={{
        borderRadius: 12,
        border: `1px solid ${theme.colors.exodus.primaryBlue}`,
      }}
      direction={"column"}
      p={4}
    >
      <Text
        display="flex"
        fontSize={18}
        fontWeight={600}
        color={theme.colors.exodus.primaryBlue}
        textAlign={"left"}
      >
        {title}
      </Text>
      <Stack direction="column" justifyContent={"space-between"} gap={0}>
        <Text
          display="flex"
          fontSize={14}
          fontWeight={400}
          color={theme.colors.exodus.primaryBlue}
          textAlign={"left"}
        >
          {firstText}
        </Text>
        <Text
          display="flex"
          fontSize={14}
          fontWeight={400}
          color={theme.colors.exodus.darkGrey}
          textAlign={"left"}
        >
          {firstValue}
        </Text>
      </Stack>
      <Stack direction="column" justifyContent={"space-between"} gap={0}>
        <Text
          display="flex"
          fontSize={14}
          fontWeight={400}
          color={theme.colors.exodus.primaryBlue}
          textAlign={"left"}
        >
          {secondText}
        </Text>
        <Text
          display="flex"
          fontSize={14}
          fontWeight={400}
          color={theme.colors.exodus.darkGrey}
          textAlign={"left"}
        >
          {secondValue}
        </Text>
      </Stack>
      <Stack direction="column" justifyContent={"space-between"} gap={0}>
        <Text
          display="flex"
          fontSize={14}
          fontWeight={400}
          color={theme.colors.exodus.primaryBlue}
          textAlign={"left"}
        >
          {thirdText}
        </Text>
        <Text
          display="flex"
          fontSize={14}
          fontWeight={400}
          color={theme.colors.exodus.darkGrey}
          textAlign={"left"}
        >
          {thirdValue && thirdValue < 0
            ? `(${formattedThirdValue})`
            : formattedThirdValue}
        </Text>
      </Stack>
      <Box display="flex" flexDirection={"row"} justifyContent="flex-end">
        <Button
          colorScheme="buttonSecondary"
          variant={"ghost"}
          onClick={() => handleOnClick()}
        >
          <Text
            fontSize={16}
            fontWeight={600}
            color={theme.colors.exodus.secondaryBlue}
            display="flex"
            alignItems="center"
            gap={2}
          >
            <Icon as={IoMdEye} boxSize={6} />
            Lihat Detail
          </Text>
        </Button>
      </Box>
    </Stack>
  );
};

export default BiayaDasarBoxSmallBreakpoint;
