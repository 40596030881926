import { useState, useEffect } from "react";
import { COMMON_PAGINATION_LIMIT_CHOICES } from "../../../constants/components/PaginationConstants";
import BiayaMurniProvider from "../dataProviders/BiayaMurniProvider";
import { BiayaMurniFeatureType } from "../models/BiayaMurniFeatureType";
import BiayaMurniSavedListMetadata from "../models/BiayaMurniSavedListMetadata";
import ErrorResponse from "../../../models/common/ErrorResponse";

const useListMetadata = ({
  featureType,
}: {
  featureType: BiayaMurniFeatureType;
}) => {
  const initialValue = new BiayaMurniSavedListMetadata({
    page: 1,
    limit: COMMON_PAGINATION_LIMIT_CHOICES[0].value,
    featureType: featureType,
  });

  const [metadataValues, setMetadataValues] =
    useState<BiayaMurniSavedListMetadata | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    const initializeMetadata = async () => {
      try {
        setLoading(true);
        setError(null);

        const dbMetadata: BiayaMurniSavedListMetadata | null =
          await BiayaMurniProvider.localDbGetListMetadata(featureType);

        if (dbMetadata && dbMetadata.featureType === featureType) {
          setMetadataValues(dbMetadata);
        } else {
          setMetadataValues(initialValue);
        }
      } catch (error) {
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setLoading(false);
      }
    };

    initializeMetadata();
  }, [featureType]);

  const saveMetadata = async (newMetadata: BiayaMurniSavedListMetadata) => {
    try {
      setLoading(true);
      setError(null);
      await BiayaMurniProvider.localDbSaveListMetadata(newMetadata);
      setMetadataValues(newMetadata);
    } catch (error) {
      if (error as ErrorResponse) {
        setError(error as ErrorResponse);
      }
    } finally {
      setLoading(false);
    }
  };

  return { metadataValues, saveMetadata, isLoading, error };
};

export default useListMetadata;
