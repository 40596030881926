import {
  Box,
  HStack,
  Spinner,
  Text,
  VStack,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useSalesOutletSummaryContext } from "../../contexts/SalesOutletSummaryContext";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";

const SalesOutletSummarySection = (): JSX.Element => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const {
    states: { data, error, isLoading },
  } = useSalesOutletSummaryContext();

  if (isLoading) {
    if (isMobile) {
      return (
        <VStack alignItems={"flex-start"} gap={0}>
          <Text
            fontSize={14}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
            textAlign={"start"}
          >
            Total Sales Outlet
          </Text>
          <Spinner ml={4} color={theme.colors.exodus.primaryBlue} />
          <Text
            fontSize={14}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
            textAlign={"start"}
          >
            Total Estimasi Sales Outlet
          </Text>
          <Spinner ml={4} color={theme.colors.exodus.primaryBlue} />
        </VStack>
      );
    } else {
      return (
        <HStack gap={8}>
          <VStack alignItems={"flex-start"}>
            <Text
              fontWeight={600}
              color={theme.colors.exodus.primaryBlue}
              textAlign={"start"}
            >
              Total Sales Outlet
            </Text>
            <Text
              fontWeight={600}
              color={theme.colors.exodus.primaryBlue}
              textAlign={"start"}
            >
              Total Estimasi Sales Outlet
            </Text>
          </VStack>
          <Spinner color={theme.colors.exodus.primaryBlue} />
        </HStack>
      );
    }
  }

  if (error) {
    return (
      <Text
        fontWeight={600}
        color={theme.colors.exodus.red}
        textAlign={"start"}
      >
        {`Summary Error: ` + error.message}
      </Text>
    );
  }

  if (data) {
    if (isMobile) {
      return (
        <VStack alignItems={"flex-start"} gap={0}>
          <Text
            fontSize={14}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
            textAlign={"start"}
          >
            Total Sales Outlet
          </Text>
          <Text fontSize={14} fontWeight={600} textAlign={"start"}>
            {CurrencyIdrFormatter.convertToRp(data.salesValue)}
          </Text>
          <Box m={1} />
          <Text
            fontSize={14}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
            textAlign={"start"}
          >
            Total Estimasi Sales Outlet
          </Text>
          <Text fontSize={14} fontWeight={600} textAlign={"start"}>
            {CurrencyIdrFormatter.convertToRp(data.estimatedSalesValue)}
          </Text>
        </VStack>
      );
    } else {
      return (
        <VStack alignItems={"flex-start"}>
          <HStack>
            <Text
              fontWeight={600}
              color={theme.colors.exodus.primaryBlue}
              sx={{ width: 225 }}
              textAlign={"start"}
            >
              Total Sales Outlet
            </Text>
            <Text
              fontWeight={600}
              color={theme.colors.exodus.primaryBlue}
              textAlign={"start"}
            >
              :
            </Text>
            <Text fontWeight={600}>
              {CurrencyIdrFormatter.convertToRp(data.salesValue)}
            </Text>
          </HStack>
          <HStack>
            <Text
              fontWeight={600}
              color={theme.colors.exodus.primaryBlue}
              sx={{ width: 225 }}
              textAlign={"start"}
            >
              Total Estimasi Sales Outlet
            </Text>
            <Text
              fontWeight={600}
              color={theme.colors.exodus.primaryBlue}
              textAlign={"start"}
            >
              :
            </Text>
            <Text fontWeight={600}>
              {CurrencyIdrFormatter.convertToRp(data.estimatedSalesValue)}
            </Text>
          </HStack>
        </VStack>
      );
    }
  }

  return (
    <Text fontWeight={600} textAlign={"start"}>
      Data total summary sales outlet tidak ditemukan
    </Text>
  );
};

export default SalesOutletSummarySection;
