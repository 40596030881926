export default class Budget {
  bulan: number;
  targetSales: number;
  targetKumulatif: number;
  estimasiSales: number;
  estimasiKumulatif: number;
  realisasiSales: number;
  realisasiKumulatif: number;
  achievementSales: number;
  achievementKumulatif: number;
  biayaPssp: number;
  biayaEntertainment: number;
  biayaDplDpf: number;
  biayaStandarisasi: number;
  biayaDP: number;
  total: number;
  kumulatif: number;

  // TODO:  Get from correct json attribute name
  constructor(json: any) {
    this.bulan = json["month"];
    this.targetSales = json["target_sales_current"];
    this.targetKumulatif = json["cumulative_target_sales_current"];
    this.estimasiSales = json["estimated_sales_current"];
    this.estimasiKumulatif = json["cumulative_estimated_sales_current"];
    this.realisasiSales = json["sales_current"];
    this.realisasiKumulatif = json["cumulative_sales_current"];
    this.achievementSales = json["sales_achieved_current"];
    this.achievementKumulatif = json["cumulative_sales_achieved_current"];
    this.biayaPssp = json["pssp_cost"];
    this.biayaEntertainment = json["entertain_cost"];
    this.biayaDplDpf = json["discount_cost"];
    this.biayaStandarisasi = json["standarisasi_cost"];
    this.biayaDP = json["dp_cost"];
    this.total = json["total_cost_current"];
    this.kumulatif = json["total_cost_cumulative"];
  }
}
