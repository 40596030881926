import { useState, useEffect } from "react";
import BiayaMurniProvider from "../../dataProviders/BiayaMurniProvider";
import { BiayaMurniGetCostTotalsParam } from "../../dataProviders/BiayaMurniProviderParams";
import { ApiGetCostTotalsResponse } from "../../models/apiResponses/ApiGetCostTotalsResponse";
import ErrorResponse from "../../../../models/common/ErrorResponse";

const useGetCostTotals = (params: BiayaMurniGetCostTotalsParam) => {
  const [data, setData] = useState<ApiGetCostTotalsResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setError(null);
        setIsLoading(true);

        const result = await BiayaMurniProvider.getCostTotals(params);

        setData(result);
      } catch (error) {
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [params.startDate, params.endDate, params.userId, params.project]);

  return { data, isLoading, error };
};

export default useGetCostTotals;
