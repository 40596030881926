import { Button, useMediaQuery, useTheme, useToast } from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../models/RealisasiAndExtraCallDataModel";
import useDeleteExtraCall from "../services/useDeleteExtraCall";
import { useContext, useState } from "react";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";
import DialogKonfirmasiRealisasiKunjungan from "./DialogKonfirmasiRealisasiKunjungan";
import DateExodus from "../../../../../models/DateExodus";
import { CommonStatus } from "../../../../../models/common/Status";

type Props = {
  data: RealisasiAndExtraCallDetailRealizationModelData;
};

const ButtonDeleteExtraCall: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const { selectedPeriode, key, setKey } = useContext(
    InputRealisasiKunjunganContext
  );

  const { deleteExtraCall, loading } = useDeleteExtraCall();

  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const handleDeleteExtraCall = () => {
    deleteExtraCall(props.data.id, {
      onSuccess: () => {
        setOpenConfirmationDialog(false);
        toast({
          title: "Berhasil Menghapus Extra Call",
          status: "success",
        });
        setKey(key + 1);
      },
      onError: (errorMessage: string) => {
        setOpenConfirmationDialog(false);
        toast({
          title: "Gagal Menghapus Extra Call",
          description: `${errorMessage}`,
          status: "error",
        });
      },
    });
  };

  const disableButtonDeleteCondition = (): boolean => {
    if (!selectedPeriode) return true;

    const dateNow = new DateExodus();

    const isDateNowGreaterThanSelectedEndPeriod: boolean =
      parseInt(JSON.stringify(dateNow)) >
      parseInt(JSON.stringify(selectedPeriode.end_period));

    if (selectedPeriode.status_realization !== CommonStatus.Pending) {
      return true;
    } else if (isDateNowGreaterThanSelectedEndPeriod) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Button
        variant="ghost"
        leftIcon={
          <MdDelete
            style={{
              width: !isDesktopView ? "20px" : "24px",
              height: !isDesktopView ? "20px" : "24px",
            }}
          />
        }
        sx={{
          fontWeight: "bold",
          fontSize: !isDesktopView ? 12 : 14,
          color: theme.colors.exodus.red,
          paddingX: !isDesktopView ? "8px" : 0,
        }}
        onClick={() => setOpenConfirmationDialog(true)}
        isDisabled={disableButtonDeleteCondition()}
      >
        Delete
      </Button>

      <DialogKonfirmasiRealisasiKunjungan
        openConfirmationDialog={openConfirmationDialog}
        onSubmit={handleDeleteExtraCall}
        onClose={() => setOpenConfirmationDialog(false)}
        loading={loading}
        dialogTitle="Hapus Extra Call"
        dialogContent="Apakah Anda yakin mau menghapus Extra Call ini ?"
      />
    </>
  );
};

export default ButtonDeleteExtraCall;
