import { createContext, useContext } from "react";
import useGetSummary from "../services/dataFetching/useGetSummary";
import ApiGetSummarySalesOutletResponse from "../models/apiResponses/ApiGetSummarySalesOutletResponse";
import ErrorResponse from "../../../../../../models/common/ErrorResponse";
import { useSalesOutletListContext } from "./SalesOutletListContext";

type _Value = {
  states: {
    data: ApiGetSummarySalesOutletResponse | null;
    isLoading: boolean;
    error: ErrorResponse | null;
  };
};

const SalesOutletSummaryContext = createContext<_Value>({
  states: {
    data: null,
    isLoading: false,
    error: null,
  },
});

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{}>;

function useSalesOutletSummaryContext(): _Value {
  return useContext(SalesOutletSummaryContext);
}

const SalesOutletSummaryContextProvider = ({
  children,
}: _InternalProps): JSX.Element => {
  const {
    states: { filterValues },
  } = useSalesOutletListContext();

  const { data, isLoading, error } = useGetSummary({
    project: filterValues?.project,
    startDate: filterValues?.periode.awal,
    endDate: filterValues?.periode.akhir,
    userId: filterValues?.pekerja?.id,
    outlets: filterValues?.outlets,
    sector: filterValues?.sector,
    zone: filterValues?.zona,
  });

  const value: _Value = {
    states: { data, isLoading, error },
  };

  return (
    <SalesOutletSummaryContext.Provider value={value}>
      {children}
    </SalesOutletSummaryContext.Provider>
  );
};

export { SalesOutletSummaryContextProvider, useSalesOutletSummaryContext };
