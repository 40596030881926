import { FormControl, Input, NumberInput } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import MonitorKlaimSalesContext from "../../contexts/MonitorKlaimSalesContext";

const ClaimIdFormFieldKlaimSales: React.FC = () => {
  const { register, setValue, getValues } = useFormContext();
  const { filterValues } = useContext(MonitorKlaimSalesContext);

  useEffect(() => {
    setValue("idKlaimSales", filterValues?.idKlaimSales);
  }, []);

  return (
    <FormControl width={"100%"}>
      <FormInputLabel title="Claim ID" />
      <NumberInput defaultValue={getValues("idKlaimSales")}>
        <Input
          {...register("idKlaimSales")}
          type="number"
          onChange={(event: any) => {
            setValue(
              "idKlaimSales",
              event.target.value ? event.target.value : null
            );
          }}
          placeholder="Claim ID"
        />
      </NumberInput>
    </FormControl>
  );
};

export default ClaimIdFormFieldKlaimSales;
