import DateExodus from "../DateExodus";

class UserModel {
  id: number;
  name: string;
  nip: string;
  userZoneId?: number | undefined;
  role: {
    id: number;
    label?: string | undefined;
    name?: string | undefined;
  };
  salesZone: {
    id?: number | undefined;
    name?: string | undefined;
    type?: string | undefined;
  };
  assignedDate?: DateExodus | undefined;
  finishedDate?: DateExodus | undefined;

  constructor(params: {
    id: number;
    name: string;
    nip: string;
    userZoneId?: number;
    role: {
      id: number;
      label?: string | undefined;
      name?: string | undefined;
    };
    salesZone: {
      id?: number | undefined;
      name?: string | undefined;
      type?: string | undefined;
    };
    assignedDate?: DateExodus | undefined;
    finishedDate?: DateExodus | undefined;
  }) {
    this.id = params.id;
    this.name = params.name;
    this.nip = params.nip;
    this.userZoneId = params.userZoneId;
    this.role = params.role;
    this.salesZone = params.salesZone;
    this.assignedDate = params.assignedDate;
    this.finishedDate = params.finishedDate;
  }

  public static fromLocalDbJson(json: any) {
    return new UserModel({
      id: json.id,
      name: json.name,
      nip: json.nip,
      userZoneId: json.user_zone_id,
      role: json.role,
      salesZone: json.sales_zone,
      assignedDate:
        json.assignedDate === undefined
          ? DateExodus.fromBackend(json.assigned_date)
          : undefined,
      finishedDate:
        json.finishedDate === undefined
          ? DateExodus.fromBackend(json.finished_date)
          : undefined,
    });
  }

  toLocalDbJson() {
    return {
      id: this.id,
      name: this.name,
      nip: this.nip,
      user_zone_id: this.userZoneId,
      role: this.role,
      sales_zone: this.salesZone,
      assigned_date: this.assignedDate?.toJSON() ?? undefined,
      finished_date: this.finishedDate?.toJSON() ?? undefined,
    };
  }
}

export default UserModel;
