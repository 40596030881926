import { AxiosResponse, isAxiosError } from "axios";
import { EXODUS_API_VERSION_1 } from "../../../../constants/common/RootConstants";
import {
  GetListOutletCardParam,
  GetSaldoKontribusiBody,
  GetSaldoKontribusiParam,
} from "./OutletCardProviderParams";
import {
  ERROR_GET_FROM_INDEXED_DB,
  UNKNOWN_ERROR_RESPONSE,
} from "../../../../constants/common/ErrorConstants";
import AxiosBaseClient from "../../../../dataProviders/config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import {
  GetListOutletCardModelData,
  GetListOutletCardModelPack,
} from "../models/GetListOutletCardModel";
import IndexedDatabase from "../../../../database/IndexDB";
import {
  OutletCardListFilterType,
  OutletCardListPagination,
  OutletCardListSavedType,
} from "../OutletCardList/models/OutletCardListFilterType";
import DateExodus from "../../../../models/DateExodus";
import { ErrorResponse } from "react-router-dom";
import { isEmpty } from "lodash";
import { GetSaldoKontribusiModelData } from "../models/GetSaldoKontribusiModel";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const OutletCardProvider = {
  getList: async (params: GetListOutletCardParam) => {
    const url = `${EXODUS_API_VERSION_1}/cost/outlet-card`;

    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          start_date: params.startDate?.startOf("month").toJSON(),
          end_date: params.endDate?.endOf("month").toJSON(),
          page: params.page,
          limit: params.limit,
          outlet_id: params.outlet?.outletId,
          sector_type: params.sector?.value,
          user_id: params.userId,
          sales_zone_id: params.salesZoneId,
          sales_zone_type: params.salesZoneType,
          project: params.project?.value,
        },
      });

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const result = new GetListOutletCardModelPack(response.data);
        return Promise.resolve(result.data);
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error) {
      handleOnError(error);
    }
  },
  getSaldoKontribusi: async (
    params: GetSaldoKontribusiParam,
    body: GetSaldoKontribusiBody
  ) => {
    const url = `${EXODUS_API_VERSION_1}/cost/saldo-kontribusi/detail`;

    try {
      const response: AxiosResponse = await AxiosBaseClient.post(
        url,
        {
          start_date: body.startDate?.startOf("month").toJSON(),
          end_date: body.endDate?.endOf("month").toJSON(),
          outlet_id: body.outletId,
        },
        {
          params: {
            page: params.page,
            limit: params.limit,
          },
        }
      );
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        let result: GetSaldoKontribusiModelData | null = null;
        if (response.data.data?.records) {
          result = new GetSaldoKontribusiModelData(response.data.data.records);
        }
        return Promise.resolve(result ?? null);
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error) {
      console.log(error);
      handleOnError(error);
    }
  },
  getCostDetail: async (params: {
    startDate: DateExodus;
    endDate: DateExodus;
    outletId: number;
  }) => {
    const url = `${EXODUS_API_VERSION_1}/cost/outlet-card`;

    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          start_date: params.startDate?.startOf("month").toJSON(),
          end_date: params.endDate?.endOf("month").toJSON(),
          outlet_id: params.outletId,
        },
      });

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const data = new GetListOutletCardModelData(response.data.data[0]);
        return Promise.resolve(data);
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error) {
      handleOnError(error);
    }
  },
  getIndexDbFilter: async () => {
    try {
      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

      const filterData: OutletCardListSavedType[] | undefined =
        await IndexedDatabase.outletCardListFilter.toArray();

      if (filterData && filterData.length === 1) {
        const data = filterData[0];

        const result: OutletCardListFilterType = {
          startDate: new DateExodus(data.startDate),
          endDate: new DateExodus(data.endDate),
          sector: data.sector,
          zone: data.zone,
          bawahan: data.bawahan,
          role: data.role,
          outlet: data.outlet,
          project: data.project,
        };

        return Promise.resolve(result);
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      handleErrorIndexedDb();
    }
  },
  saveIndexDbFilter: async (updatedFilterValues: OutletCardListFilterType) => {
    try {
      const savedFilterValues: OutletCardListSavedType = {
        startDate: updatedFilterValues.startDate.getEpochDate(),
        endDate: updatedFilterValues.endDate.getEpochDate(),
        sector: updatedFilterValues.sector,
        zone: updatedFilterValues.zone,
        bawahan: updatedFilterValues.bawahan,
        role: updatedFilterValues.role,
        outlet: updatedFilterValues.outlet,
        project: updatedFilterValues.project,
      };

      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

      const oldData: OutletCardListSavedType[] | undefined =
        await IndexedDatabase.outletCardListFilter.toArray();

      if (oldData && oldData.length === 1) {
        IndexedDatabase.outletCardListFilter.update(
          oldData[0] as OutletCardListSavedType,
          savedFilterValues
        );
      } else {
        IndexedDatabase.outletCardListFilter.add(savedFilterValues);
      }
      return Promise.resolve();
    } catch (error) {
      handleErrorIndexedDb();
    }
  },
  getIndexDbPagination: async () => {
    try {
      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

      const paginationData: OutletCardListPagination[] | undefined =
        await IndexedDatabase.outletCardListPagination.toArray();

      if (!isEmpty(paginationData) && paginationData.length === 1) {
        return Promise.resolve(paginationData[0] as OutletCardListPagination);
      }
      return Promise.resolve(null);
    } catch (error) {
      handleErrorIndexedDb();
    }
  },
  saveIndexDbPagination: async (
    updatedPagination: OutletCardListPagination
  ) => {
    try {
      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

      const oldData: OutletCardListPagination[] | undefined =
        await IndexedDatabase.outletCardListPagination.toArray();

      if (oldData && oldData.length === 1) {
        IndexedDatabase.outletCardListPagination.update(
          oldData[0] as OutletCardListPagination,
          updatedPagination
        );
      } else {
        IndexedDatabase.outletCardListPagination.add(updatedPagination);
      }

      return Promise.resolve();
    } catch (error) {
      handleErrorIndexedDb();
    }
  },
};

const handleOnError = (error: unknown) => {
  if (isAxiosError(error)) {
    return Promise.reject({
      message: error.response?.data.message,
      status: error.response?.status,
      code: error.code,
    });
  }

  return Promise.reject(UNKNOWN_ERROR_RESPONSE);
};

const handleErrorIndexedDb = (): Promise<ErrorResponse> => {
  return Promise.reject(ERROR_GET_FROM_INDEXED_DB);
};

export default OutletCardProvider;
