import DateExodus from "../../../../../../models/DateExodus";
import { OutletModel } from "../../../../../../models/Outlet/OutletModel";
import SectorModel from "../../../../../../models/Sector/SectorModel";
import RoleModel from "../../../../../../models/Users/RoleModel";
import UserModel from "../../../../../../models/Users/UserModel";
import ZoneModel from "../../../../../../models/Zone/ZoneModel";
import { ProjectEnum } from "../../../../../../models/common/Project";
import {
  OutletModelJsonConverter,
  SectorTypeJsonConverter,
  ZoneModelJsonConverter,
} from "../../../utils/utils";

class SalesOutletListFilterModel {
  project: undefined | ProjectEnum;
  jabatan: undefined | RoleModel;
  pekerja: undefined | UserModel;
  zona: undefined | ZoneModel;
  outlets: undefined | OutletModel[];
  sector: undefined | SectorModel;
  periode: {
    awal: DateExodus;
    akhir: DateExodus;
  };

  constructor({
    project = undefined,
    jabatan = undefined,
    pekerja = undefined,
    zona = undefined,
    outlets = undefined,
    sector = undefined,
    periode,
  }: {
    project?: ProjectEnum;
    jabatan?: RoleModel;
    pekerja?: UserModel;
    zona?: ZoneModel;
    outlets?: OutletModel[];
    sector?: SectorModel;
    periode: {
      awal: DateExodus;
      akhir: DateExodus;
    };
  }) {
    this.project = project;
    this.jabatan = jabatan;
    this.pekerja = pekerja;
    this.zona = zona;
    this.outlets = outlets;
    this.sector = sector;
    this.periode = periode;
  }

  public static fromJson(json: any) {
    const tempOutlets: OutletModel[] = [];
    for (let i = 0; i < json.outlets.length; i++) {
      tempOutlets.push(OutletModelJsonConverter.fromJson(json.outlets[i]));
    }

    return new SalesOutletListFilterModel({
      project: json.project ?? undefined,
      jabatan:
        json.jabatan === null
          ? undefined
          : RoleModel.fromLocalDbJson(json.jabatan),
      pekerja:
        json.pekerja === null
          ? undefined
          : UserModel.fromLocalDbJson(json.pekerja),
      zona: ZoneModelJsonConverter.fromJson(json.zona),
      outlets: tempOutlets,
      sector: SectorTypeJsonConverter.fromJson(json.sector),
      periode: {
        awal: DateExodus.fromBackend(json.periode.awal),
        akhir: DateExodus.fromBackend(json.periode.akhir),
      },
    });
  }

  toJson() {
    const tempOutlets: any[] = [];
    if (this.outlets?.length) {
      for (let i = 0; i < this.outlets.length; i++) {
        tempOutlets.push(OutletModelJsonConverter.toJson(this.outlets[i]));
      }
    }
    return {
      project: this.project ?? null,
      jabatan: this.jabatan?.toLocalDbJson() ?? null,
      pekerja: this.pekerja?.toLocalDbJson() ?? null,
      zona: ZoneModelJsonConverter.toJson(this.zona),
      outlets: tempOutlets,
      sector: SectorTypeJsonConverter.toJson(this.sector),
      periode: {
        awal: new DateExodus(this.periode.awal.getEpochStartOfMonth()).toJSON(),
        akhir: new DateExodus(this.periode.akhir.getEpochEndOfMonth()).toJSON(),
      },
    };
  }
}

export default SalesOutletListFilterModel;
