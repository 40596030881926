import { useContext } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spinner,
  Text,
  useTheme,
} from "@chakra-ui/react";
import InputRealisasiKunjunganContext from "../../context/InputRealisasiKunjunganContext";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../../models/RealisasiAndExtraCallDataModel";
import HorizontalAccordionDetail from "../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import ButtonLihatDetailRealisasiAndExtraCall from "../ButtonLihatDetailRealisasiAndExtraCall";
import ButtonInputRealisasiAndEditExtraCall from "../ButtonInputRealisasiAndEditExtraCall";
import ButtonDeleteExtraCall from "../ButtonDeleteExtraCall";

const MobileTableRealisasiKunjungan: React.FC = () => {
  const theme = useTheme();

  const {
    realisasiOrExtraCallData,
    realisasiOrExtraCallLoading,
    realisasiOrExtraCallError,
    currentKunjungan,
  } = useContext(InputRealisasiKunjunganContext);

  const generateWaktuKunjungan = (
    data: RealisasiAndExtraCallDetailRealizationModelData
  ) => {
    if (
      !data.date &&
      !data.startTime &&
      !data.endTime &&
      !data.komentarKunjungan
    ) {
      return "-";
    } else if (
      !data.date &&
      !data.startTime &&
      !data.endTime &&
      data.komentarKunjungan
    ) {
      return "Gagal Kunjungan";
    } else {
      return `${data.date!.getStringDateFormat("dddd, D MMMM YYYY")}`;
    }
  };

  const generateWaktuKunjunganTextColor = (
    data: RealisasiAndExtraCallDetailRealizationModelData
  ) => {
    if (
      !data.date &&
      !data.startTime &&
      !data.endTime &&
      data.komentarKunjungan
    ) {
      return theme.colors.exodus.red;
    } else {
      return theme.colors.exodus.fontDefault;
    }
  };

  if (realisasiOrExtraCallLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (realisasiOrExtraCallError) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={realisasiOrExtraCallError.toString()} />
      </Box>
    );
  }

  return realisasiOrExtraCallData.length > 0 ? (
    <Box marginTop="24px" marginBottom="15px">
      <Accordion allowToggle>
        {realisasiOrExtraCallData.map(
          (item: RealisasiAndExtraCallDetailRealizationModelData) => (
            <AccordionItem key={item.id} border="none" marginBottom="16px">
              <AccordionButton
                display="flex"
                justifyContent="space-between"
                gap={4}
              >
                <Box textAlign="left">
                  <Text
                    fontSize={14}
                    fontWeight="bold"
                    color={theme.colors.exodus.primaryBlue}
                  >
                    {item.outletName}
                  </Text>
                  <Text fontSize={12}>{item.outletCode}</Text>
                </Box>
                <Box display="flex" alignItems="center" gap="10px">
                  {currentKunjungan === "rencana" && (
                    <Text
                      fontSize={12}
                      fontWeight="bold"
                      color={
                        item.komentarKunjungan
                          ? theme.colors.exodus.primaryBlue
                          : theme.colors.exodus.yellow
                      }
                      textAlign="right"
                    >
                      {item.komentarKunjungan
                        ? "Telah Diinput"
                        : "Perlu Diinput"}
                    </Text>
                  )}
                  <AccordionIcon />
                </Box>
              </AccordionButton>
              <AccordionPanel marginX="16px">
                <HorizontalAccordionDetail
                  leftText="Customer"
                  rightSide={`${item.customerName} (${item.customerCode})`}
                  size="small"
                  isRowColored
                />
                <HorizontalAccordionDetail
                  leftText="Spesialis"
                  rightSide={item.spesialis}
                  size="small"
                />
                <HorizontalAccordionDetail
                  leftText="Jabatan"
                  rightSide={item.jabatan}
                  size="small"
                  isRowColored
                />
                {currentKunjungan === "rencana" && (
                  <HorizontalAccordionDetail
                    leftText="Waktu Kunjungan"
                    rightSide={
                      <Text
                        textAlign="left"
                        fontSize={12}
                        color={generateWaktuKunjunganTextColor(item)}
                      >
                        {generateWaktuKunjungan(item)}
                      </Text>
                    }
                    size="small"
                  />
                )}

                <Flex justify="flex-end" paddingTop="10px" gap={2}>
                  {currentKunjungan === "rencana" && item.komentarKunjungan && (
                    <ButtonLihatDetailRealisasiAndExtraCall data={item} />
                  )}
                  {currentKunjungan === "extracall" && (
                    <ButtonLihatDetailRealisasiAndExtraCall data={item} />
                  )}
                  {currentKunjungan === "rencana" &&
                    !item.komentarKunjungan && (
                      <ButtonInputRealisasiAndEditExtraCall
                        data={item}
                        buttonText="Input Realisasi"
                      />
                    )}
                  {currentKunjungan === "rencana" && item.komentarKunjungan && (
                    <ButtonInputRealisasiAndEditExtraCall
                      data={item}
                      buttonText="Edit"
                    />
                  )}
                  {currentKunjungan === "extracall" && (
                    <ButtonInputRealisasiAndEditExtraCall
                      data={item}
                      buttonText="Edit"
                    />
                  )}
                  {currentKunjungan === "extracall" && (
                    <ButtonDeleteExtraCall data={item} />
                  )}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          )
        )}
      </Accordion>
    </Box>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default MobileTableRealisasiKunjungan;
