import { Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import CustomDatePicker from "../../../../../../../../../../../components/DatePicker/CustomDatePicker";
import DateExodus from "../../../../../../../../../../../models/DateExodus";
import SalesOutletListFilterModel from "../../../../../../models/SalesOutletListFilterModel";
import FilterFieldFrame from "../FilterFieldFrame";

const FilterByPeriode = () => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const formContext = useFormContext<SalesOutletListFilterModel>();

  const [tempStartDate, setTempStartDate] = useState<DateExodus>(
    formContext.getValues("periode.awal")
  );
  const [tempEndDate, setTempEndDate] = useState<DateExodus>(
    formContext.getValues("periode.akhir")
  );

  return (
    <Stack gap={4} direction={isMobile ? "column" : "row"}>
      <FilterFieldFrame title="Periode Awal">
        <CustomDatePicker
          placeholder="Periode Awal"
          type={"month_year"}
          defaultValue={tempStartDate.getDateObject()}
          onChange={(value) => {
            if (value) {
              setTempStartDate(DateExodus.fromDateObject(value));
              formContext.setValue(
                "periode.awal",
                DateExodus.fromDateObject(value)
              );
            }
          }}
          name={"jsPeriodeAwal"}
          maxDate={tempEndDate.getDateObject()}
        />
      </FilterFieldFrame>
      <FilterFieldFrame title="Periode Akhir">
        <CustomDatePicker
          placeholder="Periode Akhir"
          type={"month_year"}
          defaultValue={tempEndDate.getDateObject()}
          onChange={(value) => {
            if (value) {
              setTempEndDate(DateExodus.fromDateObject(value));
              formContext.setValue(
                "periode.akhir",
                DateExodus.fromDateObject(value)
              );
            }
          }}
          name={"jsPeriodeAkhir"}
          minDate={tempStartDate.getDateObject()}
          maxDate={new DateExodus().getDateObject()}
        />
      </FilterFieldFrame>
    </Stack>
  );
};

export default FilterByPeriode;
