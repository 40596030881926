import { SECTOR_LIST } from "../../../../constants/common/SectorConstants";
import DateExodus from "../../../../models/DateExodus";
import { OutletModel } from "../../../../models/Outlet/OutletModel";
import SectorModel from "../../../../models/Sector/SectorModel";
import ZoneModel from "../../../../models/Zone/ZoneModel";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { ProjectEnum } from "../../../../models/common/Project";

export const OutletModelJsonConverter = {
  toJson: (model: OutletModel) => {
    return {
      outlet_id: model.outletId,
      outlet_code: model.outletCode,
      outlet_name: model.outletName,
      outlet_label: model.outletLabel,
      sector_type: SectorTypeJsonConverter.toJson(model.sectorType),
      address: model.address,
      code_post: model.codePost,
      group_territory_id: model.groupTerritoryId,
      group_territory_name: model.groupTerritoryName,
    };
  },
  fromJson: (json: any): OutletModel => {
    return {
      outletId: json.outlet_id,
      outletCode: json.outlet_code,
      outletName: json.outlet_name,
      outletLabel: json.outlet_label,
      sectorType: SectorTypeJsonConverter.fromJson(json.sector_type)!,
      address: json.address,
      codePost: json.code_post,
      groupTerritoryId: json.group_territory_id,
      groupTerritoryName: json.group_territory_name,
    };
  },
};

export const SectorTypeJsonConverter = {
  toJson: (model: SectorModel | undefined) => {
    if (model) {
      return {
        label: model.label,
        value: model.value,
      };
    } else {
      return null;
    }
  },
  fromJson: (json: any): SectorModel | undefined => {
    if (json) {
      return SECTOR_LIST.find((x) => x.value === json)!;
    }
    return undefined;
  },
};

export const ZoneModelJsonConverter = {
  toJson: (model: ZoneModel | undefined) => {
    if (model) {
      return {
        sales_zone_id: model.salesZoneId,
        sales_zone_type: model.salesZoneType,
        sales_zone_name: model.salesZoneName,
      };
    } else {
      return null;
    }
  },
  fromJson: (json: any): ZoneModel | undefined => {
    if (json) {
      return {
        salesZoneId: json.sales_zone_id,
        salesZoneName: json.sales_zone_name,
        salesZoneType: json.sales_zone_type,
      };
    } else {
      return undefined;
    }
  },
};

export const feCustomErrorResponse = (message: string) => {
  const error: ErrorResponse = {
    message: message,
    status: 0,
    code: "",
  };
  return error;
};

export const generateSalesOutletDetailUri = (props: {
  outletId: number;
  startDate?: DateExodus;
  endDate?: DateExodus;
  userId?: number;
  project?: ProjectEnum;
}) => {
  let url = `/sales-outlet/detail/${
    props.outletId
  }?start-date=${props.startDate?.toJSON()}&end-date=${props.endDate?.toJSON()}`;

  if (props.userId) {
    url = url + `&user-id=${props.userId}`;
  }
  if (props.project) {
    url = url + `&project=${props.project}`;
  }

  return url;
};
