import { useState } from "react";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { InputKlaimSalesBody } from "../models/InputKlaimSalesModel";
import InputKlaimSalesProvider from "../dataProviders/InputKlaimSalesProvider";

type CallResultHandler = {
  onSuccess: (data: string) => void;
  onError: (data: ErrorResponse) => void;
};

const useInputKlaimSales = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorResponse, setError] = useState<ErrorResponse | null>(null);

  const inputKlaimSales = async (
    data: InputKlaimSalesBody,
    handler: CallResultHandler
  ) => {
    try {
      setError(null);
      setLoading(true);

      const response = await InputKlaimSalesProvider.inputKlaimSales({
        data: data,
      });

      setLoading(false);
      handler.onSuccess(response);
    } catch (error) {
      setError(error as ErrorResponse);
      setLoading(false);
      handler.onError(error as ErrorResponse);
    }
  };

  return { inputKlaimSales, loading, errorResponse };
};

export default useInputKlaimSales;
