import { Box, Flex, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import DropdownPeriodeByUserId from "../../../components/DropdownPeriodeByUserId";
import StatusChip from "../../../../../components/StatusChip/StatusChip";
import { useContext } from "react";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";
import { CommonStatus } from "../../../../../models/common/Status";
import ButtonInputExtraCall from "./ButtonInputExtraCall";
import ButtonSubmitRealisasiKunjungan from "./ButtonSubmitRealisasiKunjungan";

const InputRealisasiPeriodeAndStatus: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { periodeData, currentKunjungan, selectedPeriode, setSelectedPeriode } =
    useContext(InputRealisasiKunjunganContext);

  const setStatusType = (): "processed" | "pending" => {
    if (selectedPeriode?.status_realization === CommonStatus.Approved) {
      return "processed";
    } else {
      return "pending";
    }
  };

  const generatePaddingBottom = (): number | string => {
    if (isDesktopView && selectedPeriode && selectedPeriode.senior_comment)
      return "20px";
    if (isDesktopView && selectedPeriode && !selectedPeriode.senior_comment)
      return "26px";
    if (!isDesktopView && selectedPeriode && selectedPeriode.senior_comment)
      return "8px";
    if (!isDesktopView && selectedPeriode && !selectedPeriode.senior_comment)
      return "16px";
    return "16px";
  };

  return (
    <Flex
      paddingBottom={generatePaddingBottom()}
      justify="space-between"
      align="flex-start"
    >
      <Flex
        gap={isDesktopView ? 7 : "10px"}
        direction={isDesktopView ? "row" : "column"}
        width="100%"
        align={isDesktopView ? "center" : "start"}
      >
        <Box width={isDesktopView ? "301px" : "100%"}>
          <DropdownPeriodeByUserId
            options={periodeData}
            value={selectedPeriode}
            onChange={setSelectedPeriode}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Text
            fontWeight="semibold"
            fontSize={isDesktopView ? 16 : 14}
            color={theme.colors.exodus.primaryBlue}
          >
            Status:
          </Text>
          <StatusChip
            status={setStatusType()}
            text={selectedPeriode ? selectedPeriode.status_realization : ""}
          />
        </Box>
      </Flex>
      {isDesktopView && (
        <Flex width="100%" direction="row-reverse" gap={4} wrap="wrap">
          <ButtonSubmitRealisasiKunjungan />
          {currentKunjungan === "extracall" && <ButtonInputExtraCall />}
        </Flex>
      )}
    </Flex>
  );
};

export default InputRealisasiPeriodeAndStatus;
