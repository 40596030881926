import { MdMonitor } from "react-icons/md";
import {
  AccordionItem,
  AccordionButton,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import {
  ROUTE_ID_INPUT_KLAIM_ENTERTAIN,
  ROUTE_ID_MONITOR_KLAIM_ENTERTAIN,
  ROUTE_ID_APPROVAL_KLAIM_ENTERTAIN,
  ROUTE_ID_FINANCE_APPROVAL_KLAIM_ENTERTAIN,
  ROUTE_ID_DETAIL_DPL_DPF,
  ROUTE_ID_MONITOR_DPL_DPF,
  ROUTE_ID_REQUEST_CREATE_DPL_DPF,
  ROUTE_ID_APPROVAL_CREATE_DPL_DPF,
  ROUTE_ID_HISTORY_REQUEST_EDIT_DPF,
  ROUTE_ID_REQUEST_EDIT_DPF,
  ROUTE_ID_APPROVAL_EDIT_DPF,
  ROUTE_ID_PSSP_REQUEST_LIST,
  ROUTE_ID_INPUT_PSSP,
  ROUTE_ID_APPROVAL_PSSP,
  ROUTE_ID_FINANCE_APPROVAL_PSSP,
  ROUTE_ID_INPUT_STANDARISASI,
  ROUTE_ID_DETAIL_STANDARISASI,
  ROUTE_ID_MONITOR_STANDARISASI,
  ROUTE_ID_APPROVAL_STANDARISASI,
  ROUTE_ID_FINANCE_APPROVAL_STANDARISASI,
  ROUTE_ID_INPUT_DOWN_PAYMENT,
  ROUTE_ID_APPROVAL_DOWN_PAYMENT,
  ROUTE_ID_APPROVAL_DOWN_PAYMENT_ADDENDUM,
  ROUTE_ID_APPROVAL_DOWN_PAYMENT_DETAIL,
  ROUTE_ID_APPROVAL_DOWN_PAYMENT_PERIODE_AKHIR,
  ROUTE_ID_TRANSFER_DOWN_PAYMENT,
  ROUTE_ID_TRANSFER_DOWN_PAYMENT_DETAIL,
  ROUTE_ID_MONITORING_DOWN_PAYMENT,
  ROUTE_ID_MONITORING_DOWN_PAYMENT_DETAIL,
} from "../../../constants/common/RouteIdConstants";
import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";
import verifyPageAccess from "../../../utils/VerifyPageAccess";

const PromotionMenu = ({}: { onClose: Function }) => {
  const theme = useTheme();

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  const isEntertainPageVerified =
    verifyPageAccess(loggedUser, ROUTE_ID_INPUT_KLAIM_ENTERTAIN) ||
    verifyPageAccess(loggedUser, ROUTE_ID_MONITOR_KLAIM_ENTERTAIN) ||
    verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_KLAIM_ENTERTAIN) ||
    verifyPageAccess(loggedUser, ROUTE_ID_FINANCE_APPROVAL_KLAIM_ENTERTAIN);

  const isDiscountPageVerified =
    verifyPageAccess(loggedUser, ROUTE_ID_DETAIL_DPL_DPF) ||
    verifyPageAccess(loggedUser, ROUTE_ID_MONITOR_DPL_DPF) ||
    verifyPageAccess(loggedUser, ROUTE_ID_REQUEST_CREATE_DPL_DPF) ||
    verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_CREATE_DPL_DPF) ||
    verifyPageAccess(loggedUser, ROUTE_ID_HISTORY_REQUEST_EDIT_DPF) ||
    verifyPageAccess(loggedUser, ROUTE_ID_REQUEST_EDIT_DPF) ||
    verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_EDIT_DPF);

  const isPSSPPageVerified =
    verifyPageAccess(loggedUser, ROUTE_ID_PSSP_REQUEST_LIST) ||
    verifyPageAccess(loggedUser, ROUTE_ID_INPUT_PSSP) ||
    verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_PSSP) ||
    verifyPageAccess(loggedUser, ROUTE_ID_FINANCE_APPROVAL_PSSP);
  // kurang pssp detail, tapi tetep bisa jalan soalnya masuknya ke ethical.

  const isStandarisasiPageVerified =
    verifyPageAccess(loggedUser, ROUTE_ID_INPUT_STANDARISASI) ||
    verifyPageAccess(loggedUser, ROUTE_ID_DETAIL_STANDARISASI) ||
    verifyPageAccess(loggedUser, ROUTE_ID_MONITOR_STANDARISASI) ||
    verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_STANDARISASI) ||
    verifyPageAccess(loggedUser, ROUTE_ID_FINANCE_APPROVAL_STANDARISASI);

  const isDownpaymentPageVerified =
    verifyPageAccess(loggedUser, ROUTE_ID_INPUT_DOWN_PAYMENT) ||
    verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_DOWN_PAYMENT) ||
    verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_DOWN_PAYMENT_ADDENDUM) ||
    verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_DOWN_PAYMENT_DETAIL) ||
    verifyPageAccess(
      loggedUser,
      ROUTE_ID_APPROVAL_DOWN_PAYMENT_PERIODE_AKHIR
    ) ||
    verifyPageAccess(loggedUser, ROUTE_ID_TRANSFER_DOWN_PAYMENT) ||
    verifyPageAccess(loggedUser, ROUTE_ID_MONITORING_DOWN_PAYMENT) ||
    verifyPageAccess(loggedUser, ROUTE_ID_MONITORING_DOWN_PAYMENT_DETAIL) ||
    verifyPageAccess(loggedUser, ROUTE_ID_TRANSFER_DOWN_PAYMENT_DETAIL);

  if (
    !isEntertainPageVerified &&
    !isDiscountPageVerified &&
    !isDownpaymentPageVerified &&
    !isPSSPPageVerified &&
    !isStandarisasiPageVerified
  )
    return null;

  const handleOnClick = () => {
    window.location.href = `/promotion`;
  };

  return (
    <AccordionItem onClick={handleOnClick}>
      <AccordionButton>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 4,
          }}
        >
          <Icon
            as={MdMonitor}
            color={theme.colors.exodus.primaryBlue}
            boxSize={6}
          />
          <Text
            fontSize={18}
            fontWeight={400}
            sx={{ color: theme.colors.exodus.primaryBlue }}
          >
            Promotion
          </Text>
        </Stack>
      </AccordionButton>
    </AccordionItem>
  );
};

export default PromotionMenu;
