import {
  FormControl,
  FormErrorMessage,
  Textarea,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import { useFormContext } from "react-hook-form";
import CreateAndUpdateExtraCallBody from "../../models/CreateAndUpdateExtraCallModel";

type Props = {
  defaultValue?: string;
};

const RealisasiExtraCallKomentarKunjunganField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const {
    register,
    formState: { errors },
  } = useFormContext<CreateAndUpdateExtraCallBody>();

  return (
    <FormControl
      width="100%"
      marginBottom={!isDesktopView ? 0 : "20px"}
      isInvalid={!!errors.visitor_comment}
    >
      <FormInputLabel title="Komentar Kunjungan" />
      <Textarea
        {...register("visitor_comment", {
          required: "Required",
          validate: (value) => {
            if (value && value.trim() === "") {
              return "Required";
            }
          },
        })}
        placeholder="Masukkan Komentar..."
        resize="none"
        defaultValue={props.defaultValue}
      />
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.visitor_comment && (errors.visitor_comment.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default RealisasiExtraCallKomentarKunjunganField;
