import { SECTOR_LIST } from "../../../../constants/common/SectorConstants";
import SectorModel from "../../../../models/Sector/SectorModel";

export function sectorStringToSectorModel(sectorType: string): SectorModel {
  const result = SECTOR_LIST.find((x) => x.value === sectorType);

  if (result) {
    return result;
  } else {
    throw Error("Unrecognized sector type");
  }
}
