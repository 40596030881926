import { ProjectEnum } from "../../../../models/common/Project";

class BiayaMurniDplDpfData {
  outletId: number;
  outletName: string;
  outletCode: string;
  total: number;
  project: ProjectEnum;

  constructor(json: any) {
    this.outletId = json["outlet_id"];
    this.outletName = json["outlet_name"];
    this.outletCode = json["outlet_code"];
    this.total = json["discount_cost_total"];
    this.project = json.project;
  }
}

export { BiayaMurniDplDpfData };
