import { useState } from "react";
import MonitoringFilterValues from "../models/MonitoringFilterValues";
import { MonitoringBudgetingProvider } from "../dataProviders/MonitoringBudgetingProvider";

const useSaveFilter = () => {
  const [message, setMessage] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);

  const saveFilter = async (filterValues: MonitoringFilterValues) => {
    setLoading(true);
    try {
      const savedFilter = {
        ...filterValues,
        periodeTahun: filterValues.periodeTahun?.getEpochDate(),
        periodeAwal: filterValues.periodeAwal?.getEpochStartOfMonth(),
        periodeAkhir: filterValues.periodeAkhir?.getEpochEndOfMonth(),
      };
      const result: string =
        await MonitoringBudgetingProvider.saveFilter(savedFilter);

      setMessage(result);
    } catch (error: unknown) {
      setMessage(error as string);
    } finally {
      setLoading(false);
    }
  };

  return { message, isLoading, saveFilter };
};

export default useSaveFilter;
