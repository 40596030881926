import { FormControl } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import UserModel from "../../../../../models/Users/UserModel";
import useGetUserByRole from "../../../../../services/Users/useGetUserByRole";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import MonitorKlaimSalesContext from "../../contexts/MonitorKlaimSalesContext";
import { isEmpty } from "lodash";

const NsmFormFieldKlaimSales: React.FC = () => {
  const { register, setValue, watch } = useFormContext();
  const watchId = watch("idKlaimSales");

  const { filterValues } = useContext(MonitorKlaimSalesContext);

  const [selectedNama, setSelectedNama] = useState<
    UserModel | null | undefined
  >(filterValues?.claimUser);

  const { userData } = useGetUserByRole({
    roleName: "nsm",
  });

  useEffect(() => {
    setValue("claimUser", selectedNama);
  }, []);

  return (
    <FormControl width={"100%"}>
      <FormInputLabel title="Nama NSM" />
      <CustomSelect
        {...register("claimUser")}
        onChange={(data: UserModel | null) => {
          setSelectedNama(data);
          setValue("claimUser", data ? data : null);
        }}
        options={userData}
        value={selectedNama}
        getOptionLabel={(data: UserModel) => data.name}
        getOptionValue={(data: UserModel) => data.id.toString()}
        placeholder={"Nama NSM"}
        isDisabled={!isEmpty(watchId)}
      />
    </FormControl>
  );
};

export default NsmFormFieldKlaimSales;
