import { Box, Card, Spinner, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import useDistributorLastUpdate from "../service/useDistributorLastUpdate";

const DistributorLastUpdateCard: React.FC = () => {
  const theme = useTheme();
  const { data, error, loading } = useDistributorLastUpdate();
  return (
    <Card
      padding={{
        base: "14px",
        tablet: "24px",
      }}
      borderRadius={"16px"}
      width={"100%"}
      backgroundColor={theme.colors.exodus.background}
      boxShadow="0px 7px 24px 4px #282c7a26"
    >
      <Text
        fontSize={{
          base: "16px",
          tablet: "24px",
        }}
        fontWeight={700}
        color={theme.colors.exodus.primaryBlue}
        textAlign={"start"}
      >
        Update Data Distributor
      </Text>
      {error && (
        <Text py={4} color={theme.colors.exodus.red}>
          {error}
        </Text>
      )}
      {loading && (
        <Box py={4}>
          <Spinner />
        </Box>
      )}
      <Box mt={3} display={"flex"} flexDirection={"column"} gap={2}>
        {data &&
          data.map((d, key) => (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={3}
              key={key}
            >
              <Text
                fontSize={{
                  base: "12px",
                  tablet: "16px",
                }}
                fontWeight={600}
                textAlign={"start"}
                color={theme.colors.exodus.primaryBlue}
              >
                {d.distributorName}
              </Text>
              <Text
                fontSize={{
                  base: "12px",
                  tablet: "16px",
                }}
                color={theme.colors.exodus.fontDefault}
                fontWeight={600}
                textAlign={"end"}
              >
                {d.periodSales.getStringDateFormat("DD MMMM YYYY")}
              </Text>
            </Box>
          ))}
      </Box>
    </Card>
  );
};

export default DistributorLastUpdateCard;
