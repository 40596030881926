import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Spinner,
  Text,
  useMediaQuery,
  useTheme,
  Card,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import TableDataComponent from "../../../components/TableData/TableDataComponent";
import { TableColumn } from "react-data-table-component";
import DashboardContext from "../context/DashboardContext";
import DateExodus from "../../../models/DateExodus";
import { TargetSalesMonthlyModelData } from "../model/TargetSalesMonthly";
import CurrencyIdrFormatter from "../../../utils/CurrencyIdrFormatter";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";
import ProjectType from "../../../models/common/ProjectType";

const TargetSalesCard: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const context = useContext(DashboardContext);
  const tableHeader: TableColumn<TargetSalesMonthlyModelData>[] = [
    {
      name: "NIP",
      selector: (row) => row.fieldForceNip ?? "-",
    },
    {
      name: "Nama FF",
      selector: (row) => row.fieldForceNama ?? "-",
    },
    {
      name: "Target Sales",
      selector: (row) =>
        CurrencyIdrFormatter.convertToRp(row.targetSalesValue ?? 0),
    },
    {
      name: "Total sales sekarang",
      selector: (row) => CurrencyIdrFormatter.convertToRp(row.salesValue ?? 0),
    },
  ];

  return (
    <Card
      padding={{
        base: "14px",
        tablet: "24px",
      }}
      width={"100%"}
      backgroundColor={theme.colors.exodus.background}
      boxShadow="0px 7px 24px 4px #282c7a26"
      borderRadius={"16px"}
    >
      <Text
        fontSize={{
          base: "16px",
          tablet: "24px",
        }}
        fontWeight={700}
        color={theme.colors.exodus.primaryBlue}
        textAlign={"start"}
      >
        Target sales {new DateExodus().getStringDateFormat("MMMM YYYY")}
      </Text>
      {context.targetSalesError && (
        <Text py={4} color={theme.colors.exodus.red}>
          {context.targetSalesError}
        </Text>
      )}
      {context.targetSalesLoading && (
        <Box py={4}>
          <Spinner />
        </Box>
      )}
      <Box paddingY="12px">
        <CustomSelect
          options={loggedUser?.projects ?? []}
          isDisabled={loggedUser ? loggedUser.projects.length <= 1 : false}
          getOptionLabel={(data: ProjectType) => data.label}
          getOptionValue={(data: ProjectType) => data.value}
          value={context.selectedProject}
          placeholder="Project"
          onChange={(data: ProjectType | null) => {
            if (!data) {
              context.setSelectedProject(null);
            } else {
              context.setSelectedProject(data);
            }
          }}
        />
      </Box>
      {!context.targetSalesError && !context.targetSalesLoading && (
        <Box mt={2}>
          {isDesktopView ? (
            <TableDataComponent
              data={context.targetSales ?? [{}]}
              checboxOn={false}
              paginationOn={false}
              tableHeader={tableHeader}
              paginationLimit={[0]}
              onSelectChange={() => {}}
            />
          ) : (
            // Jika di mobile, show Accordion view
            <>
              <Accordion allowToggle>
                {context.targetSales &&
                  context.targetSales.map((data) => (
                    <AccordionItem>
                      <AccordionButton
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <Box>
                          <Text
                            textAlign={"start"}
                            fontWeight={600}
                            fontSize={"14px"}
                            color={theme.colors.exodus.primaryBlue}
                          >
                            {data.fieldForceNama}
                          </Text>
                          <Text textAlign={"start"} fontSize={"14px"}>
                            {data.fieldForceNip}
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Text
                            fontSize={"14px"}
                            color={theme.colors.exodus.primaryBlue}
                            fontWeight={600}
                          >
                            Target Sales
                          </Text>
                          <Text fontSize={"14px"}>
                            {CurrencyIdrFormatter.convertToRp(
                              data.targetSalesValue ?? 0
                            )}
                          </Text>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mt={1}
                        >
                          <Text
                            fontSize={"14px"}
                            color={theme.colors.exodus.primaryBlue}
                            fontWeight={600}
                          >
                            Total Sales Sekarang
                          </Text>
                          <Text fontSize={"14px"}>
                            {CurrencyIdrFormatter.convertToRp(
                              data.salesValue ?? 0
                            )}
                          </Text>
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
              </Accordion>
            </>
          )}
        </Box>
      )}
    </Card>
  );
};

export default TargetSalesCard;
