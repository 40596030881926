import { useState } from "react";

/**
 * A logical hook with functions and states, made for Input Klaim Sales's
 * Pilih Produk (Step 3).
 *
 * @remarks
 * Not to be used directly inside components. Use with
 * [PilihProdukDetailEnabledProvider]. Contain editable state and the modifier
 * for "faktur" detail.
 *
 * @returns JSX.Element
 *
 */
const usePilihProdukDetailEnabled = (initialValue: boolean) => {
  const [isEditable, setIsEditable] = useState<boolean>(initialValue);

  return { isEditable, setIsEditable };
};

export default usePilihProdukDetailEnabled;
