import { useEffect, useState } from "react";
import ErrorResponse from "../../../models/common/ErrorResponse";
import KlaimSalesProvider from "../dataProviders/KlaimSalesProvider";
import { KlaimSalesDetailModelData } from "../models/KlaimSalesDetailModel";

const useGetDetailKlaimSales = (klaimSalesListId: number | null) => {
  const [detailKlaimSalesData, setData] =
    useState<KlaimSalesDetailModelData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorResponse, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    if (klaimSalesListId) {
      getDetailKlaimSales();
    }
  }, [klaimSalesListId]);

  const getDetailKlaimSales = async () => {
    try {
      setError(null);
      setLoading(true);

      const response: KlaimSalesDetailModelData | undefined =
        await KlaimSalesProvider.getDetailKlaimSales({ id: klaimSalesListId! });

      setData(response!);
      setLoading(false);
    } catch (error) {
      setError(error as ErrorResponse);
      setLoading(false);
    }
  };

  const refetchGetDetailKlaimSales = () => {
    getDetailKlaimSales();
  };

  return {
    refetchGetDetailKlaimSales,
    detailKlaimSalesData,
    loading,
    errorResponse,
  };
};

export default useGetDetailKlaimSales;
