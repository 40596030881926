import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import FilterAreaMonitorKunjunganUserList from "./FilterAreaMonitorKunjunganUserList";
import DesktopTableListUser from "./desktop/DesktopTableListUser";
import MobileTableListUser from "./mobile/MobileTableListUser";
import AxiosBaseClient from "../../../../../dataProviders/config/AxiosBaseClient";
import { EXODUS_API_VERSION_1 } from "../../../../../constants/common/RootConstants";
import MonitorKunjunganUserListContext from "../context/MonitorKunjunganUserListContext";
import { useContext } from "react";
import React from "react";
import useGetAllPeriodeList from "../../../services/useGetAllPeriodeList";
import AllPeriodeDataModel from "../../../models/AllPeriodeDataModel";
import DropdownAllPeriode from "../../../components/DropdownAllPeriode";

const MonitorKunjunganUserListPage: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const [isOpenExcel, setIsOpenExcel] = React.useState<boolean>(false);
  const [selectedPeriode, setSelectedPeriode] = React.useState<any>(null);
  const [isLoadingExcel, setIsLoadingExcel] = React.useState<boolean>(false);

  const { savedFilter, userData } = useContext(MonitorKunjunganUserListContext);

  const { allPeriodeData, loading } = useGetAllPeriodeList();

  const handleOnChangePeriode = (value: AllPeriodeDataModel) => {
    setSelectedPeriode(value);
  };

  const handleOnClickExportExcel = async () => {
    try {
      setIsLoadingExcel(true);
      const url = `${EXODUS_API_VERSION_1}/monitor/header/excel`;

      const defaultUsers: Array<number> = [];
      userData.map((ud) => {
        return defaultUsers.push(ud.id);
      });

      const userIds = savedFilter?.user
        ? [savedFilter?.user?.id]
        : defaultUsers;

      const body = {
        start_period: selectedPeriode.start_period.getEpochDate(),
        end_period: selectedPeriode.end_period.getEpochDate(),
        user_id: userIds,
      };
      const response = await AxiosBaseClient.post(url, body);

      const excelUrl = response.data.data;
      const link = document.createElement("a");
      link.href = excelUrl;

      const splittedUrl = excelUrl.split("/");
      const filename = splittedUrl[splittedUrl.length - 1];

      link.download = filename;
      link.click();

      toast({
        title: "Sukses Mengunduh Excel.",
        description: `${filename}`,
        status: "success",
      });
      console.log(response);
    } catch (error) {
      console.log(error);
      toast({
        title: "Gagal Mengunduh Excel",
        status: "error",
      });
    } finally {
      setIsLoadingExcel(false);
    }
  };

  return (
    <Box>
      <PageTitle label="Monitor Kunjungan" />

      <Box width="100%" display="flex" justifyContent="flex-end">
        <Button
          variant="solid"
          colorScheme="buttonPrimary"
          fontSize={isDesktopView ? 16 : 14}
          onClick={() => setIsOpenExcel(true)}
          disabled={loading}
        >
          Export Excel
        </Button>
      </Box>

      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        blockScrollOnMount
        isCentered
        isOpen={isOpenExcel}
        onClose={() => setIsOpenExcel(false)}
        scrollBehavior="outside"
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent marginX="40px">
          <ModalHeader
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text
              color={theme.colors.exodus.primaryBlue}
              fontSize={24}
              fontWeight="semibold"
            >
              Export Excel
            </Text>
          </ModalHeader>
          <ModalBody>
            <DropdownAllPeriode
              options={allPeriodeData}
              value={selectedPeriode}
              onChange={(data: AllPeriodeDataModel | null) =>
                handleOnChangePeriode(data!)
              }
            />
            <Flex
              paddingBottom="16px"
              marginTop="20px"
              direction={isDesktopView ? "row-reverse" : "column"}
              gap={!isDesktopView ? 0 : 5}
            >
              <Button
                width="100%"
                colorScheme="buttonPrimary"
                onClick={handleOnClickExportExcel}
                isLoading={isLoadingExcel}
              >
                Submit
              </Button>
              <Button
                variant="outline"
                width="100%"
                marginTop={!isDesktopView ? "12px" : 0}
                colorScheme="buttonSecondary"
                onClick={() => setIsOpenExcel(false)}
                isLoading={isLoadingExcel}
              >
                Close
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <FilterAreaMonitorKunjunganUserList />
      {isDesktopView && <DesktopTableListUser />}
      {!isDesktopView && <MobileTableListUser />}
    </Box>
  );
};

export default MonitorKunjunganUserListPage;
