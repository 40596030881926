import { useMediaQuery, useTheme } from "@chakra-ui/react";
import BiayaMurniPsspNonDokterPageMobile from "./components/mobile/BiayaMurniPsspNonDokterPageMobile";
import BiayaMurniPsspNonDokterPageDesktop from "./components/desktop/BiayaMurniPsspNonDokterPageDesktop";
import { BiayaMurniFilterContextProvider } from "../../contexts/BiayaMurniFilterContext";
import useTitle from "../../../../services/useTitle";
import { BiayaMurniListContextProvider } from "../../contexts/BiayaMurniListContext";

const BiayaMurniPsspNonDokterPage = (): JSX.Element => {
  useTitle("Biaya Murni - PSSP Non-Dokter");
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <BiayaMurniFilterContextProvider>
      <BiayaMurniListContextProvider featureType="pssp-non-doctor">
        {isDesktopView ? (
          <BiayaMurniPsspNonDokterPageDesktop />
        ) : (
          <BiayaMurniPsspNonDokterPageMobile />
        )}
      </BiayaMurniListContextProvider>
    </BiayaMurniFilterContextProvider>
  );
};

export default BiayaMurniPsspNonDokterPage;
