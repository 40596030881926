import { Button, useMediaQuery, useTheme, useToast } from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import { DraftRencanaKunjunganModelData } from "../models/DraftRencanaKunjunganModel";
import { useContext, useState } from "react";
import DialogEditRencanaKunjungan from "./DialogEditRencanaKunjungan";
import DraftRencanaKunjunganUpdateBody from "../models/UpdateDraftRencanaKunjunganModel";
import useUpdateDraftRencanaKunjungan from "../services/useUpdateDraftRencanaKunjungan";
import { useNavigate } from "react-router-dom";
import InputRencanaKunjunganContext from "../context/InputRencanaKunjunganContext";
import DateExodus from "../../../../../models/DateExodus";
import { CommonStatus } from "../../../../../models/common/Status";

type Props = {
  data: DraftRencanaKunjunganModelData;
};

const ButtonEditRencanaKunjungan: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();
  const navigate = useNavigate();

  const [openDialogEdit, setOpenDialogEdit] = useState<boolean>(false);

  const { selectedPeriode, key, setKey } = useContext(
    InputRencanaKunjunganContext
  );

  const { updateDraftRencanaKunjungan, loading } =
    useUpdateDraftRencanaKunjungan();

  const handleSubmitUpdate = (value: DraftRencanaKunjunganUpdateBody) => {
    const submittedData: DraftRencanaKunjunganUpdateBody = {
      position_customer: value.position_customer,
      outlet_id: value.outlet_id,
      customer_id: value.customer_id,
      visit_header_id: props.data.visitHeaderId,
      purpose:
        value.purpose === "Lain-lain (diisi sendiri)"
          ? value.tujuan_lain!.trim()
          : value.purpose,
      total_visit: parseInt(value.total_visit.toString()),
    };

    updateDraftRencanaKunjungan(props.data.id, submittedData, {
      onSuccess: () => {
        toast({
          title: "Berhasil Mengubah Draft Rencana Kunjungan",
          status: "success",
        });
        setOpenDialogEdit(false);
        navigate("/rencana-kunjungan", { replace: true });
        setKey(key + 1);
      },
      onError: (errorMessage: string) => {
        toast({
          title: `Gagal Mengubah Draft Rencana Kunjungan`,
          description: `${errorMessage}`,
          status: "error",
        });
        setOpenDialogEdit(false);
      },
    });
  };

  const disableButtonEditCondition = (): boolean => {
    if (!selectedPeriode) return true;

    const dateNow = new DateExodus();
    const isDateNowGreaterThanSelectedStartPeriod: boolean =
      parseInt(JSON.stringify(dateNow)) >
      parseInt(JSON.stringify(selectedPeriode.start_period));

    if (selectedPeriode.status_plan !== CommonStatus.Pending) {
      return true;
    } else if (isDateNowGreaterThanSelectedStartPeriod) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Button
        variant="ghost"
        leftIcon={
          <MdEdit
            style={{
              width: !isDesktopView ? "20px" : "24px",
              height: !isDesktopView ? "20px" : "24px",
            }}
          />
        }
        isDisabled={disableButtonEditCondition()}
        onClick={() => {
          setOpenDialogEdit(true);
        }}
        sx={{
          fontSize: !isDesktopView ? 12 : 14,
          fontWeight: "bold",
          color: theme.colors.exodus.secondaryBlue,
          paddingX: !isDesktopView ? "8px" : 0,
        }}
      >
        Edit
      </Button>

      <DialogEditRencanaKunjungan
        open={openDialogEdit}
        onClose={() => setOpenDialogEdit(false)}
        data={props.data}
        onSubmit={handleSubmitUpdate}
        isLoadingSubmit={loading}
      />
    </>
  );
};

export default ButtonEditRencanaKunjungan;
