import { Button } from "@chakra-ui/react";
import { MouseEventHandler } from "react";
import { MdOutlineFileDownload } from "react-icons/md";

const ExportExcelButton = (props: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  isDisabled?: boolean;
  fullWidth?: boolean;
}): JSX.Element => {
  return (
    <Button
      width={props.fullWidth ? "100%" : undefined}
      colorScheme="buttonSecondary"
      variant={"outline"}
      leftIcon={<MdOutlineFileDownload />}
      onClick={props.onClick}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
    >
      Export to Excel
    </Button>
  );
};

export default ExportExcelButton;
