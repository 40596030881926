import { useNavigate, useParams } from "react-router-dom";
import MonitorKunjunganMonitorListPage from "./MonitorKunjunganMonitorListPage";
import { useEffect, useState } from "react";
import MonitorKunjunganMonitorListContext from "../context/MonitorKunjunganMonitorListContext";
import useGetAllPeriodeList from "../../../services/useGetAllPeriodeList";
import useGetMonitorKunjunganList from "../../services/useGetMonitorKunjunganList";
import useFilterStateMonitorKunjunganMonitorList from "../service/useFilterStateMonitorKunjunganMonitorList";
import useTitle from "../../../../../services/useTitle";

const MonitorKunjunganMonitorListBase: React.FC = () => {
  const [currentPsspType, setCurrentPsspType] = useState<string>("");

  const { page } = useParams();
  const navigate = useNavigate();

  const {
    savedFilter,
    selectedPeriode,
    handleChangeSelectedPeriode,
    selectedProject,
    handleChangeSelectedProject,
  } = useFilterStateMonitorKunjunganMonitorList();

  const {
    allPeriodeData,
    loading: loadingAllPeriode,
    errorMessage: errorMessageAllPeriode,
  } = useGetAllPeriodeList();

  const {
    monitorKunjunganData,
    loading: loadingMonitorKunjungan,
    errorMessage: errorMessageMonitorKunjungan,
  } = useGetMonitorKunjunganList({
    isPsspActive: currentPsspType === "pssp-tidak-aktif" ? false : true,
    user_id: savedFilter?.selectedBawahanId
      ? savedFilter.selectedBawahanId
      : undefined,
    start_period: selectedPeriode?.start_period,
    end_period: selectedPeriode?.end_period,
    project: selectedProject,
  });

  useEffect(() => {
    if (page === "pssp-aktif") {
      setCurrentPsspType("pssp-aktif");
    } else if (page === "pssp-tidak-aktif") {
      setCurrentPsspType("pssp-tidak-aktif");
    } else {
      setCurrentPsspType("pssp-aktif");
      navigate("/monitor-kunjungan/pssp-aktif", { replace: true });
    }
  }, [navigate, page]);

  useEffect(() => {
    if (allPeriodeData.length > 0) {
      if (!selectedPeriode) {
        handleChangeSelectedPeriode(allPeriodeData[0]);
      } else {
        handleChangeSelectedPeriode(selectedPeriode);
      }
    }
  }, [allPeriodeData, handleChangeSelectedPeriode, selectedPeriode]);

  useTitle("Monitor Kunjungan");

  return (
    <MonitorKunjunganMonitorListContext.Provider
      value={{
        allPeriodeData: allPeriodeData,
        loadingAllPeriode: loadingAllPeriode,
        errorMessageAllPeriode: errorMessageAllPeriode,
        monitorKunjunganData: monitorKunjunganData,
        loadingMonitorKunjungan: loadingMonitorKunjungan,
        errorMessageMonitorKunjungan: errorMessageMonitorKunjungan,
        selectedPeriode: selectedPeriode,
        handleChangeSelectedPeriode: handleChangeSelectedPeriode,
        selectedProject: selectedProject,
        handleChangeSelectedProject: handleChangeSelectedProject,
        savedFilter: savedFilter,
        currentPsspType: currentPsspType,
        setCurrentPsspType: setCurrentPsspType,
      }}
    >
      <MonitorKunjunganMonitorListPage />
    </MonitorKunjunganMonitorListContext.Provider>
  );
};

export default MonitorKunjunganMonitorListBase;
