/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import AllPeriodeDataModel from "../models/AllPeriodeDataModel";
import ActivityGeneralProvider from "../dataProviders/ActivityGeneralProvider";

const useGetAllPeriodeList = () => {
  const [allPeriodeData, setData] = useState<AllPeriodeDataModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: AllPeriodeDataModel[] | undefined =
          await ActivityGeneralProvider.getAllPeriodeList();

        setData(responseData!);

        setLoading(false);
      } catch (error: any) {
        setData([]);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    getData();
  }, []);

  return { allPeriodeData, loading, errorMessage };
};

export default useGetAllPeriodeList;
