import { createContext, useContext } from "react";
import ApiGetListSalesOutletResponse from "../models/apiResponses/ApiGetListSalesOutletResponse";
import ErrorResponse from "../../../../../../models/common/ErrorResponse";
import SalesOutletListFilterModel from "../models/SalesOutletListFilterModel";
import useList, { LoadingType } from "../services/dataFetching/useList";
import SalesOutletSavedPaginationMetadata from "../../../models/SalesOutletSavedPaginationMetadata";

type _Value = {
  states: {
    data: ApiGetListSalesOutletResponse | null;
    filterValues: SalesOutletListFilterModel | null;
    metadataValues: SalesOutletSavedPaginationMetadata | null;
    loading: LoadingType | null;
    error: ErrorResponse | null;
  };
  actions: {
    changeFilter: (filterValues: SalesOutletListFilterModel) => Promise<void>;
    setLimit: (limit: number) => void;
    setPage: (value: "next" | "prev" | "first") => void;
  };
};

const SalesOutletListContext = createContext<_Value>({
  states: {
    data: null,
    filterValues: null,
    metadataValues: null,
    loading: null,
    error: null,
  },
  actions: {
    changeFilter: () => Promise.prototype,
    setLimit: () => {},
    setPage: () => {},
  },
});

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{}>;

function useSalesOutletListContext(): _Value {
  return useContext(SalesOutletListContext);
}

const SalesOutletListContextProvider = ({
  children,
}: _InternalProps): JSX.Element => {
  const {
    data,
    filterValues,
    metadataValues,
    loading,
    error,
    changeFilter,
    setLimit,
    setPage,
  } = useList();

  const value: _Value = {
    states: {
      data,
      filterValues,
      metadataValues,
      loading,
      error,
    },
    actions: {
      changeFilter,
      setLimit,
      setPage,
    },
  };

  return (
    <SalesOutletListContext.Provider value={value}>
      {children}
    </SalesOutletListContext.Provider>
  );
};

export { SalesOutletListContextProvider, useSalesOutletListContext };
