import { createContext, useContext } from "react";
import { BiayaMurniFeatureType } from "../models/BiayaMurniFeatureType";
import BiayaMurniSavedListMetadata from "../models/BiayaMurniSavedListMetadata";
import { ApiGetListBiayaMurniResponse } from "../models/apiResponses/ApiGetListBiayaMurniResponse";
import useGetList from "../services/dataFetchings/useGetList";
import useListMetadata from "../services/useListMetadata";
import { useBiayaMurniFilterContext } from "./BiayaMurniFilterContext";
import ErrorResponse from "../../../models/common/ErrorResponse";

type _Value = {
  states: {
    data: ApiGetListBiayaMurniResponse | null;
    isLoading: boolean;
    error: ErrorResponse | null;
  };
  actions: {
    setLimit: (limit: number) => void;
    setPage: (value: "next" | "prev" | "first") => void;
  };
};

const BiayaMurniListContext = createContext<_Value>({
  states: {
    data: null,
    isLoading: false,
    error: null,
  },
  actions: {
    setLimit: () => {},
    setPage: () => {},
  },
});

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{
  featureType: BiayaMurniFeatureType;
}>;

function useBiayaMurniListContext(): _Value {
  return useContext(BiayaMurniListContext);
}

const BiayaMurniListContextProvider = ({
  children,
  featureType,
}: _InternalProps): JSX.Element => {
  const {
    states: { filterValues },
  } = useBiayaMurniFilterContext();

  const { metadataValues, saveMetadata } = useListMetadata({
    featureType: featureType,
  });

  const { data, isLoading, error } = useGetList({
    featureType: featureType,
    startDate: filterValues?.periode.awal.toJSON(),
    endDate: filterValues?.periode.akhir.toJSON(),
    userId: filterValues?.pekerja?.id,
    project: filterValues?.project,
    page: metadataValues?.page,
    limit: metadataValues?.limit,
  });

  const setPage = (value: "next" | "prev" | "first") => {
    let page: number = 0;
    if (metadataValues) {
      if (value === "next") {
        page = metadataValues.page + 1;
      }
      if (value === "prev") {
        page = metadataValues.page - 1;
      }
      if (value === "first") {
        page = 1;
      }
      saveMetadata(
        new BiayaMurniSavedListMetadata({
          featureType: featureType,
          limit: metadataValues.limit,
          page: page,
        })
      );
    }
  };

  const setLimit = (limit: number) => {
    if (metadataValues) {
      saveMetadata(
        new BiayaMurniSavedListMetadata({
          featureType: featureType,
          limit: limit,
          page: 1,
        })
      );
    }
  };

  const value: _Value = {
    states: { data, isLoading, error },
    actions: { setPage, setLimit },
  };

  return (
    <BiayaMurniListContext.Provider value={value}>
      {children}
    </BiayaMurniListContext.Provider>
  );
};

export { BiayaMurniListContextProvider, useBiayaMurniListContext };
