import DateExodus from "../../../models/DateExodus";
import RoleModel from "../../../models/Users/RoleModel";
import UserModel from "../../../models/Users/UserModel";
import ProjectType from "../../../models/common/ProjectType";

type ConstructorProps = {
  jabatan: RoleModel | null | undefined;
  bawahan: UserModel | null | undefined;
  projectAvailable: ProjectType | null | undefined;
  periodeTahun: any;
  periodeAwal: any;
  periodeAkhir: any;
};

class MonitoringFilterValues {
  jabatan: RoleModel | null | undefined;
  bawahan: UserModel | null | undefined;
  projectAvailable: ProjectType | null | undefined;
  periodeTahun: DateExodus;
  periodeAwal: DateExodus;
  periodeAkhir: DateExodus;

  constructor({
    jabatan,
    bawahan,
    projectAvailable,
    periodeTahun,
    periodeAwal,
    periodeAkhir,
  }: ConstructorProps) {
    this.jabatan = jabatan;
    this.bawahan = bawahan;
    this.projectAvailable = projectAvailable;
    this.periodeTahun =
      periodeTahun instanceof DateExodus
        ? periodeTahun
        : new DateExodus(periodeTahun);
    this.periodeAwal =
      periodeAwal instanceof DateExodus
        ? periodeAwal
        : new DateExodus(periodeAwal);
    this.periodeAkhir =
      periodeAkhir instanceof DateExodus
        ? periodeAkhir
        : new DateExodus(periodeAkhir);
  }
}

export default MonitoringFilterValues;
