import { useContext, useState } from "react";
import { Box, Flex, IconButton, useToast } from "@chakra-ui/react";
import DropdownPeriodeByUserId from "../../../../components/DropdownPeriodeByUserId";
import InputRencanaKunjunganContext from "../../context/InputRencanaKunjunganContext";
import { MdAdd } from "react-icons/md";
import DialogInputRencanaKunjungan from "../DialogInputRencanaKunjungan";
import { DraftRencanaKunjunganCreateBody } from "../../models/CreateDraftRencanaKunjunganModel";
import { useNavigate } from "react-router-dom";
import useCreateDraftRencanaKunjungan from "../../services/useCreateDraftRencanaKunjungan";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../redux/hooks";
import DateExodus from "../../../../../../models/DateExodus";
import { CommonStatus } from "../../../../../../models/common/Status";

const MobileInputRencanaKunjunganToolbar: React.FC = () => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const toast = useToast();
  const navigate = useNavigate();

  const { periodeData, selectedPeriode, setSelectedPeriode, key, setKey } =
    useContext(InputRencanaKunjunganContext);

  const [openDialogInput, setOpenDialogInput] = useState<boolean>(false);

  const { createDraftRencanaKunjungan, loading } =
    useCreateDraftRencanaKunjungan();

  const handleSubmitCreate = (value: DraftRencanaKunjunganCreateBody) => {
    const startPeriod = JSON.stringify(selectedPeriode?.start_period);
    const endPeriod = JSON.stringify(selectedPeriode?.end_period);

    const submittedData: DraftRencanaKunjunganCreateBody = {
      user_id: loggedUser!.userId,
      position_customer: value.position_customer,
      start_date: parseInt(startPeriod),
      end_date: parseInt(endPeriod),
      outlet_id: value.outlet_id,
      customer_id: value.customer_id,
      visit_header_id: selectedPeriode!.header_id,
      purpose:
        value.purpose === "Lain-lain (diisi sendiri)"
          ? value.tujuan_lain!.trim()
          : value.purpose,
      total_visit: parseInt(value.total_visit.toString()),
    };

    createDraftRencanaKunjungan(submittedData, {
      onSuccess: () => {
        toast({
          title: "Berhasil Menginput Draft Rencana Kunjungan",
          status: "success",
        });
        setOpenDialogInput(false);
        navigate("/rencana-kunjungan", { replace: true });
        setKey(key + 1);
      },
      onError: (errorMessage: string) => {
        toast({
          title: `Gagal Menginput Draft Rencana Kunjungan`,
          description: `${errorMessage}`,
          status: "error",
        });
      },
    });
  };

  const disableButtonCreateCondition = (): boolean => {
    if (!selectedPeriode) return true;

    const dateNow = new DateExodus();
    const isDateNowGreaterThanSelectedStartPeriod: boolean =
      parseInt(JSON.stringify(dateNow)) >
      parseInt(JSON.stringify(selectedPeriode.start_period));

    if (selectedPeriode.status_plan !== CommonStatus.Pending) {
      return true;
    } else if (isDateNowGreaterThanSelectedStartPeriod) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Flex paddingY="30px" justify="space-between" align="center" gap="4px">
        <Box width="85%">
          <DropdownPeriodeByUserId
            options={periodeData}
            value={selectedPeriode}
            onChange={setSelectedPeriode}
          />
        </Box>
        <IconButton
          aria-label="add"
          variant="solid"
          isRound
          colorScheme="buttonPrimary"
          fontSize="24px"
          size="lg"
          icon={<MdAdd />}
          onClick={() => setOpenDialogInput(true)}
          isDisabled={disableButtonCreateCondition()}
        />
      </Flex>

      <DialogInputRencanaKunjungan
        open={openDialogInput}
        onClose={() => setOpenDialogInput(false)}
        onSubmit={handleSubmitCreate}
        isLoadingSubmit={loading}
      />
    </>
  );
};

export default MobileInputRencanaKunjunganToolbar;
