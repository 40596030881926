import { Stack } from "@chakra-ui/react";
import { useOutletCardListContext } from "../../contexts/OutletCardListContextProvider";
import FilterBox from "../../../../../../components/FilterBox/FilterBox";

const FilterBoxArea = () => {
  const {
    states: { filterValues },
  } = useOutletCardListContext();

  return (
    <Stack
      flexDirection={"row"}
      overflow={"scroll"}
      maxWidth={"70%"}
      flexWrap={"wrap"}
      overflowWrap={"break-word"}
      maxHeight={"150px"}
      css={{
        "&::-webkit-scrollbar": {
          width: "0px",
          background: "transparent",
          display: "none",
        },
      }}
    >
      {filterValues?.endDate && filterValues.startDate && (
        <FilterBox
          title={"Periode"}
          content={`${filterValues.startDate.getStringDateFormat(
            "MMMM YYYY"
          )} - ${filterValues.endDate.getStringDateFormat("MMMM YYYY")}`}
        />
      )}
      {filterValues?.sector && (
        <FilterBox title={"Sektor"} content={filterValues.sector.label} />
      )}
      {filterValues?.zone && (
        <FilterBox title={"Zona"} content={filterValues.zone.salesZoneName} />
      )}
      {filterValues?.bawahan && (
        <FilterBox
          title={`Nama ${filterValues?.role?.name.toUpperCase() ?? null}`}
          content={filterValues.bawahan.name}
        />
      )}
      {filterValues?.outlet && (
        <FilterBox title={"Outlet"} content={filterValues.outlet.outletName} />
      )}
      {filterValues?.project && (
        <FilterBox title={"Project"} content={filterValues.project.label} />
      )}
    </Stack>
  );
};

export default FilterBoxArea;
