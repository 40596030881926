import {
  Grid,
  useTheme,
  Text,
  GridItem,
  Box,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import HeaderRecordApprovalRealisasiContext from "../../context/HeaderRecordApprovalRealisasiContext";
import { RealisasiStatus } from "../../models/ApprovalRealisasiStatus";
import DialogApproveRejectRealisasiKunjungan from "../DialogApproveRejectRealisasiKunjungan";
import { useAppSelector } from "../../../../../../redux/hooks";
import { ROLE_ID_TRADE_TEAM } from "../../../../../../constants/common/RoleConstants";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import useUpdateStatusRealisasi from "../../services/useUpdateStatusRealisasi";
import DialogConfirmationApproveRejectRealisasiKunjungan from "../DialogConfirmationApproveRejectRealisasiKunjungan";
import { UpdateStatusRealisasiBodyModel } from "../../models/UpdateStatusRealisasiBodyModel";

const DesktopDetailApprovalRealisasiBasicInformation: React.FC = () => {
  const theme = useTheme();
  const {
    headerData: record,
    setKey,
    key,
  } = useContext(HeaderRecordApprovalRealisasiContext);
  const toast = useToast();
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state?.authReducer.loggedUser
  );

  const [isReject, setIsReject] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [openComment, setOpenComment] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const { updateStatusRealisasi, loading } = useUpdateStatusRealisasi();
  const [status, setStatus] = useState<string>("");
  const [submittedData, setSubmittedData] =
    useState<UpdateStatusRealisasiBodyModel | null>(null);

  //========================================== Functions ========================================================
  const allowApproveReject = (): boolean => {
    if (loggedUser?.userRoleId === ROLE_ID_TRADE_TEAM) return false;

    if (record?.approvalRealisasiStatus === RealisasiStatus.Approved) {
      return false;
    } else {
      return true;
    }
  };

  const setApproveAndRejectButtonDisable = (): boolean => {
    if (record?.approvalRealisasiStatus === RealisasiStatus.Request) {
      return false;
    } else return true;
  };

  const onClickApprove = () => {
    setIsReject(false);
    for (let i = 0; i < record!.detailRealisasi.length; i++) {
      if (
        record!.detailRealisasi[i].komentarKunjungan === null ||
        record!.detailRealisasi[i].komentarKunjungan === ""
      ) {
        toast({
          title: `${record?.userName} belum melengkapi semua realisasi kunjungan`,
          status: "error",
        });

        return;
      }
    }

    setOpenComment(true);
  };

  const onClickReject = () => {
    setIsReject(true);
    setOpenComment(true);
  };

  const handleApproveReject = (data: any) => {
    const temp: any = {};
    if (!isReject) {
      temp["status"] = "Approved";
      temp["senior_comment"] = data.comment.trim();
      setStatus("Approve");
      setSubmittedData(temp);
    } else {
      temp["status"] = "Pending";
      temp["reason"] = data.comment.trim();
      setStatus("Reject");
      setSubmittedData(temp);
    }
    setOpenConfirmation(true);
  };

  const handleSubmitConfirmation = () => {
    if (record && submittedData) {
      updateStatusRealisasi(record.id, submittedData, {
        onSuccess: () => {
          setComment("");
          toast({
            title: `Berhasil ${status} Realisasi Kunjungan`,
            status: "success",
          });
          setOpenConfirmation(false);
          setOpenComment(false);
          setKey(key + 1);
        },
        onError: (errorMessage: string) => {
          setComment("");
          toast({
            title: `Gagal ${status} Realisasi Kunjungan.`,
            description: `${errorMessage}`,
            status: "error",
          });
          setOpenConfirmation(false);
          setOpenComment(false);
          setKey(key + 1);
        },
      });
    }
  };

  if (!record) return null;
  return (
    <>
      <Grid
        templateColumns="repeat(10, 1fr)"
        gap={1}
        marginTop="16px"
        justifyContent="space-between"
      >
        <GridItem colSpan={7}>
          <Grid
            templateColumns="repeat(12, 1fr)"
            marginBottom="8px"
            textAlign={"left"}
          >
            <GridItem colSpan={3}>
              <Text
                fontSize={16}
                fontWeight="bold"
                color={theme.colors.exodus.primaryBlue}
              >
                Field Force
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text textAlign={"center"} fontSize={16}>
                :
              </Text>
            </GridItem>
            <GridItem colSpan={7}>
              <Text fontSize={16}>
                {record.userName} {`(${record.nip})`}
              </Text>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(12, 1fr)"
            marginBottom="8px"
            textAlign={"left"}
          >
            <GridItem colSpan={3}>
              <Text
                fontSize={16}
                fontWeight="bold"
                color={theme.colors.exodus.primaryBlue}
              >
                Jabatan
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text textAlign={"center"} fontSize={16}>
                :
              </Text>
            </GridItem>
            <GridItem colSpan={7}>
              <Text fontSize={16}>{record.roleLabel}</Text>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(12, 1fr)"
            marginBottom="8px"
            textAlign={"left"}
          >
            <GridItem colSpan={3}>
              <Text
                fontSize={16}
                fontWeight="bold"
                color={theme.colors.exodus.primaryBlue}
              >
                Periode Rencana Kunjungan
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text textAlign={"center"} fontSize={16}>
                :
              </Text>
            </GridItem>
            <GridItem colSpan={7}>
              <Text fontSize={16}>{`${record.startDate.getStringDateFormat(
                "D MMMM YYYY"
              )} - ${record.endDate.getStringDateFormat("D MMMM YYYY")}`}</Text>
            </GridItem>
          </Grid>
          {record.komentarAtasan !== null && (
            <Grid
              templateColumns="repeat(12, 1fr)"
              marginBottom="8px"
              textAlign={"left"}
            >
              <GridItem colSpan={3}>
                <Text
                  fontSize={16}
                  fontWeight="bold"
                  color={theme.colors.exodus.primaryBlue}
                >
                  Komentar Atasan
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Text textAlign={"center"} fontSize={16}>
                  :
                </Text>
              </GridItem>
              <GridItem colSpan={7}>
                <Text fontSize={16}>{record.komentarAtasan}</Text>
              </GridItem>
            </Grid>
          )}
        </GridItem>
        {allowApproveReject() && (
          <GridItem colSpan={3} textAlign={"right"}>
            <Box display="flex" flexDirection="row-reverse" gap={3}>
              <Button
                type="submit"
                width="100%"
                colorScheme="buttonSuccess"
                onClick={onClickApprove}
                isDisabled={setApproveAndRejectButtonDisable()}
              >
                Approve
              </Button>
              <Button
                width="100%"
                variant="outline"
                colorScheme="buttonError"
                color={theme.colors.exodus.red}
                borderColor={theme.colors.exodus.red}
                onClick={onClickReject}
                isDisabled={setApproveAndRejectButtonDisable()}
              >
                Reject
              </Button>
            </Box>
          </GridItem>
        )}
      </Grid>

      <DialogApproveRejectRealisasiKunjungan
        comment={comment}
        openComment={openComment}
        onClose={() => setOpenComment(false)}
        isReject={isReject}
        loading={loading}
        onSubmit={handleApproveReject}
      />

      <DialogConfirmationApproveRejectRealisasiKunjungan
        loading={loading}
        onClose={() => setOpenConfirmation(false)}
        openConfirmation={openConfirmation}
        onSubmit={handleSubmitConfirmation}
        isReject={isReject}
      />
    </>
  );
};

export default DesktopDetailApprovalRealisasiBasicInformation;
