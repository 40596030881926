import { Box } from "@chakra-ui/react";
import React from "react";
import DistributorLastUpdateCard from "./DistributorLastUpdateCard";
import TargetSalesCard from "./TargetSalesCard";
import BiayaMurniSalesRatioCard from "./BiayaMurniSalesRatioCard";
import BudgetNasionalCard from "./BudgetNasionalCard";
import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";
import {
  ROLE_ID_ASM,
  ROLE_ID_SUPER_ADMIN,
} from "../../../constants/common/RoleConstants";

const DesktopDashboardComponent: React.FC = () => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  return (
    <Box display={"flex"} gap={"20px"} flexDirection={"column"}>
      <Box display={"flex"} gap={"20px"}>
        <BiayaMurniSalesRatioCard />
        <BudgetNasionalCard />
      </Box>
      <Box display={"flex"} gap={"20px"}>
        <Box width={"50%"}>
          <DistributorLastUpdateCard />
        </Box>
        <Box width={"50%"}>
          {(loggedUser?.userRoleId === ROLE_ID_ASM ||
            loggedUser?.userRoleId === ROLE_ID_SUPER_ADMIN) && (
            <TargetSalesCard />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopDashboardComponent;
