import { FormControl, Input } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import ApprovalKlaimSalesContext from "../../../contexts/ApprovalKlaimSalesContext";
import { isEmpty } from "lodash";

const OutletTujuanFormFieldKlaimSales: React.FC = () => {
  const { register, setValue, watch } = useFormContext();
  const watchId = watch("idKlaimSales");
  const { filterValues } = useContext(ApprovalKlaimSalesContext);

  useEffect(() => {
    setValue("outletTujuan", filterValues?.outletTargetName?.trim());
  }, []);

  return (
    <FormControl width={"100%"}>
      <FormInputLabel title="Outlet Tujuan" />
      <Input
        {...register("outletTujuan")}
        placeholder="Nama Outlet Tujuan"
        defaultValue={filterValues?.outletTargetName?.trim()}
        isDisabled={!isEmpty(watchId)}
      />
    </FormControl>
  );
};

export default OutletTujuanFormFieldKlaimSales;
