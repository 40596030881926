import useTitle from "../../../../../services/useTitle";
import useGetUserChildNonVacantMonitorKunjungan from "../../services/useGetUserChildNonVacantMonitorKunjungan";
import MonitorKunjunganUserListContext from "../context/MonitorKunjunganUserListContext";
import useFilterStateMonitorKunjunganUserList from "../service/useFilterStateMonitorKunjunganUserList";
import MonitorKunjunganUserListPage from "./MonitorKunjunganUserListPage";

const MonitorKunjunganUserListBase: React.FC = () => {
  const {
    savedFilter,
    saveFilterValueToIndexedDB,
    saveSelectedBawahanToIndexedDB,
    resetSavedFilterValue,
  } = useFilterStateMonitorKunjunganUserList();

  const {
    userData,
    loading: userLoading,
    errorMessage: userErrorMessage,
  } = useGetUserChildNonVacantMonitorKunjungan(savedFilter?.user?.id);

  useTitle("Monitor Kunjungan");

  return (
    <MonitorKunjunganUserListContext.Provider
      value={{
        userData: userData,
        userLoading: userLoading,
        userErrorMessage: userErrorMessage,
        savedFilter: savedFilter,
        saveFilterValueToIndexedDB: saveFilterValueToIndexedDB,
        saveSelectedBawahanToIndexedDB: saveSelectedBawahanToIndexedDB,
        resetSavedFilterValue: resetSavedFilterValue,
      }}
    >
      <MonitorKunjunganUserListPage />
    </MonitorKunjunganUserListContext.Provider>
  );
};

export default MonitorKunjunganUserListBase;
