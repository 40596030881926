import { extendTheme, ThemeConfig, ThemeOverride } from "@chakra-ui/react";
import "@fontsource-variable/noto-sans";
import { AccordionTheme } from "./AccordionTheme";
import { ModalTheme } from "./ModalTheme";
import { RadioTheme } from "./RadioTheme";
import { StepperTheme } from "./StepperTheme";

const buttonSwatches = {
  // Used for solid button
  buttonPrimary: {
    50: "#C6E7F9",
    100: "#F1FDFF",
    500: "#0F4C82",
    600: "#0A366A",
    700: "#146BAA",
  },
  // Used for outline button
  buttonSecondary: {
    50: "#C6E7F9",
    100: "#F1FDFF",
    500: "#426EA4",
    600: "#426EA4",
    700: "#146BAA",
  },
  buttonSuccess: {
    50: "#E6F9C6",
    100: "#F9FFF1",
    500: "#4F7735",
    600: "#375826",
    700: "#82AF39",
  },
  buttonError: {
    50: "#F9CFC6",
    100: "#FFF1F1",
    500: "#B25650",
    600: "#912F1A",
    700: "#E2483E",
  },
};

const exodusColors = {
  exodus: {
    background: "#f8f8ff",
    darkGrey: "#666666",
    lightGrey: "#d9d9d9",
    primaryLight: "#bbcee4",
    primaryBlue: "#0f4c82",
    secondaryBlue: "#426ea4",
    red: "#b25550",
    yellow: "#d6961c",
    green: "#4f7735",
    approved: "#0f81e5",
    primaryBackground: "#bbcee4",
    white: "#ffffff",
    transferred: "#9747ff",
    fontDefault: "#666666",
    tableDataBackground: "#E6F0FC",
  },
  ...buttonSwatches,
};

const exodusBreakpoints = {
  tablet: "800px",
  smallMonitor: "1024px",
  largeMonitor: "1280px",
};

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  colors: exodusColors,
  breakpoints: exodusBreakpoints,
  components: {
    Accordion: AccordionTheme,
    Modal: ModalTheme,
    Radio: RadioTheme,
    Stepper: StepperTheme,
  },
  fonts: {
    heading: "Noto Sans Variable",
    body: "Noto Sans Variable",
    mono: "Noto Sans Variable",
  },
  styles: {
    global: {
      "html, body": {
        color: "#666666",
      },
    },
  },
} satisfies ThemeOverride);

export default theme;
