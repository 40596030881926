import { AxiosResponse, isAxiosError } from "axios";
import AxiosAuthClient from "../../../dataProviders/config/AxiosAuthClient";
import {
  AUTHENTICATION_API_ROOT,
  EXODUS_API_VERSION_1,
} from "../../../constants/common/RootConstants";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";
import { ChangePasswordPayloadType } from "../models/commonType";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

export const ProfileDataProvider = {
  changePassword: async (params: ChangePasswordPayloadType) => {
    try {
      const response: AxiosResponse = await AxiosAuthClient.post(
        `${EXODUS_API_VERSION_1}${AUTHENTICATION_API_ROOT}/change_password`,
        {
          Old_password: params.oldPassword,
          New_password: params.newPassword,
        }
      );

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const resolvedResponse = {
          status: response.status,
          message: response.data.message as string,
        };
        return Promise.resolve(resolvedResponse);
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
  logout: async () => {
    try {
      const response: AxiosResponse = await AxiosAuthClient.post(
        EXODUS_API_VERSION_1 + AUTHENTICATION_API_ROOT + "/logout"
      );
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const resolvedResponse = {
          status: response.status,
          message: response.data.message as string,
        };
        return Promise.resolve(resolvedResponse);
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
};
