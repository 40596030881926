/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import InputRencanaKunjunganContext from "../../context/InputRencanaKunjunganContext";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";
import { DraftRencanaKunjunganModelData } from "../../models/DraftRencanaKunjunganModel";
import { Box, Spinner, Text, useTheme } from "@chakra-ui/react";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import ButtonEditRencanaKunjungan from "../ButtonEditRencanaKunjungan";
import ButtonDeleteRencanaKunjungan from "../ButtonDeleteRencanaKunjungan";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import { TableColumn } from "react-data-table-component";

const DesktopTableDraftRencanaKunjungan: React.FC = () => {
  const theme = useTheme();

  const { draftRencanaKunjunganData, draftRencanaLoading, draftRencanaError } =
    useContext(InputRencanaKunjunganContext);

  const dataColumn: TableColumn<any>[] = [
    {
      name: "Nama Outlet",
      cell: (row: DraftRencanaKunjunganModelData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.outletName}
          bottomValue={row.outletCode}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "250px",
    },
    {
      name: "Nama Customer",
      cell: (row: DraftRencanaKunjunganModelData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.customerName}
          bottomValue={row.customerCode}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "250px",
    },
    {
      name: "Spesialis",
      // selector: (row: DraftRencanaKunjunganModelData) => row.customerSpecialist,
      cell: (row: DraftRencanaKunjunganModelData) => (
        <Text textAlign="left">{row.customerSpecialist}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "200px",
      maxWidth: "250px",
    },
    {
      name: "Jabatan",
      // selector: (row: DraftRencanaKunjunganModelData) => row.positionCustomer,
      cell: (row: DraftRencanaKunjunganModelData) => (
        <Text textAlign="left">{row.positionCustomer}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      maxWidth: "200px",
    },
    {
      name: "Kunjungan",
      selector: (row: DraftRencanaKunjunganModelData) =>
        `${row.totalVisit} kali`,
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      maxWidth: "150px",
    },
    {
      name: "Tujuan",
      // selector: (row: DraftRencanaKunjunganModelData) => row.purpose,
      cell: (row: DraftRencanaKunjunganModelData) => (
        <Text textAlign="left">{row.purpose}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "200px",
      maxWidth: "250px",
    },
    {
      name: "",
      cell: (row: DraftRencanaKunjunganModelData) => (
        <ButtonEditRencanaKunjungan data={row} />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      width: "100px",
    },
    {
      name: "",
      cell: (row: DraftRencanaKunjunganModelData) => (
        <ButtonDeleteRencanaKunjungan data={row} />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      width: "120px",
    },
  ];

  if (draftRencanaLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (draftRencanaError) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={draftRencanaError.toString()} />
      </Box>
    );
  }

  return draftRencanaKunjunganData.length > 0 ? (
    <Box marginTop="24px" marginBottom="15px">
      <TableDataComponent
        data={draftRencanaKunjunganData}
        tableHeader={dataColumn}
        onSelectChange={() => {}}
        paginationOn={false}
        paginationLimit={[]}
        checboxOn={false}
      />
    </Box>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default DesktopTableDraftRencanaKunjungan;
