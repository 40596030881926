import { useState, useEffect } from "react";
import { DetailApprovalRencanaKunjunganParams } from "../../dataProviders/RencanaKunjunganParamType";
import { DetailApprovalRencanaKunjunganModelData } from "../models/ApprovalRencanaKunjunganData";
import RencanaKunjunganProvider from "../../dataProviders/RencanaKunjunganProvider";

const useGetDetailApprovalRencanaKunjungan = (
  key: number,
  { id, user_id }: DetailApprovalRencanaKunjunganParams
) => {
  const [approvalRencanaData, setData] =
    useState<DetailApprovalRencanaKunjunganModelData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData =
          await RencanaKunjunganProvider.getDetailApprovalRencanaKunjungan({
            id: id,
            user_id: user_id,
          });

        setData(responseData!);
        setLoading(false);
      } catch (error: any) {
        setData(null);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    getData();
  }, [id, user_id, key]);

  return { approvalRencanaData, loading, errorMessage };
};

export default useGetDetailApprovalRencanaKunjungan;
