import { createContext, useContext, useEffect, useState } from "react";
import useDetailFilterAndMetadata from "../services/useDetailFilterAndMetadata";
import SalesOutletSavedPaginationMetadata from "../../../models/SalesOutletSavedPaginationMetadata";
import SalesOutletDetailFilterModel from "../models/SalesOutletDetailFilterModel";

type _Value = {
  states: {
    filterValues: SalesOutletDetailFilterModel | null;
    metadataValues: SalesOutletSavedPaginationMetadata | null;
    isLoading: boolean;
    error: string | null;
  };
  actions: {
    saveMetadata: Function;
  };
};

const SalesOutletDetailFilterContext = createContext<_Value>({
  states: {
    filterValues: null,
    metadataValues: null,
    isLoading: false,
    error: null,
  },
  actions: { saveMetadata: () => {} },
});

function useSalesOutletDetailFilterContext(): _Value {
  return useContext(SalesOutletDetailFilterContext);
}

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{}>;

const SalesOutletDetailFilterContextProvider = ({
  children,
}: _InternalProps): JSX.Element => {
  const {
    filterValues,
    isLoadingRigidFilter,
    errorRigidFilter,
    isLoadingMetadata,
    errorMetadata,
    metadataValues,
    saveMetadata,
  } = useDetailFilterAndMetadata();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isLoadingMetadata || isLoadingRigidFilter) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingMetadata, isLoadingRigidFilter]);

  useEffect(() => {
    if (errorMetadata === null && errorRigidFilter === null) {
      setError(null);
    } else {
      if (errorRigidFilter) {
        setError(errorRigidFilter);
      } else if (errorMetadata) {
        setError(errorMetadata.message);
      }
    }
  }, [errorMetadata, errorRigidFilter]);

  const value: _Value = {
    states: { filterValues, metadataValues, isLoading, error },
    actions: { saveMetadata },
  };

  return (
    <SalesOutletDetailFilterContext.Provider value={value}>
      {children}
    </SalesOutletDetailFilterContext.Provider>
  );
};

export {
  SalesOutletDetailFilterContextProvider,
  useSalesOutletDetailFilterContext,
};
