import { Button } from "@chakra-ui/react";
import { MouseEventHandler } from "react";
import { IoMdEye } from "react-icons/io";

const LihatDetailButton = (props: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}): JSX.Element => {
  return (
    <Button
      colorScheme="buttonSecondary"
      variant={"ghost"}
      leftIcon={<IoMdEye />}
      onClick={props.onClick}
    >
      Lihat Detail
    </Button>
  );
};

export default LihatDetailButton;
