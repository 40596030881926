import React, { useContext, useEffect, useState } from "react";
import useGetRoleByLoggedUser from "../../../../../../services/Users/useGetRoleByLoggedUser";
import useGetUserByRole from "../../../../../../services/Users/useGetUserByRole";
import { isEmpty } from "lodash";
import {
  Box,
  useMediaQuery,
  useTheme,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import RoleModel from "../../../../../../models/Users/RoleModel";
import UserModel from "../../../../../../models/Users/UserModel";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import MonitorSalesTargetContext from "../../context/MonitorSalesTargetContext";

const SalesTargetRoleBawahanFormField: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const {
    register,
    setValue,
    getValues,
    resetField,
    formState: { errors },
  } = useFormContext();

  const { filterValues } = useContext(MonitorSalesTargetContext);

  const [selectedRole, setSelectedRole] = useState<
    RoleModel | null | undefined
  >(filterValues?.jabatan);
  const [selectedNama, setSelectedNama] = useState<
    UserModel | null | undefined
  >(filterValues?.bawahan);

  const { roleData } = useGetRoleByLoggedUser();
  const { userData } = useGetUserByRole({
    roleName: !isEmpty(selectedRole) ? selectedRole.name : null,
  });

  useEffect(() => {
    if (userData.length === 0) {
      resetField("bawahan");
    }
  }, [userData.length]);

  useEffect(() => {
    setValue("jabatan", selectedRole);
    setValue("bawahan", selectedNama);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: isDesktopView ? "row" : "column",
        justifyContent: "space-between",
        gap: isDesktopView ? 4 : 0,
      }}
    >
      <FormControl width={isDesktopView ? "35%" : "100%"}>
        <FormInputLabel title="Jabatan" />
        <CustomSelect
          {...register("jabatan")}
          options={roleData}
          getOptionValue={(data: RoleModel) => data.name}
          getOptionLabel={(data: RoleModel) => data.label}
          value={selectedRole}
          placeholder="Jabatan"
          onChange={(data: RoleModel | null) => {
            setSelectedRole(data);
            setValue("jabatan", data ? data : null);
            if (!data) {
              setSelectedNama(null);
              resetField("bawahan");
            }
            setSelectedNama(null);
            setValue("bawahan", null);
          }}
        />
      </FormControl>
      <FormControl
        width={isDesktopView ? "65%" : "100%"}
        marginY={!isDesktopView ? "1%" : 0}
        isInvalid={!!errors.bawahan}
      >
        <FormInputLabel title="Cari Nama" />
        <CustomSelect
          {...register("bawahan", {
            required: {
              value: !isEmpty(getValues("jabatan")) ? true : false,
              message: "Required",
            },
          })}
          isDisabled={!selectedRole}
          onChange={(data: UserModel | null) => {
            setSelectedNama(data);
            setValue("bawahan", data ? data : null);
          }}
          options={userData}
          value={selectedNama}
          getOptionLabel={(data: UserModel) => data.name}
          getOptionValue={(data: UserModel) => data.id.toString()}
          placeholder={"Cari Nama"}
        />
        <FormErrorMessage color={theme.colors.exodus.red}>
          {errors.bawahan && (errors.bawahan.message as string)}
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
};

export default SalesTargetRoleBawahanFormField;
