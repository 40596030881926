import { useLocation, useNavigate } from "react-router-dom";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import { useEffect, useState } from "react";

type SelectionType = {
  label: string;
  value: string;
};

const selections: SelectionType[] = [
  {
    label: "PSSP Dokter",
    value: "pssp-dokter",
  },
  {
    label: "PSSP Non-Dokter",
    value: "pssp-non-dokter",
  },
  {
    label: "Entertainment",
    value: "entertainment",
  },
  {
    label: "DPL/DPF",
    value: "dpl-dpf",
  },
  {
    label: "Standarisasi",
    value: "standarisasi",
  },
  {
    label: "DP",
    value: "dp",
  },
];

const BiayaMurniMobilePageSelector = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;
    const strings: string[] = currentPath.split("/");
    if (strings && strings.length > 0) {
      const lastString = strings[strings.length - 1];
      let item1 = selections.find((i) => i.value === lastString);
      setCurrentValue(item1);
    }
  }, [location]);

  const [currentValue, setCurrentValue] = useState<SelectionType | undefined>(
    undefined
  );

  return (
    <CustomSelect
      onChange={(option) => {
        if (option) {
          navigate(`/biaya-murni/${option.value}`);
        }
      }}
      value={currentValue}
      getOptionLabel={undefined}
      getOptionValue={undefined}
      placeholder={undefined}
      options={selections}
    />
  );
};

export default BiayaMurniMobilePageSelector;
