import { createContext } from "react";
import ErrorResponse from "../../../../../models/common/ErrorResponse";
import {
  ListSalesTargetMetadata,
  ListSalesTargetRecords,
} from "../../models/SalesTargetModelPack";
import SalesTargetFilterAndPaginationModel from "../../models/SalesTargetFilterAndPaginationModel";

type InputSalesTarget = {
  salesTargetData: ListSalesTargetRecords[];
  loadingListSalesTarget: boolean;
  errorListSalesTarget: ErrorResponse | null;
  metadata: ListSalesTargetMetadata | null;
  filterValues: SalesTargetFilterAndPaginationModel | null;
  loadingFilter: boolean;
  errorFilter: string | null;
  saveDataToIndexedDB: (data: SalesTargetFilterAndPaginationModel) => void;
  refetchSalesTargetData: () => void;
};

const defaultValue: InputSalesTarget = {
  salesTargetData: [],
  loadingListSalesTarget: false,
  errorListSalesTarget: null,
  metadata: null,
  filterValues: null,
  loadingFilter: false,
  errorFilter: null,
  saveDataToIndexedDB: () => {},
  refetchSalesTargetData: () => {},
};

const InputSalesTargetContext = createContext(defaultValue);

export default InputSalesTargetContext;
