import { FormControl } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  CommonStatus,
  CommonStatusType,
} from "../../../../../models/common/Status";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import MonitorKlaimSalesContext from "../../contexts/MonitorKlaimSalesContext";
import { isEmpty } from "lodash";

const StatusFormFieldKlaimSales: React.FC = () => {
  const { register, setValue, watch } = useFormContext();
  const watchId = watch("idKlaimSales");

  const { filterValues } = useContext(MonitorKlaimSalesContext);

  const [status, setStatus] = useState<CommonStatusType | null>(null);

  useEffect(() => {
    setValue("klaimSalesStatus", status);
  }, [status]);

  useEffect(() => {
    if (filterValues?.status && !status) {
      setStatus({
        id: filterValues?.status as CommonStatus,
        name: filterValues?.status as CommonStatus,
      });
    }
  }, [filterValues]);

  const StatusOptions: CommonStatusType[] = [
    {
      id: CommonStatus.Pending,
      name: CommonStatus.Pending,
    },
    {
      id: CommonStatus.Approved,
      name: CommonStatus.Approved,
    },
    {
      id: CommonStatus.Rejected,
      name: CommonStatus.Rejected,
    },
  ];

  return (
    <FormControl width="100%">
      <FormInputLabel title="Status" />
      <CustomSelect
        {...register("klaimSalesStatus")}
        options={StatusOptions}
        isClearable
        getOptionValue={(data: CommonStatusType) => data.id}
        getOptionLabel={(data: CommonStatusType) => data.name}
        onChange={(data: CommonStatusType) => {
          setValue("klaimSalesStatus", data ? data.name : null);
          setStatus(data);
        }}
        placeholder="Status"
        value={status}
        isDisabled={!isEmpty(watchId)}
      />
    </FormControl>
  );
};

export default StatusFormFieldKlaimSales;
