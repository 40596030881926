import { Grid, GridItem, useTheme, Text } from "@chakra-ui/react";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";
import { useOutletCardDetailContext } from "../../../contexts/OutletCardDetailContextProvider";

const DesktopListProductDetail = () => {
  const { states } = useOutletCardDetailContext();
  const theme = useTheme();

  return (
    <Grid templateColumns="repeat(14, 1fr)" h={"100%"} gap={2}>
      <GridItem colSpan={3}>
        <Text
          display="flex"
          fontSize={16}
          fontWeight={400}
          color={theme.colors.exodus.primaryBlue}
        >
          Jumlah Produk
        </Text>
      </GridItem>
      <GridItem colSpan={11}>
        <Text
          display="flex"
          fontSize={16}
          fontWeight={400}
          color={theme.colors.exodus.darkGrey}
        >
          : {states.saldoKontribusiDetail?.jumlahProduk ?? "-"}
        </Text>
      </GridItem>
      <GridItem colSpan={3}>
        <Text
          display="flex"
          fontSize={16}
          fontWeight={400}
          color={theme.colors.exodus.primaryBlue}
          textAlign={"left"}
        >
          Total Saldo Kontribusi Produk
        </Text>
      </GridItem>
      <GridItem colSpan={11}>
        <Text
          display="flex"
          fontSize={16}
          fontWeight={400}
          color={theme.colors.exodus.darkGrey}
        >
          :{" "}
          {CurrencyIdrFormatter.convertToRp(
            states.saldoKontribusiDetail?.totalSaldo ?? 0
          )}
        </Text>
      </GridItem>
    </Grid>
  );
};

export default DesktopListProductDetail;
