import { useEffect, useState } from "react";
import OutletCardProvider from "../../dataProviders/OutletCardProvider";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../../constants/common/ErrorConstants";
import IsTypeOfErrorResponse from "../../../../../utils/IsTypeOfErrorResponse";
import { GetCostDetailParam } from "../../dataProviders/OutletCardProviderParams";
import ErrorResponse from "../../../../../models/common/ErrorResponse";
import { GetListOutletCardModelData } from "../../models/GetListOutletCardModel";

const useGetCostDetail = (params: GetCostDetailParam) => {
  const [data, setData] = useState<GetListOutletCardModelData | null>(null);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);

        const result = await OutletCardProvider.getCostDetail({
          startDate: params.startDate,
          endDate: params.endDate,
          outletId: params.outletId,
        });

        setData(result ?? null);
      } catch (error) {
        if (IsTypeOfErrorResponse(error)) {
          setError(error);
        } else {
          setError(UNKNOWN_ERROR_RESPONSE);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (params.startDate && params.endDate && params.outletId) {
      getData();
    }
  }, []);

  return { data, error, isLoading };
};

export default useGetCostDetail;
