import dayjs from "dayjs";
import "dayjs/locale/id";

const convertAchievement = (value: number): string => {
  let convertedValue: number = value * 100;
  return `${convertedValue.toFixed(2)} %`;
};

const convertCostRatio = (
  totalBiaya: number,
  realisasiSales: number
): string => {
  let costRatioValue: number;

  if (realisasiSales === 0) {
    costRatioValue = 0;
  } else {
    costRatioValue = (totalBiaya / realisasiSales) * 100;
  }

  return `${costRatioValue.toFixed(2)} %`;
};

const formatMonthYearMonitoring = (month: number, year: string) => {
  try {
    const formattedMonth = String(month).padStart(2, "0");

    const formattedDate = `${formattedMonth}-${year}`;

    return formattedDate;
  } catch (e: unknown) {
    return "00-0000";
  }
};

const dateConverterNumberToString = (month: number) => {
  const monthName = dayjs()
    .month(month - 1)
    .locale("id")
    .format("MMMM");

  return monthName;
};
export {
  convertAchievement,
  convertCostRatio,
  formatMonthYearMonitoring,
  dateConverterNumberToString,
};
