export const getOutletSectorType = (sector: string | undefined) => {
  let value: string = "";
  if (sector === "hospital") {
    value = "Rumah Sakit";
  } else if (sector === "clinic") {
    value = "Klinik";
  } else if (sector === "pharmacy") {
    value = "Apotek";
  } else if (sector === "pharmacy_panel") {
    value = "Apotek Panel";
  } else {
    value = "-";
  }
  return value;
};

export const getProjectType = (project: string | undefined) => {
  let value: string = "";
  if (project === "ethical") {
    value = "Ethical";
  } else if (project === "omega") {
    value = "Omega";
  } else if (project === "msa") {
    value = "Msa";
  } else {
    value = "-";
  }
  return value;
};
