import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js/auto";
import {
  CategoryScale,
  ChartOptions,
  ArcElement,
  Legend,
  Tooltip,
  Title,
  BarElement,
  LinearScale,
  ChartData,
} from "chart.js";
import CostSalesRatio from "../model/CostSalesRatio";
import { useTheme } from "@chakra-ui/react";

Chart.register(
  CategoryScale,
  ArcElement,
  Legend,
  Tooltip,
  Title,
  BarElement,
  LinearScale
);

interface Props {
  data: CostSalesRatio | null;
}

const BiayaMurniSalesRatioChart: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [salesValue, setSalesValue] = useState<number>(0);
  const [totalCostMurni, setTotalCostMurni] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(0);

  const barData: ChartData<"bar"> = {
    labels: ["Comparison"],
    datasets: [
      {
        label: "Sales",
        data: [salesValue === 0 && totalCostMurni === 0 ? 50 : salesValue],
        backgroundColor: theme.colors.exodus.secondaryBlue,
      },
      {
        label: "Biaya Murni",
        data: [salesValue === 0 && totalCostMurni === 0 ? 50 : totalCostMurni],
        backgroundColor: theme.colors.exodus.red,
      },
    ],
  };

  const option: ChartOptions<"bar"> = {
    plugins: {
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    indexAxis: "y",
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        min: 0,
        max: maxValue,
        stacked: true,
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    if (props.data !== null) {
      setSalesValue(props.data.salesValue);
      setTotalCostMurni(props.data.totalCostMurni);

      if (props.data.salesValue === 0 && props.data.totalCostMurni === 0) {
        setMaxValue(100);
      } else {
        setMaxValue(props.data.salesValue + props.data.totalCostMurni);
      }
    }
  }, [props.data]);

  return <Bar data={barData} options={option} height={12} />;
};

export default BiayaMurniSalesRatioChart;
