import {
  FormControl,
  FormErrorMessage,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import TujuanKunjunganDataModel from "../../../../models/TujuanKunjunganDataModel";

import CreateAndUpdateExtraCallBody from "../../models/CreateAndUpdateExtraCallModel";

type Props = {
  tujuanKunjunganData: TujuanKunjunganDataModel[];
  selectedTujuan: TujuanKunjunganDataModel | null;
  onChange: (data: TujuanKunjunganDataModel) => void;
};

const RealisasiExtraCallTujuanFormField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<CreateAndUpdateExtraCallBody>();

  return (
    <FormControl
      width={isDesktopView ? "80%" : "100%"}
      isInvalid={!!errors.purpose_of_visit}
    >
      <FormInputLabel title="Jenis Kegiatan" />
      <CustomSelect
        {...register("purpose_of_visit", {
          required: "Required",
        })}
        isSearchable={false}
        isClearable={false}
        options={props.tujuanKunjunganData}
        getOptionValue={(data: TujuanKunjunganDataModel) => data.id.toString()}
        getOptionLabel={(data: TujuanKunjunganDataModel) => data.visitName}
        onChange={(data: TujuanKunjunganDataModel) => {
          setValue("purpose_of_visit", data.visitName);
          props.onChange(data);
        }}
        value={props.selectedTujuan}
        placeholder="Jenis Kegiatan"
        menuPlacement="auto"
      />
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.purpose_of_visit && (errors.purpose_of_visit.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default RealisasiExtraCallTujuanFormField;
