import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Center,
  Button,
  ModalBody,
  Stack,
  ModalFooter,
  useMediaQuery,
  useTheme,
  Text,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import React from "react";
import dayjs from "dayjs";
import { ROLE_ID_FF } from "../../../../../../constants/common/RoleConstants";
import DateExodus from "../../../../../../models/DateExodus";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useOutletCardListContext } from "../../contexts/OutletCardListContextProvider";
import { OutletCardListFilterType } from "../../models/OutletCardListFilterType";
import FilterByOutlet from "./FilterByOutlet";
import FilterByPeriod from "./FilterByPeriod";
import ClearAllButton from "../../../../../../components/CustomButtons/ClearAllButton";
import FilterByRole from "./FilterByRole";
import FilterByBawahan from "./FilterByBawahan";
import FilterByZone from "./FilterByZone";
import FilterBySector from "./FilterBySector";
import FilterByProject from "./FilterByProject";

const OutletCardFilterModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state?.authReducer.loggedUser
  );

  const {
    states: { filterValues },
    actions: { saveFilter, setPage },
  } = useOutletCardListContext();

  const methods = useForm<OutletCardListFilterType>({
    defaultValues: {
      startDate: filterValues?.startDate,
      endDate: filterValues?.endDate,
      role: filterValues?.role ?? null,
      bawahan: filterValues?.bawahan ?? null,
      sector: filterValues?.sector ?? null,
      zone: filterValues?.zone ?? null,
      outlet: filterValues?.outlet ?? null,
      project: filterValues?.project ?? null,
    },
  });

  React.useEffect(() => {
    if (filterValues) {
      methods.setValue("startDate", filterValues.startDate);
      methods.setValue("endDate", filterValues.endDate);
      methods.setValue("role", filterValues.role);
      methods.setValue("bawahan", filterValues.bawahan);
      methods.setValue("sector", filterValues.sector);
      methods.setValue("zone", filterValues.zone);
      methods.setValue("outlet", filterValues.outlet);
      methods.setValue("project", filterValues.project);
    }
  }, [filterValues]);

  const handleOnClearAll = () => {
    methods.setValue(
      "startDate",
      new DateExodus(dayjs().startOf("month").unix())
    );
    methods.setValue("endDate", new DateExodus(dayjs().endOf("month").unix()));
    methods.setValue("role", null);
    methods.setValue("bawahan", null);
    methods.setValue("sector", null);
    methods.setValue("zone", null);
    methods.setValue("outlet", null);
    methods.setValue("project", null);

    handleOnSubmitFilter();
  };

  const handleOnSubmitFilter = () => {
    const updatedFilter: OutletCardListFilterType = {
      startDate: methods.getValues("startDate"),
      endDate: methods.getValues("endDate"),
      sector: methods.getValues("sector"),
      zone: methods.getValues("zone"),
      bawahan: methods.getValues("bawahan"),
      outlet: methods.getValues("outlet"),
      role: methods.getValues("role"),
      project: methods.getValues("project"),
    };
    saveFilter(updatedFilter);
    setPage("first");
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      autoFocus={false}
      size={isDesktopView ? "3xl" : "sm"}
    >
      <FormProvider {...methods}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center
              sx={{
                justifyContent: "space-between",
                display: "flex",
                gap: 10,
              }}
            >
              <Text
                sx={{ color: theme.colors.exodus.primaryBlue, fontSize: 24 }}
              >
                Filter
              </Text>
              <ClearAllButton onClick={handleOnClearAll} />
            </Center>
          </ModalHeader>
          <ModalBody>
            <Stack
              sx={{
                justifyContent: "space-between",
                display: "flex",
                gap: 2,
              }}
            >
              <FilterByProject />
              {loggedUser?.userRoleId !== ROLE_ID_FF && (
                <>
                  <Stack
                    gap={isMobile ? 0 : 4}
                    direction={isMobile ? "column" : "row"}
                  >
                    <FilterByRole />
                    <FilterByBawahan />
                  </Stack>
                  <FilterByZone />
                </>
              )}
              <FilterByOutlet />
              <FilterBySector />
              <FilterByProject />
              <FilterByPeriod />
            </Stack>
          </ModalBody>
          <ModalFooter
            gap={4}
            flexDirection={!isDesktopView ? "column-reverse" : undefined}
          >
            <Button
              colorScheme="blue"
              onClick={onClose}
              sx={{ width: "100% " }}
              variant={"outline"}
            >
              Close
            </Button>
            <Button
              colorScheme={`buttonPrimary`}
              sx={{ width: "100%" }}
              onClick={handleOnSubmitFilter}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};

export default OutletCardFilterModal;
