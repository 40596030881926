import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import ClearAllButton from "../../../../components/CustomButtons/ClearAllButton";
import FormInputLabel from "../../../../components/FormInputLabel/FormInputLabel";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import { FormProvider, useForm } from "react-hook-form";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import useGetOutletByUserId from "../../../../services/Outlet/useGetOutletByUserId";
import LoggedUser from "../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../redux/hooks";
import useGetListDistributor from "../../../../services/Distributor/useGetListDistributor";
import SalesDistributorFilterValue from "../model/SalesDistributorFilterValues";
import { debounce, isEmpty, isNil } from "lodash";
import { OutletModel } from "../../../../models/Outlet/OutletModel";
import DistributorDataModel from "../../../../models/Distributor/DistributorDataModel";
import DateExodus from "../../../../models/DateExodus";
import SalesDistributorContext from "../context/SalesDistributorFilterContext";
import dayjs from "dayjs";
import SectorModel from "../../../../models/Sector/SectorModel";
import { SALES_DISTRIBUTOR_SECTOR } from "../constants/SectorList";
import ProjectType from "../../../../models/common/ProjectType";

type Props = {
  open: boolean;
  onClose: () => void;
  saveData: (filterData: SalesDistributorFilterValue) => void;
  setPageDefault: () => void;
};

const SalesDistributorFilterDialog = ({
  open,
  onClose,
  saveData,
  setPageDefault,
}: Props) => {
  const theme = useTheme();
  const toast = useToast();

  const { filterValues, setFilterValues } = React.useContext(
    SalesDistributorContext
  );

  const [nomorFaktur, setSelectedNomorFaktur] = useState<string>(
    !isEmpty(filterValues?.nomorFaktur) ? filterValues?.nomorFaktur! : ""
  );

  const [namaOutlet, setSelectedNamaOutlet] = useState<
    OutletModel | null | undefined
  >(filterValues?.namaOutlet);

  const [namaDistributor, setSelectedNamaDistributor] = useState<
    DistributorDataModel | null | undefined
  >(filterValues?.namaDistributor);

  const [selectedProject, setSelectedProject] = useState<
    ProjectType | null | undefined
  >(filterValues?.projectAvailable);

  const [namaSektor, setSelectedNamaSektor] = useState<
    SectorModel | null | undefined
  >(filterValues?.sector);

  const [bridgingType, setSelectedBridgingType] = useState<string | undefined>(
    filterValues?.bridgingType
  );

  const [selectedPeriodeAwal, setSelectedPeriodeAwal] = useState<
    Date | undefined
  >(filterValues?.periodeAwal.getDateObject());

  const [selectedPeriodeAkhir, setSelectedPeriodeAkhir] = useState<
    Date | undefined
  >(filterValues?.periodeAkhir.getDateObject());

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  const [searched, setSearched] = useState<string | undefined>();
  const debouncedOnSearch = useRef(
    debounce(async (value: string | undefined) => {
      if (value && (value.length === 0 || value.length >= 3)) {
        setSearched(value);
      }
    }, 300)
  ).current;
  const { outletData } = useGetOutletByUserId({
    params: { userId: loggedUser?.userId, keyword: searched },
    startFetching: open,
  });

  const { distributorData } = useGetListDistributor();

  useEffect(() => {
    //RE SAVE STATE WHEN FILTER VALUES UPDATED
    if (filterValues) {
      setSelectedNomorFaktur(
        !isEmpty(filterValues?.nomorFaktur) ? filterValues?.nomorFaktur! : ""
      );
      setSelectedNamaOutlet(filterValues?.namaOutlet);
      setSelectedNamaDistributor(filterValues?.namaDistributor);
      setSelectedProject(filterValues?.projectAvailable);
      setSelectedNamaSektor(filterValues?.sector);
      setSelectedBridgingType(filterValues?.bridgingType);
      setSelectedPeriodeAwal(filterValues?.periodeAwal.getDateObject());
      setSelectedPeriodeAkhir(filterValues?.periodeAkhir.getDateObject());
    }
  }, [filterValues]);

  const formMethods = useForm({
    defaultValues: {
      nomorFaktur: filterValues?.nomorFaktur,
      namaOutlet: filterValues?.namaOutlet,
      namaDistributor: filterValues?.namaDistributor,
      projectAvailable: filterValues?.projectAvailable,
      sector: filterValues?.sector,
      bridgingType: filterValues?.bridgingType,
      periodeAwal: filterValues?.periodeAwal,
      periodeAkhir: filterValues?.periodeAkhir,
    },
  });

  React.useEffect(() => {
    if (filterValues) {
      formMethods.setValue("nomorFaktur", filterValues.nomorFaktur);
      formMethods.setValue("namaOutlet", filterValues.namaOutlet);
      formMethods.setValue("namaDistributor", filterValues.namaDistributor);
      formMethods.setValue("projectAvailable", filterValues.projectAvailable);
      formMethods.setValue("sector", filterValues.sector);
      formMethods.setValue("bridgingType", filterValues.bridgingType);
      formMethods.setValue("periodeAwal", filterValues.periodeAwal);
      formMethods.setValue("periodeAkhir", filterValues.periodeAkhir);
    }
  }, [filterValues]);

  const handleOnSubmitFilter = () => {
    const updatedFilter = new SalesDistributorFilterValue({
      nomorFaktur: formMethods.getValues("nomorFaktur"),
      namaOutlet: formMethods.getValues("namaOutlet"),
      namaDistributor: formMethods.getValues("namaDistributor"),
      projectAvailable: formMethods.getValues("projectAvailable"),
      sector: formMethods.getValues("sector"),
      bridgingType: formMethods.getValues("bridgingType"),
      periodeAwal: formMethods.getValues("periodeAwal"),
      periodeAkhir: formMethods.getValues("periodeAkhir"),
    });
    if (
      updatedFilter.periodeAkhir.getEpochDate() <
      updatedFilter.periodeAwal.getEpochDate()
    ) {
      toast({
        title: "Gagal Submit !",
        description: "Filter periode akhir melebihi periode awal!",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } else {
      console.log("updated filter checked", updatedFilter);
      setFilterValues(updatedFilter);
      saveData(updatedFilter);
      setPageDefault();
      onClose();
    }
  };

  const handleClearAll = () => {
    formMethods.setValue("nomorFaktur", null);
    formMethods.setValue("namaOutlet", null);
    formMethods.setValue("namaDistributor", null);
    formMethods.setValue("projectAvailable", null);
    formMethods.setValue("sector", null);
    formMethods.setValue("bridgingType", "1");
    formMethods.setValue(
      "periodeAwal",
      new DateExodus(dayjs().startOf("year").unix())
    );
    formMethods.setValue(
      "periodeAkhir",
      new DateExodus(dayjs().endOf("year").unix())
    );
    handleOnSubmitFilter();
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      size={{
        base: "xs",
        tablet: "xl",
      }}
    >
      <FormProvider {...formMethods}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text
              fontSize="2xl"
              sx={{ color: theme.colors.exodus.primaryBlue }}
            >
              Filter
            </Text>
            <ClearAllButton onClick={handleClearAll} />
          </ModalHeader>
          <ModalBody>
            <Box display={"flex"} flexDirection={"column"} gap={3}>
              <Box>
                <FormInputLabel title="Project" />
                <CustomSelect
                  isDisabled={loggedUser!.projects.length <= 1}
                  isSearchable={true}
                  isClearable={true}
                  onChange={(selectedData: ProjectType | null) => {
                    setSelectedProject(selectedData);
                    formMethods.setValue("projectAvailable", selectedData);
                  }}
                  value={selectedProject}
                  getOptionLabel={(data) => `${data.label}`}
                  getOptionValue={undefined}
                  placeholder={"Project Tersedia"}
                  options={loggedUser?.projects ? loggedUser?.projects : []}
                />
              </Box>
              <Box>
                <FormInputLabel title="Nomor Faktur" />
                <Input
                  placeholder="Nomor Faktur"
                  value={nomorFaktur}
                  onChange={(event) => {
                    setSelectedNomorFaktur(event.target.value.trim());
                    formMethods.setValue(
                      "nomorFaktur",
                      event.target.value.trim()
                    );
                  }}
                />
              </Box>
              <Box>
                <FormInputLabel title="Nama Outlet" />
                <CustomSelect
                  isDisabled={!isNil(formMethods.getValues("sector"))}
                  isSearchable={true}
                  isClearable={true}
                  onChange={(selectedData: OutletModel | null) => {
                    setSelectedNamaOutlet(selectedData);
                    formMethods.setValue("namaOutlet", selectedData);
                  }}
                  value={namaOutlet}
                  getOptionLabel={(data) =>
                    `${data.outletName + " - " + data.outletCode}`
                  }
                  getOptionValue={undefined}
                  placeholder={"Nama Outlet - Kode Outlet"}
                  options={outletData}
                  onInputChange={(value) => {
                    debouncedOnSearch(value);
                  }}
                />
              </Box>
              <Box>
                <FormInputLabel title="Nama Distributor" />
                <CustomSelect
                  isDisabled={false}
                  isSearchable={true}
                  isClearable={true}
                  onChange={(selectedData: DistributorDataModel | null) => {
                    setSelectedNamaDistributor(selectedData);
                    formMethods.setValue("namaDistributor", selectedData);
                  }}
                  value={namaDistributor}
                  getOptionLabel={(data) => `${data.distributorName}`}
                  getOptionValue={undefined}
                  placeholder={"Nama Distributor"}
                  options={distributorData}
                />
              </Box>
              <Box>
                <FormInputLabel title="Sektor" />
                <CustomSelect
                  onChange={(selectedData: SectorModel) => {
                    setSelectedNamaSektor(selectedData);
                    formMethods.setValue("sector", selectedData);

                    setSelectedNamaOutlet(null);
                    formMethods.setValue("namaOutlet", undefined);
                  }}
                  value={namaSektor}
                  getOptionLabel={(data) => `${data.label}`}
                  getOptionValue={undefined}
                  placeholder={"Sektor"}
                  options={SALES_DISTRIBUTOR_SECTOR}
                />
              </Box>
              <Box>
                <FormInputLabel title="Bridging Status" />
                <RadioGroup
                  value={bridgingType}
                  onChange={(selectedData: string) => {
                    setSelectedBridgingType(selectedData);
                    formMethods.setValue("bridgingType", selectedData);
                  }}
                >
                  <Stack direction="row" gap={"20px"}>
                    <Radio value="1">All</Radio>
                    <Radio value="2">Linked</Radio>
                    <Radio value="3">Unlinked</Radio>
                  </Stack>
                </RadioGroup>
              </Box>
              <Box>
                <FormInputLabel title="Periode" />
                <Box
                  display={"flex"}
                  flexDirection={{
                    base: "column",
                    tablet: "row",
                  }}
                  gap={3}
                >
                  <CustomDatePicker
                    name={"periode_awal_distributor"}
                    type={"date"}
                    placeholder="Periode Awal"
                    onChange={(selectedData: Date | null) => {
                      const newPeriodeAwal = new DateExodus(
                        selectedData?.getTime()! / 1000.0
                      );
                      formMethods.setValue("periodeAwal", newPeriodeAwal);
                      setSelectedPeriodeAwal(selectedData!);
                    }}
                    disabled={false}
                    rules={undefined}
                    selected={selectedPeriodeAwal}
                    defaultValue={selectedPeriodeAwal}
                  />
                  <CustomDatePicker
                    name={"periode_akhir_distributo"}
                    type={"date"}
                    placeholder="Periode Akhir"
                    onChange={(selectedData: Date | null) => {
                      const newPeriodeAkhir = new DateExodus(
                        selectedData?.getTime()! / 1000.0
                      );
                      formMethods.setValue("periodeAkhir", newPeriodeAkhir);
                      setSelectedPeriodeAkhir(selectedData!);
                    }}
                    disabled={false}
                    rules={undefined}
                    selected={selectedPeriodeAkhir}
                    defaultValue={selectedPeriodeAkhir}
                  />
                </Box>
              </Box>
              <Box
                display={"flex"}
                flexDirection={{
                  base: "column-reverse",
                  tablet: "row",
                }}
                gap={2}
                mt={3}
                pb={"16px"}
              >
                <Button
                  width={{
                    base: "100%",
                    tablet: "50%",
                  }}
                  colorScheme="buttonSecondary"
                  variant={"outline"}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  width={{
                    base: "100%",
                    tablet: "50%",
                  }}
                  colorScheme="buttonPrimary"
                  onClick={handleOnSubmitFilter}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};

export default SalesDistributorFilterDialog;
