import { Box, Grid, GridItem, Text, useTheme } from "@chakra-ui/react";
import InputKlaimSalesStoredDataModel from "../../../../models/InputKlaimSalesStoredDataModel";

type Props = {
  data: InputKlaimSalesStoredDataModel | null;
};

const MobileSummaryKlaimSalesGeneralInformation: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Box paddingTop="16px" paddingBottom="24px">
      <Grid
        templateColumns="repeat(4, 1fr)"
        textAlign="left"
        fontSize={14}
        gap={3}
      >
        <GridItem colSpan={2}>
          <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
            Nama Outlet Panel
          </Text>
          <Text>{props.data?.outlet?.outletPanel.data?.outletName ?? "-"}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
            Nama Outlet Tujuan
          </Text>
          <Text>
            {props.data?.outlet?.outletTujuan.data?.outletName ?? "-"}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
            Kode Outlet Panel
          </Text>
          <Text>{props.data?.outlet?.outletPanel.data?.outletCode ?? "-"}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
            Kode Outlet Tujuan
          </Text>
          <Text>
            {props.data?.outlet?.outletTujuan.data?.outletCode ?? "-"}
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default MobileSummaryKlaimSalesGeneralInformation;
