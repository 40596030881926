import DataTable, { TableColumn } from "react-data-table-component";
import theme from "../../layout/theme/theme";
import { Spinner, useTheme } from "@chakra-ui/react";

type ReactDataTableProps = {
  data: any;
  checboxOn: boolean;
  checkBoxDisabled?: (data: any) => boolean;
  tableHeader: TableColumn<any>[];
  paginationOn: boolean;
  paginationLimit: Array<number>;
  onSelectChange: (data: any) => void;
  isExpandable?: boolean;
  expandComponent?: any;
  isLoading?: boolean;
  onRowClicked?: (row: any, event: any) => void;
  noTableHead?: boolean;
};

const commonCustomStyle = {
  progress: {
    style: {
      backgroundColor: theme.colors.exodus.background,
    },
  },
  noData: {
    style: {
      backgroundColor: theme.colors.exodus.background,
    },
  },
  rows: {
    style: {
      minHeight: "45px",
      color: theme.colors.exodus.fontDefault,
      backgroundColor: theme.colors.exodus.tableDataBackground,
    },
    stripedStyle: {
      color: theme.colors.exodus.fontDefault,
      backgroundColor: theme.colors.exodus.background,
    },
  },
  headRow: {
    style: {
      color: "#0F4C82",
      fontSize: 14,
      fontWeight: 600,
      backgroundColor: theme.colors.exodus.background,
    },
  },
  headCells: {
    style: {
      //Style padding tidak bisa menggunakan PaddingX maupun PaddingY
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "#0F4C82",
      fontSize: 14,
      fontWeight: 600,
      backgroundColor: theme.colors.exodus.background,
    },
  },
  cells: {
    style: {
      //Style padding tidak bisa menggunakan PaddingX maupun PaddingY
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "6px",
      paddingBottom: "6px",
      fontSize: 14,
    },
  },
};

const TableDataComponent: React.FC<ReactDataTableProps> = (props) => {
  const theme = useTheme();
  return (
    <DataTable
      noTableHead={props.noTableHead}
      columns={props.tableHeader}
      data={props.data}
      selectableRows={props.checboxOn}
      selectableRowDisabled={props.checkBoxDisabled}
      onRowClicked={props.onRowClicked}
      striped
      customStyles={commonCustomStyle}
      pagination={props.paginationOn}
      paginationRowsPerPageOptions={props.paginationLimit}
      onSelectedRowsChange={props.onSelectChange}
      expandableRows={props.isExpandable}
      expandableRowsComponent={props.expandComponent}
      progressPending={props.isLoading}
      progressComponent={
        <Spinner
          thickness="4px"
          speed="1s"
          emptyColor={theme.colors.exodus.background}
          color={theme.colors.exodus.primaryBlue}
          size="xl"
        />
      }
    />
  );
};

export default TableDataComponent;
