import { Box, Flex, useMediaQuery, useTheme } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import FilterBox from "../../../../../components/FilterBox/FilterBox";
import ApprovalKlaimSalesContext from "../../contexts/ApprovalKlaimSalesContext";
import DateExodus from "../../../../../models/DateExodus";
import ShowFilterButton from "../../../../../components/CustomButtons/ShowFilterButton";
import DialogFilterApprovalKlaimSales from "./DialogFilterApprovalKlaimSales";

const FilterAreaApprovalKlaimSales: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const { filterValues } = useContext(ApprovalKlaimSalesContext);
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);

  return (
    <Flex
      paddingY={isDesktopView ? "20px" : "12px"}
      gap={3}
      direction={"row"}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Box
        flexDirection={isDesktopView ? "row" : "column"}
        display="flex"
        flexWrap="wrap"
      >
        {filterValues?.idKlaimSales && (
          <FilterBox
            title="Klaim Sales ID"
            content={filterValues.idKlaimSales}
          />
        )}
        {filterValues?.claimUser && (
          <FilterBox title="NSM" content={filterValues.claimUser.name} />
        )}
        {filterValues?.outletPanelName && (
          <FilterBox
            title="Nama Outlet Panel"
            content={filterValues.outletPanelName}
          />
        )}
        {filterValues?.outletTargetName && (
          <FilterBox
            title="Nama Outlet Tujuan"
            content={filterValues.outletTargetName}
          />
        )}
        {filterValues?.status && (
          <FilterBox title="Status" content={filterValues.status} />
        )}
        {filterValues?.startAt &&
          filterValues?.endAt &&
          !filterValues?.idKlaimSales && (
            <FilterBox
              title="Tanggal Pengajuan"
              content={`${new DateExodus(
                filterValues.startAt
              )?.getStringDateFormat("MMMM YYYY")} - ${new DateExodus(
                filterValues.endAt
              )?.getStringDateFormat("MMMM YYYY")}`}
            />
          )}
      </Box>

      <ShowFilterButton onClick={() => setOpenFilterDialog(true)} />
      <DialogFilterApprovalKlaimSales
        isOpen={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
      />
    </Flex>
  );
};

export default FilterAreaApprovalKlaimSales;
