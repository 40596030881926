import { useState, useEffect } from "react";
import SalesOutletProvider from "../../../../dataProviders/SalesOutletProvider";
import { SalesOutletGetSummaryParam } from "../../../../dataProviders/SalesOutletProviderParams";
import ErrorResponse from "../../../../../../../models/common/ErrorResponse";
import ApiGetSummarySalesOutletResponse from "../../models/apiResponses/ApiGetSummarySalesOutletResponse";

const useGetSummary = (params: SalesOutletGetSummaryParam) => {
  const [data, setData] = useState<ApiGetSummarySalesOutletResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setError(null);
        setIsLoading(true);

        const result = await SalesOutletProvider.getSummary(params);

        setData(result);
      } catch (error) {
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setIsLoading(false);
      }
    }
    if (params.endDate && params.startDate) {
      getData();
    }
  }, [
    params.endDate,
    params.startDate,
    params.userId,
    params.outlets,
    params.sector,
    params.zone,
    params.project,
  ]);

  return { data, isLoading, error };
};

export default useGetSummary;
