/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import {
  FakturByCodeModelData,
  FakturByCodeModelDetailData,
} from "../../../models/FakturByCodeModel";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";
import DesktopTableDetailFakturKlaimSales from "./desktop/DesktopTableDetailFakturKlaimSales";
import MobileTableDetailFakturKlaimSales from "./mobile/MobileTableDetailFakturKlaimSales";

type Props = {
  data: FakturByCodeModelData | null;
  onAdd: () => void;
};

const DetailFakturKlaimSales: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const [dataFakturAlreadyFullyClaimed, setDataFakturAlreadyFullyClaimed] =
    useState<boolean>(false);

  useEffect(() => {
    if (!props.data) return;

    /**
     * Cek apakah faktur sudah diklaim secara full oleh user lain atau belum.
     * Cek berdasarkan jumlah qty produk dalam faktur.
     * Apabila semua nya nol, maka faktur tsb sudah diklaim full.
     */
    const fakturProductQtyInvalid = props.data.salesDetail.every(
      (product: FakturByCodeModelDetailData) => {
        return product.qty === 0;
      }
    );

    setDataFakturAlreadyFullyClaimed(fakturProductQtyInvalid);
  }, [props.data]);

  if (!props.data) {
    return (
      <Box py="120px" width="100%" display="flex" justifyContent="center">
        <Text fontSize="18px">Tidak Ada Faktur yang Terpilih</Text>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
    >
      <Text
        color={theme.colors.exodus.primaryBlue}
        fontWeight="semibold"
        fontSize={isDesktopView ? 20 : 16}
      >
        {props.data.invoice}
      </Text>
      <Flex gap={2} paddingBottom={isDesktopView ? "12px" : "24px"}>
        <Text
          color={theme.colors.exodus.primaryBlue}
          fontWeight="semibold"
          fontSize={isDesktopView ? 16 : 14}
        >
          Distributor:{" "}
        </Text>
        <Text fontWeight="semibold" fontSize={isDesktopView ? 16 : 14}>
          {props.data.distributorName}
        </Text>
      </Flex>
      {isDesktopView && (
        <Box maxHeight="60vh" width="100%">
          <DesktopTableDetailFakturKlaimSales data={props.data.salesDetail} />
        </Box>
      )}
      {!isDesktopView && (
        <Box maxHeight="60vh" width="100%">
          <MobileTableDetailFakturKlaimSales data={props.data.salesDetail} />
        </Box>
      )}
      <Flex width="100%" direction="column" align="flex-end">
        <Flex
          width="100%"
          color={theme.colors.exodus.primaryBlue}
          fontWeight="semibold"
          fontSize={isDesktopView ? 20 : 16}
          justify="space-between"
          paddingTop="24px"
          paddingBottom={isDesktopView ? "24px" : "32px"}
        >
          <Text>Total Harga</Text>
          <Text>{CurrencyIdrFormatter.convertToRp(props.data.totalSales)}</Text>
        </Flex>
        {isDesktopView && (
          <Flex justify="space-between" align="center" gap={3}>
            {dataFakturAlreadyFullyClaimed && (
              <Text
                textAlign="left"
                color={theme.colors.exodus.red}
                fontSize={12}
                fontWeight="semibold"
                maxWidth="275px"
              >
                Sales untuk faktur ini sudah diklaim secara keseluruhan
              </Text>
            )}

            <Button
              colorScheme="buttonPrimary"
              onClick={props.onAdd}
              isDisabled={dataFakturAlreadyFullyClaimed}
              minWidth="150px"
            >
              Tambah ke List
            </Button>
          </Flex>
        )}
      </Flex>
      {!isDesktopView && (
        <Flex width="100%" direction="column" gap={2}>
          {dataFakturAlreadyFullyClaimed && (
            <Text
              textAlign="left"
              color={theme.colors.exodus.red}
              fontSize={12}
              fontWeight="semibold"
            >
              Sales untuk faktur ini sudah diklaim secara keseluruhan
            </Text>
          )}

          <Button
            colorScheme="buttonPrimary"
            width="100%"
            onClick={props.onAdd}
            isDisabled={dataFakturAlreadyFullyClaimed}
          >
            Tambah ke List
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default DetailFakturKlaimSales;
