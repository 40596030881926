import { useOutletCardDetailContext } from "../../contexts/OutletCardDetailContextProvider";
import {
  Accordion,
  Center,
  Spinner,
  Stack,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import { GetSaldoKontribusiProdukDetail } from "../../../models/GetSaldoKontribusiModel";
import MobileOutletCardAccordionItem from "./components/MobileOutletCardAccordionItem";

const MobileOutletCardAccordion = () => {
  const theme = useTheme();
  const {
    states: {
      errorSaldoKontribusiDetail,
      isLoadingSaldoKontribusiDetail,
      saldoKontribusiDetail,
    },
  } = useOutletCardDetailContext();

  if (isLoadingSaldoKontribusiDetail) {
    return (
      <Center>
        <Spinner
          marginTop={6}
          thickness="4px"
          speed="1s"
          emptyColor={theme.colors.exodus.background}
          color={theme.colors.exodus.primaryBlue}
          size="xl"
        />
      </Center>
    );
  }

  if (errorSaldoKontribusiDetail) {
    <VStack>
      <Stack direction="row">
        <Text fontSize={18} fontWeight={600} color={theme.colors.exodus.red}>
          {errorSaldoKontribusiDetail.code} {errorSaldoKontribusiDetail.status}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontSize={18} fontWeight={600} color={theme.colors.exodus.red}>
          {errorSaldoKontribusiDetail.message}
        </Text>
      </Stack>
    </VStack>;
  }

  return (
    <>
      {isEmpty(saldoKontribusiDetail?.detailProduk) ? (
        <DataNotFound />
      ) : (
        <Accordion allowMultiple>
          {saldoKontribusiDetail?.detailProduk.map(
            (eachData: GetSaldoKontribusiProdukDetail, key: number) => {
              return (
                <MobileOutletCardAccordionItem record={eachData} key={key} />
              );
            }
          )}
        </Accordion>
      )}
    </>
  );
};

export default MobileOutletCardAccordion;
