import { useMediaQuery, useTheme } from "@chakra-ui/react";

import BiayaMurniPsspDokterPageMobile from "./components/mobile/BiayaMurniPsspDokterPageMobile";
import BiayaMurniPsspDokterPageDesktop from "./components/desktop/BiayaMurniPsspDokterPageDesktop";
import { BiayaMurniFilterContextProvider } from "../../contexts/BiayaMurniFilterContext";
import useTitle from "../../../../services/useTitle";
import { BiayaMurniListContextProvider } from "../../contexts/BiayaMurniListContext";

const BiayaMurniPsspDokterPage = (): JSX.Element => {
  useTitle("Biaya Murni - PSSP Dokter");
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <BiayaMurniFilterContextProvider>
      <BiayaMurniListContextProvider featureType="pssp-doctor">
        {isDesktopView ? (
          <BiayaMurniPsspDokterPageDesktop />
        ) : (
          <BiayaMurniPsspDokterPageMobile />
        )}
      </BiayaMurniListContextProvider>
    </BiayaMurniFilterContextProvider>
  );
};

export default BiayaMurniPsspDokterPage;
