/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import TujuanKunjunganDataModel from "../models/TujuanKunjunganDataModel";
import ActivityGeneralProvider from "../dataProviders/ActivityGeneralProvider";

const useGetTujuanKunjunganList = (isOpen: boolean) => {
  const [tujuanKunjunganData, setData] = useState<TujuanKunjunganDataModel[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: TujuanKunjunganDataModel[] | undefined =
          await ActivityGeneralProvider.getTujuanKunjunganList();

        setData(responseData!);

        setLoading(false);
      } catch (error: any) {
        setData([]);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    if (isOpen) {
      getData();
    }
  }, [isOpen]);

  return { tujuanKunjunganData, loading, errorMessage };
};

export default useGetTujuanKunjunganList;
