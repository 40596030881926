import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useForgotPasswordStyle from "../services/useForgotPasswordStyle";
import {
  Box,
  Button,
  Card,
  Stack,
  useToast,
  useTheme,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import pharosSvgLogo from "../../../assets/PharosLogo.svg";
import PasswordField from "./components/PasswordField";
import {
  MODAL_SUCCESS_DESCRIPTION,
  PASSWORD_HAS_TO_BE_SAME_ERROR,
  PASSWORD_IS_NOT_INPUTTED_ERROR,
} from "./constants/constants";
import { isEmpty, isEqual } from "lodash";
import ConfirmPasswordField from "./components/ConfirmPasswordField";
import useVerifyForgotPasswordToken from "./services/useVerifyForgotPasswordToken";
import usePostNewPassword from "./services/usePostNewPassword";
import IsTypeOfErrorResponse from "../../../utils/IsTypeOfErrorResponse";
import OnSuccessModal from "../components/OnSuccessModal";
import { LOGIN_NAVIGATION_ROOT } from "../../../constants/common/RootConstants";

const NewPasswordInputPage = () => {
  const { rootStyle, logoStyle, newPasswordCardStyle } =
    useForgotPasswordStyle();
  const { token } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const theme = useTheme();
  const methods = useForm();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const [isTokenValid, setIsTokenValid] = React.useState<boolean | null>(null);
  const [userId, setUserId] = React.useState<number | null>(null);
  const [isSuccessModalShown, setIsSuccessModalShown] =
    React.useState<boolean>(false);

  const handleOnChangeIsSuccessModalShown = (e: boolean) => {
    setIsSuccessModalShown(e);
  };

  React.useEffect(() => {
    if (!token) {
      navigate(LOGIN_NAVIGATION_ROOT, { replace: true });
      return;
    }
    checkTokenValidity();
  }, [token]);

  const checkTokenValidity = async () => {
    if (token) {
      try {
        let userId: number = await useVerifyForgotPasswordToken({
          token,
        });
        setIsTokenValid(true);
        setUserId(userId);
      } catch (error: unknown) {
        setIsTokenValid(false);
        toast({
          title: "Token Gagal Tervalidasi. Navigasi Otomatis Ke Halaman Login.",
          description: JSON.stringify(error),
          status: "error",
        });
        navigate(LOGIN_NAVIGATION_ROOT, { replace: true });
      }
    }
  };

  const validateFormInput = (e: FieldValues) => {
    let isValid = true;
    if (isEmpty(e.password) || isEmpty(e.confirmPassword)) {
      toast({
        title: PASSWORD_IS_NOT_INPUTTED_ERROR,
        status: "error",
      });
      isValid = false;
    }
    if (!isEqual(e.password, e.confirmPassword)) {
      toast({
        title: PASSWORD_HAS_TO_BE_SAME_ERROR,
        status: "error",
      });
      isValid = false;
    }
    return isValid;
  };

  const handleOnError = (error: unknown) => {
    if (IsTypeOfErrorResponse(error)) {
      toast({
        title: "Gagal Mengubah Password Baru",
        description: `${error.status} ${error.code} ${error.message}`,
        status: "error",
      });
    } else {
      toast({
        title: "Gagal Mengubah Password Baru",
        description: `${error}`,
        status: "error",
      });
    }
  };

  const handleOnSubmit = async (e: FieldValues) => {
    const isValid = validateFormInput(e);
    if (!isValid) return;
    try {
      const payload = {
        userId: userId!!,
        newPassword: e.password,
      };
      await usePostNewPassword(payload);

      setIsSuccessModalShown(true);
    } catch (error: unknown) {
      handleOnError(error);
    }
  };
  return (
    <Stack sx={rootStyle}>
      <Card sx={newPasswordCardStyle}>
        <Box sx={logoStyle}>
          <Image
            src={pharosSvgLogo}
            alt="pharosSvgLogo"
            boxSize={isDesktopView ? "150px" : "80px"}
          />
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
            <PasswordField />
            <ConfirmPasswordField />
            <Button
              mt={16}
              colorScheme="buttonSuccess"
              type="submit"
              sx={{ width: "100%" }}
              size={"lg"}
              disabled={isTokenValid ?? true}
            >
              <Text fontWeight={600} fontSize={16}>
                Submit
              </Text>
            </Button>
          </form>
        </FormProvider>
      </Card>
      <OnSuccessModal
        isSuccessModalShown={isSuccessModalShown}
        handleOnChangeIsSuccessModalShown={handleOnChangeIsSuccessModalShown}
        descriptionText={MODAL_SUCCESS_DESCRIPTION}
        isCloseButtonNavigateToLogin
      />
    </Stack>
  );
};

export default NewPasswordInputPage;
