import ApiGetListResponseMetadata from "../../../../../../../models/common/ApiGetListResponseMetadata";
import SalesOutletListSingularData from "../SalesOutletListSingularData";

class ApiGetListSalesOutletResponse {
  metadata: ApiGetListResponseMetadata;
  records: SalesOutletListSingularData[];
  hasProject: boolean;

  constructor(json: { metadata: any; records: any }) {
    this.metadata = new ApiGetListResponseMetadata(json.metadata);
    const tempRecords: SalesOutletListSingularData[] = [];
    this.hasProject = false;

    if (json.records && json.records.length > 0) {
      for (let i = 0; i < json.records.length; i++) {
        tempRecords.push(new SalesOutletListSingularData(json.records[i]));
      }
      if (Object.hasOwn(json.records[0], "project")) {
        this.hasProject = true;
      }
    }
    this.records = tempRecords;
  }
}

export default ApiGetListSalesOutletResponse;
