import { HStack, Stack } from "@chakra-ui/react";
import ExportExcelButton from "../../../../../../../components/CustomButtons/ExportExcelButton";
import ShowFilterButton from "../../../../../../../components/CustomButtons/ShowFilterButton";
import FilterBoxArea from "../../common/FilterBoxArea";
import { useOutletCardListContext } from "../../../contexts/OutletCardListContextProvider";

const FilterAndExcelHeader = ({
  onFilterModalChange,
}: {
  onFilterModalChange: () => void;
}) => {
  const {
    states: { isExportLoading },
    actions: { exportExcel },
  } = useOutletCardListContext();

  return (
    <HStack gap={2} justifyContent={"space-between"}>
      <Stack flexDirection={"column"}>
        <FilterBoxArea />
      </Stack>
      <HStack justifyContent={"flex-end"} paddingY={"20px"}>
        <ShowFilterButton onClick={onFilterModalChange} />
        <ExportExcelButton onClick={exportExcel} isLoading={isExportLoading} />
      </HStack>
    </HStack>
  );
};

export default FilterAndExcelHeader;
