import { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import CustomDatePicker from "../../../../../../components/DatePicker/CustomDatePicker";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";

type Props = {
  value: Date | number;
  formInputLabel: string;
  name: string;
  type: "date" | "time";
  onChange: (data: Date | null) => void;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

const RealisasiExtraCallDateTimeFormField: React.FC<Props> = (props) => {
  const [value, setValue] = useState<Date | number>(new Date(props.value));

  useEffect(() => {
    setValue(new Date(props.value));
  }, [props.value]);

  return (
    <Box width="100%">
      <FormInputLabel title={props.formInputLabel} />
      <CustomDatePicker
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        defaultValue={value as Date}
        selected={value as Date}
        disabled={props.disabled}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </Box>
  );
};

export default RealisasiExtraCallDateTimeFormField;
