import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { GetSaldoKontribusiProdukDetail } from "../../../../models/GetSaldoKontribusiModel";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";
import HorizontalAccordionDetail from "../../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";

const MobileOutletCardAccordionItem = ({
  record,
}: {
  record: GetSaldoKontribusiProdukDetail;
}) => {
  const theme = useTheme();
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            fontSize={14}
          >
            {record.productName}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel mx={4}>
        <HorizontalAccordionDetail
          leftText="Value Sales"
          rightSide={CurrencyIdrFormatter.convertToRp(record.valueSales ?? 0)}
        />
        <HorizontalAccordionDetail
          leftText="Kontribusi"
          rightSide={record.kontribusi * 100 + "%"}
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText="Saldo"
          rightSide={CurrencyIdrFormatter.convertToRp(record.saldo)}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default MobileOutletCardAccordionItem;
