import DateExodus from "../../../../models/DateExodus";
import { ProjectEnum } from "../../../../models/common/Project";

class BiayaMurniEntertainData {
  entertainId: number;
  outletId: number;
  outletName: string;
  outletCode: string;
  customerId: number;
  customerName: string;
  customerCode: string;
  spesialis: string;
  posisi: string;
  total: number;
  createdAt: number;
  processedAt: number;
  tglRealisasiFormat: DateExodus | null;
  project: ProjectEnum;

  constructor(json: any) {
    this.entertainId = json["entertain_id"];
    this.outletId = json["outlet_id"];
    this.outletName = json["outlet_name"];
    this.outletCode = json["outlet_code"];
    this.customerId = json["customer_id"];
    this.customerName = json["customer_name"];
    this.customerCode = json["customer_code"];
    this.spesialis = json["customer_special"];
    this.posisi = json["customer_position"];
    this.total = json["entertain_total"];
    this.createdAt = json["entertain_created_at"];
    this.processedAt = json["entertain_or_processed_at"];
    if (json["entertain_or_processed_at"]) {
      this.tglRealisasiFormat = DateExodus.fromBackend(
        json["entertain_or_processed_at"]
      );
    } else {
      this.tglRealisasiFormat = null;
    }
    this.project = json.project;
  }
}

export { BiayaMurniEntertainData };
