import {
  Button,
  Center,
  HStack,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import Pagination from "../../../../../../../../components/Pagination/Pagination";
import TableDataComponent from "../../../../../../../../components/TableData/TableDataComponent";
import { COMMON_PAGINATION_LIMIT_CHOICES } from "../../../../../../../../constants/components/PaginationConstants";
import CurrencyIdrFormatter from "../../../../../../../../utils/CurrencyIdrFormatter";
import { useSalesOutletListContext } from "../../../contexts/SalesOutletListContext";
import SalesOutletListSingularData from "../../../models/SalesOutletListSingularData";
import { MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { generateSalesOutletDetailUri } from "../../../../../utils/utils";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { projectEnumToUi } from "../../../../../../../../models/common/Project";

const SalesOutletTable = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    states: { data, filterValues },
    actions: { setPage, setLimit },
  } = useSalesOutletListContext();

  const [projectColumn, setProjectColumn] = useState<TableColumn<any>[]>([]);

  useEffect(() => {
    if (data?.hasProject) {
      setProjectColumn([
        {
          name: "Project",
          cell: (row: SalesOutletListSingularData) => (
            <Text textAlign={"start"}>{projectEnumToUi(row.project)}</Text>
          ),
          width: "100px",
        },
      ]);
    } else {
      setProjectColumn([]);
    }
  }, [data]);

  if (data?.records) {
    return (
      <Stack>
        <TableDataComponent
          data={data.records}
          onSelectChange={() => {}}
          paginationLimit={COMMON_PAGINATION_LIMIT_CHOICES.map((val) => {
            return val.value;
          })}
          paginationOn={false}
          tableHeader={[
            ...projectColumn,
            {
              name: "Kode PI",
              cell: (row: SalesOutletListSingularData) => (
                <Text textAlign={"start"}>{row.outletPharosCode}</Text>
              ),
              width: "10%",
            },
            {
              name: "Nama Outlet",
              cell: (row: SalesOutletListSingularData) => (
                <Text textAlign={"start"}>{row.outletName}</Text>
              ),
              width: "30%",
            },
            {
              name: "Sektor",
              cell: (row: SalesOutletListSingularData) => (
                <Text textAlign={"start"}>{row.sector.label}</Text>
              ),
            },
            {
              name: "Sales Value",
              cell: (row: SalesOutletListSingularData) => (
                <Text textAlign={"start"}>
                  {CurrencyIdrFormatter.convertToRp(row.salesValue)}
                </Text>
              ),
            },
            {
              name: "Estimasi Value",
              cell: (row: SalesOutletListSingularData) => (
                <Text textAlign={"start"}>
                  {CurrencyIdrFormatter.convertToRp(row.estimatedValue)}
                </Text>
              ),
            },
            {
              name: "",
              cell: (row: SalesOutletListSingularData) => {
                return (
                  <Button
                    colorScheme="buttonSecondary"
                    variant={"ghost"}
                    leftIcon={<MdRemoveRedEye fontSize={16} />}
                    onClick={() => {
                      navigate(
                        generateSalesOutletDetailUri({
                          project: filterValues?.project,
                          outletId: row.outletId,
                          startDate: filterValues?.periode.awal,
                          endDate: filterValues?.periode.akhir,
                          userId: filterValues?.pekerja?.id,
                        })
                      );
                    }}
                  >
                    <Wrap spacing={1}>
                      <WrapItem>
                        <Text>Lihat</Text>
                      </WrapItem>
                      <WrapItem>
                        <Text>Detail</Text>
                      </WrapItem>
                    </Wrap>
                  </Button>
                );
              },
              width: "15%",
            },
          ]}
          checboxOn={false}
        />
        <HStack justifyContent={"flex-end"}>
          <Pagination
            handleOnNavigateFirstPage={() => {
              setPage("first");
            }}
            handleOnNext={() => {
              setPage("next");
            }}
            handleOnBack={() => {
              setPage("prev");
            }}
            handleOnLimitChange={(value) => {
              setLimit(value);
            }}
            page={data.metadata.currentPage}
            limit={data.metadata.totalDataPerPage}
            totalData={data.metadata.totalData}
            totalPage={data.metadata.totalPage}
          />
        </HStack>
      </Stack>
    );
  }

  return (
    <Center>
      <Text fontWeight={600}>Data daftar sales outlet tidak ditemukan</Text>
    </Center>
  );
};

export default SalesOutletTable;
