import { ROLE_ID_FF } from "../../../../../../../../constants/common/RoleConstants";
import LoggedUser from "../../../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../../../redux/hooks";
import SalesOutletFilterFfRole from "./roleFf/SalesOutletFilterFfRole";
import SalesOutletFilterOthersRole from "./roleOthers/SalesOutletFilterOthersRole";

const SalesOutletFilter = (): JSX.Element => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  if (loggedUser?.userRoleId === ROLE_ID_FF) {
    return <SalesOutletFilterFfRole />;
  } else {
    return <SalesOutletFilterOthersRole />;
  }
};

export default SalesOutletFilter;
