import { Divider, useTheme } from "@chakra-ui/react";

type KlaimSalesDividerProps = {
  orientation: "horizontal" | "vertical";
};

const KlaimSalesDivider: React.FC<KlaimSalesDividerProps> = (props) => {
  const theme = useTheme();

  return (
    <Divider
      orientation={props.orientation}
      sx={{
        borderWidth: "1px",
        borderColor: theme.colors.exodus.primaryBlue,
        marginX: props.orientation === "vertical" ? "44px" : 0,
        marginY: props.orientation === "horizontal" ? "15px" : 0,
      }}
    />
  );
};

export default KlaimSalesDivider;
