import { Box } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";
import ProjectType from "../../../../../models/common/ProjectType";

const FilterByProject = () => {
  const formContext = useFormContext();
  const watchProject = formContext.watch("project");

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  return (
    <Box width={"100%"}>
      <FormInputLabel title="Project" />
      <CustomSelect
        options={loggedUser?.projects}
        onChange={(project: ProjectType | null) => {
          formContext.setValue("project", project);
        }}
        getOptionLabel={(project: ProjectType) => project.label}
        value={watchProject}
        placeholder="Project"
        getOptionValue={(project: ProjectType) => project.value}
        isClearable
        isDisabled={loggedUser?.projects && loggedUser?.projects.length < 2}
      />
    </Box>
  );
};

export default FilterByProject;
