import { Tag, useTheme } from "@chakra-ui/react";
import React from "react";

type Props = {
  text: string;
  status: "pending" | "approved" | "transferred" | "processed" | "reject";
};

const StatusChip: React.FC<Props> = ({ status, text }) => {
  const theme = useTheme();
  const getColorScheme = () => {
    switch (status) {
      case "pending":
        return theme.colors.exodus.yellow;
      case "approved":
        return theme.colors.exodus.approved;
      case "transferred":
        return theme.colors.exodus.transferred;
      case "processed":
        return theme.colors.exodus.green;
      case "reject":
        return theme.colors.exodus.red;
      default:
        return "teal";
    }
  };

  return (
    <Tag
      variant="solid"
      fontWeight={600}
      bgColor={getColorScheme()}
      borderRadius="12px"
    >
      {text}
    </Tag>
  );
};

export default StatusChip;
