import { createContext } from "react";
import AllPeriodeDataModel from "../../../models/AllPeriodeDataModel";
import {
  ListApprovalKunjunganModelMetadata,
  ListApprovalKunjunganModelRecords,
} from "../../../models/ApprovalListModel";
import ApprovalRencanaFilterValues from "../models/ApprovalRencanaFilterValues";
import initialFilterValue from "../utils/InitialFilterValue";
import ProjectType from "../../../../../models/common/ProjectType";

type InputRencanaKunjunganProps = {
  periodeData: AllPeriodeDataModel[];
  periodeLoading: boolean;
  periodeError: string | null;
  draftApprovalRencanaKunjunganData: ListApprovalKunjunganModelRecords[];
  draftRencanaLoading: boolean;
  draftRencanaError: string | null;
  isDialogOpen: boolean;
  setIsDialogOpen: (data: boolean) => void;
  filterValues: ApprovalRencanaFilterValues | null | undefined;
  setFilterValues: (data: ApprovalRencanaFilterValues) => void;
  selectedPeriode: AllPeriodeDataModel | null;
  setSelectedPeriode: (data: AllPeriodeDataModel | null) => void;
  metadata: ListApprovalKunjunganModelMetadata | null;
  page: number | null;
  setPage: (data: number | null) => void;
  limit: number | null;
  setLimit: (data: number | null) => void;
  jabatan: string | null | undefined;
  setJabatan: (data: string | null | undefined) => void;
  nama: string | null | undefined;
  setNama: (data: string | null | undefined) => void;
  status: string | null | undefined;
  setStatus: (data: string | null | undefined) => void;
  project: ProjectType | null | undefined;
  setProject: (data: ProjectType | null | undefined) => void;
};

const defaultValue: InputRencanaKunjunganProps = {
  periodeData: [],
  periodeLoading: false,
  periodeError: null,
  draftApprovalRencanaKunjunganData: [],
  draftRencanaLoading: false,
  draftRencanaError: null,
  isDialogOpen: false,
  setIsDialogOpen: () => {},
  filterValues: initialFilterValue,
  setFilterValues: () => {},
  selectedPeriode: null,
  setSelectedPeriode: () => {},
  metadata: null,
  page: 1,
  setPage: () => {},
  jabatan: null,
  setJabatan: () => {},
  nama: null,
  setNama: () => {},
  status: null,
  setStatus: () => {},
  limit: 20,
  setLimit: () => {},
  project: null,
  setProject: () => {}
};

const ApprovalRencanaKunjunganContext = createContext(defaultValue);

export default ApprovalRencanaKunjunganContext;
