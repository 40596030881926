import { useContext } from "react";
import ExportExcelButton from "../../../../../components/CustomButtons/ExportExcelButton";
import MonitorSalesTargetContext from "../context/MonitorSalesTargetContext";
import useExportSalesTarget from "../services/useExportSalesTarget";
import { useToast } from "@chakra-ui/react";

const ButtonExportExcelMonitorSalesTarget: React.FC = () => {
  const { filterValues } = useContext(MonitorSalesTargetContext);
  const toast = useToast();

  const { exportData } = useExportSalesTarget(filterValues!);

  const onError = (errorMessage: string) => {
    toast({
      title: "Gagal Mengunduh Excel",
      description: errorMessage,
      status: "error",
    });
  };
  const onClickExport = () => {
    exportData(onError);
  };

  return <ExportExcelButton onClick={() => onClickExport()} />;
};

export default ButtonExportExcelMonitorSalesTarget;
