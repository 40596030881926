import { useContext } from "react";
import InputRencanaKunjunganContext from "../../context/InputRencanaKunjunganContext";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Spinner,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { DraftRencanaKunjunganModelData } from "../../models/DraftRencanaKunjunganModel";
import HorizontalAccordionDetail from "../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import ButtonDeleteRencanaKunjungan from "../ButtonDeleteRencanaKunjungan";
import ButtonEditRencanaKunjungan from "../ButtonEditRencanaKunjungan";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";

const MobileTableDraftRencanaKunjungan: React.FC = () => {
  const { draftRencanaKunjunganData, draftRencanaLoading, draftRencanaError } =
    useContext(InputRencanaKunjunganContext);

  const theme = useTheme();

  if (draftRencanaLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (draftRencanaError) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={draftRencanaError.toString()} />
      </Box>
    );
  }

  return draftRencanaKunjunganData.length > 0 ? (
    <Box marginTop="24px" marginBottom="15px">
      <Accordion allowToggle>
        {draftRencanaKunjunganData.map(
          (item: DraftRencanaKunjunganModelData) => (
            <AccordionItem key={item.id} border="none" marginBottom="16px">
              <AccordionButton display="flex" justifyContent="space-between">
                <Box textAlign="left">
                  <Text
                    fontSize={14}
                    fontWeight="bold"
                    color={theme.colors.exodus.primaryBlue}
                  >
                    {item.outletName}
                  </Text>
                  <Text fontSize={12}>{item.outletCode}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel marginX="16px">
                <HorizontalAccordionDetail
                  leftText="Customer"
                  rightSide={`${item.customerName} (${item.customerCode})`}
                  size="small"
                  isRowColored
                />
                <HorizontalAccordionDetail
                  leftText="Spesialis"
                  rightSide={`${item.customerSpecialist}`}
                  size="small"
                />
                <HorizontalAccordionDetail
                  leftText="Jabatan"
                  rightSide={`${item.positionCustomer}`}
                  size="small"
                  isRowColored
                />
                <HorizontalAccordionDetail
                  leftText="Kunjungan"
                  rightSide={`${item.totalVisit} kali`}
                  size="small"
                />
                <HorizontalAccordionDetail
                  leftText="Tujuan"
                  rightSide={`${item.purpose}`}
                  size="small"
                  isRowColored
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    paddingTop: "10px",
                    gap: 2,
                  }}
                >
                  <ButtonEditRencanaKunjungan data={item} />
                  <ButtonDeleteRencanaKunjungan data={item} />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          )
        )}
      </Accordion>
    </Box>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default MobileTableDraftRencanaKunjungan;
