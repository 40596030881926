import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Text,
  useTheme,
} from "@chakra-ui/react";
import HorizontalAccordionDetail from "../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import { useContext, useState } from "react";
import ApprovalRencanaKunjunganDetailContext from "../../context/ApprovalRencanaKunjunganDetailContext";
import { DetailApprovalRencanaKunjunganModelDetailVisitData } from "../../models/ApprovalRencanaKunjunganData";
import DialogKonfirmasiSubmitApproveRencanaKunjungan from "../DialogKonfirmasiSubmitApproveRencanaKunjungan";
import DialogKonfirmasiRejectApprovalRencanaKunjungan from "../DialogKonfirmasiRejectApprovalRencanaKunjungan";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../redux/hooks";
import { ROLE_ID_TRADE_TEAM } from "../../../../../../constants/common/RoleConstants";

const MobileAccordionApprovalRencanaKunjunganDetail = () => {
  const { detailData } = useContext(ApprovalRencanaKunjunganDetailContext);
  const theme = useTheme();
  const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const setApproveAndRejectButtonDisable = (): boolean => {
    if (loggedUser?.userRoleId === ROLE_ID_TRADE_TEAM) return true;
    if (detailData?.approvalPlanStatus === "Approved") {
      return true;
    } else if (detailData?.approvalPlanStatus === "Pending") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box>
      <Box marginTop="24px" marginBottom="15px">
        <Accordion allowToggle>
          {detailData?.detailVisitData.map(
            (item: DetailApprovalRencanaKunjunganModelDetailVisitData) => (
              <AccordionItem
                key={item.id}
                border="none"
                marginBottom="16px"
                marginX={"-3"}
              >
                <AccordionButton display="flex" justifyContent="space-between">
                  <Box textAlign="left">
                    <Text
                      fontSize={14}
                      fontWeight="bold"
                      color={theme.colors.exodus.primaryBlue}
                    >
                      {item.outletName}
                    </Text>
                    <Text fontSize={12}>{item.outletCode}</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel marginX="16px">
                  {item.project && (
                    <HorizontalAccordionDetail
                      leftText="Project"
                      rightSide={item.project.label}
                      size="small"
                      isRowColored
                    />
                  )}
                  <HorizontalAccordionDetail
                    leftText="Customer"
                    rightSide={
                      <Box fontSize={12} textAlign={"right"}>
                        <Text>{item.customerName}</Text>
                        <Text>{item.customerCode}</Text>
                      </Box>
                    }
                    size="small"
                  />
                  <HorizontalAccordionDetail
                    leftText="Spesialis"
                    rightSide={`${item.spesialis}`}
                    size="small"
                    isRowColored
                  />
                  <HorizontalAccordionDetail
                    leftText="Jabatan"
                    rightSide={`${item.posisi}`}
                    size="small"
                  />
                  <HorizontalAccordionDetail
                    leftText="Kunjungan"
                    rightSide={`${item.totalVisit} Kali`}
                    size="small"
                    isRowColored
                  />
                  <HorizontalAccordionDetail
                    leftText="Tujuan"
                    rightSide={`${item.tujuan}`}
                    size="small"
                  />
                </AccordionPanel>
              </AccordionItem>
            )
          )}
        </Accordion>
      </Box>
      <Box
        sx={{
          position: "sticky",
          bgColor: theme.colors.exodus.background,
          bottom: 0,
          left: 0,
          width: "100%",
          pt: 2,
          pb: 2,
          px: 5,
          zIndex: 999,
        }}
      >
        {detailData?.approvalPlanStatus === "Request" && (
          <Box display="flex" gap={2}>
            <Button
              variant="outline"
              colorScheme="buttonError"
              color={theme.colors.exodus.red}
              borderColor={theme.colors.exodus.red}
              width={"50%"}
              isDisabled={setApproveAndRejectButtonDisable()}
              onClick={() => setOpenRejectDialog(true)}
            >
              Reject
            </Button>
            <Button
              sx={{ marginRight: "8px" }}
              width={"50%"}
              type="submit"
              colorScheme="buttonSuccess"
              bgColor={theme.colors.exodus.green}
              isDisabled={setApproveAndRejectButtonDisable()}
              onClick={() => setOpenConfirmation(true)}
            >
              Approve
            </Button>
          </Box>
        )}
      </Box>
      <DialogKonfirmasiSubmitApproveRencanaKunjungan
        openConfirmationDialog={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
      />
      <DialogKonfirmasiRejectApprovalRencanaKunjungan
        openDescriptionDialog={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
      />
    </Box>
  );
};

export default MobileAccordionApprovalRencanaKunjunganDetail;
