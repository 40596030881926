import ProjectType from "../../../../../models/common/ProjectType";
import RoleModel from "../../../../../models/Users/RoleModel";
import UserModel from "../../../../../models/Users/UserModel";
import AllPeriodeDataModel from "../../../models/AllPeriodeDataModel";
import { ApprovalRencanaStatus } from "./ApprovalRencanaKunjunganStatus";

type ConstructorProps = {
  id?: number;
  jabatan: RoleModel | null | undefined;
  status: ApprovalRencanaStatus | null | undefined;
  bawahan: UserModel | null | undefined;
  periode: AllPeriodeDataModel | null | undefined;
  project: ProjectType | null | undefined;
};

class ApprovalRencanaFilterValues {
  id?: number;
  jabatan: RoleModel | null | undefined;
  status: ApprovalRencanaStatus | null | undefined;
  bawahan: UserModel | null | undefined;
  periode: AllPeriodeDataModel | null | undefined;
  project: ProjectType | null | undefined;

  constructor({ id, jabatan, status, bawahan, periode, project }: ConstructorProps) {
    this.id = id;
    this.jabatan = jabatan;
    this.status = status;
    this.bawahan = bawahan;
    this.periode = periode;
    this.project = project;
  }
}

export default ApprovalRencanaFilterValues;
