import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import { useOutletCardDetailContext } from "../../contexts/OutletCardDetailContextProvider";
import { GetSaldoKontribusiProdukDetail } from "../../../models/GetSaldoKontribusiModel";
import { Text } from "@chakra-ui/react";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";

const DesktopOutletCardDetail = () => {
  const {
    states: {
      isLoadingSaldoKontribusiDetail,
      saldoKontribusiDetail,
      errorSaldoKontribusiDetail,
    },
  } = useOutletCardDetailContext();
  return (
    <>
      {errorSaldoKontribusiDetail && !isLoadingSaldoKontribusiDetail ? (
        <></>
      ) : (
        <TableDataComponent
          data={saldoKontribusiDetail?.detailProduk}
          expandComponent={() => {}}
          onSelectChange={() => {}}
          paginationOn={false}
          tableHeader={[
            {
              cell: (record: GetSaldoKontribusiProdukDetail) => (
                <Text fontSize={14}>{record.productName}</Text>
              ),
              name: "Nama Produk",
              width: "40%",
            },
            {
              cell: (record: GetSaldoKontribusiProdukDetail) => (
                <Text fontSize={14}>
                  {CurrencyIdrFormatter.convertToRp(record.valueSales)}
                </Text>
              ),
              name: "Value Sales",
            },
            {
              name: "Kontribusi",
              cell: (record: GetSaldoKontribusiProdukDetail) => (
                <Text fontSize={14}>{record.kontribusi * 100 + "%"}</Text>
              ),
            },
            {
              name: "Saldo",
              cell: (record: GetSaldoKontribusiProdukDetail) => (
                <Text fontSize={14}>
                  {CurrencyIdrFormatter.convertToRp(record.saldo)}
                </Text>
              ),
            },
          ]}
          checboxOn={false}
          paginationLimit={[]}
          isLoading={isLoadingSaldoKontribusiDetail}
        />
      )}
    </>
  );
};

export default DesktopOutletCardDetail;
