import { Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DateExodus from "../../../../../models/DateExodus";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import CustomDatePicker from "../../../../../components/DatePicker/CustomDatePicker";
import MonitorKlaimSalesContext from "../../contexts/MonitorKlaimSalesContext";
import { isEmpty } from "lodash";

const PeriodeFormFieldKlaimSales: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { register, setValue, watch } = useFormContext();
  const watchId = watch("idKlaimSales");
  const { filterValues } = useContext(MonitorKlaimSalesContext);

  const [tempStartDate, setTempStartDate] = useState<DateExodus | null>(
    filterValues?.startAt ? new DateExodus(filterValues.startAt) : null
  );
  const [tempEndDate, setTempEndDate] = useState<DateExodus | null>(
    filterValues?.endAt ? new DateExodus(filterValues.endAt) : null
  );

  useEffect(() => {
    setValue("startAtKlaimSales", tempStartDate?.getDateObject());
    setValue("endAtKlaimSales", tempEndDate?.getDateObject());
  }, []);

  return (
    <Stack gap={0}>
      <FormInputLabel title="Tanggal Pengajuan" />
      <Stack gap={4} direction={isDesktopView ? "row" : "column"}>
        <Stack sx={{ width: "100%" }}>
          <CustomDatePicker
            {...register("startAtKlaimSales", {
              required: !isEmpty(watchId) ? false : "Required",
            })}
            placeholder="Periode Awal"
            type={"month_year"}
            defaultValue={tempStartDate?.getDateObject()}
            onChange={(data) => {
              if (data) {
                setTempStartDate(DateExodus.fromDateObject(data));
                setValue("startAtKlaimSales", DateExodus.fromDateObject(data));
              }
            }}
            name={"startAtKlaimSales"}
            maxDate={tempEndDate?.getDateObject()}
            disabled={!isEmpty(watchId)}
          />
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <CustomDatePicker
            {...register("endAtKlaimSales", {
              required: !isEmpty(watchId) ? false : "Required",
            })}
            placeholder="Periode Akhir"
            type={"month_year"}
            defaultValue={tempEndDate?.getDateObject()}
            onChange={(data) => {
              if (data) {
                setTempEndDate(DateExodus.fromDateObject(data).endOf("month"));
                setValue(
                  "endAtKlaimSales",
                  DateExodus.fromDateObject(data).endOf("month")
                );
              }
            }}
            name={"endAtKlaimSales"}
            minDate={tempStartDate?.getDateObject()}
            disabled={!isEmpty(watchId)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PeriodeFormFieldKlaimSales;
