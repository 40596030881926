import { Stack, Text, VStack, useMediaQuery, useTheme } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import CustomDatePicker from "../../../../../../components/DatePicker/CustomDatePicker";

const FilterDummyUi = () => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <Stack
        marginY={4}
        gap={isMobile ? 2 : 4}
        direction={isMobile ? "column" : "row"}
      >
        <VStack width={isMobile ? "100%" : 302} alignItems={"stretch"}>
          <Text textAlign={"start"}>Project</Text>
          <CustomSelect
            defaultValue={null}
            placeholder="Project"
            isDisabled={true}
            onChange={undefined}
            value={undefined}
            getOptionLabel={undefined}
            getOptionValue={undefined}
            options={undefined}
          />
        </VStack>
        <Stack
          sx={{ width: isMobile ? "100%" : undefined }}
          alignItems={"baseline"}
        >
          <Text>Periode Awal</Text>
          <CustomDatePicker
            disabled
            placeholder="Periode Awal"
            name={""}
            type={"month_year"}
          />
        </Stack>
        <Stack
          sx={{ width: isMobile ? "100%" : undefined }}
          alignItems={"baseline"}
        >
          <Text>Periode Akhir</Text>
          <CustomDatePicker
            disabled
            placeholder="Periode Akhir"
            name={""}
            type={"month_year"}
          />
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default FilterDummyUi;
