import { useEffect, useState } from "react";
import InputKlaimSalesProvider from "../../../dataProviders/InputKlaimSalesProvider";
import {
  EditableFakturSalesData,
  StoredEditableFakturListModel,
} from "../models/EditableFakturModel";
import { useToast } from "@chakra-ui/react";
import { notifyMessages } from "../constants/KlaimSalesPilihProdukConstants";
import InputKlaimSalesStoredDataModel from "../../../models/InputKlaimSalesStoredDataModel";

enum PilihProdukAsyncType {
  idle,
  storeSaving,
  storeDeleting,
  storeDeletingDone,
  storeFetching,
  storeFetchingDone,
  saveSingularDone,
}

/**
 * A logical hook with functions and states, made for Input Klaim Sales's
 * Pilih Produk (Step 3).
 *
 * @remarks
 * Not to be used directly inside components. Use with [PilihProdukProvider].
 * Contain various functions and states to manage "faktur" list operations,
 * while also managing the related local storage.
 *
 * @returns JSX.Element
 *
 */
const usePilihProdukData = () => {
  const [fakturList, setFakturList] = useState<EditableFakturSalesData[]>([]);
  const [isValidForNextStep, _setIsValidForNextStep] = useState<boolean>(false);
  const [awaitState, setAwaitState] = useState<PilihProdukAsyncType>(
    PilihProdukAsyncType.idle
  );
  const [_editingFakturId, setEditingFakturId] = useState<number | null>(null);
  const [currentEditingFaktur, _setCurrentEditingFaktur] =
    useState<EditableFakturSalesData | null>(null);
  const [removingFakturAttempt, _setRemovingFakturAttempt] =
    useState<EditableFakturSalesData | null>(null);

  const toast = useToast();

  useEffect(() => {
    const generateData = async () => {
      await fetchFakturList();
    };
    generateData();
  }, []);

  useEffect(() => {
    const curData = fakturList.find((obj) => obj.id === _editingFakturId);
    _setCurrentEditingFaktur(curData ?? null);
    checkValidityForNextStep();
  }, [_editingFakturId]);

  useEffect(() => {
    const saveData = async () => {
      await storeFakturList();
    };

    if (
      awaitState === PilihProdukAsyncType.storeDeletingDone ||
      awaitState === PilihProdukAsyncType.storeFetchingDone ||
      awaitState === PilihProdukAsyncType.saveSingularDone
    ) {
      saveData();
      checkValidityForNextStep();
    }

    /// notify call
    if (awaitState === PilihProdukAsyncType.saveSingularDone) {
      toast({
        title: notifyMessages.info.savedToStorage,
        status: "info",
      });
    } else if (awaitState === PilihProdukAsyncType.storeDeletingDone) {
      toast({
        title: notifyMessages.info.removedInStorage,
        status: "info",
      });
    }
  }, [awaitState, fakturList]);

  const fetchFakturList = async () => {
    if (awaitState === PilihProdukAsyncType.idle) {
      setAwaitState(PilihProdukAsyncType.storeFetching);
      const data: InputKlaimSalesStoredDataModel | null =
        await InputKlaimSalesProvider.getSavedInputKlaimSalesData();

      if (data && data.pilihProduk) {
        setFakturList(data.pilihProduk);
      } else {
        const uneditedData: InputKlaimSalesStoredDataModel | null =
          await InputKlaimSalesProvider.getSavedInputKlaimSalesData();

        if (uneditedData && uneditedData.selectedFaktur) {
          const newData = new StoredEditableFakturListModel(
            uneditedData.selectedFaktur.data
          );
          setFakturList(newData.data);
        }
      }
      setAwaitState(PilihProdukAsyncType.storeFetchingDone);
    }
  };

  const storeFakturList = async () => {
    if (
      awaitState === PilihProdukAsyncType.storeDeletingDone ||
      awaitState === PilihProdukAsyncType.storeFetchingDone ||
      awaitState === PilihProdukAsyncType.saveSingularDone
    ) {
      setAwaitState(PilihProdukAsyncType.storeSaving);
      await InputKlaimSalesProvider.setSavePilihProdukKlaimSales({
        produk: fakturList,
      });
      setAwaitState(PilihProdukAsyncType.idle);
    }
  };

  const clearFakturList = async () => {
    if (awaitState === PilihProdukAsyncType.idle) {
      setAwaitState(PilihProdukAsyncType.storeDeleting);
      await InputKlaimSalesProvider.removeSavedPilihProdukKlaimSales();
      setAwaitState(PilihProdukAsyncType.storeDeletingDone);
    }
  };

  const checkValidityForNextStep = () => {
    let validity: boolean = true;
    for (let i = 0; i < fakturList.length; i++) {
      if (fakturList[i].isValid === false) {
        validity = false;
        break;
      }
      /// else do nothing (continue)
    }
    if (fakturList.length === 0) {
      validity = false;
    }
    if (_editingFakturId !== null) {
      validity = false;
    }
    _setIsValidForNextStep(validity);
  };

  const saveSingularFakturForm = (newData: EditableFakturSalesData) => {
    if (_editingFakturId !== null) {
      const arr = fakturList;
      const idx = arr.findIndex((el) => el.id === _editingFakturId);
      if (idx === -1) return;
      arr[idx] = newData;
      setFakturList(arr);
      setEditingFakturId(null);
      setAwaitState(PilihProdukAsyncType.saveSingularDone);
    }
  };

  const removeSingularFaktur = async (id: number) => {
    if (awaitState === PilihProdukAsyncType.idle) {
      setAwaitState(PilihProdukAsyncType.storeDeleting);
      if (id === _editingFakturId) {
        setEditingFakturId(null);
      }
      _setRemovingFakturAttempt(null);
      setFakturList(fakturList.filter((el) => el.id !== id));
      setAwaitState(PilihProdukAsyncType.storeDeletingDone);
    }
  };

  const removeSingularFakturAttempt = (
    data: EditableFakturSalesData | null
  ) => {
    _setRemovingFakturAttempt(data);
  };

  return {
    fakturList,
    fetchFakturList,
    storeFakturList,
    clearFakturList,
    isValidForNextStep,
    setEditingFakturId,
    currentEditingFaktur,
    awaitState,
    removeSingularFaktur,
    removingFakturAttempt,
    removeSingularFakturAttempt,
    saveSingularFakturForm,
  };
};

export default usePilihProdukData;

export { PilihProdukAsyncType };
