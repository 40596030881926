import {
  Button,
  Grid,
  GridItem,
  Spinner,
  Text,
  Wrap,
  WrapItem,
  useTheme,
} from "@chakra-ui/react";
import CustomPopover from "../../../../../../../components/CustomPopover/CustomPopover";
import { MdInfoOutline } from "react-icons/md";
import { BiayaMurniOrCode } from "../../../../../models/BiayaMurniOrCode";
import { useDpOrManagerContext } from "../../../contexts/DpOrManagerContext";

const BiayaMurniDpOrPopover = ({ index }: { index: number }): JSX.Element => {
  const {
    actions: { fetchOneByIndex },
  } = useDpOrManagerContext();

  return (
    <CustomPopover
      autoFocus={true}
      onOpen={() => {
        fetchOneByIndex(index);
      }}
      strategy="fixed"
      triggerComponent={
        <Button
          colorScheme="buttonSecondary"
          variant={"ghost"}
          leftIcon={<MdInfoOutline fontSize={16} />}
        >
          <Wrap spacing={1}>
            <WrapItem>
              <Text>Tanggal</Text>
            </WrapItem>
            <WrapItem>
              <Text>Realisasi</Text>
            </WrapItem>
          </Wrap>
        </Button>
      }
      width={600}
      bodyComponent={<OrPopoverBody index={index} />}
      triggerType="click"
      placement="bottom-end"
    />
  );
};

const OrPopoverBody = ({ index }: { index: number }): JSX.Element => {
  const theme = useTheme();

  const {
    states: { dataArr, isLoadingArr, errorArr },
  } = useDpOrManagerContext();

  if (isLoadingArr[index]) {
    return <Spinner color={theme.colors.exodus.primaryBlue} />;
  }

  if (errorArr[index]) {
    return (
      <Text color={theme.colors.exodus.red} fontWeight={600}>
        {errorArr[index]!.message}
      </Text>
    );
  }

  if (dataArr[index]) {
    return (
      <Grid templateColumns="repeat(3, 1fr)" gap={2}>
        <GridItem w="100%">
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            textAlign={"start"}
          >
            Nama PT
          </Text>
        </GridItem>
        <GridItem w="100%">
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            textAlign={"start"}
          >
            Tanggal Realisasi
          </Text>
        </GridItem>
        <GridItem w="100%">
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            textAlign={"start"}
          >
            Kode OR
          </Text>
        </GridItem>
        {dataArr[index]!.map((val, idx) => {
          return <Fields data={val} key={idx} />;
        })}
      </Grid>
    );
  }

  return <Text fontWeight={600}>Data tidak ditemukan</Text>;
};

const Fields = ({ data }: { data: BiayaMurniOrCode }): JSX.Element => {
  return (
    <>
      <GridItem w="100%">
        <Text textAlign={"start"}>{data.ptFullName}</Text>
      </GridItem>
      <GridItem w="100%">
        <Text textAlign={"start"}>
          {data.tglRealisasi.getStringDateFormat("DD MMMM YYYY")}
        </Text>
      </GridItem>
      <GridItem w="100%">
        <Text textAlign={"start"}>{data.orCode}</Text>
      </GridItem>
    </>
  );
};

export default BiayaMurniDpOrPopover;
