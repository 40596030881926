import { Text, useMediaQuery, useTheme } from "@chakra-ui/react";

type PageTitleProps = {
  label: string;
};

const PageTitle: React.FC<PageTitleProps> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Text
      color={theme.colors.exodus.primaryBlue}
      fontWeight="bold"
      fontSize={isDesktopView ? 40 : 24}
      textAlign="left"
    >
      {props.label}
    </Text>
  );
};

export default PageTitle;
