import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../../../../../../components/CustomSelect/CustomSelect";
import UserModel from "../../../../../../../../../../../models/Users/UserModel";
import useGetUserByRole from "../../../../../../../../../../../services/Users/useGetUserByRole";
import SalesOutletListFilterModel from "../../../../../../models/SalesOutletListFilterModel";
import FilterFieldFrame from "../FilterFieldFrame";
import { VStack, HStack, Spinner, Text, Box, useTheme } from "@chakra-ui/react";

const FilterByPekerja = () => {
  const theme = useTheme();

  const formContext = useFormContext<SalesOutletListFilterModel>();
  const watchJabatan = formContext.watch("jabatan");
  const watchPekerja = formContext.watch("pekerja");
  const watchZona = formContext.watch("zona");

  const { userData, loading, errorMessage } = useGetUserByRole({
    roleName: watchJabatan?.name ?? "",
  });

  return (
    <FilterFieldFrame title="Cari Nama">
      <VStack width={"100%"} gap={0} alignItems={"stretch"}>
        <HStack width={"100%"} gap={4}>
          {loading ? (
            <Spinner color={theme.colors.exodus.primaryBlue} />
          ) : (
            <></>
          )}
          <Box width={"100%"}>
            <CustomSelect
              options={userData}
              isDisabled={watchJabatan === undefined || watchZona !== undefined}
              onChange={(value: UserModel | null) => {
                if (value) {
                  formContext.setValue("pekerja", value);
                } else {
                  formContext.setValue("pekerja", undefined);
                }
                formContext.setValue("outlets", undefined);
              }}
              getOptionLabel={(pekerja) => `${pekerja.name} - ${pekerja.nip}`}
              getOptionValue={(eachName) => eachName.nip}
              defaultValue={null}
              value={watchPekerja ?? null}
              placeholder="Cari Nama"
              isClearable
            />
          </Box>
        </HStack>
        {errorMessage ? (
          <Text
            color={theme.colors.exodus.red}
            textAlign={"left"}
            pl={2}
            fontSize={14}
          >
            {errorMessage}
          </Text>
        ) : (
          <></>
        )}
      </VStack>
    </FilterFieldFrame>
  );
};

export default FilterByPekerja;
