import { Box, useMediaQuery, useTheme } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MobileDetailApprovalRealisasiComponent from "./mobile/MobileDetailApprovalRealisasiComponent";
import DesktopDetailApprovalRealisasiComponent from "./desktop/DesktopDetailApprovalRealisasiComponent";
import { useLocation, useParams } from "react-router-dom";
import useGetRealisasiByHeaderUserIdAll from "../services/useGetRealisasiByHeaderUserIdAll";
import HeaderRecordApprovalRealisasiContext from "../context/HeaderRecordApprovalRealisasiContext";
import useGetApprovalRealisasiUserId from "../services/useGetApprovalRealisasiUserId";
import { isEmpty } from "lodash";

const DetailApprovalRealisasiKunjunganPage: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const { id } = useParams();
  const location = useLocation();

  const { data: persistentUserId } = useGetApprovalRealisasiUserId();

  const [userId, setUserId] = useState<number | null>(null);
  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    if (persistentUserId!==null) {
      setUserId(persistentUserId);
    }
  }, [persistentUserId]);

  const {
    realisasiHeaderData,
    loading: headerLoading,
    errorMessage: errorHeader,
  } = useGetRealisasiByHeaderUserIdAll(key, {
    header_id: Number(id),
    user_id: !isEmpty(location.state) ? location.state.userId : userId,
  });

  return (
    <HeaderRecordApprovalRealisasiContext.Provider
      value={{
        headerData: realisasiHeaderData,
        headerLoading: headerLoading,
        headerError: errorHeader,
        key: key,
        setKey: setKey,
      }}
    >
      <Box>
        {isDesktopView ? (
          <DesktopDetailApprovalRealisasiComponent />
        ) : (
          <MobileDetailApprovalRealisasiComponent />
        )}
      </Box>
    </HeaderRecordApprovalRealisasiContext.Provider>
  );
};

export default DetailApprovalRealisasiKunjunganPage;
