import { useState } from "react";
import DateExodus from "../../../../../models/DateExodus";
import useGetListSalesTarget from "../../services/useGetListSalesTarget";
import MonitorSalesTargetContext from "../context/MonitorSalesTargetContext";
import useFilterStateMonitorSalesTarget from "../services/useFilterStateMonitorSalesTarget";
import MonitorSalesTargetPage from "./MonitorSalesTargetPage";
import useTitle from "../../../../../services/useTitle";

const MonitorSalesTargetBase: React.FC = () => {
  const [selectedSalesTarget, setSelectedSalesTarget] = useState<number[]>([]);

  const {
    filterValues,
    loadingFilter,
    errorFilter,
    setFilterMonitorSalesTarget,
    handleChangePageOrLimit,
    handleClearFilter,
  } = useFilterStateMonitorSalesTarget();

  const convertEpochToDateExodus = (data?: DateExodus | number | null) => {
    if (typeof data === "number") {
      return new DateExodus(data);
    } else if (data === null) {
      return undefined;
    } else {
      return data;
    }
  };

  const {
    salesTargetData,
    metadata: metadataSalesTarget,
    loading: salesTargetLoading,
    error: salesTargetError,
    refetchSalesTargetData,
  } = useGetListSalesTarget({
    page: filterValues?.page,
    limit: filterValues?.limit,
    userId: filterValues?.bawahan?.id,
    startDate: convertEpochToDateExodus(filterValues?.periodeKuartalStart),
    endDate: convertEpochToDateExodus(filterValues?.periodeKuartalEnd),
    project: filterValues ? filterValues.project : null,
  });

  useTitle("Monitor Sales Target");

  return (
    <MonitorSalesTargetContext.Provider
      value={{
        filterValues: filterValues,
        loadingFilter: loadingFilter,
        errorFilter: errorFilter,
        setFilterMonitorSalesTarget: setFilterMonitorSalesTarget,
        salesTargetData: salesTargetData,
        metadataSalesTarget: metadataSalesTarget,
        salesTargetLoading: salesTargetLoading,
        salesTargetError: salesTargetError,
        selectedSalesTarget: selectedSalesTarget,
        setSelectedSalesTarget: setSelectedSalesTarget,
        handleChangePageOrLimit: handleChangePageOrLimit,
        handleClearFilter: handleClearFilter,
        refetchSalesTargetData: refetchSalesTargetData,
      }}
    >
      <MonitorSalesTargetPage />
    </MonitorSalesTargetContext.Provider>
  );
};

export default MonitorSalesTargetBase;
