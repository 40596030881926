import { useContext } from "react";
import {
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import MonitorKunjunganMonitorListContext from "../context/MonitorKunjunganMonitorListContext";

const MonitorKunjunganRadioGroup: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const navigate = useNavigate();

  const { currentPsspType, setCurrentPsspType } = useContext(
    MonitorKunjunganMonitorListContext
  );

  return (
    <Flex
      paddingY={isDesktopView ? "16px" : "12px"}
      gap={isDesktopView ? 4 : 2}
      direction={isDesktopView ? "row" : "column"}
      align={isDesktopView ? "center" : "start"}
    >
      <Text
        fontSize={isDesktopView ? 20 : 16}
        fontWeight="semibold"
        color={theme.colors.exodus.primaryBlue}
      >
        Jenis PSSP
      </Text>
      <RadioGroup
        value={currentPsspType}
        onChange={(data) => {
          setCurrentPsspType(data);
          navigate(`/monitor-kunjungan/${data}`);
        }}
      >
        <Stack
          direction={isDesktopView ? "row" : "column"}
          gap={isDesktopView ? 6 : 1}
        >
          <Radio value="pssp-aktif">PSSP Aktif</Radio>
          <Radio value="pssp-tidak-aktif">PSSP Tidak Aktif</Radio>
        </Stack>
      </RadioGroup>
    </Flex>
  );
};

export default MonitorKunjunganRadioGroup;
