import { createContext } from "react";
import UserModel from "../../../../../models/Users/UserModel";
import RoleModel from "../../../../../models/Users/RoleModel";
import FilterMonitorKunjunganModel from "../../models/FilterMonitorKunjunganModel";

type MonitorKunjunganUserListProps = {
  userData: UserModel[];
  userLoading: boolean;
  userErrorMessage: string | null;
  savedFilter: FilterMonitorKunjunganModel | null;
  saveFilterValueToIndexedDB: (
    dataJabatan: RoleModel | null,
    dataUser: UserModel | null
  ) => void;
  saveSelectedBawahanToIndexedDB: (data: number) => void;
  resetSavedFilterValue: () => void;
};

const defaultValue: MonitorKunjunganUserListProps = {
  userData: [],
  userLoading: false,
  userErrorMessage: null,
  savedFilter: null,
  saveFilterValueToIndexedDB: () => {},
  saveSelectedBawahanToIndexedDB: () => {},
  resetSavedFilterValue: () => {},
};

const MonitorKunjunganUserListContext = createContext(defaultValue);

export default MonitorKunjunganUserListContext;
