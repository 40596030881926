import { Dispatch, createContext, SetStateAction } from "react";
import SalesDistributorFilterValue from "../model/SalesDistributorFilterValues";
import SalesDistributorIntialValue from "../utils/SalesDistributorInitialValue";

interface SalesDistributorContextType {
  filterValues: SalesDistributorFilterValue | null;
  setFilterValues: Dispatch<SetStateAction<SalesDistributorFilterValue | null>>;
}

const initialValue: SalesDistributorContextType = {
  filterValues: SalesDistributorIntialValue,
  setFilterValues: () => {},
};

const SalesDistributorContext =
  createContext<SalesDistributorContextType>(initialValue);

export default SalesDistributorContext;
