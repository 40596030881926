import {
  Accordion,
  Box,
  Center,
  Divider,
  Spinner,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import ErrorPage from "../../../../../../../components/ErrorPage/ErrorPage";
import PageTitle from "../../../../../../../components/PageTitle/PageTitle";
import { useSalesOutletDetailFilterContext } from "../../contexts/SalesOutletDetailFilterContext";
import { useSalesOutletDetailContext } from "../../contexts/SalesOutletDetailContext";
import SalesOutletDetailSummarySection from "../common/SalesOutletSummarySection";
import DataNotFound from "../../../../../../../components/DataNotFound/DataNotFound";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import { useEffect, useState } from "react";
import SalesOutletDetailProductData from "../../models/SalesOutletDetailProductData";
import SalesOutletDetailAccordionItem from "./parts/SalesOutletDetailAccordionItem";
import ApiGetDetailSalesOutletResponse from "../../models/apiResponses/ApiGetDetailSalesOutletResponse";
import { projectEnumToUi } from "../../../../../../../models/common/Project";

const SalesOutletDetailPageMobile = (): JSX.Element => {
  const {
    states: { error },
  } = useSalesOutletDetailFilterContext();

  if (error) {
    return (
      <VStack alignItems={"stretch"}>
        <PageTitle label="Sales Outlet" />
        <ErrorPage message={error} />
      </VStack>
    );
  }

  return <ContentUnderFilter />;
};

const ContentUnderFilter = (): JSX.Element => {
  const theme = useTheme();

  const {
    states: { data, error, isLoading },
    actions: { setLimit, setPage },
  } = useSalesOutletDetailContext();

  if (isLoading) {
    return (
      <PageFrameWithFilter
        child={
          <Center p={10}>
            <VStack>
              <Spinner color={theme.colors.exodus.primaryBlue} />
              <Text>Loading Detail Sales Outlet</Text>
            </VStack>
          </Center>
        }
      />
    );
  }

  if (error) {
    return (
      <PageFrameWithFilter child={<ErrorPage message={error.message} />} />
    );
  }

  if (data) {
    return (
      <PageFrameWithFilter
        data={data}
        child={
          <VStack alignItems={"stretch"}>
            <SalesOutletDetailAccordion records={data.records} />
            <Center>
              <Pagination
                handleOnNavigateFirstPage={() => {
                  setPage("first");
                }}
                handleOnBack={() => {
                  setPage("prev");
                }}
                handleOnNext={() => {
                  setPage("next");
                }}
                handleOnLimitChange={(limit) => {
                  setLimit(limit);
                }}
                totalData={data.metadata.totalData}
                totalPage={data.metadata.totalPage}
                limit={data.metadata.totalDataPerPage}
                page={data.metadata.currentPage}
              />
            </Center>
          </VStack>
        }
      />
    );
  }

  return <PageFrameWithFilter child={<DataNotFound />} />;
};

const PageFrameWithFilter = ({
  child,
  data,
}: {
  child: JSX.Element;
  data?: ApiGetDetailSalesOutletResponse;
}): JSX.Element => {
  const theme = useTheme();

  return (
    <VStack alignItems={"stretch"} flex={1} gap={0}>
      {data ? (
        <>
          {data.hasProject && (
            <PageTitle label={projectEnumToUi(data.project)} />
          )}
          <PageTitle
            label={`${data.outlet.outletName} (${data.outlet.outletCode})`}
          />
          <Text fontSize={14} textAlign={"start"} mb={2}>
            Sektor: {data.outlet.sector.label}
          </Text>
        </>
      ) : (
        <PageTitle label={"Sales Outlet Detail"} />
      )}
      <Box mt={1} />
      <SalesOutletDetailSummarySection />
      <Divider
        sx={{
          marginY: 4,
          border: "1px",
          opacity: 1,
          borderColor: theme.colors.exodus.primaryBlue,
        }}
      />
      {child}
    </VStack>
  );
};

const SalesOutletDetailAccordion = ({
  records,
}: {
  records: SalesOutletDetailProductData[];
}): JSX.Element => {
  const [accordionIndex, setAccordionIndex] = useState(-1);

  useEffect(() => {
    setAccordionIndex(-1);
  }, [records]);

  if (records.length === 0) {
    return <DataNotFound />;
  }

  return (
    <Accordion allowToggle index={accordionIndex}>
      {records.map((item, i) => (
        <SalesOutletDetailAccordionItem
          key={i}
          index={i}
          data={item as SalesOutletDetailProductData}
          handleChangeAccordion={() => {
            if (i === accordionIndex) {
              setAccordionIndex(-1);
            } else {
              setAccordionIndex(i);
            }
          }}
        />
      ))}
    </Accordion>
  );
};

export default SalesOutletDetailPageMobile;
