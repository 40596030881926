/* eslint-disable @typescript-eslint/no-explicit-any */
import DateExodus from "../../../models/DateExodus";
import ApiGetListResponseMetadata from "../../../models/common/ApiGetListResponseMetadata";

class KlaimSalesListModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: KlaimSalesListModelData;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = new KlaimSalesListModelData(json["data"]);
  }
}

class KlaimSalesListModelData {
  records!: KlaimSalesListModelRecords[];
  metadata!: ApiGetListResponseMetadata;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: KlaimSalesListModelRecords[] = [];

    if (json["records"] !== null) {
      for (let i = 0; i < json.records.length; i++) {
        temp.push(new KlaimSalesListModelRecords(json.records[i]));
      }
    }

    this.records = temp;
    this.metadata = new ApiGetListResponseMetadata(json["metadata"]);
  }
}

class KlaimSalesListModelRecords {
  id!: number;
  status!: string;
  userName!: string;
  claimOutletName!: string;
  claimOutletCode!: string;
  targetOutletName!: string;
  targetOutletCode!: string;
  createdAt!: DateExodus;
  createdAtUi!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.id = json["id"];
    this.status = json["status"];
    this.userName = json["user_name"];
    this.claimOutletName = json["claim_outlet_name"];
    this.claimOutletCode = json["claim_outlet_code"];
    this.targetOutletName = json["target_outlet_name"];
    this.targetOutletCode = json["target_outlet_code"];
    this.createdAt = DateExodus.fromBackend(json["created_at"]);
    this.createdAtUi = DateExodus.fromBackend(
      json["created_at"]
    ).getStringDateFormat("D MMM YYYY");
  }
}

export {
  KlaimSalesListModelPack,
  KlaimSalesListModelData,
  KlaimSalesListModelRecords,
};
