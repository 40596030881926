import { Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import useTitle from "../../../services/useTitle";
import { SalesProductContextProvider } from "./contexts/SalesProductContextProvider";
import PageTitle from "../../../components/PageTitle/PageTitle";
import SalesProductDesktop from "./components/desktop/SalesProductDesktop";
import SalesProductMobile from "./components/mobile/SalesProductMobile";

const SalesProductPage = () => {
  useTitle("Sales Produk");
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <SalesProductContextProvider>
      <Stack>
        <PageTitle label={"Sales Produk"} />
        {isDesktopView ? <SalesProductDesktop /> : <SalesProductMobile />}
      </Stack>
    </SalesProductContextProvider>
  );
};

export default SalesProductPage;
