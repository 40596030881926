import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type InitialState = {
  allowAccess: boolean | null;
};

const initialState: InitialState = {
  allowAccess: null,
};

const KlaimSalesReducer = createSlice({
  name: "klaim-sales",
  initialState: initialState,
  reducers: {
    setAllowAccess: (state, action: PayloadAction<boolean>) => {
      state.allowAccess = action.payload;
    },
  },
});

export const { setAllowAccess: setAccessKlaimSales } =
  KlaimSalesReducer.actions;
export default KlaimSalesReducer.reducer;
