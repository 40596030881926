/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { DraftRencanaKunjunganListParam } from "../../dataProviders/RencanaKunjunganParamType";
import { DraftRencanaKunjunganModelData } from "../models/DraftRencanaKunjunganModel";
import RencanaKunjunganProvider from "../../dataProviders/RencanaKunjunganProvider";

const useGetDraftRencanaKunjunganList = (
  key: number,
  { header_id }: DraftRencanaKunjunganListParam
) => {
  const [draftRencanaKunjunganData, setData] = useState<
    DraftRencanaKunjunganModelData[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: DraftRencanaKunjunganModelData[] | undefined =
          await RencanaKunjunganProvider.getDraftRencanaKunjunganList({
            header_id: header_id,
          });

        setData(responseData!);

        setLoading(false);
      } catch (error: any) {
        setData([]);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    if (header_id !== null && header_id !== undefined) {
      getData();
    }
  }, [header_id, key]);

  return { draftRencanaKunjunganData, loading, errorMessage };
};

export default useGetDraftRencanaKunjunganList;
