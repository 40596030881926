import React, { useContext } from "react";
import { KlaimSalesDetailListModelItem } from "../../models/KlaimSalesDetailModel";
import {
  Grid,
  GridItem,
  useMediaQuery,
  useTheme,
  Text,
} from "@chakra-ui/react";
import CurrencyIdrFormatter from "../../../../utils/CurrencyIdrFormatter";
import DetailMonitorKlaimSalesContext from "../contexts/DetailMonitorKlaimSalesContext";
import { CommonStatus } from "../../../../models/common/Status";

interface Props {
  key?: number;
  isRowColored: boolean;
  data: KlaimSalesDetailListModelItem;
}

const DetailMonitorKlaimSalesCardItem: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { detailKlaimSalesData: record } = useContext(
    DetailMonitorKlaimSalesContext
  );

  const generateQuantityText = (): string => {
    if (record?.status === CommonStatus.Pending) {
      return `${props.data.claimQuantity.toString()} / ${props.data.realQuantity.toString()}`;
    } else {
      return `${props.data.claimQuantity.toString()}`;
    }
  };

  return (
    <Grid
      backgroundColor={
        props.isRowColored
          ? theme.colors.exodus.tableDataBackground
          : theme.colors.exodus.background
      }
      paddingY="6px"
      templateColumns="repeat(12, 1fr)"
      gap={2}
      alignItems="center"
    >
      <GridItem colSpan={9} textAlign={"left"}>
        <Text
          fontSize={isDesktopView ? 16 : 12}
          color={theme.colors.exodus.primaryBlue}
          fontWeight="semibold"
        >
          {props.data.productName}
        </Text>
        <Text fontSize={isDesktopView ? 14 : 12} fontWeight="medium">
          {CurrencyIdrFormatter.convertToRp(props.data.productPrice)}
        </Text>
      </GridItem>
      <GridItem
        colSpan={3}
        fontSize={isDesktopView ? 16 : 12}
        textAlign={"left"}
      >
        {generateQuantityText()}
      </GridItem>
    </Grid>
  );
};

export default DetailMonitorKlaimSalesCardItem;
