import saveAs from "file-saver";
import { useSalesProductContext } from "../contexts/SalesProductContextProvider";
import ExcelJS from "exceljs";
import { toUpper } from "lodash";
import LoggedUser from "../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../redux/hooks";
import React from "react";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import IsTypeOfErrorResponse from "../../../../utils/IsTypeOfErrorResponse";
import { SalesProductProvider } from "../dataProviders/SalesProductProvider";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { SalesProductRecordModel } from "../models/SalesProductModel";

const useExportExcel = () => {
  const {
    states: { filterValues },
  } = useSalesProductContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<ErrorResponse | null>(null);

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );
  async function doExportExcel() {
    try {
      if (filterValues?.startPeriod && filterValues?.endPeriod) {
        setIsLoading(true);

        const result = await SalesProductProvider.getListExcel({
          startDate: filterValues?.startPeriod!!,
          endDate: filterValues?.endPeriod!!,
          userId: filterValues?.bawahan?.id,
          sector: filterValues?.sector,
          salesZoneId: filterValues?.zone?.salesZoneId,
          salesZoneType: filterValues?.zone?.salesZoneType,
          products: filterValues?.productList,
          project: filterValues?.project?.value,
        });

        await exportData(result!!);
      }
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error);
      } else {
        setError(UNKNOWN_ERROR_RESPONSE);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const createFirstSheet = async (
    ExcelWorkBook: ExcelJS.Workbook,
    data: SalesProductRecordModel[]
  ) => {
    const firstSheet = ExcelWorkBook.addWorksheet();
    firstSheet.name = "Sales Produk";
    firstSheet.columns = [
      { key: "A", width: 25 },
      { key: "B", width: 25 },
      { key: "C", width: 28 },
      { key: "D", width: 30 },
      { key: "E", width: 20 },
      { key: "F", width: 20 },
      { key: "G", width: 22 },
      { key: "H", width: 22 },
    ];

    const rowHeaderPosition = 1;
    const rowHeader = firstSheet.getRow(rowHeaderPosition);

    rowHeader.alignment = {
      horizontal: "center",
    };

    // Isi data Header
    rowHeader.getCell(1).value = "Periode Awal";
    rowHeader.getCell(2).value = "Periode Akhir";
    rowHeader.getCell(3).value = "Nama Produk";
    rowHeader.getCell(4).value = "Kode Produk";
    rowHeader.getCell(5).value = "Unit";
    rowHeader.getCell(6).value = "Value";
    rowHeader.getCell(7).value = "Estimasi Unit";
    rowHeader.getCell(8).value = "Estimasi Value";

    for (let i = 1; i <= 8; i++) {
      rowHeader.getCell(i).font = {
        size: 14,
        bold: true,
      };
    }

    if (data) {
      for (let i = 0; i < data?.length; i++) {
        const row = firstSheet.getRow(rowHeaderPosition + 1 + i);
        row.alignment = {
          wrapText: true,
          horizontal: "center",
        };
        row.getCell(1).value =
          filterValues?.startPeriod.getStringDateFormat("DD MMMM YYYY");
        row.getCell(2).value =
          filterValues?.endPeriod.getStringDateFormat("DD MMMM YYYY");
        row.getCell(3).value = data[i].productName ?? "-";
        row.getCell(4).value = data[i].productCodeByPharos ?? "-";
        row.getCell(5).value = data[i].salesUnit ?? "-";
        row.getCell(6).value = data[i].salesValue ?? "-";
        row.getCell(7).value = data[i].estimatedUnit ?? "-";
        row.getCell(8).value = data[i].estimatedCost ?? "-";
      }
    }
  };

  const exportData = async (data: SalesProductRecordModel[]) => {
    const ExcelWorkBook = new ExcelJS.Workbook();

    const selectedJabatan = toUpper(filterValues?.role?.label) ?? "";
    const selectedSector = filterValues?.sector?.label ?? "";

    await createFirstSheet(ExcelWorkBook, data);
    const buf = await ExcelWorkBook.xlsx.writeBuffer();

    saveAs(
      new Blob([buf]),
      `Sales Produk - ${selectedJabatan} - ${loggedUser?.userName} - (${loggedUser?.userNip}) - ${selectedSector}.xlsx`
    );
  };
  return { isLoading, error, doExportExcel };
};

export default useExportExcel;
