import ProjectType from "../../../../../models/common/ProjectType";
import DateExodus from "../../../../../models/DateExodus";

class HeaderApprovalRealisasiKunjunganModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: HeaderApprovalRealisasiKunjunganModelData;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = new HeaderApprovalRealisasiKunjunganModelData(json["data"]);
  }
}

class HeaderApprovalRealisasiKunjunganModelData {
  id!: number;
  userId!: number;
  userName!: string;
  nip!: string;
  roleLabel!: string;
  approvalPlanId?: number;
  approvalPlanStatus!: string;
  approvalPlanTime!: DateExodus;
  approvalRealisasiId?: number;
  approvalRealisasiStatus!: string;
  approvalRealisasiTime?: DateExodus | null;
  description?: string;
  startDate!: DateExodus;
  endDate!: DateExodus;
  komentarAtasan?: string;
  detailRealisasi!: DetailApprovalRealisasiKunjunganModel[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: DetailApprovalRealisasiKunjunganModel[] = [];

    if (json["detail_realization"] !== null) {
      for (let i = 0; i < json.detail_realization.length; i++) {
        temp.push(
          new DetailApprovalRealisasiKunjunganModel(json.detail_realization[i])
        );
      }
    }

    this.id = json["id"];
    this.userId = json["user_id"];
    this.userName = json["user_name"];
    this.nip = json["user_nip"];
    this.roleLabel = json["role_label"];
    this.approvalPlanId = json["approval_plan_id"];
    this.approvalPlanStatus = json["approval_plan_status"];
    this.approvalPlanTime = DateExodus.fromBackend(json["approval_plan_time"]);
    this.approvalRealisasiId = json["approval_realization_id"];
    this.approvalRealisasiStatus = json["approval_realization_status"];
    this.approvalRealisasiTime = json["approval_realization_time"]
      ? DateExodus.fromBackend(json["approval_realization_time"])
      : null;
    this.description = json["description"];
    this.startDate = DateExodus.fromBackend(json["start_date"]);
    this.endDate = DateExodus.fromBackend(json["end_date"]);
    this.komentarAtasan = json["senior_comment_realization"];
    this.detailRealisasi = temp;
  }
}

class DetailApprovalRealisasiKunjunganModel {
  id!: number;
  date!: DateExodus | null;
  startTime!: DateExodus | null;
  endTime!: DateExodus | null;
  outletId!: number;
  outletCode!: string;
  outletName!: string;
  customerId!: number;
  customerCode!: string;
  customerName!: string;
  spesialis!: string;
  jabatan!: string;
  tujuan!: string;
  komentarKunjungan?: string;
  photo?: string;
  visitType!: string;
  formattedDate!: string;
  formattedStartTime!: string;
  formattedEndTime!: string;
  project!: ProjectType | null;

  constructor(json: any) {
    this.mapFromJson(json);
  }
  // record["assigned_date"] ? DateExodus.fromBackend(record["assigned_date"]) : null
  mapFromJson(json: any) {
    this.id = json["id"];
    this.date = json["date"] ? DateExodus.fromBackend(json["date"]) : null;
    this.startTime = json["start_time"]
      ? DateExodus.fromBackend(json["start_time"])
      : null;
    this.endTime = json["end_time"]
      ? DateExodus.fromBackend(json["end_time"])
      : null;
    this.outletId = json["outlet_id"];
    this.outletCode = json["outlet_code"];
    this.outletName = json["outlet_name"];
    this.customerId = json["customer_id"];
    this.customerCode = json["customer_code"];
    this.customerName = json["customer_name"];
    this.spesialis = json["customer_special"];
    this.jabatan = json["position_customer"];
    this.tujuan = json["purpose_of_visit"];
    this.komentarKunjungan = json["visitor_comment"];
    this.photo = json["photo"];
    this.visitType = json["visit_type"];
    this.formattedDate = DateExodus.fromBackend(
      json["date"]
    ).getStringDateFormat("dddd, D MMMM YYYY");
    this.formattedStartTime = DateExodus.fromBackend(
      json["start_time"]
    ).getStringDateFormat("HH.mm");
    this.formattedEndTime = DateExodus.fromBackend(
      json["end_time"]
    ).getStringDateFormat("HH.mm");
    this.project = json["project"] ?? null;
  }
}

export {
  HeaderApprovalRealisasiKunjunganModelPack,
  HeaderApprovalRealisasiKunjunganModelData,
  DetailApprovalRealisasiKunjunganModel,
};
