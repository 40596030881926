import { useState, useEffect } from "react";
import SalesOutletProvider from "../../../../dataProviders/SalesOutletProvider";
import { SalesOutletGetOneParam } from "../../../../dataProviders/SalesOutletProviderParams";
import ApiGetDetailSalesOutletResponse from "../../models/apiResponses/ApiGetDetailSalesOutletResponse";
import ErrorResponse from "../../../../../../../models/common/ErrorResponse";

const useGetOneDetail = (params: SalesOutletGetOneParam) => {
  const [data, setData] = useState<ApiGetDetailSalesOutletResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setError(null);
        setIsLoading(true);

        const result = await SalesOutletProvider.getOne(params);

        setData(result);
      } catch (error) {
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setIsLoading(false);
      }
    }
    if (params.endDate && params.startDate && params.limit && params.page) {
      getData();
    }
  }, [
    params.endDate,
    params.startDate,
    params.outletId,
    params.limit,
    params.page,
    params.userId,
  ]);

  return { data, isLoading, error };
};

export default useGetOneDetail;
