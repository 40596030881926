/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import AxiosBaseClient from "../../../../dataProviders/config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import {
  DetailApprovalRencanaKunjunganParams,
  DraftRencanaKunjunganCreateParam,
  DraftRencanaKunjunganDeleteParam,
  DraftRencanaKunjunganListParam,
  DraftRencanaKunjunganUpdateParam,
  MinimalVisitParam,
} from "./RencanaKunjunganParamType";
import MinimalVisitDataModel from "../Input/models/MinimalVisitDataModel";
import { DraftRencanaKunjunganModelPack } from "../Input/models/DraftRencanaKunjunganModel";
import { DraftRencanaKunjunganAfterCreateData } from "../Input/models/CreateDraftRencanaKunjunganModel";
import { DetailApprovalRencanaKunjunganModelData } from "../Approval/models/ApprovalRencanaKunjunganData";
import IndexedDatabase from "../../../../database/IndexDB";
import { isEmpty } from "lodash";
import {
  GET_DATA_DB_ERROR_RESPONSE,
  SAVE_TO_DB_ERROR_RESPONSE,
} from "../Approval/constants/Constants";
import StoredUserId from "../Approval/models/StoredUserId";
import ApprovalRencanaMetadata from "../Approval/models/ApprovalRencanaMetadata";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const RencanaKunjunganProvider = {
  getMinimalVisit: async (params: MinimalVisitParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `/v1/rencana/minimalvisit/${params.role_id}`
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData: MinimalVisitDataModel = {
            ID: response.data.data["ID"],
            role_id: response.data.data["role_id"],
            role_name: response.data.data["role_name"],
            minimalvisit: response.data.data["minimalvisit"],
          };

          return Promise.resolve(responseData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  getDraftRencanaKunjunganList: async (
    params: DraftRencanaKunjunganListParam
  ) => {
    try {
      if (params.header_id === 0) {
        return Promise.resolve([]);
      }

      const response: AxiosResponse = await AxiosBaseClient.get(
        `/v1/rencana/by/header/${params.header_id}`
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new DraftRencanaKunjunganModelPack(
            response.data
          );
          return Promise.resolve(responseData.data);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  createDraftRencanaKunjungan: async (
    params: DraftRencanaKunjunganCreateParam
  ) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.post(
        "/v1/rencana/create",
        params.data
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData: DraftRencanaKunjunganAfterCreateData = {
            message: response.data["message"],
            visitHeaderId: response.data.data["visit_header_id"],
          };

          return Promise.resolve(responseData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  updateDraftRencanaKunjungan: async (
    params: DraftRencanaKunjunganUpdateParam
  ) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.put(
        `/v1/rencana/detail/${params.id}`,
        params.data
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  deleteDraftRencanaKunjungan: async (
    params: DraftRencanaKunjunganDeleteParam
  ) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.delete(
        `/v1/rencana/detail/${params.id}`
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message.includes(SUCCESS_MESSAGE.toLowerCase())) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  getDetailApprovalRencanaKunjungan: async (
    params: DetailApprovalRencanaKunjunganParams
  ) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `/v1/rencana/header/${params.id}/user/${params.user_id}`
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new DetailApprovalRencanaKunjunganModelData(
            response.data.data
          );
          return Promise.resolve(responseData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  saveUserIdRencanaKunjunganDetail: async (userId: number) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.detailApprovalRencanaKunjungan.toArray();

      if (databaseResponse.length === 0) {
        const newRecord: StoredUserId = { id: 0, userId };
        await IndexedDatabase.detailApprovalRencanaKunjungan.add(newRecord);
        return Promise.resolve(SUCCESS_MESSAGE);
      } else if (databaseResponse && databaseResponse.length !== 0) {
        const recordToUpdate = { ...databaseResponse[0], userId };
        await IndexedDatabase.detailApprovalRencanaKunjungan.update(
          databaseResponse[0].id,
          recordToUpdate
        );
        return Promise.resolve(SUCCESS_MESSAGE);
      }

      await IndexedDatabase.detailApprovalRencanaKunjungan.clear();
      return Promise.reject(SAVE_TO_DB_ERROR_RESPONSE);
    } catch (error: unknown) {
      return Promise.reject(SAVE_TO_DB_ERROR_RESPONSE);
    }
  },
  getUserIdRencanaKunjunganDetail: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.detailApprovalRencanaKunjungan.toArray();

      if (
        databaseResponse &&
        !isEmpty(databaseResponse) &&
        databaseResponse.length !== 0
      ) {
        return Promise.resolve(databaseResponse[0]);
      }
      return Promise.resolve(null);
    } catch (error) {
      return Promise.reject(GET_DATA_DB_ERROR_RESPONSE);
    }
  },
  saveMetadataApprovalRencana: async (data: ApprovalRencanaMetadata) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.approvalRencanaKunjunganMetadata.toArray();

      if (databaseResponse.length === 0) {
        const newRecord: ApprovalRencanaMetadata = { id: 0, ...data };
        await IndexedDatabase.approvalRencanaKunjunganMetadata.add(newRecord);
        return Promise.resolve(SUCCESS_MESSAGE);
      } else if (databaseResponse && databaseResponse.length !== 0) {
        const recordToUpdate = { ...databaseResponse[0], ...data };
        await IndexedDatabase.approvalRencanaKunjunganMetadata.update(
          databaseResponse[0].id,
          recordToUpdate
        );
        return Promise.resolve(SUCCESS_MESSAGE);
      }

      await IndexedDatabase.approvalRencanaKunjunganMetadata.clear();
      return Promise.reject(SAVE_TO_DB_ERROR_RESPONSE);
    } catch (error: unknown) {
      return Promise.reject(SAVE_TO_DB_ERROR_RESPONSE);
    }
  },
  getMetadataApprovalRencana: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.approvalRencanaKunjunganMetadata.toArray();

      if (
        databaseResponse &&
        !isEmpty(databaseResponse) &&
        databaseResponse.length !== 0
      ) {
        return Promise.resolve(databaseResponse[0]);
      }
      return Promise.resolve(null);
    } catch (error) {
      return Promise.reject(GET_DATA_DB_ERROR_RESPONSE);
    }
  },
};

export default RencanaKunjunganProvider;
