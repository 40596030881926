/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react";
import { Box, Spinner, useTheme } from "@chakra-ui/react";
import InputRealisasiKunjunganContext from "../../context/InputRealisasiKunjunganContext";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import GenerateRealisasiTableColumnData from "../../services/generateRealisasiTableColumnData";
import GenerateExtraCallTableColumnData from "../../services/generateExtraCallTableColumnData";

const DesktopTableRealisasiKunjungan: React.FC = () => {
  const theme = useTheme();

  const {
    realisasiOrExtraCallData,
    realisasiOrExtraCallLoading,
    realisasiOrExtraCallError,
    currentKunjungan,
  } = useContext(InputRealisasiKunjunganContext);

  if (realisasiOrExtraCallLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (realisasiOrExtraCallError) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={realisasiOrExtraCallError.toString()} />
      </Box>
    );
  }

  return realisasiOrExtraCallData.length > 0 ? (
    <Box marginTop="24px" marginBottom="15px">
      <TableDataComponent
        data={realisasiOrExtraCallData}
        tableHeader={
          currentKunjungan === "extracall"
            ? GenerateExtraCallTableColumnData()
            : GenerateRealisasiTableColumnData()
        }
        onSelectChange={() => {}}
        paginationOn={false}
        paginationLimit={[]}
        checboxOn={false}
      />
    </Box>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default DesktopTableRealisasiKunjungan;
