import { FocusEvent, useState } from "react";
import { MdInsertInvitation } from "react-icons/md";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RegisterOptions, useController } from "react-hook-form";
import { Text, useTheme } from "@chakra-ui/react";
import id from "date-fns/locale/id";
// import dayjs from "dayjs";
import "./CustomDatePicker.css";
registerLocale("id", id);

export type CustomDatePickerProps = {
  name: string;
  type: "date" | "month" | "month_year" | "year" | "quarter" | "time";
  selected?: Date | null;
  defaultValue?: Date | null;
  onChange?: (data: Date | null) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement, Element>) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  rules?: RegisterOptions;
  placeholder?: string;
  disabled?: boolean;
  timeCaption?: string;
  isClearable?: boolean;
};

const CustomDatePicker: React.FC<CustomDatePickerProps> = (props) => {
  const theme = useTheme();
  const input = useController({
    name: props.name,
    // defaultValue: props.defaultValue ?? dayjs().startOf("day").toDate(), // Use this if we want to set the defaultValue to today if we didn't pass any value to defaultValue props
    defaultValue: props.defaultValue,
    rules: props.rules,
  });

  // react-datepicker only accept Date | null | undefined as value
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    // props.defaultValue ?? dayjs().startOf("day").toDate() // Use this if we want to set the defaultValue to today if we didn't pass any value to defaultValue props
    props.defaultValue
  );

  const generateDateFormat = (): string => {
    if (props.type === "date") return "d MMMM yyyy";
    if (props.type === "month") return "MMMM";
    if (props.type === "month_year") return "MMMM yyyy";
    if (props.type === "year") return "yyyy";
    if (props.type === "quarter") return "QQQ, yyyy";
    if (props.type === "time") return "HH:mm";
    return "d MMMM yyyy";
  };

  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  return (
    <>
      <DatePicker
        locale={id}
        {...input}
        wrapperClassName={"input-container-wrapper"}
        className={
          props.disabled
            ? "disabled-style"
            : input.fieldState.error
              ? "react-datepicker__input-error"
              : "normal-style"
        }
        calendarIconClassname="calendar-icon"
        selected={props.selected ?? selectedDate}
        onChange={(date) => {
          // console.log(dayjs(date)); //to convert Date to Dayjs
          // console.log(dayjs(date).unix()); //to convert Date to unix
          setSelectedDate(date);
          if (props.onChange) {
            props.onChange(date);
          }
          input.field.onChange(date);
          setOpenCalendar(false);
        }}
        onBlur={props.onBlur}
        dropdownMode="select"
        placeholderText={props.placeholder}
        readOnly={props.disabled}
        showPopperArrow={false}
        dateFormat={generateDateFormat()}
        minDate={props.minDate}
        maxDate={props.maxDate}
        // MONTH DROPDOWN
        showMonthDropdown={props.type === "date"}
        scrollableMonthYearDropdown={props.type === "date"}
        // YEAR DROPDOWN
        showYearDropdown={props.type === "date"}
        scrollableYearDropdown={props.type === "date"}
        // ICON
        showIcon
        icon={
          <MdInsertInvitation
            onClick={() => {
              if (!props.disabled) {
                setOpenCalendar(true);
              }
            }}
            style={{
              cursor: props.disabled ? "not-allowed" : "text",
            }}
          />
        }
        // MONTH PICKER
        showMonthYearPicker={
          props.type === "month" || props.type === "month_year"
        }
        // YEAR PICKER
        showYearPicker={props.type === "year"}
        yearItemNumber={15}
        // QUARTAL PICKER
        showQuarterYearPicker={props.type === "quarter"}
        // TIME PICKER
        showTimeSelect={props.type === "time"}
        showTimeSelectOnly={props.type === "time"}
        timeCaption={props.timeCaption}
        timeIntervals={5}
        timeFormat="HH:mm"
        isClearable={props.isClearable && !props.disabled}
        open={openCalendar}
        onClickOutside={() => setOpenCalendar(false)}
        onInputClick={() => {
          if (!props.disabled) {
            setOpenCalendar(true);
          }
        }}
      />
      {input.fieldState.error && (
        <Text marginTop="8px" fontSize={14} color={theme.colors.exodus.red}>
          {input.fieldState.error.message}
        </Text>
      )}
    </>
  );
};

export default CustomDatePicker;
