import ErrorResponse from "../models/common/ErrorResponse";

const IsTypeOfErrorResponse = (error: unknown): error is ErrorResponse => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    "status" in error &&
    "code" in error
  );
};

export default IsTypeOfErrorResponse;