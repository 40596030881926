import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import InputRealisasiKunjunganBody from "../models/InputRealisasiKunjunganModel";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../models/RealisasiAndExtraCallDataModel";
import { useContext, useState } from "react";
import RealisasiExtraCallForeverDisabledField from "./form/RealisasiExtraCallForeverDisabledField";
import RealisasiExtraCallDateTimeFormField from "./form/RealisasiExtraCallDateTimeFormField";
import RealisasiExtraCallKomentarKunjunganField from "./form/RealisasiExtraCallKomentarKunjunganField";
import RealisasiExtraCallImagePickerFormField from "./form/RealisasiExtraCallImagePickerFormField";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";
import dayjs from "dayjs";

type Props = {
  data: RealisasiAndExtraCallDetailRealizationModelData;
  openDialogInputRealisasi: boolean;
  onClose: () => void;
  onSubmit: (
    data: InputRealisasiKunjunganBody,
    isGagalKunjungan: boolean
  ) => void;
  isLoadingSubmit: boolean;
  isLoadingPhotoProcessing: boolean;
};

const DialogInputRealisasiKunjungan: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { selectedPeriode } = useContext(InputRealisasiKunjunganContext);

  const [isGagalKunjungan, setIsGagalKunjungan] = useState<boolean>(
    props.data.komentarKunjungan && !props.data.date ? true : false
  );
  const [dateValue, setDateValue] = useState<Date | number>(
    props.data.date
      ? props.data.date.getEpochDate() * 1000
      : selectedPeriode
        ? dayjs(selectedPeriode.start_period.getEpochDate() * 1000).toDate()
        : dayjs().startOf("date").toDate()
  );
  const [startTime, setStartTime] = useState<Date | number>(
    props.data.startTime
      ? props.data.startTime.getEpochDate() * 1000
      : selectedPeriode
        ? dayjs(selectedPeriode!.start_period.getEpochDate() * 1000)
            .startOf("date")
            .toDate()
        : dayjs().startOf("date").toDate()
  );
  const [endTime, setEndTime] = useState<Date | number>(
    props.data.endTime
      ? props.data.endTime.getEpochDate() * 1000
      : selectedPeriode
        ? dayjs(selectedPeriode!.start_period.getEpochDate() * 1000)
            .endOf("date")
            .toDate()
        : dayjs().endOf("date").toDate()
  );

  const form = useForm<InputRealisasiKunjunganBody>();

  const handleInputOrUpdateRealisasi = async (
    value: InputRealisasiKunjunganBody
  ) => {
    props.onSubmit(value, isGagalKunjungan);
  };

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      isOpen={props.openDialogInputRealisasi}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          Input Realisasi Kunjungan
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form
              id="form-input-realisasi"
              onSubmit={form.handleSubmit(handleInputOrUpdateRealisasi)}
            >
              <RealisasiExtraCallForeverDisabledField
                title="Outlet"
                value={props.data.outletName}
              />
              <RealisasiExtraCallForeverDisabledField
                title="Customer"
                value={props.data.customerName}
              />
              <Box
                marginBottom={!isDesktopView ? 0 : "10px"}
                marginTop={!isDesktopView ? "10px" : 0}
              >
                <Checkbox
                  isChecked={isGagalKunjungan}
                  onChange={(data) => setIsGagalKunjungan(data.target.checked)}
                >
                  Gagal Kunjungan
                </Checkbox>
              </Box>
              <Box marginBottom={!isDesktopView ? 0 : "20px"}>
                <RealisasiExtraCallDateTimeFormField
                  value={dateValue}
                  formInputLabel="Tanggal Kunjungan"
                  name="date"
                  type="date"
                  onChange={(data) => {
                    if (data) {
                      setDateValue(data!);
                      setStartTime(dayjs(data).startOf("day").toDate());
                      setEndTime(dayjs(data).endOf("day").toDate());
                    }
                  }}
                  disabled={isGagalKunjungan}
                  minDate={
                    selectedPeriode
                      ? dayjs(
                          selectedPeriode.start_period.getEpochDate() * 1000
                        ).toDate()
                      : undefined
                  }
                  maxDate={
                    selectedPeriode
                      ? dayjs(
                          selectedPeriode.end_period.getEpochDate() * 1000
                        ).toDate()
                      : undefined
                  }
                />
              </Box>
              <Flex
                marginBottom={!isDesktopView ? 0 : "20px"}
                gap={!isDesktopView ? 0 : 9}
                direction={isDesktopView ? "row" : "column"}
              >
                <RealisasiExtraCallDateTimeFormField
                  value={startTime}
                  formInputLabel="Jam Mulai"
                  name="start_time"
                  type="time"
                  onChange={(data) => {
                    if (data) setStartTime(data);
                  }}
                  disabled={isGagalKunjungan}
                />
                <RealisasiExtraCallDateTimeFormField
                  value={endTime}
                  formInputLabel="Jam Selesai"
                  name="end_time"
                  type="time"
                  onChange={(data) => {
                    if (data) setEndTime(data);
                  }}
                  disabled={isGagalKunjungan}
                />
              </Flex>
              <RealisasiExtraCallForeverDisabledField
                title="Jenis Kegiatan"
                value={props.data.tujuanKunjungan}
              />
              <RealisasiExtraCallImagePickerFormField
                isGagalKunjungan={isGagalKunjungan}
                defaultValue={props.data.photo ?? undefined}
              />
              <RealisasiExtraCallKomentarKunjunganField
                defaultValue={props.data.komentarKunjungan ?? undefined}
              />
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter
          flexDirection={!isDesktopView ? "column" : "row-reverse"}
          gap={!isDesktopView ? 0 : 5}
        >
          <Button
            type="submit"
            form="form-input-realisasi"
            width="100%"
            colorScheme="buttonPrimary"
            isLoading={props.isLoadingPhotoProcessing || props.isLoadingSubmit}
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              setIsGagalKunjungan(false);
              setDateValue(
                props.data.date
                  ? props.data.date.getEpochDate() * 1000
                  : dayjs(
                      selectedPeriode!.start_period.getEpochDate() * 1000
                    ).toDate()
              );
              setStartTime(
                props.data.startTime
                  ? props.data.startTime.getEpochDate() * 1000
                  : dayjs().startOf("date").toDate()
              );
              setEndTime(
                props.data.endTime
                  ? props.data.endTime.getEpochDate() * 1000
                  : dayjs().endOf("date").toDate()
              );
              form.reset();
              props.onClose();
            }}
            width="100%"
            variant="outline"
            colorScheme="buttonSecondary"
            marginTop={!isDesktopView ? "12px" : 0}
            isDisabled={props.isLoadingPhotoProcessing || props.isLoadingSubmit}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DialogInputRealisasiKunjungan;
