import { Box, useMediaQuery, useTheme } from "@chakra-ui/react";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import DesktopInputSalesTarget from "./desktop/DesktopInputSalesTarget";
import MobileInputTargetSales from "./mobile/MobileInputTargetSales";
import DateExodus from "../../../../../models/DateExodus";
import SalesTargetQuarterModel from "../../models/SalesTargetQuarterModel";
import { useContext, useEffect, useState } from "react";
import CustomDatePicker from "../../../../../components/DatePicker/CustomDatePicker";
import { FormProvider, useForm } from "react-hook-form";
import SalesTargetQuarterSelect from "../../components/SalesTargetQuarterSelect";
import dayjs from "dayjs";
import InputSalesTargetContext from "../context/InputSalesTargetContext";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import ProjectType from "../../../../../models/common/ProjectType";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";

const InputSalesTargetPage = () => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const [selectedYear, setSelectedYear] = useState<Date | number | null>(null);
  const [selectedQuarter, setSelectedQuarter] =
    useState<SalesTargetQuarterModel | null>(null);
  const { filterValues, saveDataToIndexedDB } = useContext(
    InputSalesTargetContext
  );
  const [counter, setCounter] = useState<number>(0);
  const methods = useForm();

  const handleYearChange = (data: Date | null) => {
    setSelectedYear(data!);

    if (counter === 0) setCounter(counter + 1);
  };

  useEffect(() => {
    if (filterValues && filterValues.periodeTahun && !selectedYear) {
      setSelectedYear(filterValues.periodeTahun);
    }
  }, [filterValues, selectedYear]);

  useEffect(() => {
    if (
      filterValues &&
      filterValues.periodeKuartalStart &&
      filterValues.periodeKuartalEnd &&
      !selectedQuarter
    ) {
      setSelectedQuarter({
        id: filterValues.idKuartal!,
        label: filterValues.labelKuartal!,
        value: {
          start_month: new DateExodus(filterValues.periodeKuartalStart),
          end_month: new DateExodus(filterValues.periodeKuartalEnd),
        },
      });
    }
  }, [counter, filterValues, selectedQuarter]);

  const handleQuarterChange = (data: SalesTargetQuarterModel | null) => {
    if (
      filterValues &&
      filterValues.periodeKuartalStart &&
      filterValues.periodeKuartalEnd &&
      counter !== 0
    ) {
      setSelectedQuarter(data);
      saveDataToIndexedDB({
        page: 1,
        limit: filterValues.limit,
        periodeTahun:
          typeof selectedYear === "number"
            ? selectedYear
            : new DateExodus(dayjs(selectedYear).unix()).getEpochDate(),
        idKuartal: data?.id,
        labelKuartal: data?.label,
        periodeKuartalStart: data?.value.start_month.getEpochStartOfMonth(),
        periodeKuartalEnd: data?.value.end_month.getEpochEndOfMonth(),
        project: filterValues.project,
      });

      // UNTUK EKSEKUSI PERTAMA KALI MASUK SINI
    } else if (
      filterValues &&
      filterValues.periodeKuartalStart &&
      filterValues.periodeKuartalEnd &&
      counter === 0
    ) {
      const previousData = {
        page: filterValues.page,
        limit: filterValues.limit,
        periodeTahun:
          typeof selectedYear === "number"
            ? selectedYear
            : new DateExodus(dayjs(selectedYear).unix()).getEpochDate(),
        project: filterValues.project,
      };

      // KALAU SEBELUMNYA SUDAH GANTI TAHUN DAN DISIMPAN DI INDEXED DB, MASUK KESINI UNTUK SET VALUE BARU
      // PERTAMA KALI SAAT PAGE DIBUKA
      if (
        new DateExodus(filterValues.periodeTahun!)
          .getDateObject()
          .getFullYear() !==
        data?.value.start_month.getDateObject().getFullYear()
      ) {
        saveDataToIndexedDB({
          ...previousData,
          idKuartal: filterValues.idKuartal,
          labelKuartal: filterValues.labelKuartal,
          periodeKuartalStart: filterValues.periodeKuartalStart,
          periodeKuartalEnd: filterValues.periodeKuartalEnd,
        });

        setCounter(counter + 1);
      } else {
        // KALAU SEBELUMNYA SUDAH GANTI PERIODE DI TAHUN YANG BERJALAN SEKARANG DAN DISIMPAN DI INDEXED DB,
        // ATAU INI PERTAMA KALI DIEKSESUI SAAT BELUM ADA INDEXED DB, MASUK KESINI

        setSelectedQuarter(data);
        saveDataToIndexedDB({
          ...previousData,
          idKuartal: data?.id,
          labelKuartal: data?.label,
          periodeKuartalStart: data?.value.start_month.getEpochStartOfMonth(),
          periodeKuartalEnd: data?.value.end_month.getEpochEndOfMonth(),
        });

        setCounter(counter + 1);
      }
    }
  };

  return (
    <Box width={"100%"}>
      <PageTitle label="Input Sales Target" />
      <Box paddingTop={"10px"} textAlign={"left"}>
        <FormProvider {...methods}>
          <form>
            <Box
              display={"Flex"}
              flexDirection={isDesktopView ? "row" : "column"}
              gap={5}
            >
              <Box width={isDesktopView ? "22%" : "100%"}>
                <FormInputLabel title="Project" />
                <CustomSelect
                  options={loggedUser?.projects ?? []}
                  isDisabled={
                    loggedUser ? loggedUser.projects.length <= 1 : false
                  }
                  getOptionLabel={(data: ProjectType) => data.label}
                  getOptionValue={(data: ProjectType) => data.value}
                  value={filterValues?.project}
                  placeholder="Project"
                  onChange={(data: ProjectType | null) => {
                    if (!data) {
                      saveDataToIndexedDB({
                        ...filterValues,
                        page: 1,
                        limit: filterValues?.limit ?? 10,
                        project: null,
                      });
                    } else {
                      saveDataToIndexedDB({
                        ...filterValues,
                        page: 1,
                        limit: filterValues?.limit ?? 10,
                        project: data,
                      });
                    }
                  }}
                />
              </Box>
              <Box width={isDesktopView ? "22%" : "100%"}>
                <FormInputLabel title="Periode Kuartal" />
                <SalesTargetQuarterSelect
                  year={
                    typeof selectedYear === "number"
                      ? new DateExodus(selectedYear)
                      : new DateExodus(dayjs(selectedYear).unix())
                  }
                  onChange={(data) => {
                    handleQuarterChange(data);
                  }}
                  value={selectedQuarter}
                />
              </Box>
              <Box width={isDesktopView ? "16%" : "100%"}>
                <FormInputLabel title="Tahun" />
                <CustomDatePicker
                  name="yearPicker"
                  type="year"
                  onChange={handleYearChange}
                  defaultValue={
                    typeof selectedYear === "number"
                      ? new DateExodus(selectedYear).getDateObject()
                      : (selectedYear as Date)
                  }
                  selected={
                    typeof selectedYear === "number"
                      ? new DateExodus(selectedYear).getDateObject()
                      : (selectedYear as Date)
                  }
                  placeholder="Select Year"
                />
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
      {isDesktopView && <DesktopInputSalesTarget />}
      {!isDesktopView && <MobileInputTargetSales />}
    </Box>
  );
};

export default InputSalesTargetPage;
