import { Button, useMediaQuery, useTheme, useToast } from "@chakra-ui/react";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";
import { ROLE_ID_SUPER_ADMIN, ROLE_ID_TRADE_TEAM } from "../../../../../constants/common/RoleConstants";
import { useContext, useState } from "react";
import DialogConfirmationApprovalSalesTarget from "./DialogConfirmationApprovalSalesTarget";
import MonitorSalesTargetContext from "../context/MonitorSalesTargetContext";
import useApproveSalesTarget from "../../services/useApproveSalesTarget";
import ErrorResponse from "../../../../../models/common/ErrorResponse";

const ButtonApproveMonitorSalesTarget: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const onClose = () => {
    setOpenConfirmDialog(false);
  };

  const {
    selectedSalesTarget,
    setSelectedSalesTarget,
    refetchSalesTargetData,
  } = useContext(MonitorSalesTargetContext);

  const { approveSalesTarget, loadingApprove } = useApproveSalesTarget();

  const handleSubmitApproveSalesTarget = () => {
    approveSalesTarget(
      {
        target_sales_id: selectedSalesTarget,
      },
      {
        onSuccess: () => {
          toast({
            title: "Berhasil Approve Sales Target",
            status: "success",
          });
          onClose();
          setSelectedSalesTarget([]);
          refetchSalesTargetData();
        },
        onError: (error: ErrorResponse) => {
          toast({
            title: "Gagal Approve Sales Target",
            description: error.message,
            status: "error",
          });
        },
      }
    );
  };

  return (loggedUser?.userRoleId === ROLE_ID_TRADE_TEAM || loggedUser?.userRoleId === ROLE_ID_SUPER_ADMIN) ? (
    <>
      <Button
        variant="solid"
        colorScheme="buttonSuccess"
        maxWidth={isDesktopView ? "173px" : "100%"}
        width="100%"
        onClick={() => setOpenConfirmDialog(true)}
        isDisabled={selectedSalesTarget.length === 0}
      >
        Approve
      </Button>
      <DialogConfirmationApprovalSalesTarget
        onClose={onClose}
        onSubmit={handleSubmitApproveSalesTarget}
        openConfirm={openConfirmDialog}
        isLoading={loadingApprove}
      />
    </>
  ) : null;
};

export default ButtonApproveMonitorSalesTarget;
