import { useEffect, useState } from "react";
import InputKlaimSalesStoredDataModel from "../../../models/InputKlaimSalesStoredDataModel";
import InputKlaimSalesProvider from "../../../dataProviders/InputKlaimSalesProvider";
import { useToast } from "@chakra-ui/react";

const useSummaryKlaimSalesState = () => {
  const [storedKlaimSalesData, setStoredKlaimSalesData] =
    useState<InputKlaimSalesStoredDataModel | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  const toast = useToast();

  useEffect(() => {
    getStoredKlaimSalesData();
  }, []);

  const getStoredKlaimSalesData = async () => {
    try {
      setErrorMessage(null);
      setLoading(true);

      const data: InputKlaimSalesStoredDataModel | null =
        await InputKlaimSalesProvider.getSavedInputKlaimSalesData();

      if (data) {
        setStoredKlaimSalesData(data);
      }

      setLoading(false);
    } catch (error) {
      setErrorMessage(error as string);
      setLoading(false);
    }
  };

  const removeAllInputKlaimSalesData = async () => {
    try {
      setErrorMessage(null);
      setLoading(true);

      await InputKlaimSalesProvider.removeAllInputKlaimSalesData();

      setLoading(false);
    } catch (error) {
      setErrorMessage(error as string);
      setLoading(false);
    }
  };

  const handleSelectPdfFile = (data: HTMLInputElement) => {
    const maxFileSize = 3 * 1024 * 1024;

    if (data.files && data.files.length > 0) {
      const pdfData = data.files[0];

      if (pdfData.size > maxFileSize) {
        toast({
          title: "Ukuran File tidak boleh melebihi 3 MB",
          status: "warning",
        });
        return;
      }

      setSelectedFile(pdfData);
      setSelectedFileName(pdfData.name);
    } else {
      setSelectedFile(null);
      setSelectedFileName(null);
    }
  };

  const getFileBase64 = async () => {
    if (!selectedFile) {
      toast({
        title: "Silahkan pilih File PDF untuk diupload",
        status: "warning",
      });
      return null;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile!);

      reader.onloadend = () => {
        if (reader.result) {
          const base64Result = reader.result as string;
          resolve(base64Result.split(",")[1]);
        } else {
          reject(
            new Error(
              "Error converting file to base64. Error detail: " +
                reader.error?.message
            )
          );
        }
      };
    });
  };

  return {
    storedKlaimSalesData,
    loading,
    errorMessage,
    selectedFile,
    selectedFileName,
    handleSelectPdfFile,
    removeAllInputKlaimSalesData,
    getFileBase64,
  };
};

export default useSummaryKlaimSalesState;
