import { Stack, Text } from "@chakra-ui/react";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";

const TotalBiayaStack = ({
  totalSalesValue,
}: {
  totalSalesValue: number | undefined;
}) => {
  return (
    <Stack width={"100%"} direction={"row"} justifyContent={"flex-start"}>
      <Text fontWeight={600} fontSize={20}>
        Total Biaya: {CurrencyIdrFormatter.convertToRp(totalSalesValue ?? 0)}
      </Text>
    </Stack>
  );
};

export default TotalBiayaStack;
