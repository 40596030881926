import { useMediaQuery, useTheme, Box } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import UserModel from "../../../../../../models/Users/UserModel";
import useGetUserByRole from "../../../../../../services/Users/useGetUserByRole";

const FilterByBawahan = () => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const formContext = useFormContext();
  const watchRole = formContext.watch("role");
  const watchBawahan = formContext.watch("bawahan");

  const { userData } = useGetUserByRole({
    roleName: watchRole?.name ?? "",
  });

  return (
    <Box width={isMobile ? "100%" : "60%"}>
      <FormInputLabel title="Cari Nama" />
      <CustomSelect
        options={userData}
        value={watchBawahan}
        isDisabled={isEmpty(watchRole)}
        onChange={(value: UserModel | null) => {
          if (value) {
            formContext.setValue("bawahan", value);
            formContext.setValue("zone", null);
          } else {
            formContext.setValue("bawahan", null);
          }
          formContext.setValue("outlet", null);
        }}
        getOptionLabel={(bawahan) => `${bawahan.name} - ${bawahan.nip}`}
        getOptionValue={(eachBawahan) => eachBawahan.nip}
        defaultValue={null}
        placeholder="Nama - Nip"
        isClearable
      />
    </Box>
  );
};

export default FilterByBawahan;
