import LoggedUser from "../../models/common/LoggedUser";
import { AuthenticationProvider } from "../../dataProviders/common/Authentication/AuthenticationProvider";

const useGenerateNewToken = async () => {
  try {
    const response: LoggedUser = await AuthenticationProvider.generateNewToken();

    return Promise.resolve(response);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export default useGenerateNewToken;
