import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  useTheme,
} from "@chakra-ui/react";
import HorizontalAccordionDetail from "../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import { SalesProductRecordModel } from "../../../models/SalesProductModel";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";

const SalesProductAccordionItem = ({
  record,
}: {
  record: SalesProductRecordModel;
}) => {
  const theme = useTheme();
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            fontSize={14}
          >
            {record.productName}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel mx={4}>
        <Text
          fontWeight={600}
          fontSize={18}
          color={theme.colors.exodus.primaryBlue}
          textAlign={"left"}
          marginBottom={2}
        >
          Detail Sales
        </Text>
        <HorizontalAccordionDetail
          leftText="Unit"
          rightSide={record.salesUnit ?? "-"}
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText="Value"
          rightSide={CurrencyIdrFormatter.convertToRp(record.salesValue) ?? "-"}
        />
        <HorizontalAccordionDetail
          leftText="Estimasi Unit"
          rightSide={record.estimatedUnit ?? "-"}
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText="Estimasi Value"
          rightSide={
            CurrencyIdrFormatter.convertToRp(record.estimatedCost) ?? "-"
          }
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default SalesProductAccordionItem;
