import ExcelJS from "exceljs";
import { useState } from "react";
import saveAs from "file-saver";
import { isEmpty } from "lodash";
import { GetListOutletCardModelData } from "../../models/GetListOutletCardModel";
import { ROLE_ID_FF } from "../../../../../constants/common/RoleConstants";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";
import { useToast } from "@chakra-ui/react";
import {
  GetSaldoKontribusiModelData,
  GetSaldoKontribusiProdukDetail,
} from "../../models/GetSaldoKontribusiModel";
import OutletCardProvider from "../../dataProviders/OutletCardProvider";
import DateExodus from "../../../../../models/DateExodus";
import CapitalizeFirstWord from "../../../../../utils/CapitalizeFirstWord";

const useExportExcelDetail = (
  filterValues: { startDate: DateExodus; endDate: DateExodus },
  excelHeaderListData: Array<GetListOutletCardModelData>
) => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );
  const toast = useToast();

  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  const getExcelSaldoKontribusiDetail = async () => {
    try {
      const listOutletId = excelHeaderListData?.map(
        (item: GetListOutletCardModelData) => item.outletId
      );

      const result: GetSaldoKontribusiModelData | null | undefined =
        await OutletCardProvider.getSaldoKontribusi(
          { page: 1, limit: 99999 },
          {
            startDate: filterValues?.startDate!!,
            endDate: filterValues?.endDate!!,
            outletId: listOutletId,
          }
        );
      return Promise.resolve((result as GetSaldoKontribusiModelData) ?? []);
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  };

  const exportFirstSheet = async (ExcelWorkBook: ExcelJS.Workbook) => {
    const firstSheet = ExcelWorkBook.addWorksheet();
    firstSheet.name = "Header";

    firstSheet.columns = [
      { key: "A", width: 20 },
      { key: "B", width: 20 },
      { key: "C", width: 35 },
      { key: "D", width: 20 },
      { key: "E", width: 30 },
      { key: "F", width: 30 },
      { key: "G", width: 30 },
      { key: "H", width: 30 },
    ];

    const rowHeaderPosition = 1;
    const rowHeader = firstSheet.getRow(rowHeaderPosition);

    rowHeader.getCell(1).value = "Project";
    rowHeader.getCell(2).value = "Kode PI Outlet";
    rowHeader.getCell(3).value = "Nama Outlet";
    rowHeader.getCell(4).value = "Sektor";
    rowHeader.getCell(5).value = "Biaya Dasar";
    rowHeader.getCell(6).value = "Saldo Kontribusi";
    rowHeader.getCell(7).value = "Sisa Saldo";
    rowHeader.getCell(8).value = "Biaya Murni";

    for (let i = 1; i <= 8; i++) {
      rowHeader.getCell(i).font = {
        size: 12,
        bold: true,
      };
      rowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
    }

    for (let i = 1; i <= 8; i++) {
      for (let j = 1; j <= excelHeaderListData?.length + 1; j++) {
        const cell = firstSheet.getCell(j, i);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
    for (let i = 0; i < excelHeaderListData?.length; i++) {
      const row = firstSheet.getRow(rowHeaderPosition + 1 + i);
      row.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      };

      row.getCell(1).value = excelHeaderListData[i].project
        ? CapitalizeFirstWord(excelHeaderListData[i].project ?? "-")
        : "-";
      row.getCell(2).value = excelHeaderListData[i].outletCode;
      row.getCell(3).value = excelHeaderListData[i].outletName;
      row.getCell(4).value = excelHeaderListData[i].sectorType;
      row.getCell(5).value = excelHeaderListData[i].biayaDasar.totalCost;
      row.getCell(6).value = excelHeaderListData[i].saldoKontribusi;
      row.getCell(7).value = excelHeaderListData[i].sisaSaldo;
      row.getCell(8).value = excelHeaderListData[i].biayaMurni.totalCost;
    }
  };
  const exportSecondSheet = async (ExcelWorkBook: ExcelJS.Workbook) => {
    const secondSheet = ExcelWorkBook.addWorksheet();
    secondSheet.name = "Biaya";

    secondSheet.columns = [
      { key: "A", width: 15 },
      { key: "B", width: 15 },
      { key: "C", width: 20 },
      { key: "D", width: 15 },
      { key: "E", width: 10 },
      { key: "F", width: 15 },
      { key: "G", width: 10 },
      { key: "H", width: 10 },
      { key: "I", width: 15 },
      { key: "J", width: 20 },
      { key: "K", width: 15 },
      { key: "L", width: 10 },
      { key: "M", width: 15 },
      { key: "N", width: 10 },
      { key: "O", width: 10 },
    ];
    const rowHeaderPosition = 1;
    const secondRowHeaderPosition = 2;
    const secondRowHeader = secondSheet.getRow(secondRowHeaderPosition);
    const firstRowHeader = secondSheet.getRow(rowHeaderPosition);
    for (let i = 1; i <= 15; i++) {
      firstRowHeader.getCell(i).font = {
        size: 11,
        bold: true,
      };
      secondRowHeader.getCell(i).font = {
        size: 11,
        bold: true,
      };
      firstRowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      secondRowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      for (let j = 1; j <= excelHeaderListData.length + 2; j++) {
        const firstRowCell = secondSheet.getCell(j, i);
        firstRowCell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    secondSheet.mergeCells("A1:A2");
    secondSheet.getCell("A1").value = "Kode PI Outlet";
    secondSheet.mergeCells("B1:H1");
    secondSheet.getCell("B1").value = "Biaya Dasar";
    secondSheet.mergeCells("I1:O1");
    secondSheet.getCell("I1").value = "Biaya Murni";

    secondRowHeader.getCell(2).value = "PSSP Dokter";
    secondRowHeader.getCell(3).value = "PSSP Non Dokter";
    secondRowHeader.getCell(4).value = "Entertainment";
    secondRowHeader.getCell(5).value = "DPL/DPF";
    secondRowHeader.getCell(6).value = "Standarisasi";
    secondRowHeader.getCell(7).value = "DP";
    secondRowHeader.getCell(8).value = "Total";
    secondRowHeader.getCell(9).value = "PSSP Dokter";
    secondRowHeader.getCell(10).value = "PSSP Non Dokter";
    secondRowHeader.getCell(11).value = "Entertainment";
    secondRowHeader.getCell(12).value = "DPL/DPF";
    secondRowHeader.getCell(13).value = "Standarisasi";
    secondRowHeader.getCell(14).value = "DP";
    secondRowHeader.getCell(15).value = "Total";

    for (let i = 0; i < excelHeaderListData.length; i++) {
      const row = secondSheet.getRow(secondRowHeaderPosition + 1 + i);
      row.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      };

      row.getCell(1).value = excelHeaderListData[i].outletCode;
      row.getCell(2).value = excelHeaderListData[i].biayaDasar.psspDoctorCost;
      row.getCell(3).value =
        excelHeaderListData[i].biayaDasar.psspNonDoctorCost;
      row.getCell(4).value = excelHeaderListData[i].biayaDasar.entertainCost;
      row.getCell(5).value = excelHeaderListData[i].biayaDasar.discountCost;
      row.getCell(6).value = excelHeaderListData[i].biayaDasar.standarisasiCost;
      row.getCell(7).value = excelHeaderListData[i].biayaDasar.downPaymentCost;
      row.getCell(8).value = excelHeaderListData[i].biayaDasar.totalCost;
      row.getCell(9).value = excelHeaderListData[i].biayaMurni.psspDoctorCost;
      row.getCell(10).value =
        excelHeaderListData[i].biayaMurni.psspNonDoctorCost;
      row.getCell(11).value = excelHeaderListData[i].biayaMurni.entertainCost;
      row.getCell(12).value = excelHeaderListData[i].biayaMurni.discountCost;
      row.getCell(13).value =
        excelHeaderListData[i].biayaMurni.standarisasiCost;
      row.getCell(14).value = excelHeaderListData[i].biayaMurni.downPaymentCost;
      row.getCell(15).value = excelHeaderListData[i].biayaMurni.totalCost;
    }
  };
  const exportThirdSheet = async (
    ExcelWorkBook: ExcelJS.Workbook,
    excelSaldoKontribusiData: Array<GetSaldoKontribusiProdukDetail>
  ) => {
    const thirdSheet = ExcelWorkBook.addWorksheet();
    thirdSheet.name = "Saldo Kontribusi";
    thirdSheet.columns = [
      { key: "A", width: 20 },
      { key: "B", width: 20 },
      { key: "C", width: 20 },
      { key: "D", width: 20 },
      { key: "E", width: 20 },
      { key: "F", width: 20 },
      { key: "G", width: 20 },
      { key: "H", width: 20 },
    ];
    const rowHeaderPosition = 1;
    const rowHeader = thirdSheet.getRow(rowHeaderPosition);
    for (let i = 1; i <= 8; i++) {
      rowHeader.getCell(i).font = {
        size: 12,
        bold: true,
      };
      rowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      for (let j = 1; j <= excelSaldoKontribusiData?.length + 1; j++) {
        const firstRowCell = thirdSheet.getCell(j, i);
        firstRowCell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
    rowHeader.getCell(1).value = "Kode PI Outlet";
    rowHeader.getCell(2).value = "Procode";
    rowHeader.getCell(3).value = "Nama Produk";
    rowHeader.getCell(4).value = "PT";
    rowHeader.getCell(5).value = "Divisi";
    rowHeader.getCell(6).value = "Value Sales";
    rowHeader.getCell(7).value = "Kontribusi Maksimum";
    rowHeader.getCell(8).value = "Saldo";
    if (excelSaldoKontribusiData?.length > 0) {
      for (let i = 0; i < excelSaldoKontribusiData.length; i++) {
        const row = thirdSheet.getRow(rowHeaderPosition + 1 + i);
        row.alignment = {
          wrapText: true,
          horizontal: "center",
          vertical: "middle",
        };
        row.getCell(1).value = excelSaldoKontribusiData[i].outletCode ?? "-";
        row.getCell(2).value = excelSaldoKontribusiData[i].productCode ?? "-";
        row.getCell(3).value = excelSaldoKontribusiData[i].productName ?? "-";
        row.getCell(4).value = excelSaldoKontribusiData[i].groupName ?? "-";
        row.getCell(5).value = excelSaldoKontribusiData[i].divisi ?? "-";
        row.getCell(6).value = excelSaldoKontribusiData[i].valueSales ?? "-";
        row.getCell(7).value = excelSaldoKontribusiData[i].kontribusi
          ? excelSaldoKontribusiData[i].kontribusi
          : "-"; // Convert to percentage
        if (excelSaldoKontribusiData[i].kontribusi) {
          row.getCell(7).numFmt = "0.00%";
        }
        row.getCell(8).value = excelSaldoKontribusiData[i].saldo ?? "-";
      }
    }
  };

  const exportExcel = async () => {
    try {
      const excelSaldoKontribusiData: GetSaldoKontribusiModelData =
        await getExcelSaldoKontribusiDetail();
      const ExcelWorkBook = new ExcelJS.Workbook();

      await exportFirstSheet(ExcelWorkBook);
      await exportSecondSheet(ExcelWorkBook);
      if (loggedUser?.userRoleId !== ROLE_ID_FF)
        await exportThirdSheet(
          ExcelWorkBook,
          excelSaldoKontribusiData.detailProduk
        );
      const buf = await ExcelWorkBook.xlsx.writeBuffer();
      saveAs(
        new Blob([buf]),
        `export_outlet_card_${loggedUser?.userNip}_${loggedUser?.userName}.xlsx`
      );
    } catch (error: unknown) {
      toast({
        title: "Gagal Mengunduh Excel",
        description: JSON.stringify(error),
        status: "error",
      });
    }
  };

  const exportData = async () => {
    try {
      setIsExportLoading(true);

      if (isEmpty(excelHeaderListData)) throw new Error("Header List Empty");
      await exportExcel();
    } catch (error: unknown) {
      toast({
        title: "Gagal Mengunduh Excel",
        description: JSON.stringify(error),
        status: "error",
      });
    } finally {
      setIsExportLoading(false);
    }
  };

  return { exportData, isExportLoading };
};

export default useExportExcelDetail;
