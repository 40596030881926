import React from "react";
import { Box } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useSalesProductContext } from "../../contexts/SalesProductContextProvider";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import CustomMultiselect from "../../../../../components/CustomMultiselectDropdown/CustomMultiselect";
import useGetAllProducts from "../../../../../services/Product/useGetAllProducts";
import ProductModel from "../../../../../models/Product/ProductModel";
import { isEmpty } from "lodash";

const FilterByProducts = () => {
  const {
    states: { filterValues },
  } = useSalesProductContext();

  const [selectedProducts, setSelectedProducts] = React.useState<
    ProductModel[] | null
  >(filterValues?.productList ?? null);

  const formContext = useFormContext();
  const watchSector = formContext.watch("sector");

  const { productData } = useGetAllProducts();

  return (
    <Box width={"100%"}>
      <FormInputLabel title="Nama Produk" />
      <CustomMultiselect
        displayValue="name"
        name="multiSelectProducts"
        options={productData}
        placeholder="Semua Produk"
        onChange={(productList: ProductModel[]) => {
          setSelectedProducts(productList);
          formContext.setValue("productList", productList);
        }}
        defaultValue={selectedProducts}
        disabled={!isEmpty(watchSector)}
      />
    </Box>
  );
};

export default FilterByProducts;
