import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { MdFilterAlt } from "react-icons/md";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import useGetRoleByLoggedUser from "../../../../../services/Users/useGetRoleByLoggedUser";
import useGetUserByRole from "../../../../../services/Users/useGetUserByRole";
import RoleModel from "../../../../../models/Users/RoleModel";
import UserModel from "../../../../../models/Users/UserModel";
import MonitorKunjunganUserListContext from "../context/MonitorKunjunganUserListContext";
import ClearAllButton from "../../../../../components/CustomButtons/ClearAllButton";

const ButtonFilterMonitorKunjunganUserList: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const { savedFilter, saveFilterValueToIndexedDB, resetSavedFilterValue } =
    useContext(MonitorKunjunganUserListContext);

  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<RoleModel | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserModel | null>(null);

  const { roleData } = useGetRoleByLoggedUser();
  const { userData } = useGetUserByRole({
    roleName: selectedRole ? selectedRole.name : null,
  });

  useEffect(() => {
    if (openFilterDialog) {
      setSelectedRole(savedFilter?.jabatan ?? null);
      setSelectedUser(savedFilter?.user ?? null);
    }
  }, [openFilterDialog]);

  const handleSubmitFilter = () => {
    if (!selectedRole) {
      toast({
        title: "Gagal Submit Filter",
        description: "Jabatan Tidak Boleh Kosong",
        status: "error",
      });
      return;
    }

    if (!selectedUser) {
      toast({
        title: "Gagal Submit Filter",
        description: `Nama ${selectedRole.label} Tidak Boleh Kosong`,
        status: "error",
      });
      return;
    }

    saveFilterValueToIndexedDB(selectedRole, selectedUser);
    setOpenFilterDialog(false);
  };

  const handleResetFilter = () => {
    resetSavedFilterValue();
    setOpenFilterDialog(false);
  };

  return (
    <>
      <Button
        variant="ghost"
        colorScheme="buttonSecondary"
        leftIcon={
          <MdFilterAlt
            style={{
              width: !isDesktopView ? "20px" : "24px",
              height: !isDesktopView ? "20px" : "24px",
            }}
          />
        }
        fontSize={isDesktopView ? 16 : 14}
        onClick={() => setOpenFilterDialog(true)}
      >
        Show Filter
      </Button>

      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        blockScrollOnMount
        isCentered
        isOpen={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        scrollBehavior="outside"
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent marginX="40px">
          <ModalHeader
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text
              color={theme.colors.exodus.primaryBlue}
              fontSize={24}
              fontWeight="semibold"
            >
              Filter Data
            </Text>
            <ClearAllButton onClick={handleResetFilter} />
          </ModalHeader>
          <ModalBody>
            <Flex
              flexDirection={isDesktopView ? "row" : "column"}
              justifyContent="space-between"
              gap={isDesktopView ? 6 : 0}
              paddingBottom="48px"
            >
              <Box width={isDesktopView ? "70%" : "100%"}>
                <FormInputLabel title="Jabatan" />
                <CustomSelect
                  onChange={(data) => {
                    setSelectedRole(data);
                    setSelectedUser(null);
                  }}
                  value={selectedRole}
                  getOptionLabel={(data: RoleModel) => data.label}
                  getOptionValue={(data: RoleModel) => data.id.toString()}
                  placeholder="Jabatan"
                  options={roleData}
                  menuPlacement="auto"
                />
              </Box>
              <Box width="100%">
                <FormInputLabel title="Cari Nama" />
                <CustomSelect
                  onChange={(data) => setSelectedUser(data)}
                  value={selectedUser}
                  getOptionLabel={(data: UserModel) => data.name}
                  getOptionValue={(data: UserModel) => data.id.toString()}
                  placeholder="Nama"
                  options={userData}
                  menuPlacement="auto"
                  isDisabled={!selectedRole}
                />
              </Box>
            </Flex>
            <Flex
              paddingBottom="16px"
              direction={isDesktopView ? "row-reverse" : "column"}
              gap={!isDesktopView ? 0 : 5}
            >
              <Button
                width="100%"
                colorScheme="buttonPrimary"
                onClick={handleSubmitFilter}
              >
                Submit
              </Button>
              <Button
                variant="outline"
                width="100%"
                marginTop={!isDesktopView ? "12px" : 0}
                colorScheme="buttonSecondary"
                onClick={() => setOpenFilterDialog(false)}
              >
                Close
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ButtonFilterMonitorKunjunganUserList;
