import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import KeteranganSalesPopover from "./KeteranganSalesPopover";

type Props = {
  open: boolean;
  onClose: () => void;
};

const KeteranganSalesDialog: React.FC<Props> = (props) => {
  return (
    <Modal isOpen={props.open} onClose={props.onClose} size={"xs"} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody py={5}>
          <KeteranganSalesPopover />
          <Button
            width={"100%"}
            mt={"24px"}
            colorScheme="buttonSecondary"
            variant={"outline"}
            onClick={props.onClose}
          >
            Close
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default KeteranganSalesDialog;
