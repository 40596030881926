import { useMediaQuery, useTheme } from "@chakra-ui/react";
import BiayaMurniDpPageMobile from "./components/mobile/BiayaMurniDpPageMobile";
import BiayaMurniDpPageDesktop from "./components/desktop/BiayaMurniDpPageDesktop";
import { BiayaMurniFilterContextProvider } from "../../contexts/BiayaMurniFilterContext";
import useTitle from "../../../../services/useTitle";
import { BiayaMurniListContextProvider } from "../../contexts/BiayaMurniListContext";

const BiayaMurniDpPage = (): JSX.Element => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  useTitle("Biaya Murni - DP");

  return (
    <BiayaMurniFilterContextProvider>
      <BiayaMurniListContextProvider featureType={"dp"}>
        {isMobile ? <BiayaMurniDpPageMobile /> : <BiayaMurniDpPageDesktop />}
      </BiayaMurniListContextProvider>
    </BiayaMurniFilterContextProvider>
  );
};

export default BiayaMurniDpPage;
