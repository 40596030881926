import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  useTheme,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

const EmailField = () => {
  const theme = useTheme();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl isInvalid={!!errors.email}>
      <FormLabel color={theme.colors.exodus.primaryBlue}>Email</FormLabel>
      <Input
        type="email"
        placeholder={"Email"}
        {...register("email", {
          required: "This is required",
          minLength: {
            value: 5,
            message: "Minimum length should be 5",
          },
        })}
      />
      <FormErrorMessage>
        {errors.email && (errors.email.message as string)}
      </FormErrorMessage>
      <Box sx={{ mb: 4 }} />
    </FormControl>
  );
};

export default EmailField;
