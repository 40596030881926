/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import UserModel from "../../../../models/Users/UserModel";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import IsTypeOfErrorResponse from "../../../../utils/IsTypeOfErrorResponse";
import UserProvider from "../../../../dataProviders/common/Users/UserProvider";

const useGetUserChildNonVacantMonitorKunjungan = (userId?: number) => {
  const [userData, setUserData] = useState<UserModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: UserModel[] | undefined =
          await UserProvider.getUserChildNonVacant();

        if (userId) {
          setUserData(responseData!.filter((item) => item.id === userId));
        } else {
          setUserData(responseData!);
        }

        setLoading(false);
      } catch (error: any) {
        setUserData([]);
        if (IsTypeOfErrorResponse(error)) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage(UNKNOWN_ERROR_RESPONSE.message);
        }
        setLoading(false);
      }
    }

    getData();
  }, [userId]);

  return { userData, loading, errorMessage };
};

export default useGetUserChildNonVacantMonitorKunjungan;
