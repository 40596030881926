import {
  useDisclosure,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Accordion,
  Stack,
  Box,
  useTheme,
  Text,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import DashboardMenu from "./components/DashboardMenu";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useAppSelector } from "../../redux/hooks";
import ActivityMenu from "./components/ActivityMenu";
import { useNavigate } from "react-router-dom";
import { PROFILE_NAVIGATION_ROOT } from "../../constants/common/RootConstants";
import { IoMdPerson } from "react-icons/io";
import BiayaMurniMenu from "./components/BiayaMurniMenu";
import MonitoringBudgetingMenu from "./components/MonitoringBudgetingMenu";
import PerformanceMenu from "./components/PerformanceMenu";
import KlaimSalesMenu from "./components/KlaimSalesMenu";
import PromotionMenu from "./components/PromotionMenu";

const Appbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sideBarRef = React.useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const backgroundTheme = theme.colors.exodus.background;
  const primaryTheme = theme.colors.exodus.primaryBlue;

  const loggedUser = useAppSelector((state) => state.authReducer.loggedUser);

  return (
    <>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          backgroundColor: primaryTheme,
          height: 14,
          paddingX: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            // @ts-ignore
            ref={sideBarRef}
            colorScheme={primaryTheme}
            onClick={onOpen}
          >
            <HamburgerIcon boxSize={6} />
          </Button>
        </Box>
        <Box sx={{ gap: 5, display: "flex", height: "100%" }}>
          <Button
            colorScheme={primaryTheme}
            onClick={() => navigate(PROFILE_NAVIGATION_ROOT)}
            sx={{ height: "100%" }}
          >
            <Text
              color={theme.colors.exodus.background}
              fontWeight={400}
              fontSize={16}
              sx={{
                marginRight: 3,
              }}
            >
              Hello, {loggedUser?.userName}
            </Text>
            <Icon
              as={IoMdPerson}
              boxSize={6}
              color={theme.colors.exodus.background}
            />
          </Button>
        </Box>
      </Stack>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={sideBarRef}
      >
        <DrawerOverlay />
        <DrawerContent sx={{ bg: backgroundTheme }}>
          <DrawerCloseButton />
          <DrawerHeader>Exodus</DrawerHeader>
          <DrawerBody>
            <Accordion defaultIndex={[0]} allowMultiple>
              <DashboardMenu onClose={onClose} />
              <ActivityMenu onClose={onClose} />
              <PerformanceMenu onClose={onClose} />
              <KlaimSalesMenu onClose={onClose} />
              <BiayaMurniMenu onClose={onClose} />
              <MonitoringBudgetingMenu onClose={onClose} />
              <PromotionMenu onClose={onClose} />
            </Accordion>
          </DrawerBody>
          <DrawerFooter>v{APP_VERSION}</DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Appbar;
