import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import IsTypeOfErrorResponse from "../../../../utils/IsTypeOfErrorResponse";
import { EmailVerificationProvider } from "../dataProviders/EmailVerificationProvider";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

type PayloadType = {
  email: string;
};

const usePostForgotPasswordEmail = async (payload: PayloadType) => {
  try {
    const response =
      await EmailVerificationProvider.sendForgotPasswordEmail(payload);

    if (
      response?.message === SUCCESS_MESSAGE &&
      response?.status === SUCCESS_GET_STATUS
    ) {
      return response;
    }
    throw UNKNOWN_ERROR_RESPONSE;
  } catch (error: unknown) {
    if (IsTypeOfErrorResponse(error)) {
      throw error;
    }
    throw UNKNOWN_ERROR_RESPONSE;
  }
};

export default usePostForgotPasswordEmail;
