import React, { useContext, useState } from "react";
import HeaderRecordApprovalRealisasiContext from "../../context/HeaderRecordApprovalRealisasiContext";
import {
  Box,
  BoxProps,
  Button,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  forwardRef,
  useTheme,
  Text,
} from "@chakra-ui/react";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DesktopDetailApprovalRealisasiBasicInformation from "./DesktopDetailApprovalRealisasiBasicInformation";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import StatusChip from "../../../../../../components/StatusChip/StatusChip";
import { RealisasiStatus } from "../../models/ApprovalRealisasiStatus";
import DesktopDetailApprovalRealisasiDetailList from "./DesktopDetailApprovalRealisasiDetailList";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import { MdInfoOutline } from "react-icons/md";
import CustomPopover from "../../../../../../components/CustomPopover/CustomPopover";

const DesktopDetailApprovalRealisasiComponent: React.FC = () => {
  const theme = useTheme();
  const { headerData, headerLoading, headerError } = useContext(
    HeaderRecordApprovalRealisasiContext
  );

  const [activeTab, setActiveTab] = useState<"realization" | "extracall">(
    "realization"
  );

  const getStatusColor = (status: string) => {
    if (status === RealisasiStatus.Approved) {
      return "processed";
    } else if (status === RealisasiStatus.Pending) {
      return "pending";
    } else {
      return "pending";
    }
  };

  const LihatApprovalButton = forwardRef<BoxProps, "div">((props, ref) => (
    <Box ref={ref} {...props} sx={{ width: "fit-content" }}>
      <Button
        colorScheme="buttonPrimary"
        variant="ghost"
        size={"md"}
        padding={2}
        leftIcon={<MdInfoOutline />}
      >
        Lihat List Approval
      </Button>
    </Box>
  ));

  /* Waiting for nama pemberi approval data from backend */
  const LihatApprovalDetail = (): JSX.Element => {
    return (
      <Box textAlign={"left"}>
        <Text color={theme.colors.exodus.primaryBlue} fontWeight={"bold"}>
          Approve Realisasi Kunjungan
        </Text>
        <Box display={"flex"} justifyContent={"space-between"}>
          {/* Put nama pemberi approval here */}
          <Text>
            {headerData?.approvalRealisasiTime?.getStringDateFormat(
              "D MMMM YYYY"
            )}
          </Text>
        </Box>
      </Box>
    );
  };

  if (headerLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (headerError && headerData === null) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={headerError.toString()} />
      </Box>
    );
  }
  return headerData ? (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        gap={10}
      >
        <PageTitle label="Approval Realisasi Kunjungan" />
        <StatusChip
          status={getStatusColor(headerData.approvalRealisasiStatus)}
          text={headerData.approvalRealisasiStatus.toUpperCase()}
        />
      </Box>
      <DesktopDetailApprovalRealisasiBasicInformation />
      {headerData?.approvalRealisasiStatus === RealisasiStatus.Approved && (
        <CustomPopover
          bodyComponent={<LihatApprovalDetail />}
          triggerComponent={<LihatApprovalButton />}
          placement="bottom-start"
        />
      )}

      {headerError ? null : (
        <Stack
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          gap={5}
        >
          <FormInputLabel title="Jenis Realisasi" />
          <RadioGroup
            onChange={(data: "realization" | "extracall") => setActiveTab(data)}
            value={activeTab}
          >
            <Stack sx={{ display: "flex", flexDirection: "row" }} gap={5}>
              <Radio value="realization">Rencana Kunjungan</Radio>
              <Radio value="extracall">Extra Call</Radio>
            </Stack>
          </RadioGroup>
        </Stack>
      )}
      <DesktopDetailApprovalRealisasiDetailList activeTab={activeTab} />
    </>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <ErrorPage message={"Error getting data"} />
    </Box>
  );
};

export default DesktopDetailApprovalRealisasiComponent;
