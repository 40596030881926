import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import useGetRoleByLoggedUser from "../../../../../../services/Users/useGetRoleByLoggedUser";
import useGetUserByRole from "../../../../../../services/Users/useGetUserByRole";
import FilterApprovalRealisasiKunjunganContext from "../../context/FilterApprovalRealisasiKunjunganContext";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import { isEmpty } from "lodash";
import RoleModel from "../../../../../../models/Users/RoleModel";
import UserModel from "../../../../../../models/Users/UserModel";

const ApprovalRealisasiRoleBawahanFormField: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const {
    register,
    setValue,
    getValues,
    resetField,
    formState: { errors },
  } = useFormContext();

  const { filterValues } = useContext(FilterApprovalRealisasiKunjunganContext);
  const [selectedRole, setSelectedRole] = useState<RoleModel | null | undefined>(
    filterValues.role
  );
  const [selectedBawahan, setSelectedBawahan] = useState<UserModel | null | undefined>(
    filterValues.bawahan
  );
  const { roleData } = useGetRoleByLoggedUser();
  const { userData } = useGetUserByRole({ roleName: selectedRole ? selectedRole.name : null});

  useEffect(() => {
    if (userData.length === 0) {
      resetField("user");
    }
  }, [userData.length]);

  useEffect(() => {
    setValue("role", selectedRole);
    setValue("user", selectedBawahan);
  }, []);

  return (
    <Flex
      direction={!isDesktopView ? "column" : "row"}
      gap={!isDesktopView ? 0 : 5}
      marginBottom={!isDesktopView ? 0 : "16px"}
    >
      <FormControl width="100%" isInvalid={!!errors.role}>
        <FormInputLabel title="Nama Jabatan" />
        <CustomSelect
          {...register("role")}
          options={roleData}
          getOptionValue={(data: RoleModel) => data.name}
          getOptionLabel={(data: RoleModel) => data.label}
          onChange={(data: RoleModel) => {
            setSelectedBawahan(null)
            setValue("user", null)
            setValue("role", data ? data : null);
            if (!data) {
              setSelectedBawahan(null)
              resetField("user");
            }
            setSelectedRole(data);
          }}
          value={selectedRole}
          placeholder="Jabatan"
        />
        <FormErrorMessage color={theme.colors.exodus.red}>
          {errors.role && (errors.role.message as string)}
        </FormErrorMessage>
      </FormControl>

      <FormControl width="100%" isInvalid={!!errors.user}>
        <FormInputLabel title="Cari Nama" />
        <CustomSelect
          {...register("user", {
            required: !isEmpty(getValues("role")) ? true : false,
          })}
          options={userData}
          getOptionValue={(data: UserModel) => data.id.toString()}
          getOptionLabel={(data: UserModel) => data.name}
          onChange={(data: UserModel) => {
            setValue("user", data ? data : null);
            setSelectedBawahan(data);
          }}
          value={selectedBawahan}
          placeholder="Cari Nama"
          isDisabled={!selectedRole}
        />
        <FormErrorMessage color={theme.colors.exodus.red}>
          {errors.user?.type === "required" && "Required"}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  );
};

export default ApprovalRealisasiRoleBawahanFormField;
