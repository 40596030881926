window.global ||= window;
import { Accordion, useMediaQuery, useTheme } from "@chakra-ui/react";
import Masonry from "react-smart-masonry";
import SummaryKlaimSalesCard from "./SummaryKlaimSalesCard";
import InputKlaimSalesStoredDataModel from "../../../models/InputKlaimSalesStoredDataModel";
import { EditableFakturSalesData } from "../../PilihProduk/models/EditableFakturModel";

type Props = {
  data: InputKlaimSalesStoredDataModel | null;
};

const SummaryKlaimSalesCardBase: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Accordion allowMultiple>
      <Masonry
        columns={isDesktopView ? 2 : 1}
        gap={15}
        style={{ paddingBottom: "16px" }}
      >
        {props.data &&
          props.data.pilihProduk &&
          props.data.pilihProduk.map((data: EditableFakturSalesData) => (
            <SummaryKlaimSalesCard data={data} />
          ))}
      </Masonry>
    </Accordion>
  );
};

export default SummaryKlaimSalesCardBase;
