import {
  Box,
  Grid,
  GridItem,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { OutletModel } from "../../../../../../models/Outlet/OutletModel";

type Props = {
  data: OutletModel | null;
};

const KlaimSalesDetailOutlet: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  if (!props.data) {
    return (
      <Box py="120px" width="100%" display="flex" justifyContent="center">
        <Text fontSize="18px">Tidak Ada Outlet Terpilih</Text>
      </Box>
    );
  }

  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      textAlign="left"
      fontSize={isDesktopView ? 16 : 14}
      gap={isDesktopView ? 5 : 3}
    >
      <GridItem colSpan={1}>
        <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
          Nama Outlet
        </Text>
        <Text>{props.data.outletName ?? "-"}</Text>
      </GridItem>
      <GridItem colSpan={1}>
        <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
          Kode Outlet
        </Text>
        <Text>{props.data.outletCode ?? "-"}</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
          Sektor
        </Text>
        <Text>Sektor</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
          Alamat
        </Text>
        <Text>{props.data.address ?? "-"}</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
          Kode Pos
        </Text>
        <Text>{props.data.codePost ?? "-"}</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
          GT saat ini
        </Text>
        <Text>{props.data.groupTerritoryName ?? "-"}</Text>
      </GridItem>
    </Grid>
  );
};

export default KlaimSalesDetailOutlet;
