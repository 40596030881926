import { useFormContext } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import CustomSelect from "../../../../../../../components/CustomSelect/CustomSelect";
import LoggedUser from "../../../../../../../models/common/LoggedUser";
import ProjectType from "../../../../../../../models/common/ProjectType";
import { useAppSelector } from "../../../../../../../redux/hooks";
import BiayaMurniFilterModel from "../../../../../models/BiayaMurniFilterModel";
import FilterFieldFrame from "../FilterFieldFrame";

const FilterByProject = () => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const formContext = useFormContext<BiayaMurniFilterModel>();
  const watchProject = formContext.watch("project");

  return (
    <FilterFieldFrame title="Project">
      <Box width={"100%"}>
        <CustomSelect
          options={loggedUser?.projects}
          onChange={(value: ProjectType | null | undefined) => {
            if (value) {
              formContext.setValue("project", value.value);
            } else {
              formContext.setValue("project", undefined);
            }
          }}
          getOptionLabel={(option: ProjectType) => option.label}
          defaultValue={null}
          value={loggedUser?.projects.find((o) => o.value === watchProject)}
          placeholder="Project"
          getOptionValue={(option: ProjectType) => option.value}
          isClearable
          isDisabled={
            loggedUser?.projects.length !== undefined &&
            loggedUser?.projects.length < 2
          }
        />
      </Box>
    </FilterFieldFrame>
  );
};

export default FilterByProject;
