import { FormProvider, useForm } from "react-hook-form";
import FilterByPeriode from "./fields/FilterByPeriode";
import SalesOutletListFilterModel from "../../../../models/SalesOutletListFilterModel";
import FilterDummyUi from "./fields/FilterDummyUi";
import DateExodus from "../../../../../../../../../models/DateExodus";
import { useSalesOutletListContext } from "../../../../contexts/SalesOutletListContext";
import { Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import FilterByProject from "./fields/FilterByProject";

const SalesOutletFilterFfRole = (): JSX.Element => {
  const {
    states: { filterValues, loading },
  } = useSalesOutletListContext();

  if (!loading && filterValues) {
    return <Content filterValues={filterValues} />;
  } else {
    return <FilterDummyUi />;
  }
};

const Content = ({
  filterValues,
}: {
  filterValues: SalesOutletListFilterModel;
}): JSX.Element => {
  const {
    actions: { changeFilter },
  } = useSalesOutletListContext();

  const methods = useForm<SalesOutletListFilterModel>({
    defaultValues: new SalesOutletListFilterModel({
      project: filterValues?.project ?? undefined,
      jabatan: undefined,
      pekerja: undefined,
      zona: undefined,
      outlets: undefined,
      sector: undefined,
      periode: {
        awal: filterValues?.periode.awal ?? new DateExodus(),
        akhir: filterValues?.periode.akhir ?? new DateExodus(),
      },
    }),
  });

  const handleOnSubmitFilter = () => {
    const updatedFilter = new SalesOutletListFilterModel({
      project: methods.getValues("project"),
      jabatan: undefined,
      pekerja: undefined,
      zona: undefined,
      outlets: undefined,
      sector: undefined,
      periode: {
        awal: methods.getValues("periode.awal"),
        akhir: methods.getValues("periode.akhir"),
      },
    });
    changeFilter(updatedFilter);
  };

  const theme = useTheme();
  const [isSmall] = useMediaQuery(
    `(max-width: ${theme.breakpoints.smallMonitor})`
  );

  return (
    <FormProvider {...methods}>
      <Stack
        marginY={4}
        direction={isSmall ? "column" : "row"}
        gap={isSmall ? 2 : 4}
      >
        <FilterByProject handleSubmit={handleOnSubmitFilter} />
        <FilterByPeriode handleSubmit={handleOnSubmitFilter} />
      </Stack>
    </FormProvider>
  );
};

export default SalesOutletFilterFfRole;
