import Dexie from "dexie";
import ApprovalRencanaFilterValues from "../features/Activity/RencanaKunjungan/Approval/models/ApprovalRencanaFilterValues";
import FilterMonitorKunjunganModel from "../features/Activity/MonitorKunjungan/models/FilterMonitorKunjunganModel";
import StoredUserId from "../features/Activity/RencanaKunjungan/Approval/models/StoredUserId";
import ApprovalRencanaMetadata from "../features/Activity/RencanaKunjungan/Approval/models/ApprovalRencanaMetadata";
import ApprovalRealisasiKunjunganFilterValues from "../features/Activity/RealisasiKunjungan/Approval/models/ApprovalRealisasiKunjunganFilterValues";
import { MetadataApprovalRealisasi } from "../features/Activity/RealisasiKunjungan/Approval/models/ApprovalRealisasiIndexDbModels";
import SalesTargetFilterAndPaginationModel from "../features/Performance/SalesTarget/models/SalesTargetFilterAndPaginationModel";
import KlaimSalesFilterAndPagination from "../features/KlaimSales/models/KlaimSalesFilterAndPaginationModel";
import InputKlaimSalesStoredDataModel from "../features/KlaimSales/Input/models/InputKlaimSalesStoredDataModel";

export class IndexDB extends Dexie {
  // add table for each feature here.
  biayaMurniFilter: Dexie.Table<any, number>;
  biayaMurniListMeta: Dexie.Table<any, number>;
  rencanaKunjunganFilter: Dexie.Table<ApprovalRencanaFilterValues>;
  detailApprovalRencanaKunjungan: Dexie.Table<StoredUserId>;
  approvalRencanaKunjunganMetadata: Dexie.Table<ApprovalRencanaMetadata>;
  approvalRealisasiFilter: Dexie.Table<
    ApprovalRealisasiKunjunganFilterValues,
    number
  >;
  approvalRealisasiDetailUserId: Dexie.Table<any, number>;
  metadataApprovalRealisasi: Dexie.Table<MetadataApprovalRealisasi>;
  filterMonitorKunjungan: Dexie.Table<FilterMonitorKunjunganModel>;
  monitoringBudgetFilter: Dexie.Table<any, number>;
  salesDistributorFilter: Dexie.Table<any, number>;
  inputSalesTargetFilter: Dexie.Table<SalesTargetFilterAndPaginationModel>;
  monitorSalesTargetFilter: Dexie.Table<SalesTargetFilterAndPaginationModel>;
  salesProductFilter: Dexie.Table<any, number>;
  salesProductListMetadata: Dexie.Table<any, number>;
  salesOutletListFilter: Dexie.Table<any, number>;
  salesOutletListMeta: Dexie.Table<any, number>;
  salesOutletDetailFilter: Dexie.Table<any, number>;
  salesOutletDetailMeta: Dexie.Table<any, number>;
  approvalKlaimSalesFilter: Dexie.Table<KlaimSalesFilterAndPagination>;
  monitorKlaimSalesFilter: Dexie.Table<KlaimSalesFilterAndPagination>;
  inputKlaimSalesData: Dexie.Table<InputKlaimSalesStoredDataModel>;
  outletCardListFilter: Dexie.Table<any, number>;
  outletCardListPagination: Dexie.Table<any, number>;

  constructor() {
    super("Exodus");
    // add attribute for each table here.
    this.version(1).stores({
      biayaMurniFilter: "++id",
      biayaMurniListMeta: "++id, feature_type",
      rencanaKunjunganFilter: "++id, jabatan, bawahan, periode",
      detailApprovalRencanaKunjungan: "++id, userId",
      approvalRencanaKunjunganMetadata: "++id, currentPage, limit",
      approvalRealisasiFilter: "++id, status, role, bawahan, periode",
      approvalRealisasiDetailUserId: "++id, userId",
      metadataApprovalRealisasi: "++id",
      filterMonitorKunjungan: "++id",
      monitoringBudgetFilter:
        "++id, jabatan.name, bawahan.nama, periodeTahun, periodeAwal, periodeAkhir",
      salesDistributorFilter: "++id",
      inputSalesTargetFilter: "++id",
      monitorSalesTargetFilter: "++id",
      salesProductFilter: "++id",
      salesProductListMetadata: "++id",
      salesOutletListFilter: "++id",
      salesOutletListMeta: "++id",
      salesOutletDetailFilter: "++id",
      salesOutletDetailMeta: "++id",
      approvalKlaimSalesFilter: "++id",
      monitorKlaimSalesFilter: "++id",
      inputKlaimSalesData: "++id",
      outletCardListFilter: "++id",
      outletCardListPagination: "++id",
    });

    this.biayaMurniFilter = this.table("biayaMurniFilter");
    this.biayaMurniListMeta = this.table("biayaMurniListMeta");
    this.rencanaKunjunganFilter = this.table("rencanaKunjunganFilter");
    this.detailApprovalRencanaKunjungan = this.table(
      "detailApprovalRencanaKunjungan"
    );
    this.approvalRencanaKunjunganMetadata = this.table(
      "approvalRencanaKunjunganMetadata"
    );
    this.approvalRealisasiFilter = this.table("approvalRealisasiFilter");
    this.approvalRealisasiDetailUserId = this.table(
      "approvalRealisasiDetailUserId"
    );
    this.metadataApprovalRealisasi = this.table("metadataApprovalRealisasi");
    this.filterMonitorKunjungan = this.table("filterMonitorKunjungan");
    this.monitoringBudgetFilter = this.table("monitoringBudgetFilter");
    this.salesDistributorFilter = this.table("salesDistributorFilter");
    this.inputSalesTargetFilter = this.table("inputSalesTargetFilter");
    this.monitorSalesTargetFilter = this.table("monitorSalesTargetFilter");
    this.salesProductFilter = this.table("salesProductFilter");
    this.salesProductListMetadata = this.table("salesProductListMetadata");
    this.salesOutletListFilter = this.table("salesOutletListFilter");
    this.salesOutletListMeta = this.table("salesOutletListMeta");
    this.salesOutletDetailFilter = this.table("salesOutletDetailFilter");
    this.salesOutletDetailMeta = this.table("salesOutletDetailMeta");
    this.approvalKlaimSalesFilter = this.table("approvalKlaimSalesFilter");
    this.monitorKlaimSalesFilter = this.table("monitorKlaimSalesFilter");
    this.inputKlaimSalesData = this.table("inputKlaimSalesData");
    this.outletCardListFilter = this.table("outletCardListFilter");
    this.outletCardListPagination = this.table("outletCardListPagination");
  }
}

const IndexedDatabase = new IndexDB();

export default IndexedDatabase;
