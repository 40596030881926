import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import IsTypeOfErrorResponse from "../../../../utils/IsTypeOfErrorResponse";
import { NewPasswordInputProvider } from "../dataProviders/NewPasswordInputProvider";

type PayloadType = {
  token: string;
};

const useVerifyForgotPasswordToken = async (payload: PayloadType) => {
  try {
    const response =
      await NewPasswordInputProvider.verifyForgotPasswordToken(payload);

    if (response) {
      return response;
    }
    throw UNKNOWN_ERROR_RESPONSE;
  } catch (error: unknown) {
    if (IsTypeOfErrorResponse(error)) {
      throw error;
    }
    throw UNKNOWN_ERROR_RESPONSE;
  }
};

export default useVerifyForgotPasswordToken;
