import { Box, Button, Grid, GridItem, Text, useTheme } from "@chakra-ui/react";
import ApprovalRencanaKunjunganDetailContext from "../../context/ApprovalRencanaKunjunganDetailContext";
import { useContext, useState } from "react";
import DialogKonfirmasiSubmitApproveRencanaKunjungan from "../DialogKonfirmasiSubmitApproveRencanaKunjungan";
import DialogKonfirmasiRejectApprovalRencanaKunjungan from "../DialogKonfirmasiRejectApprovalRencanaKunjungan";
import { ROLE_ID_TRADE_TEAM } from "../../../../../../constants/common/RoleConstants";
import { useAppSelector } from "../../../../../../redux/hooks";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import PopOverDetailRencanaKunjungan from "../PopOverDetailRencanaKunjungan";

const DesktopApprovalRencanaKunjunganBasicInformation = () => {
  const theme = useTheme();
  const { detailData } = useContext(ApprovalRencanaKunjunganDetailContext);
  const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const setApproveAndRejectButtonDisable = (): boolean => {
    if (loggedUser?.userRoleId === ROLE_ID_TRADE_TEAM) return true;

    if (detailData?.approvalPlanStatus === "Approved") {
      return true;
    } else if (detailData?.approvalPlanStatus === "Pending") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Grid
        templateColumns="repeat(10, 1fr)"
        gap={1}
        marginTop={"16px"}
        marginBottom={"5px"}
        justifyContent={"space-between"}
      >
        <GridItem colSpan={7}>
          <Grid
            templateColumns="repeat(12, 1fr)"
            marginBottom={"8px"}
            textAlign={"left"}
          >
            <GridItem colSpan={3}>
              <Text
                color={theme.colors.exodus.primaryBlue}
                fontWeight="bold"
                fontSize={16}
              >
                Field Force
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text fontSize={16}>:</Text>
            </GridItem>
            <GridItem colSpan={7}>
              <Text fontSize={16} marginLeft={"-40px"}>
                {detailData?.userName} ({detailData?.nip})
              </Text>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(12, 1fr)"
            marginBottom={"8px"}
            textAlign={"left"}
          >
            <GridItem colSpan={3}>
              <Text
                color={theme.colors.exodus.primaryBlue}
                fontWeight="bold"
                fontSize={16}
              >
                Jabatan
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text fontSize={16}>:</Text>
            </GridItem>
            <GridItem colSpan={7}>
              <Text fontSize={16} marginLeft={"-40px"}>
                {detailData?.roleLabel}
              </Text>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(12, 1fr)"
            marginBottom={"8px"}
            textAlign={"left"}
          >
            <GridItem colSpan={3}>
              <Text
                color={theme.colors.exodus.primaryBlue}
                fontWeight="bold"
                fontSize={16}
              >
                Periode Rencana Kunjungan
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text fontSize={16}>:</Text>
            </GridItem>
            <GridItem colSpan={7}>
              <Text fontSize={16} marginLeft={"-40px"}>
                {detailData?.periodeKunjungan}
              </Text>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(12, 1fr)"
            marginBottom={"8px"}
            textAlign={"left"}
          >
            <GridItem colSpan={3}>
              <Text
                color={theme.colors.exodus.primaryBlue}
                fontWeight="bold"
                fontSize={16}
              >
                Waktu Input
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text fontSize={16}>:</Text>
            </GridItem>
            <GridItem colSpan={7}>
              <Text fontSize={16} marginLeft={"-40px"}>
                {detailData?.createdAt?.getStringDateFormat(
                  "D MMMM YYYY HH:mm:ss"
                )}
              </Text>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(12, 1fr)"
            marginBottom={"8px"}
            textAlign={"left"}
          >
            <GridItem colSpan={3}>
              <Text
                color={theme.colors.exodus.primaryBlue}
                fontWeight="bold"
                fontSize={16}
              >
                Total Kunjungan
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text fontSize={16}>:</Text>
            </GridItem>
            <GridItem colSpan={7}>
              <Text fontSize={16} marginLeft={"-40px"}>
                {detailData?.totalSeluruhKunjungan} Kali
              </Text>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(12, 1fr)"
            marginBottom={"8px"}
            textAlign={"left"}
          >
            <GridItem colSpan={3}>
              <Text
                color={theme.colors.exodus.primaryBlue}
                fontWeight="bold"
                fontSize={16}
              >
                Keterangan
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text fontSize={16}>:</Text>
            </GridItem>
            <GridItem colSpan={7}>
              <Text fontSize={16} marginLeft={"-40px"}>
                {detailData?.description === "" ||
                detailData?.description === null
                  ? "-"
                  : detailData?.description}
              </Text>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem colSpan={3} textAlign={"right"}>
          {detailData?.approvalPlanStatus === "Request" && (
            <Box display="flex" flexDirection="row-reverse" gap={2}>
              <Button
                sx={{ marginRight: "8px" }}
                width={"150px"}
                type="submit"
                colorScheme="buttonSuccess"
                bgColor={theme.colors.exodus.green}
                isDisabled={setApproveAndRejectButtonDisable()}
                onClick={() => setOpenConfirmation(true)}
              >
                Approve
              </Button>
              <Button
                variant="outline"
                colorScheme="buttonError"
                color={theme.colors.exodus.red}
                borderColor={theme.colors.exodus.red}
                width={"150px"}
                isDisabled={setApproveAndRejectButtonDisable()}
                onClick={() => setOpenRejectDialog(true)}
              >
                Reject
              </Button>
            </Box>
          )}
        </GridItem>
      </Grid>
      <Box marginLeft={"-15px"}>
        <PopOverDetailRencanaKunjungan />
      </Box>
      <DialogKonfirmasiSubmitApproveRencanaKunjungan
        openConfirmationDialog={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
      />
      <DialogKonfirmasiRejectApprovalRencanaKunjungan
        openDescriptionDialog={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
      />
    </>
  );
};

export default DesktopApprovalRencanaKunjunganBasicInformation;
