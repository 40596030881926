import ApiGetListResponseMetadata from "../../../../../../../models/common/ApiGetListResponseMetadata";
import { ProjectEnum } from "../../../../../../../models/common/Project";
import SalesOutletDetailOutletData from "../SalesOutletDetailOutletData";
import SalesOutletDetailProductData from "../SalesOutletDetailProductData";

class ApiGetDetailSalesOutletResponse {
  metadata: ApiGetListResponseMetadata;
  records: SalesOutletDetailProductData[];
  outlet: SalesOutletDetailOutletData;
  project: ProjectEnum | null;
  hasProject: boolean;

  constructor(json: any) {
    this.metadata = new ApiGetListResponseMetadata(json.metadata);
    const tempRecords: SalesOutletDetailProductData[] = [];
    if (json.records !== null) {
      for (let i = 0; i < json.records.length; i++) {
        tempRecords.push(new SalesOutletDetailProductData(json.records[i]));
      }
    }
    this.records = tempRecords;
    this.outlet = new SalesOutletDetailOutletData(json.outlet);
    this.project = json.project ?? null;
    if (Object.hasOwn(json, "project")) {
      this.hasProject = true;
    } else {
      this.hasProject = false;
    }
  }
}

export default ApiGetDetailSalesOutletResponse;
