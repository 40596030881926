import { useTheme } from "@chakra-ui/react";
import React from "react";
import Select, {
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  InputActionMeta,
  OptionsOrGroups,
  PropsValue,
  SingleValue,
  StylesConfig,
} from "react-select";

type CustomSelectProps = {
  isDisabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  onChange: ((newValue: SingleValue<any>) => void) | undefined;
  value: PropsValue<any> | undefined;
  getOptionLabel: GetOptionLabel<any> | undefined;
  getOptionValue: GetOptionValue<any> | undefined;
  placeholder: React.ReactNode;
  options: OptionsOrGroups<any, GroupBase<any>> | undefined;
  menuPlacement?: "auto" | "bottom" | "top";
  defaultValue?: any;
  onInputChange?:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
};

const CustomSelect = ({
  isDisabled = false,
  isSearchable = true,
  isClearable = true,
  placeholder,
  onChange,
  getOptionValue,
  getOptionLabel,
  options,
  value,
  menuPlacement = "bottom",
  onInputChange,
  ...props
}: CustomSelectProps) => {
  const theme = useTheme();

  const selectStyles: StylesConfig<any> = {
    control: (styles, { isDisabled, isFocused }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? theme.colors.exodus.lightGrey
        : theme.colors.exodus.background,
      color: "red",
      border: isFocused ? `1px solid ${theme.colors.exodus.primaryBlue}` : ``,
      boxShadow: "none",
      "&:hover": {
        border: `1.5px solid ${theme.colors.exodus.primaryBlue}`,
        boxShadow: `none`,
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        textAlign: "left",
        // borderRadius: "8px",
        backgroundColor: isSelected
          ? "#DCE4F4"
          : isFocused
            ? "#C4CFE5"
            : theme.colors.exodus.background,
        color: isDisabled ? theme.colors.exodus.background : undefined,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? theme.colors.exodus.background
              : theme.colors.exodus.lightGrey
            : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles, textAlign: "left" }),
    dropdownIndicator: (styles) => ({ ...styles }),
    singleValue: (styles) => ({ ...styles, textAlign: "left" }),
  };

  return (
    <Select
      {...props}
      styles={selectStyles}
      options={options}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      isClearable={isClearable}
      placeholder={placeholder}
      onChange={onChange}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      value={value}
      menuPlacement={menuPlacement}
      onInputChange={onInputChange}
    />
  );
};

export default CustomSelect;
