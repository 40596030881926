import { useState } from "react";
import RealisasiKunjunganProvider from "../../dataProviders/RealisasiKunjunganProvider";
import { UpdateStatusRealisasiBodyModel } from "../models/UpdateStatusRealisasiBodyModel";

type CallResultHandler = {
  onSuccess: (data: string) => void;
  onError: (data: string) => void;
};

const useUpdateStatusRealisasi = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const updateStatusRealisasi = async (
    header_id: number,
    data: UpdateStatusRealisasiBodyModel,
    handler: CallResultHandler
  ) => {
    try {
      setErrorMessage(null);
      setLoading(true);

      const response: string | null =
        await RealisasiKunjunganProvider.updateStatusRealisasi({
          header_id: header_id,
          data: data,
        });

      setSuccessMessage(response!);
      setLoading(false);

      handler.onSuccess(response!);
    } catch (error: any) {
      setSuccessMessage(null);
      setErrorMessage(error);
      setLoading(false);
      handler.onError(error);
    }
  };

  return {
    updateStatusRealisasi,
    successMessage,
    loading,
    errorMessage,
  };
};

export default useUpdateStatusRealisasi;
