import { MdBarChart } from "react-icons/md";
import {
  AccordionItem,
  AccordionButton,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@chakra-ui/react";
import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";
import { ROUTE_ID_BIAYA_MURNI } from "../../../constants/common/RouteIdConstants";
import verifyPageAccess from "../../../utils/VerifyPageAccess";

const BiayaMurniMenu = ({ onClose }: { onClose: Function }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/biaya-murni");
    onClose();
  };

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  if (!verifyPageAccess(loggedUser, ROUTE_ID_BIAYA_MURNI)) return null;

  return (
    <AccordionItem onClick={handleOnClick}>
      <AccordionButton>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 4,
          }}
        >
          <Icon
            as={MdBarChart}
            color={theme.colors.exodus.primaryBlue}
            boxSize={6}
          />
          <Text
            fontSize={18}
            fontWeight={400}
            sx={{ color: theme.colors.exodus.primaryBlue }}
          >
            Biaya Murni
          </Text>
        </Stack>
      </AccordionButton>
    </AccordionItem>
  );
};

export default BiayaMurniMenu;
