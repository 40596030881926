import { createContext } from "react";
import { DetailApprovalRencanaKunjunganModelData } from "../models/ApprovalRencanaKunjunganData";

type InputRencanaKunjunganProps = {
  detailData: DetailApprovalRencanaKunjunganModelData | null;
  detailLoading: boolean;
  detailError: string | null;
  key: number;
  setKey: (data: number) => void;
};

const defaultValue: InputRencanaKunjunganProps = {
  detailData: null,
  detailLoading: false,
  detailError: null,
  key: 0,
  setKey: () => {},
};

const ApprovalRencanaKunjunganDetailContext = createContext(defaultValue);

export default ApprovalRencanaKunjunganDetailContext;
