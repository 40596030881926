import { useFormContext } from "react-hook-form";
import BiayaMurniFilterModel from "../../../../models/BiayaMurniFilterModel";
import { Text, useMediaQuery, useTheme, VStack } from "@chakra-ui/react";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import ProjectType from "../../../../../../models/common/ProjectType";
import { useAppSelector } from "../../../../../../redux/hooks";

const FilterByProject = () => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const formContext = useFormContext<BiayaMurniFilterModel>();
  const watchProject = formContext.watch("project");

  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  return (
    <VStack width={isMobile ? "100%" : 302} alignItems={"stretch"}>
      <Text textAlign={"start"}>Project</Text>
      <CustomSelect
        options={loggedUser?.projects}
        onChange={(value: ProjectType | null | undefined) => {
          if (value) {
            formContext.setValue("project", value.value);
          } else {
            formContext.setValue("project", undefined);
          }
        }}
        getOptionLabel={(option: ProjectType) => option.label}
        defaultValue={null}
        value={loggedUser?.projects.find((o) => o.value === watchProject)}
        placeholder="Project"
        getOptionValue={(option: ProjectType) => option.value}
        isClearable
        isDisabled={
          loggedUser?.projects.length !== undefined &&
          loggedUser?.projects.length < 2
        }
      />
    </VStack>
  );
};

export default FilterByProject;
