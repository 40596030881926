import ProjectType from "../../../models/common/ProjectType";
import DateExodus from "../../../models/DateExodus";

class ListApprovalKunjunganModelPack {
  message!: string;
  status!: number;
  code!: string;
  data: ListApprovalKunjunganModelData | null;

  constructor(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = json["data"]
      ? new ListApprovalKunjunganModelData(json["data"])
      : null;
  }
}

class ListApprovalKunjunganModelData {
  metadata!: ListApprovalKunjunganModelMetadata;
  records!: ListApprovalKunjunganModelRecords[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: ListApprovalKunjunganModelRecords[] = [];

    if (json["records"] !== null) {
      for (let i = 0; i < json.records.length; i++) {
        temp.push(new ListApprovalKunjunganModelRecords(json.records[i]));
      }
    }

    this.metadata = new ListApprovalKunjunganModelMetadata(json["metadata"]);
    this.records = temp;
  }
}

class ListApprovalKunjunganModelMetadata {
  totalData!: number;
  limit!: number;
  currentPage!: number;
  totalPage!: number;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.totalData = json["total_data"];
    this.limit = json["total_data_per_page"];
    this.currentPage = json["current_page"];
    this.totalPage = json["total_page"];
  }
}

class ListApprovalKunjunganModelRecords {
  id!: number;
  userId!: number;
  userName!: string;
  authId!: number;
  nip!: string;
  email!: string;
  roleId!: number;
  roleName!: string;
  roleLabel!: string;
  approvalPlanId?: number;
  approvalPlanStatus!: string;
  approvalPlanTime?: DateExodus | null;
  approvalRealisasiId?: number;
  approvalRealisasiStatus!: string;
  approvalRealisasiTime?: DateExodus | null;
  description?: string;
  startDate!: DateExodus;
  endDate!: DateExodus;
  periodeKunjungan!: string;
  project!: ProjectType | null;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.id =
      json["id"] === 0
        ? `${json["id"]} ${json["start_date"]} ${json["end_date"]} ${json["user_id"]}`
        : json["id"];
    this.userId = json["user_id"];
    this.userName = json["user_name"];
    this.authId = json["user_auth"];
    this.nip = json["user_nip"];
    this.email = json["user_email"];
    this.roleId = json["user_role_id"];
    this.roleName = json["role_name"];
    this.roleLabel = json["role_label"];
    this.approvalPlanId = json["approval_plan_id"];
    this.approvalPlanStatus = json["approval_plan_status"];
    this.approvalPlanTime = json["approval_plan_time"]
      ? DateExodus.fromBackend(json["approval_plan_time"])
      : null;
    this.approvalRealisasiId = json["approval_realization_id"];
    this.approvalRealisasiStatus = json["approval_realization_status"];
    this.approvalRealisasiTime = json["approval_realization_time"]
      ? DateExodus.fromBackend(json["approval_realization_time"])
      : null;
    this.description = json["description"];
    this.startDate = DateExodus.fromBackend(json["start_date"]);
    this.endDate = DateExodus.fromBackend(json["end_date"]);
    this.periodeKunjungan = `${json["start_date"]} - ${json["end_date"]}`;
    this.project = json["project"] ?? null;
  }
}

export {
  ListApprovalKunjunganModelPack,
  ListApprovalKunjunganModelRecords,
  ListApprovalKunjunganModelMetadata,
  ListApprovalKunjunganModelData,
};
