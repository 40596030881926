import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import IsTypeOfErrorResponse from "../../../../utils/IsTypeOfErrorResponse";
import { NewPasswordInputProvider } from "../dataProviders/NewPasswordInputProvider";

type PayloadType = {
  userId: number;
  newPassword: string;
};

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const usePostNewPassword = async (payload: PayloadType) => {
  try {
    const response = await NewPasswordInputProvider.sendNewPassword(payload);

    if (
      response?.message === SUCCESS_MESSAGE &&
      response?.status === SUCCESS_GET_STATUS
    ) {
      return response;
    }
    throw UNKNOWN_ERROR_RESPONSE;
  } catch (error: unknown) {
    if (IsTypeOfErrorResponse(error)) {
      throw error;
    }
    throw UNKNOWN_ERROR_RESPONSE;
  }
};

export default usePostNewPassword;
