import { createContext, useContext } from "react";
import useDpOrManager from "../services/useDpOrManager";
import { BiayaMurniOrCode } from "../../../models/BiayaMurniOrCode";
import ErrorResponse from "../../../../../models/common/ErrorResponse";

type _Value = {
  states: {
    dataArr: (BiayaMurniOrCode[] | null)[];
    isLoadingArr: boolean[];
    errorArr: (ErrorResponse | null)[];
    isLoadingInit: boolean;
  };
  actions: {
    fetchOneByIndex: Function;
  };
};

const DpOrManagerContext = createContext<_Value>({
  states: {
    dataArr: [],
    isLoadingArr: [],
    errorArr: [],
    isLoadingInit: true,
  },
  actions: {
    fetchOneByIndex: () => {},
  },
});

function useDpOrManagerContext(): _Value {
  return useContext(DpOrManagerContext);
}

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{
  dpIds: number[];
}>;

const DpOrManagerContextProvider = ({
  children,
  dpIds,
}: _InternalProps): JSX.Element => {
  const { fetchOneByIndex, dataArr, isLoadingArr, errorArr, isLoadingInit } =
    useDpOrManager(dpIds);

  const value: _Value = {
    states: { dataArr, isLoadingArr, errorArr, isLoadingInit },
    actions: { fetchOneByIndex },
  };

  return (
    <DpOrManagerContext.Provider value={value}>
      {children}
    </DpOrManagerContext.Provider>
  );
};

export { DpOrManagerContextProvider, useDpOrManagerContext };
