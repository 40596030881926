import { useLocation, useParams } from "react-router-dom";
import useGetDetailApprovalRencanaKunjungan from "../services/useGetDetailApprovalRencanaKunjungan";
import ApprovalRencanaKunjunganDetailContext from "../context/ApprovalRencanaKunjunganDetailContext";
import ApprovalRencanaKunjunganDetailPage from "./ApprovalRencanaKunjunganDetailPage";
import useTitle from "../../../../../services/useTitle";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import useGetApprovalRencanaUserId from "../services/useGetApprovalRencanaUserId";

const ApprovalRencanaKunjunganDetailBase = () => {
  const { id } = useParams();
  const location = useLocation();
  const idNumber = parseInt(id!);
  const [key, setKey] = useState<number>(0);
  const [userId, setUserId] = useState<number | null>(null);
  const { data: storedUserId } = useGetApprovalRencanaUserId();

  useEffect(() => {
    if (storedUserId !== null) {
      setUserId(storedUserId);
    }
  }, [storedUserId]);

  const { approvalRencanaData, loading, errorMessage } =
    useGetDetailApprovalRencanaKunjungan(key, {
      id: idNumber,
      user_id: !isEmpty(location.state) ? location.state.userId : userId,
    });

  useTitle("Approval Rencana Kunjungan Detail");

  return (
    <ApprovalRencanaKunjunganDetailContext.Provider
      value={{
        detailData: approvalRencanaData,
        detailLoading: loading,
        detailError: errorMessage,
        key: key,
        setKey: setKey,
      }}
    >
      <ApprovalRencanaKunjunganDetailPage />
    </ApprovalRencanaKunjunganDetailContext.Provider>
  );
};

export default ApprovalRencanaKunjunganDetailBase;
