import { Box, Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import useTitle from "../../../../services/useTitle";
import OutletCardDetailContextProvider from "./contexts/OutletCardDetailContextProvider";
import { useParams, useSearchParams } from "react-router-dom";
import OutletCardDetailHeader from "./components/common/OutletCardDetailHeader";
import ListProductDetail from "./components/common/ListProductDetail";
import DesktopOutletCardDetail from "./components/desktop/DesktopOutletCardDetail";
import LoggedUser from "../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../redux/hooks";
import { ROLE_ID_FF } from "../../../../constants/common/RoleConstants";
import MobileOutletCardAccordion from "./components/mobile/MobileOutletCardAccordion";
import MobileExportExcelArea from "./components/mobile/components/MobileExportExcelArea";

const OutletCardDetail = () => {
  useTitle("Outlet Card Detail");
  const theme = useTheme();
  const [urlParam] = useSearchParams();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  const { outletId } = useParams();
  const startDate = Number(urlParam.get("startDate"));
  const endDate = Number(urlParam.get("endDate"));

  const contextParams = {
    outletId: Number(outletId),
    urlStartDate: startDate,
    urlEndDate: endDate,
  };

  const RenderSaldoKontribusiArea = () => {
    return isDesktopView ? (
      <DesktopOutletCardDetail />
    ) : (
      <MobileOutletCardAccordion />
    );
  };

  return (
    <OutletCardDetailContextProvider params={contextParams}>
      <Stack minHeight={isDesktopView ? "100%" : "90vh"}>
        <OutletCardDetailHeader />
        <Box mt={2} />
        <ListProductDetail />
        {loggedUser?.userRoleId !== ROLE_ID_FF && RenderSaldoKontribusiArea()}
        {!isDesktopView && <MobileExportExcelArea />}
      </Stack>
    </OutletCardDetailContextProvider>
  );
};

export default OutletCardDetail;
