import { Box, useMediaQuery, useTheme, Text } from "@chakra-ui/react";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { useContext } from "react";
import ApprovalRencanaKunjunganDetailContext from "../context/ApprovalRencanaKunjunganDetailContext";
import StatusChip from "../../../../../components/StatusChip/StatusChip";
import DesktopApprovalRencanaKunjunganDetailPage from "./desktop/DesktopApprovalRencanaKunjunganDetailPage";
import MobileApprovalRencanaKunjunganDetailPage from "./mobile/MobileApprovalRencanaKunjunganDetailPage";

const ApprovalRencanaKunjunganDetailPage = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const { detailData } = useContext(ApprovalRencanaKunjunganDetailContext);

  const renderApprovalStatus = (item: string | undefined) => {
    if (item === "Pending") {
      return <StatusChip text={"PENDING"} status={"pending"} />;
    } else if (item === "Reject") {
      return <StatusChip text={"REJECT"} status={"reject"} />;
    } else if (item === "Approved") {
      return <StatusChip text={"APPROVED"} status={"processed"} />;
    } else if (item === "Transfered") {
      return <StatusChip text={"TRANSFERED"} status={"transferred"} />;
    } else if (item === "Processed") {
      return <StatusChip text={"PROCESSED"} status={"processed"} />;
    } else if (item === "Request") {
      return <StatusChip text={"REQUEST"} status={"pending"} />;
    } else {
      return <Text textAlign="left">{item}</Text>;
    }
  };

  return (
    <Box width={"100%"}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={!isDesktopView ? "space-between" : ""}
      >
        <PageTitle label="Approval Rencana Kunjungan" />
        <Box paddingLeft={"50px"}>
          {renderApprovalStatus(detailData?.approvalPlanStatus)}
        </Box>
      </Box>
      {isDesktopView && <DesktopApprovalRencanaKunjunganDetailPage />}
      {!isDesktopView && <MobileApprovalRencanaKunjunganDetailPage />}
    </Box>
  );
};

export default ApprovalRencanaKunjunganDetailPage;
