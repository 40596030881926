import { Flex, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import { CommonStatus } from "../../../../../models/common/Status";
import { useContext } from "react";
import InputRencanaKunjunganContext from "../context/InputRencanaKunjunganContext";

const MinimalVisitAndStatusComponent: React.FC = () => {
  const { minimalVisitData, selectedPeriode } = useContext(
    InputRencanaKunjunganContext
  );

  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  let textColor;

  if (selectedPeriode?.status_plan === CommonStatus.Approved) {
    textColor = theme.colors.exodus.green;
  } else {
    textColor = theme.colors.exodus.yellow;
  }

  return (
    <Flex
      gap={isDesktopView ? "24px" : "8px"}
      direction={isDesktopView ? "row" : "column"}
    >
      <Flex display="flex" gap="6px">
        <Text
          color={theme.colors.exodus.primaryBlue}
          fontWeight="semibold"
          fontSize={isDesktopView ? 16 : 14}
        >
          Minimal Kunjungan:
        </Text>
        <Text fontWeight="semibold" fontSize={isDesktopView ? 16 : 14}>
          {minimalVisitData?.minimalvisit ?? "-"} Kunjungan
        </Text>
      </Flex>
      <Flex display="flex" gap="6px">
        <Text
          color={theme.colors.exodus.primaryBlue}
          fontWeight="semibold"
          fontSize={isDesktopView ? 16 : 14}
        >
          Status:
        </Text>
        <Text
          color={textColor}
          fontWeight="semibold"
          fontSize={isDesktopView ? 16 : 14}
        >
          {selectedPeriode?.status_plan ?? "-"}
        </Text>
      </Flex>
    </Flex>
  );
};

export default MinimalVisitAndStatusComponent;
