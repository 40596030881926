import React, { useState } from "react";
import { useOutletCardDetailContext } from "../../contexts/OutletCardDetailContextProvider";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import { Grid, Stack, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import ExportExcelButton from "../../../../../../components/CustomButtons/ExportExcelButton";
import BiayaDasarHeaderArea from "./BiayaDasarHeaderArea";
import BiayaMurniHeaderArea from "./BiayaMurniHeaderArea";
import OutletCardBiayaDasarModal from "./Modal/OutletCardBiayaDasarModal";
import OutletCardBiayaMurniModal from "./Modal/OutletCardBiayaMurniModal";

const OutletCardDetailHeader: React.FC = () => {
  const [isOpenBiayaDasarModal, setIsOpenBiayaDasarModal] =
    useState<boolean>(false);
  const [isOpenBiayaMurniModal, setIsOpenBiayaMurniModal] =
    useState<boolean>(false);

  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const {
    states: { outletCardDetail, isExportLoading },
    actions: { exportData },
  } = useOutletCardDetailContext();

  return (
    <Stack gap={0}>
      <PageTitle label={outletCardDetail?.outletName ?? "-"} />
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Stack direction="column" gap={1}>
          <Text
            fontSize={isMobile ? 16 : 24}
            fontWeight={400}
            color={theme.colors.exodus.primaryBlue}
          >
            Outlet Card Detail
          </Text>
          {outletCardDetail?.project && (
            <Text
              textAlign={"left"}
              fontSize={isMobile ? 14 : 20}
              fontWeight={400}
              color={theme.colors.exodus.darkGrey}
            >
              Project {outletCardDetail?.project}
            </Text>
          )}
        </Stack>
        {!isMobile && (
          <ExportExcelButton onClick={exportData} isLoading={isExportLoading} />
        )}
      </Stack>
      <Grid templateColumns={`repeat(${isMobile ? 1 : 2}, 1fr)`} gap={2}>
        <BiayaDasarHeaderArea
          handleOpenModal={() => setIsOpenBiayaDasarModal(true)}
        />
        <BiayaMurniHeaderArea
          handleOpenModal={() => setIsOpenBiayaMurniModal(true)}
        />
      </Grid>
      <OutletCardBiayaDasarModal
        isOpen={isOpenBiayaDasarModal}
        onClose={() => setIsOpenBiayaDasarModal(false)}
      />
      <OutletCardBiayaMurniModal
        isOpen={isOpenBiayaMurniModal}
        onClose={() => setIsOpenBiayaMurniModal(false)}
      />
    </Stack>
  );
};

export default OutletCardDetailHeader;
