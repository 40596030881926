import { createContext } from "react";
import { useContext } from "react";
import usePageState from "../services/usePageState";

type _Value = {
  constValue: {
    maxStepIdx: number;
  };
  state: {
    activeStepIdx: number;
    isActionButtonHidden: boolean;
  };
  actions: {
    handleNext: () => void;
    handleBack: () => void;
    setIsActionButtonHidden: (data: boolean) => void;
    setSavedStepIdx: () => void;
    resetActiveStepIdx: () => void;
    handleFailSubmit: () => void;
  };
};

const _StepperContext = createContext<_Value>({
  constValue: { maxStepIdx: 0 },
  state: { activeStepIdx: 0, isActionButtonHidden: false },
  actions: {
    handleNext: () => {},
    handleBack: () => {},
    setSavedStepIdx: () => {},
    resetActiveStepIdx: () => {},
    setIsActionButtonHidden: () => {},
    handleFailSubmit: () => {},
  },
});

function useStepper(): _Value {
  return useContext(_StepperContext);
}

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{}>;

const StepperProvider = ({ children }: _InternalProps): JSX.Element => {
  const {
    activeStepIdx,
    maxStepIdx,
    handleNext,
    handleBack,
    setSavedStepIdx,
    resetActiveStepIdx,
    isActionButtonHidden,
    setIsActionButtonHidden,
    handleFailSubmit,
  } = usePageState();

  const value: _Value = {
    constValue: { maxStepIdx },
    state: { activeStepIdx, isActionButtonHidden },
    actions: {
      handleNext,
      handleBack,
      setSavedStepIdx,
      resetActiveStepIdx,
      setIsActionButtonHidden,
      handleFailSubmit,
    },
  };

  return (
    <_StepperContext.Provider value={value}>
      {children}
    </_StepperContext.Provider>
  );
};

export { StepperProvider, useStepper };
