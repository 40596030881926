import React, { useContext } from "react";
import FilterBox from "../../../../../components/FilterBox/FilterBox";
import { Box } from "@chakra-ui/react";
import FilterApprovalRealisasiKunjunganContext from "../context/FilterApprovalRealisasiKunjunganContext";

const ApprovalRealisasiKunjunganFilterBoxSegment: React.FC = () => {
  const { filterValues } = useContext(FilterApprovalRealisasiKunjunganContext);
  const boxFilterStyle = {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignContent: "start",
  };
  return (
    <>
      <Box sx={boxFilterStyle}>
        {filterValues.role && (
          <FilterBox title="Jabatan" content={filterValues.role.label} />
        )}
        {filterValues.role && filterValues.bawahan && (
          <FilterBox title="Nama" content={filterValues.bawahan.name} />
        )}
        {filterValues.status && (
          <FilterBox title="Status" content={filterValues.status.status_id} />
        )}
        {filterValues.project && (
          <FilterBox title="Project" content={filterValues.project.label} />
        )}
      </Box>
    </>
  );
};

export default ApprovalRealisasiKunjunganFilterBoxSegment;
