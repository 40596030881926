import { HStack, Stack, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import MobileListProductDetail from "../mobile/components/MobileListProductDetail";
import DesktopListProductDetail from "../desktop/components/DesktopListProductDetail";

const ListProductDetail = () => {
  const theme = useTheme();

  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  return (
    <Stack direction="column">
      <HStack>
        <Text
          fontSize={18}
          fontWeight={600}
          color={theme.colors.exodus.primaryBlue}
        >
          List Produk
        </Text>
      </HStack>
      {isMobile ? <MobileListProductDetail /> : <DesktopListProductDetail />}
    </Stack>
  );
};

export default ListProductDetail;
