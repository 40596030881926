import { Box, HStack, Text } from "@chakra-ui/react";
import TotalSalesAreaDesktop from "./components/TotalSalesAreaDesktop";
import React from "react";
import DataNotFound from "../../../../../components/DataNotFound/DataNotFound";
import TableDataComponent from "../../../../../components/TableData/TableDataComponent";
import { useSalesProductContext } from "../../contexts/SalesProductContextProvider";
import { SalesProductRecordModel } from "../../models/SalesProductModel";
import CurrencyIdrFormatter from "../../../../../utils/CurrencyIdrFormatter";
import Pagination from "../../../../../components/Pagination/Pagination";
import { isEmpty } from "lodash";
import FilterAndExcelTopBar from "./components/FilterAndExcelTopBar";
import FilterModal from "../common/FilterModal";

const SalesProductDesktop = () => {
  const {
    states: { getListData, isLoadingList },
    actions: { setPage, setLimit },
  } = useSalesProductContext();

  const [isFilterModalOpen, setIsFilterModalOpen] =
    React.useState<boolean>(false);

  const handleOnFilterModalChange = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  return (
    <>
      <FilterAndExcelTopBar
        handleOnFilterModalChange={handleOnFilterModalChange}
      />
      <Box mt={2} />
      <TotalSalesAreaDesktop />
      {isEmpty(getListData?.records) && !isLoadingList ? (
        <DataNotFound />
      ) : (
        <TableDataComponent
          data={getListData?.records}
          expandComponent={() => {}}
          onSelectChange={() => {}}
          paginationOn={false}
          tableHeader={[
            {
              cell: (record: SalesProductRecordModel) => (
                <Text fontSize={14} textAlign={"left"}>
                  {record.productName}
                </Text>
              ),
              name: "Nama Produk",
              width: "30%",
            },
            {
              cell: (record: SalesProductRecordModel) => (
                <Text fontSize={14}>{record.salesUnit}</Text>
              ),
              name: "Unit",
              width: "20%",
            },
            {
              name: "Value",
              cell: (record: SalesProductRecordModel) => (
                <Text fontSize={14}>
                  {CurrencyIdrFormatter.convertToRp(record.salesValue)}
                </Text>
              ),
            },
            {
              name: "Estimasi Unit",
              cell: (record: SalesProductRecordModel) => (
                <Text fontSize={14}>{record.estimatedUnit}</Text>
              ),
            },
            {
              name: "Estimasi Value",
              cell: (record: SalesProductRecordModel) => (
                <Text fontSize={14}>
                  {CurrencyIdrFormatter.convertToRp(record.estimatedCost)}
                </Text>
              ),
            },
          ]}
          checboxOn={false}
          paginationLimit={[]}
          isLoading={isLoadingList}
        />
      )}
      {!isLoadingList && (
        <HStack justifyContent={"flex-end"}>
          <Pagination
            handleOnBack={() => setPage("prev")}
            handleOnLimitChange={setLimit}
            handleOnNavigateFirstPage={() => setPage("first")}
            handleOnNext={() => setPage("next")}
            limit={getListData?.metadata?.totalDataPerPage}
            page={getListData?.metadata?.currentPage}
            showCurrentPageDetail
            showLimit
            showNavigateFirstPage
            totalData={getListData?.metadata?.totalData}
            totalPage={getListData?.metadata?.totalPage}
          />
        </HStack>
      )}
      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={handleOnFilterModalChange}
      />
    </>
  );
};

export default SalesProductDesktop;
