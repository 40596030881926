import { useEffect, useState } from "react";
import ActivityGeneralProvider from "../dataProviders/ActivityGeneralProvider";
import { AllListApprovalParams } from "../dataProviders/ActivityGeneralParamType";
import { ApprovalKunjunganExcelModelData } from "../models/ApprovalKunjunganExcelModelData";
import { isEmpty } from "lodash";

const useGetListAllApproval = ({
  start_period,
  end_period,
  bawahan,
  status,
  kunjungan,
  project
}: AllListApprovalParams) => {
  const [allApprovalData, setData] = useState<
    ApprovalKunjunganExcelModelData[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: ApprovalKunjunganExcelModelData[] | undefined =
          await ActivityGeneralProvider.getListAllApproval({
            start_period,
            end_period,
            bawahan,
            status,
            kunjungan,
            project: project
          });

        setData(responseData!);

        setLoading(false);
      } catch (error: any) {
        setData([]);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    if(!isEmpty(start_period) && !isEmpty(end_period)){
      getData();
    }
    
  }, [
    JSON.stringify(start_period),
    JSON.stringify(end_period),
    bawahan.toString(),
    status,
    kunjungan,
    project
  ]);

  return { allApprovalData, loading, errorMessage };
};

export default useGetListAllApproval;
