import { useEffect } from "react";
import { FormControl, FormErrorMessage, useTheme } from "@chakra-ui/react";
import CustomerDataModel from "../../../../../../models/Customer/CustomerDataModel";
import { OutletModel } from "../../../../../../models/Outlet/OutletModel";
import CreateAndUpdateExtraCallBody from "../../models/CreateAndUpdateExtraCallModel";
import { useFormContext } from "react-hook-form";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import { InputActionMeta } from "react-select";

type Props = {
  outletData: OutletModel[];
  customerData: CustomerDataModel[];
  selectedOutlet: OutletModel | null;
  onChange: (data: OutletModel | null) => void;
  onInputChange:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
};

const RealisasiExtraCallOutletFormField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const {
    register,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<CreateAndUpdateExtraCallBody>();

  useEffect(() => {
    if (props.customerData.length === 0) {
      resetField("customer_id");
    }
  }, [props.customerData.length]);

  return (
    <FormControl width="100%" isInvalid={!!errors.outlet_id}>
      <FormInputLabel title="Outlet" />
      <CustomSelect
        {...register("outlet_id", { required: "Required" })}
        options={props.outletData}
        getOptionValue={(data: OutletModel) => data.outletId.toString()}
        getOptionLabel={(data: OutletModel) =>
          `${data.outletName} (${data.outletCode})`
        }
        onChange={(data: OutletModel) => {
          setValue("outlet_id", data ? data.outletId : null);
          if (!data) {
            resetField("customer_id");
          }
          props.onChange(data);
        }}
        value={props.selectedOutlet}
        placeholder="Nama Outlet (Kode Outlet)"
        onInputChange={props.onInputChange}
      />
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.outlet_id && (errors.outlet_id.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default RealisasiExtraCallOutletFormField;
