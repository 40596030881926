import { useState, useEffect } from "react";
import LoggedUser from "../../../models/common/LoggedUser";
import CostSalesRatio from "../model/CostSalesRatio";
import CurrencyIdrFormatter from "../../../utils/CurrencyIdrFormatter";
import DashboardDataProvider from "../dataProvider/DashboardDataProvider";
import DateExodus from "../../../models/DateExodus";
import IsTypeOfErrorResponse from "../../../utils/IsTypeOfErrorResponse";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";
import { ProjectEnum } from "../../../models/common/Project";

const useBiayaMurniSalesRatio = (
  loggedUser: LoggedUser | null,
  date: DateExodus | null
) => {
  const [dataCostSalesRatio, setData] = useState<CostSalesRatio | null>(null);
  const [loadingCostSalesRatio, setLoading] = useState<boolean>(false);
  const [errorCostSalesRatio, setError] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        setError(null);

        let start_period: number | null;
        let end_period: number | null;

        if (!date) {
          start_period = null;
          end_period = null;
        } else {
          start_period = date.getEpochStartOfMonth();
          end_period = date.getEpochEndOfMonth();
        }

        const result = await DashboardDataProvider.getBiayaMurniSalesRatio(
          start_period,
          end_period
        );

        if (result.data !== null) {
          const convertedData: CostSalesRatio = {
            totalCostMurni: result.data["total_cost_murni"],
            totalCostMurniUI: CurrencyIdrFormatter.convertToRp(
              result.data["total_cost_murni"]
            ),
            salesValue: result.data["sales_value"],
            salesValueUI: CurrencyIdrFormatter.convertToRp(
              result.data["sales_value"]
            ),
          };

          setData(convertedData);
        }
      } catch (error: any) {
        console.log(error);
        if (IsTypeOfErrorResponse(error)) {
          setError(error.message);
          return;
        }
        setError(UNKNOWN_ERROR_RESPONSE.message);
      } finally {
        setLoading(false);
      }
    }

    if (
      loggedUser &&
      loggedUser?.projects.find((e) => e.value === ProjectEnum.Ethical)
    ) {
      getData();
    }
  }, [loggedUser, date]);

  return { dataCostSalesRatio, loadingCostSalesRatio, errorCostSalesRatio };
};

export default useBiayaMurniSalesRatio;
