/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../models/RealisasiAndExtraCallDataModel";
import RealisasiKunjunganProvider from "../../dataProviders/RealisasiKunjunganProvider";
import { RealisasiOrExtraCallListParam } from "../../dataProviders/RealisasiKunjunganParamType";

const useGetRealisasiOrExtraCallList = (
  key: number,
  { header_id, isRealisasi }: RealisasiOrExtraCallListParam
) => {
  const [realisasiOrExtraCallData, setData] = useState<
    RealisasiAndExtraCallDetailRealizationModelData[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData:
          | RealisasiAndExtraCallDetailRealizationModelData[]
          | undefined =
          await RealisasiKunjunganProvider.getRealisasiOrExtraCallList({
            header_id: header_id,
            isRealisasi: isRealisasi,
          });

        setData(responseData!);

        setLoading(false);
      } catch (error: any) {
        setData([]);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    if (header_id !== null && header_id !== undefined) {
      getData();
    }
  }, [header_id, isRealisasi, key]);

  return { realisasiOrExtraCallData, loading, errorMessage };
};

export default useGetRealisasiOrExtraCallList;
