import React from "react";
import ExcelJS from "exceljs";
import { isEmpty } from "lodash";
import saveAs from "file-saver";
import {
  getOutletSectorType,
  getProjectType,
} from "../utils/SalesDistributorUtils";
import { GetListSalesDistributorDetailData } from "../model/GetListSalesDistributorDetailModelPack.ts";
import DateExodus from "../../../../models/DateExodus.ts";

const useExportDistributorDetail = (
  dataDetail: GetListSalesDistributorDetailData | null
) => {
  const totalHeaderLength = 1;
  const [totalDetailLength, setTotalDetailLength] = React.useState<number>(-1);
  const [loadingExportDetail, setLoadingExportDetail] =
    React.useState<boolean>(false);
  const workbook = new ExcelJS.Workbook();
  const worksheetHeader = workbook.addWorksheet();
  const worksheetDetail = workbook.addWorksheet();

  const insertNullValueInRowCell = (row: ExcelJS.Row, cell: number) => {
    row.getCell(cell).value = "-";
  };

  const insertDataToCell = (value: any, row: ExcelJS.Row, cell: number) => {
    let insertedValue: string | undefined | null = value;
    if (typeof value === "number") {
      insertedValue = value.toString();
    }

    if (!isEmpty(insertedValue)) {
      row.getCell(cell).value = insertedValue;
    } else {
      insertNullValueInRowCell(row, cell);
    }
  };

  const handleExportHeaderSheet = async () => {
    worksheetHeader.name = "Header";
    worksheetHeader.columns = [
      { key: "A", width: 15 },
      { key: "B", width: 20 },
      { key: "C", width: 30 },
      { key: "D", width: 20 },
      { key: "E", width: 20 },
      { key: "F", width: 20 },
      { key: "G", width: 20 },
      { key: "H", width: 20 },
    ];

    const rowHeaderPosition = 1;
    const rowHeader = worksheetHeader.getRow(rowHeaderPosition);

    rowHeader.getCell(1).value = "Project";
    rowHeader.getCell(2).value = "No. Faktur";
    rowHeader.getCell(3).value = "Distributor";
    rowHeader.getCell(4).value = "Periode Sales";
    rowHeader.getCell(5).value = "Kode Outlet";
    rowHeader.getCell(6).value = "Nama Outlet";
    rowHeader.getCell(7).value = "Sektor";
    rowHeader.getCell(8).value = "Total Sales";

    for (let i = 1; i <= 8; i++) {
      rowHeader.getCell(i).font = {
        size: 10,
        bold: true,
      };
      rowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
    }

    for (let i = 1; i <= 8; i++) {
      for (let j = 1; j <= totalHeaderLength + 1; j++) {
        const cell = worksheetHeader.getCell(j, i);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    for (let i = 0; i < totalHeaderLength; i++) {
      const row = worksheetHeader.getRow(rowHeaderPosition + 1 + i);
      row.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      };

      insertDataToCell(getProjectType(dataDetail?.project), row, 1);
      insertDataToCell(dataDetail?.nomorInvoice, row, 2);
      insertDataToCell(dataDetail?.distributorName, row, 3);
      if (dataDetail?.salesPeriode) {
        row.getCell(4).value = new DateExodus(
          dataDetail?.salesPeriode
        ).getStringDateFormat("YYYY-MM-DD");
      } else {
        insertNullValueInRowCell(row, 4);
      }
      insertDataToCell(dataDetail?.outletCode, row, 5);
      insertDataToCell(dataDetail?.outletName, row, 6);
      insertDataToCell(
        getOutletSectorType(dataDetail?.outletSectorType),
        row,
        7
      );
      insertDataToCell(dataDetail?.totalSales, row, 8);
    }
  };

  const handleExportDetailSheet = async () => {
    worksheetDetail.name = "Detail";
    worksheetDetail.columns = [
      { key: "A", width: 15 },
      { key: "B", width: 20 },
      { key: "C", width: 30 },
      { key: "D", width: 15 },
      { key: "E", width: 30 },
      { key: "F", width: 15 },
      { key: "G", width: 20 },
      { key: "H", width: 20 },
      { key: "I", width: 20 },
    ];

    const rowHeaderPosition = 1;
    const rowHeader = worksheetDetail.getRow(rowHeaderPosition);

    rowHeader.getCell(1).value = "Project";
    rowHeader.getCell(2).value = "No. Faktur";
    rowHeader.getCell(3).value = "Nama Produk";
    rowHeader.getCell(4).value = "Kode Produk";
    rowHeader.getCell(5).value = "Kode Produk Distributor";
    rowHeader.getCell(6).value = "Qty";
    rowHeader.getCell(7).value = "Harga";
    rowHeader.getCell(8).value = "Diskon Principal";
    rowHeader.getCell(9).value = "Subtotal";

    for (let i = 1; i <= 9; i++) {
      rowHeader.getCell(i).font = {
        size: 10,
        bold: true,
      };
      rowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
    }

    for (let i = 1; i <= 9; i++) {
      for (let j = 1; j <= totalDetailLength + 1; j++) {
        const cell = worksheetDetail.getCell(j, i);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    for (let i = 0; i < totalDetailLength; i++) {
      const row = worksheetDetail.getRow(rowHeaderPosition + 1 + i);
      row.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      };

      insertDataToCell(getProjectType(dataDetail?.project), row, 1);
      insertDataToCell(dataDetail?.nomorInvoice, row, 2);
      insertDataToCell(dataDetail?.detailDistributor[i].productName, row, 3);
      if (!isEmpty(dataDetail?.detailDistributor[i].productCode?.toString())) {
        if (dataDetail?.detailDistributor[i].productCode !== 0) {
          row.getCell(4).value = dataDetail?.detailDistributor[i].productCode
            ?.toString()
            .padStart(6, "0");
        } else {
          insertNullValueInRowCell(row, 4);
        }
      } else {
        insertNullValueInRowCell(row, 4);
      }
      insertDataToCell(
        dataDetail?.detailDistributor[i].productNameDistributor,
        row,
        5
      );
      insertDataToCell(dataDetail?.detailDistributor[i].quantity, row, 6);
      insertDataToCell(dataDetail?.detailDistributor[i].salePrice, row, 7);
      insertDataToCell(dataDetail?.detailDistributor[i].diskonPi, row, 8);
      if (!isEmpty(dataDetail?.detailDistributor[i].diskonPi?.toString())) {
        row.getCell(8).value = dataDetail?.detailDistributor[i].diskonPi + "%";
      } else {
        row.getCell(8).value = "0%";
      }
      insertDataToCell(dataDetail?.detailDistributor[i].subTotal, row, 9);
    }
  };

  const handleExport = async () => {
    setLoadingExportDetail(true);

    handleExportHeaderSheet();
    handleExportDetailSheet();

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `sales_distributor_exported.xlsx`);

    setLoadingExportDetail(false);
  };

  React.useEffect(() => {
    setTotalDetailLength(dataDetail?.detailDistributor?.length || -1);
  }, [dataDetail]);

  const exportData = async () => {
    if (dataDetail) {
      handleExport();
    }
  };

  return { exportData, loadingExportDetail };
};

export default useExportDistributorDetail;
