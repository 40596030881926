import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import LoggedUser from "./models/common/LoggedUser";
import { setLoggedUser } from "./redux/AuthReducer/AuthReducer";
import useGetUserProfile from "./services/Authentication/useGetUserProfile";
import useGenerateNewToken from "./services/Authentication/useGenerateNewToken";
import LoginPage from "./features/Login/LoginPage";
import AppbarLayout from "./layout/Appbar/AppbarLayout";
import ApprovalRencanaKunjunganBase from "./features/Activity/RencanaKunjungan/Approval/components/ApprovalRencanaKunjunganBase";
import MonitoringBudget from "./features/MonitoringBudgeting/MonitoringBudgeting";
import EmailVerificationPage from "./features/ForgotPassword/EmailVerification/EmailVerificationPage";
import {
  EMAIL_VERIFICATION_NAVIGATION_URL,
  FORGOT_PASSWORD_NAVIGATION_ROOT,
  GENERATE_NEW_PASSWORD_URL,
  LOGIN_NAVIGATION_ROOT,
  MONITORING_BUDGETING,
  PROFILE_TEXT,
  MONITOR_SALES_PRODUCT_TEXT,
  SALES_DISTRIBUTOR,
  SALES_OUTLET_TEXT,
  OUTLET_CARD_PATH,
} from "./constants/common/RootConstants";
import NewPasswordInputPage from "./features/ForgotPassword/NewPasswordInput/NewPasswordInputPage";
import BiayaMurniPage from "./features/BiayaMurni/components/BiayaMurniPage";
import BiayaMurniDpPage from "./features/BiayaMurni/features/Dp/BiayaMurniDpPage";
import BiayaMurniDplDpfPage from "./features/BiayaMurni/features/DplDpf/BiayaMurniDplDpfPage";
import BiayaMurniEntertainmentPage from "./features/BiayaMurni/features/Entertainment/BiayaMurniEntertainmentPage";
import BiayaMurniPsspDokterPage from "./features/BiayaMurni/features/PsspDokter/BiayaMurniPsspDokterPage";
import BiayaMurniPsspNonDokterPage from "./features/BiayaMurni/features/PsspNonDokter/BiayaMurniPsspNonDokterPage";
import BiayaMurniStandarisasiPage from "./features/BiayaMurni/features/Standarisasi/BiayaMurniStandarisasiPage";
import ProfilePage from "./features/Profile/ProfilePage";
import InputRencanaKunjunganBase from "./features/Activity/RencanaKunjungan/Input/components/InputRencanaKunjunganBase";
import ApprovalRencanaKunjunganDetailBase from "./features/Activity/RencanaKunjungan/Approval/components/ApprovalRencanaKunjunganDetailBase";
import InputRealisasiKunjunganBase from "./features/Activity/RealisasiKunjungan/Input/components/InputRealisasiKunjunganBase";
import MonitorKunjunganUserListBase from "./features/Activity/MonitorKunjungan/UserList/components/MonitorKunjunganUserListBase";
import MonitorKunjunganMonitorListBase from "./features/Activity/MonitorKunjungan/MonitorList/components/MonitorKunjunganMonitorListBase";
import ApprovalRealisasiKunjunganPage from "./features/Activity/RealisasiKunjungan/Approval/components/ApprovalRealisasiKunjunganPage";
import * as Sentry from "@sentry/react";
import BiayaMurniDplDpfDetailPage from "./features/BiayaMurni/features/DplDpfDetail/BiayaMurniDplDpfDetail";
import DashboardPage from "./features/Dashboard/DashboardPage";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import SalesProductPage from "./features/Performance/SalesProduct/SalesProductPage";
import DetailApprovalRealisasiKunjunganPage from "./features/Activity/RealisasiKunjungan/Approval/components/DetailApprovalRealisasiKunjunganPage";
import SalesDistributor from "./features/Performance/SalesDistributor/SalesDistributor";
import SalesDistributorDetail from "./features/Performance/SalesDistributor/SalesDistributorDetail";
import SalesOutletDetail from "./features/Performance/SalesOutlet/pages/detail/components/SalesOutletDetail";
import SalesOutletList from "./features/Performance/SalesOutlet/pages/list/components/SalesOutletList";
import SalesOutletDetailEmptyId from "./features/Performance/SalesOutlet/pages/detail/components/common/SalesOutletDetailEmptyId";
import MonitorSalesTargetBase from "./features/Performance/SalesTarget/Monitor/components/MonitorSalesTargetBase";
import InputSalesTargetBase from "./features/Performance/SalesTarget/Input/components/InputSalesTargetBase";
import InputKlaimSalesBase from "./features/KlaimSales/Input/components/InputKlaimSalesBase";
import MonitorKlaimSalesBase from "./features/KlaimSales/Monitor/components/MonitorKlaimSalesBase";
import ApprovalKlaimSalesBase from "./features/KlaimSales/Approval/components/ApprovalKlaimSalesBase";
import DetailMonitorKlaimSalesBase from "./features/KlaimSales/Monitor/components/DetailMonitorKlaimSalesBase";
import DetailApprovalKlaimSalesBase from "./features/KlaimSales/Approval/components/DetailApprovalKlaimSalesBase";
import ProtectedPage from "./layout/ProtectedPage/ProtectedPage";
import verifyPageAccess from "./utils/VerifyPageAccess";
import {
  ROUTE_ID_APPROVAL_KLAIM_SALES,
  ROUTE_ID_APPROVAL_REALISASI_KUNJUNGAN,
  ROUTE_ID_APPROVAL_RENCANA_KUNJUNGAN,
  ROUTE_ID_BIAYA_MURNI,
  ROUTE_ID_INPUT_KLAIM_SALES,
  ROUTE_ID_INPUT_REALISASI_KUNJUNGAN,
  ROUTE_ID_INPUT_RENCANA_KUNJUNGAN,
  ROUTE_ID_INPUT_TARGET_SALES,
  ROUTE_ID_MONITORING_BUDGETING,
  ROUTE_ID_MONITORING_KLAIM_SALES,
  ROUTE_ID_MONITORING_KUNJUNGAN,
  ROUTE_ID_MONITORING_TARGET_SALES,
  ROUTE_ID_SALES_DISTRIBUTOR,
  ROUTE_ID_OUTLET_CARD,
  ROUTE_ID_SALES_OUTLET,
  ROUTE_ID_SALES_PRODUCT,
} from "./constants/common/RouteIdConstants";
import useKlaimSalesAccess from "./features/KlaimSales/Input/services/useKlaimSalesAccess";
import OutletCardList from "./features/Performance/OutletCard/OutletCardList/OutletCardList";
import OutletCardDetail from "./features/Performance/OutletCard/OutletCardDetail/OutletCardDetail";
import PageLoading from "./components/PageLoading/PageLoading";

const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [appLoading, setAppLoading] = React.useState<boolean>(true);

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );
  const { listenToRemote: listenToKlaimSalesRemoteAccess } =
    useKlaimSalesAccess();

  const fetchUserData = async () => {
    const data: LoggedUser = await useGetUserProfile();
    dispatch(setLoggedUser(data));
  };

  const getNewToken = async () => {
    try {
      await useGenerateNewToken();
      await fetchUserData();
    } catch (error) {
      if (
        !location.pathname.includes(
          FORGOT_PASSWORD_NAVIGATION_ROOT + EMAIL_VERIFICATION_NAVIGATION_URL
        ) &&
        !location.pathname.includes(
          FORGOT_PASSWORD_NAVIGATION_ROOT + GENERATE_NEW_PASSWORD_URL
        )
      ) {
        navigate(LOGIN_NAVIGATION_ROOT, { replace: true });
      }
    } finally {
      setAppLoading(false);
    }
  };

  React.useEffect(() => {
    setAppLoading(true);
    getNewToken();
    listenToKlaimSalesRemoteAccess();
  }, []);

  React.useEffect(() => {
    if (loggedUser) {
      Sentry.setUser({
        id: loggedUser.userId ? loggedUser.userId.toString() : undefined,
        username: loggedUser.userName && loggedUser.userName,
        email: loggedUser.userEmail && loggedUser.userEmail,
      });
    }
  }, [loggedUser]);

  if (appLoading) return <PageLoading />;

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />

      <Route
        path={
          FORGOT_PASSWORD_NAVIGATION_ROOT + EMAIL_VERIFICATION_NAVIGATION_URL
        }
        element={<EmailVerificationPage />}
      />
      <Route path={LOGIN_NAVIGATION_ROOT} element={<LoginPage />} />
      <Route
        path={
          FORGOT_PASSWORD_NAVIGATION_ROOT +
          GENERATE_NEW_PASSWORD_URL +
          "/:token"
        }
        element={<NewPasswordInputPage />}
      />
      <Route path="/*" element={<AppbarLayout />}>
        <Route index element={<DashboardPage />} />
        <Route
          path="rencana-kunjungan"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_INPUT_RENCANA_KUNJUNGAN
              )}
            >
              <InputRencanaKunjunganBase />
            </ProtectedPage>
          }
        />
        <Route
          path="realisasi-kunjungan/*"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_INPUT_REALISASI_KUNJUNGAN
              )}
            >
              <InputRealisasiKunjunganBase />
            </ProtectedPage>
          }
        />
        <Route
          path="rencana-kunjungan-approval"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_APPROVAL_RENCANA_KUNJUNGAN
              )}
            >
              <ApprovalRencanaKunjunganBase />
            </ProtectedPage>
          }
        />
        <Route
          path="rencana-kunjungan-approval/:id"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_APPROVAL_RENCANA_KUNJUNGAN
              )}
            >
              <ApprovalRencanaKunjunganDetailBase />
            </ProtectedPage>
          }
        />
        <Route
          path="realisasi-kunjungan-approval"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_APPROVAL_REALISASI_KUNJUNGAN
              )}
            >
              <ApprovalRealisasiKunjunganPage />
            </ProtectedPage>
          }
        />
        <Route
          path="realisasi-kunjungan-approval/:id"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_APPROVAL_REALISASI_KUNJUNGAN
              )}
            >
              <DetailApprovalRealisasiKunjunganPage />
            </ProtectedPage>
          }
        />
        <Route
          path="monitor-kunjungan"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_MONITORING_KUNJUNGAN
              )}
            >
              <MonitorKunjunganUserListBase />
            </ProtectedPage>
          }
        />
        <Route
          path="monitor-kunjungan/:page"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_MONITORING_KUNJUNGAN
              )}
            >
              <MonitorKunjunganMonitorListBase />
            </ProtectedPage>
          }
        />
        <Route
          path="sales-target"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_INPUT_TARGET_SALES
              )}
            >
              <InputSalesTargetBase />
            </ProtectedPage>
          }
        />
        <Route
          path="monitor-sales-target"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_MONITORING_TARGET_SALES
              )}
            >
              <MonitorSalesTargetBase />
            </ProtectedPage>
          }
        />
        <Route
          path={OUTLET_CARD_PATH}
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_OUTLET_CARD)}
            >
              <OutletCardList />
            </ProtectedPage>
          }
        />
        <Route
          path={OUTLET_CARD_PATH + "/:outletId"}
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_OUTLET_CARD)}
            >
              <OutletCardDetail />
            </ProtectedPage>
          }
        />
        <Route path="biaya-murni/*">
          <Route
            index
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_BIAYA_MURNI)}
              >
                <BiayaMurniPage />
              </ProtectedPage>
            }
          />
          <Route
            path="pssp-dokter"
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_BIAYA_MURNI)}
              >
                <BiayaMurniPsspDokterPage />
              </ProtectedPage>
            }
          />
          <Route
            path="pssp-non-dokter"
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_BIAYA_MURNI)}
              >
                <BiayaMurniPsspNonDokterPage />
              </ProtectedPage>
            }
          />
          <Route
            path="entertainment"
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_BIAYA_MURNI)}
              >
                <BiayaMurniEntertainmentPage />
              </ProtectedPage>
            }
          />
          <Route
            path="dpl-dpf"
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_BIAYA_MURNI)}
              >
                <BiayaMurniDplDpfPage />
              </ProtectedPage>
            }
          />
          <Route
            path="dpl-dpf/:outletId"
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_BIAYA_MURNI)}
              >
                <BiayaMurniDplDpfDetailPage />
              </ProtectedPage>
            }
          />
          <Route
            path="standarisasi"
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_BIAYA_MURNI)}
              >
                <BiayaMurniStandarisasiPage />
              </ProtectedPage>
            }
          />
          <Route
            path="dp"
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_BIAYA_MURNI)}
              >
                <BiayaMurniDpPage />
              </ProtectedPage>
            }
          />
        </Route>
        <Route path={SALES_OUTLET_TEXT + "/*"}>
          <Route
            index
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_SALES_OUTLET)}
              >
                <SalesOutletList />
              </ProtectedPage>
            }
          />
          <Route
            path="detail/"
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_SALES_OUTLET)}
              >
                <SalesOutletDetailEmptyId />
              </ProtectedPage>
            }
          />
          <Route
            path="detail/:outletId/"
            element={
              <ProtectedPage
                isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_SALES_OUTLET)}
              >
                <SalesOutletDetail />
              </ProtectedPage>
            }
          />
        </Route>
        <Route path={PROFILE_TEXT} element={<ProfilePage />} />
        <Route
          path={MONITOR_SALES_PRODUCT_TEXT}
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(loggedUser, ROUTE_ID_SALES_PRODUCT)}
            >
              <SalesProductPage />
            </ProtectedPage>
          }
        />
        <Route
          path={MONITORING_BUDGETING}
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_MONITORING_BUDGETING
              )}
            >
              <MonitoringBudget />
            </ProtectedPage>
          }
        />
        <Route
          path={SALES_DISTRIBUTOR}
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_SALES_DISTRIBUTOR
              )}
            >
              <SalesDistributor />
            </ProtectedPage>
          }
        />
        <Route
          path={SALES_DISTRIBUTOR + "/:id"}
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_SALES_DISTRIBUTOR
              )}
            >
              <SalesDistributorDetail />
            </ProtectedPage>
          }
        />
        <Route
          path="klaim-sales-input"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_INPUT_KLAIM_SALES
              )}
            >
              <InputKlaimSalesBase />
            </ProtectedPage>
          }
        />
        <Route
          path="klaim-sales-monitor"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_MONITORING_KLAIM_SALES
              )}
            >
              <MonitorKlaimSalesBase />
            </ProtectedPage>
          }
        />
        <Route
          path="klaim-sales-monitor/:id"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_MONITORING_KLAIM_SALES
              )}
            >
              <DetailMonitorKlaimSalesBase />
            </ProtectedPage>
          }
        />
        <Route
          path="klaim-sales-approval"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_APPROVAL_KLAIM_SALES
              )}
            >
              <ApprovalKlaimSalesBase />
            </ProtectedPage>
          }
        />
        <Route
          path="klaim-sales-approval/:id"
          element={
            <ProtectedPage
              isAllowed={verifyPageAccess(
                loggedUser,
                ROUTE_ID_APPROVAL_KLAIM_SALES
              )}
            >
              <DetailApprovalKlaimSalesBase />
            </ProtectedPage>
          }
        />
        <Route path={"*"} element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
