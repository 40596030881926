import { createContext, useContext } from "react";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { SalesProductFilterType } from "../models/SalesProductFilterType";
import useSalesProductFilter from "../services/useFilter";
import useGetSalesProductList from "../services/useGetList";
import { SalesProductListResponse } from "../models/SalesProductListResponse";
import useGetSalesProductTotalSalesValue from "../services/useGetTotalSalesValue";
import { TotalSalesValueModel } from "../models/TotalSalesValueModel";
import { PayloadParamType } from "../models/ProviderParamType";

type ValueType = {
  states: {
    filterValues: SalesProductFilterType | null;
    isLoadingFilter: boolean;
    errorFilter: ErrorResponse | null;
    getListData: SalesProductListResponse | null;
    isLoadingList: boolean;
    errorList: ErrorResponse | null;
    salesValue: TotalSalesValueModel | null;
    isLoadingSalesValue: boolean;
    errorSalesValue: ErrorResponse | null;
  };
  actions: {
    saveFilter: (filterValues: SalesProductFilterType) => Promise<void>;
    setLimit: (limit: number) => void;
    setPage: (value: "next" | "prev" | "first") => void;
  };
};

const SalesProductContext = createContext<ValueType>({
  states: {
    filterValues: null,
    isLoadingFilter: true,
    errorFilter: null,
    getListData: null,
    isLoadingList: true,
    errorList: null,
    salesValue: null,
    isLoadingSalesValue: true,
    errorSalesValue: null,
  },
  actions: {
    saveFilter: () => Promise.prototype,
    setLimit: () => {},
    setPage: () => {},
  },
});

function useSalesProductContext(): ValueType {
  return useContext(SalesProductContext);
}

const SalesProductContextProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const {
    filterValues,
    isLoading: isLoadingFilter,
    saveFilter,
    error: errorFilter,
  } = useSalesProductFilter();

  const payloadParameter: PayloadParamType = {
    startDate: filterValues?.startPeriod!!,
    endDate: filterValues?.endPeriod!!,
    page: filterValues?.page,
    limit: filterValues?.limit,
    userId: filterValues?.bawahan?.id,
    salesZoneId: filterValues?.zone?.salesZoneId,
    salesZoneType: filterValues?.zone?.salesZoneType,
    products: filterValues?.productList,
    sector: filterValues?.sector,
    project: filterValues?.project?.value,
  };

  const {
    data: getListData,
    isLoading: isLoadingList,
    error: errorList,
  } = useGetSalesProductList(payloadParameter);

  const {
    data: salesValue,
    isLoading: isLoadingSalesValue,
    error: errorSalesValue,
  } = useGetSalesProductTotalSalesValue(payloadParameter);

  const setPage = (value: "next" | "prev" | "first") => {
    let page: number = 0;
    if (filterValues?.page) {
      if (value === "next") {
        page = filterValues?.page + 1;
      }
      if (value === "prev") {
        page = filterValues?.page - 1;
      }
      if (value === "first") {
        page = 1;
      }
      const updatedFilterMetadata = {
        ...filterValues,
        limit: filterValues?.limit,
        page: page,
      };
      saveFilter(updatedFilterMetadata);
    }
  };

  const setLimit = (limit: number) => {
    if (filterValues?.limit) {
      const updatedFilterMetadata = {
        ...filterValues,
        page: 1,
        limit: limit,
      };
      saveFilter(updatedFilterMetadata);
    }
  };

  const value: ValueType = {
    states: {
      filterValues,
      isLoadingFilter,
      errorFilter,
      getListData,
      isLoadingList,
      errorList,
      salesValue,
      isLoadingSalesValue,
      errorSalesValue,
    },
    actions: { saveFilter, setLimit, setPage },
  };

  return (
    <SalesProductContext.Provider value={value}>
      {children}
    </SalesProductContext.Provider>
  );
};

export { SalesProductContextProvider, useSalesProductContext };
