import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { DraftRencanaKunjunganModelData } from "../models/DraftRencanaKunjunganModel";
import { useContext, useState } from "react";
import useDeleteDraftRencanaKunjungan from "../services/useDeleteDraftRencanaKunjungan";
import { useNavigate } from "react-router-dom";
import InputRencanaKunjunganContext from "../context/InputRencanaKunjunganContext";
import DateExodus from "../../../../../models/DateExodus";
import { CommonStatus } from "../../../../../models/common/Status";

type Props = {
  data: DraftRencanaKunjunganModelData;
};

const ButtonDeleteRencanaKunjungan: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();
  const navigate = useNavigate();

  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const { selectedPeriode, key, setKey } = useContext(
    InputRencanaKunjunganContext
  );

  const { deleteDraftRencanaKunjungan, loading } =
    useDeleteDraftRencanaKunjungan();

  const handleConfirmDelete = () => {
    deleteDraftRencanaKunjungan(props.data.id, {
      onSuccess: () => {
        toast({
          title: "Berhasil Menghapus Draft Rencana Kunjungan",
          status: "success",
        });
        setOpenConfirmationDialog(false);
        navigate("/rencana-kunjungan", { replace: true });
        setKey(key + 1);
      },
      onError: (errorMessage: string) => {
        toast({
          title: `Gagal Menghapus Draft Rencana Kunjungan`,
          description: `${errorMessage}`,
          status: "error",
        });
        setOpenConfirmationDialog(false);
      },
    });
  };

  const disableButtonDeleteCondition = (): boolean => {
    if (!selectedPeriode) return true;

    const dateNow = new DateExodus();
    const isDateNowGreaterThanSelectedStartPeriod: boolean =
      parseInt(JSON.stringify(dateNow)) >
      parseInt(JSON.stringify(selectedPeriode.start_period));

    if (selectedPeriode.status_plan !== CommonStatus.Pending) {
      return true;
    } else if (isDateNowGreaterThanSelectedStartPeriod) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Button
        variant="ghost"
        leftIcon={
          <MdDelete
            style={{
              width: !isDesktopView ? "20px" : "24px",
              height: !isDesktopView ? "20px" : "24px",
            }}
          />
        }
        sx={{
          fontWeight: "bold",
          fontSize: !isDesktopView ? 12 : 14,
          color: theme.colors.exodus.red,
          paddingX: !isDesktopView ? "8px" : 0,
        }}
        isDisabled={disableButtonDeleteCondition()}
        onClick={() => setOpenConfirmationDialog(true)}
      >
        Delete
      </Button>

      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        blockScrollOnMount
        isCentered
        isOpen={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <ModalOverlay />
        <ModalContent marginX="40px">
          <ModalHeader
            fontSize={24}
            fontWeight="semibold"
            color={theme.colors.exodus.primaryBlue}
          >
            Hapus Draft Rencana Kunjungan
          </ModalHeader>
          <ModalBody>
            Apakah Anda yakin mau menghapus Draft Rencana Kunjungan ini ?
          </ModalBody>
          <ModalFooter
            flexDirection={!isDesktopView ? "column" : "row-reverse"}
            gap={!isDesktopView ? 0 : 5}
          >
            <Button
              isLoading={loading}
              width="100%"
              colorScheme="buttonPrimary"
              onClick={() => handleConfirmDelete()}
            >
              Confirm
            </Button>
            <Button
              isDisabled={loading}
              onClick={() => setOpenConfirmationDialog(false)}
              width="100%"
              variant="outline"
              colorScheme="buttonSecondary"
              marginTop={!isDesktopView ? "12px" : 0}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ButtonDeleteRencanaKunjungan;
