window.global ||= window;
import React, { useContext } from "react";
import { Accordion, useMediaQuery, useTheme } from "@chakra-ui/react";
import { KlaimSalesDetailListModelData } from "../../models/KlaimSalesDetailModel";
import Masonry from "react-smart-masonry";
import DetailMonitorKlaimSalesContext from "../contexts/DetailMonitorKlaimSalesContext";
import DetailMonitorKlaimSalesCard from "./DetailMonitorKlaimSalesCard";

const DetailMonitorKlaimSalesCardBase: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { detailKlaimSalesData } = useContext(DetailMonitorKlaimSalesContext);

  if (!detailKlaimSalesData) return null;
  return (
    <Accordion allowMultiple>
      <Masonry
        columns={isDesktopView ? 2 : 1}
        gap={15}
        style={{ paddingBottom: "18px" }}
      >
        {detailKlaimSalesData.claimSales.map(
          (item: KlaimSalesDetailListModelData, index) => (
            <DetailMonitorKlaimSalesCard key={index} data={item} />
          )
        )}
      </Masonry>
    </Accordion>
  );
};

export default DetailMonitorKlaimSalesCardBase;
