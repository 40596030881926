import React from "react";
import {
  KlaimSalesDetailListModelData,
  KlaimSalesDetailListModelItem,
} from "../../../models/KlaimSalesDetailModel";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  useMediaQuery,
  useTheme,
  Text,
  AccordionPanel,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import DetailApprovalKlaimSalesItem from "./DetailApprovalKlaimSalesItem";

interface Props {
  key?: number;
  data: KlaimSalesDetailListModelData;
}

const DetailApprovalKlaimSalesCard: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <AccordionItem
      border="1px solid"
      borderRadius="8px"
      borderColor={theme.colors.exodus.primaryBlue}
    >
      <AccordionButton
        display="flex"
        justifyContent="space-between"
        borderRadius="8px"
        height={isDesktopView ? "60px" : "51px"}
        borderColor={theme.colors.exodus.primaryBlue}
      >
        <Box textAlign="left">
          <Text
            fontSize={isDesktopView ? "20px" : "16px"}
            fontWeight="semibold"
            color={theme.colors.exodus.primaryBlue}
          >
            {props.data.nomorFaktur} - Faktur
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel marginX="16px" >
        <Flex
          paddingBottom={isDesktopView ? "20px" : "10px"}
          direction="row"
          gap={2}
          fontWeight="semibold"
          fontSize={isDesktopView ? 20 : 14}
        >
          <Text color={theme.colors.exodus.primaryBlue}>Distributor: </Text>
          <Text>{props.data.distributorName}</Text>
        </Flex>
        {isDesktopView && (
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={2}
            paddingBottom={2}
            color={theme.colors.exodus.primaryBlue}
            fontSize={20}
            fontWeight="semibold"
          >
            <GridItem colSpan={9} textAlign={"left"}>
              List Produk
            </GridItem>
            <GridItem colSpan={3} textAlign={"left"}>
              Quantity
            </GridItem>
          </Grid>
        )}

        {props.data.claimSalesDetail.map(
          (data: KlaimSalesDetailListModelItem, index) => (
            <DetailApprovalKlaimSalesItem
              data={data}
              isRowColored={index % 2 !== 0}
              key={index}
            />
          )
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default DetailApprovalKlaimSalesCard;
