import { AxiosResponse } from "axios";
import AxiosBaseClient from "../../../dataProviders/config/AxiosBaseClient";
import { EXODUS_API_VERSION_1 } from "../../../constants/common/RootConstants";
import Budget from "../models/BudgetModel";
import { COMMON_ERRORS } from "../../../constants/common/ErrorConstants";
import IndexedDatabase from "../../../database/IndexDB";
import { isEmpty } from "lodash";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";

export const MonitoringBudgetingProvider = {
  getList: async (param: {
    start_period: number | undefined;
    end_period: number | undefined;
    user_id: number | undefined | null;
  }) => {
    const response: AxiosResponse = await AxiosBaseClient.get(
      EXODUS_API_VERSION_1 + "/monitor/budget",
      {
        params: param,
      }
    );

    const budgetList: Budget[] = response.data.data.map(
      (budget: any) => new Budget(budget)
    );

    if (response.status === 200) return Promise.resolve(budgetList);
    return Promise.reject(new Error(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA));
  },
  saveFilter: async (filterValues: any) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.monitoringBudgetFilter.toArray();

      if (isEmpty(databaseResponse)) {
        await IndexedDatabase.monitoringBudgetFilter.add(filterValues);
        return Promise.resolve(AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_MESSAGE);
      } else if (databaseResponse && databaseResponse.length === 1) {
        await IndexedDatabase.monitoringBudgetFilter.update(
          databaseResponse[0].id,
          filterValues
        );
        return Promise.resolve(AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_MESSAGE);
      }

      await IndexedDatabase.monitoringBudgetFilter.clear();
      throw "Error";
    } catch (error: unknown) {
      return Promise.reject("error");
    }
  },
  getSavedFilter: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    const databaseResponse =
      await IndexedDatabase.monitoringBudgetFilter.toArray();
    if (
      databaseResponse &&
      !isEmpty(databaseResponse) &&
      databaseResponse.length === 1
    ) {
      return Promise.resolve(databaseResponse[0]);
    }
    return Promise.resolve(null);
  },
};
