import { useEffect, useState } from "react";
import KlaimSalesFilterAndPagination from "../../models/KlaimSalesFilterAndPaginationModel";
import dayjs from "dayjs";
import MonitorKlaimSalesProvider from "../dataProviders/MonitorKlaimSalesProvider";

const useFilterKlaimSales = () => {
  const [filterValues, setFilterValues] =
    useState<KlaimSalesFilterAndPagination | null>(null);
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const [errorFilter, setErrorFilter] = useState<string | null>(null);

  const initialValue: KlaimSalesFilterAndPagination = {
    page: 1,
    limit: 10,
    startAt: dayjs().startOf("month").unix(),
    endAt: dayjs().endOf("month").unix(),
  };

  useEffect(() => {
    getFilterMonitorKlaimSales();
  }, []);

  const getFilterMonitorKlaimSales = async () => {
    try {
      setErrorFilter(null);
      setLoadingFilter(true);

      const data: KlaimSalesFilterAndPagination | null =
        await MonitorKlaimSalesProvider.getSavedFilterMonitorKlaimSales();

      if (!data) {
        setFilterValues(initialValue);
      } else {
        setFilterValues(data);
      }

      setLoadingFilter(false);
    } catch (error) {
      setErrorFilter(error as string);
      setLoadingFilter(false);
    }
  };

  const setFilterMonitorKlaimSales = async (
    data: KlaimSalesFilterAndPagination
  ) => {
    try {
      setErrorFilter(null);
      setLoadingFilter(true);

      await MonitorKlaimSalesProvider.setSaveFilterMonitorKlaimSales(data);

      setFilterValues(data);
      setLoadingFilter(false);
    } catch (error) {
      setErrorFilter(error as string);
      setLoadingFilter(false);
    }
  };

  const handleClearFilter = async () => {
    const resetFilter: KlaimSalesFilterAndPagination = {
      page: 1,
      limit: filterValues!.limit,
      startAt: dayjs().startOf("month").unix(),
      endAt: dayjs().endOf("month").unix(),
      claimUser: undefined,
      outletPanelName: undefined,
      outletTargetName: undefined,
      status: undefined,
      idKlaimSales: undefined,
    };
    setFilterValues(resetFilter);

    await MonitorKlaimSalesProvider.setSaveFilterMonitorKlaimSales(resetFilter);
  };

  const handleChangePageOrLimit = (
    type: "first" | "next" | "prev" | "limit",
    newLimit?: number
  ) => {
    const oldData = {
      claimUser: filterValues?.claimUser,
      outletPanelName: filterValues?.outletPanelName?.trim() ?? undefined,
      outletTargetName: filterValues?.outletTargetName?.trim() ?? undefined,
      status: filterValues?.status,
      startAt: filterValues?.startAt,
      endAt: filterValues?.endAt,
    };

    if (type === "first") {
      setFilterMonitorKlaimSales({
        ...oldData,
        page: 1,
        limit: filterValues!.limit!,
      });
    }

    if (type === "next") {
      setFilterMonitorKlaimSales({
        ...oldData,
        page: filterValues!.page! + 1,
        limit: filterValues!.limit!,
      });
    }

    if (type === "prev") {
      setFilterMonitorKlaimSales({
        ...oldData,
        page: filterValues!.page! - 1,
        limit: filterValues!.limit!,
      });
    }

    if (type === "limit") {
      setFilterMonitorKlaimSales({
        ...oldData,
        page: 1,
        limit: newLimit!,
      });
    }
  };

  return {
    filterValues,
    loadingFilter,
    errorFilter,
    setFilterMonitorKlaimSales,
    handleChangePageOrLimit,
    handleClearFilter,
  };
};

export default useFilterKlaimSales;
