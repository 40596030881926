import { AxiosResponse } from "axios";
import {
  OutletByCodeOrPanelParam,
  OutletByUserIdParam,
  OutletDetailParam,
} from "./OutletParamType";
import AxiosBaseClient from "../../config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import { OutletModel } from "../../../models/Outlet/OutletModel";
import ErrorResponse from "../../../models/common/ErrorResponse";
import IsTypeOfErrorResponse from "../../../utils/IsTypeOfErrorResponse";
import { SECTOR_LIST } from "../../../constants/common/SectorConstants";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const OutletProvider = {
  getOutletByUserId: async (params: OutletByUserIdParam) => {
    const queryParam = {
      user_id: params.userId,
      keyword: params.keyword,
      page: 1,
      limit: 10,
    };
    try {
      const response: AxiosResponse = await AxiosBaseClient.get("/v2/outlets", {
        params: queryParam,
      });
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const result: OutletModel[] = response.data.data.records.map(
          (json: any) => {
            return {
              outletId: json["id"],
              outletCode: json["outlet_code"],
              outletName: json["name"],
              sectorType: json["sector_type"],
              outletLabel: `${json["name"]} - ${json["outlet_code"]}`,
              address: json["address"],
              codePost: json["code_post"],
              groupTerritoryId: json["group_territory"] ?? null,
              groupTerritoryName: json["group_territory_name"] ?? null,
            };
          }
        );
        return Promise.resolve(result);
      }
      const errorResponse: ErrorResponse = {
        message: response.data.message,
        status: response.data.status,
        code: response.data.code,
      };
      return Promise.reject(errorResponse);
    } catch (error: any) {
      if (IsTypeOfErrorResponse(error)) {
        return Promise.reject(error);
      }
      const errorResponse: ErrorResponse = {
        message: `${error.message}. ${error.response.data.message}`,
        status: error.response.data.status,
        code: error.response.data.code,
      };
      return Promise.reject(errorResponse);
    }
  },
  getOutletDetail: async (params: OutletDetailParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/outlet/" + params.outletId
      );
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const responseData = response.data.data;
        const result: OutletModel = {
          outletId: responseData["id"],
          outletCode: responseData["outlet_code"],
          outletName: responseData["name"],
          outletLabel: `${responseData["name"]} - ${responseData["outlet_code"]}`,
          sectorType: SECTOR_LIST.find(
            (x) => x.value === responseData["sector_type"]
          )!,
          address: responseData["address"],
          codePost: responseData["code_post"],
          groupTerritoryId: responseData["group_territory"] ?? null,
          groupTerritoryName: responseData["group_territory_name"] ?? null,
        };
        return Promise.resolve(result);
      }
      const errorResponse: ErrorResponse = {
        message: response.data.message,
        status: response.data.status,
        code: response.data.code,
      };
      return Promise.reject(errorResponse);
    } catch (error: any) {
      if (IsTypeOfErrorResponse(error)) {
        return Promise.reject(error);
      }
      const errorResponse: ErrorResponse = {
        message: `${error.message}. ${error.response.data.message}`,
        status: error.response.data.status,
        code: error.response.data.code,
      };
      return Promise.reject(errorResponse);
    }
  },
  getOutletByCodeOrPanel: async function (params: OutletByCodeOrPanelParam) {
    const url = "/v1/outlet/code";
    const queryParam = {
      outlet_code: params.outletCode,
      panel: params.panel,
    };

    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: queryParam,
      });

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const responseData = response.data.data;
        const result: OutletModel = {
          outletId: responseData["outlet_id"],
          outletCode: responseData["outlet_code"],
          outletName: responseData["outlet_name"],
          sectorType: responseData["sector_type"],
          outletLabel: `${responseData["outlet_name"]} - ${responseData["outlet_code"]}`,
          address: responseData["address"],
          codePost: responseData["code_post"],
          groupTerritoryId: responseData["group_territory"] ?? null,
          groupTerritoryName: responseData["group_territory_name"] ?? null,
        };
        return Promise.resolve(result);
      }
      const errorResponse: ErrorResponse = {
        message: response.data.message,
        status: response.data.status,
        code: response.data.code,
      };
      return Promise.reject(errorResponse);
    } catch (error: any) {
      if (IsTypeOfErrorResponse(error)) {
        return Promise.reject(error);
      }
      const errorResponse: ErrorResponse = {
        message: `${error.message}. ${error.response.data.message}`,
        status: error.response.data.status,
        code: error.response.data.code,
      };
      return Promise.reject(errorResponse);
    }
  },
};

export default OutletProvider;
