import React, { useContext, useState } from "react";
import AllPeriodeApprovalRealisasiContext from "../context/AllPeriodeApprovalRealisasiContext";
import FilterApprovalRealisasiKunjunganContext from "../context/FilterApprovalRealisasiKunjunganContext";
import useExportApprovalKunjungan from "../services/useExportApprovalRealisasi";
import { Box, useMediaQuery, useTheme } from "@chakra-ui/react";
import ExportExcelButton from "../../../../../components/CustomButtons/ExportExcelButton";
import ShowFilterButton from "../../../../../components/CustomButtons/ShowFilterButton";
import DropdownAllPeriode from "../../../components/DropdownAllPeriode";
import ApprovalRealisasiKunjunganFilterBoxSegment from "./ApprovalRealisasiKunjunganFilterBoxSegment";
import DialogFilterApprovalRealisasiKunjungan from "./DialogFilterApprovalRealisasiKunjungan";

const ApprovalRealisasiKunjunganToolbar: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { exportData } = useExportApprovalKunjungan();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const { setPeriode, periode, approvalListData } = useContext(
    FilterApprovalRealisasiKunjunganContext
  );
  const { periodeData } = useContext(AllPeriodeApprovalRealisasiContext);

  return (
    <>
      <Box
        display={"flex"}
        paddingBottom={isDesktopView ? "30px" : 0}
        paddingTop={"30px"}
        justifyContent={isDesktopView ? "space-between" : "start"}
        alignItems={"start"}
        gap={isDesktopView ? "4px" : "5px"}
        flexDirection={isDesktopView ? "row" : "column"}
      >
        <Box
          width={isDesktopView ? "301px" : "100%"}
          marginBottom={isDesktopView ? 0 : "5px"}
        >
          <DropdownAllPeriode
            options={periodeData}
            value={periode}
            onChange={setPeriode}
          />
        </Box>
        <ApprovalRealisasiKunjunganFilterBoxSegment />
        <Box
          gap={isDesktopView ? 2 : 0}
          flexWrap={isDesktopView ? "wrap" : "nowrap"}
          alignContent="end"
          display={"flex"}
          width={!isDesktopView ? "100%" : "fit-content"}
          justifyContent={"flex-end"}
        >
          <ShowFilterButton
            onClick={() => {
              setOpenFilter(true);
            }}
          />
          {isDesktopView && (
            <ExportExcelButton
              onClick={() => exportData()}
              isDisabled={approvalListData.length === 0}
            />
          )}
        </Box>
      </Box>
      <DialogFilterApprovalRealisasiKunjungan
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      />
    </>
  );
};

export default ApprovalRealisasiKunjunganToolbar;
