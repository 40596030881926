/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { MinimalVisitParam } from "../../dataProviders/RencanaKunjunganParamType";
import MinimalVisitDataModel from "../models/MinimalVisitDataModel";
import RencanaKunjunganProvider from "../../dataProviders/RencanaKunjunganProvider";

const useGetMinimalVisit = ({ role_id }: MinimalVisitParam) => {
  const [minimalVisitData, setData] = useState<MinimalVisitDataModel | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: MinimalVisitDataModel | undefined =
          await RencanaKunjunganProvider.getMinimalVisit({ role_id: role_id });

        setData(responseData!);

        setLoading(false);
      } catch (error: any) {
        setData(null);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    if (role_id) {
      getData();
    }
  }, [role_id]);

  return { minimalVisitData, loading, errorMessage };
};

export default useGetMinimalVisit;
