import { useBiayaMurniDplDpfDetailContext } from "../../contexts/BiayaMurniDplDpfDetailContext";
import { Stack, VStack } from "@chakra-ui/react";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import { BiayaMurniDplDpfPageProps } from "../../models/BiayaMurniDplDpfDetailPageProps";
import BackButton from "./common/BackButton";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";
import { BiayaMurniDplDpfDetailData } from "../../../../models/apiResponses/BiayaMurniDplDpfDetailData";
import TotalBiayaStack from "../common/TotalBiayaStack";
import { projectEnumToUi } from "../../../../../../models/common/Project";

const BiayaMurniDplDpfDetailPageDesktop = ({
  outletDetail,
  isLoadingFetchingOutlet,
  errorFetchingOutlet,
}: BiayaMurniDplDpfPageProps): JSX.Element => {
  const {
    states: { data, error: errorGetList, isLoading: isLoadingGetList },
  } = useBiayaMurniDplDpfDetailContext();

  const totalSalesValue: number | undefined = data?.products.reduce(
    (accumulator, currentValue) => accumulator + currentValue.discountCost,
    0
  );

  if (errorFetchingOutlet || errorGetList) {
    return (
      <VStack alignItems={"stretch"}>
        <PageTitle label="DPL/DPF Detail" />
        <ErrorPage
          message={
            errorFetchingOutlet
              ? errorFetchingOutlet.message
              : errorGetList?.message!!
          }
        />
      </VStack>
    );
  }

  return (
    <VStack alignItems={"stretch"}>
      <PageTitle label="DPL/DPF Detail" />
      {data?.hasProject && <PageTitle label={projectEnumToUi(data?.project)} />}
      <PageTitle label={outletDetail?.outletName!!} />
      <Stack width={"100%"} direction={"row"} justifyContent={"flex-end"}>
        <BackButton />
      </Stack>
      {data?.products === null &&
      !isLoadingGetList &&
      !isLoadingFetchingOutlet ? (
        <DataNotFound />
      ) : (
        <>
          <TotalBiayaStack totalSalesValue={totalSalesValue} />
          <TableDataComponent
            data={data?.products ?? []}
            expandComponent={() => {}}
            onSelectChange={() => {}}
            paginationOn={false}
            tableHeader={[
              {
                selector: (record: BiayaMurniDplDpfDetailData) =>
                  record.productName,
                name: "Nama Produk",
                width: "40%",
              },
              {
                name: "Sales",
                selector: (record: BiayaMurniDplDpfDetailData) =>
                  CurrencyIdrFormatter.convertToRp(record.salesValue),
              },
              {
                name: "Diskon",
                selector: (record: BiayaMurniDplDpfDetailData) =>
                  `${(record.discountPercentage * 100).toFixed(2)}%`,
              },
              {
                name: "Biaya",
                selector: (record: BiayaMurniDplDpfDetailData) =>
                  CurrencyIdrFormatter.convertToRp(record.discountCost),
              },
            ]}
            checboxOn={false}
            paginationLimit={[]}
            isLoading={isLoadingGetList || isLoadingFetchingOutlet}
          />
        </>
      )}
    </VStack>
  );
};

export default BiayaMurniDplDpfDetailPageDesktop;
