import { AxiosResponse, isAxiosError } from "axios";
import AxiosAuthClient from "../../config/AxiosAuthClient";
import AxiosBaseClient from "../../config/AxiosBaseClient";
import LoggedUser from "../../../models/common/LoggedUser";
import { MapUserData } from "../../../models/Authentication/MapUserData";
import {
  AUTHENTICATION_API_ROOT,
  EXODUS_API_VERSION_1,
} from "../../../constants/common/RootConstants";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";
import { APP_ID } from "../../../constants/common/AppId";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

export const AuthenticationProvider = {
  login: async (param: {
    email: string;
    password: string;
    remember_me: boolean;
  }) => {
    try {
      const response: AxiosResponse = await AxiosAuthClient.post(
        EXODUS_API_VERSION_1 + AUTHENTICATION_API_ROOT + "/login",
        {
          email: param.email,
          password: param.password,
          remember_me: param.remember_me,
        },
        {
          headers: {
            "app-id": APP_ID,
          },
        }
      );
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const resolvedResponse = {
          status: response.status,
          message: response.data.message as string,
        };
        return Promise.resolve(resolvedResponse);
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error: any) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.error_message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },

  generateNewToken: async () => {
    const response: AxiosResponse = await AxiosAuthClient.post(
      EXODUS_API_VERSION_1 + AUTHENTICATION_API_ROOT + "/generate_token",
      null,
      {
        headers: {
          "app-id": APP_ID,
        },
      }
    );
    if (response.data.message === SUCCESS_MESSAGE)
      return Promise.resolve(response.data.message);

    return Promise.reject(response.data.message);
  },

  getUserProfile: async () => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        EXODUS_API_VERSION_1 + "/user/profile"
      );

      if (response.data.message === SUCCESS_MESSAGE) {
        const userData: LoggedUser = MapUserData(response);
        return Promise.resolve(userData);
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
};
