/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableColumn } from "react-data-table-component";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../models/RealisasiAndExtraCallDataModel";
import StackedColumn from "../../../../../components/TableData/StackedColumnTable";
import { Box, Text } from "@chakra-ui/react";
import ButtonInputRealisasiAndEditExtraCall from "../components/ButtonInputRealisasiAndEditExtraCall";
import ButtonLihatDetailRealisasiAndExtraCall from "../components/ButtonLihatDetailRealisasiAndExtraCall";
import ButtonDeleteExtraCall from "../components/ButtonDeleteExtraCall";

const GenerateExtraCallTableColumnData = () => {
  const extraCallDataColumn: TableColumn<any>[] = [
    {
      name: "Nama Outlet",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.outletName}
          bottomValue={row.outletCode}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "250px",
      grow: 1.5,
    },
    {
      name: "Nama Customer",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.customerName}
          bottomValue={row.customerCode}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "250px",
      grow: 1.5,
    },
    {
      name: "Spesialis",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <Text textAlign="left">{row.spesialis}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "200px",
    },
    {
      name: "Jabatan",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <Text textAlign="left">{row.jabatan}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 0.5,
    },
    {
      name: "",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <Box>
          {row.komentarKunjungan && (
            <ButtonLihatDetailRealisasiAndExtraCall data={row} />
          )}
        </Box>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      right: true,
      grow: 0.5,
    },
    {
      name: "",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <Box>
          <ButtonInputRealisasiAndEditExtraCall data={row} buttonText="Edit" />
        </Box>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      right: true,
      grow: 0.5,
    },
    {
      name: "",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <Box>
          <ButtonDeleteExtraCall data={row} />
        </Box>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      right: true,
      grow: 0.5,
    },
  ];

  return extraCallDataColumn;
};

export default GenerateExtraCallTableColumnData;
