import { Box, useTheme, Text, Button } from "@chakra-ui/react";
import React from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import { DetailApprovalRealisasiKunjunganModel } from "../../models/ApprovalRealisasiKunjunganModel";
import { MdOutlineImage } from "react-icons/md";

const ExpandedDetailApprovalRealisasiCard: React.FC<
  ExpanderComponentProps<DetailApprovalRealisasiKunjunganModel>
> = ({ data }) => {
  const theme = useTheme();

  const handleClick = (url: string | undefined) => {
    if (!url) return;

    if (url.includes("http")) {
      window.open(url);
    } else {
      const image = new Image();
      image.src = url;
      const newTab = window.open();
      newTab!.document.body.innerHTML = image.outerHTML;
    }
  };

  if (!data) return null;

  return (
    <Box
      width="100%"
      paddingLeft="48px"
      paddingTop="8px"
      backgroundColor={theme.colors.exodus.background}
    >
      <Box display="flex" flexDirection="row">
        <Box marginRight="62px" textAlign={"left"}>
          <Text
            fontWeight="bold"
            fontSize={14}
            color={`${theme.colors.exodus.primaryBlue}`}
          >
            Waktu Kunjungan
          </Text>
          <Text fontSize={14}>
            {data.date && data.startTime && data.endTime
              ? `${data.formattedDate}, ${data.formattedStartTime} - ${data.formattedEndTime}`
              : "-"}
          </Text>
        </Box>
        <Box textAlign={"left"}>
          <Text
            fontWeight="bold"
            fontSize={14}
            color={theme.colors.exodus.primaryBlue}
          >
            Foto
          </Text>
          <Button
            colorScheme="buttonPrimary"
            isDisabled={!data.photo}
            variant="ghost"
            size={"sm"}
            sx={{ textDecoration: "underline", padding: 0 }}
            leftIcon={<MdOutlineImage />}
            onClick={() => handleClick(data.photo)}
          >
            Lihat Foto
          </Button>
        </Box>
      </Box>
      <Box paddingY="14px" width="70%">
        <Box textAlign={"left"}>
          <Text
            fontWeight="bold"
            fontSize={14}
            color={`${theme.colors.exodus.primaryBlue}`}
          >
            {!data.date && data.komentarKunjungan
              ? "Komentar Kunjungan (Gagal Kunjungan)"
              : "Komentar Kunjungan"}
          </Text>
          <Text fontSize={14} sx={{ wordBreak: "break-word" }}>
            {data.komentarKunjungan ?? "-"}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ExpandedDetailApprovalRealisasiCard;
