import { AxiosResponse, isAxiosError } from "axios";
import AxiosAuthClient from "../../../../dataProviders/config/AxiosAuthClient";
import {
  AUTHENTICATION_API_ROOT,
  EXODUS_API_VERSION_1,
} from "../../../../constants/common/RootConstants";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

export const NewPasswordInputProvider = {
  verifyForgotPasswordToken: async ({ token }: { token: string }) => {
    try {
      const response: AxiosResponse = await AxiosAuthClient.post(
        `${EXODUS_API_VERSION_1}${AUTHENTICATION_API_ROOT}/forget/validate`,
        {
          token: token,
        }
      );

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const userId: number = response.data.data.user_id;
        return Promise.resolve(userId);
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.error_message,
          status: error.response?.status,
          code: error.code,
        });
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
  sendNewPassword: async ({
    userId,
    newPassword,
  }: {
    userId: number;
    newPassword: string;
  }) => {
    try {
      const response: AxiosResponse = await AxiosAuthClient.post(
        `${EXODUS_API_VERSION_1}${AUTHENTICATION_API_ROOT}/forget/change/` +
          userId,
        {
          new_password: newPassword,
        }
      );

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const resolvedResponse = {
          status: response.status,
          message: response.data.message as string,
        };
        return Promise.resolve(resolvedResponse);
      }
      
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.error_message,
          status: error.response?.status,
          code: error.code,
        });
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
};
