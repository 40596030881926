import { createContext } from "react";
import PeriodeDataModel from "../../../models/PeriodeDataModel";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../models/RealisasiAndExtraCallDataModel";

type InputRealisasiKunjunganContextProps = {
  periodeData: PeriodeDataModel[];
  periodeLoading: boolean;
  periodeError: string | null;
  selectedPeriode: PeriodeDataModel | null;
  setSelectedPeriode: (data: PeriodeDataModel | null) => void;
  realisasiOrExtraCallData: RealisasiAndExtraCallDetailRealizationModelData[];
  realisasiOrExtraCallLoading: boolean;
  realisasiOrExtraCallError: string | null;
  currentKunjungan: string;
  setCurrentKunjungan: (data: string) => void;
  key: number;
  setKey: (data: number) => void;
};

const defaultValue: InputRealisasiKunjunganContextProps = {
  periodeData: [],
  periodeLoading: false,
  periodeError: null,
  selectedPeriode: null,
  setSelectedPeriode: () => {},
  realisasiOrExtraCallData: [],
  realisasiOrExtraCallLoading: false,
  realisasiOrExtraCallError: null,
  currentKunjungan: "",
  setCurrentKunjungan: () => {},
  key: 0,
  setKey: () => {},
};

const InputRealisasiKunjunganContext = createContext(defaultValue);

export default InputRealisasiKunjunganContext;
