import { AxiosResponse, isAxiosError } from "axios";
import { EXODUS_API_VERSION_3 } from "../../../../constants/common/RootConstants";
import AxiosBaseClient from "../../../../dataProviders/config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import {
  ERROR_GET_FROM_INDEXED_DB,
  UNKNOWN_ERROR_RESPONSE,
} from "../../../../constants/common/ErrorConstants";
import { PayloadParamType } from "../models/ProviderParamType";
import { SalesProductListResponse } from "../models/SalesProductListResponse";
import { TotalSalesValueModel } from "../models/TotalSalesValueModel";
import {
  SalesProductFilterSavedType,
  SalesProductFilterType,
} from "../models/SalesProductFilterType";
import IndexedDatabase from "../../../../database/IndexDB";
import DateExodus from "../../../../models/DateExodus";
import { SalesProductRecordModel } from "../models/SalesProductModel";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

export const SalesProductProvider = {
  getList: async (params: PayloadParamType) => {
    try {
      const productIds = params?.products?.map((product) => product.productId);
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_3}/sales/product`,
        {
          params: {
            start_date: params.startDate.toJSON(),
            end_date: params.endDate.toJSON(),
            page: params.page,
            limit: params.limit,
            sector: params.sector?.value,
            user_id: params.userId,
            sales_zone_id: params.salesZoneId,
            sales_zone_type: params.salesZoneType,
            product_id: productIds,
            project: params.project,
          },
        }
      );
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const resolvedResponse = new SalesProductListResponse(
          response.data.data
        );
        return Promise.resolve(resolvedResponse);
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error) {
      handleOnError(error);
    }
  },

  getTotalSalesValue: async (params: PayloadParamType) => {
    try {
      const productIds = params?.products?.map((product) => product.productId);
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_3}/sales/product/sales-summary`,
        {
          params: {
            start_date: params.startDate.toJSON(),
            end_date: params.endDate.toJSON(),
            user_id: params.userId,
            sector: params.sector,
            sales_zone_id: params.salesZoneId,
            sales_zone_type: params.salesZoneType,
            product_id: productIds,
            project: params.project,
          },
        }
      );

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const resolvedResponse: TotalSalesValueModel = {
          totalSalesProductValue: response.data.data.total_sales_product_value,
          totalEstimatedSalesProductValue:
            response.data.data.total_estimated_sales_product_value,
        };
        return Promise.resolve(resolvedResponse);
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error) {
      handleOnError(error);
    }
  },
  getListExcel: async (params: PayloadParamType) => {
    try {
      const productIds = params?.products?.map((product) => product.productId);
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_3}/sales/product/print`,
        {
          params: {
            start_date: params.startDate.toJSON(),
            end_date: params.endDate.toJSON(),
            user_id: params.userId,
            sector: params.sector?.value,
            sales_zone_id: params.salesZoneId,
            sales_zone_type: params.salesZoneType,
            product_id: productIds,
            project: params.project,
          },
        }
      );

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const resolvedResponse: SalesProductRecordModel[] =
          response?.data?.data.map((eachRecord: any) => {
            return {
              productId: eachRecord.product_id,
              productName: eachRecord.product_name,
              productCodeByPharos: eachRecord.product_pharos_code,
              estimatedUnit: eachRecord.estimated_unit,
              estimatedCost: eachRecord.estimated_value,
              salesUnit: eachRecord.sales_unit,
              salesValue: eachRecord.sales_value,
            };
          });
        return Promise.resolve(resolvedResponse);
      }

      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    } catch (error) {
      handleOnError(error);
    }
  },
  getIndexedDbFilter: async () => {
    try {
      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

      const oldData: SalesProductFilterSavedType[] | undefined =
        await IndexedDatabase.salesProductFilter.toArray();

      if (oldData && oldData.length === 1) {
        const updatedFilterValues: SalesProductFilterType = {
          startPeriod: new DateExodus(oldData[0].startPeriod),
          endPeriod: new DateExodus(oldData[0].endPeriod),
          sector: oldData[0].sector,
          zone: oldData[0].zone,
          bawahan: oldData[0].bawahan,
          productList: oldData[0].productList,
          role: oldData[0].role,
          page: oldData[0].page,
          limit: oldData[0].limit,
          project: oldData[0].project,
        };

        return Promise.resolve(updatedFilterValues);
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      handleErrorIndexedDb();
    }
  },
  saveIndexedDbFilter: async (updatedFilterValues: SalesProductFilterType) => {
    try {
      const savedFilterValues: SalesProductFilterSavedType = {
        startPeriod: updatedFilterValues.startPeriod.getEpochDate(),
        endPeriod: updatedFilterValues.endPeriod.getEpochDate(),
        sector: updatedFilterValues.sector,
        zone: updatedFilterValues.zone,
        bawahan: updatedFilterValues.bawahan,
        productList: updatedFilterValues.productList,
        role: updatedFilterValues.role,
        page: updatedFilterValues.page,
        limit: updatedFilterValues.limit,
        project: updatedFilterValues.project,
      };

      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

      const oldData: SalesProductFilterType[] | undefined =
        await IndexedDatabase.salesProductFilter.toArray();

      if (oldData && oldData.length === 1) {
        IndexedDatabase.salesProductFilter.update(
          oldData[0] as SalesProductFilterType,
          savedFilterValues
        );
      } else {
        IndexedDatabase.salesProductFilter.add(savedFilterValues);
      }
      return Promise.resolve();
    } catch (error) {
      handleErrorIndexedDb();
    }
  },
};

const handleOnError = (error: unknown) => {
  if (isAxiosError(error)) {
    return Promise.reject({
      message: error.response?.data.message,
      status: error.response?.status,
      code: error.code,
    });
  }
  return Promise.reject(UNKNOWN_ERROR_RESPONSE);
};

const handleErrorIndexedDb = () => {
  return Promise.reject(ERROR_GET_FROM_INDEXED_DB);
};
