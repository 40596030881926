class BiayaMurniDplDpfDetailData {
  productId: number;
  productName: string;
  salesValue: number;
  discountPercentage: number;
  discountCost: number;

  constructor(json: any) {
    this.productId = json["product_id"];
    this.productName = json["product_name"];
    this.salesValue = json["sales_value"];
    this.discountPercentage = json["discount_percentage"];
    this.discountCost = json["discount_cost"];
  }
}

export { BiayaMurniDplDpfDetailData };
