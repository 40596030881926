import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Spinner,
  useTheme,
  Text,
  AccordionIcon,
  AccordionPanel,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useGetRealisasiByHeaderUserIdAndActiveTab from "../../services/useGetRealisasiByHeaderUserIdAndActiveTab";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import { DetailApprovalRealisasiKunjunganModel } from "../../models/ApprovalRealisasiKunjunganModel";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";
import { MdOutlineImage } from "react-icons/md";
import useGetApprovalRealisasiUserId from "../../services/useGetApprovalRealisasiUserId";
import { isEmpty } from "lodash";

type Props = {
  activeTab: "realization" | "extracall";
};

const MobileDetailApprovalRealisasiDetailList: React.FC<Props> = ({
  activeTab,
}) => {
  const theme = useTheme();
  const { id } = useParams();
  const location = useLocation();
  const { data: persistentUserId } = useGetApprovalRealisasiUserId();

  const [userId, setUserId] = useState<number | null>(null);

  useEffect(() => {
    if (persistentUserId !== null) {
      setUserId(persistentUserId);
    }
  }, [persistentUserId]);

  const { realisasiDetailData, errorMessage, loading } =
    useGetRealisasiByHeaderUserIdAndActiveTab({
      header_id: Number(id),
      user_id: !isEmpty(location.state) ? location.state.userId : userId,
      activeTab: activeTab,
    });

  const handleClick = (url: string | undefined) => {
    if (!url) return;

    if (url.includes("http")) {
      window.open(url);
    } else {
      const image = new Image();
      image.src = url;
      const newTab = window.open();
      newTab!.document.body.innerHTML = image.outerHTML;
    }
  };

  if (loading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (errorMessage && realisasiDetailData.length < 0) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={errorMessage.toString()} />
      </Box>
    );
  }

  return realisasiDetailData.length > 0 ? (
    <>
      <Accordion allowToggle>
        {realisasiDetailData.map(
          (item: DetailApprovalRealisasiKunjunganModel) => (
            <AccordionItem key={item.id} padding={0}>
              <AccordionButton display="flex" justifyContent="space-between">
                <Box textAlign="left">
                  <StackedColumn
                    isTopBoldApplied
                    isTopColorPrimaryBlue
                    topValue={item.outletName}
                    bottomValue={item.outletCode}
                  />
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel marginX="16px">
                <Box display={"flex"} flexDirection={"column"} gap={3}>
                  {item.project && (
                    <StackedColumn
                      isTopBoldApplied
                      isTopColorPrimaryBlue
                      topValue="Project"
                      bottomValue={item.project.label}
                    />
                  )}
                  <StackedColumn
                    isTopBoldApplied
                    isTopColorPrimaryBlue
                    topValue={item.customerName}
                    bottomValue={item.customerCode}
                  />
                  <Box display={"flex"} flexDirection={"row"}>
                    <Box display={"flex"} flex={1}>
                      <StackedColumn
                        isTopBoldApplied
                        isTopColorPrimaryBlue
                        topValue="Spesialis"
                        bottomValue={item.spesialis}
                      />
                    </Box>
                    <Box display={"flex"} flex={1}>
                      <StackedColumn
                        isTopBoldApplied
                        isTopColorPrimaryBlue
                        topValue="Jabatan"
                        bottomValue={item.jabatan}
                      />
                    </Box>
                  </Box>

                  <StackedColumn
                    isTopBoldApplied
                    isTopColorPrimaryBlue
                    topValue="Waktu Kunjungan"
                    bottomValue={
                      item.date && item.startTime && item.endTime
                        ? `${item.formattedDate}, ${item.formattedStartTime} - ${item.formattedEndTime}`
                        : "-"
                    }
                  />
                  <Box textAlign={"left"}>
                    <Text
                      fontWeight="semibold"
                      fontSize={16}
                      color={theme.colors.exodus.primaryBlue}
                    >
                      Foto
                    </Text>
                    <Button
                      colorScheme="buttonPrimary"
                      isDisabled={!item.photo}
                      variant="ghost"
                      size={"md"}
                      sx={{ textDecoration: "underline", padding: 0 }}
                      leftIcon={<MdOutlineImage />}
                      onClick={() => handleClick(item.photo)}
                    >
                      Lihat Foto
                    </Button>
                  </Box>
                  <StackedColumn
                    isTopBoldApplied
                    isTopColorPrimaryBlue
                    topValue={
                      !item.date && item.komentarKunjungan
                        ? "Komentar Kunjungan (Gagal Kunjungan)"
                        : "Komentar Kunjungan"
                    }
                    bottomValue={item.tujuan}
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          )
        )}
      </Accordion>
    </>
  ) : (
    <Box
      marginTop="24px"
      marginBottom="15px"
      display={"flex"}
      justifyContent={"start"}
    >
      <DataNotFound />
    </Box>
  );
};

export default MobileDetailApprovalRealisasiDetailList;
