import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Text,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import React from "react";

type Props = {
  openConfirm: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
};

const DialogConfirmationApprovalSalesTarget: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <>
      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        blockScrollOnMount
        isCentered
        isOpen={props.openConfirm}
        onClose={props.onClose}
        scrollBehavior="outside"
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent marginX="40px">
          <ModalHeader
            fontSize={24}
            fontWeight="semibold"
            color={theme.colors.exodus.primaryBlue}
          >
            <Text>Konfirmasi Approval</Text>
          </ModalHeader>
          <ModalBody>
            <Text>Apakah anda yakin ingin memberikan Approval?</Text>
          </ModalBody>
          <ModalFooter>
            <Flex
              direction={!isDesktopView ? "column" : "row-reverse"}
              width={"100%"}
              gap={!isDesktopView ? 0 : 5}
            >
              <Button
                onClick={props.onSubmit}
                width="100%"
                colorScheme="buttonPrimary"
                isLoading={props.isLoading}
              >
                Submit
              </Button>
              <Button
                onClick={props.onClose}
                width="100%"
                variant="outline"
                colorScheme="buttonSecondary"
                marginTop={!isDesktopView ? "12px" : 0}
                isDisabled={props.isLoading}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DialogConfirmationApprovalSalesTarget;
