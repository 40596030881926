import DateExodus from "../../../../models/DateExodus";
import { BiayaMurniOrCode } from "../BiayaMurniOrCode";

class ApiGetOrCodesOfDpResponse {
  data: BiayaMurniOrCode[];

  constructor(json: any) {
    const tempData: BiayaMurniOrCode[] = [];
    for (let i = 0; i < json.data.length; i++) {
      const ptFullName = json.data[i]["group_name"];
      const ptInitial = json.data[i]["group_label"];
      const tglRealisasi = DateExodus.fromBackend(json.data[i]["tanggal_or"]);
      const orCode = json.data[i]["or_code"];
      tempData.push(
        new BiayaMurniOrCode(ptFullName, ptInitial, tglRealisasi, orCode)
      );
    }
    this.data = tempData;
  }
}

export { ApiGetOrCodesOfDpResponse };
