import { Text, useTheme } from "@chakra-ui/react";
import Multiselect from "multiselect-react-dropdown";
import { RegisterOptions, useController } from "react-hook-form";

interface CustomMultiselectProps {
  name: string;
  rules?: RegisterOptions;
  defaultValue?: any;
  options: any[];
  displayValue: string;
  placeholder: string;
  disabled?: boolean;
  onChange: (event: any) => void;
  onSearch?: (value: string) => void;
}

const CustomMultiselect: React.FC<CustomMultiselectProps> = (props) => {
  const theme = useTheme();
  const input = useController({
    name: props.name,
    defaultValue: props.defaultValue,
    rules: props.rules,
  });

  return (
    <>
      <Multiselect
        {...input}
        style={{
          chips: {
            backgroundColor: theme.colors.exodus.primaryBlue,
          },
          inputField: {
            margin: 3,
          },
          optionContainer: {
            backgroundColor: theme.colors.exodus.background,
          },
        }}
        avoidHighlightFirstOption={true}
        placeholder={props.placeholder}
        hidePlaceholder={true}
        selectedValues={props.defaultValue}
        isObject={true}
        onRemove={props.onChange}
        onSelect={props.onChange}
        options={props.options}
        displayValue={props.displayValue}
        disable={props.disabled}
        showCheckbox={true}
        onSearch={props.onSearch}
      />
      {input.fieldState.error && (
        <Text mt={1} mb={2} fontSize={14} color={theme.colors.exodus.red}>
          {input.fieldState.error.message}
        </Text>
      )}
    </>
  );
};

export default CustomMultiselect;
export type { CustomMultiselectProps };
