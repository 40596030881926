import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Icon,
  Box,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const OldPasswordInputField = () => {
  const theme = useTheme();
  const [isPasswordShown, setIsPasswordShown] = React.useState<boolean>(false);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const handleOnChangePasswordShown = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <FormControl isInvalid={!!errors.oldPassword}>
      <FormLabel
        color={theme.colors.exodus.primaryBlue}
        fontWeight={600}
        fontSize={16}
      >
        Password Lama
      </FormLabel>
      <InputGroup>
        <Input
          type={isPasswordShown ? "text" : "password"}
          style={
            errors.oldPassword && {
              borderColor: `${theme.colors.exodus.red}`,
              boxShadow: `0 0 1px ${theme.colors.exodus.red}`,
            }
          }
          placeholder={"Password Lama"}
          {...register("oldPassword", {
            required: "This is required",
            minLength: { value: 5, message: "Minimum length should be 5" },
          })}
        />
        <InputRightElement width="3rem">
          {isPasswordShown ? (
            <Icon
              as={FaEye}
              boxSize={5}
              onClick={handleOnChangePasswordShown}
              color={theme.colors.exodus.primaryBlue}
            />
          ) : (
            <Icon
              as={FaEyeSlash}
              boxSize={5}
              onClick={handleOnChangePasswordShown}
              color={theme.colors.exodus.primaryBlue}
            />
          )}
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>
        {errors?.oldPassword && (errors?.oldPassword.message as string)}
      </FormErrorMessage>
      <Box sx={{ mb: 5 }} />
    </FormControl>
  );
};

export default OldPasswordInputField;
