import { Box } from "@chakra-ui/react";
import FilterBox from "../../../../../../components/FilterBox/FilterBox";
import ShowFilterButton from "../../../../../../components/CustomButtons/ShowFilterButton";
import FilterApprovalRencanaKunjungan from "../FilterApprovalRencanaKunjungan";
import { useContext } from "react";
import ApprovalRencanaKunjunganContext from "../../context/ApprovalRencanaKunjunganContext";
import DropdownAllPeriode from "../../../../components/DropdownAllPeriode";
import IndexedDatabase from "../../../../../../database/IndexDB";
import AllPeriodeDataModel from "../../../../models/AllPeriodeDataModel";
import useSaveApprovalRencanaMetadata from "../../services/useSaveApprovalRencanaMetadata";

const MobileApprovalRencanaKunjunganToolbar = () => {
  const {
    nama,
    jabatan,
    status,
    selectedPeriode,
    isDialogOpen,
    periodeData,
    setSelectedPeriode,
    setIsDialogOpen,
    metadata,
    project,
    setPage,
  } = useContext(ApprovalRencanaKunjunganContext);

  const { saveMetadata } = useSaveApprovalRencanaMetadata();
  const handleOpenFilterDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setIsDialogOpen(false);
  };

  const getIndexedDBData = async () => {
    const data = await IndexedDatabase.rencanaKunjunganFilter.toArray();

    return data;
  };

  const handleChangePeriode = async (value: AllPeriodeDataModel | null) => {
    await saveMetadata({
      limit: metadata!.limit,
      currentPage: 1,
    });
    setPage(1);

    setSelectedPeriode(value);
    await IndexedDatabase.open();
    try {
      const oldData = await getIndexedDBData();

      const newValue: any = {
        jabatan: oldData[0]?.jabatan,
        status: oldData[0]?.status,
        bawahan: oldData[0]?.bawahan,
        periode: {
          start_period: value?.start_period.getEpochDate(),
          end_period: value?.end_period.getEpochDate(),
        },
        project: oldData[0]?.project,
      };

      if (oldData.length === 0 || !oldData) {
        await IndexedDatabase.rencanaKunjunganFilter
          .add(newValue)
          .then(async () => await getIndexedDBData());
      } else {
        await IndexedDatabase.rencanaKunjunganFilter.update(
          oldData[0].id,
          newValue
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box paddingY="16px" gap={2}>
        <Box flexDirection={"column"}>
          <DropdownAllPeriode
            options={periodeData}
            value={selectedPeriode}
            onChange={handleChangePeriode}
          />
        </Box>
        <Box marginTop={"20px"} display={"flex"} gap={2} textAlign={"left"}>
          {jabatan && <FilterBox title={"Jabatan"} content={jabatan} />}
          {nama && <FilterBox title={"Nama"} content={nama} />}
          {status && <FilterBox title={"Status"} content={status} />}
          {project && <FilterBox title={"Project"} content={project.label} />}
        </Box>
      </Box>
      <Box gap={1} display={"flex"} justifyContent={"end"}>
        <ShowFilterButton onClick={handleOpenFilterDialog} />
      </Box>
      <FilterApprovalRencanaKunjungan
        isOpen={isDialogOpen}
        onClose={handleCloseFilterDialog}
      />
    </>
  );
};

export default MobileApprovalRencanaKunjunganToolbar;
