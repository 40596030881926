import SectorModel from "../../models/Sector/SectorModel";

export const SECTOR_LIST: SectorModel[] = [
  {
    label: "Rumah Sakit",
    value: "hospital",
  },
  {
    label: "Klinik",
    value: "clinic",
  },
  {
    label: "Apotek",
    value: "pharmacy",
  },
  {
    label: "Apotek Panel",
    value: "pharmacy_panel",
  },
];
