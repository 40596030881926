import { Dispatch, SetStateAction, createContext } from "react";
import ApprovalRealisasiKunjunganFilterValues from "../models/ApprovalRealisasiKunjunganFilterValues";
import AllPeriodeDataModel from "../../../models/AllPeriodeDataModel";
import {
  ListApprovalKunjunganModelMetadata,
  ListApprovalKunjunganModelRecords,
} from "../../../models/ApprovalListModel";

export const filterApprovalRealisasiInitialValue: ApprovalRealisasiKunjunganFilterValues =
  new ApprovalRealisasiKunjunganFilterValues({
    role: null,
    bawahan: null,
    periode: null,
    status: null,
    project: null,
  });

interface FilterApprovalRealisasiKunjunganContextType {
  filterValues: ApprovalRealisasiKunjunganFilterValues;
  setFilterValues: Dispatch<
    SetStateAction<ApprovalRealisasiKunjunganFilterValues>
  >;
  periode: AllPeriodeDataModel | null;
  setPeriode: (data: AllPeriodeDataModel | null) => void;
  approvalListData: ListApprovalKunjunganModelRecords[];
  metadataList: ListApprovalKunjunganModelMetadata | null;
  errorList: string | null;
  loadingList: boolean;
}

const initialValue: FilterApprovalRealisasiKunjunganContextType = {
  filterValues: filterApprovalRealisasiInitialValue,
  setFilterValues: () => {},
  periode: null,
  setPeriode: () => {},
  approvalListData: [],
  metadataList: null,
  errorList: null,
  loadingList: false,
};

const FilterApprovalRealisasiKunjunganContext =
  createContext<FilterApprovalRealisasiKunjunganContextType>(initialValue);

export default FilterApprovalRealisasiKunjunganContext;
