import { Box, Flex, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import { MdCheck, MdWarning } from "react-icons/md";

type Props = {
  title: string;
  message: string;
  type: "warning" | "success";
};

const KunjunganAlertComponent: React.FC<Props> = (props) => {
  const theme = useTheme();

  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Box
      borderWidth={1}
      borderRadius="8px"
      marginBottom="16px"
      borderColor={
        props.type === "warning"
          ? theme.colors.exodus.yellow
          : theme.colors.exodus.green
      }
    >
      <Flex
        paddingX="16px"
        paddingY="12px"
        gap="16px"
        color={
          props.type === "warning"
            ? theme.colors.exodus.yellow
            : theme.colors.exodus.green
        }
      >
        {props.type === "warning" && (
          <MdWarning
            style={{
              width: isDesktopView ? "48px" : "40px",
              height: isDesktopView ? "48px" : "40px",
            }}
          />
        )}

        {props.type === "success" && (
          <MdCheck
            style={{
              width: isDesktopView ? "48px" : "40px",
              height: isDesktopView ? "48px" : "40px",
            }}
          />
        )}

        <Flex direction="column" textAlign="left" gap="2px">
          <Text fontSize={isDesktopView ? 16 : 12} fontWeight="semibold">
            {props.title}
          </Text>
          <Text fontSize={isDesktopView ? 16 : 12}>{props.message}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default KunjunganAlertComponent;
