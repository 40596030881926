import { useMediaQuery, useTheme } from "@chakra-ui/react";
import DesktopProfileCard from "./components/desktop/DesktopProfileCard";
import MobileProfile from "./components/mobile/MobileProfile";
import useTitle from "../../services/useTitle";

const ProfilePage = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  useTitle("Profile");

  return isDesktopView ? <DesktopProfileCard /> : <MobileProfile />;
};

export default ProfilePage;
