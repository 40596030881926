import { Box, Card, IconButton, Stack, Text, useTheme } from "@chakra-ui/react";
import { EditableFakturSalesData } from "../../models/EditableFakturModel";
import { MdCheckCircleOutline } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { usePilihProduk } from "../../contexts/PilihProdukContextProvider";

type Props = {
  data: EditableFakturSalesData;
};

/**
 * Called internally inside this file.
 *
 * @remarks
 * See caller.
 *
 * @returns JSX.Element
 *
 */
const _StatusBox = (data: EditableFakturSalesData): JSX.Element => {
  const theme = useTheme();

  if (data.isValid) {
    return (
      <Box
        sx={{
          width: 50,
          backgroundColor: theme.colors.exodus.green,
          height: "100%",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MdCheckCircleOutline
          style={{ fontSize: 24, color: theme.colors.exodus.white }}
        />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: 50,
          height: "100%",
          backgroundColor: theme.colors.exodus.primaryBlue,
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        }}
      />
    );
  }
};

/**
 * A component made for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * A singular component for each item inside of the list of "faktur"(s) from
 * [usePilihProduk()] in the UI. Most of the container area is clickable, and
 * if clicked, this faktur is marked as selected in [usePilihProduk()] hook.
 * Have delete button to remove this item from "faktur" list. Have a visible
 * status box of green or blue color, which signifies the "faktur"'s validity
 * to be submitted in the related claim.
 *
 * @returns JSX.Element
 *
 */
const KlaimSalesSelectableFaktur: React.FC<Props> = (props): JSX.Element => {
  const theme = useTheme();

  const {
    state: { currentEditingFaktur },
    actions: { setEditingFakturId, removeSingularFakturAttempt },
  } = usePilihProduk();

  function initiateCardColor() {
    if (props.data.id === currentEditingFaktur?.id) {
      if (props.data.isValid) {
        return "#DCF0D0";
      } else {
        return "#D0E1F0";
      }
    } else if (currentEditingFaktur !== null) {
      return "#D9D9D9";
    } else {
      return theme.colors.exodus.background;
    }
  }

  const cardColor = initiateCardColor();

  return (
    <Card
      sx={{
        borderRadius: "8px",
        backgroundColor: cardColor,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        cursor: "pointer",
        boxShadow: "0px 7px 24px 4px #282c7a26",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        flex={1}
        onClick={() => {
          if (!currentEditingFaktur) {
            setEditingFakturId(props.data.id);
          }
        }}
      >
        <_StatusBox
          id={props.data.id}
          originalData={props.data.originalData}
          newDetail={props.data.newDetail}
          isValid={props.data.isValid}
        />
        <Text
          color={theme.colors.exodus.primaryBlue}
          fontSize={20}
          fontWeight="semibold"
          padding="16.5px"
        >
          {props.data.originalData.invoice}
        </Text>
      </Stack>
      <Stack align="center" justify="center">
        <IconButton
          aria-label="close"
          variant="ghost"
          colorScheme="buttonPrimary"
          isRound
          onClick={() => {
            removeSingularFakturAttempt(props.data);
          }}
          isDisabled={
            currentEditingFaktur !== null &&
            props.data.id !== currentEditingFaktur.id
          }
          marginRight="16px"
          icon={
            <MdClose
              style={{
                fontSize: 32,
                color:
                  currentEditingFaktur !== null &&
                  props.data.id !== currentEditingFaktur.id
                    ? "A1A1A1"
                    : theme.colors.exodus.primaryBlue,
              }}
            />
          }
        />
      </Stack>
    </Card>
  );
};

export default KlaimSalesSelectableFaktur;
