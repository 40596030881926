import React from "react";
import { Button } from "@chakra-ui/react";
import { MdRemoveRedEye } from "react-icons/md";

interface ButtonLihatDetailProps {
  onClick: () => void;
  isDisabled?: boolean;
}

const ButtonLihatDetail: React.FC<ButtonLihatDetailProps> = ({
  onClick,
  isDisabled = false,
}) => {
  return (
    <Button
      variant="ghost"
      justifyContent={"end"}
      colorScheme="buttonSecondary"
      leftIcon={<MdRemoveRedEye style={{ width: "24px", height: "24px" }} />}
      sx={{
        fontWeight: "bold",
        fontSize: 14,
      }}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      Lihat Detail
    </Button>
  );
};

export default ButtonLihatDetail;
