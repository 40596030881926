import React, { useEffect } from "react";
import {
  StepperProvider,
  useStepper,
} from "../contexts/StepperContextProvider";
import { Box, Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import StepperKlaimSales from "./common/StepperKlaimSales";
import SummaryKlaimSalesBase from "./Summary/components/SummaryKlaimSalesBase";
import SelectFakturKlaimSalesBase from "./SelectFaktur/components/SelectFakturKlaimSalesBase";
import SearchOutletKlaimSalesBase from "./SearchOutlet/components/SearchOutletKlaimSalesBase";
import PilihProdukKlaimSalesBase from "./PilihProduk/components/PilihProdukKlaimSalesBase";
import useTitle from "../../../../services/useTitle";
import { useAppSelector } from "../../../../redux/hooks";
import KlaimSalesAccessWarningDialog from "./common/KlaimSalesAccessWarningDialog";

const InputKlaimSales: React.FC = () => {
  useTitle("Input Klaim Sales");

  return (
    <StepperProvider>
      <_AfterWrapper />
    </StepperProvider>
  );
};

const _AfterWrapper = (): JSX.Element => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const allowAccess: boolean | null = useAppSelector(
    (state) => state.klaimSalesReducer.allowAccess
  );

  if (!allowAccess) {
    return <KlaimSalesAccessWarningDialog />;
  }

  return (
    <>
      <Stack height={"100%"} minHeight="100vh">
        <Stack
          gap={2}
          justifyContent="space-between"
          direction={isDesktopView ? "row" : "column"}
        >
          <PageTitle label="Input Klaim Sales"></PageTitle>
          <Box width={isDesktopView ? 310 : 200}>
            <StepperKlaimSales />
          </Box>
        </Stack>
        <Box flex={1} py={2} display="flex" justifyContent="center">
          <_StepperChild />
        </Box>
      </Stack>
    </>
  );
};

const _StepperChild = (): JSX.Element => {
  const {
    state: { activeStepIdx },
    actions: { setSavedStepIdx },
  } = useStepper();

  useEffect(() => {
    setSavedStepIdx();
  }, [activeStepIdx]);

  switch (activeStepIdx) {
    case 0:
      return <SearchOutletKlaimSalesBase />;
    case 1:
      return <SelectFakturKlaimSalesBase />;
    case 2:
      return <PilihProdukKlaimSalesBase />;
    case 3:
      return <SummaryKlaimSalesBase />;
    default:
      return <></>;
  }
};

export default InputKlaimSales;
