import {
  Box,
  Text,
  useTheme,
  useMediaQuery,
  useDisclosure,
  CircularProgress,
} from "@chakra-ui/react";
import React from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import ExportExcelButton from "../../../components/CustomButtons/ExportExcelButton";
import TableDataComponent from "../../../components/TableData/TableDataComponent";
import { TableColumn } from "react-data-table-component";
import useTitle from "../../../services/useTitle";
import MobileSalesDistributorDetailInfo from "./component/MobileSalesDistributorDetailInfo";
import MobileSalesDistributorDetailList from "./component/MobileSalesDistributorDetailList";
import DesktopSalesDistributorDetailInfo from "./component/DesktopSalesDistributorDetailInfo";
import KeteranganSalesDialog from "./component/KeteranganSalesDialog";
import useGetSalesDistributorDetail from "./service/useGetSalesDistributorDetail";
import { GetListProducts } from "./model/GetListSalesDistributorDetailModelPack.ts";
import StackedColumn from "../../../components/TableData/StackedColumnTable.tsx";
import { isEmpty, isNil } from "lodash";
import ErrorPage from "../../../components/ErrorPage/ErrorPage.tsx";
import useExportDistributorDetail from "./service/useExportDistributorExcelDetail.ts";
import { useParams } from "react-router-dom";
import CurrencyIdrFormatter from "../../../utils/CurrencyIdrFormatter.ts";
import { getProjectType } from "./utils/SalesDistributorUtils.ts";

const SalesDistributorDetail: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  useTitle("Sales Distributor");
  const {
    isOpen: isKeteranganSalesOpen,
    onClose: onCloseKeteranganSales,
    onOpen: onOpenKeteranganSales,
  } = useDisclosure();

  const { distributorDetail, loading, error } =
    useGetSalesDistributorDetail(id);

  const { exportData, loadingExportDetail } =
    useExportDistributorDetail(distributorDetail);

  const tableHeader: TableColumn<any>[] = [
    {
      name: "Nama Produk",
      cell: (row: GetListProducts) => (
        <StackedColumn
          topValue={
            !isEmpty(row.productName)
              ? row.productName
              : row.productNameDistributor
          }
          bottomValue={row.productCode.toString().padStart(6, "0")}
          isTopBoldApplied={true}
          isTopColorPrimaryBlue
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1.5,
    },
    {
      name: "Product Code Distributor",
      selector: (row: GetListProducts) => row.productCodeBridging,
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1.5,
    },
    {
      name: "Quantity",
      selector: (row: GetListProducts) => row.quantity + " pc(s)",
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: "Price/Qty",
      selector: (row: GetListProducts) =>
        CurrencyIdrFormatter.convertToRp(row.salePrice),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: "Total",
      selector: (row: GetListProducts) =>
        CurrencyIdrFormatter.convertToRp(row.subTotal),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: "Diskon",
      selector: (row: GetListProducts) => row.diskonPi + "%",
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
  ];

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <PageTitle
          label={
            !isNil(distributorDetail) &&
            !isEmpty(distributorDetail.distributorName)
              ? distributorDetail.distributorName
              : "-"
          }
        />
        {isDesktopView && (
          <ExportExcelButton
            onClick={exportData}
            isLoading={loadingExportDetail}
          />
        )}
      </Box>
      <Text
        fontSize={{
          base: "16px",
          tablet: "24px",
        }}
        fontWeight={600}
        textAlign={"start"}
      >
        {!isNil(distributorDetail) && !isEmpty(distributorDetail.nomorInvoice)
          ? distributorDetail.nomorInvoice
          : "-"}
      </Text>
      <Text
        fontSize={{
          base: "16px",
          tablet: "24px",
        }}
        fontWeight={600}
        textAlign={"start"}
      >
        {!isNil(distributorDetail) && !isEmpty(distributorDetail.project)
          ? getProjectType(distributorDetail.project)
          : "-"}
      </Text>
      {isDesktopView ? (
        <DesktopSalesDistributorDetailInfo dataDetail={distributorDetail} />
      ) : (
        <MobileSalesDistributorDetailInfo
          dataDetail={distributorDetail}
          onOpenKeteranganSales={onOpenKeteranganSales}
        />
      )}
      {!isEmpty(error) ? (
        <ErrorPage message={error.message} />
      ) : loading ? (
        <CircularProgress
          sx={{
            display: "flex",
            marginTop: "32px",
            justifyContent: "center",
          }}
          isIndeterminate
        />
      ) : (
        <Box mt={"20px"}>
          {isDesktopView ? (
            <TableDataComponent
              data={
                !isEmpty(distributorDetail)
                  ? distributorDetail.detailDistributor
                  : []
              }
              checboxOn={false}
              paginationOn={false}
              tableHeader={tableHeader}
              paginationLimit={[0]}
              onSelectChange={() => {}}
            />
          ) : (
            distributorDetail &&
            distributorDetail.detailDistributor.map((eachData) => {
              return (
                <MobileSalesDistributorDetailList
                  key={eachData.salesDetailId}
                  data={eachData}
                />
              );
            })
          )}
        </Box>
      )}

      {!isDesktopView && (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          position="sticky"
          bottom={0}
          paddingY="12px"
          backgroundColor={theme.colors.exodus.background}
        >
          <ExportExcelButton
            onClick={exportData}
            isLoading={loadingExportDetail}
          />
        </Box>
      )}
      <KeteranganSalesDialog
        open={isKeteranganSalesOpen}
        onClose={onCloseKeteranganSales}
      />
    </Box>
  );
};

export default SalesDistributorDetail;
