/// Code made by Alex Filatov, copied from stackoverflow.

const numberOfDecimalPlace = function (value: number) {
  let text = value.toString();
  if (text.indexOf("e-") > -1) {
    let [, trail] = text.split("e-");
    let deg = parseInt(trail, 10);
    return deg;
  }
  if (Math.floor(value) !== value) {
    return value.toString().split(".")[1].length || 0;
  }
  return 0;
};

export default numberOfDecimalPlace;
