import {
  Grid,
  GridItem,
  Spinner,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useSalesProductContext } from "../../../contexts/SalesProductContextProvider";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";
import {
  ERROR_RETRIEVING_DATA,
  TOTAL_ESTIMATED_PRODUCT,
  TOTAL_SALES_PRODUCT,
} from "../../../constants/textConstants";

const TotalSalesAreaDesktop = () => {
  const theme = useTheme();
  const {
    states: { salesValue, isLoadingSalesValue, errorSalesValue },
  } = useSalesProductContext();

  if (errorSalesValue) {
    return (
      <Grid templateColumns="repeat(14, 1fr)" gap={2}>
        <GridItem colSpan={6}>
          <Text
            textAlign={"left"}
            color={theme.colors.exodus.red}
            fontSize={16}
            fontWeight={600}
          >
            {ERROR_RETRIEVING_DATA} {errorSalesValue.message}
          </Text>
        </GridItem>
      </Grid>
    );
  }
  return (
    <Stack gap={0}>
      <Grid templateColumns="repeat(20, 1fr)" gap={0}>
        <GridItem colSpan={3}>
          <Text
            textAlign={"left"}
            color={theme.colors.exodus.primaryBlue}
            fontSize={16}
            fontWeight={600}
          >
            {TOTAL_SALES_PRODUCT}
          </Text>
        </GridItem>
        <GridItem colSpan={17}>
          {isLoadingSalesValue ? (
            <Text textAlign={"left"}>
              <Spinner size="sm" color={theme.colors.exodus.primaryBlue} />
            </Text>
          ) : (
            <Text textAlign={"left"}>
              :{" "}
              {CurrencyIdrFormatter.convertToRp(
                salesValue?.totalSalesProductValue!!
              )}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(20, 1fr)" gap={0}>
        <GridItem colSpan={3}>
          <Text
            textAlign={"left"}
            color={theme.colors.exodus.primaryBlue}
            fontSize={16}
            fontWeight={600}
          >
            {TOTAL_ESTIMATED_PRODUCT}
          </Text>
        </GridItem>
        <GridItem colSpan={17}>
          {isLoadingSalesValue ? (
            <Text textAlign={"left"}>
              <Spinner size="sm" color={theme.colors.exodus.primaryBlue} />
            </Text>
          ) : (
            <Text textAlign={"left"}>
              :{" "}
              {CurrencyIdrFormatter.convertToRp(
                salesValue?.totalEstimatedSalesProductValue!!
              )}
            </Text>
          )}
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default TotalSalesAreaDesktop;
