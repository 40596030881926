import React from "react";
import {
  Step,
  Stepper,
  StepIndicator,
  StepStatus,
  StepNumber,
  StepSeparator,
  Box,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useStepper } from "../../contexts/StepperContextProvider";

const StepperKlaimSales: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const steps = [
    { index: "1" },
    { index: "2" },
    { index: "3" },
    { index: "4" },
  ];

  const {
    state: { activeStepIdx },
  } = useStepper();

  return (
    <Box paddingTop={"10px"}>
      <Stepper size={"lg"} index={activeStepIdx}>
        {steps.map((_i, index) => (
          <Step key={index}>
            <StepIndicator
              width={isDesktopView ? "60px" : "40px"}
              height={isDesktopView ? "60px" : "40px"}
            >
              <StepStatus
                complete={<StepNumber />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperKlaimSales;
