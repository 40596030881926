import { useContext } from "react";
import { Box, useTheme, useToast } from "@chakra-ui/react";
import useExportExcelMonitorKunjungan from "../../../services/useExportExcelMonitorKunjungan";
import MonitorKunjunganMonitorListContext from "../../context/MonitorKunjunganMonitorListContext";
import ExportExcelButton from "../../../../../../components/CustomButtons/ExportExcelButton";

const MobileButtonExportExcel: React.FC = () => {
  const theme = useTheme();
  const toast = useToast();

  const { currentPsspType, savedFilter, monitorKunjunganData } = useContext(
    MonitorKunjunganMonitorListContext
  );

  const { exportExcelMonitorKunjungan } = useExportExcelMonitorKunjungan(
    currentPsspType === "pssp-tidak-aktif" ? false : true,
    savedFilter
  );

  const handleClickExportExcel = () => {
    exportExcelMonitorKunjungan({
      onError: (errorMessage: string) => {
        toast({
          title: "Gagal Mengunduh Excel",
          description: errorMessage,
          status: "error",
        });
      },
    });
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      position="sticky"
      bottom={0}
      paddingY="12px"
      backgroundColor={theme.colors.exodus.background}
    >
      <ExportExcelButton
        onClick={handleClickExportExcel}
        isDisabled={monitorKunjunganData.length === 0}
      />
    </Box>
  );
};

export default MobileButtonExportExcel;
