import dayjs, { Dayjs, OpUnitType } from "dayjs";
import "dayjs/locale/id";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

class DateExodus {
  private date: number;

  constructor(value?: number) {
    if (!value) this.date = this.getEpochNow();
    else this.date = value;
  }

  public static fromBackend(value: number): DateExodus {
    const newTime = this.handleOffsetToLocalTime(value);
    return new DateExodus(newTime);
  }

  public static fromDateObject(value: Date): DateExodus {
    const unix = Math.floor(value.getTime() / 1000);
    return new DateExodus(unix);
  }

  toJSON() {
    return DateExodus.handleOffsetToServerTime(this.date);
  }

  public getEpochDate = () => {
    return this.date;
  };

  public getObjectDate = () => {
    return dayjs.unix(this.date);
  };

  public getStringDateFormat = (format: string) => {
    const formattedDate: Dayjs = dayjs.unix(this.date);
    return formattedDate.locale("id").format(format);
  };

  public getEpochStartOfMonth = () => {
    const objectFormat = dayjs.unix(this.date);
    const startOfMonth = objectFormat.startOf("month");
    return startOfMonth.unix() as number;
  };

  public getEpochEndOfMonth = () => {
    const objectFormat = dayjs.unix(this.date);
    const endOfMonth = objectFormat.endOf("month");
    return endOfMonth.unix();
  };

  public getEpochStartOfYear = () => {
    const objectFormat = dayjs.unix(this.date);
    const startOfYear = objectFormat.startOf("year");
    return startOfYear.unix() as number;
  };

  public startOf(unit: OpUnitType) {
    const objectFormat = dayjs.unix(this.date);
    const newDayJs = objectFormat.startOf(unit);
    return new DateExodus(newDayJs.unix() as number);
  }

  public endOf(unit: OpUnitType) {
    const objectFormat = dayjs.unix(this.date);
    const newDayJs = objectFormat.endOf(unit);
    return new DateExodus(newDayJs.unix() as number);
  }

  public getEpochEndOfYear = () => {
    const objectFormat = dayjs.unix(this.date);
    const endOfYear = objectFormat.endOf("year");
    return endOfYear.unix() as number;
  };

  public getDateObjectStartOfYear = () => {
    const objectFormat = dayjs.unix(this.date);
    const startOfYear = objectFormat.startOf("year");
    const dateFormat = startOfYear.unix() as number;

    return new Date(dateFormat * 1000);
  };

  public getDateObjectEndOfYear = () => {
    const objectFormat = dayjs.unix(this.date);
    const endOfYear = objectFormat.endOf("year");
    const dateFormat = endOfYear.unix() as number;
    return new Date(dateFormat * 1000);
  };

  public getDateObject = () => {
    return new Date(this.date * 1000);
  };

  private getEpochNow = () => {
    return dayjs().unix();
  };

  // time wasted gaslighting yourself from this logic : 2.

  /**  handle from Local time to Jakarta time (equivalent time in jakarta time utc)
   *
   *  eg :
   * send 00:00 from singapore.
   * jakarta utc + 7,
   *
   * send 00:00 from singapore.
   * singapore utc + 8
   * send 00:00 from singapore.
   *
   * after using this function,
   * singapore will see 01:00,
   * jakarta will see 00:00.
   *
   * this function sends the same hour, but instead of their local timezone, we're sending jakarta timezone.
   */
  private static handleOffsetToServerTime = (dateInEpoch: number) => {
    const jakartaOffset = 420;
    const localOffset = dayjs().utcOffset();

    const diff = jakartaOffset - localOffset;

    let result = dateInEpoch - diff * 60;

    return result;
  };

  /** handle from Jakarta time to Local time (equivalent time in Jakarta time utc)
   *
   * eg :
   * jakarta utc + 7,
   * singapore utc + 8
   *
   * backend/server sends 00:00 jakarta time.
   * after using this function,
   * singapore will see 00:00,
   *
   * a place where time is utc + 6, will see it also as 00:00,
   *
   * jakarta will see 00:00. (because it's jakarta time from the server)
   *
   * this function sends same hour based on their local timezone.
   */
  private static handleOffsetToLocalTime = (dateInEpoch: number) => {
    const jakartaOffset = 420;
    const localOffset = dayjs().utcOffset();

    const diff = jakartaOffset - localOffset;

    let result = dateInEpoch + diff * 60;

    return result;
  };
}

export default DateExodus;
