import { Button, useTheme, Text, useToast } from "@chakra-ui/react";
import usePostLogout from "../../services/usePostLogout";
import IsTypeOfErrorResponse from "../../../../utils/IsTypeOfErrorResponse";
import { FAILED_TO_LOGOUT } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { LOGIN_NAVIGATION_ROOT } from "../../../../constants/common/RootConstants";
import { useAppDispatch } from "../../../../redux/hooks";
import { clearLoggedUser } from "../../../../redux/AuthReducer/AuthReducer";
import { SUCCESS_LOGOUT } from "../../../Login/constants/commonConstants";

const LogoutButton = () => {
  const theme = useTheme();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleOnReject = (error: unknown) => {
    if (IsTypeOfErrorResponse(error)) {
      toast({
        title: FAILED_TO_LOGOUT,
        description: `${error.status} ${error.code} ${error.message}`,
        status: "error",
      });
    } else {
      toast({
        title: FAILED_TO_LOGOUT,
        description: `${error}`,
        status: "error",
      });
    }
  };

  const handleOnLogout = async () => {
    try {
      await usePostLogout();

      toast({
        title: SUCCESS_LOGOUT,
        status: "success",
      });

      dispatch(clearLoggedUser());
      navigate(LOGIN_NAVIGATION_ROOT, { replace: true });
    } catch (error: unknown) {
      handleOnReject(error);
    }
  };

  return (
    <Button
      colorScheme="buttonError"
      variant="solid"
      borderRadius={8}
      onClick={handleOnLogout}
    >
      <Text
        color={theme.colors.exodus.background}
        fontWeight={600}
        fontSize={16}
      >
        Logout
      </Text>
    </Button>
  );
};

export default LogoutButton;
