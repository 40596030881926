class ApiGetListResponseMetadata {
  totalData: number;
  totalDataPerPage: number;
  currentPage: number;
  previousPage: number;
  totalPage: number;
  nextPageUrl: string | null | undefined;
  previousPageUrl: string | null | undefined;
  firstPageUrl: string | null | undefined;
  lastPageUrl: string | null | undefined;

  constructor(json: any) {
    this.totalData = json["total_data"];
    this.totalDataPerPage = json["total_data_per_page"];
    this.currentPage = json["current_page"];
    this.previousPage = json["previous_page"];
    this.totalPage = json["total_page"];
    this.nextPageUrl = json["next_page_url"];
    this.previousPageUrl = json["previous_page_url"];
    this.firstPageUrl = json["first_page_url"];
    this.lastPageUrl = json["last_page_url"];
  }
}

export default ApiGetListResponseMetadata;
