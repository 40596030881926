import { useEffect } from "react";
import { Box, Stack } from "@chakra-ui/react";
import KlaimSalesDivider from "../../../common/KlaimSalesDivider";
import PilihProdukKlaimSalesFakturList from "../FakturList/PilihProdukKlaimSalesFakturList";
import FakturDetailBlank from "../FakturDetail/FakturDetailBlank";
import { useStepper } from "../../../../contexts/StepperContextProvider";
import { usePilihProduk } from "../../contexts/PilihProdukContextProvider";
import FakturDetailProduk from "../FakturDetail/FakturDetailProduk";

const DesktopPilihProdukKlaimSales: React.FC = () => {
  const {
    state: { currentEditingFaktur },
  } = usePilihProduk();

  const {
    actions: { setIsActionButtonHidden },
  } = useStepper();

  useEffect(() => {
    setIsActionButtonHidden(false);
  }, []);

  return (
    <Stack
      flexDirection="row"
      divider={<KlaimSalesDivider orientation="vertical" />}
      width="100%"
      height="100%"
    >
      <Box width="100%">
        <PilihProdukKlaimSalesFakturList />
      </Box>
      <Box width="100%">
        {currentEditingFaktur === null ? (
          <FakturDetailBlank />
        ) : (
          <FakturDetailProduk />
        )}
      </Box>
    </Stack>
  );
};

export default DesktopPilihProdukKlaimSales;
