import { useContext } from "react";
import DetailMonitorKlaimSalesContext from "../../contexts/DetailMonitorKlaimSalesContext";
import { Box, Text, Button, useTheme } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import ReactShowMoreText from "react-show-more-text";
import { CommonStatus } from "../../../../../models/common/Status";

const MobileDetailMonitorKlaimSalesBasicInformation = () => {
  const { detailKlaimSalesData, openPDF } = useContext(
    DetailMonitorKlaimSalesContext
  );
  const theme = useTheme();

  return (
    <Box marginBottom="24px" textAlign={"left"}>
      <Box marginBottom="8px">
        <Text
          fontSize={14}
          fontWeight="bold"
          color={theme.colors.exodus.primaryBlue}
        >
          Outlet Panel
        </Text>
        <Text fontSize={14}>
          {detailKlaimSalesData?.outletPanelName} -{" "}
          {detailKlaimSalesData?.outletPanelCode}
        </Text>
      </Box>
      <Box marginBottom="8px">
        <Text
          fontSize={14}
          fontWeight="bold"
          color={theme.colors.exodus.primaryBlue}
        >
          Outlet Tujuan
        </Text>
        <Text fontSize={14}>
          {detailKlaimSalesData?.outletTujuanName} -{" "}
          {detailKlaimSalesData?.outletTujuanCode}
        </Text>
      </Box>
      <Box marginBottom="16px">
        <Text
          fontSize={14}
          fontWeight="bold"
          color={theme.colors.exodus.primaryBlue}
        >
          Tanggal Pengajuan
        </Text>
        <Text fontSize={14}>
          {detailKlaimSalesData?.createdAt.getStringDateFormat("D MMMM YYYY")}
        </Text>
      </Box>
      {detailKlaimSalesData?.status !== CommonStatus.Pending && (
        <Box marginBottom="16px">
          <Text
            fontSize={14}
            fontWeight="bold"
            color={theme.colors.exodus.primaryBlue}
          >
            {detailKlaimSalesData?.status === CommonStatus.Rejected
              ? "Di-reject Oleh"
              : "Di-approve Oleh"}
          </Text>
          <Text fontSize={14}>{detailKlaimSalesData?.approvedByName}</Text>
        </Box>
      )}
      {detailKlaimSalesData?.status !== CommonStatus.Pending && (
        <Box marginBottom="16px">
          <Text
            fontSize={14}
            fontWeight="bold"
            color={theme.colors.exodus.primaryBlue}
          >
            {detailKlaimSalesData?.status === CommonStatus.Rejected
              ? "Tanggal Reject"
              : "Tanggal Approval"}
          </Text>
          <Text fontSize={14}>
            {detailKlaimSalesData?.approvalAt.getStringDateFormat(
              "D MMMM YYYY"
            )}
          </Text>
        </Box>
      )}
      {detailKlaimSalesData?.status === CommonStatus.Rejected && (
        <Box marginBottom="16px">
          <Text
            fontSize={14}
            fontWeight="bold"
            color={theme.colors.exodus.primaryBlue}
          >
            Alasan Reject
          </Text>
          <Text fontSize={14}>
            <ReactShowMoreText lines={1}>
              {detailKlaimSalesData?.keterangan}
            </ReactShowMoreText>
          </Text>
        </Box>
      )}
      <Box display="flex" gap={1}>
        <Text
          fontSize={14}
          fontWeight="bold"
          color={theme.colors.exodus.primaryBlue}
        >
          PDF Bukti :{" "}
        </Text>
        <Button
          variant="text"
          color={theme.colors.exodus.primaryBlue}
          alignItems={"baseline"}
          justifyContent={"start"}
          marginLeft={"-15px"}
          sx={{
            paddingTop: "3px",
            fontSize: "16px",
            textDecoration: "underline",
            ":hover": { textDecoration: "underline" },
          }}
          onClick={() => openPDF(detailKlaimSalesData!.pdfLink)}
          isDisabled={isEmpty(detailKlaimSalesData?.pdfLink)}
        >
          Lihat PDF
        </Button>
      </Box>
    </Box>
  );
};

export default MobileDetailMonitorKlaimSalesBasicInformation;
