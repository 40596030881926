import * as Sentry from "@sentry/react";

const SentryConfig: Sentry.BrowserOptions = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  release: import.meta.env.VITE_SENTRY_RELEASE,
  beforeSend(event, hint) {
    if (hint.originalException === "Timeout") return null;
    return event;
  },
  ignoreErrors: [
    "getReadModeConfig",
    "getReadModeExtract",
    "getReadModeRender",
  ],
  environment: import.meta.env.MODE,

  // Performance Monitoring
  // Capture 100% of the transactions, reduce in production!
  tracesSampleRate: import.meta.env.MODE === "development" ? 0.0 : 0.2,
};

export default SentryConfig;
