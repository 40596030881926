import {
  Box,
  FormControl,
  FormErrorMessage,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import useGetUserByRole from "../../../../../../services/Users/useGetUserByRole";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import useGetRoleByLoggedUser from "../../../../../../services/Users/useGetRoleByLoggedUser";
import ApprovalRencanaKunjunganContext from "../../context/ApprovalRencanaKunjunganContext";
import RoleModel from "../../../../../../models/Users/RoleModel";
import UserModel from "../../../../../../models/Users/UserModel";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";

const RencanaKunjunganJabatanDanBawahanFormField = () => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { filterValues } = useContext(ApprovalRencanaKunjunganContext);
  const [selectedRole, setSelectedRole] = useState<
    RoleModel | null | undefined
  >(filterValues?.jabatan);
  const [selectedNama, setSelectedNama] = useState<
    UserModel | null | undefined
  >(filterValues?.bawahan);

  const { roleData } = useGetRoleByLoggedUser();
  const { userData } = useGetUserByRole({
    roleName: selectedRole ? selectedRole.name : "",
  });
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  useEffect(() => {
    setValue("jabatan", selectedRole);
  }, [selectedRole]);

  useEffect(() => {
    setValue("bawahan", selectedNama);
  }, [selectedNama]);

  return (
    <>
      {
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: isDesktopView ? "row" : "column",
            justifyContent: isDesktopView ? "space-between" : "normal",
            marginBottom: "10px",
            gap: 3,
          }}
        >
          <Box sx={{ width: isDesktopView ? "35%" : "100%" }}>
            <FormControl width="100%" isInvalid={!!errors.jabatan}>
              <FormInputLabel title="Jabatan" />
              <CustomSelect
                {...register("jabatan")}
                isDisabled={false}
                isSearchable={true}
                isClearable={true}
                onChange={(selectedData: RoleModel | null) => {
                  setSelectedRole(selectedData);
                  setValue("jabatan", selectedData);
                  setSelectedNama(null);
                  setValue("bawahan", null);
                }}
                value={selectedRole}
                getOptionLabel={(data: RoleModel) => `${data.label}`}
                getOptionValue={(data: RoleModel) => `${data.name}`}
                placeholder={"Jabatan"}
                options={roleData}
              />
              <FormErrorMessage color={theme.colors.exodus.red}>
                {errors.jabatan && (errors.jabatan.message as string)}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box sx={{ width: isDesktopView ? "60%" : "100%" }}>
            <FormControl width="100%" isInvalid={!!errors.bawahan}>
              <FormInputLabel title="Cari Nama" />
              <CustomSelect
                {...register("bawahan", {
                  required: !isEmpty(getValues("jabatan")) ? true : false,
                })}
                isDisabled={!isEmpty(selectedRole) ? false : true}
                isSearchable={true}
                isClearable={true}
                onChange={(selectedData: UserModel | null) => {
                  setSelectedNama(selectedData);
                  setValue("bawahan", selectedData);
                }}
                value={selectedNama}
                getOptionLabel={(data: UserModel) => `${data.name}`}
                getOptionValue={undefined}
                placeholder={"Cari Nama"}
                options={userData}
              />
              <FormErrorMessage color={theme.colors.exodus.red}>
                {errors.bawahan?.type === "required" && "Required"}
                {errors.bawahan && (errors.bawahan.message as string)}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </Box>
      }
    </>
  );
};

export default RencanaKunjunganJabatanDanBawahanFormField;
