import { Box, Card, Spinner, Text, useTheme } from "@chakra-ui/react";
import React, { useContext } from "react";
import DashboardContext from "../context/DashboardContext";
import LoggedUser from "../../../models/common/LoggedUser";
import { ProjectEnum } from "../../../models/common/Project";
import { useAppSelector } from "../../../redux/hooks";

const BudgetNasionalCard = (): JSX.Element => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  if (loggedUser?.projects.find((e) => e.value === ProjectEnum.Ethical)) {
    return <BudgetNasionalCardContent />;
  } else {
    return <></>;
  }
};

const BudgetNasionalCardContent: React.FC = () => {
  const theme = useTheme();
  const context = useContext(DashboardContext);

  if (!context.budgetNasional || context.budgetNasionalLoading)
    return (
      <Box py={5}>
        <Spinner />
      </Box>
    );

  return (
    <Card
      width={"100%"}
      backgroundColor={theme.colors.exodus.background}
      boxShadow="0px 7px 24px 4px #282c7a26"
      borderRadius={"16px"}
    >
      <Box
        padding={{
          base: "14px",
          tablet: "24px",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Text
            fontSize={{
              base: "16px",
              tablet: "24px",
            }}
            fontWeight={700}
            color={theme.colors.exodus.primaryBlue}
            textAlign={"start"}
          >
            Budget Nasional
          </Text>
          {context.budgetNasionalError && (
            <Text py={4} color={theme.colors.exodus.red}>
              {context.budgetNasionalError}
            </Text>
          )}
          <Text
            fontSize={{
              base: "14px",
              tablet: "24px",
            }}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
            textAlign={"start"}
          >
            {context.budgetNasional.budgetNasionalValueUi}
          </Text>
        </Box>
        <Box
          mt={{
            base: "13.7px",
            tablet: "16px",
          }}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Text
            fontSize={{
              base: "12px",
              tablet: "16px",
            }}
            textAlign={"start"}
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
          >
            Target sisa + Sales berjalan
          </Text>
          <Text
            fontSize={{
              base: "12px",
              tablet: "16px",
            }}
            textAlign={"start"}
            color={theme.colors.exodus.fontDefault}
            fontWeight={600}
          >
            {context.budgetNasional.totalSalesValueUi}
          </Text>
        </Box>
        <Box
          mt={{
            base: "13.7px",
            tablet: "16px",
          }}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Text
            fontSize={{
              base: "12px",
              tablet: "16px",
            }}
            textAlign={"start"}
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
          >
            Persentase
          </Text>
          <Text
            fontSize={{
              base: "12px",
              tablet: "16px",
            }}
            textAlign={"start"}
            color={theme.colors.exodus.fontDefault}
            fontWeight={600}
          >
            {context.budgetNasional.persentase}%
          </Text>
        </Box>
        <Box
          mt={{
            base: "13.7px",
            tablet: "16px",
          }}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Text
            fontSize={{
              base: "12px",
              tablet: "16px",
            }}
            textAlign={"start"}
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
          >
            Biaya Kumulatif
          </Text>
          <Text
            fontSize={{
              base: "12px",
              tablet: "16px",
            }}
            textAlign={"start"}
            color={theme.colors.exodus.fontDefault}
            fontWeight={600}
          >
            {context.budgetNasional.biayaKumulatifValueUi}
          </Text>
        </Box>
      </Box>
    </Card>
  );
};

export default BudgetNasionalCard;
