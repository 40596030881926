import { useEffect, useState } from "react";
import ErrorResponse from "../../../models/common/ErrorResponse";
import {
  KlaimSalesListModelData,
  KlaimSalesListModelRecords,
} from "../models/KlaimSalesListModel";
import ApiGetListResponseMetadata from "../../../models/common/ApiGetListResponseMetadata";
import KlaimSalesProvider from "../dataProviders/KlaimSalesProvider";
import { ListKlaimSalesParam } from "../dataProviders/KlaimSalesParamType";
import { KlaimSalesDetailModelData } from "../models/KlaimSalesDetailModel";
import { isEmpty } from "lodash";

const useGetListKlaimSales = ({
  page,
  limit,
  claimUserId,
  outletPanelName,
  outletTargetName,
  status,
  startAt,
  endAt,
  idKlaimSales,
}: ListKlaimSalesParam) => {
  const [klaimSalesListData, setData] = useState<KlaimSalesListModelRecords[]>(
    []
  );
  const [klaimSalesMetadata, setMetadata] =
    useState<ApiGetListResponseMetadata | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorResponse, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    if (page && limit) {
      getListKlaimSales();
    }
  }, [
    page,
    limit,
    claimUserId,
    outletPanelName,
    outletTargetName,
    status,
    JSON.stringify(startAt),
    JSON.stringify(endAt),
    idKlaimSales,
  ]);

  const getListKlaimSales = async () => {
    try {
      setError(null);
      setLoading(true);
      let metadata = null;
      if (!isEmpty(idKlaimSales)) {
        const response: KlaimSalesDetailModelData | undefined =
          await KlaimSalesProvider.getDetailKlaimSales({ id: idKlaimSales! });

        const reassignJSON = {
          id: response?.claimSalesListId,
          status: response?.status,
          user_name: response?.claimUserName,
          claim_outlet_name: response?.outletPanelName,
          claim_outlet_code: response?.outletPanelCode,
          target_outlet_name: response?.outletTujuanName,
          target_outlet_code: response?.outletTujuanCode,
          created_at: response?.unprocessedCreatedAt,
        };
        const dataToListRecord: KlaimSalesListModelRecords =
          new KlaimSalesListModelRecords(reassignJSON);
        setData([dataToListRecord]);
      } else {
        const response: KlaimSalesListModelData | undefined =
          await KlaimSalesProvider.getKlaimSalesList({
            page: page,
            limit: limit,
            claimUserId: claimUserId,
            outletPanelName: outletPanelName,
            outletTargetName: outletTargetName,
            status: status,
            startAt: startAt,
            endAt: endAt,
          });
        setData(response!.records);
        metadata = response!.metadata;
      }

      setMetadata(metadata);
      setLoading(false);
    } catch (error) {
      setError(error as ErrorResponse);
      setLoading(false);
    }
  };

  const refetchGetListKlaimSales = () => {
    getListKlaimSales();
  };

  return {
    refetchGetListKlaimSales,
    klaimSalesListData,
    klaimSalesMetadata,
    loading,
    errorResponse,
  };
};

export default useGetListKlaimSales;
