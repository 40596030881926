import { useMediaQuery, useTheme } from "@chakra-ui/react";
import BiayaMurniDplDpfPageMobile from "./components/mobile/BiayaMurniDplDpfPageMobile";
import BiayaMurniDplDpfPageDesktop from "./components/desktop/BiayaMurniDplDpfPageDesktop";
import { BiayaMurniFilterContextProvider } from "../../contexts/BiayaMurniFilterContext";
import useTitle from "../../../../services/useTitle";
import { BiayaMurniListContextProvider } from "../../contexts/BiayaMurniListContext";

const BiayaMurniDplDpfPage = (): JSX.Element => {
  useTitle("Biaya Murni - DPL/DPF");
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <BiayaMurniFilterContextProvider>
      <BiayaMurniListContextProvider featureType="discount">
        {isDesktopView ? (
          <BiayaMurniDplDpfPageDesktop />
        ) : (
          <BiayaMurniDplDpfPageMobile />
        )}
      </BiayaMurniListContextProvider>
    </BiayaMurniFilterContextProvider>
  );
};

export default BiayaMurniDplDpfPage;
