export enum ProjectEnum {
  Ethical = "ethical",
  Omega = "omega",
  Msa = "msa",
}

export const projectEnumToUi = (project: ProjectEnum | null | undefined) => {
  switch (project) {
    case ProjectEnum.Ethical:
      return "Ethical";
    case ProjectEnum.Msa:
      return "MSA";
    case ProjectEnum.Omega:
      return "Omega";
    default:
      return "Other";
  }
};
