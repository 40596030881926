import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Spinner,
  useTheme,
  Text,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { useContext } from "react";
import InputSalesTargetContext from "../../context/InputSalesTargetContext";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import HorizontalAccordionDetail from "../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import { ListSalesTargetRecords } from "../../../models/SalesTargetModelPack";
import ButtonInputorEditSalesTarget from "../ButtonInputorEditSalesTarget";
import Pagination from "../../../../../../components/Pagination/Pagination";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";

const MobileInputTargetSales = () => {
  const {
    salesTargetData,
    metadata,
    filterValues,
    loadingListSalesTarget,
    saveDataToIndexedDB,
  } = useContext(InputSalesTargetContext);
  const theme = useTheme();

  if (loadingListSalesTarget) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  const generateMonthsName = () => {
    if (filterValues && filterValues.idKuartal) {
      if (filterValues.idKuartal === 1) {
        return ["Januari", "Februari", "Maret"];
      } else if (filterValues.idKuartal === 2) {
        return ["April", "Mei", "Juni"];
      } else if (filterValues.idKuartal === 3) {
        return ["Juli", "Agustus", "September"];
      } else if (filterValues.idKuartal === 4) {
        return ["Oktober", "November", "Desember"];
      }
    }

    return [];
  };

  const handleOnLimitChange = (updatedLimit: number) => {
    saveDataToIndexedDB({
      limit: updatedLimit,
      page: 1,
      idKuartal: filterValues?.idKuartal,
      periodeTahun: filterValues?.periodeTahun,
      periodeKuartalStart: filterValues?.periodeKuartalStart,
      periodeKuartalEnd: filterValues?.periodeKuartalEnd,
      project: filterValues?.project ?? null,
    });
  };

  const handleOnNextPage = () => {
    saveDataToIndexedDB({
      limit: metadata?.totalDataPerPage!,
      page: metadata?.currentPage! + 1,
      idKuartal: filterValues?.idKuartal,
      periodeTahun: filterValues?.periodeTahun,
      periodeKuartalStart: filterValues?.periodeKuartalStart,
      periodeKuartalEnd: filterValues?.periodeKuartalEnd,
      project: filterValues?.project ?? null,
    });
  };

  const handleOnPrevPage = () => {
    saveDataToIndexedDB({
      limit: metadata?.totalDataPerPage!,
      page: metadata?.currentPage! - 1,
      idKuartal: filterValues?.idKuartal,
      periodeTahun: filterValues?.periodeTahun,
      periodeKuartalStart: filterValues?.periodeKuartalStart,
      periodeKuartalEnd: filterValues?.periodeKuartalEnd,
      project: filterValues?.project ?? null,
    });
  };

  const handleOnNavigateFirstPage = () => {
    saveDataToIndexedDB({
      limit: metadata?.totalDataPerPage!,
      page: 1,
      idKuartal: filterValues?.idKuartal,
      periodeTahun: filterValues?.periodeTahun,
      periodeKuartalStart: filterValues?.periodeKuartalStart,
      periodeKuartalEnd: filterValues?.periodeKuartalEnd,
      project: filterValues?.project ?? null,
    });
  };

  return salesTargetData.length > 0 ? (
    <Box paddingTop={"10px"}>
      <Accordion allowToggle>
        {salesTargetData.map((item: ListSalesTargetRecords) => (
          <AccordionItem
            key={item.fieldForceIdentity?.fieldForceId}
            border="none"
            marginBottom="16px"
          >
            <AccordionButton display="flex" justifyContent="space-between">
              <Box textAlign="left">
                <Text
                  fontSize={14}
                  fontWeight="bold"
                  color={theme.colors.exodus.primaryBlue}
                >
                  {item.fieldForceIdentity?.fieldForceNip
                    ? `${item.fieldForceIdentity.fieldForceNip} - `
                    : "Vacant"}{" "}
                  {item.fieldForceIdentity?.fieldForceName}
                </Text>
                <Text fontSize={12}>{item.userZoneName}</Text>
              </Box>
              <Box display={"flex"} justifyContent={"end"}>
                <AccordionIcon />
              </Box>
            </AccordionButton>
            <AccordionPanel marginX="16px">
              {item.project && (
                <HorizontalAccordionDetail
                  size="small"
                  leftText="Project"
                  rightSide={item.project.label}
                  isRowColored
                />
              )}
              <HorizontalAccordionDetail
                leftText={`Target ${generateMonthsName()[0]}`}
                rightSide={`${
                  item.targetSalesList[0]
                    ? CurrencyIdrFormatter.convertToRp(
                        item.targetSalesList[0]?.valueTarget as number
                      )
                    : "-"
                }`}
                size="small"
              />
              <HorizontalAccordionDetail
                leftText={`Target ${generateMonthsName()[1]}`}
                rightSide={`${
                  item.targetSalesList[1]
                    ? CurrencyIdrFormatter.convertToRp(
                        item.targetSalesList[1]?.valueTarget as number
                      )
                    : "-"
                }`}
                size="small"
                isRowColored
              />
              <HorizontalAccordionDetail
                leftText={`Target ${generateMonthsName()[2]}`}
                rightSide={`${
                  item.targetSalesList[2]
                    ? CurrencyIdrFormatter.convertToRp(
                        item.targetSalesList[2]?.valueTarget as number
                      )
                    : "-"
                }`}
                size="small"
              />
              <Box display={"flex"} justifyContent={"end"}>
                <ButtonInputorEditSalesTarget
                  data={item}
                  isButtonDisabled={
                    item.targetSalesList.length > 0 &&
                    item.targetSalesList[0].approvedBy !== null
                  }
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Box paddingTop={2} display={"flex"} justifyContent={"center"}>
        <Pagination
          handleOnNavigateFirstPage={handleOnNavigateFirstPage}
          handleOnLimitChange={handleOnLimitChange}
          handleOnBack={handleOnPrevPage}
          handleOnNext={handleOnNextPage}
          page={filterValues?.page!}
          limit={filterValues?.limit!}
          totalData={metadata?.totalData}
          totalPage={metadata?.totalPage}
          showCurrentPageDetail
          showLimit
          showNavigateFirstPage
        />
      </Box>
    </Box>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default MobileInputTargetSales;
