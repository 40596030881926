import { useFormContext } from "react-hook-form";
import FilterFieldFrame from "../FilterFieldFrame";
import CustomSelect from "../../../../../../../../../../../components/CustomSelect/CustomSelect";
import { RoleNameToZoneType } from "../../../../../../../../../../../utils/RoleNameToZoneType";
import useGetZoneList from "../../../../../../../../../../../services/Zone/useGetZoneList";
import ZoneModel from "../../../../../../../../../../../models/Zone/ZoneModel";
import SalesOutletListFilterModel from "../../../../../../models/SalesOutletListFilterModel";
import { Box, HStack, Spinner, Text, VStack, useTheme } from "@chakra-ui/react";
import ProjectType from "../../../../../../../../../../../models/common/ProjectType";
import { useEffect, useState } from "react";

const FilterByZona = () => {
  const theme = useTheme();

  const formContext = useFormContext<SalesOutletListFilterModel>();
  const watchPekerja = formContext.watch("pekerja");
  const watchJabatan = formContext.watch("jabatan");
  const watchZona = formContext.watch("zona");

  const watchProject = formContext.watch("project");

  const [usedProject, setUsedProject] = useState<ProjectType | null>(null);

  const { zoneData, loading, errorMessage } = useGetZoneList({
    userId: null,
    salesZoneType: RoleNameToZoneType(watchJabatan?.name ?? null),
    project: usedProject,
  });

  useEffect(() => {
    if (watchProject) {
      setUsedProject({ label: "", value: watchProject });
    } else {
      setUsedProject(null);
    }
  }, [watchProject]);

  return (
    <FilterFieldFrame title="Zona">
      <VStack width={"100%"} gap={0} alignItems={"stretch"}>
        <HStack width={"100%"} gap={4}>
          {loading ? (
            <Spinner color={theme.colors.exodus.primaryBlue} />
          ) : (
            <></>
          )}
          <Box width={"100%"}>
            <CustomSelect
              options={zoneData}
              value={watchZona ?? null}
              onChange={(value: ZoneModel | null | undefined) => {
                if (value) {
                  formContext.setValue("zona", value);
                } else {
                  formContext.setValue("zona", undefined);
                }
                formContext.setValue("outlets", undefined);
              }}
              getOptionLabel={(value: ZoneModel) => value.salesZoneName}
              placeholder="Zona"
              getOptionValue={(value) => value.salesZoneId}
              isClearable
              isDisabled={
                watchJabatan === undefined || watchPekerja !== undefined
              }
            />
          </Box>
        </HStack>
        {errorMessage ? (
          <Text
            color={theme.colors.exodus.red}
            textAlign={"left"}
            pl={2}
            fontSize={14}
          >
            {errorMessage}
          </Text>
        ) : (
          <></>
        )}
      </VStack>
    </FilterFieldFrame>
  );
};

export default FilterByZona;
