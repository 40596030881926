import { useMediaQuery, useTheme } from "@chakra-ui/react";
import useTitle from "../../../../../../services/useTitle";
import { SalesOutletDetailContextProvider } from "../contexts/SalesOutletDetailContext";
import { SalesOutletDetailFilterContextProvider } from "../contexts/SalesOutletDetailFilterContext";
import { SalesOutletDetailSummaryContextProvider } from "../contexts/SalesOutletDetailSummaryContext";
import SalesOutletDetailPageDesktop from "./desktop/SalesOutletDetailPageDesktop";
import SalesOutletDetailPageMobile from "./mobile/SalesOutletDetailPageMobile";

const SalesOutletDetail = () => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  useTitle("Sales Outlet Detail");

  return (
    <SalesOutletDetailFilterContextProvider>
      <SalesOutletDetailSummaryContextProvider>
        <SalesOutletDetailContextProvider>
          {isMobile ? (
            <SalesOutletDetailPageMobile />
          ) : (
            <SalesOutletDetailPageDesktop />
          )}
        </SalesOutletDetailContextProvider>
      </SalesOutletDetailSummaryContextProvider>
    </SalesOutletDetailFilterContextProvider>
  );
};

export default SalesOutletDetail;
