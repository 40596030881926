class GetListSalesDistributorDetailModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: GetListSalesDistributorDetailData;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = new GetListSalesDistributorDetailData(json["data"]);
  }
}

class GetListSalesDistributorDetailData {
  salesHeaderId!: number;
  outletBridgingId!: number;
  nomorInvoice!: string;
  salesPeriode!: number;
  distributorId!: number;
  distributorName!: string;
  outletIdDistributor!: string;
  outletId!: number;
  outletName!: string;
  outletCode!: string;
  outletSectorType!: string;
  totalSales!: number;
  totalSalesBridging!: number;
  project!: string;
  detailDistributor!: GetListProducts[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    var temp: GetListProducts[] = [];

    if (
      json["detail_sales_distributor"] !== null &&
      json["detail_sales_distributor"].length !== 0
    ) {
      for (let i = 0; i < json.detail_sales_distributor?.length; i++) {
        temp.push(new GetListProducts(json.detail_sales_distributor[i]));
      }
    }

    this.salesHeaderId = json["sales_header_id"];
    this.outletBridgingId = json["outlet_bridging_id"];
    this.nomorInvoice = json["nomor_invoice"];
    this.salesPeriode = json["sales_period"];
    this.distributorId = json["distributor_id"];
    this.distributorName = json["distributor_name"];
    this.outletIdDistributor = json["outlet_id_distributor"];
    this.outletId = json["outlet_id"];
    this.outletName = json["outlet_name"];
    this.outletCode = json["outlet_code"];
    this.outletSectorType = json["outlet_sector_type"];
    this.totalSales = json["total_sales"];
    this.totalSalesBridging = json["total_sales_bridging"];
    this.project = json["project"];
    this.detailDistributor = temp;
  }
}

class GetListProducts {
  salesDetailId!: number;
  productId!: number;
  productName!: string;
  productCode!: number;
  productNameDistributor!: string;
  productCodeBridging!: string;
  quantity!: number;
  salePrice!: number;
  subTotal!: number;
  diskonPi!: number;
  project!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.salesDetailId = json["sales_detail_id"];
    this.productId = json["product_id"];
    this.productName = json["product_name"];
    this.productCode = json["product_code"];
    this.productNameDistributor = json["product_name_distributor"];
    this.productCodeBridging = json["product_code_distributor"];
    this.quantity = json["quantity"];
    this.salePrice = json["sale_price"];
    this.subTotal = json["subtotal"];
    this.diskonPi = json["diskon_pi"];
    this.project = json["project"];
  }
}

export {
  GetListSalesDistributorDetailModelPack,
  GetListSalesDistributorDetailData,
  GetListProducts,
};
