import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
  useToast,
} from "@chakra-ui/react";
import theme from "../../../layout/theme/theme";
import { Text } from "@chakra-ui/react";
import ClearAllButton from "../../../components/CustomButtons/ClearAllButton";
import JabatanCariNamaInput from "./JabatanCariNamaInput";
import { FormProvider, useForm } from "react-hook-form";
import MonitoringBudgetingContext from "../context/MonitoringBudgetingFilterContext";
import React from "react";
import MonitoringFilterValues from "../models/MonitoringFilterValues";
import DateExodus from "../../../models/DateExodus";
import dayjs from "dayjs";
import PeriodeInput from "./PeriodeInput";
import LoggedUser from "../../../models/common/LoggedUser";
import { isEmpty } from "lodash";
import ProjectAvailableInput from "./projectAvailableInput";
import { ProjectEnum } from "../../../models/common/Project";

type DialogProps = {
  onClose: () => void;
  isOpen: boolean;
  saveData: (filterData: MonitoringFilterValues) => void;
  isDesktopView: boolean;
  userInfo: LoggedUser | null;
};

const FilterDialog = ({
  isOpen,
  onClose,
  saveData,
  isDesktopView,
  userInfo,
}: DialogProps) => {
  const toast = useToast();

  const { filterValues, setFilterValues } = React.useContext(
    MonitoringBudgetingContext
  );

  const methods = useForm({
    defaultValues: {
      jabatan: filterValues?.jabatan,
      bawahan: filterValues?.bawahan,
      projectAvailable: filterValues?.projectAvailable,
      periodeTahun: filterValues?.periodeTahun,
      periodeAwal: filterValues?.periodeAwal,
      periodeAkhir: filterValues?.periodeAkhir,
    },
  });

  React.useEffect(() => {
    if (filterValues) {
      if (userInfo?.userRoleName === "field-force") {
        methods.setValue("jabatan", null);
        methods.setValue("bawahan", null);
        methods.setValue("projectAvailable", filterValues.projectAvailable);
        methods.setValue("periodeTahun", filterValues.periodeTahun);
        methods.setValue("periodeAwal", filterValues.periodeAwal);
        methods.setValue("periodeAkhir", filterValues.periodeAkhir);
      }
      methods.setValue("jabatan", filterValues.jabatan);
      methods.setValue("bawahan", filterValues.bawahan);
      methods.setValue("projectAvailable", filterValues.projectAvailable);
      methods.setValue("periodeTahun", filterValues.periodeTahun);
      methods.setValue("periodeAwal", filterValues.periodeAwal);
      methods.setValue("periodeAkhir", filterValues.periodeAkhir);
    }
  }, [filterValues]);

  const handleOnSubmitFilter = () => {
    const updatedFilter = new MonitoringFilterValues({
      jabatan: methods.getValues("jabatan"),
      bawahan: methods.getValues("bawahan"),
      projectAvailable: methods.getValues("projectAvailable"),
      periodeTahun: methods.getValues("periodeTahun"),
      periodeAwal: methods.getValues("periodeAwal"),
      periodeAkhir: methods.getValues("periodeAkhir"),
    });
    if (
      updatedFilter.periodeAkhir.getEpochDate() <
      updatedFilter.periodeAwal.getEpochDate()
    ) {
      toast({
        title: "Gagal Submit !",
        description: "Filter periode akhir melebihi periode awal!",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } else if (
      (!isEmpty(updatedFilter.bawahan) && isEmpty(updatedFilter.jabatan)) ||
      (isEmpty(updatedFilter.bawahan) && !isEmpty(updatedFilter.jabatan))
    ) {
      toast({
        title: "Gagal Submit !",
        description: "Mohon isi nama bawahan!",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setFilterValues(updatedFilter);
      saveData(updatedFilter); //injek DB
      onClose();
    }
  };

  const handleClearAll = () => {
    methods.setValue("jabatan", null);
    methods.setValue("bawahan", null);
    methods.setValue("projectAvailable", {
      label: "Ethical",
      value: ProjectEnum.Ethical,
    });
    methods.setValue("periodeTahun", new DateExodus());
    methods.setValue(
      "periodeAwal",
      new DateExodus(dayjs().startOf("year").unix())
    );
    methods.setValue(
      "periodeAkhir",
      new DateExodus(dayjs().endOf("year").unix())
    );
    handleOnSubmitFilter();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        autoFocus={false}
        size={isDesktopView ? "xl" : "sm"}
      >
        <FormProvider {...methods}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Center
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                  gap: 10,
                }}
              >
                <Text
                  fontSize="2xl"
                  sx={{ color: theme.colors.exodus.primaryBlue }}
                >
                  Filter Data
                </Text>
                <ClearAllButton onClick={handleClearAll} />
              </Center>
            </ModalHeader>
            <ModalBody>
              <ProjectAvailableInput />
              {userInfo?.userRoleName !== "field-force" && (
                <JabatanCariNamaInput />
              )}
              <PeriodeInput />
            </ModalBody>
            <ModalFooter>
              {isDesktopView ? (
                <Center
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    sx={{ width: "48%" }}
                    colorScheme="buttonSecondary"
                    variant="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{ width: "48%" }}
                    colorScheme="buttonPrimary"
                    onClick={handleOnSubmitFilter}
                  >
                    Submit
                  </Button>
                </Center>
              ) : (
                <Center
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    sx={{ width: "100%" }}
                    colorScheme="buttonPrimary"
                    onClick={handleOnSubmitFilter}
                  >
                    Submit
                  </Button>
                  <Button
                    sx={{ width: "100%", marginTop: "2%" }}
                    colorScheme="buttonSecondary"
                    variant="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Center>
              )}
            </ModalFooter>
          </ModalContent>
        </FormProvider>
      </Modal>
    </>
  );
};

export default FilterDialog;
