import { Box, Stack, Text, useTheme } from "@chakra-ui/react";
import React from "react";

type FilterBoxProps = {
  title: string;
  content: string | number;
};

const FilterBox: React.FC<FilterBoxProps> = ({ title, content }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: "1px",
        borderColor: theme.colors.exodus.secondaryBlue,
        borderRadius: 6,
        paddingY: 2,
        paddingX: 2,
        mr: 1,
        mb: 1,
        width: "fit-content",
      }}
    >
      <Stack spacing={0.5}>
        <Text
          fontWeight="semibold"
          color={theme.colors.exodus.secondaryBlue}
          fontSize={12}
          lineHeight={1}
          sx={{ display: "flex", justifyContent: "start" }}
          textAlign={"left"}
        >
          {title}
        </Text>
        <Text
          fontSize={14}
          lineHeight={1}
          color={theme.colors.exodus.darkGrey}
          textAlign={"left"}
        >
          {content}
        </Text>
      </Stack>
    </Box>
  );
};

export default FilterBox;
