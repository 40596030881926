import { Box, Spinner, Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import React, { useContext } from "react";
import DetailApprovalKlaimSalesContext from "../../contexts/DetailApprovalKlaimSalesContext";
import ErrorPage from "../../../../../components/ErrorPage/ErrorPage";
import DetailApprovalKlaimSalesTitle from "./DetailApprovalKlaimSalesTitle";
import DesktopDetailApprovalKlaimSalesBasicInformation from "./desktop/DesktopDetailApprovalKlaimSalesBasicInformation";
import MobileDetailApprovalKlaimSalesBasicInformation from "./mobile/MobileDetailApprovalKlaimSalesBasicInformation";
import ButtonApproveKlaimSales from "./ButtonApproveKlaimSales";
import DetailApprovalKlaimSalesList from "./DetailApprovalKlaimSalesList";

const DetailApprovalKlaimSalesPage: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const {
    detailKlaimSalesData: record,
    detailKlaimSalesError: error,
    detailKlaimSalesLoading: loading,
  } = useContext(DetailApprovalKlaimSalesContext);

  if (loading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={error.message.toString()} />
      </Box>
    );
  }
  if (!record) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={"Error getting data"} />
      </Box>
    );
  }
  return (
    <Box
      minHeight={isDesktopView ? "100%" : "100vh"}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column">
        <DetailApprovalKlaimSalesTitle />
        {isDesktopView ? (
          <DesktopDetailApprovalKlaimSalesBasicInformation />
        ) : (
          <MobileDetailApprovalKlaimSalesBasicInformation />
        )}
        <DetailApprovalKlaimSalesList />
      </Box>
      {!isDesktopView && (
        <Stack
          spacing={1}
          sx={{
            position: "sticky",
            pt: 2,
            pb: 2,
            bottom: 0,
            backgroundColor: theme.colors.exodus.background,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonApproveKlaimSales />
        </Stack>
      )}
    </Box>
  );
};

export default DetailApprovalKlaimSalesPage;
