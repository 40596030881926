import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { Provider } from "react-redux";
import { ChakraProvider, UseToastOptions } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

import SentryConfig from "./sentry/SentryConfig.ts";
import theme from "./layout/theme/theme.ts";
import store from "./redux/store.ts";
import "./index.css";

const toastDefaultOptions: UseToastOptions = {
  duration: 5000,
  isClosable: true,
  position: "bottom-right",
};

Sentry.init(SentryConfig);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ChakraProvider
          theme={theme}
          toastOptions={{
            defaultOptions: toastDefaultOptions,
          }}
        >
          <App />
        </ChakraProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
