import { Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import ChangePasswordButton from "./ChangePasswordButton";
import LogoutButton from "./LogoutButton";

const ProfileActionButton = ({
  handleOnClickChangePassword,
}: {
  handleOnClickChangePassword: () => void;
}) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: `${isDesktopView ? "flex-end" : "center"}`,
        flexDirection: `${isDesktopView ? "row" : "column"}`,
        backgroundColor: `${theme.colors.exodus.background}`,
      }}
      gap={2}
    >
      <ChangePasswordButton
        handleOnClickChangePassword={handleOnClickChangePassword}
      />
      <LogoutButton />
    </Stack>
  );
};

export default ProfileActionButton;
