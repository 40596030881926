/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import DialogKonfimasiSubmitRencanaKunjungan from "./DialogKonfimasiSubmitRencanaKunjungan";

type Props = {
  openDescriptionDialog: boolean;
  onClose: () => void;
};

const DialogMinimumVisitNotAchieved: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [keterangan, setKeterangan] = useState<string | undefined>(undefined);

  const form = useForm();

  const handleSubmitKeterangan = (value: any) => {
    setKeterangan(value.description);
    setOpenConfirmationDialog(true);
  };

  return (
    <>
      <Modal
        isOpen={props.openDescriptionDialog}
        onClose={props.onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        blockScrollOnMount
        isCentered
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent marginX="40px">
          <ModalHeader
            fontSize={24}
            fontWeight="semibold"
            color={theme.colors.exodus.primaryBlue}
          >
            Input Keterangan
          </ModalHeader>
          <ModalBody>
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(handleSubmitKeterangan)}
                id="desc-form"
              >
                <FormControl isInvalid={!!form.formState.errors.description}>
                  <Textarea
                    {...form.register("description", {
                      required: "Required",
                      validate: (value) => {
                        if (value && value.trim() === "") {
                          return "Required";
                        }
                      },
                    })}
                    placeholder="Input Keterangan..."
                    resize="none"
                  />
                  <FormErrorMessage color={theme.colors.exodus.red}>
                    {form.formState.errors.description &&
                      (form.formState.errors.description.message as string)}
                  </FormErrorMessage>
                </FormControl>
              </form>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            flexDirection={!isDesktopView ? "column" : "row-reverse"}
            gap={!isDesktopView ? 0 : 5}
          >
            <Button
              type="submit"
              form="desc-form"
              width="100%"
              colorScheme="buttonPrimary"
            >
              Submit
            </Button>
            <Button
              onClick={() => {
                form.reset();
                setKeterangan(undefined);
                props.onClose();
              }}
              width="100%"
              variant="outline"
              colorScheme="buttonSecondary"
              marginTop={!isDesktopView ? "12px" : 0}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <DialogKonfimasiSubmitRencanaKunjungan
        keterangan={keterangan}
        openConfirmationDialog={openConfirmationDialog}
        onClose={() => {
          form.reset();
          setKeterangan(undefined);
          setOpenConfirmationDialog(false);
          props.onClose();
        }}
      />
    </>
  );
};

export default DialogMinimumVisitNotAchieved;
