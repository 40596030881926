import { useState } from "react";
import RealisasiKunjunganProvider from "../../dataProviders/RealisasiKunjunganProvider";

const useSaveApprovalRealisasiUserId = () => {
  const [message, setMessage] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);

  const saveData = async (userId: number) => {
    setLoading(true);
    try {
      const saveUserId = {
        userId: userId,
      };

      const result: string =
        await RealisasiKunjunganProvider.saveApprovalRealisasiDetailUserId(
            saveUserId
        );

      setMessage(result);
    } catch (error: unknown) {
      setMessage(error as string);
    } finally {
      setLoading(false);
    }
  };

  return { message, isLoading, saveData };
};

export default useSaveApprovalRealisasiUserId;
