import useTitle from "../../../../services/useTitle";
import MonitorKlaimSalesContext from "../contexts/MonitorKlaimSalesContext";
import useGetListKlaimSales from "../../services/useGetListKlaimSales";
import MonitorKlaimSalesPage from "./MonitorKlaimSalesPage";
import useFilterKlaimSales from "../services/useFilterKlaimSales";
import LoggedUser from "../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../redux/hooks";
import DateExodus from "../../../../models/DateExodus";

const MonitorKlaimSalesBase = () => {
  useTitle("Monitor Klaim Sales");

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const {
    filterValues,
    handleClearFilter,
    errorFilter,
    loadingFilter,
    handleChangePageOrLimit,
    setFilterMonitorKlaimSales,
  } = useFilterKlaimSales();

  const { klaimSalesListData, klaimSalesMetadata, loading, errorResponse } =
    useGetListKlaimSales({
      page: filterValues?.page,
      limit: filterValues?.limit,
      startAt: new DateExodus(filterValues?.startAt),
      endAt: new DateExodus(filterValues?.endAt),
      claimUserId:
        loggedUser?.userRoleName === "nsm"
          ? loggedUser.userId
          : filterValues?.claimUser?.id,
      outletPanelName: filterValues?.outletPanelName?.trim() ?? undefined,
      outletTargetName: filterValues?.outletTargetName?.trim() ?? undefined,
      status: filterValues?.status,
      idKlaimSales: filterValues?.idKlaimSales,
    });

  return (
    <MonitorKlaimSalesContext.Provider
      value={{
        filterValues: filterValues,
        loadingFilter: loadingFilter,
        errorFilter: errorFilter,
        handleChangePageOrLimit: handleChangePageOrLimit,
        handleClearFilter: handleClearFilter,
        setFilterMonitorKlaimSales: setFilterMonitorKlaimSales,
        klaimSalesListData: klaimSalesListData,
        klaimSalesMetadata: klaimSalesMetadata,
        loading: loading,
        errorResponse: errorResponse,
      }}
    >
      <MonitorKlaimSalesPage />
    </MonitorKlaimSalesContext.Provider>
  );
};

export default MonitorKlaimSalesBase;
