import { useCallback, useEffect, useState } from "react";
import { GetListOutletCardParam } from "../../dataProviders/OutletCardProviderParams";
import { GetListOutletCardMetadataAndRecords } from "../../models/GetListOutletCardModel";
import ErrorResponse from "../../../../../models/common/ErrorResponse";
import IsTypeOfErrorResponse from "../../../../../utils/IsTypeOfErrorResponse";
import OutletCardProvider from "../../dataProviders/OutletCardProvider";

const useGetOutletCardList = (params: GetListOutletCardParam) => {
  const [data, setData] = useState<GetListOutletCardMetadataAndRecords | null>(
    null
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const result = await OutletCardProvider.getList(params);

      setData(result ?? null);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  }, [
    params.startDate,
    params.endDate,
    params.page,
    params.limit,
    params.salesZoneId,
    params.salesZoneType,
    params.sector,
    params.userId,
    params.outlet,
    params.project,
  ]);

  useEffect(() => {
    if (params.startDate && params.endDate && params.page && params.limit) {
      getData();
    }
  }, [
    params.startDate,
    params.endDate,
    params.page,
    params.limit,
    params.salesZoneId,
    params.salesZoneType,
    params.sector,
    params.userId,
    params.outlet,
    params.project,
  ]);

  return { data, error, isLoading };
};

export default useGetOutletCardList;
