import { ReactNode, createContext, useContext } from "react";
import { GetListOutletCardModelData } from "../../models/GetListOutletCardModel";
import ErrorResponse from "../../../../../models/common/ErrorResponse";
import useGetCostDetail from "../services/useGetCostDetail";
import DateExodus from "../../../../../models/DateExodus";
import useGetSaldoKontribusi from "../services/useGetSaldoKontribusi";
import { GetSaldoKontribusiModelData } from "../../models/GetSaldoKontribusiModel";
import useExportExcelDetail from "../services/useExportExcelDetail";

/* --------------------------------- Sandbox -------------------------------- */

type ValueType = {
  states: {
    outletCardDetail: GetListOutletCardModelData | null;
    isLoadingGetOutletCardDetail: boolean;
    errorGetOutletCardDetail: ErrorResponse | null;
    saldoKontribusiDetail: GetSaldoKontribusiModelData | null;
    isLoadingSaldoKontribusiDetail: boolean;
    errorSaldoKontribusiDetail: ErrorResponse | null;
    isExportLoading: boolean;
  };
  actions: { exportData: () => void };
};

const OutletCardDetailContext = createContext<ValueType>({
  states: {
    outletCardDetail: null,
    isLoadingGetOutletCardDetail: false,
    errorGetOutletCardDetail: null,
    saldoKontribusiDetail: null,
    isLoadingSaldoKontribusiDetail: false,
    errorSaldoKontribusiDetail: null,
    isExportLoading: false,
  },
  actions: { exportData: () => {} },
});

/* ------------------------------- Components ------------------------------- */
function useOutletCardDetailContext(): ValueType {
  return useContext(OutletCardDetailContext);
}

const OutletCardDetailContextProvider = ({
  children,
  params,
}: {
  children: ReactNode;
  params: { outletId: number; urlStartDate: number; urlEndDate: number };
}): JSX.Element => {
  const {
    data: outletCardDetail,
    isLoading: isLoadingGetOutletCardDetail,
    error: errorGetOutletCardDetail,
  } = useGetCostDetail({
    outletId: params.outletId,
    startDate: new DateExodus(params.urlStartDate),
    endDate: new DateExodus(params.urlEndDate),
  });

  const {
    data: saldoKontribusiDetail,
    isLoading: isLoadingSaldoKontribusiDetail,
    error: errorSaldoKontribusiDetail,
  } = useGetSaldoKontribusi({
    outletId: params.outletId,
    startDate: new DateExodus(params.urlStartDate),
    endDate: new DateExodus(params.urlEndDate),
  });

  const { exportData, isExportLoading } = useExportExcelDetail(
    {
      startDate: new DateExodus(params.urlStartDate),
      endDate: new DateExodus(params.urlEndDate),
    },
    [outletCardDetail!!]
  );

  const value: ValueType = {
    states: {
      outletCardDetail,
      isLoadingGetOutletCardDetail,
      errorGetOutletCardDetail,
      saldoKontribusiDetail,
      isLoadingSaldoKontribusiDetail,
      errorSaldoKontribusiDetail,
      isExportLoading,
    },
    actions: { exportData },
  };

  return (
    <OutletCardDetailContext.Provider value={value}>
      {children}
    </OutletCardDetailContext.Provider>
  );
};

export default OutletCardDetailContextProvider;
export { useOutletCardDetailContext };
