import { useContext, useState } from "react";
import ApprovalRencanaKunjunganDetailContext from "../../context/ApprovalRencanaKunjunganDetailContext";
import { Box, Button, Text, useTheme } from "@chakra-ui/react";
import {
  MdAccessTimeFilled,
  MdCalendarMonth,
  MdInfoOutline,
  MdNearMe,
  MdOutlineWork,
  MdPerson,
  MdReceiptLong,
} from "react-icons/md";
import MobileDialogApprovalRencanaKunjunganDetail from "./MobileDialogApprovalRencanaKunjunganDetail";

const MobileApprovalRencanaKunjunganBasicInformation = () => {
  const theme = useTheme();
  const { detailData } = useContext(ApprovalRencanaKunjunganDetailContext);
  const [openApprovalDetail, setOpenApprovalDetail] = useState<boolean>(false);

  return (
    <Box
      marginTop={"20px"}
      color={theme.colors.exodus.primaryBlue}
      gap={2}
      fontSize={"14px"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        paddingBottom={"5px"}
      >
        <MdPerson />
        <Text paddingLeft={"20px"}>
          {detailData?.userName} ({detailData?.nip})
        </Text>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        paddingBottom={"5px"}
      >
        <MdOutlineWork />
        <Text paddingLeft={"20px"}>{detailData?.roleLabel}</Text>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        paddingBottom={"5px"}
      >
        <MdCalendarMonth />
        <Text paddingLeft={"20px"}>{detailData?.periodeKunjungan}</Text>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        paddingBottom={"5px"}
      >
        <MdAccessTimeFilled />
        <Text paddingLeft={"20px"}>
          {detailData?.createdAt?.getStringDateFormat("D MMMM YYYY HH:mm:ss")}
        </Text>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        paddingBottom={"5px"}
      >
        <MdNearMe />
        <Text paddingLeft={"20px"}>
          {detailData?.totalSeluruhKunjungan} Kali
        </Text>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        paddingBottom={"5px"}
      >
        <MdReceiptLong />
        <Text paddingLeft={"20px"}>
          {detailData?.description === "" || detailData?.description === null
            ? "-"
            : detailData?.description}
        </Text>
      </Box>
      <Box marginTop={"2px"} marginLeft={"-15px"}>
        <Box marginBottom={"15px"} textAlign={"left"}>
          <Button
            leftIcon={<MdInfoOutline />}
            variant={"ghost"}
            sx={{
              fontSize: 14,
              color: theme.colors.exodus.primaryBlue,
            }}
            onClick={() => setOpenApprovalDetail(true)}
          >
            Lihat List Approval
          </Button>
        </Box>
      </Box>
      <MobileDialogApprovalRencanaKunjunganDetail
        openApprovalDetail={openApprovalDetail}
        onClose={() => setOpenApprovalDetail(false)}
      />
    </Box>
  );
};

export default MobileApprovalRencanaKunjunganBasicInformation;
