import { Card, Text, useTheme } from "@chakra-ui/react";
import CurrencyIdrFormatter from "../../../../utils/CurrencyIdrFormatter";
import { useNavigate } from "react-router-dom";

type BiayaMurniGeneralCardProps = {
  title: string;
  total: number;
  url: string;
};

const BiayaMurniGeneralCard = ({
  title,
  total,
  url,
}: BiayaMurniGeneralCardProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/biaya-murni/${url}`);
  };

  return (
    <Card
      minH={210}
      sx={{
        backgroundColor: `${theme.colors.exodus.background}`,
        display: "flex",
        paddingX: 8,
        paddingY: 5,
        cursor: "pointer",
        boxShadow: "0px 7px 24px 4px #282c7a26",
        borderRadius: "16px",
      }}
      onClick={handleOnClick}
    >
      <Text
        fontSize={32}
        fontWeight={700}
        sx={{ mb: 10, wordBreak: "break-word" }}
        textAlign={"left"}
        color={theme.colors.exodus.primaryBlue}
      >
        {title}
      </Text>
      <Text
        textAlign={"left"}
        color={theme.colors.exodus.darkGrey}
        fontSize={20}
        fontWeight={700}
        sx={{ wordBreak: "break-word" }}
      >
        Biaya Total
      </Text>
      <Text
        textAlign={"left"}
        fontSize={28}
        fontWeight={700}
        color={theme.colors.exodus.primaryBlue}
        sx={{ wordBreak: "break-word" }}
      >
        {CurrencyIdrFormatter.convertToRp(total)}
      </Text>
    </Card>
  );
};

export default BiayaMurniGeneralCard;
