import {
  Box,
  FormControl,
  FormErrorMessage,
  Image,
  Input,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import CreateAndUpdateExtraCallBody from "../../models/CreateAndUpdateExtraCallModel";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";

type Props = {
  isGagalKunjungan?: boolean;
  defaultValue?: string;
};

const RealisasiExtraCallImagePickerFormField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const {
    register,
    formState: { errors },
  } = useFormContext<CreateAndUpdateExtraCallBody>();

  const setupFormRequiredValidation = () => {
    if (props.isGagalKunjungan) return false;
    if (!props.isGagalKunjungan && !props.defaultValue) return true;
    return false;
  };

  return (
    <FormControl
      isInvalid={!!errors.photo}
      paddingBottom={isDesktopView ? "20px" : 0}
    >
      <FormInputLabel title="Foto Kunjungan" />
      <Input
        {...register("photo", {
          required: {
            value: setupFormRequiredValidation(),
            message: "Required",
          },
        })}
        type="file"
        accept={"image/jpg, image/jpeg, image/png"}
      />
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.photo && (errors.photo.message as string)}
      </FormErrorMessage>
      {props.defaultValue && (
        <Box paddingTop="10px" paddingBottom={isDesktopView ? "10px" : 0}>
          <Text fontSize={12}>Gambar Sebelumnya</Text>
          <Image alt="prev" width="250px" src={props.defaultValue} />
        </Box>
      )}
    </FormControl>
  );
};

export default RealisasiExtraCallImagePickerFormField;
