import IndexedDatabase from "../../../../database/IndexDB";
import KlaimSalesFilterAndPagination from "../../models/KlaimSalesFilterAndPaginationModel";

const MonitorKlaimSalesProvider = {
  getSavedFilterMonitorKlaimSales: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

    try {
      const data: KlaimSalesFilterAndPagination[] =
        await IndexedDatabase.monitorKlaimSalesFilter.toArray();

      if (!data || data.length === 0) {
        return Promise.resolve(null);
      } else {
        return Promise.resolve(data[0]);
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  setSaveFilterMonitorKlaimSales: async (
    newData: KlaimSalesFilterAndPagination
  ) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

    try {
      const oldData: KlaimSalesFilterAndPagination[] =
        await IndexedDatabase.monitorKlaimSalesFilter.toArray();

      if (!oldData || oldData.length === 0) {
        await IndexedDatabase.monitorKlaimSalesFilter.add(newData);
      } else {
        await IndexedDatabase.monitorKlaimSalesFilter.update(
          oldData[0].id,
          newData
        );
      }
    } catch (error) {
      Promise.reject("Error. Try re-logging in your account");
    }
  },
};

export default MonitorKlaimSalesProvider;
