/* eslint-disable @typescript-eslint/no-explicit-any */
import DateExodus from "../../../../../models/DateExodus";

class RealisasiAndExtraCallModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: RealisasiAndExtraCallModelData;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = new RealisasiAndExtraCallModelData(json["data"]);
  }
}

class RealisasiAndExtraCallModelData {
  id!: number;
  userId!: number;
  userName!: string;
  nip!: string;
  roleLabel!: string;
  approvalPlanId!: number | null;
  approvalPlanStatus!: string;
  approvalPlanTime!: DateExodus | null;
  approvalRealisasiId!: number | null;
  approvalRealisasiStatus!: string;
  approvalRealisasiTime!: DateExodus | null;
  description!: string | null; // Alasan yg diinput user jika jumlah kunjungan dibawah seharusnya
  seniorCommentRealization!: string | null; // Komentar Atasan
  startDate!: DateExodus;
  endDate!: DateExodus;
  detailRealisasi!: RealisasiAndExtraCallDetailRealizationModelData[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: RealisasiAndExtraCallDetailRealizationModelData[] = [];

    if (json["detail_realization"] !== null) {
      for (let i = 0; i < json.detail_realization.length; i++) {
        temp.push(
          new RealisasiAndExtraCallDetailRealizationModelData(
            json.detail_realization[i]
          )
        );
      }
    }

    this.id = json["id"];
    this.userId = json["user_id"];
    this.userName = json["user_name"];
    this.nip = json["user_nip"];
    this.roleLabel = json["role_label"];
    this.approvalPlanId = json["approval_plan_id"];
    this.approvalPlanStatus = json["approval_plan_status"];
    this.approvalPlanTime = json["approval_plan_time"]
      ? DateExodus.fromBackend(json["approval_plan_time"])
      : null;
    this.approvalRealisasiId = json["approval_realization_id"];
    this.approvalRealisasiStatus = json["approval_realization_status"];
    this.approvalRealisasiTime = json["approval_realization_time"]
      ? DateExodus.fromBackend(json["approval_realization_time"])
      : null;
    this.description = json["description"];
    this.seniorCommentRealization = json["senior_comment_realization"];
    this.startDate = DateExodus.fromBackend(json["start_date"]);
    this.endDate = DateExodus.fromBackend(json["end_date"]);
    this.detailRealisasi = temp;
  }
}

class RealisasiAndExtraCallDetailRealizationModelData {
  id!: number;
  date!: DateExodus | null;
  startTime!: DateExodus | null;
  endTime!: DateExodus | null;
  outletId!: number;
  outletName!: string;
  outletCode!: string;
  customerId!: number;
  customerCode!: string;
  customerName!: string;
  spesialis!: string;
  jabatan!: string;
  tujuanKunjungan!: string;
  komentarKunjungan!: string | null;
  photo!: string | null;
  visitType!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.id = json["id"];
    this.date = json["date"] ? DateExodus.fromBackend(json["date"]) : null;
    this.startTime = json["start_time"]
      ? DateExodus.fromBackend(json["start_time"])
      : null;
    this.endTime = json["end_time"]
      ? DateExodus.fromBackend(json["end_time"])
      : null;
    this.outletId = json["outlet_id"];
    this.outletName = json["outlet_name"];
    this.outletCode = json["outlet_code"];
    this.customerId = json["customer_id"];
    this.customerCode = json["customer_code"];
    this.customerName = json["customer_name"];
    this.spesialis = json["customer_special"];
    this.jabatan = json["position_customer"];
    this.tujuanKunjungan = json["purpose_of_visit"];
    this.komentarKunjungan = json["visitor_comment"];
    this.photo = json["photo"];
    this.visitType = json["visit_type"];
  }
}

export {
  RealisasiAndExtraCallModelPack,
  RealisasiAndExtraCallModelData,
  RealisasiAndExtraCallDetailRealizationModelData,
};
