class SalesOutletSavedPaginationMetadata {
  page: number;
  limit: number;

  constructor({ page, limit }: { page: number; limit: number }) {
    this.page = page;
    this.limit = limit;
  }

  public static fromJson(json: any) {
    return new SalesOutletSavedPaginationMetadata({
      page: json.page,
      limit: json.limit,
    });
  }

  toJson() {
    return {
      page: this.page,
      limit: this.limit,
    };
  }
}

export default SalesOutletSavedPaginationMetadata;
