import { useContext, useEffect, useState } from "react";
import MonitorSalesTargetContext from "../../context/MonitorSalesTargetContext";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  HStack,
  Spinner,
  Text,
  useTheme,
} from "@chakra-ui/react";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import {
  ListSalesTargetRecords,
  TargetSalesList,
} from "../../../models/SalesTargetModelPack";
import HorizontalAccordionDetail from "../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";
import Pagination from "../../../../../../components/Pagination/Pagination";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../redux/hooks";
import { ROLE_ID_TRADE_TEAM } from "../../../../../../constants/common/RoleConstants";

const MobileTableMonitorSalesTarget: React.FC = () => {
  const theme = useTheme();
  const [allItemsChecked, setAllItemsChecked] = useState<boolean>(false);
  const [hasCheckableBox, setHasCheckableBox] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
  const [allItemsId, setAllItemsId] = useState<string | null>(null);

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const {
    salesTargetData,
    metadataSalesTarget,
    salesTargetLoading,
    salesTargetError,
    filterValues,
    loadingFilter,
    errorFilter,
    handleChangePageOrLimit,
    selectedSalesTarget,
    setSelectedSalesTarget,
  } = useContext(MonitorSalesTargetContext);

  useEffect(() => {
    // Cek Apakah Ada Data dari BE
    if (salesTargetData.length > 0) {
      const tempInitialBoolean = [];
      const tempAllId = [];

      // Loop untuk mendapatkan jumlah checkbox yang perlu digenerate
      for (let i = 0; i < salesTargetData.length; i++) {
        // Cek apakah Sales Target sudah diinput dan diapprove atau belum.
        // Jika sudah input danbBelum approve, simpan ID sementar di tempAllId untuk kemudian disimpan
        // di state untuk dijadikan value select all
        if (
          salesTargetData[i].targetSalesList.length > 0 &&
          !salesTargetData[i].targetSalesList[0].approvedBy
        ) {
          tempInitialBoolean.push(false);
          for (let j = 0; j < salesTargetData[i].targetSalesList.length; j++) {
            tempAllId.push(salesTargetData[i].targetSalesList[j].targetSalesId);
          }
        } else {
          tempInitialBoolean.push(true);
        }
      }

      setAllItemsId(tempAllId.toString());
      setCheckedItems(tempInitialBoolean);

      if (tempInitialBoolean.includes(false)) {
        setHasCheckableBox(true);
      } else {
        setHasCheckableBox(false);
      }
    }
  }, [salesTargetData, salesTargetData.length]);

  useEffect(() => {
    if (checkedItems.includes(false)) {
      setAllItemsChecked(false);
    } else {
      setAllItemsChecked(true);
    }
  }, [checkedItems]);

  useEffect(() => {
    setSelectedSalesTarget([]);
  }, []);

  const generateMonthsName = () => {
    if (filterValues && filterValues.idKuartal) {
      if (filterValues.idKuartal === 1) {
        return ["Januari", "Februari", "Maret"];
      } else if (filterValues.idKuartal === 2) {
        return ["April", "Mei", "Juni"];
      } else if (filterValues.idKuartal === 3) {
        return ["Juli", "Agustus", "September"];
      } else if (filterValues.idKuartal === 4) {
        return ["Oktober", "November", "Desember"];
      }
    }

    return [];
  };

  const generateValueTargetPerMonth = (
    data: TargetSalesList[],
    monthName: string
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].salesPeriod.getStringDateFormat("MMMM") === monthName) {
        return CurrencyIdrFormatter.convertToRp(Number(data[i].valueTarget));
      }
    }

    return "-";
  };

  const showCheckboxForApprove = (/* data: ListSalesTargetRecords */) => {
    if (!loggedUser || loggedUser.userRoleId !== ROLE_ID_TRADE_TEAM)
      return false;
    // if (data.targetSalesList.length === 0) return false;
    // if (data.targetSalesList.find((item) => item.approvedBy)) return false;
    return true;
  };

  if (salesTargetLoading || loadingFilter) {
    return (
      <Box paddingBottom="15px">
        <Spinner size="lg" color={theme.colors.exodus.primaryBlue} />
      </Box>
    );
  }

  if (errorFilter) {
    return (
      <Box paddingBottom="15px">
        <ErrorPage message={errorFilter} />
      </Box>
    );
  }

  if (salesTargetError) {
    return (
      <Box paddingBottom="15px">
        <ErrorPage message={salesTargetError.message} />
      </Box>
    );
  }

  return salesTargetData.length > 0 ? (
    <Box paddingBottom="15px" textAlign="left">
      {loggedUser && loggedUser.userRoleId === ROLE_ID_TRADE_TEAM && (
        <Checkbox
          value={allItemsId ? allItemsId : "0"}
          isChecked={!hasCheckableBox ? false : allItemsChecked}
          isDisabled={!allItemsId}
          onChange={(e) => {
            setCheckedItems(
              checkedItems.map(() => {
                return e.target.checked;
              })
            );

            if (e.target.checked) {
              const temp: number[] = e.target.value.split(",").map((item) => {
                return parseInt(item);
              });

              setSelectedSalesTarget(temp);
            } else {
              setSelectedSalesTarget([]);
            }
          }}
        >
          <Text paddingLeft="14px">Select All</Text>
        </Checkbox>
      )}

      <Accordion allowToggle>
        {salesTargetData.map((data: ListSalesTargetRecords, index) => (
          <HStack key={index}>
            {showCheckboxForApprove(/*data*/) === true && (
              <Checkbox
                isChecked={
                  data.targetSalesList.length > 0 &&
                  !data.targetSalesList[0].approvedBy
                    ? checkedItems[index]
                    : false
                }
                isDisabled={
                  data.targetSalesList.length === 0 ||
                  (data.targetSalesList.length > 0 &&
                    !!data.targetSalesList[0].approvedBy)
                }
                value={
                  data.targetSalesList.length > 0
                    ? [
                        data.targetSalesList[0]?.targetSalesId ?? "",
                        data.targetSalesList[1]?.targetSalesId ?? "",
                        data.targetSalesList[2]?.targetSalesId ?? "",
                      ].toString()
                    : "0"
                }
                onChange={(e) => {
                  setCheckedItems(
                    checkedItems.map((item: boolean, boolIndex) => {
                      if (index === boolIndex) {
                        return e.target.checked;
                      }
                      return item;
                    })
                  );
                  const idStringToNumber: (number | null)[] = e.target.value
                    .split(",")
                    .map((item) => {
                      if (item !== "") return parseInt(item);
                      return null;
                    })
                    .filter((item) => item !== null);

                  let tempSelectedValue = selectedSalesTarget;

                  for (let i = 0; i < idStringToNumber.length; i++) {
                    if (
                      tempSelectedValue.find(
                        (item) => item === idStringToNumber[i]
                      )
                    ) {
                      tempSelectedValue = tempSelectedValue.filter(
                        (item) => item !== idStringToNumber[i]
                      );
                    } else {
                      tempSelectedValue.push(idStringToNumber[i]!);
                    }
                  }

                  setSelectedSalesTarget([...tempSelectedValue]);
                }}
              />
            )}

            <AccordionItem key={index} width="100%" marginY="8px" border="none">
              <AccordionButton display="flex" justifyContent="space-between">
                <Box textAlign="left">
                  <Text
                    fontSize={14}
                    fontWeight="semibold"
                    color={theme.colors.exodus.primaryBlue}
                  >
                    {data.fieldForceIdentity
                      ? `${data.fieldForceIdentity.fieldForceNip} -
                      ${data.fieldForceIdentity.fieldForceName}`
                      : "Vacant - Vacant"}
                  </Text>
                  <Text fontSize={12}>{data.userZoneName}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel paddingX="16px">
                {data.project && (
                  <HorizontalAccordionDetail
                    size="small"
                    leftText="Project"
                    rightSide={data.project.label}
                    isRowColored
                  />
                )}
                <HorizontalAccordionDetail
                  size="small"
                  leftText="Tanggal Input"
                  rightSide={
                    data.targetSalesList.length > 0
                      ? data.targetSalesList[0].createdAt.getStringDateFormat(
                          "D MMMM YYYY"
                        )
                      : "-"
                  }
                />
                <HorizontalAccordionDetail
                  isRowColored
                  size="small"
                  leftText={`Target ${generateMonthsName()[0]}`}
                  rightSide={generateValueTargetPerMonth(
                    data.targetSalesList,
                    generateMonthsName()[0]
                  )}
                />
                <HorizontalAccordionDetail
                  size="small"
                  leftText={`Target ${generateMonthsName()[1]}`}
                  rightSide={generateValueTargetPerMonth(
                    data.targetSalesList,
                    generateMonthsName()[1]
                  )}
                />
                <HorizontalAccordionDetail
                  isRowColored
                  size="small"
                  leftText={`Target ${generateMonthsName()[2]}`}
                  rightSide={generateValueTargetPerMonth(
                    data.targetSalesList,
                    generateMonthsName()[2]
                  )}
                />
                <HorizontalAccordionDetail
                  size="small"
                  leftText="Diinput Oleh"
                  rightSide={
                    data.targetSalesList.length > 0 &&
                    data.targetSalesList[0].createdBy
                      ? data.targetSalesList[0].createdBy.createdByName
                      : "-"
                  }
                />
                <HorizontalAccordionDetail
                  isRowColored
                  size="small"
                  leftText="Diapprove Oleh"
                  rightSide={
                    data.targetSalesList.length > 0 &&
                    data.targetSalesList[0].approvedBy
                      ? data.targetSalesList[0].approvedBy.approvedByNama
                      : "-"
                  }
                />
              </AccordionPanel>
            </AccordionItem>
          </HStack>
        ))}
      </Accordion>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingTop="16px"
      >
        <Pagination
          handleOnLimitChange={(newLimit) => {
            setSelectedSalesTarget([]);
            handleChangePageOrLimit("limit", newLimit);
          }}
          handleOnBack={() => {
            setSelectedSalesTarget([]);
            handleChangePageOrLimit("prev");
          }}
          handleOnNext={() => {
            setSelectedSalesTarget([]);
            handleChangePageOrLimit("next");
          }}
          page={filterValues?.page}
          limit={filterValues?.limit}
          totalPage={metadataSalesTarget?.totalPage}
          totalData={metadataSalesTarget?.totalData}
          showLimit
          showCurrentPageDetail
          handleOnNavigateFirstPage={() => handleChangePageOrLimit("first")}
        />
      </Box>
    </Box>
  ) : (
    <Box paddingBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default MobileTableMonitorSalesTarget;
