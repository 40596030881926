import {
  Box,
  Divider,
  Icon,
  SimpleGrid,
  Text,
  useTheme,
} from "@chakra-ui/react";
import StackedColumn from "../../../../components/TableData/StackedColumnTable";
import { MdHelpOutline } from "react-icons/md";
import { GetListSalesDistributorDetailData } from "../model/GetListSalesDistributorDetailModelPack.ts";
import { isEmpty, isNil } from "lodash";
import DateExodus from "../../../../models/DateExodus.ts";
import { getOutletSectorType } from "../utils/SalesDistributorUtils.ts";
import CurrencyIdrFormatter from "../../../../utils/CurrencyIdrFormatter.ts";

type Props = {
  onOpenKeteranganSales: () => void;
  dataDetail: GetListSalesDistributorDetailData | null;
};

const MobileSalesDistributorDetailInfo = ({
  dataDetail,
  onOpenKeteranganSales,
}: Props) => {
  const theme = useTheme();
  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} gap={"12px"} mt={"12px"}>
        <StackedColumn
          topValue="Tanggal Faktur"
          bottomValue={
            !isNil(dataDetail?.salesPeriode) && dataDetail.salesPeriode
              ? new DateExodus(dataDetail.salesPeriode).getStringDateFormat(
                  "D MMMM YYYY "
                )
              : "-"
          }
          isTopBoldApplied
          isTopColorPrimaryBlue
        />
        <StackedColumn
          topValue="Nama Outlet"
          bottomValue={
            !isEmpty(dataDetail?.outletName) ? dataDetail!.outletName : "-"
          }
          isTopBoldApplied
          isTopColorPrimaryBlue
        />
      </Box>
      <SimpleGrid columns={2} mt={"12px"} gap={"12px"}>
        <StackedColumn
          topValue="Kode Outlet"
          bottomValue={
            !isEmpty(dataDetail?.outletCode) ? dataDetail!.outletCode : "-"
          }
          isTopBoldApplied
          isTopColorPrimaryBlue
        />
        <StackedColumn
          topValue="Sektor Outlet"
          bottomValue={
            !isEmpty(dataDetail?.outletSectorType)
              ? getOutletSectorType(dataDetail?.outletSectorType)
              : "-"
          }
          isTopBoldApplied
          isTopColorPrimaryBlue
        />
        <StackedColumn
          topValue="Sales Invoice"
          bottomValue={
            !isNil(dataDetail?.totalSales) && dataDetail.totalSales
              ? CurrencyIdrFormatter.convertToRp(dataDetail.totalSales)
              : "-"
          }
          isTopBoldApplied
          isTopColorPrimaryBlue
        />
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <StackedColumn
            topValue="Sales Terdaftar"
            bottomValue={
              !isNil(dataDetail?.totalSalesBridging) &&
              dataDetail.totalSalesBridging
                ? CurrencyIdrFormatter.convertToRp(
                    dataDetail.totalSalesBridging
                  )
                : "-"
            }
            isTopBoldApplied
            isTopColorPrimaryBlue
          />
          <Icon
            as={MdHelpOutline}
            color={theme.colors.exodus.primaryBlue}
            boxSize={"20px"}
            onClick={onOpenKeteranganSales}
          />
        </Box>
      </SimpleGrid>
      <Text
        mt={"12px"}
        textAlign={"end"}
        fontWeight={600}
        color={theme.colors.exodus.primaryBlue}
      >
        Total Produk:{" "}
        {!isEmpty(dataDetail?.detailDistributor)
          ? dataDetail?.detailDistributor.length
          : "-"}
      </Text>
      <Divider />
    </Box>
  );
};

export default MobileSalesDistributorDetailInfo;
