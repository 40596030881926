import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useTheme,
  ModalBody,
  Flex,
  Button,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";
import ApprovalRencanaKunjunganDetailContext from "../../context/ApprovalRencanaKunjunganDetailContext";

type Props = {
  openApprovalDetail: boolean;
  onClose: () => void;
};

const MobileDialogApprovalRencanaKunjunganDetail: React.FC<Props> = ({
  onClose,
  openApprovalDetail,
}) => {
  const theme = useTheme();
  const { detailData } = useContext(ApprovalRencanaKunjunganDetailContext);

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={openApprovalDetail}
      onClose={onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="10px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          <Text>User Approve</Text>
        </ModalHeader>
        <ModalBody>
          <StackedColumn
            isTopBoldApplied
            isTopColorPrimaryBlue
            topValue="Approve Rencana Kunjungan"
            bottomValue={
              detailData?.approvalPlanTime?.getStringDateFormat("D MMMM YYYY")!
            }
          />
          <Flex marginTop="48px" marginBottom="20px">
            <Button
              onClick={onClose}
              width="100%"
              variant="outline"
              colorScheme="buttonSecondary"
            >
              Tutup
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MobileDialogApprovalRencanaKunjunganDetail;
