import { useTheme, Box, Text, Button } from "@chakra-ui/react";
import React, { useContext } from "react";
import DetailApprovalKlaimSalesContext from "../../../contexts/DetailApprovalKlaimSalesContext";

const MobileDetailApprovalKlaimSalesBasicInformation: React.FC = () => {
  const theme = useTheme();

  const { detailKlaimSalesData: record, openPdf } = useContext(
    DetailApprovalKlaimSalesContext
  );

  const MobileStackedBasicInfo: React.FC<{ title: string; value: string }> = (
    props
  ) => {
    return (
      <Box sx={{ textAlign: "left", paddingBottom: 2 }}>
        <Text
          fontWeight={"bold"}
          color={theme.colors.exodus.primaryBlue}
          fontSize={14}
        >
          {props.title}
        </Text>
        <Text fontSize={14}>{props.value}</Text>
      </Box>
    );
  };

  if (!record) return null;
  return (
    <>
      <MobileStackedBasicInfo
        title={"Outlet Panel"}
        value={`${record.outletPanelName} - ${record.outletPanelCode}`}
      />
      <MobileStackedBasicInfo
        title={"Outlet Tujuan"}
        value={`${record.outletTujuanName} - ${record.outletTujuanCode}`}
      />
      <MobileStackedBasicInfo
        title={"Tanggal Pengajuan"}
        value={record.createdAt.getStringDateFormat("D MMMM YYYY")}
      />

      <Box display="flex" gap={1} paddingTop={2} paddingBottom={4}>
        <Text
          fontSize={14}
          fontWeight="bold"
          color={theme.colors.exodus.primaryBlue}
        >
          PDF Bukti :{" "}
        </Text>
        <Button
          colorScheme="buttonPrimary"
          variant="ghost"
          size={"sm"}
          sx={{
            textDecoration: "underline",
            padding: 0,
            fontWeight: "normal",
            height: "21px",
          }}
          onClick={() => openPdf(record.pdfLink)}
          disabled={!record.pdfLink}
        >
          Lihat PDF
        </Button>
      </Box>
    </>
  );
};

export default MobileDetailApprovalKlaimSalesBasicInformation;
