import { OutletModel } from "../../../../../../models/Outlet/OutletModel";

class StoredOutletKlaimSalesModel {
  data: OutletModel | null;

  constructor(json: any) {
    this.data = json;
  }
}

export default StoredOutletKlaimSalesModel;
