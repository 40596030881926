import { useEffect, useState } from "react";
import { RealisasiByHeaderUserIdAllParam } from "../../dataProviders/RealisasiKunjunganParamType";
import RealisasiKunjunganProvider from "../../dataProviders/RealisasiKunjunganProvider";
import { HeaderApprovalRealisasiKunjunganModelData } from "../models/ApprovalRealisasiKunjunganModel";

const useGetRealisasiByHeaderUserIdAll = (
  key: number,
  { header_id, user_id }: RealisasiByHeaderUserIdAllParam
) => {
  const [realisasiHeaderData, setData] =
    useState<HeaderApprovalRealisasiKunjunganModelData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function getData() {
    try {
      setLoading(true);

      const response =
        await RealisasiKunjunganProvider.getRealisasiByHeaderUserIdAll({
          header_id: header_id,
          user_id: user_id,
        });

      setData(response!.data);
      setLoading(false);
    } catch (error: any) {
      setData(null);
      setErrorMessage(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (header_id && user_id) {
      getData();
    }
  }, [header_id, user_id, key]);

  return { realisasiHeaderData, errorMessage, loading };
};

export default useGetRealisasiByHeaderUserIdAll;
