import IndexedDatabase from "../../../../../database/IndexDB";
import SalesTargetFilterAndPaginationModel from "../../models/SalesTargetFilterAndPaginationModel";

const MonitorSalesTargetProvider = {
  getSavedFilterValueMonitorSalesTarget: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

    try {
      const data: SalesTargetFilterAndPaginationModel[] =
        await IndexedDatabase.monitorSalesTargetFilter.toArray();

      if (!data || data.length === 0) {
        return Promise.resolve(null);
      } else {
        return Promise.resolve(data[0]);
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  setSavedFilterValueMonitorSalesTarget: async (
    params: SalesTargetFilterAndPaginationModel
  ) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

    try {
      const oldData: SalesTargetFilterAndPaginationModel[] =
        await IndexedDatabase.monitorSalesTargetFilter.toArray();

      if (!oldData || oldData.length === 0) {
        await IndexedDatabase.monitorSalesTargetFilter.add(params);
      } else {
        await IndexedDatabase.monitorSalesTargetFilter.update(
          oldData[0].id!,
          params
        );
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
};

export default MonitorSalesTargetProvider;
