import {
  AccordionButton,
  AccordionItem,
  Stack,
  useTheme,
  Text,
  AccordionIcon,
  Accordion,
  Icon,
} from "@chakra-ui/react";

import SidebarAccordion from "./SidebarAccordion/SidebarAccordion";
import { MenuListType } from "../models/SidebarAccordionType";
import { MdPostAdd, MdApproval, MdMonitor } from "react-icons/md";
import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";
import verifyPageAccess from "../../../utils/VerifyPageAccess";
import {
  ROUTE_ID_APPROVAL_REALISASI_KUNJUNGAN,
  ROUTE_ID_APPROVAL_RENCANA_KUNJUNGAN,
  ROUTE_ID_INPUT_REALISASI_KUNJUNGAN,
  ROUTE_ID_INPUT_RENCANA_KUNJUNGAN,
  ROUTE_ID_MONITORING_KUNJUNGAN,
} from "../../../constants/common/RouteIdConstants";

const ActivityMenu = ({ onClose }: { onClose: Function }) => {
  const theme = useTheme();

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  if (
    !verifyPageAccess(loggedUser, ROUTE_ID_INPUT_RENCANA_KUNJUNGAN) &&
    !verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_RENCANA_KUNJUNGAN) &&
    !verifyPageAccess(loggedUser, ROUTE_ID_INPUT_REALISASI_KUNJUNGAN) &&
    !verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_REALISASI_KUNJUNGAN) &&
    !verifyPageAccess(loggedUser, ROUTE_ID_MONITORING_KUNJUNGAN)
  )
    return null;

  const ActivityMenuList: MenuListType[] = [];

  if (verifyPageAccess(loggedUser, ROUTE_ID_INPUT_RENCANA_KUNJUNGAN))
    ActivityMenuList.push({
      title: "Rencana Kunjungan",
      pathName: "rencana-kunjungan",
      icon: (
        <Icon
          as={MdPostAdd}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });

  if (verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_RENCANA_KUNJUNGAN))
    ActivityMenuList.push({
      title: "Approval Rencana Kunjungan",
      pathName: "rencana-kunjungan-approval",
      icon: (
        <Icon
          as={MdApproval}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });

  if (verifyPageAccess(loggedUser, ROUTE_ID_INPUT_REALISASI_KUNJUNGAN))
    ActivityMenuList.push({
      title: "Realisasi Kunjungan",
      pathName: "realisasi-kunjungan",
      icon: (
        <Icon
          as={MdPostAdd}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });

  if (verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_REALISASI_KUNJUNGAN))
    ActivityMenuList.push({
      title: "Approval Realisasi Kunjungan",
      pathName: "realisasi-kunjungan-approval",
      icon: (
        <Icon
          as={MdApproval}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });

  if (verifyPageAccess(loggedUser, ROUTE_ID_MONITORING_KUNJUNGAN))
    ActivityMenuList.push({
      title: "Monitoring Kunjungan",
      pathName: "monitor-kunjungan",
      icon: (
        <Icon
          as={MdMonitor}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      <AccordionItem>
        <AccordionButton>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 4,
              }}
            >
              <AccordionIcon />
              <Text
                fontSize={18}
                fontWeight={400}
                sx={{ color: theme.colors.exodus.primaryBlue }}
              >
                Activity
              </Text>
            </Stack>
          </Stack>
        </AccordionButton>
        <SidebarAccordion menuList={ActivityMenuList} onClose={onClose} />
      </AccordionItem>
    </Accordion>
  );
};

export default ActivityMenu;
