/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import PeriodeDataModel from "../models/PeriodeDataModel";
import ActivityGeneralProvider from "../dataProviders/ActivityGeneralProvider";

import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";

const useGetPeriodeByUserId = (key: number) => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );
  const [periodeData, setData] = useState<PeriodeDataModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: PeriodeDataModel[] | undefined =
          await ActivityGeneralProvider.getPeriodeByUserId({
            user_id: loggedUser ? loggedUser.userId : null,
          });

        setData(responseData!);

        setLoading(false);
      } catch (error: any) {
        setData([]);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    if (loggedUser) {
      getData();
    }
  }, [loggedUser, key]);

  return { periodeData, loading, errorMessage };
};

export default useGetPeriodeByUserId;
