import {
  Box,
  Button,
  Spinner,
  Text,
  useMediaQuery,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import ExportExcelButton from "../../../../../../../components/CustomButtons/ExportExcelButton";
import { useSalesOutletPrintContext } from "../../contexts/SalesOutletPrintContext";
import { useEffect } from "react";

const SalesOutletExportExcelButton = (): JSX.Element => {
  const theme = useTheme();
  const toast = useToast();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const {
    states: { error, isLoading },
    actions: { doExportDataToExcel },
  } = useSalesOutletPrintContext();

  useEffect(() => {
    if (error) {
      toast({
        title: "Gagal Mengambil Data Excel",
        description: error.message,
        status: "error",
      });
    }
  }, [error]);

  if (isLoading) {
    return (
      <Box minWidth={200} width={isMobile ? "100%" : undefined}>
        <Button
          width={isMobile ? "100%" : undefined}
          colorScheme="buttonSecondary"
          variant={"outline"}
          isDisabled
        >
          <Spinner color={theme.colors.exodus.primaryBlue} />
          <Box mr={2} />
          <Text>Exporting excel</Text>
        </Button>
      </Box>
    );
  }

  return (
    <Box minWidth={150} width={isMobile ? "100%" : undefined}>
      <ExportExcelButton
        fullWidth
        onClick={() => {
          doExportDataToExcel();
        }}
      />
    </Box>
  );
};

export default SalesOutletExportExcelButton;
