import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  useTheme,
} from "@chakra-ui/react";
import HorizontalAccordionDetail from "../../../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import SalesOutletDetailProductData from "../../../models/SalesOutletDetailProductData";
import CurrencyIdrFormatter from "../../../../../../../../utils/CurrencyIdrFormatter";

const SalesOutletDetailAccordionItem = ({
  data,
  handleChangeAccordion,
}: {
  index: number;
  data: SalesOutletDetailProductData;
  handleChangeAccordion: Function;
}): JSX.Element => {
  const theme = useTheme();

  return (
    <AccordionItem borderColor={theme.colors.exodus.background}>
      <AccordionButton
        px={0}
        onClick={() => {
          handleChangeAccordion();
        }}
      >
        <Box width={"100%"}>
          <Text
            fontSize={14}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
            textAlign={"start"}
          >
            {data.productName}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4} pt={0}>
        <HorizontalAccordionDetail
          leftText={"Sales Value"}
          rightSide={CurrencyIdrFormatter.convertToRp(data.salesValue)}
          size="small"
        />
        <HorizontalAccordionDetail
          leftText={"Estimasi Value"}
          rightSide={CurrencyIdrFormatter.convertToRp(data.estimatedValue)}
          size="small"
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText={"Sales Quantity"}
          rightSide={`${data.salesUnit} pc(s)`}
          size="small"
        />
        <HorizontalAccordionDetail
          leftText={"Estimasi Quantity"}
          rightSide={`${data.estimatedUnit} pc(s)`}
          size="small"
          isRowColored
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default SalesOutletDetailAccordionItem;
