import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import useGenerateSalesTargetQuarterValueList from "../services/useGenerateSalesTargetQuarterValueList";
import SalesTargetQuarterModel from "../models/SalesTargetQuarterModel";
import DateExodus from "../../../../models/DateExodus";
import { useEffect, useState } from "react";

type Props = {
  value: SalesTargetQuarterModel | null;
  year: DateExodus | null;
  onChange: (data: SalesTargetQuarterModel | null) => void;
};

const SalesTargetQuarterSelect: React.FC<Props> = (props) => {
  const { quarterList } = useGenerateSalesTargetQuarterValueList(props.year);

  const [selectedQuarter, setSelectedQuarter] =
    useState<SalesTargetQuarterModel | null>(props.value);

  const [previousYear, setPreviousYear] = useState<number | null>(
    props.year
      ? props.year.getDateObject().getFullYear()
      : new DateExodus().getDateObject().getFullYear()
  );

  useEffect(() => {
    if (!props.value) {
      setSelectedQuarter(quarterList[0]);
      props.onChange(quarterList[0]);
    } else {
      setSelectedQuarter(
        quarterList.find((item) => item.id === props.value!.id)!
      );

      if (
        props.year &&
        props.year.getDateObject().getFullYear() !== previousYear
      ) {
        props.onChange(
          quarterList.find((item) => item.id === props.value!.id)!
        );
        setPreviousYear(props.year.getDateObject().getFullYear());
      }
    }
  }, [quarterList]);

  return (
    <CustomSelect
      options={quarterList}
      onChange={(data) => {
        setSelectedQuarter(data);
        props.onChange(data);
      }}
      value={selectedQuarter}
      getOptionLabel={(data: SalesTargetQuarterModel) => data.label}
      getOptionValue={(data: SalesTargetQuarterModel) => data.id.toString()}
      placeholder={"Pilih Periode"}
      isClearable={false}
      isSearchable={false}
    />
  );
};

export default SalesTargetQuarterSelect;
