import { AxiosResponse } from "axios";
import {
  BiayaMurniGetCostTotalsParam,
  BiayaMurniGetListParam,
  BiayaMurniGetOneDplDpfParam,
  GetOrCodesOfDpParam,
  GetOrCodesOfStandarisasiParam,
} from "./BiayaMurniProviderParams";
import AxiosBaseClient from "../../../dataProviders/config/AxiosBaseClient";
import { ApiGetListBiayaMurniResponse } from "../models/apiResponses/ApiGetListBiayaMurniResponse";
import { COMMON_ERRORS } from "../../../constants/common/ErrorConstants";
import { ApiGetOneDplDpfResponse } from "../models/apiResponses/ApiGetOneDplDpfResponse";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import { ApiGetCostTotalsResponse } from "../models/apiResponses/ApiGetCostTotalsResponse";
import { ApiGetOrCodesOfStandarisasiResponse } from "../models/apiResponses/ApiGetOrCodesOfStandarisasiResponse";
import { ApiGetOrCodesOfDpResponse } from "../models/apiResponses/ApiGetOrCodesOfDpResponse";
import ErrorResponse from "../../../models/common/ErrorResponse";
import IndexedDatabase from "../../../database/IndexDB";
import BiayaMurniFilterModel from "../models/BiayaMurniFilterModel";
import BiayaMurniSavedListMetadata from "../models/BiayaMurniSavedListMetadata";
import { LOCAL_DB_ERROR_RESPONSE } from "../constants/constants";
import DateExodus from "../../../models/DateExodus";
import { BiayaMurniFeatureType } from "../models/BiayaMurniFeatureType";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const BiayaMurniProvider = {
  getList: async (params: BiayaMurniGetListParam) => {
    const url = `/v1/cost/${params.featureType}`;
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          page: params.page,
          limit: params.limit,
          end_date: new DateExodus(
            new DateExodus(params.endDate).getEpochEndOfMonth()
          ).toJSON(),
          start_date: new DateExodus(
            new DateExodus(params.startDate).getEpochStartOfMonth()
          ).toJSON(),
          user_id: params.userId,
          project: params.project,
        },
      });
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        if (response.data.data) {
          const data = new ApiGetListBiayaMurniResponse(
            response.data.data,
            params.featureType
          );
          return Promise.resolve(data);
        }
        return Promise.resolve(null);
      }
      return Promise.reject(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA);
    } catch (error) {
      return handleCatch(error);
    }
  },
  getOneDplDpf: async (params: BiayaMurniGetOneDplDpfParam) => {
    const url = `/v1/cost/discount/detail/${params.outletId}`;

    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          start_date: new DateExodus(
            params.startDate?.getEpochStartOfMonth()
          ).toJSON(),
          end_date: new DateExodus(
            params.endDate?.getEpochEndOfMonth()
          ).toJSON(),
          user_id: params.userId,
        },
      });

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const data: ApiGetOneDplDpfResponse = new ApiGetOneDplDpfResponse(
          response.data
        );
        return Promise.resolve(data);
      } else {
        return Promise.reject(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA);
      }
    } catch (error) {
      return handleCatch(error);
    }
  },
  getCostTotals: async (params: BiayaMurniGetCostTotalsParam) => {
    const url = `/v1/cost`;

    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          start_date: new DateExodus(
            params.startDate.getEpochStartOfMonth()
          ).toJSON(),
          end_date: new DateExodus(
            params.endDate.getEpochEndOfMonth()
          ).toJSON(),
          user_id: params.userId,
          project: params.project,
        },
      });

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const data = new ApiGetCostTotalsResponse(response.data.data);
        return Promise.resolve(data);
      } else {
        return Promise.reject(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA);
      }
    } catch (error) {
      return handleCatch(error);
    }
  },
  getOrCodesOfStandarisasi: async (params: GetOrCodesOfStandarisasiParam) => {
    const url = `/v1/standarisasi/or/${params.id}`;

    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url);

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const data = new ApiGetOrCodesOfStandarisasiResponse(response.data);
        return Promise.resolve(data);
      } else {
        return Promise.reject(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA);
      }
    } catch (error) {
      return handleCatch(error);
    }
  },
  getOrCodesOfDp: async (params: GetOrCodesOfDpParam) => {
    const url = `/v1/down_payment/or-code/${params.id}`;

    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url);

      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        const data = new ApiGetOrCodesOfDpResponse(response.data);
        return Promise.resolve(data);
      } else {
        return Promise.reject(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA);
      }
    } catch (error) {
      return handleCatch(error);
    }
  },
  localDbSaveFilter: async (newData: BiayaMurniFilterModel) => {
    try {
      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

      const oldData: BiayaMurniFilterModel[] | undefined =
        await IndexedDatabase.biayaMurniFilter.toArray();

      if (oldData && oldData.length > 0) {
        IndexedDatabase.biayaMurniFilter.update(oldData[0], newData.toJson());
      } else {
        IndexedDatabase.biayaMurniFilter.add(newData.toJson());
      }

      const oldMetadata: any[] | undefined =
        await IndexedDatabase.biayaMurniListMeta.toArray();

      if (oldMetadata) {
        const newMetadata: any[] = [];
        for (let i = 0; i < oldMetadata.length; i++) {
          let singular = BiayaMurniSavedListMetadata.fromJson(oldMetadata[i]);
          singular.page = 1;
          newMetadata.push(singular.toJson());
        }
        await IndexedDatabase.biayaMurniListMeta.clear();
        await IndexedDatabase.biayaMurniListMeta.bulkAdd(newMetadata);
      }

      return Promise.resolve();
    } catch (error) {
      return handleCatchLocalDb();
    }
  },
  localDbGetFilter: async () => {
    try {
      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

      const oldData: BiayaMurniFilterModel[] | undefined =
        await IndexedDatabase.biayaMurniFilter.toArray();

      if (oldData && oldData.length > 0) {
        return Promise.resolve(BiayaMurniFilterModel.fromJson(oldData[0]));
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      return handleCatchLocalDb();
    }
  },
  localDbSaveListMetadata: async (newData: BiayaMurniSavedListMetadata) => {
    try {
      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

      const oldData: BiayaMurniSavedListMetadata[] | undefined =
        await IndexedDatabase.biayaMurniListMeta
          .where("feature_type")
          .equals(newData.featureType)
          .toArray();

      if (oldData && oldData.length > 0) {
        IndexedDatabase.biayaMurniListMeta.update(oldData[0], newData.toJson());
      } else {
        IndexedDatabase.biayaMurniListMeta.add(newData.toJson());
      }
      return Promise.resolve();
    } catch (error) {
      return handleCatchLocalDb();
    }
  },
  localDbGetListMetadata: async (featureType: BiayaMurniFeatureType) => {
    try {
      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

      const oldData: BiayaMurniSavedListMetadata[] | undefined =
        await IndexedDatabase.biayaMurniListMeta
          .where("feature_type")
          .equals(featureType.toString())
          .toArray();

      if (oldData && oldData.length > 0) {
        return Promise.resolve(
          BiayaMurniSavedListMetadata.fromJson(oldData[0])
        );
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      return handleCatchLocalDb();
    }
  },
};

const handleCatch = (error: any) => {
  if (error.response.data.message) {
    return Promise.reject(`${error.message}. ${error.response.data.message}`);
  }
  const response: ErrorResponse = {
    message: error.response.data.message,
    status: error.response.data.status,
    code: error.response.data.code,
  };
  return Promise.reject(response);
};

const handleCatchLocalDb = () => {
  return Promise.reject(LOCAL_DB_ERROR_RESPONSE);
};

export default BiayaMurniProvider;
