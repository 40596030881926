import { useEffect, useState } from "react";
import SalesTargetFilterAndPaginationModel from "../../models/SalesTargetFilterAndPaginationModel";
import MonitorSalesTargetProvider from "../dataProviders/MonitorSalesTargetProvider";
import DateExodus from "../../../../../models/DateExodus";
import useGenerateSalesTargetQuarterValueList from "../../services/useGenerateSalesTargetQuarterValueList";

const useFilterStateMonitorSalesTarget = () => {
  const [filterValues, setFilterValues] =
    useState<SalesTargetFilterAndPaginationModel | null>(null);
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const [errorFilter, setErrorFilter] = useState<string | null>(null);

  const { quarterList } = useGenerateSalesTargetQuarterValueList();

  const initialValue: SalesTargetFilterAndPaginationModel = {
    page: 1,
    limit: 10,
    project: null,
  };

  useEffect(() => {
    getFilterMonitorSalesTarget();
  }, [quarterList.length]);

  const generateInitialKuartalFilter = () => {
    const month = new DateExodus().getDateObject().getMonth();

    if (month >= 0 && month <= 2) return 0;
    if (month >= 3 && month <= 5) return 1;
    if (month >= 6 && month <= 8) return 2;
    if (month >= 9 && month <= 11) return 3;
    return 0;
  };

  const getFilterMonitorSalesTarget = async () => {
    try {
      setErrorFilter(null);
      setLoadingFilter(true);

      const data: SalesTargetFilterAndPaginationModel | null =
        await MonitorSalesTargetProvider.getSavedFilterValueMonitorSalesTarget();

      // const kuartalData = generateInitialKuartalFilter();

      const index = generateInitialKuartalFilter();

      if (!data) {
        if (quarterList.length > 0) {
          setFilterValues({
            ...initialValue,
            idKuartal: quarterList[index].id,
            labelKuartal: quarterList[index].label,
            periodeKuartalStart:
              quarterList[index].value.start_month.getEpochStartOfMonth(),
            periodeKuartalEnd:
              quarterList[index].value.end_month.getEpochStartOfMonth(),
            periodeTahun:
              quarterList[index].value.start_month.getEpochStartOfMonth(),
          });
        } else {
          setFilterValues(initialValue);
        }
      } else {
        setFilterValues(data);
      }

      setLoadingFilter(false);
    } catch (error) {
      setErrorFilter(error as string);
      setLoadingFilter(false);
    }
  };

  const setFilterMonitorSalesTarget = async (
    data: SalesTargetFilterAndPaginationModel
  ) => {
    try {
      setErrorFilter(null);
      setLoadingFilter(true);

      await MonitorSalesTargetProvider.setSavedFilterValueMonitorSalesTarget(
        data
      );

      setFilterValues(data);
      setLoadingFilter(false);
    } catch (error) {
      setErrorFilter(error as string);
      setLoadingFilter(false);
    }
  };

  const handleClearFilter = async () => {
    const index = generateInitialKuartalFilter();
    const resetFilter = {
      page: 1,
      limit: filterValues!.limit,
      jabatan: null,
      bawahan: null,
      idKuartal: quarterList[index].id,
      labelKuartal: quarterList[index].label,
      periodeKuartalStart:
        quarterList[index].value.start_month.getEpochStartOfMonth(),
      periodeKuartalEnd:
        quarterList[index].value.end_month.getEpochStartOfMonth(),
      periodeTahun: quarterList[index].value.start_month.getEpochStartOfMonth(),
      project: null,
    };
    setFilterValues(resetFilter);
    await MonitorSalesTargetProvider.setSavedFilterValueMonitorSalesTarget(
      resetFilter
    );
  };

  const handleChangePageOrLimit = (
    type: "first" | "next" | "prev" | "limit",
    newLimit?: number
  ) => {
    const oldData = {
      jabatan: filterValues?.jabatan,
      bawahan: filterValues?.bawahan,
      idKuartal: filterValues?.idKuartal,
      labelKuartal: filterValues?.labelKuartal,
      periodeKuartalStart: filterValues?.periodeKuartalStart,
      periodeKuartalEnd: filterValues?.periodeKuartalEnd,
      periodeTahun: filterValues?.periodeTahun,
      project: filterValues ? filterValues.project : null,
    };

    if (type === "first") {
      setFilterMonitorSalesTarget({
        ...oldData,
        page: 1,
        limit: filterValues!.limit!,
      });
    }

    if (type === "next") {
      setFilterMonitorSalesTarget({
        ...oldData,
        page: filterValues!.page + 1,
        limit: filterValues!.limit!,
      });
    }

    if (type === "prev") {
      setFilterMonitorSalesTarget({
        ...oldData,
        page: filterValues!.page - 1,
        limit: filterValues!.limit!,
      });
    }

    if (type === "limit") {
      setFilterMonitorSalesTarget({
        ...oldData,
        page: 1,
        limit: newLimit!,
      });
    }
  };

  return {
    filterValues,
    loadingFilter,
    errorFilter,
    setFilterMonitorSalesTarget,
    handleChangePageOrLimit,
    handleClearFilter,
  };
};

export default useFilterStateMonitorSalesTarget;
