import { Button } from "@chakra-ui/react";
import React from "react";
import { MdVisibility } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import useSaveApprovalRealisasiUserId from "../services/useSaveApprovalRealisasiUserId";

type Props={
  id:number,
  userId: number,
  isDisabled: boolean
}

const LihatDetailButtonApprovalRealisasi: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  
  const { saveData } = useSaveApprovalRealisasiUserId();
  const onClick = () => {

    saveData(props.userId)
    navigate(
      {
        pathname: "/realisasi-kunjungan-approval/" + props.id,
      },
      {
        state: {
          userId: props.userId,
        },
      }
    );
  };
  
  return (
    <Button
      colorScheme="buttonSecondary"
      variant={"ghost"}
      leftIcon={<MdVisibility />}
      onClick={onClick}
      isDisabled={props.isDisabled}
    >
      Lihat Detail
    </Button>
  );
};

export default LihatDetailButtonApprovalRealisasi;
