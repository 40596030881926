import DesktopApprovalRencanaKunjunganToolbar from "./DesktopApprovalRencanaKunjunganToolbar";
import DesktopTableApprovalRencanaKunjungan from "./DesktopTableApprovalRencanaKunjungan";

const DesktopApprovalRencanaKunjungan = () => {
  return (
    <>
      <DesktopApprovalRencanaKunjunganToolbar />
      <DesktopTableApprovalRencanaKunjungan />
    </>
  );
};

export default DesktopApprovalRencanaKunjungan;
