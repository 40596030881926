import DateExodus from "../../../../models/DateExodus";
import { ProjectEnum } from "../../../../models/common/Project";

class BiayaMurniStandarisasiData {
  standarisasiId: number;
  outletId: number;
  outletName: string;
  outletCode: string;
  customerId: number;
  customerName: string;
  customerCode: string;
  startDate: DateExodus;
  endDate: DateExodus;
  biaya: number;
  project: ProjectEnum;

  constructor(json: any) {
    this.standarisasiId = json["id"];
    this.outletId = json["outlet_id"];
    this.outletName = json["outlet_name"];
    this.outletCode = json["outlet_code"];
    this.customerId = json["customer_id"];
    this.customerName = json["customer_name"];
    this.customerCode = json["customer_code"];
    this.startDate = DateExodus.fromBackend(json["start_date"]);
    this.endDate = DateExodus.fromBackend(json["end_date"]);
    this.biaya = json["biaya"];
    this.project = json.project;
  }
}

export { BiayaMurniStandarisasiData };
