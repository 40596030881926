import SectorModel from "../../../../../../models/Sector/SectorModel";
import { SectorTypeJsonConverter } from "../../../utils/utils";

class SalesOutletDetailOutletData {
  outletCode: string;
  outletName: string;
  sector: SectorModel;

  constructor(json: any) {
    this.outletCode = json.outlet_code;
    this.outletName = json.name;
    this.sector = SectorTypeJsonConverter.fromJson(json.sector_type)!!;
  }
}

export default SalesOutletDetailOutletData;
