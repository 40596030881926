import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { ListSalesTargetParams } from "../dataProviders/SalesTargetParamType";
import {
  ListSalesTargetMetadata,
  ListSalesTargetRecords,
} from "../models/SalesTargetModelPack";
import SalesTargetProvider from "../dataProviders/SalesTargetProvider";
import ErrorResponse from "../../../../models/common/ErrorResponse";

const useGetListSalesTarget = ({
  endDate,
  startDate,
  limit,
  page,
  userId,
  project
}: ListSalesTargetParams) => {
  const [salesTargetData, setData] = useState<ListSalesTargetRecords[]>([]);
  const [metadata, setMetadata] = useState<ListSalesTargetMetadata | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorResponse | null>(null);
  useEffect(() => {
    if (!isEmpty(startDate) && !isEmpty(endDate) && page && limit) {
      getSalesTargetData();
    }
  }, [page, limit, JSON.stringify(startDate), JSON.stringify(endDate), userId, project]);

  const getSalesTargetData = async () => {
    try {
      setError(null);
      setLoading(true);

      const response = await SalesTargetProvider.getList({
        startDate,
        endDate,
        page,
        limit,
        userId,
        project
      });

      setData(response?.data || []);
      setMetadata(response?.metadata as ListSalesTargetMetadata);
      setLoading(false);
    } catch (error) {
      if (error as ErrorResponse) {
        setError(error as ErrorResponse);
      }
      setData([]);
      setMetadata(null);
      setLoading(false);
    }
  };

  const refetchSalesTargetData = () => {
    getSalesTargetData();
  };

  return { salesTargetData, metadata, loading, error, refetchSalesTargetData };
};

export default useGetListSalesTarget;
