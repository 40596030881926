import { Grid, GridItem, Text, useTheme, Button } from "@chakra-ui/react";
import React, { useContext } from "react";
import DetailMonitorKlaimSalesContext from "../../contexts/DetailMonitorKlaimSalesContext";
import { isEmpty } from "lodash";
import { CommonStatus } from "../../../../../models/common/Status";

const DesktopDetailMonitorKlaimSalesBasicInformation: React.FC = () => {
  const theme = useTheme();
  const { detailKlaimSalesData, openPDF } = useContext(
    DetailMonitorKlaimSalesContext
  );

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={2} textAlign={"left"}>
      <GridItem
        colSpan={{ base: 12, md: 6 }}
        marginTop="24px"
        marginBottom="32px"
      >
        <Grid templateColumns="repeat(12, 1fr)" gap={2} marginBottom="8px">
          <GridItem colSpan={{ base: 5, md: 3 }}>
            <Text fontWeight="bold" color={theme.colors.exodus.primaryBlue}>
              Outlet Panel
            </Text>
          </GridItem>
          <GridItem colSpan={{ base: 1 }}>
            <Text>:</Text>
          </GridItem>
          <GridItem colSpan={{ base: 6 }}>
            <Text>
              {detailKlaimSalesData?.outletPanelName} -{" "}
              {detailKlaimSalesData?.outletPanelCode}
            </Text>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(12, 1fr)" gap={2} marginBottom="8px">
          <GridItem colSpan={{ base: 5, md: 3 }}>
            <Text fontWeight="bold" color={theme.colors.exodus.primaryBlue}>
              Outlet Tujuan
            </Text>
          </GridItem>
          <GridItem colSpan={{ base: 1 }}>
            <Text>:</Text>
          </GridItem>
          <GridItem colSpan={{ base: 6 }}>
            <Text>
              {detailKlaimSalesData?.outletTujuanName} -{" "}
              {detailKlaimSalesData?.outletTujuanCode}
            </Text>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(12, 1fr)" gap={2} marginBottom="8px">
          <GridItem colSpan={{ base: 5, md: 3 }}>
            <Text fontWeight="bold" color={theme.colors.exodus.primaryBlue}>
              Tanngal Pengajuan
            </Text>
          </GridItem>
          <GridItem colSpan={{ base: 1 }}>
            <Text>:</Text>
          </GridItem>
          <GridItem colSpan={{ base: 6 }}>
            <Text>
              {detailKlaimSalesData?.createdAt.getStringDateFormat(
                "D MMMM YYYY"
              )}
            </Text>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(12, 1fr)" gap={2} marginBottom="8px">
          <GridItem colSpan={{ base: 5, md: 3 }}>
            <Text fontWeight="bold" color={theme.colors.exodus.primaryBlue}>
              PDF Bukti
            </Text>
          </GridItem>
          <GridItem colSpan={{ base: 1 }}>
            <Text>:</Text>
          </GridItem>
          <GridItem colSpan={{ base: 6 }}>
            <Button
              variant="text"
              color={theme.colors.exodus.primaryBlue}
              alignItems={"baseline"}
              justifyContent={"start"}
              marginLeft={"-15px"}
              sx={{
                paddingTop: "3px",
                fontSize: "16px",
                textDecoration: "underline",
                ":hover": { textDecoration: "underline" },
              }}
              onClick={() => openPDF(detailKlaimSalesData!.pdfLink)}
              isDisabled={isEmpty(detailKlaimSalesData?.pdfLink)}
            >
              Lihat PDF
            </Button>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem
        colSpan={{ base: 12, md: 6 }}
        marginTop="24px"
        marginBottom="32px"
      >
        <GridItem colSpan={{ base: 12, md: 4 }}>
          {detailKlaimSalesData?.status !== CommonStatus.Pending && (
            <>
              <Grid
                templateColumns="repeat(12, 1fr)"
                gap={2}
                marginBottom="8px"
              >
                <GridItem colSpan={{ base: 5, md: 3 }}>
                  <Text
                    fontWeight="bold"
                    color={theme.colors.exodus.primaryBlue}
                  >
                    {detailKlaimSalesData?.status === CommonStatus.Rejected
                      ? "Di-reject Oleh"
                      : "Di-approve Oleh"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 1 }}>
                  <Text>:</Text>
                </GridItem>
                <GridItem colSpan={{ base: 6 }}>
                  <Text>{detailKlaimSalesData?.approvedByName}</Text>
                </GridItem>
              </Grid>
            </>
          )}
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 4 }}>
          {detailKlaimSalesData?.status !== CommonStatus.Pending && (
            <>
              <Grid
                templateColumns="repeat(12, 1fr)"
                gap={2}
                marginBottom="8px"
              >
                <GridItem colSpan={{ base: 5, md: 3 }}>
                  <Text
                    fontWeight="bold"
                    color={theme.colors.exodus.primaryBlue}
                  >
                    {detailKlaimSalesData?.status === CommonStatus.Rejected
                      ? "Tanggal Reject"
                      : "Tanggal Approval"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 1 }}>
                  <Text>:</Text>
                </GridItem>
                <GridItem colSpan={{ base: 6 }}>
                  <Text>
                    {detailKlaimSalesData?.approvalAt.getStringDateFormat(
                      "D MMMM YYYY"
                    )}
                  </Text>
                </GridItem>
              </Grid>
              {detailKlaimSalesData?.status === CommonStatus.Rejected && (
                <Grid
                  templateColumns="repeat(12, 1fr)"
                  gap={2}
                  marginBottom="8px"
                >
                  <GridItem colSpan={{ base: 5, md: 3 }}>
                    <Text
                      fontWeight="bold"
                      color={theme.colors.exodus.primaryBlue}
                    >
                      Alasan Reject
                    </Text>
                  </GridItem>
                  <GridItem colSpan={{ base: 1 }}>
                    <Text>:</Text>
                  </GridItem>
                  <GridItem colSpan={{ base: 6 }}>
                    <Text>{detailKlaimSalesData?.keterangan}</Text>
                  </GridItem>
                </Grid>
              )}
            </>
          )}
        </GridItem>
      </GridItem>
    </Grid>
  );
};

export default DesktopDetailMonitorKlaimSalesBasicInformation;
