import { Box, useMediaQuery, useTheme } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import RoleModel from "../../../../../../models/Users/RoleModel";
import useGetRoleByLoggedUser from "../../../../../../services/Users/useGetRoleByLoggedUser";

const FilterByRole = () => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const formContext = useFormContext();
  const watchRole = formContext.watch("role");

  const { roleData } = useGetRoleByLoggedUser();

  return (
    <Box width={isMobile ? "100%" : "40%"}>
      <FormInputLabel title="Jabatan" />
      <CustomSelect
        options={roleData}
        value={watchRole}
        onChange={(eachRole: RoleModel | null) => {
          formContext.setValue("role", eachRole);
          formContext.setValue("zone", null);
          formContext.setValue("bawahan", null);
        }}
        getOptionLabel={(eachRole: RoleModel) => eachRole.label}
        placeholder="Jabatan"
        getOptionValue={(eachRole) => eachRole.label}
        isClearable
      />
    </Box>
  );
};

export default FilterByRole;
