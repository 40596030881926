import { Box, Input, useMediaQuery, useTheme } from "@chakra-ui/react";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";

type Props = {
  title: string;
  value: string;
};

const RealisasiExtraCallForeverDisabledField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Box width="100%" marginBottom={!isDesktopView ? 0 : "20px"}>
      <FormInputLabel title={props.title} />
      <Input
        disabled
        value={props.value}
        _disabled={{
          bgColor: theme.colors.exodus.lightGrey,
          cursor: "not-allowed",
        }}
      />
    </Box>
  );
};

export default RealisasiExtraCallForeverDisabledField;
