import { createContext } from "react";
import { useContext } from "react";
import { EditableFakturSalesData } from "../models/EditableFakturModel";
import usePilihProdukData, {
  PilihProdukAsyncType,
} from "../services/usePilihProdukData";

type _Value = {
  state: {
    fakturList: EditableFakturSalesData[];
    isValidForNextStep: boolean;
    currentEditingFaktur: EditableFakturSalesData | null;
    awaitState: PilihProdukAsyncType;
    removingFakturAttempt: EditableFakturSalesData | null;
  };
  actions: {
    fetchFakturList: () => void;
    storeFakturList: () => void;
    clearFakturList: () => void;
    setEditingFakturId: React.Dispatch<React.SetStateAction<number | null>>;
    removeSingularFaktur: (id: number) => void;
    removeSingularFakturAttempt: (data: EditableFakturSalesData | null) => void;
    saveSingularFakturForm: (newData: EditableFakturSalesData) => void;
  };
};

const _PilihProdukContext = createContext<_Value>({
  state: {
    fakturList: [],
    isValidForNextStep: false,
    currentEditingFaktur: null,
    awaitState: PilihProdukAsyncType.idle,
    removingFakturAttempt: null,
  },
  actions: {
    fetchFakturList: () => {},
    storeFakturList: () => {},
    clearFakturList: () => {},
    setEditingFakturId: () => {},
    removeSingularFaktur: () => {},
    removeSingularFakturAttempt: () => {},
    saveSingularFakturForm: () => {},
  },
});

/**
 * A context looker made for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * Call only under [PilihProdukProvider] component.
 *
 * @returns useContext
 *
 */
function usePilihProduk(): _Value {
  return useContext(_PilihProdukContext);
}

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{}>;

/**
 * A logic provider made for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * Used to provide [usePilihProdukData()]'s hook variables and functions to
 * this component's children. Inside the children component, use
 * [usePilihProduk()] hook to refer here.
 *
 * @param children - this component accept a single JSX.Element child
 * component.
 *
 * @returns JSX.Element
 *
 */
const PilihProdukProvider = ({ children }: _InternalProps): JSX.Element => {
  const {
    fakturList,
    fetchFakturList,
    storeFakturList,
    clearFakturList,
    isValidForNextStep,
    currentEditingFaktur,
    setEditingFakturId,
    awaitState,
    removeSingularFaktur,
    removingFakturAttempt,
    removeSingularFakturAttempt,
    saveSingularFakturForm,
  } = usePilihProdukData();

  const value: _Value = {
    state: {
      fakturList,
      isValidForNextStep,
      currentEditingFaktur,
      awaitState,
      removingFakturAttempt,
    },
    actions: {
      fetchFakturList,
      storeFakturList,
      clearFakturList,
      setEditingFakturId,
      removeSingularFaktur,
      removeSingularFakturAttempt,
      saveSingularFakturForm,
    },
  };

  return (
    <_PilihProdukContext.Provider value={value}>
      {children}
    </_PilihProdukContext.Provider>
  );
};

export { PilihProdukProvider, usePilihProduk };
