import { useMediaQuery, useTheme } from "@chakra-ui/react";
import BiayaMurniStandarisasiPageMobile from "./components/mobile/BiayaMurniStandarisasiPageMobile";
import BiayaMurniStandarisasiPageDesktop from "./components/desktop/BiayaMurniStandarisasiPageDesktop";
import { BiayaMurniFilterContextProvider } from "../../contexts/BiayaMurniFilterContext";
import useTitle from "../../../../services/useTitle";
import { BiayaMurniListContextProvider } from "../../contexts/BiayaMurniListContext";

const BiayaMurniStandarisasiPage = (): JSX.Element => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  useTitle("Biaya Murni - Standarisasi");

  return (
    <BiayaMurniFilterContextProvider>
      <BiayaMurniListContextProvider featureType={"standarisasi"}>
        {isMobile ? (
          <BiayaMurniStandarisasiPageMobile />
        ) : (
          <BiayaMurniStandarisasiPageDesktop />
        )}
      </BiayaMurniListContextProvider>
    </BiayaMurniFilterContextProvider>
  );
};

export default BiayaMurniStandarisasiPage;
