import {
  Stack,
  Button,
  Text,
  useTheme,
  useMediaQuery,
  Icon,
} from "@chakra-ui/react";
import { SingleValue } from "react-select";
import { COMMON_PAGINATION_LIMIT_CHOICES } from "../../constants/components/PaginationConstants";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import CustomSelect from "../CustomSelect/CustomSelect";

type PaginationType = {
  showLimit?: boolean;
  showCurrentPageDetail?: boolean;
  showNavigateFirstPage?: boolean;
  handleOnNavigateFirstPage: () => void;
  handleOnLimitChange?: (value: number) => void;
  handleOnBack?: () => void;
  handleOnNext?: () => void;
  limit?: number;
  page?: number;
  totalPage?: number;
  totalData?: number;
};

type eachPaginationType = {
  value: number;
};
const Pagination = ({
  showLimit = true,
  showCurrentPageDetail = true,
  showNavigateFirstPage = true,
  handleOnNavigateFirstPage = () => {},
  handleOnLimitChange = () => {},
  handleOnBack = () => {},
  handleOnNext = () => {},
  limit = 10,
  page = 1,
  totalPage = 1,
  totalData = 1,
}: PaginationType) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: isDesktopView ? "row" : "column",
        width: "fit-content",
      }}
    >
      {showLimit && (
        <Stack
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Text>Rows Per Page:</Text>
          <CustomSelect
            menuPlacement={"top"}
            options={COMMON_PAGINATION_LIMIT_CHOICES}
            onChange={(
              eachLimit: SingleValue<{
                value: number;
              }>
            ) => {
              handleOnLimitChange(eachLimit?.value!!);
            }}
            getOptionLabel={(eachLimit: eachPaginationType) =>
              `${eachLimit.value}`
            }
            getOptionValue={(eachLimit: eachPaginationType) =>
              `${eachLimit.value}`
            }
            placeholder={null}
            defaultValue={{ value: 10 }}
            value={{ value: limit }}
            isClearable={false}
            isSearchable={false}
          />
        </Stack>
      )}
      {showCurrentPageDetail && (
        <Stack
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Text>
            {page * limit - (limit - 1)}-
            {page === totalPage ? totalData : page * limit}
          </Text>
          <Text> of </Text>
          <Text>{totalData}</Text>
        </Stack>
      )}
      <Stack
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {showNavigateFirstPage && (
          <Button
            colorScheme="buttonPrimary"
            variant="outline"
            sx={{ borderRadius: "8px" }}
            onClick={handleOnNavigateFirstPage}
            isDisabled={page <= 1}
          >
            First
          </Button>
        )}
        <Button
          colorScheme="buttonPrimary"
          variant="outline"
          sx={{ borderRadius: "8px", padding: "0px, 12px" }}
          isDisabled={page <= 1}
          onClick={handleOnBack}
        >
          <Icon as={MdNavigateBefore} boxSize={6} />
        </Button>
        <Text>
          {page} / {totalPage}
        </Text>
        <Button
          colorScheme="buttonPrimary"
          variant="outline"
          sx={{ borderRadius: "8px" }}
          onClick={handleOnNext}
          isDisabled={page === totalPage}
        >
          <Icon as={MdNavigateNext} boxSize={6} />
        </Button>
      </Stack>
    </Stack>
  );
};

export default Pagination;
