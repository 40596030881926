import ProjectType from "../../../../models/common/ProjectType";
import DateExodus from "../../../../models/DateExodus";

/* eslint-disable @typescript-eslint/no-explicit-any */
class MonitorKunjunganModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: MonitorKunjunganModelData[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: MonitorKunjunganModelData[] = [];

    if (json["data"]["Results"] !== null) {
      for (let i = 0; i < json.data.Results.length; i++) {
        temp.push(
          new MonitorKunjunganModelData(json.data.Results[i], json.data)
        );
      }
    }

    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = temp;
  }
}

class MonitorKunjunganModelData {
  outletName!: string;
  outletCode!: string;
  psspNominal!: number;
  customerId!: number;
  customerCode!: string;
  customerName!: string;
  spesialis!: string;
  jabatan!: string;
  startMonth!: DateExodus;
  endMonth!: DateExodus;
  totalRencana!: number;
  totalRealisasi!: number;
  project!: ProjectType | null;
  userData!: MonitorKunjunganUserData;

  constructor(json: any, userData: any) {
    this.mapFromJson(json, userData);
  }

  mapFromJson(json: any, userData: any) {
    this.outletName = json["outlet_name"];
    this.outletCode = json["outlet_code"];
    this.psspNominal = json["pssp_nominal"];
    this.customerId = json["customer_id"];
    this.customerCode = json["customer_code"];
    this.customerName = json["customer_name"];
    this.spesialis = json["specialist"];
    this.jabatan = json["position"];
    this.startMonth = DateExodus.fromBackend(json["start_month"]);
    this.endMonth = DateExodus.fromBackend(json["end_month"]);
    this.totalRencana = json["total_plan"];
    this.totalRealisasi = json["total_realization"];
    this.project = json["project"] ?? null;
    this.userData = new MonitorKunjunganUserData(userData);
  }
}

class MonitorKunjunganUserData {
  userId!: number;
  nip!: string;
  name!: string;
  roleId!: number;
  roleName!: string;
  roleLabel!: string;
  email!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.userId = json["user_id"];
    this.nip = json["nip"];
    this.name = json["name"];
    this.roleId = json["role_id"];
    this.roleName = json["role_name"];
    this.roleLabel = json["role_lable"];
    this.email = json["email"];
  }
}

export { MonitorKunjunganModelPack, MonitorKunjunganModelData };
