/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from "axios";
import AxiosBaseClient from "../../../dataProviders/config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import AllPeriodeDataModel from "../models/AllPeriodeDataModel";
import DateExodus from "../../../models/DateExodus";
import {
  PeriodeByUserIdParam,
  UpdateStatusRencanaKunjunganParam,
} from "./ActivityGeneralParamType";
import PeriodeDataModel from "../models/PeriodeDataModel";
import {
  AllListApprovalParams,
  ListApprovalParams,
} from "./ActivityGeneralParamType";
import { ApprovalKunjunganExcelModelData } from "../models/ApprovalKunjunganExcelModelData";
import { ListApprovalKunjunganModelPack } from "../models/ApprovalListModel";
import TujuanKunjunganDataModel from "../models/TujuanKunjunganDataModel";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const ActivityGeneralProvider = {
  getApprovalList: async (params: ListApprovalParams) => {
    const url = `/v1/header/approval/list?page=${params.page}&limit=${params.limit}`;

    try {
      const listBawahan = [];

      if (params.jabatan && params.userId) {
        listBawahan.push(params.userId);
      }

      const response: AxiosResponse = await AxiosBaseClient.post(
        url,
        {
          start_period: parseInt(JSON.stringify(params.start_period)),
          end_period: parseInt(JSON.stringify(params.end_period)),
          bawahan: listBawahan,
          kunjungan: params.kunjungan,
          status: params.status,
        },
        {
          params: {
            project: params.project?.value,
          },
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new ListApprovalKunjunganModelPack(
            response.data
          );

          if (!responseData.data) {
            return {
              data: [],
              metadata: [],
            };
          }

          return {
            data: responseData.data.records,
            metadata: responseData.data.metadata,
          };
        } else {
          return Promise.reject(response.data.message);
        }
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getListAllApproval: async (param: AllListApprovalParams) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.post(
        "/v1/header/approval/list/all",
        {
          start_period: parseInt(JSON.stringify(param.start_period)),
          end_period: parseInt(JSON.stringify(param.end_period)),
          bawahan: param.bawahan,
          status: param.status,
          kunjungan: param.kunjungan,
        },
        {
          params: {
            project: param.project?.value,
          },
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData: ApprovalKunjunganExcelModelData[] =
            response.data.data.map((json: any) => {
              return {
                id: json["id"],
                userId: json["user_id"],
                userName: json["user_name"],
                authId: json["user_auth"],
                nip: json["user_nip"],
                email: json["user_email"],
                roleId: json["role_parent_id"],
                roleName: json["role_name"],
                roleLabel: json["role_label"],
                approvalPlanId: json["approval_plan_id"],
                approvalPlanStatus: json["approval_plan_status"],
                approvalPlanTime: json["approval_plan_time"]
                  ? DateExodus.fromBackend(json["approval_plan_time"])
                  : null,
                approvalRealisasiId: json["approval_realization_id"],
                approvalRealisasiStatus: json["approval_realization_status"],
                approvalRealisasiTime: json["approval_realization_time"]
                  ? DateExodus.fromBackend(json["approval_realization_time"])
                  : null,
                description: json["description"],
                startDate: DateExodus.fromBackend(json["start_date"]),
                endDate: DateExodus.fromBackend(json["end_date"]),
                periodeKunjungan: `${json["start_date"]} - ${json["end_date"]}`,
                totalRencana: json["total_rencana"],
                totalRealisasi: json["total_realisasi"],
                project: json["project"] ?? null,
              };
            });
          return Promise.resolve(responseData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getAllPeriodeList: async () => {
    try {
      const response: AxiosResponse =
        await AxiosBaseClient.get("/v1/header/period");

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData: AllPeriodeDataModel[] = response.data.data.map(
            (json: any) => {
              return {
                start_period: DateExodus.fromBackend(json["start_period"]),
                end_period: DateExodus.fromBackend(json["end_period"]),
              };
            }
          );

          return Promise.resolve(responseData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getPeriodeByUserId: async (param: PeriodeByUserIdParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `/v1/header/period/${param.user_id}`
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData: PeriodeDataModel[] = response.data.data.map(
            (json: any) => {
              const startPeriod = DateExodus.fromBackend(json["start_period"]);
              const endPeriod = DateExodus.fromBackend(json["end_period"]);

              return {
                header_id: json["header_id"],
                start_period: startPeriod,
                end_period: endPeriod,
                total_visit: json["total_visit"],
                approval_plan_id: json["approval_plan_id"],
                approval_plan_name: json["approval_plan_name"],
                status_plan: json["status_plan"],
                reject_reason: json["reject_reason"],
                approval_realization_id: json["approval_realization_id"],
                approval_realization_name: json["approval_realization_name"],
                status_realization: json["status_realization"],
                realization_is_empty: json["realization_is_empty"],
                realization_reject_reason: json["realization_reject_reason"],
                senior_comment: json["senior_comment"],
              };
            }
          );

          return Promise.resolve(responseData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getTujuanKunjunganList: async () => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/rencana/mastervisit"
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData: TujuanKunjunganDataModel[] =
            response.data.data.map((json: any) => {
              return {
                id: json["ID"],
                visitName: json["visit_name"],
              };
            });

          return Promise.resolve(responseData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  updateStatusRencanaKunjungan: async (
    params: UpdateStatusRencanaKunjunganParam
  ) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.put(
        `/v1/rencana/header/status/${params.header_id}`,
        params.data
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
};

export default ActivityGeneralProvider;
