import ApiGetListResponseMetadata from "../../../../models/common/ApiGetListResponseMetadata";
import { BiayaMurniFeatureType } from "../BiayaMurniFeatureType";
import { BiayaMurniDplDpfData } from "./BiayaMurniDplDpfData";
import { BiayaMurniDpData } from "./BiayaMurniDpData";
import { BiayaMurniEntertainData } from "./BiayaMurniEntertainData";
import { BiayaMurniPsspData } from "./BiayaMurniPsspData";
import { BiayaMurniStandarisasiData } from "./BiayaMurniStandarisasiData";

class ApiGetListBiayaMurniResponse {
  metadata: ApiGetListResponseMetadata;
  featureType: BiayaMurniFeatureType;
  records:
    | BiayaMurniDpData[]
    | BiayaMurniDplDpfData[]
    | BiayaMurniEntertainData[]
    | BiayaMurniPsspData[]
    | BiayaMurniStandarisasiData[];
  hasProject: boolean;

  constructor(
    json: { metadata: any; records: any },
    featureType: BiayaMurniFeatureType
  ) {
    this.metadata = new ApiGetListResponseMetadata(json.metadata);
    this.featureType = featureType;
    this.hasProject = false;

    const tempRecords: any[] = [];
    if (json.records && json.records.length > 0) {
      for (let i = 0; i < json.records.length; i++) {
        // CK: don't worry js switch case is o(1)
        switch (featureType) {
          case "pssp-doctor":
          case "pssp-non-doctor":
            tempRecords.push(new BiayaMurniPsspData(json.records[i]));
            break;
          case "entertain":
            tempRecords.push(new BiayaMurniEntertainData(json.records[i]));
            break;
          case "discount":
            tempRecords.push(new BiayaMurniDplDpfData(json.records[i]));
            break;
          case "standarisasi":
            tempRecords.push(new BiayaMurniStandarisasiData(json.records[i]));
            break;
          case "dp":
            tempRecords.push(new BiayaMurniDpData(json.records[i]));
            break;
        }
      }

      if (Object.hasOwn(json.records[0], "project")) {
        this.hasProject = true;
      }
    }

    this.records = tempRecords;
  }
}

export { ApiGetListBiayaMurniResponse };
