import { useEffect, useState } from "react";
import { EditableFakturSalesData } from "../../models/EditableFakturModel";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Stack,
  Text,
  useMediaQuery,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import {
  formIdCheckboxCounter,
  formIdPrefixCheckbox,
  formIdPrefixTextField,
  notifyMessages,
} from "../../constants/KlaimSalesPilihProdukConstants";
import {
  PilihProdukDetailEnabledProvider,
  usePilihProdukDetail,
} from "../../contexts/PilihProdukDetailEnabledContextProvider";
import { usePilihProduk } from "../../contexts/PilihProdukContextProvider";
import FakturDetailItem from "./FakturDetailItem";
import convertDecimalString from "../../../../../../../utils/decimalStringFormat";
import convertNumberNegativity from "../../../../../../../utils/numberNegativityConverter";

/**
 * Called internally inside this file.
 *
 * @remarks
 * See caller.
 *
 * @returns JSX.Element
 *
 */
const _SelectAll = ({
  data,
}: {
  data: EditableFakturSalesData;
}): JSX.Element => {
  const theme = useTheme();
  const formContext = useFormContext();

  const checkedCounter: number = formContext.watch(formIdCheckboxCounter);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);

  const {
    state: { isEditable },
  } = usePilihProdukDetail();

  useEffect(() => {
    if (checkedCounter === data.newDetail.length) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }
  }, [checkedCounter]);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"flex-start"}
    >
      <Checkbox
        sx={{
          color: theme.colors.exodus.primaryBlue,
          p: 1,
          m: 0,
        }}
        onChange={() => {
          if (isSelectedAll) {
            for (let i = 0; i < data.newDetail.length; i++) {
              formContext.setValue(formIdPrefixCheckbox + i.toString(), false);
            }
            formContext.setValue(formIdCheckboxCounter, 0);
          } else {
            for (let i = 0; i < data.newDetail.length; i++) {
              formContext.setValue(formIdPrefixCheckbox + i.toString(), true);
            }
            formContext.setValue(formIdCheckboxCounter, data.newDetail.length);
          }
        }}
        value={isSelectedAll as any}
        isChecked={isSelectedAll}
        isDisabled={!isEditable}
      />
      <Text
        color={theme.colors.exodus.primaryBlue}
        fontSize={16}
        fontWeight={600}
      >
        Select All
      </Text>
    </Stack>
  );
};

/**
 * Called internally inside this file.
 *
 * @remarks
 * See caller.
 *
 * @returns JSX.Element
 *
 */
const _FakturDetailProdukFormContent = ({
  data,
}: {
  data: EditableFakturSalesData;
}): JSX.Element => {
  const theme = useTheme();
  const formContext = useFormContext();

  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const {
    actions: { setEditingFakturId },
  } = usePilihProduk();

  const {
    actions: { setIsEditable },
    state: { isEditable },
  } = usePilihProdukDetail();

  const [itemComponents, setItemComponents] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const components: JSX.Element[] = [];
    let checkedCounter: number = 0;
    for (let i = 0; i < data.newDetail.length; i++) {
      let isEven: boolean = false;
      if (i % 2 === 0) {
        isEven = true;
      }
      components.push(
        <FakturDetailItem
          isEven={isEven}
          data={data.newDetail[i]}
          itemIdx={i}
          key={i}
        />
      );
      if (data.newDetail[i].isSelected) {
        checkedCounter += 1;
      }
    }
    formContext.setValue(formIdCheckboxCounter, checkedCounter);
    setItemComponents(components);
  }, []);

  return (
    <Stack direction="column" width="100%" height="100%">
      <Stack
        direction={isDesktopView ? "row" : "column"}
        width="100%"
        justifyContent={isDesktopView ? "space-between" : "flex-start"}
        alignItems="flex-start"
        px={isDesktopView ? "20px" : "16px"}
      >
        <Stack direction="column" width="100%" alignItems="flex-start">
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontSize={20}
            fontWeight="semibold"
          >
            {data.originalData.invoice}
          </Text>
          <Stack direction={"row"} gap={1}>
            <Text
              color={theme.colors.exodus.primaryBlue}
              fontSize={16}
              fontWeight="semibold"
            >
              Distributor:
            </Text>
            <Box flex={1}>
              <Text
                fontSize={16}
                fontWeight="semibold"
                color={theme.colors.exodus.fontDefault}
              >
                {data.originalData.distributorName}
              </Text>
            </Box>
          </Stack>
        </Stack>
        <Box sx={{ width: isDesktopView ? "150px" : "100%" }}>
          <Box
            sx={{
              float: "right",
            }}
          >
            <_SelectAll data={data} />
          </Box>
        </Box>
      </Stack>
      {isDesktopView && (
        <Stack direction="row" mt="16px" textAlign="left">
          <Text
            flex={2}
            color={theme.colors.exodus.primaryBlue}
            fontSize={20}
            fontWeight="semibold"
            px="20px"
          >
            List Produk
          </Text>
          <Text
            flex={1}
            color={theme.colors.exodus.primaryBlue}
            fontSize={20}
            fontWeight="semibold"
            px="20px"
          >
            Quantity
          </Text>
        </Stack>
      )}
      <Box height="100%" sx={{ position: "relative" }}>
        <Stack
          height="100%"
          width="100%"
          sx={{ overflowY: "auto", position: "absolute" }}
        >
          {itemComponents}
        </Stack>
      </Box>
      <Stack
        direction={isDesktopView ? "row" : "column-reverse"}
        px="16px"
        pt="16px"
        gap={2}
        width="100%"
      >
        <Button
          width="100%"
          variant="outline"
          colorScheme="buttonSecondary"
          onClick={() => {
            setEditingFakturId(null);
          }}
        >
          {isEditable ? "Cancel" : "Tutup"}
        </Button>
        {isEditable && (
          <Button width="100%" colorScheme="buttonPrimary" type="submit">
            Save
          </Button>
        )}
        {!isEditable && (
          <Button
            width="100%"
            colorScheme="buttonPrimary"
            onClick={() => {
              setIsEditable(true);
            }}
          >
            Edit
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

/**
 * A component made for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * Used when a "faktur" from [usePilihProduk()] is selected to view its detail.
 * This component offers [form] to view and/or edit its detail data. From the
 * business side, this view is used to select which items included in claim,
 * and each of the item's claimed quantity.
 *
 * @returns JSX.Element
 *
 */
const FakturDetailProduk = (): JSX.Element => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const methods = useForm({
    mode: "onChange",
  });

  const {
    state: { currentEditingFaktur },
    actions: { saveSingularFakturForm },
  } = usePilihProduk();

  const toast = useToast();

  const onSubmit = () => {
    if (methods.getValues(formIdCheckboxCounter) === 0) {
      toast({
        title: notifyMessages.warning.noSelectedItem,
        status: "warning",
      });
      return;
    }
    if (currentEditingFaktur !== null) {
      const record = currentEditingFaktur;
      const data: EditableFakturSalesData = record;
      for (let i = 0; i < data.newDetail.length; i++) {
        data.newDetail[i].isSelected = methods.getValues(
          formIdPrefixCheckbox + i.toString()
        );

        const qtyVal = +convertDecimalString.commaToPoint(
          methods.getValues(formIdPrefixTextField + i.toString())
        );
        if (data.newDetail[i].originalData.qty < 0) {
          data.newDetail[i].selectedQty = convertNumberNegativity(qtyVal);
        } else {
          data.newDetail[i].selectedQty = qtyVal;
        }
      }
      data.isValid = true;
      saveSingularFakturForm(data);
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        borderRadius: "8px",
        py: isDesktopView ? "20px" : "16px",
        bgColor: theme.colors.exodus.background,
        boxShadow: "0px 7px 24px 4px #282c7a26",
        color: theme.colors.exodus.fontDefault,
      }}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{ width: "100%", height: "100%" }}
        >
          {currentEditingFaktur === null ? (
            <></>
          ) : (
            <PilihProdukDetailEnabledProvider
              initialValue={!currentEditingFaktur.isValid}
            >
              <_FakturDetailProdukFormContent data={currentEditingFaktur} />
            </PilihProdukDetailEnabledProvider>
          )}
        </form>
      </FormProvider>
    </Card>
  );
};

export default FakturDetailProduk;
