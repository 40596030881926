import { createContext, useContext } from "react";
import useGetPrint from "../services/dataFetching/useGetPrint";
import ErrorResponse from "../../../../../../models/common/ErrorResponse";
import { useSalesOutletListContext } from "./SalesOutletListContext";

type _Value = {
  states: {
    isLoading: boolean;
    error: ErrorResponse | null;
  };
  actions: { doExportDataToExcel: Function };
};

const SalesOutletPrintContext = createContext<_Value>({
  states: {
    isLoading: false,
    error: null,
  },
  actions: { doExportDataToExcel: () => {} },
});

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{}>;

function useSalesOutletPrintContext(): _Value {
  return useContext(SalesOutletPrintContext);
}

const SalesOutletPrintContextProvider = ({
  children,
}: _InternalProps): JSX.Element => {
  const {
    states: { filterValues },
  } = useSalesOutletListContext();

  const { isLoading, error, getData } = useGetPrint(filterValues);

  const doExportDataToExcel = async () => {
    await getData();
  };

  const value: _Value = {
    states: { isLoading, error },
    actions: { doExportDataToExcel },
  };

  return (
    <SalesOutletPrintContext.Provider value={value}>
      {children}
    </SalesOutletPrintContext.Provider>
  );
};

export { SalesOutletPrintContextProvider, useSalesOutletPrintContext };
