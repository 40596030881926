import DateExodus from "../../../models/DateExodus";
import MonitoringFilterValues from "../models/MonitoringFilterValues";
import { ProjectEnum } from "../../../models/common/Project";

const filterInitialValue: MonitoringFilterValues = new MonitoringFilterValues({
  jabatan: null,
  bawahan: null,
  projectAvailable: {
    label: "Ethical",
    value: ProjectEnum.Ethical,
  },
  periodeTahun: new DateExodus(),
  periodeAwal: new DateExodus().getEpochStartOfYear(),
  periodeAkhir: new DateExodus().getEpochEndOfYear(),
});

export default filterInitialValue;
