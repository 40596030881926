import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Center,
  Button,
  ModalBody,
  Stack,
  ModalFooter,
  useMediaQuery,
  useTheme,
  Text,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { SalesProductFilterType } from "../../models/SalesProductFilterType";
import DateExodus from "../../../../../models/DateExodus";
import React from "react";
import { useSalesProductContext } from "../../contexts/SalesProductContextProvider";
import FilterByPeriode from "./FilterByPeriod";
import dayjs from "dayjs";
import FilterBySector from "./FilterBySector";
import FilterByProducts from "./FilterByProducts";
import { ROLE_ID_FF } from "../../../../../constants/common/RoleConstants";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";
import FilterByRole from "./FilterByRole";
import FilterByBawahan from "./FilterByBawahan";
import FilterByZone from "./FilterByZone";
import ClearAllButton from "../../../../../components/CustomButtons/ClearAllButton";
import FilterByProject from "./FilterByProject";

const FilterModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state?.authReducer.loggedUser
  );

  const {
    states: { filterValues },
    actions: { saveFilter },
  } = useSalesProductContext();

  const methods = useForm<SalesProductFilterType>({
    defaultValues: {
      startPeriod: filterValues?.startPeriod,
      endPeriod: filterValues?.endPeriod,
      sector: filterValues?.sector ?? null,
      zone: filterValues?.zone ?? null,
      bawahan: filterValues?.bawahan ?? null,
      productList: filterValues?.productList ?? null,
      project: filterValues?.project,
    },
  });

  React.useEffect(() => {
    if (filterValues) {
      methods.setValue("sector", filterValues.sector);
      methods.setValue("role", filterValues.role);
      methods.setValue("zone", filterValues.zone);
      methods.setValue("bawahan", filterValues.bawahan);
      methods.setValue("startPeriod", filterValues.startPeriod);
      methods.setValue("endPeriod", filterValues.endPeriod);
      methods.setValue("productList", filterValues.productList);
      methods.setValue("project", filterValues.project);
    }
  }, [filterValues]);

  const handleOnClearAll = () => {
    methods.setValue("sector", null);
    methods.setValue("role", null);
    methods.setValue("zone", null);
    methods.setValue("bawahan", null);
    methods.setValue(
      "startPeriod",
      new DateExodus(dayjs().startOf("month").unix())
    );
    methods.setValue(
      "endPeriod",
      new DateExodus(dayjs().endOf("month").unix())
    );
    methods.setValue("productList", null);

    methods.setValue("project", null);

    handleOnSubmitFilter();
  };

  const handleOnSubmitFilter = () => {
    const updatedFilter: SalesProductFilterType = {
      startPeriod: methods.getValues("startPeriod"),
      endPeriod: methods.getValues("endPeriod"),
      sector: methods.getValues("sector"),
      zone: methods.getValues("zone"),
      bawahan: methods.getValues("bawahan"),
      productList: methods.getValues("productList"),
      role: methods.getValues("role"),
      page: 1,
      limit: filterValues?.limit ?? 10,
      project: methods.getValues("project"),
    };
    saveFilter(updatedFilter);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      autoFocus={false}
      size={isDesktopView ? "3xl" : "sm"}
    >
      <FormProvider {...methods}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center
              sx={{
                justifyContent: "space-between",
                display: "flex",
                gap: 10,
              }}
            >
              <Text
                sx={{ color: theme.colors.exodus.primaryBlue, fontSize: 24 }}
              >
                Filter
              </Text>
              <ClearAllButton onClick={handleOnClearAll} />
            </Center>
          </ModalHeader>
          <ModalBody>
            <Stack
              sx={{
                justifyContent: "space-between",
                display: "flex",
                gap: 2,
              }}
            >
              <FilterByProject />
              <FilterByProducts />
              {loggedUser?.userRoleId !== ROLE_ID_FF && (
                <>
                  <Stack
                    gap={isMobile ? 0 : 4}
                    direction={isMobile ? "column" : "row"}
                  >
                    <FilterByRole />
                    <FilterByBawahan />
                  </Stack>
                  <FilterByZone />
                </>
              )}
              <FilterBySector />
              <FilterByPeriode />
            </Stack>
          </ModalBody>
          <ModalFooter
            gap={4}
            flexDirection={!isDesktopView ? "column-reverse" : undefined}
          >
            <Button
              colorScheme="blue"
              onClick={onClose}
              sx={{ width: "100% " }}
              variant={"outline"}
            >
              Close
            </Button>
            <Button
              colorScheme={`buttonPrimary`}
              sx={{ width: "100%" }}
              onClick={handleOnSubmitFilter}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};

export default FilterModal;
