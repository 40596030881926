import { useEffect, useState } from "react";
import SalesDistributorFilterValue from "../model/SalesDistributorFilterValues";
import { isEmpty } from "lodash";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import SalesDistributorDataProvider from "../dataProvider/SalesDistributorDataProvider";
import { GetListSalesDistributorData } from "../model/GetSalesDistributorModelPack";

const useGetSalesDistributor = (
  filterValues: SalesDistributorFilterValue | null,
  pageState: number,
  limitState: number
) => {
  const [distributorList, setDistributorList] = useState<
    GetListSalesDistributorData | null | undefined
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    if (!isEmpty(filterValues)) {
      fetch();
    }
  }, [filterValues, pageState, limitState]);

  const fetch = async () => {
    setLoading(true);

    var bridgingType;

    if (filterValues?.bridgingType === "1") {
      bridgingType = undefined;
    }
    if (filterValues?.bridgingType === "2") {
      bridgingType = true;
    }
    if (filterValues?.bridgingType === "3") {
      bridgingType = false;
    }

    const payload = {
      start_date: filterValues?.periodeAwal.getEpochDate(),
      end_date: filterValues?.periodeAkhir.getEpochDate(),
      page: pageState,
      limit: limitState,
      is_bridge: bridgingType,
      sector: filterValues?.sector?.value,
      distributor_id: filterValues?.namaDistributor?.id,
      project: filterValues?.projectAvailable?.value,
      outlet_id: filterValues?.namaOutlet?.outletId,
      no_invoice: filterValues?.nomorFaktur,
    };

    try {
      setError(null);
      const data = await SalesDistributorDataProvider.getList(payload);
      setDistributorList(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(UNKNOWN_ERROR_RESPONSE);
    }
  };

  return { distributorList, loading, error };
};

export default useGetSalesDistributor;
