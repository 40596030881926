import { createContext, useContext } from "react";
import useGetOneSummary from "../services/dataFetchings/useGetOneSummary";
import { useSalesOutletDetailFilterContext } from "./SalesOutletDetailFilterContext";
import ErrorResponse from "../../../../../../models/common/ErrorResponse";
import ApiGetSummarySalesOutletResponse from "../../list/models/apiResponses/ApiGetSummarySalesOutletResponse";

type _Value = {
  states: {
    data: ApiGetSummarySalesOutletResponse | null;
    error: ErrorResponse | null;
    isLoading: boolean;
  };
};

const SalesOutletDetailSummaryContext = createContext<_Value>({
  states: {
    data: null,
    error: null,
    isLoading: false,
  },
});

function useSalesOutletDetailSummaryContext(): _Value {
  return useContext(SalesOutletDetailSummaryContext);
}

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{}>;

const SalesOutletDetailSummaryContextProvider = ({
  children,
}: _InternalProps) => {
  const {
    states: { filterValues },
  } = useSalesOutletDetailFilterContext();
  const { data, error, isLoading } = useGetOneSummary({
    project: filterValues?.project,
    outletId: filterValues?.outletId,
    endDate: filterValues?.periode.akhir,
    startDate: filterValues?.periode.awal,
    userId: filterValues?.userId,
  });

  const value: _Value = {
    states: { data, error, isLoading },
  };
  return (
    <SalesOutletDetailSummaryContext.Provider value={value}>
      {children}
    </SalesOutletDetailSummaryContext.Provider>
  );
};

export {
  SalesOutletDetailSummaryContextProvider,
  useSalesOutletDetailSummaryContext,
};
