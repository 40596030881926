import { Box, Spinner, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import { useContext } from "react";
import DetailMonitorKlaimSalesContext from "../contexts/DetailMonitorKlaimSalesContext";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import StatusChip from "../../../../components/StatusChip/StatusChip";
import DesktopDetailMonitorKlaimSalesBasicInformation from "./desktop/DesktopDetailMonitorKlaimSalesBasicInformation";
import ErrorPage from "../../../../components/ErrorPage/ErrorPage";
import MobileDetailMonitorKlaimSalesBasicInformation from "./mobile/MobileDetailMonitorKlaimSalesBasicInformation";
import { getStatusColor } from "../../utils/getStatusColor";
import DetailMonitorKlaimSalesCardBase from "./DetailMonitorKlaimSalesCardBase";

const DetailMonitorKlaimSalesPage = () => {
  const { detailKlaimSalesData, loading, errorResponse } = useContext(
    DetailMonitorKlaimSalesContext
  );

  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  if (loading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (errorResponse) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={errorResponse.message.toString()} />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        textAlign={"left"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={isDesktopView ? "" : "space-between"}
      >
        <Box>
          <PageTitle label={detailKlaimSalesData!.claimUserName} />
          <Text
            color={theme.colors.exodus.darkGrey}
            fontSize={isDesktopView ? "24px" : "16px"}
            fontWeight={600}
          >
            CLAIM-{detailKlaimSalesData?.claimSalesListId}
          </Text>
        </Box>
        <Box paddingTop={isDesktopView ? "20px" : "70px"} paddingLeft={"25px"}>
          <StatusChip
            status={getStatusColor(detailKlaimSalesData!.status)}
            text={detailKlaimSalesData!.status.toUpperCase()}
          />
        </Box>
      </Box>
      <Box>
        {isDesktopView ? (
          <DesktopDetailMonitorKlaimSalesBasicInformation />
        ) : (
          <MobileDetailMonitorKlaimSalesBasicInformation />
        )}
      </Box>
      <DetailMonitorKlaimSalesCardBase />
    </Box>
  );
};

export default DetailMonitorKlaimSalesPage;
