export const RoleNameToZoneType = (
  roleName: string | null
): "group_territories" | "areas" | "regions" | "districts" | null => {
  if (roleName === "nsm") {
    return "districts";
  }
  if (roleName === "sm") {
    return "regions";
  }
  if (roleName === "asm") {
    return "areas";
  }
  if (roleName === "field-force") {
    return "group_territories";
  }
  return null;
};
