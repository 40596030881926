import { useState, useEffect } from "react";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { SalesProductProvider } from "../dataProviders/SalesProductProvider";
import { SalesProductFilterType } from "../models/SalesProductFilterType";
import DateExodus from "../../../../models/DateExodus";
import dayjs from "dayjs";
import IsTypeOfErrorResponse from "../../../../utils/IsTypeOfErrorResponse";

const filterInitialValue: SalesProductFilterType = {
  startPeriod: new DateExodus(dayjs().startOf("month").unix()),
  endPeriod: new DateExodus(dayjs().endOf("month").unix()),
  sector: null,
  zone: null,
  bawahan: null,
  productList: null,
  role: null,
  page: 1,
  limit: 10,
  project: null,
};

const useSalesProductFilter = () => {
  const [filterValues, setFilterValues] =
    useState<SalesProductFilterType | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    const initializeFilter = async () => {
      try {
        setLoading(true);
        setError(null);

        const databaseResponse: SalesProductFilterType | null | undefined =
          await SalesProductProvider.getIndexedDbFilter();

        if (databaseResponse) {
          setFilterValues(databaseResponse);
        } else {
          setFilterValues(filterInitialValue);
        }
      } catch (error) {
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setLoading(false);
      }
    };

    initializeFilter();
  }, []);

  const saveFilter = async (updatedFilterValues: SalesProductFilterType) => {
    try {
      setLoading(true);
      setError(null);
      await SalesProductProvider.saveIndexedDbFilter(updatedFilterValues);
      setFilterValues(updatedFilterValues);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return { filterValues, saveFilter, isLoading, error };
};

export default useSalesProductFilter;
