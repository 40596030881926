import { useContext } from "react";
import MobileAccordionApprovalRencanaKunjunganDetail from "./MobileAccordionApprovalRencanaKunjunganDetail";
import MobileApprovalRencanaKunjunganBasicInformation from "./MobileApprovalRencanaKunjunganBasicInformation";
import { Box, Spinner, useTheme } from "@chakra-ui/react";
import ApprovalRencanaKunjunganDetailContext from "../../context/ApprovalRencanaKunjunganDetailContext";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";

const MobileApprovalRencanaKunjunganDetailPage = () => {
  const { detailLoading, detailError } = useContext(
    ApprovalRencanaKunjunganDetailContext
  );

  const theme = useTheme();

  if (detailLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (detailError) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={detailError.toString()} />
      </Box>
    );
  }

  return (
    <>
      <MobileApprovalRencanaKunjunganBasicInformation />
      <MobileAccordionApprovalRencanaKunjunganDetail />
    </>
  );
};

export default MobileApprovalRencanaKunjunganDetailPage;
