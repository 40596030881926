import ProjectType from "../../../../models/common/ProjectType";
import DateExodus from "../../../../models/DateExodus";

class ListSalesTargetModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: ListSalesTargetData;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = new ListSalesTargetData(json["data"]);
  }
}

class AllListTargetModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: ListSalesTargetRecords[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];

    const temp: ListSalesTargetRecords[] = [];

    if (json["data"] !== null && json["data"].length !== 0) {
      for (let i = 0; i < json.data.length; i++) {
        temp.push(new ListSalesTargetRecords(json.data[i]));
      }
    }
    this.data = temp;
  }
}

class ListSalesTargetData {
  metadata!: ListSalesTargetMetadata;
  records!: ListSalesTargetRecords[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: ListSalesTargetRecords[] = [];

    if (json["records"] !== null && json["records"].length !== 0) {
      for (let i = 0; i < json.records.length; i++) {
        temp.push(new ListSalesTargetRecords(json.records[i]));
      }
    }

    this.metadata = new ListSalesTargetMetadata(json["metadata"]);
    this.records = temp;
  }
}

class ListSalesTargetMetadata {
  totalData!: number;
  totalDataPerPage!: number;
  currentPage!: number;
  previousPage!: number;
  totalPage!: number;
  nextPageUrl!: string;
  previousPageUrl!: string;
  firstPageUrl!: string;
  lastPageUrl!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.totalData = json["total_data"];
    this.totalDataPerPage = json["total_data_per_page"];
    this.currentPage = json["current_page"];
    this.previousPage = json["previous_page"];
    this.totalPage = json["total_page"];
    this.nextPageUrl = json["next_page_url"];
    this.previousPageUrl = json["previous_page_url"];
    this.firstPageUrl = json["first_page_url"];
    this.lastPageUrl = json["last_page_url"];
  }
}

class ListSalesTargetRecords {
  userZoneId!: number;
  userZoneType!: string;
  userZoneName!: string;
  userZoneCode?: number | null;
  fieldForceIdentity?: FieldForceIdentity | null;
  project!: ProjectType | null;
  targetSalesList!: TargetSalesList[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: TargetSalesList[] = [];
    if (
      json["target_sales_list"] !== null &&
      json["target_sales_list"].length !== 0
    ) {
      for (let i = 0; i < json.target_sales_list.length; i++) {
        temp.push(new TargetSalesList(json.target_sales_list[i]));
      }
    }

    this.userZoneId = json["user_zone_id"];
    this.userZoneType = json["user_zone_type"];
    this.userZoneName = json["user_zone_name"];
    if (
      json["user_zone_code"] !== null &&
      json["user_zone_code"] !== undefined
    ) {
      this.userZoneCode = json["user_zone_code"];
    } else {
      this.userZoneCode = null;
    }
    if (json["field_force"] !== null && json["field_force"] !== undefined) {
      this.fieldForceIdentity = new FieldForceIdentity(json["field_force"]);
    } else {
      this.fieldForceIdentity = null;
    }

    this.project = json["project"] ?? null;
    this.targetSalesList = temp;
  }
}

class FieldForceIdentity {
  fieldForceId!: number;
  fieldForceNip!: string;
  fieldForceName!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.fieldForceId = json["field_force_id"];
    this.fieldForceNip = json["field_force_nip"];
    this.fieldForceName = json["field_force_name"];
  }
}

class TargetSalesList {
  targetSalesId!: number;
  groupTerritoryId!: number;
  valueTarget!: number | string;
  salesPeriod!: DateExodus;
  createdAt!: DateExodus;
  updatedAt!: DateExodus;
  approvedAt!: DateExodus | null;
  createdBy?: CreatedByTargetSalesList;
  approvedBy?: ApprovedByTargetSalesList | null;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.targetSalesId = json["id"];
    this.groupTerritoryId = json["group_territory_id"];
    this.valueTarget = json["value_target"];
    this.salesPeriod = DateExodus.fromBackend(json["sales_period"]);
    this.createdAt = DateExodus.fromBackend(json["created_at"]);
    this.updatedAt = DateExodus.fromBackend(json["updated_at"]);
    this.approvedAt = json["approved_at"] ? DateExodus.fromBackend(json["approved_at"])
    : null;
    this.createdBy = new CreatedByTargetSalesList(json["created_by"]);

    if (json["approved_by"] !== null && json["approved_by"] !== undefined) {
      this.approvedBy = new ApprovedByTargetSalesList(json["approved_by"]);
    } else {
      this.approvedBy = null;
    }
  }
}

class CreatedByTargetSalesList {
  createdById!: number;
  createdByName!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.createdById = json["id"];
    this.createdByName = json["name"];
  }
}

class ApprovedByTargetSalesList {
  approvedById!: number;
  approvedByNama!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.approvedById = json["id"];
    this.approvedByNama = json["name"];
  }
}

export {ApprovedByTargetSalesList, CreatedByTargetSalesList, FieldForceIdentity, ListSalesTargetData, ListSalesTargetMetadata,ListSalesTargetModelPack,ListSalesTargetRecords, TargetSalesList, AllListTargetModelPack } 