import { useEffect, useState } from "react";
import ActivityGeneralProvider from "../dataProviders/ActivityGeneralProvider";
import { ListApprovalParams } from "../dataProviders/ActivityGeneralParamType";
import {
  ListApprovalKunjunganModelMetadata,
  ListApprovalKunjunganModelRecords,
} from "../models/ApprovalListModel";
import { isEmpty } from "lodash";

const useGetListApproval = ({
  page,
  limit,
  start_period,
  end_period,
  status,
  kunjungan,
  jabatan,
  userId,
  project
}: ListApprovalParams) => {
  const [approvalData, setData] = useState<ListApprovalKunjunganModelRecords[]>(
    []
  );
  const [metadata, setMetadata] =
    useState<ListApprovalKunjunganModelMetadata | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const response = await ActivityGeneralProvider.getApprovalList({
          page,
          limit,
          start_period,
          end_period,
          status,
          kunjungan,
          jabatan,
          userId,
          project
        });

        setData(response?.data || []);
        setMetadata(response?.metadata as ListApprovalKunjunganModelMetadata);
        setLoading(false);
      } catch (error: any) {
        setData([]);
        setMetadata(null);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    if (!isEmpty(start_period) && !isEmpty(end_period) && page && limit) {
      getData();
    }
  }, [
    page,
    limit,
    JSON.stringify(start_period),
    JSON.stringify(end_period),
    status,
    kunjungan,
    jabatan,
    userId,
    project
  ]);

  return { approvalData, metadata, loading, errorMessage };
};

export default useGetListApproval;
