export const AUTHENTICATION_API_ROOT = "/authentication";
export const EXODUS_API_VERSION_1 = "/v1";
export const EXODUS_API_VERSION_2 = "/v2";
export const EXODUS_API_VERSION_3 = "/v3";

export const FORGOT_PASSWORD_NAVIGATION_ROOT = "/forgot-password";
export const EMAIL_VERIFICATION_NAVIGATION_URL = "/email-verification";
export const GENERATE_NEW_PASSWORD_URL = "/new-password";

export const LOGIN_NAVIGATION_ROOT = "/login";

export const PROFILE_TEXT = "profile";
export const MONITORING_BUDGETING = "monitoring-budgeting";
export const PROFILE_NAVIGATION_ROOT = "/" + PROFILE_TEXT;

export const SALES_TARGET = "sales-target";
export const MONITOR_SALES_TARGET = "monitor-sales-target";

export const MONITOR_SALES_PRODUCT_TEXT = "monitor-sales-product";
export const MONITOR_SALES_PRODUCT_NAVIGATION_ROOT =
  "/" + MONITOR_SALES_PRODUCT_TEXT;

export const SALES_DISTRIBUTOR = "sales-distributor";
export const SALES_OUTLET_TEXT = "sales-outlet";
export const SALES_OUTLET_NAVIGATION_ROOT = "/" + SALES_OUTLET_TEXT;

export const OUTLET_CARD_PATH = "outlet-card";
