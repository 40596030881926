import { useMediaQuery, Flex, Spacer, useTheme } from "@chakra-ui/react";
import { useState } from "react";
import BiayaMurniFilterBoxes from "../boxes/BiayaMurniFilterBoxes";
import BiayaMurniFilterModal from "./modal/BiayaMurniFilterModal";
import ShowFilterButton from "../../../../../components/CustomButtons/ShowFilterButton";

const BiayaMurniFilterOthersRole = (): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleFilterDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const theme = useTheme();

  const [isDesktop] = useMediaQuery(`(min-width: ${theme.breakpoints.tablet})`);

  if (isDesktop) {
    return (
      <>
        <Flex direction={"row"} width={"100%"}>
          <BiayaMurniFilterBoxes />
          <Spacer />
          <ShowFilterButton onClick={handleFilterDialogOpen} />
        </Flex>
        <BiayaMurniFilterModal
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      </>
    );
  } else {
    return (
      <>
        <Flex direction={"row"}>
          <Spacer />
          <ShowFilterButton onClick={handleFilterDialogOpen} />
        </Flex>
        <BiayaMurniFilterModal
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      </>
    );
  }
};

export default BiayaMurniFilterOthersRole;
