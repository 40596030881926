import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Button,
  Text,
  useTheme,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";

type Props = {
  openConfirmation: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  isReject: boolean;
};

const DialogConfirmationApproveRejectRealisasiKunjungan: React.FC<Props> = (
  props
) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const approveRejectText = () => {
    if (!props.isReject) {
      return "Approve";
    }
    return "Reject";
  };

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={props.openConfirmation}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          <Text>Konfirmasi {approveRejectText()}</Text>
        </ModalHeader>
        <ModalBody>
          <Text>
            Apakah anda yakin ingin {approveRejectText()} Realisasi Kunjungan
            ini?
          </Text>
          <Flex
            direction={!isDesktopView ? "column" : "row-reverse"}
            gap={!isDesktopView ? 0 : 5}
            marginTop="48px"
            marginBottom="20px"
          >
            <Button
              type="submit"
              width="100%"
              colorScheme="buttonPrimary"
              isLoading={props.loading}
              isDisabled={props.loading}
              onClick={props.onSubmit}
            >
              Submit
            </Button>
            <Button
              onClick={props.onClose}
              width="100%"
              variant="outline"
              colorScheme="buttonSecondary"
              marginTop={!isDesktopView ? "12px" : 0}
              isDisabled={props.loading}
            >
              Cancel
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DialogConfirmationApproveRejectRealisasiKunjungan;
