import { Stack, useTheme } from "@chakra-ui/react";
import ExportExcelButton from "../../../../../../../components/CustomButtons/ExportExcelButton";
import { useOutletCardDetailContext } from "../../../contexts/OutletCardDetailContextProvider";

const MobileExportExcelArea = () => {
  const theme = useTheme();
  const {
    states: { isExportLoading },
    actions: { exportData },
  } = useOutletCardDetailContext();

  return (
    <Stack
      spacing={1}
      sx={{
        position: "sticky",
        pt: 2,
        pb: 2,
        bottom: 0,
        backgroundColor: theme.colors.exodus.background,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ExportExcelButton onClick={exportData} isLoading={isExportLoading} />
    </Stack>
  );
};

export default MobileExportExcelArea;
