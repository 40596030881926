import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    _checked: {
      backgroundColor: "#0f4c82",
      borderColor: "#0f4c82",
    },
  },
});

export const RadioTheme = defineMultiStyleConfig({ baseStyle });
