import { useMediaQuery, useTheme } from "@chakra-ui/react";
import BiayaMurniEntertainmentPageMobile from "./components/mobile/BiayaMurniEntertainmentPageMobile";
import BiayaMurniEntertainmentPageDesktop from "./components/desktop/BiayaMurniEntertainmentPageDesktop";
import { BiayaMurniFilterContextProvider } from "../../contexts/BiayaMurniFilterContext";
import useTitle from "../../../../services/useTitle";
import { BiayaMurniListContextProvider } from "../../contexts/BiayaMurniListContext";

const BiayaMurniEntertainmentPage = (): JSX.Element => {
  useTitle("Biaya Murni - Entertainment");
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <BiayaMurniFilterContextProvider>
      <BiayaMurniListContextProvider featureType="entertain">
        {isDesktopView ? (
          <BiayaMurniEntertainmentPageDesktop />
        ) : (
          <BiayaMurniEntertainmentPageMobile />
        )}
      </BiayaMurniListContextProvider>
    </BiayaMurniFilterContextProvider>
  );
};

export default BiayaMurniEntertainmentPage;
