import { createContext } from "react";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { KlaimSalesDetailModelData } from "../../models/KlaimSalesDetailModel";

type DetailApprovalKlaimSalesContextProps = {
  detailKlaimSalesData: KlaimSalesDetailModelData | null;
  detailKlaimSalesLoading: boolean;
  detailKlaimSalesError: ErrorResponse | null;
  refetchDetailKlaimSales: () => void;
  openPdf: (data: string)=> void;
};

const defaultValue: DetailApprovalKlaimSalesContextProps = {
  detailKlaimSalesData: null,
  detailKlaimSalesLoading: true,
  detailKlaimSalesError: null,
  refetchDetailKlaimSales: () => {},
  openPdf: ()=>{}
};

const DetailApprovalKlaimSalesContext = createContext(defaultValue);

export default DetailApprovalKlaimSalesContext;
