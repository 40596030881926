class ApiGetSummarySalesOutletResponse {
  salesValue: number;
  estimatedSalesValue: number;

  constructor(json: any) {
    this.salesValue = json.sales_value;
    this.estimatedSalesValue = json.estimated_value;
  }
}

export default ApiGetSummarySalesOutletResponse;
