import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";

type Props = {
  openConfirmationDialog: boolean;
  onSubmit: () => void;
  onClose: () => void;
  dialogTitle: string;
  dialogContent: string;
  loading: boolean;
};

const DialogKonfirmasiRealisasiKunjungan: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Modal
      isOpen={props.openConfirmationDialog}
      onClose={props.onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          {props.dialogTitle}
        </ModalHeader>
        <ModalBody>{props.dialogContent}</ModalBody>
        <ModalFooter
          flexDirection={!isDesktopView ? "column" : "row-reverse"}
          gap={!isDesktopView ? 0 : 5}
        >
          <Button
            isLoading={props.loading}
            width="100%"
            colorScheme="buttonPrimary"
            onClick={props.onSubmit}
          >
            Confirm
          </Button>
          <Button
            isDisabled={props.loading}
            onClick={props.onClose}
            width="100%"
            variant="outline"
            colorScheme="buttonSecondary"
            marginTop={!isDesktopView ? "12px" : 0}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DialogKonfirmasiRealisasiKunjungan;
