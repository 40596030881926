import { Card, Text, useTheme } from "@chakra-ui/react";

const FakturDetailBlank: React.FC = () => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
        p: 2,
        boxShadow: "0px 7px 24px 4px #282c7a26",
        backgroundColor: theme.colors.exodus.background,
      }}
    >
      <Text fontSize={20}>Tidak Ada Faktur yang Terpilih</Text>
    </Card>
  );
};

export default FakturDetailBlank;
