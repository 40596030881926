import {
  Box,
  Grid,
  GridItem,
  Spinner,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import ApprovalKlaimSalesCard from "./ApprovalKlaimSalesCard";
import ErrorPage from "../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../components/DataNotFound/DataNotFound";
import { KlaimSalesListModelRecords } from "../../../models/KlaimSalesListModel";
import ApprovalKlaimSalesContext from "../../contexts/ApprovalKlaimSalesContext";
import Pagination from "../../../../../components/Pagination/Pagination";

const ApprovalKlaimSalesList: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const {
    klaimSalesData,
    klaimSalesError,
    klaimSalesLoading,
    metadataKlaimSales,
    filterValues,
    handleChangePageOrLimit,
  } = useContext(ApprovalKlaimSalesContext);

  if (klaimSalesLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (klaimSalesError) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={klaimSalesError.message.toString()} />
      </Box>
    );
  }

  return klaimSalesData.length > 0 ? (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={5}>
        {klaimSalesData.map((klaimSalesRecord: KlaimSalesListModelRecords) => {
          return (
            <GridItem
              colSpan={isDesktopView ? 6 : 12}
              key={klaimSalesRecord.id}
            >
              <ApprovalKlaimSalesCard
                key={klaimSalesRecord.id}
                record={klaimSalesRecord}
              />
            </GridItem>
          );
        })}
      </Grid>
      <Box
        display={"flex"}
        justifyContent={isDesktopView ? "end" : "center"}
        paddingTop={5}
      >
        <Pagination
          handleOnNavigateFirstPage={() => handleChangePageOrLimit("first")}
          handleOnBack={() => handleChangePageOrLimit("prev")}
          handleOnLimitChange={(data: number) =>
            handleChangePageOrLimit("limit", data)
          }
          handleOnNext={() => handleChangePageOrLimit("next")}
          limit={filterValues?.limit}
          page={filterValues?.page}
          totalData={metadataKlaimSales?.totalData}
          totalPage={metadataKlaimSales?.totalPage}
          showNavigateFirstPage
          showLimit
        />
      </Box>
    </>
  ) : (
    <Box
      marginTop="24px"
      marginBottom="15px"
      display={"flex"}
      justifyContent={"start"}
    >
      <DataNotFound />
    </Box>
  );
};

export default ApprovalKlaimSalesList;
