import { useEffect, useState } from "react";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import SalesDistributorDataProvider from "../dataProvider/SalesDistributorDataProvider";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import { isNil } from "lodash";
import { GetListSalesDistributorDetailData } from "../model/GetListSalesDistributorDetailModelPack.ts";

const useGetSalesDistributorDetail = (salesHeaderId: string | undefined) => {
  const [distributorDetail, setDistributorDetail] =
    useState<GetListSalesDistributorDetailData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    if (!isNil(salesHeaderId)) fetch();
  }, [salesHeaderId]);

  const fetch = async () => {
    setLoading(true);

    try {
      setError(null);
      const fetchData =
        await SalesDistributorDataProvider.getListDetail(salesHeaderId);
      setDistributorDetail(fetchData!.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(UNKNOWN_ERROR_RESPONSE);
    }
  };

  return { distributorDetail, loading, error };
};

export default useGetSalesDistributorDetail;
