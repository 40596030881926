import {
  Button,
  Card,
  CardBody,
  Flex,
  Input,
  Text,
  useMediaQuery,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import StepperControlFrame from "../../common/StepperControlFrame";
import { useStepper } from "../../../contexts/StepperContextProvider";
import DesktopSummaryKlaimSalesGeneralInformation from "./desktop/DesktopSummaryKlaimSalesGeneralInformation";
import MobileSummaryKlaimSalesGeneralInformation from "./mobile/MobileSummaryKlaimSalesGeneralInformation";
import SummaryKlaimSalesCardBase from "./SummaryKlaimSalesCardBase";
import useSummaryKlaimSalesState from "../services/useSummaryKlaimSalesState";
import useInputKlaimSales from "../../../services/useInputKlaimSales";
import { InputKlaimSalesBody } from "../../../models/InputKlaimSalesModel";
import DialogKonfirmasiSubmitKlaimSales from "./DialogKonfirmasiSubmitKlaimSales";
import { useState } from "react";

const SummaryKlaimSalesBase = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const {
    actions: { handleBack, resetActiveStepIdx },
  } = useStepper();

  const {
    storedKlaimSalesData,
    selectedFile,
    selectedFileName,
    handleSelectPdfFile,
    removeAllInputKlaimSalesData,
    getFileBase64,
  } = useSummaryKlaimSalesState();

  const { inputKlaimSales, loading } = useInputKlaimSales();

  const handleOnNext = () => {
    setOpenConfirmationDialog(false);
    handleSubmitInputKlaimSales();
  };

  const handleSubmitInputKlaimSales = async () => {
    const pdfBase64 = await getFileBase64();

    if (!pdfBase64) return;

    const submittedData: InputKlaimSalesBody = {
      outlet_tujuan_id:
        storedKlaimSalesData!.outlet!.outletTujuan.data!.outletId,
      outlet_panel_id: storedKlaimSalesData!.outlet!.outletPanel.data!.outletId,
      pdf: pdfBase64 as string,
      pdf_mime: selectedFile!.type,
      claim_sales: storedKlaimSalesData!.pilihProduk!.map((value) => {
        const dataNewDetail = value.newDetail.filter(
          (item) => item.isSelected === true
        );

        return {
          sales_header_id: value.originalData.salesHeaderId,
          data_claim_detail: dataNewDetail.map((newDetail) => {
            return {
              product_bridging_id: newDetail.originalData.productBridgingId,
              quantity: newDetail.selectedQty,
            };
          }),
        };
      }),
    };

    inputKlaimSales(submittedData, {
      onSuccess: () => {
        toast({
          title: "Berhasil Submit Klaim Sales",
          status: "success",
        });
        removeAllInputKlaimSalesData().then(() => resetActiveStepIdx());
      },
      onError: (error) => {
        toast({
          title: "Gagal Submit Klaim Sales",
          description: error.message,
          status: "error",
        });
      },
    });
  };

  return (
    <>
      <StepperControlFrame
        onHandleBack={handleBack}
        onHandleNext={
          selectedFile && storedKlaimSalesData
            ? () => setOpenConfirmationDialog(true)
            : undefined
        }
        isLoading={loading}
      >
        <Card
          textAlign="left"
          bgColor={theme.colors.exodus.background}
          boxShadow="0px 7px 24px 4px #282c7a26"
          borderRadius="12px"
          color={theme.colors.exodus.fontDefault}
          height="100%"
        >
          <CardBody>
            <Text
              color={theme.colors.exodus.primaryBlue}
              fontWeight="semibold"
              fontSize={isDesktopView ? 24 : 20}
            >
              Summary Claim Faktur
            </Text>
            {isDesktopView && (
              <DesktopSummaryKlaimSalesGeneralInformation
                data={storedKlaimSalesData}
              />
            )}
            {!isDesktopView && (
              <MobileSummaryKlaimSalesGeneralInformation
                data={storedKlaimSalesData}
              />
            )}
            <Flex
              direction={isDesktopView ? "row" : "column"}
              alignItems={isDesktopView ? "center" : "flex-start"}
              gap={isDesktopView ? 4 : 1}
              paddingBottom="24px"
            >
              <Text
                color={theme.colors.exodus.primaryBlue}
                fontSize={isDesktopView ? 20 : 14}
                fontWeight="semibold"
              >
                Upload PDF Bukti
              </Text>
              <Flex direction="row" alignItems="center" gap={2}>
                <Input
                  type="file"
                  accept=".pdf"
                  sx={{ display: "none" }}
                  onChange={(event) => handleSelectPdfFile(event.target)}
                  id="pdf-input"
                  required
                />
                <label htmlFor="pdf-input">
                  <Button
                    as={"span"}
                    variant="outline"
                    colorScheme="buttonSecondary"
                    sx={{ height: "30px", minWidth: "110px" }}
                  >
                    Browse
                  </Button>
                </label>
                <Text fontSize={isDesktopView ? 14 : 12}>
                  {selectedFileName ?? ""}
                </Text>
              </Flex>
            </Flex>
            <SummaryKlaimSalesCardBase data={storedKlaimSalesData} />
          </CardBody>
        </Card>
      </StepperControlFrame>

      <DialogKonfirmasiSubmitKlaimSales
        isOpen={openConfirmationDialog}
        onConfirm={handleOnNext}
        onClose={() => setOpenConfirmationDialog(false)}
      />
    </>
  );
};

export default SummaryKlaimSalesBase;
