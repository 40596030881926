class TargetSalesMonthlyModelPack {
    message!: string;
    status!: number;
    code!: string;
    data!: TargetSalesMonthlyModelData[];

    constructor(json: any) {
        this.mapFromJson(json);
    }

    mapFromJson(json: any) {
        var temp : TargetSalesMonthlyModelData[] = [];

        if (json["data"] !== null) {
            for (let i = 0; i < json.data.length; i++) {
                temp.push(new TargetSalesMonthlyModelData(json.data[i]));
            }
        }

        this.message = json["message"];
        this.status = json["status"];
        this.code = json["code"];
        this.data = temp;
    }
}

class TargetSalesMonthlyModelData {
    fieldForceId?: number;
    fieldForceNip?: string;
    fieldForceNama?: string;
    targetSalesValue?: number;
    salesValue?: number;
    salesZoneId!: number;
    salesZoneName!: string;
    salesZoneType!: string;

    constructor(json: any) {
        this.mapFromJson(json);
    }

    mapFromJson(json: any) {
        this.fieldForceId = json["field_force"] !== null ? json["field_force"]["field_force_id"]  : null;
        this.fieldForceNip = json["field_force"] !== null ? json["field_force"]["field_force_nip"] : null;
        this.fieldForceNama = json["field_force"] !== null ? json["field_force"]["field_force_name"] : null;
        this.targetSalesValue = json["target_sales_value"];
        this.salesValue = json["sales_value"];
        this.salesZoneId = json["zone"]["sales_zone_id"];
        this.salesZoneName = json["zone"]["sales_zone_name"];
        this.salesZoneType = json["zone"]["sales_zone_type"];
    }
}

export { TargetSalesMonthlyModelPack, TargetSalesMonthlyModelData };