import { Dispatch, createContext, SetStateAction } from "react";
import MonitoringFilterValues from "../models/MonitoringFilterValues";
import filterInitialValue from "../utils/initialFilterValue";

interface MonitoringBudgetingContextType {
  filterValues: MonitoringFilterValues | null;
  setFilterValues: Dispatch<SetStateAction<MonitoringFilterValues | null>>;
}

const initialValue: MonitoringBudgetingContextType = {
  filterValues: filterInitialValue,
  setFilterValues: () => {},
};

const MonitoringBudgetingContext =
  createContext<MonitoringBudgetingContextType>(initialValue);

export default MonitoringBudgetingContext;
