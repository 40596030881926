// https://github.com/microsoft/TypeScript/issues/40793
// I don't know why we can't use constants for this .-.
export enum CommonStatus {
  Approved = "Approved",
  Pending = "Pending",
  Rejected = "Rejected",
  Processed = "Processed",
  Transferred = "Transferred",
}

export type CommonStatusType = { id: CommonStatus; name: CommonStatus };

