import { useEffect, useState } from "react";
import RoleModel from "../../../../../models/Users/RoleModel";
import UserModel from "../../../../../models/Users/UserModel";
import FilterMonitorKunjunganModel from "../../models/FilterMonitorKunjunganModel";
import IndexedDatabase from "../../../../../database/IndexDB";
import { useNavigate } from "react-router-dom";

const useFilterStateMonitorKunjunganUserList = () => {
  const navigate = useNavigate();

  const [savedFilter, setSavedFilter] =
    useState<FilterMonitorKunjunganModel | null>(null);

  useEffect(() => {
    getSavedFilterValue();
  }, []);

  const getSavedFilterValue = async () => {
    const data: FilterMonitorKunjunganModel[] | undefined =
      await IndexedDatabase.filterMonitorKunjungan.toArray();

    if (!data || data.length === 0) {
      setSavedFilter(null);
      return null;
    } else {
      setSavedFilter(data[0]);
      return data[0];
    }
  };

  const saveFilterValueToIndexedDB = async (
    newJabatan: RoleModel | null,
    newUser: UserModel | null
  ) => {
    const oldData: FilterMonitorKunjunganModel[] | undefined =
      await IndexedDatabase.filterMonitorKunjungan.toArray();

    if (!oldData || oldData.length === 0) {
      await IndexedDatabase.filterMonitorKunjungan
        .add({
          selectedBawahanId: null,
          jabatan: newJabatan,
          user: newUser,
          periode: null,
          project: null,
        })
        .then(() => getSavedFilterValue());
    } else {
      await IndexedDatabase.filterMonitorKunjungan
        .update(oldData[0].id, {
          jabatan: newJabatan,
          user: newUser,
        })
        .then(() => getSavedFilterValue());
    }
  };

  const saveSelectedBawahanToIndexedDB = async (selectedBawahanId: number) => {
    const oldData: FilterMonitorKunjunganModel[] | undefined =
      await IndexedDatabase.filterMonitorKunjungan.toArray();

    if (!oldData || oldData.length === 0) {
      await IndexedDatabase.filterMonitorKunjungan
        .add({
          selectedBawahanId: selectedBawahanId,
          jabatan: null,
          user: null,
          periode: null,
          project: null,
        })
        .then(() => {
          getSavedFilterValue();
          navigate("/monitor-kunjungan/pssp-aktif");
        });
    } else {
      await IndexedDatabase.filterMonitorKunjungan
        .update(oldData[0].id, {
          selectedBawahanId: selectedBawahanId,
          periode: null,
        })
        .then(() => {
          getSavedFilterValue();
          navigate("/monitor-kunjungan/pssp-aktif");
        });
    }
  };

  const resetSavedFilterValue = async () => {
    const oldData: FilterMonitorKunjunganModel[] | undefined =
      await IndexedDatabase.filterMonitorKunjungan.toArray();

    if (!oldData || oldData.length === 0) {
      await IndexedDatabase.filterMonitorKunjungan
        .add({
          selectedBawahanId: null,
          jabatan: null,
          user: null,
          periode: null,
          project: null,
        })
        .then(() => getSavedFilterValue());
    } else {
      await IndexedDatabase.filterMonitorKunjungan
        .update(oldData[0].id, {
          jabatan: null,
          user: null,
        })
        .then(() => getSavedFilterValue());
    }
  };

  return {
    savedFilter,
    saveFilterValueToIndexedDB,
    saveSelectedBawahanToIndexedDB,
    resetSavedFilterValue,
  };
};

export default useFilterStateMonitorKunjunganUserList;
