import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import { useOutletCardListContext } from "../../contexts/OutletCardListContextProvider";
import { GetListOutletCardModelData } from "../../../models/GetListOutletCardModel";
import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { sectorStringToSectorModel } from "../../../utils/SectorStringToSectorModel";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";
import { IoMdEye } from "react-icons/io";
import { createSearchParams, useNavigate } from "react-router-dom";
import Pagination from "../../../../../../components/Pagination/Pagination";
import { isEmpty } from "lodash";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import { useState } from "react";
import FilterAndExcelHeader from "./components/FilterAndExcelHeader";
import OutletCardFilterModal from "../common/OutletCardFilterModal";

const OutletCardListDesktop: React.FC = () => {
  const {
    states,
    actions: { setPage, setLimit },
  } = useOutletCardListContext();

  const navigate = useNavigate();

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  const tableHeader = [
    {
      name: "Nama Outlet",
      cell: (row: GetListOutletCardModelData) => (
        <Text fontSize={14} textAlign={"left"}>
          {row.outletName}
        </Text>
      ),
      width: "30%",
    },
    {
      name: "Kode Outlet",
      cell: (row: GetListOutletCardModelData) => (
        <Text fontSize={14}>{row.outletCode}</Text>
      ),
    },
    {
      name: "Sektor",
      cell: (row: GetListOutletCardModelData) => (
        <Text fontSize={14} textAlign={"left"}>
          {sectorStringToSectorModel(row.sectorType).label}
        </Text>
      ),
    },
    {
      name: "Biaya Dasar",
      cell: (row: GetListOutletCardModelData) => (
        <Text fontSize={14}>
          {CurrencyIdrFormatter.convertToRp(row.biayaDasar.totalCost)}
        </Text>
      ),
    },
    {
      name: "Saldo Kontribusi",
      cell: (row: GetListOutletCardModelData) => (
        <Text fontSize={14}>
          {CurrencyIdrFormatter.convertToRp(row.saldoKontribusi)}
        </Text>
      ),
    },
    {
      name: "Sisa Saldo",
      cell: (row: GetListOutletCardModelData) => (
        <Text fontSize={14}>
          {CurrencyIdrFormatter.convertToRp(row.sisaSaldo)}
        </Text>
      ),
    },
    {
      name: "Biaya Murni",
      cell: (row: GetListOutletCardModelData) => (
        <Text fontSize={14}>
          {CurrencyIdrFormatter.convertToRp(row.biayaMurni.totalCost)}
        </Text>
      ),
    },
    {
      name: "",
      cell: (row: GetListOutletCardModelData) => (
        <Button
          colorScheme="buttonSecondary"
          variant={"ghost"}
          leftIcon={<IoMdEye />}
          onClick={() => {
            navigate({
              pathname: `/outlet-card/${row.outletId}`,
              search: createSearchParams({
                startDate: `${states.filterValues?.startDate?.getEpochDate()}`,
                endDate: `${states.filterValues?.endDate?.getEpochDate()}`,
              }).toString(),
            });
          }}
        >
          Lihat Detail
        </Button>
      ),
    },
  ].map((x) => {
    return {
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      ...x,
    };
  });

  if (states.data?.at(0)?.project) {
    tableHeader.unshift({
      name: "Project",
      cell: (row: GetListOutletCardModelData) => (
        <Text fontSize={14} textAlign={"left"}>
          {row.project}
        </Text>
      ),
      width: "8%",
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    });
  }

  /* -------------------------------- Functions ------------------------------- */
  const onFilterModalChange = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  /* --------------------------------- Return --------------------------------- */

  return (
    <Box>
      <FilterAndExcelHeader onFilterModalChange={onFilterModalChange} />
      {isEmpty(states.data) && !states.isLoadingList ? (
        <DataNotFound />
      ) : (
        <TableDataComponent
          data={states.data}
          checboxOn={false}
          isLoading={states.isLoadingList}
          tableHeader={tableHeader}
          paginationOn={false}
          paginationLimit={[]}
          onSelectChange={() => {}}
        />
      )}
      <HStack justifyContent={"flex-end"} paddingTop={"10px"}>
        <Pagination
          handleOnBack={() => setPage("prev")}
          handleOnLimitChange={setLimit}
          handleOnNavigateFirstPage={() => setPage("first")}
          handleOnNext={() => setPage("next")}
          limit={states?.metadata?.totalDataPerPage}
          page={states?.metadata?.currentPage}
          showCurrentPageDetail
          showLimit
          showNavigateFirstPage
          totalData={states?.metadata?.totalData}
          totalPage={states?.metadata?.totalPage}
        />
      </HStack>
      <OutletCardFilterModal
        isOpen={isFilterModalOpen}
        onClose={onFilterModalChange}
      />
    </Box>
  );
};

export default OutletCardListDesktop;
