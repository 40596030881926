import {
  DocumentData,
  QuerySnapshot,
  collection,
  onSnapshot,
  query,
} from "firebase/firestore";
import { useAppDispatch } from "../../../../redux/hooks";
import { setAccessKlaimSales } from "../../../../redux/KlaimSalesReducer/KlaimSalesReducer";
import { cloudFirestore } from "../../../../firebase/FirebaseConfig";

const useKlaimSalesAccess = () => {
  const dispatch = useAppDispatch();

  const onChange = (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
    if (snapshot.docs.length > 0) {
      const currentStatus: boolean = snapshot.docs[0].data().active;
      dispatch(setAccessKlaimSales(currentStatus));
    }
  };

  const listenToRemote = () => {
    const q = query(collection(cloudFirestore, "features"));
    onSnapshot(q, onChange);
  };

  return { listenToRemote };
};

export default useKlaimSalesAccess;
