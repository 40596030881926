import { Box, Flex, useMediaQuery, useTheme } from "@chakra-ui/react";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import FilterAreaMonitorSalesTarget from "./FilterAreaMonitorSalesTarget";
import ButtonApproveMonitorSalesTarget from "./ButtonApproveMonitorSalesTarget";
import ButtonExportExcelMonitorSalesTarget from "./ButtonExportExcelMonitorSalesTarget";
import SalesTargetTotalMonthly from "./SalesTargetTotalMonthly";
import DesktopTableMonitorSalesTarget from "./desktop/DesktopTableMonitorSalesTarget";
import MobileTableMonitorSalesTarget from "./mobile/MobileTableMonitorSalesTarget";
import MonitorSalesTargetContext from "../context/MonitorSalesTargetContext";
import { useContext } from "react";
import useGetTotalMonthly from "../../services/useGetTotalMonthly";
import DateExodus from "../../../../../models/DateExodus";

const MonitorSalesTargetPage: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const { filterValues } = useContext(MonitorSalesTargetContext);

  const convertEpochToDateExodus = (data?: DateExodus | number | null) => {
    if (typeof data === "number") {
      return new DateExodus(data);
    } else if (data === null) {
      return undefined;
    } else {
      return data;
    }
  };

  const { totalMonthlyData } = useGetTotalMonthly({
    startDate: convertEpochToDateExodus(filterValues?.periodeKuartalStart),
    endDate: convertEpochToDateExodus(filterValues?.periodeKuartalEnd),
    project: filterValues ? filterValues.project : null,
  });

  return (
    <Box
      minHeight={isDesktopView ? "100%" : "100vh"}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <PageTitle label="Monitor Sales Target" />
        <FilterAreaMonitorSalesTarget />
        <Box
          paddingTop={isDesktopView ? "20px" : "12px"}
          paddingBottom={isDesktopView ? "20px" : "24px"}
        >
          {totalMonthlyData &&
            totalMonthlyData.map((totalPerMonth, index) => {
              return (
                <SalesTargetTotalMonthly
                  key={index}
                  leftText={totalPerMonth.salesPeriode.getStringDateFormat(
                    "MMMM"
                  )}
                  rightText={totalPerMonth.totalValueTarget}
                />
              );
            })}
        </Box>
        {isDesktopView && <DesktopTableMonitorSalesTarget />}
        {!isDesktopView && <MobileTableMonitorSalesTarget />}
      </Box>
      {!isDesktopView && (
        <Flex
          direction="column"
          width="100%"
          position="sticky"
          bottom={0}
          bgColor={theme.colors.exodus.background}
          paddingY="12px"
          gap={3}
        >
          <ButtonApproveMonitorSalesTarget />
          <ButtonExportExcelMonitorSalesTarget />
        </Flex>
      )}
    </Box>
  );
};

export default MonitorSalesTargetPage;
