/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableColumn } from "react-data-table-component";
import StackedColumn from "../../../../../components/TableData/StackedColumnTable";
import { Text } from "@chakra-ui/react";
import { MonitorKunjunganModelData } from "../../models/MonitorKunjunganDataModel";
import CurrencyIdrFormatter from "../../../../../utils/CurrencyIdrFormatter";

const GeneratePsspAktifTableColumnData = (
  monitorData: MonitorKunjunganModelData[]
) => {
  const psspAktifDataColumn: TableColumn<any>[] = [
    {
      name: "Nama Outlet",
      cell: (row: MonitorKunjunganModelData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.outletName}
          bottomValue={row.outletCode}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "250px",
      grow: 1.5,
    },
    {
      name: "Nama Customer",
      cell: (row: MonitorKunjunganModelData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.customerName}
          bottomValue={row.customerCode}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "250px",
      grow: 1.5,
    },
    {
      name: "Spesialis",
      cell: (row: MonitorKunjunganModelData) => (
        <Text textAlign="left">{row.spesialis}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "200px",
    },
    {
      name: "Jabatan",
      cell: (row: MonitorKunjunganModelData) => (
        <Text textAlign="left">{row.jabatan}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "Dana Keluar",
      cell: (row: MonitorKunjunganModelData) => (
        <Text textAlign="left">
          {CurrencyIdrFormatter.convertToRp(row.psspNominal)}
        </Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "Periode PSSP",
      cell: (row: MonitorKunjunganModelData) => (
        <Text textAlign="left">{`${row.startMonth.getStringDateFormat(
          "D MMM YYYY"
        )} - ${row.endMonth.getStringDateFormat("D MMM YYYY")}`}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "Total Rencana Kunjungan",
      cell: (row: MonitorKunjunganModelData) => (
        <Text textAlign="left">{`${row.totalRencana} Kunjungan, ${row.totalRealisasi} Realisasi`}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
  ];

  if (monitorData[0].project) {
    psspAktifDataColumn.unshift({
      name: "Project",
      cell: (row: MonitorKunjunganModelData) => (
        <Text textAlign="left">{row.project!.label}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    });
  }

  return psspAktifDataColumn;
};

export default GeneratePsspAktifTableColumnData;
