/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import AxiosBaseClient from "../../../../dataProviders/config/AxiosBaseClient";
import {
  RealisasiOrExtraCallListParam,
  CreateExtraCallParam,
  DeleteExtraCallParam,
  InputRealisasiKunjunganParam,
  UpdateExtraCallParam,
} from "./RealisasiKunjunganParamType";
import { RealisasiAndExtraCallModelPack } from "../Input/models/RealisasiAndExtraCallDataModel";
import { HeaderApprovalRealisasiKunjunganModelPack } from "../Approval/models/ApprovalRealisasiKunjunganModel";
import {
  RealisasiByHeaderUserIdAllParam,
  RealisasiByHeaderUserIdAndActiveTabParam,
  UpdateStatusRealisasiParam,
} from "./RealisasiKunjunganParamType";
import IndexedDatabase from "../../../../database/IndexDB";
import { isEmpty } from "lodash";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import {
  GET_DATA_DB_ERROR_RESPONSE,
  SAVE_TO_DB_ERROR_RESPONSE,
} from "../Approval/constants/Constants";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const RealisasiKunjunganProvider = {
  getRealisasiOrExtraCallList: async (
    params: RealisasiOrExtraCallListParam
  ) => {
    try {
      let url = "";

      if (params.isRealisasi === true) {
        url = `/v1/realization/header/realization`;
      } else {
        url = `/v1/realization/header/extracall`;
      }

      if (params.header_id === 0) {
        return Promise.resolve([]);
      }

      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          header_id: params.header_id,
        },
      });

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new RealisasiAndExtraCallModelPack(
            response.data
          );
          return Promise.resolve(responseData.data.detailRealisasi);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  inputRealisasiKunjungan: async (params: InputRealisasiKunjunganParam) => {
    try {
      let url = "";

      if (params.isKunjunganFailed) {
        url = `/v1/realization/visitor/comment/${params.id}`;
      } else {
        url = `/v1/realization/visitor/${params.id}`;
      }

      const response: AxiosResponse = await AxiosBaseClient.put(
        url,
        params.data
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  createExtraCall: async (params: CreateExtraCallParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.post(
        "/v1/realization/extra/create",
        params.data
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  updateExtraCall: async (params: UpdateExtraCallParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.put(
        `/v1/realization/extra/${params.id}`,
        params.data
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  deleteExtraCall: async (params: DeleteExtraCallParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.delete(
        `/v1/realization/extra/delete/${params.id}`
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message.includes(SUCCESS_MESSAGE.toLowerCase())) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  getRealisasiByHeaderUserIdAndActiveTab: async (
    params: RealisasiByHeaderUserIdAndActiveTabParam
  ) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `/v1/realization/header/${params.activeTab}`,
        {
          params: {
            header_id: params.header_id,
            user_id: params.user_id,
          },
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const result = new HeaderApprovalRealisasiKunjunganModelPack(
            response.data
          );
          return Promise.resolve(result);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getRealisasiByHeaderUserIdAll: async (
    params: RealisasiByHeaderUserIdAllParam
  ) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `/v1/realization/header/all`,
        { params: params }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const result = new HeaderApprovalRealisasiKunjunganModelPack(
            response.data
          );
          return Promise.resolve(result);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  updateStatusRealisasi: async (params: UpdateStatusRealisasiParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.put(
        `/v1/realization/status/${params.header_id}`,
        params.data,
        {
          params: {
            project: params.project?.value
          }
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  saveApprovalRealisasiFilter: async (filterValues: any) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.approvalRealisasiFilter.toArray();

      if (isEmpty(databaseResponse)) {
        await IndexedDatabase.approvalRealisasiFilter.add(filterValues);
        return Promise.resolve(SUCCESS_MESSAGE);
      } else if (databaseResponse) {
        await IndexedDatabase.approvalRealisasiFilter.update(
          databaseResponse[0],
          filterValues
        );
        return Promise.resolve(SUCCESS_MESSAGE);
      }

      await IndexedDatabase.approvalRealisasiFilter.clear();
      const errorResponse: ErrorResponse = {
        message: "Error in saving Approval Realisasi Filter",
        status: 0,
        code: "",
      };
      return Promise.reject(errorResponse.message);
    } catch (error) {
      return Promise.reject(SAVE_TO_DB_ERROR_RESPONSE);
    }
  },
  getSavedApprovalRealisasiFilter: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.approvalRealisasiFilter.toArray();
      if (databaseResponse && !isEmpty(databaseResponse)) {
        return Promise.resolve(databaseResponse[0]);
      }
      return Promise.resolve(null);
    } catch (error) {
      return Promise.reject(GET_DATA_DB_ERROR_RESPONSE);
    }
  },
  saveApprovalRealisasiDetailUserId: async (userId: any) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.approvalRealisasiDetailUserId.toArray();

      if (isEmpty(databaseResponse)) {
        await IndexedDatabase.approvalRealisasiDetailUserId.add(userId);
        return Promise.resolve(SUCCESS_MESSAGE);
      } else if (databaseResponse && databaseResponse.length > 0) {
        await IndexedDatabase.approvalRealisasiDetailUserId.update(
          databaseResponse[0],
          userId
        );
        return Promise.resolve(SUCCESS_MESSAGE);
      }

      await IndexedDatabase.approvalRealisasiDetailUserId.clear();
      const errorResponse: ErrorResponse = {
        message: "Error in saving Approval Realisasi User ID",
        status: 0,
        code: "",
      };
      return Promise.reject(errorResponse.message);
    } catch (error) {
      return Promise.reject(SAVE_TO_DB_ERROR_RESPONSE);
    }
  },
  getApprovalRealisasiDetailUserId: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.approvalRealisasiDetailUserId.toArray();

      if (
        databaseResponse &&
        !isEmpty(databaseResponse) &&
        databaseResponse.length === 1
      ) {
        return Promise.resolve(databaseResponse[0]);
      }
      return Promise.resolve(null);
    } catch (error) {
      return Promise.reject(GET_DATA_DB_ERROR_RESPONSE);
    }
  },
  saveMetadataApprovalRealisasi: async (data: any) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.metadataApprovalRealisasi.toArray();

      if (isEmpty(databaseResponse)) {
        await IndexedDatabase.metadataApprovalRealisasi.add(data);
        return Promise.resolve(SUCCESS_MESSAGE);
      } else if (databaseResponse) {
        await IndexedDatabase.metadataApprovalRealisasi.update(
          databaseResponse[0],
          data
        );
        return Promise.resolve(SUCCESS_MESSAGE);
      }

      await IndexedDatabase.metadataApprovalRealisasi.clear();
      const errorResponse: ErrorResponse = {
        message: "Error in saving Approval Realisasi Filter",
        status: 0,
        code: "",
      };
      return Promise.reject(errorResponse.message);
    } catch (error) {
      return Promise.reject(SAVE_TO_DB_ERROR_RESPONSE);
    }
  },
  getSavedMetadataApprovalRealisasi: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.metadataApprovalRealisasi.toArray();
      if (databaseResponse && !isEmpty(databaseResponse)) {
        return Promise.resolve(databaseResponse[0]);
      }
      return Promise.resolve(null);
    } catch (error) {
      return Promise.reject(GET_DATA_DB_ERROR_RESPONSE);
    }
  },
};

export default RealisasiKunjunganProvider;
