import { FormProvider, useForm } from "react-hook-form";
import { useBiayaMurniFilterContext } from "../../../contexts/BiayaMurniFilterContext";
import BiayaMurniFilterBoxes from "../boxes/BiayaMurniFilterBoxes";
import FilterByPeriode from "./fields/FilterByPeriode";
import DateExodus from "../../../../../models/DateExodus";
import { useEffect } from "react";
import BiayaMurniFilterModel from "../../../models/BiayaMurniFilterModel";
import { Flex, Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import { useBiayaMurniListContext } from "../../../contexts/BiayaMurniListContext";
import FilterDummyUi from "./fields/FilterDummyUi";
import FilterByProject from "./fields/FilterByProject";

const BiayaMurniFilterFfRole = ({
  isEditable,
}: {
  isEditable: boolean;
}): JSX.Element => {
  const {
    states: { filterValues, isLoading },
  } = useBiayaMurniFilterContext();

  if (!isLoading && filterValues) {
    return <Content isEditable={isEditable} filterValues={filterValues} />;
  } else {
    return <FilterDummyUi />;
  }
};

const Content = ({
  filterValues,
  isEditable,
}: {
  filterValues: BiayaMurniFilterModel;
  isEditable: boolean;
}): JSX.Element => {
  const {
    actions: { saveFilter },
  } = useBiayaMurniFilterContext();

  const {
    actions: { setPage },
  } = useBiayaMurniListContext();

  const methods = useForm<BiayaMurniFilterModel>({
    defaultValues: {
      project: filterValues?.project ?? undefined,
      jabatan: undefined,
      pekerja: undefined,
      periode: {
        awal: filterValues?.periode.awal ?? new DateExodus(),
        akhir: filterValues?.periode.akhir ?? new DateExodus(),
      },
    },
  });

  const handleOnSubmitFilter = () => {
    const updatedFilter = new BiayaMurniFilterModel({
      project: methods.getValues("project"),
      jabatan: undefined,
      pekerja: undefined,
      periode: {
        awal: methods.getValues("periode.awal"),
        akhir: methods.getValues("periode.akhir"),
      },
    });
    saveFilter(updatedFilter);
    setPage("first");
  };

  useEffect(() => {
    const subscription = methods.watch(() => {
      handleOnSubmitFilter();
    });
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  const theme = useTheme();
  const [isSmall] = useMediaQuery(
    `(max-width: ${theme.breakpoints.smallMonitor})`
  );

  if (isEditable) {
    return (
      <FormProvider {...methods}>
        <Stack
          marginY={4}
          direction={isSmall ? "column" : "row"}
          gap={isSmall ? 2 : 4}
        >
          <FilterByProject />
          <FilterByPeriode />
        </Stack>
      </FormProvider>
    );
  } else {
    return (
      <Flex direction={"row"} width={"100%"}>
        <BiayaMurniFilterBoxes />
      </Flex>
    );
  }
};

export default BiayaMurniFilterFfRole;
