import { Box, Spinner, Stack, useTheme } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import { OutletModel } from "../../../../../../models/Outlet/OutletModel";
import useGetOutletByUserId from "../../../../../../services/Outlet/useGetOutletByUserId";
import { debounce, isEmpty } from "lodash";
import { useRef, useState } from "react";

const FilterByOutlet = () => {
  const theme = useTheme();
  const formContext = useFormContext();
  const watchZone = formContext.watch("zone");
  const watchBawahan = formContext.watch("bawahan");
  const watchSector = formContext.watch("sector");
  const watchOutlet = formContext.watch("outlet");

  const [searched, setSearched] = useState<string | undefined>();
  const debouncedOnSearch = useRef(
    debounce(async (value: string | undefined) => {
      if (value && (value.length === 0 || value.length >= 3)) {
        setSearched(value);
      }
    }, 300)
  ).current;

  const { outletData, loading: isLoadingGetOutlet } = useGetOutletByUserId({
    params: { userId: watchBawahan?.id ?? undefined, keyword: searched },
  });

  return (
    <Box width={"100%"}>
      <FormInputLabel title="Outlet" />
      <Stack direction="row" alignItems="center" width="100%">
        {isLoadingGetOutlet && (
          <Spinner color={theme.colors.exodus.primaryBlue} />
        )}
        <Box width="100%">
          <CustomSelect
            options={outletData}
            onChange={(eachOutlet: OutletModel | null) => {
              formContext.setValue("outlet", eachOutlet);
              formContext.setValue("role", null);
              formContext.setValue("bawahan", null);
              formContext.setValue("sector", null);
              formContext.setValue("zone", null);
            }}
            getOptionLabel={(eachOutlet: OutletModel) =>
              `${eachOutlet.outletName} - ${eachOutlet.outletCode}`
            }
            value={watchOutlet}
            placeholder="Nama Outlet - Kode Outlet"
            getOptionValue={(eachOutlet) => eachOutlet.outletName}
            isDisabled={!isEmpty(watchZone) || !isEmpty(watchSector)}
            isClearable
            onInputChange={(value) => {
              debouncedOnSearch(value);
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default FilterByOutlet;
