import { Box, Flex, Text, useTheme } from "@chakra-ui/react";
import { MdOutlineWarning } from "react-icons/md";

interface ErrorPageProps {
  message: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ message }) => {
  const theme = useTheme();
  const darkGrey = theme.colors.exodus.darkGrey;

  return (
    <Box textAlign="center" paddingY={10} width="100%">
      <Flex direction={"column"} alignItems={"center"}>
        <MdOutlineWarning style={{ fontSize: "64px", color: darkGrey }} />
        <Text
          fontSize="20px"
          fontWeight="semibold"
          color={darkGrey}
          mt={3}
          mb={4}
        >
          {message}
        </Text>
      </Flex>
    </Box>
  );
};

export default ErrorPage;
