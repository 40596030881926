import { AxiosResponse } from "axios";
import AxiosBaseClient from "../../config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import {
  ProductByGroupIdOrNameParam,
  PaginatedListDivisiParam,
} from "./ProductParamType";
import ProductModel from "../../../models/Product/ProductModel";
import ProductBySearchModel from "../../../models/Product/ProductBySearchModel";
import GroupDataModel from "../../../models/Product/GroupModel";
import { DivisiDataModel } from "../../../models/Product/DivisiModel";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const ProductProvider = {
  getAllProducts: async () => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get("/v1/products");

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const productData: ProductModel[] = response.data.data.map(
            (json: any) => {
              return {
                productId: json["product_id"],
                productCode: json["product_code"],
                name: json["name"],
                priceValue: json["price_value"],
                incentivePercentage: json["incentive_percentage"],
                contributePercentage: json["contribute_percentage"],
                maxContributePercentage: json["max_contribute_percentage"],
                groupName: json["group_name"],
                categoryName: json["category_name"],
                groupCode: json["group_code"],
                categoryCode: json["category_code"],
              };
            }
          );
          return Promise.resolve(productData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getProductBySearch: async (params: ProductByGroupIdOrNameParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/products/search",
        { params: params }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const searchProductData: ProductBySearchModel[] =
            response.data.data.map((json: any) => {
              return {
                productId: json["product_id"],
                productCode: json["product_code"],
                productName: json["product_name"],
                groupId: json["group_id"],
                groupName: json["group_name"],
                categoryId: json["category_id"],
                categoryName: json["category_name"],
                categoryCode: json["category_code"],
              };
            });
          return Promise.resolve(searchProductData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getGroupsByAllProducts: async () => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/products/groups"
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const groupData: GroupDataModel[] = response.data.data.map(
            (json: any) => {
              return {
                id: json["ID"],
                groupName: json["group_name"],
                groupCode: json["group_code"],
              };
            }
          );
          return Promise.resolve(groupData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getDivisiByAllProduct: async (params: PaginatedListDivisiParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/products/category",
        { params: params, paramsSerializer: { indexes: null } }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const divisiData: DivisiDataModel[] = response.data.data.records.map(
            (json: any) => {
              return {
                id: json["id"],
                name: json["name"],
                code: json["code"],
              };
            }
          );
          return Promise.resolve(divisiData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
};

export default ProductProvider;
