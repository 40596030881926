class SalesOutletDetailProductData {
  productName: string;
  estimatedUnit: number;
  estimatedValue: number;
  salesUnit: number;
  salesValue: number;

  constructor(json: any) {
    this.productName = json.product_name;
    this.estimatedUnit = json.estimated_unit;
    this.estimatedValue = json.estimated_value;
    this.salesUnit = json.sales_unit;
    this.salesValue = json.sales_value;
  }
}

export default SalesOutletDetailProductData;
