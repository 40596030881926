import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
  Text,
  Divider,
} from "@chakra-ui/react";
import SingleRowDetail from "./SingleRowDetail";
import { useOutletCardDetailContext } from "../../../contexts/OutletCardDetailContextProvider";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";

const OutletCardBiayaMurniModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    states: { outletCardDetail },
  } = useOutletCardDetailContext();

  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      autoFocus={false}
      size={isMobile ? "sm" : "md"}
    >
      <ModalOverlay />
      <ModalContent
        sx={{
          padding: 2,
          paddingY: 4,
          backgroundColor: `${theme.colors.exodus.background}`,
          borderRadius: "16px",
        }}
      >
        <ModalHeader>
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            fontSize={24}
            textAlign={"left"}
          >
            Biaya Murni
          </Text>
        </ModalHeader>
        <ModalBody
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <SingleRowDetail
            leftText="PSSP Dokter"
            rightText={CurrencyIdrFormatter.convertToRp(
              outletCardDetail?.biayaMurni.psspDoctorCost ?? 0
            )}
          />
          <SingleRowDetail
            leftText="PSSP Non Dokter"
            rightText={CurrencyIdrFormatter.convertToRp(
              outletCardDetail?.biayaMurni.psspNonDoctorCost ?? 0
            )}
          />
          <SingleRowDetail
            leftText="Entertain"
            rightText={CurrencyIdrFormatter.convertToRp(
              outletCardDetail?.biayaMurni.entertainCost ?? 0
            )}
          />
          <SingleRowDetail
            leftText="DPL/DPF"
            rightText={CurrencyIdrFormatter.convertToRp(
              outletCardDetail?.biayaMurni.discountCost ?? 0
            )}
          />
          <SingleRowDetail
            leftText="Standarisasi"
            rightText={CurrencyIdrFormatter.convertToRp(
              outletCardDetail?.biayaMurni.standarisasiCost ?? 0
            )}
          />
          <SingleRowDetail
            leftText="DP"
            rightText={CurrencyIdrFormatter.convertToRp(
              outletCardDetail?.biayaMurni.downPaymentCost ?? 0
            )}
          />
          <Divider
            color={theme.colors.exodus.primaryBlue}
            width={"100%"}
            sx={{
              border: "1px",
            }}
          />
          <SingleRowDetail
            leftText="Total Biaya"
            rightText={CurrencyIdrFormatter.convertToRp(
              outletCardDetail?.biayaMurni.totalCost ?? 0
            )}
          />
          <Button
            variant="outline"
            marginTop={4}
            colorScheme="buttonPrimary"
            onClick={onClose}
          >
            <Text
              fontWeight={600}
              fontSize={16}
              color={theme.colors.exodus.primaryBlue}
            >
              Tutup
            </Text>
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OutletCardBiayaMurniModal;
