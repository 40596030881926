import { useState } from "react";
import RencanaKunjunganProvider from "../../dataProviders/RencanaKunjunganProvider";

const useSaveApprovalRealisasiUserId = () => {
  const [message, setMessage] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);

  const saveData = async (userId: number) => {
    setLoading(true);
    try {
      const result: string =
        await RencanaKunjunganProvider.saveUserIdRencanaKunjunganDetail(userId);
      setMessage(result);
    } catch (error: unknown) {
      setMessage(error as string);
    } finally {
      setLoading(false);
    }
  };

  return { message, isLoading, saveData };
};

export default useSaveApprovalRealisasiUserId;
