import { useState, useEffect } from "react";
import BiayaMurniProvider from "../../dataProviders/BiayaMurniProvider";
import { BiayaMurniGetOneDplDpfParam } from "../../dataProviders/BiayaMurniProviderParams";
import { ApiGetOneDplDpfResponse } from "../../models/apiResponses/ApiGetOneDplDpfResponse";
import ErrorResponse from "../../../../models/common/ErrorResponse";

const useGetOneDplDpf = (params: BiayaMurniGetOneDplDpfParam) => {
  const [data, setData] = useState<ApiGetOneDplDpfResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setError(null);
        setIsLoading(true);

        const result: ApiGetOneDplDpfResponse =
          await BiayaMurniProvider.getOneDplDpf(params);

        setData(result);
      } catch (error) {
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setIsLoading(false);
      }
    }

    if (params.outletId && params.startDate && params.endDate) {
      getData();
    }
  }, [
    params.endDate?.toJSON(),
    params.startDate?.toJSON(),
    params.userId,
    params.outletId,
  ]);

  return { data, isLoading, error };
};

export default useGetOneDplDpf;
