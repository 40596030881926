import { TableColumn } from "react-data-table-component";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";
import { ListSalesTargetRecords } from "../../../models/SalesTargetModelPack";
import { Box, Spinner, Text, useTheme } from "@chakra-ui/react";
import ButtonInputorEditSalesTarget from "../ButtonInputorEditSalesTarget";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import { useContext } from "react";
import InputSalesTargetContext from "../../context/InputSalesTargetContext";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import Pagination from "../../../../../../components/Pagination/Pagination";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";

const DesktopInputSalesTarget = () => {
  const {
    salesTargetData,
    metadata,
    filterValues,
    loadingListSalesTarget,
    saveDataToIndexedDB,
  } = useContext(InputSalesTargetContext);
  const theme = useTheme();

  if (loadingListSalesTarget) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  const generateMonthsName = () => {
    if (filterValues && filterValues.idKuartal) {
      if (filterValues.idKuartal === 1) {
        return ["Januari", "Februari", "Maret"];
      } else if (filterValues.idKuartal === 2) {
        return ["April", "Mei", "Juni"];
      } else if (filterValues.idKuartal === 3) {
        return ["Juli", "Agustus", "September"];
      } else if (filterValues.idKuartal === 4) {
        return ["Oktober", "November", "Desember"];
      }
    }

    return [];
  };

  const handleOnLimitChange = (updatedLimit: number) => {
    saveDataToIndexedDB({
      limit: updatedLimit,
      page: 1,
      periodeTahun: filterValues?.periodeTahun,
      idKuartal: filterValues?.idKuartal,
      periodeKuartalStart: filterValues?.periodeKuartalStart,
      periodeKuartalEnd: filterValues?.periodeKuartalEnd,
      project: filterValues?.project ?? null,
    });
  };

  const handleOnNextPage = () => {
    saveDataToIndexedDB({
      limit: metadata?.totalDataPerPage!,
      page: metadata?.currentPage! + 1,
      idKuartal: filterValues?.idKuartal,
      periodeTahun: filterValues?.periodeTahun,
      periodeKuartalStart: filterValues?.periodeKuartalStart,
      periodeKuartalEnd: filterValues?.periodeKuartalEnd,
      project: filterValues?.project ?? null,
    });
  };

  const handleOnPrevPage = () => {
    saveDataToIndexedDB({
      limit: metadata?.totalDataPerPage!,
      page: metadata?.currentPage! - 1,
      idKuartal: filterValues?.idKuartal,
      periodeTahun: filterValues?.periodeTahun,
      periodeKuartalStart: filterValues?.periodeKuartalStart,
      periodeKuartalEnd: filterValues?.periodeKuartalEnd,
      project: filterValues?.project ?? null,
    });
  };

  const handleOnNavigateFirstPage = () => {
    saveDataToIndexedDB({
      limit: metadata?.totalDataPerPage!,
      page: 1,
      idKuartal: filterValues?.idKuartal,
      periodeTahun: filterValues?.periodeTahun,
      periodeKuartalStart: filterValues?.periodeKuartalStart,
      periodeKuartalEnd: filterValues?.periodeKuartalEnd,
      project: filterValues?.project ?? null,
    });
  };

  const dataColumn: TableColumn<any>[] = [
    {
      name: "Field Force",
      cell: (row: ListSalesTargetRecords) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={
            row.fieldForceIdentity?.fieldForceName
              ? row.fieldForceIdentity.fieldForceName
              : "Vacant"
          }
          bottomValue={row.fieldForceIdentity?.fieldForceNip!}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1.75,
    },
    {
      name: "Area GT",
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {row.userZoneName ? row.userZoneName : "-"}
        </Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 2.5,
    },
    {
      name: `Target ${generateMonthsName()[0]}`,
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {row.targetSalesList[0]
            ? CurrencyIdrFormatter.convertToRp(
                row.targetSalesList[0]?.valueTarget as number
              )
            : "-"}
        </Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: `Target ${generateMonthsName()[1]}`,
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {row.targetSalesList[1]
            ? CurrencyIdrFormatter.convertToRp(
                row.targetSalesList[1]?.valueTarget as number
              )
            : "-"}
        </Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: `Target ${generateMonthsName()[2]}`,
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {row.targetSalesList[2]
            ? CurrencyIdrFormatter.convertToRp(
                row.targetSalesList[2]?.valueTarget as number
              )
            : "-"}
        </Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: "",
      cell: (row: ListSalesTargetRecords) => (
        <ButtonInputorEditSalesTarget
          data={row}
          isButtonDisabled={
            row.targetSalesList.length > 0 &&
            row.targetSalesList[0].approvedBy !== null
          }
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
  ];

  if (salesTargetData.length > 0 && salesTargetData[0].project) {
    dataColumn.unshift({
      name: "Project",
      cell: (row: ListSalesTargetRecords) => (
        <Text align="left">{row.project!.label}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    });
  }

  return salesTargetData.length > 0 ? (
    <Box>
      <Box paddingTop={"10px"}>
        <TableDataComponent
          data={salesTargetData}
          tableHeader={dataColumn}
          onSelectChange={() => {}}
          paginationOn={false}
          paginationLimit={[]}
          checboxOn={false}
        />
      </Box>
      <Box paddingTop={2} display={"flex"} justifyContent={"end"}>
        <Pagination
          handleOnNavigateFirstPage={handleOnNavigateFirstPage}
          handleOnLimitChange={handleOnLimitChange}
          handleOnBack={handleOnPrevPage}
          handleOnNext={handleOnNextPage}
          page={filterValues?.page!}
          limit={filterValues?.limit!}
          totalData={metadata?.totalData}
          totalPage={metadata?.totalPage}
          showCurrentPageDetail
          showLimit
          showNavigateFirstPage
        />
      </Box>
    </Box>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default DesktopInputSalesTarget;
