import { useMediaQuery, Flex, Spacer, useTheme } from "@chakra-ui/react";
import { useState } from "react";
import SalesOutletFilterBoxes from "./boxes/SalesOutletFilterBoxes";
import SalesOutletFilterModal from "./modal/SalesOutletFilterModal";
import ShowFilterButton from "../../../../../../../../../components/CustomButtons/ShowFilterButton";
import { useSalesOutletListContext } from "../../../../contexts/SalesOutletListContext";

const SalesOutletFilterOthersRole = (): JSX.Element => {
  const {
    states: { loading },
  } = useSalesOutletListContext();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleFilterDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const theme = useTheme();

  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  if (isMobile) {
    return (
      <>
        <Flex direction={"row"}>
          <Spacer />
          <ShowFilterButton
            onClick={handleFilterDialogOpen}
            isDisabled={loading !== null}
          />
        </Flex>
        <SalesOutletFilterBoxes />
        {isDialogOpen && (
          <SalesOutletFilterModal
            isOpen={isDialogOpen}
            onClose={() => {
              setIsDialogOpen(false);
            }}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <Flex direction={"row"} width={"100%"}>
          <SalesOutletFilterBoxes />
          <Spacer />
          <ShowFilterButton
            onClick={handleFilterDialogOpen}
            isDisabled={loading !== null}
          />
        </Flex>
        <SalesOutletFilterModal
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      </>
    );
  }
};

export default SalesOutletFilterOthersRole;
