import { BiayaMurniPsspData } from "../../../../../models/apiResponses/BiayaMurniPsspData";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import HorizontalAccordionDetail from "../../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";
import { projectEnumToUi } from "../../../../../../../models/common/Project";

const BiayaMurniPsspDokterAccordionItem = ({
  record,
  hasProject,
}: {
  record: BiayaMurniPsspData;
  hasProject: boolean;
}) => {
  const theme = useTheme();
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          <Box>
            {hasProject && (
              <Text
                fontSize={14}
                fontWeight={600}
                color={theme.colors.exodus.primaryBlue}
              >
                {projectEnumToUi(record.project)}
              </Text>
            )}
            <Text
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
              fontSize={14}
            >
              {record.customerName}
            </Text>
            <Text
              color={theme.colors.exodus.darkGrey}
              fontWeight={600}
              fontSize={14}
            >
              {record.customerCode} - {record.spesialis}
            </Text>
          </Box>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel mx={4}>
        <HorizontalAccordionDetail
          leftText="Jabatan"
          rightSide={record.posisi}
        />
        <HorizontalAccordionDetail
          leftText="Periode"
          rightSide={record.psspPeriod}
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText="Outlet"
          rightSide={
            <Stack flexDirection={"column"} gap={0}>
              <Text textAlign={"right"} fontSize={14} fontWeight="400">
                {record.outletName}
              </Text>
              <Text textAlign={"right"} fontSize={14} fontWeight="400">
                {record.outletCode}
              </Text>
            </Stack>
          }
        />
        <HorizontalAccordionDetail
          leftText="Tanggal Realisasi"
          rightSide={
            record.tglRealisasiFormat
              ? record.tglRealisasiFormat?.getStringDateFormat("DD MMMM YYYY")
              : "Belum Proses"
          }
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText="Biaya"
          rightSide={CurrencyIdrFormatter.convertToRp(record.psspValue) ?? "-"}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default BiayaMurniPsspDokterAccordionItem;
