import { useContext } from "react";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";
import { Box, useTheme, Text, Spinner } from "@chakra-ui/react";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import ApprovalRencanaKunjunganContext from "../../context/ApprovalRencanaKunjunganContext";
import StatusChip from "../../../../../../components/StatusChip/StatusChip";
import Pagination from "../../../../../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import ButtonLihatDetail from "../ButtonLihatDetail";
import useSaveApprovalRealisasiUserId from "../../services/useSaveApprovalRencanaUserId";
import { ListApprovalKunjunganModelRecords } from "../../../../models/ApprovalListModel";
import useSaveApprovalRencanaMetadata from "../../services/useSaveApprovalRencanaMetadata";

const DesktopTableApprovalRencanaKunjungan: React.FC = () => {
  const {
    draftApprovalRencanaKunjunganData,
    selectedPeriode,
    metadata,
    page,
    setPage,
    limit,
    setLimit,
    draftRencanaError,
    draftRencanaLoading,
  } = useContext(ApprovalRencanaKunjunganContext);

  const navigate = useNavigate();

  const { saveData } = useSaveApprovalRealisasiUserId();
  const { saveMetadata } = useSaveApprovalRencanaMetadata();

  const theme = useTheme();

  const renderApprovalStatus = (row: ListApprovalKunjunganModelRecords) => {
    if (row.approvalPlanStatus === "Pending") {
      return <StatusChip text={"PENDING"} status={"pending"} />;
    } else if (row.approvalPlanStatus === "Reject") {
      return <StatusChip text={"REJECT"} status={"reject"} />;
    } else if (row.approvalPlanStatus === "Approved") {
      return <StatusChip text={"APPROVED"} status={"processed"} />;
    } else if (row.approvalPlanStatus === "Transfered") {
      return <StatusChip text={"TRANSFERED"} status={"transferred"} />;
    } else if (row.approvalPlanStatus === "Processed") {
      return <StatusChip text={"PROCESSED"} status={"processed"} />;
    } else if (row.approvalPlanStatus === "Request") {
      return <StatusChip text={"REQUEST"} status={"pending"} />;
    } else {
      return <Text textAlign="left">{row.approvalPlanStatus}</Text>;
    }
  };

  const handleOnLimitChange = async (updatedLimit: number) => {
    await saveMetadata({
      limit: updatedLimit,
      currentPage: 1,
    });
    setPage(1);
    setLimit(updatedLimit);
  };

  const handleOnNextPage = async () => {
    await saveMetadata({
      limit: metadata?.limit!,
      currentPage: metadata?.currentPage! + 1,
    });
    setPage(metadata?.currentPage! + 1);
  };

  const handleOnPrevPage = async () => {
    await saveMetadata({
      limit: metadata?.limit!,
      currentPage: metadata?.currentPage! - 1,
    });
    setPage(metadata?.currentPage! - 1);
  };

  const handleOnNavigateFirstPage = async () => {
    await saveMetadata({
      limit: metadata?.limit!,
      currentPage: 1,
    });
    setPage(1);
  };

  if (draftRencanaLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (draftRencanaError) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={draftRencanaError.toString()} />
      </Box>
    );
  }

  const handleLihatDetail = async (id: number, userId: number) => {
    await saveData(userId);
    navigate(
      {
        pathname: "/rencana-kunjungan-approval/" + id,
      },
      {
        state: {
          userId: userId,
        },
      }
    );
  };

  const tableColumn = [
    {
      name: "Field Force",
      cell: (row: ListApprovalKunjunganModelRecords) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.userName}
          bottomValue={row.nip}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: "Jabatan",
      // selector: (row: ListApprovalKunjunganModelRecords) => row.roleLabel,
      cell: (row: ListApprovalKunjunganModelRecords) => (
        <Text textAlign="left">{row.roleLabel}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 0.8,
    },
    {
      name: "Periode Rencana Kunjungan",
      cell: () => (
        <Text textAlign="left">
          {`${selectedPeriode?.start_period?.getStringDateFormat(
            "D MMMM YYYY"
          )} - ${selectedPeriode?.end_period?.getStringDateFormat(
            "D MMMM YYYY"
          )}`}
        </Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1.5,
    },
    {
      name: "Approval",
      cell: (row: ListApprovalKunjunganModelRecords) =>
        renderApprovalStatus(row),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },

    {
      name: "",
      cell: (row: ListApprovalKunjunganModelRecords) => (
        <Box display={"flex"} justifyContent={"end"}>
          <ButtonLihatDetail
            isDisabled={row.approvalPlanStatus === "Pending"}
            onClick={() => handleLihatDetail(row.id, row.userId)}
          />
        </Box>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
  ];

  if (
    draftApprovalRencanaKunjunganData.length > 0 &&
    draftApprovalRencanaKunjunganData[0].project
  ) {
    tableColumn.unshift({
      name: "Project",
      cell: (row: ListApprovalKunjunganModelRecords) => (
        <Text textAlign="left">{row.project!.label}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    });
  }

  return draftApprovalRencanaKunjunganData.length > 0 ? (
    <Box marginTop="24px" marginBottom="15px">
      <TableDataComponent
        data={draftApprovalRencanaKunjunganData}
        tableHeader={tableColumn}
        onSelectChange={() => {}}
        checboxOn={false}
        paginationOn={false}
        paginationLimit={[]}
      />
      <Box paddingTop={2} display={"flex"} justifyContent={"end"}>
        <Pagination
          handleOnNavigateFirstPage={handleOnNavigateFirstPage}
          handleOnLimitChange={handleOnLimitChange}
          handleOnBack={handleOnPrevPage}
          handleOnNext={handleOnNextPage}
          page={page!}
          limit={limit!}
          totalData={metadata?.totalData}
          totalPage={metadata?.totalPage}
          showCurrentPageDetail
          showLimit
          showNavigateFirstPage
        />
      </Box>
    </Box>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default DesktopTableApprovalRencanaKunjungan;
