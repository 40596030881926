import LoggedUser from "../../models/common/LoggedUser";
import { AuthenticationProvider } from "../../dataProviders/common/Authentication/AuthenticationProvider";
import ErrorResponse from "../../models/common/ErrorResponse";
import { UNKNOWN_ERROR_RESPONSE } from "../../constants/common/ErrorConstants";

const useGetUserProfile = async () => {
  try {
    const response: LoggedUser = await AuthenticationProvider.getUserProfile();
    return response;
  } catch (error: unknown) {
    if (error as ErrorResponse) {
      throw error;
    }
    throw UNKNOWN_ERROR_RESPONSE;
  }
};

export default useGetUserProfile;
