import PeriodeDataModel from "../models/PeriodeDataModel";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";

type Props = {
  options: PeriodeDataModel[];
  value: PeriodeDataModel | null;
  onChange: (data: PeriodeDataModel | null) => void;
};

/**
 *
 * TIPS: Use this component inside context
 *
 */

const DropdownPeriodeByUserId: React.FC<Props> = (props) => {
  return (
    <CustomSelect
      placeholder="Pilih Periode"
      isClearable={false}
      isSearchable={false}
      options={props.options}
      value={props.value}
      onChange={(newValue) => props.onChange(newValue)}
      getOptionLabel={(data) =>
        `${data.start_period.getStringDateFormat(
          "D MMM YYYY"
        )} - ${data.end_period.getStringDateFormat("D MMM YYYY")}`
      }
      getOptionValue={(data) =>
        `${
          data.header_id
        } ${data.start_period.getEpochDate()} ${data.end_period.getEpochDate()}`
      }
    />
  );
};

export default DropdownPeriodeByUserId;
