import { useBiayaMurniFilterContext } from "../../../contexts/BiayaMurniFilterContext";
import FilterBox from "../../../../../components/FilterBox/FilterBox";
import { useEffect, useState } from "react";
import { Wrap } from "@chakra-ui/react";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";

const BiayaMurniFilterBoxes = (): JSX.Element => {
  const {
    states: { filterValues },
  } = useBiayaMurniFilterContext();

  const [boxes, setBoxes] = useState<JSX.Element[]>([]);

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  useEffect(() => {
    const _boxes: JSX.Element[] = [];
    if (filterValues) {
      if (filterValues.periode.akhir && filterValues.periode.awal) {
        _boxes.push(
          <FilterBox
            key={1}
            title="Periode"
            content={`${filterValues.periode.awal.getStringDateFormat(
              "MMMM YYYY"
            )} - ${filterValues.periode.akhir.getStringDateFormat(
              "MMMM YYYY"
            )}`}
          />
        );
      }
      if (filterValues.project) {
        _boxes.push(
          <FilterBox
            key={2}
            title="Project"
            content={
              loggedUser?.projects.find((e) => e.value === filterValues.project)
                ?.label ?? "-"
            }
          />
        );
      }
      if (filterValues.jabatan && filterValues.pekerja) {
        _boxes.push(
          <FilterBox
            key={3}
            title="Jabatan"
            content={filterValues.jabatan.label}
          />
        );
        _boxes.push(
          <FilterBox
            key={4}
            title="Nama Pekerja"
            content={`${filterValues.pekerja.nip} - ${filterValues.pekerja.name}`}
          />
        );
      }
    }
    setBoxes(_boxes);
  }, [filterValues]);

  return <Wrap>{boxes}</Wrap>;
};

export default BiayaMurniFilterBoxes;
