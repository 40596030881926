import { useContext } from "react";
import MonitorKunjunganMonitorListContext from "../../context/MonitorKunjunganMonitorListContext";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Spinner,
  Text,
  useTheme,
} from "@chakra-ui/react";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import { MonitorKunjunganModelData } from "../../../models/MonitorKunjunganDataModel";
import HorizontalAccordionDetail from "../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";

const MobileTableListMonitorKunjungan: React.FC = () => {
  const theme = useTheme();

  const {
    monitorKunjunganData,
    loadingMonitorKunjungan,
    errorMessageMonitorKunjungan,
    currentPsspType,
  } = useContext(MonitorKunjunganMonitorListContext);

  if (loadingMonitorKunjungan) {
    return (
      <Box paddingTop="24px" paddingBottom="15px">
        <Spinner size="lg" color={theme.colors.exodus.primaryBlue} />
      </Box>
    );
  }

  if (errorMessageMonitorKunjungan) {
    return (
      <Box paddingTop="24px" paddingBottom="15px">
        <ErrorPage message={errorMessageMonitorKunjungan.toString()} />
      </Box>
    );
  }

  return monitorKunjunganData.length > 0 ? (
    <Box paddingTop="24px" paddingBottom="15px">
      <Accordion allowToggle>
        {monitorKunjunganData.map((item: MonitorKunjunganModelData, index) => (
          <AccordionItem key={index} marginBottom="16px" border="none">
            <AccordionButton display="flex" justifyContent="space-between">
              <Box textAlign="left">
                <Text
                  fontSize={14}
                  fontWeight="bold"
                  color={theme.colors.exodus.primaryBlue}
                >
                  {item.outletName}
                </Text>
                <Text fontSize={14}>{item.outletCode}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel paddingX="16px">
              {item.project && (
                <HorizontalAccordionDetail
                  leftText="Project"
                  rightSide={item.project.label}
                  size="small"
                />
              )}
              <HorizontalAccordionDetail
                isRowColored
                leftText="Customer"
                rightSide={`${item.customerName} (${item.customerCode})`}
                size="small"
              />
              <HorizontalAccordionDetail
                leftText="Spesialis"
                rightSide={item.spesialis}
                size="small"
              />
              <HorizontalAccordionDetail
                isRowColored
                leftText="Jabatan"
                rightSide={item.jabatan}
                size="small"
              />
              {currentPsspType === "pssp-aktif" && (
                <HorizontalAccordionDetail
                  leftText="Periode PSSP"
                  rightSide={`${item.startMonth.getStringDateFormat(
                    "D MMM YYYY"
                  )} - ${item.endMonth.getStringDateFormat("D MMM YYYY")}`}
                  size="small"
                />
              )}
              {currentPsspType === "pssp-aktif" && (
                <HorizontalAccordionDetail
                  isRowColored
                  leftText="Dana Keluar"
                  rightSide={CurrencyIdrFormatter.convertToRp(item.psspNominal)}
                  size="small"
                />
              )}
              <HorizontalAccordionDetail
                leftText="Total Rencana Kunjungan"
                rightSide={`${item.totalRencana} Kunjungan, ${item.totalRealisasi} Realisasi`}
                size="small"
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  ) : (
    <Box paddingTop="24px" paddingBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default MobileTableListMonitorKunjungan;
