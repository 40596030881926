import { useEffect, useState } from "react";
import SalesOutletSavedPaginationMetadata from "../../../../models/SalesOutletSavedPaginationMetadata";
import SalesOutletListFilterModel from "../../models/SalesOutletListFilterModel";
import ApiGetListSalesOutletResponse from "../../models/apiResponses/ApiGetListSalesOutletResponse";
import ErrorResponse from "../../../../../../../models/common/ErrorResponse";
import SalesOutletProvider from "../../../../dataProviders/SalesOutletProvider";
import IsTypeOfErrorResponse from "../../../../../../../utils/IsTypeOfErrorResponse";
import { COMMON_PAGINATION_LIMIT_CHOICES } from "../../../../../../../constants/components/PaginationConstants";
import DateExodus from "../../../../../../../models/DateExodus";

enum LoadingType {
  initialize,
  changeFilter,
  setPage,
  setLimit,
  getList,
}

const useList = () => {
  const [filterValues, setFilterValues] =
    useState<SalesOutletListFilterModel | null>(null);
  const [metadataValues, setMetadataValues] =
    useState<SalesOutletSavedPaginationMetadata | null>(null);

  const [data, setData] = useState<ApiGetListSalesOutletResponse | null>(null);
  const [loading, setLoading] = useState<LoadingType | null>(null);
  const [error, setError] = useState<ErrorResponse | null>(null);

  const _saveMetadata = async (
    newMetadata: SalesOutletSavedPaginationMetadata
  ) => {
    try {
      await SalesOutletProvider.localDb.listFeature.metadata.save(newMetadata);
      setMetadataValues(newMetadata);
    } catch (errorMetadata) {
      throw errorMetadata;
    }
  };

  useEffect(() => {
    if (loading === LoadingType.getList) {
      _getList();
    }
  }, [loading]);

  const _getList = async () => {
    try {
      const listData = await SalesOutletProvider.getList({
        project: filterValues?.project,
        startDate: filterValues?.periode.awal,
        endDate: filterValues?.periode.akhir,
        userId: filterValues?.pekerja?.id,
        outlets: filterValues?.outlets,
        sector: filterValues?.sector,
        zone: filterValues?.zona,
        page: metadataValues?.page,
        limit: metadataValues?.limit,
      });
      setData(listData);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error as ErrorResponse);
      }
    } finally {
      setLoading(null);
    }
  };

  const setPage = async (value: "next" | "prev" | "first") => {
    setError(null);
    setLoading(LoadingType.setPage);
    try {
      let page: number = 0;
      if (metadataValues) {
        if (value === "next") {
          page = metadataValues.page + 1;
        }
        if (value === "prev") {
          page = metadataValues.page - 1;
        }
        if (value === "first") {
          page = 1;
        }
        await _saveMetadata(
          new SalesOutletSavedPaginationMetadata({
            limit: metadataValues.limit,
            page: page,
          })
        );
      }
      setLoading(LoadingType.getList);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error as ErrorResponse);
      }
      setLoading(null);
    }
  };

  const setLimit = async (limit: number) => {
    setError(null);
    setLoading(LoadingType.setLimit);
    try {
      if (metadataValues) {
        await _saveMetadata(
          new SalesOutletSavedPaginationMetadata({
            limit: limit,
            page: 1,
          })
        );
      }
      setLoading(LoadingType.getList);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error as ErrorResponse);
      }
      setLoading(null);
    }
  };

  const changeFilter = async (newFilterValues: SalesOutletListFilterModel) => {
    setError(null);
    setLoading(LoadingType.changeFilter);
    try {
      if (metadataValues) {
        await _saveMetadata(
          new SalesOutletSavedPaginationMetadata({
            limit: metadataValues.limit,
            page: 1,
          })
        );
      }
      await SalesOutletProvider.localDb.listFeature.filter.save(
        newFilterValues
      );
      setFilterValues(newFilterValues);
      setLoading(LoadingType.getList);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error as ErrorResponse);
      }
      setLoading(null);
    }
  };

  const defaultValues = {
    metadata: new SalesOutletSavedPaginationMetadata({
      page: 1,
      limit: COMMON_PAGINATION_LIMIT_CHOICES[0].value,
    }),
    filter: new SalesOutletListFilterModel({
      periode: {
        awal: new DateExodus(),
        akhir: new DateExodus(),
      },
    }),
  };

  useEffect(() => {
    const initialize = async () => {
      setError(null);
      setLoading(LoadingType.initialize);
      try {
        const dbMetadata: SalesOutletSavedPaginationMetadata | null =
          await SalesOutletProvider.localDb.listFeature.metadata.get();

        if (dbMetadata) {
          setMetadataValues(dbMetadata);
        } else {
          setMetadataValues(defaultValues.metadata);
        }

        const dbFilter: SalesOutletListFilterModel | null =
          await SalesOutletProvider.localDb.listFeature.filter.get();
        if (dbFilter) {
          setFilterValues(dbFilter);
        } else {
          setFilterValues(defaultValues.filter);
        }
        setLoading(LoadingType.getList);
      } catch (error) {
        if (IsTypeOfErrorResponse(error)) {
          setError(error as ErrorResponse);
        }
        setLoading(null);
      }
    };

    initialize();
  }, []);

  return {
    data,
    filterValues,
    metadataValues,
    loading,
    error,
    changeFilter,
    setPage,
    setLimit,
  };
};

export default useList;

export type { LoadingType };
