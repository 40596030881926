import DateExodus from "../../../../../../models/DateExodus";
import { ProjectEnum } from "../../../../../../models/common/Project";

class SalesOutletDetailFilterModel {
  project?: ProjectEnum;
  outletId?: number;
  periode: {
    awal?: DateExodus;
    akhir?: DateExodus;
  };
  userId?: number;

  constructor({
    project,
    outletId,
    periode,
    userId,
  }: {
    project?: ProjectEnum;
    outletId?: number;
    periode: {
      awal?: DateExodus;
      akhir?: DateExodus;
    };
    userId?: number;
  }) {
    this.project = project;
    this.outletId = outletId;
    this.periode = periode;
    this.userId = userId;
  }

  public static fromJson(json: any) {
    return new SalesOutletDetailFilterModel({
      project: json.project ?? undefined,
      outletId: json.outlet_id,
      periode: {
        awal: json.periode.awal
          ? DateExodus.fromBackend(json.periode.awal)
          : undefined,
        akhir: json.periode.akhir
          ? DateExodus.fromBackend(json.periode.akhir)
          : undefined,
      },
      userId: json.user_id,
    });
  }

  toJson() {
    return {
      project: this.project ?? null,
      outlet_id: this.outletId,
      periode: {
        awal:
          new DateExodus(this.periode.awal?.getEpochStartOfMonth()).toJSON() ??
          null,
        akhir:
          new DateExodus(this.periode.akhir?.getEpochEndOfMonth()).toJSON() ??
          null,
      },
      user_id: this.userId,
    };
  }
}

export default SalesOutletDetailFilterModel;
