import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  SimpleGrid,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { KlaimSalesListModelRecords } from "../../models/KlaimSalesListModel";
import StatusChip from "../../../../components/StatusChip/StatusChip";
import StackedColumnMonitorKlaimSales from "./StackedColumnMonitorKlaimSales";
import { getStatusColor } from "../../utils/getStatusColor";

interface KlaimCardProps {
  item: KlaimSalesListModelRecords;
  onClick: () => void;
}

const MonitorKlaimSalesListCard: React.FC<KlaimCardProps> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Card
      variant={"elevated"}
      width={isDesktopView ? "calc(50% - 30px)" : "100%"}
      m={3}
      bgColor={theme.colors.exodus.background}
      textAlign={"left"}
      boxShadow="xl"
      cursor={"pointer"}
      onClick={props.onClick}
    >
      <CardHeader>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Text
              fontSize={20}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
            >
              {props.item.userName}
            </Text>
            <Text
              fontSize={16}
              fontWeight={600}
              color={theme.colors.exodus.darkGrey}
            >
              CLAIM-{props.item.id} | {props.item.createdAtUi}
            </Text>
          </Box>
          <Box paddingTop={"30px"}>
            <StatusChip
              status={getStatusColor(props.item.status)}
              text={props.item.status.toUpperCase()}
            />
          </Box>
        </Box>
      </CardHeader>
      <Divider
        color={theme.colors.exodus.primaryBlue}
        width={"95%"}
        mx="auto"
      />
      <CardBody>
        <SimpleGrid columns={2} gap={5}>
          <Box>
            <Box>
              <StackedColumnMonitorKlaimSales
                topValue={"Outlet Panel"}
                bottomValue={props.item.claimOutletName}
              />
            </Box>
            <Box paddingTop={"10px"}>
              <StackedColumnMonitorKlaimSales
                topValue={"Outlet Tujuan"}
                bottomValue={props.item.targetOutletName}
              />
            </Box>
          </Box>
          <Box>
            <Box>
              <StackedColumnMonitorKlaimSales
                topValue={"Kode Outlet Panel"}
                bottomValue={props.item.claimOutletCode}
              />
            </Box>
            <Box paddingTop={"10px"}>
              <StackedColumnMonitorKlaimSales
                topValue={"Kode Outlet Tujuan"}
                bottomValue={props.item.targetOutletCode}
              />
            </Box>
          </Box>
        </SimpleGrid>
      </CardBody>
    </Card>
  );
};

export default MonitorKlaimSalesListCard;
