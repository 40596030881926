import { createContext, useContext } from "react";
import useStandarisasiOrManager from "../services/useStandarisasiOrManager";
import { BiayaMurniOrCode } from "../../../models/BiayaMurniOrCode";
import ErrorResponse from "../../../../../models/common/ErrorResponse";

type _Value = {
  states: {
    dataArr: (BiayaMurniOrCode[] | null)[];
    isLoadingArr: boolean[];
    errorArr: (ErrorResponse | null)[];
    isLoadingInit: boolean;
  };
  actions: {
    fetchOneByIndex: Function;
  };
};

const StandarisasiOrManagerContext = createContext<_Value>({
  states: {
    dataArr: [],
    isLoadingArr: [],
    errorArr: [],
    isLoadingInit: true,
  },
  actions: {
    fetchOneByIndex: () => {},
  },
});

function useStandarisasiOrManagerContext(): _Value {
  return useContext(StandarisasiOrManagerContext);
}

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{
  standarisasiIds: number[];
}>;

const StandarisasiOrManagerContextProvider = ({
  children,
  standarisasiIds,
}: _InternalProps): JSX.Element => {
  const { fetchOneByIndex, dataArr, isLoadingArr, errorArr, isLoadingInit } =
    useStandarisasiOrManager(standarisasiIds);

  const value: _Value = {
    states: { dataArr, isLoadingArr, errorArr, isLoadingInit },
    actions: { fetchOneByIndex },
  };

  return (
    <StandarisasiOrManagerContext.Provider value={value}>
      {children}
    </StandarisasiOrManagerContext.Provider>
  );
};

export {
  StandarisasiOrManagerContextProvider,
  useStandarisasiOrManagerContext,
};
