/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import RencanaKunjunganProvider from "../../dataProviders/RencanaKunjunganProvider";

type CallResultHandler = {
  onSuccess: (data: string) => void;
  onError: (data: string) => void;
};

/**
 * HOW TO USE:
 * const { functionName } = hookName();
 *
 * functionName(data_1, data_2, data_3, {
 *    onSuccess: (data) => {
 *        Do Something (if you don't want to use data, you can ignore it by () => {} or (_) => {})
 *    },
 *    onError: (data) => {
 *        Do Something (if you don't want to use data, you can ignore it by () => {} or (_) => {})
 *    },
 * })
 */

const useDeleteDraftRencanaKunjungan = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const deleteDraftRencanaKunjungan = async (
    id: number,
    handler: CallResultHandler
  ) => {
    try {
      setErrorMessage(null);
      setLoading(true);

      const response: string | null =
        await RencanaKunjunganProvider.deleteDraftRencanaKunjungan({
          id: id,
        });

      setSuccessMessage(response);
      setLoading(false);

      handler.onSuccess(response!);
    } catch (error: any) {
      setSuccessMessage(null);
      setErrorMessage(error);
      setLoading(false);
      handler.onError(error);
    }
  };

  return { deleteDraftRencanaKunjungan, successMessage, loading, errorMessage };
};

export default useDeleteDraftRencanaKunjungan;
