import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
  Center,
  useTheme,
  useMediaQuery,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import DateExodus from "../../../../../../../../../../models/DateExodus";
import SalesOutletListFilterModel from "../../../../../models/SalesOutletListFilterModel";
import FilterByPeriode from "./fields/FilterByPeriode";
import FilterBySector from "./fields/FilterBySector";
import FilterByOutlets from "./fields/FilterByOutlets";
import FilterByZona from "./fields/FilterByZona";
import FilterByPekerja from "./fields/FilterByPekerja";
import FilterByJabatan from "./fields/FilterByJabatan";
import { useSalesOutletListContext } from "../../../../../contexts/SalesOutletListContext";
import FilterByProject from "./fields/FilterByProject";

const SalesOutletFilterModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const toast = useToast();

  const {
    states: { filterValues },
    actions: { changeFilter },
  } = useSalesOutletListContext();

  const methods = useForm<SalesOutletListFilterModel>({
    defaultValues: new SalesOutletListFilterModel({
      project: filterValues?.project ?? undefined,
      jabatan: filterValues?.jabatan ?? undefined,
      pekerja: filterValues?.pekerja ?? undefined,
      zona: filterValues?.zona ?? undefined,
      outlets: filterValues?.outlets ?? undefined,
      sector: filterValues?.sector ?? undefined,
      periode: {
        awal: filterValues?.periode.awal ?? new DateExodus(),
        akhir: filterValues?.periode.akhir ?? new DateExodus(),
      },
    }),
  });

  const handleClearAll = () => {
    methods.setValue("project", undefined);
    methods.setValue("jabatan", undefined);
    methods.setValue("pekerja", undefined);
    methods.setValue("zona", undefined);
    methods.setValue("sector", undefined);
    methods.setValue("outlets", undefined);
    methods.setValue("periode", {
      awal: new DateExodus(),
      akhir: new DateExodus(),
    });
    handleOnSubmitFilter();
  };

  const handleOnSubmitFilter = () => {
    const updatedFilter = new SalesOutletListFilterModel({
      project: methods.getValues("project"),
      jabatan: methods.getValues("jabatan"),
      pekerja: methods.getValues("pekerja"),
      zona: methods.getValues("zona"),
      outlets: methods.getValues("outlets"),
      sector: methods.getValues("sector"),
      periode: {
        awal: methods.getValues("periode.awal"),
        akhir: methods.getValues("periode.akhir"),
      },
    });
    if (
      updatedFilter.jabatan &&
      updatedFilter.pekerja === undefined &&
      updatedFilter.zona === undefined
    ) {
      toast({
        title: "Gagal Mengubah Filter",
        description:
          "Jika filter Jabatan diisi, filter Cari Nama atau filter Zona (salah satu) harus diisi",
        status: "error",
      });
      return;
    }
    changeFilter(updatedFilter);
    onClose();
  };

  const setAllFormToSaved = (values: SalesOutletListFilterModel | null) => {
    if (values) {
      methods.setValue("project", values.project);
      methods.setValue("jabatan", values.jabatan);
      methods.setValue("pekerja", values.pekerja);
      methods.setValue("zona", values.zona);
      methods.setValue("outlets", values.outlets);
      methods.setValue("sector", values.sector);
      methods.setValue("periode.awal", values.periode.awal);
      methods.setValue("periode.akhir", values.periode.akhir);
    }
  };

  const openFilterId = useRef<number>(1);
  useEffect(() => {
    if (isOpen) {
      setAllFormToSaved(filterValues);
    } else {
      openFilterId.current = openFilterId.current + 1;
    }
  }, [isOpen, filterValues]);

  return (
    <Modal
      key={openFilterId.current}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      autoFocus={false}
      size={isDesktopView ? "3xl" : "sm"}
    >
      <FormProvider {...methods}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center
              sx={{
                justifyContent: "space-between",
                display: "flex",
                gap: 10,
              }}
            >
              <Text color={theme.colors.exodus.primaryBlue} fontSize={24}>
                Filter Data
              </Text>
              <Button
                colorScheme="buttonSecondary"
                variant={"link"}
                onClick={handleClearAll}
              >
                Clear All
              </Button>
            </Center>
          </ModalHeader>
          <ModalBody>
            <Stack
              sx={{
                justifyContent: "space-between",
                display: "flex",
                gap: 2,
              }}
            >
              <FilterByProject />
              <Stack gap={4} direction={isMobile ? "column" : "row"}>
                <Box width={isMobile ? "100%" : "40%"}>
                  <FilterByJabatan />
                </Box>
                <Box width={isMobile ? "100%" : "60%"}>
                  <FilterByPekerja />
                </Box>
              </Stack>
              <FilterByZona />
              <FilterByOutlets />
              <FilterBySector />
              <FilterByPeriode />
            </Stack>
          </ModalBody>
          <ModalFooter
            gap={4}
            flexDirection={isMobile ? "column-reverse" : undefined}
          >
            <Button
              colorScheme={`buttonSecondary`}
              onClick={onClose}
              sx={{ width: "100% " }}
              variant={"outline"}
            >
              Cancel
            </Button>
            <Button
              colorScheme={`buttonPrimary`}
              sx={{ width: "100%" }}
              onClick={handleOnSubmitFilter}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};

export default SalesOutletFilterModal;
