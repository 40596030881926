import { useContext } from "react";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Spinner,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import HorizontalAccordionDetail from "../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import ApprovalRencanaKunjunganContext from "../../context/ApprovalRencanaKunjunganContext";
import StatusChip from "../../../../../../components/StatusChip/StatusChip";
import Pagination from "../../../../../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import ButtonLihatDetail from "../ButtonLihatDetail";
import useSaveApprovalRealisasiUserId from "../../services/useSaveApprovalRencanaUserId";
import useSaveApprovalRencanaMetadata from "../../services/useSaveApprovalRencanaMetadata";
import ExportExcelButton from "../../../../../../components/CustomButtons/ExportExcelButton";
import useExportApprovalKunjungan from "../../services/useExportApprovalRencanaKunjungan";

const MobileAccordionApprovalRencanaKunjungan: React.FC = () => {
  const {
    draftApprovalRencanaKunjunganData,
    draftRencanaLoading,
    draftRencanaError,
    selectedPeriode,
    metadata,
    page,
    limit,
    setPage,
    setLimit,
  } = useContext(ApprovalRencanaKunjunganContext);

  const theme = useTheme();

  const navigate = useNavigate();
  const { exportData } = useExportApprovalKunjungan();

  const { saveData } = useSaveApprovalRealisasiUserId();
  const { saveMetadata } = useSaveApprovalRencanaMetadata();

  const renderApprovalStatus = (item: string) => {
    if (item === "Pending") {
      return <StatusChip text={"PENDING"} status={"pending"} />;
    } else if (item === "Reject") {
      return <StatusChip text={"REJECT"} status={"reject"} />;
    } else if (item === "Approved") {
      return <StatusChip text={"APPROVED"} status={"processed"} />;
    } else if (item === "Transfered") {
      return <StatusChip text={"TRANSFERED"} status={"transferred"} />;
    } else if (item === "Processed") {
      return <StatusChip text={"PROCESSED"} status={"processed"} />;
    } else if (item === "Request") {
      return <StatusChip text={"REQUEST"} status={"pending"} />;
    } else {
      return <Text textAlign="left">{item}</Text>;
    }
  };

  const handleOnLimitChange = async (updatedLimit: number) => {
    await saveMetadata({
      limit: updatedLimit,
      currentPage: 1,
    });
    setPage(1);
    setLimit(updatedLimit);
  };

  const handleOnNextPage = async () => {
    await saveMetadata({
      limit: metadata?.limit!,
      currentPage: metadata?.currentPage! + 1,
    });
    setPage(metadata?.currentPage! + 1);
  };

  const handleOnPrevPage = async () => {
    await saveMetadata({
      limit: metadata?.limit!,
      currentPage: metadata?.currentPage! - 1,
    });
    setPage(metadata?.currentPage! - 1);
  };

  const handleOnNavigateFirstPage = async () => {
    await saveMetadata({
      limit: metadata?.limit!,
      currentPage: 1,
    });
    setPage(1);
  };

  if (draftRencanaLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (draftRencanaError) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={draftRencanaError.toString()} />
      </Box>
    );
  }

  const handleLihatDetail = async (id: number, userId: number) => {
    await saveData(userId);
    navigate(
      {
        pathname: "/rencana-kunjungan-approval/" + id,
      },
      {
        state: {
          userId: userId,
        },
      }
    );
  };

  return draftApprovalRencanaKunjunganData.length > 0 ? (
    <Box marginTop="24px" marginBottom="15px">
      <Accordion allowToggle>
        {draftApprovalRencanaKunjunganData.map((item: any) => (
          <AccordionItem key={item.id} border="none" marginBottom="16px">
            <AccordionButton display="flex" justifyContent="space-between">
              <Box textAlign="left">
                <Text
                  fontSize={14}
                  fontWeight="bold"
                  color={theme.colors.exodus.primaryBlue}
                >
                  {item.userName}
                </Text>
                <Text fontSize={12}>{item.nip}</Text>
              </Box>
              <Box display={"flex"} justifyContent={"end"} gap={2}>
                {renderApprovalStatus(item.approvalPlanStatus)}
                <AccordionIcon />
              </Box>
            </AccordionButton>
            <AccordionPanel marginX="16px">
              {item.project && (
                <HorizontalAccordionDetail
                  leftText="Project"
                  rightSide={item.project.label}
                  size="small"
                  isRowColored
                />
              )}
              <HorizontalAccordionDetail
                leftText="Jabatan"
                rightSide={`${item.roleLabel}`}
                size="small"
              />
              <HorizontalAccordionDetail
                leftText="Periode Rencana Kunjungan"
                rightSide={`${selectedPeriode?.start_period?.getStringDateFormat(
                  "D MMMM YYYY"
                )} - ${selectedPeriode?.end_period?.getStringDateFormat(
                  "D MMMM YYYY"
                )}`}
                size="small"
                isRowColored
              />

              <Box display={"flex"} justifyContent={"end"}>
                <ButtonLihatDetail
                  isDisabled={item.approvalPlanStatus === "Pending"}
                  onClick={() => handleLihatDetail(item.id, item.userId)}
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Box paddingTop={2} display={"flex"} justifyContent={"center"}>
        <Pagination
          handleOnNavigateFirstPage={handleOnNavigateFirstPage}
          handleOnLimitChange={handleOnLimitChange}
          handleOnBack={handleOnPrevPage}
          handleOnNext={handleOnNextPage}
          page={page!}
          limit={limit!}
          totalData={metadata?.totalData}
          totalPage={metadata?.totalPage}
          showCurrentPageDetail
          showLimit
          showNavigateFirstPage
        />
      </Box>
      <Stack
        spacing={1}
        sx={{
          position: "sticky",
          pt: 2,
          pb: 2,
          bottom: 0,
          backgroundColor: theme.colors.exodus.background,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ExportExcelButton
          onClick={() => exportData()}
          isDisabled={draftApprovalRencanaKunjunganData.length === 0}
        />
      </Stack>
    </Box>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default MobileAccordionApprovalRencanaKunjungan;
