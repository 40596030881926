import { FormControl, FormErrorMessage, useTheme } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { DraftRencanaKunjunganCreateBody } from "../../models/CreateDraftRencanaKunjunganModel";
import DraftRencanaKunjunganUpdateBody from "../../models/UpdateDraftRencanaKunjunganModel";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import { JabatanCustomer } from "../../../../constants/JabatanCustomer";

type Props = {
  jabatanData: JabatanCustomer[];
  selectedJabatan: JabatanCustomer | null;
  onChange: (data: JabatanCustomer) => void;
};

const RencanaKunjunganJabatanFormField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<
    DraftRencanaKunjunganCreateBody | DraftRencanaKunjunganUpdateBody
  >();

  return (
    <FormControl width="100%" isInvalid={!!errors.position_customer}>
      <FormInputLabel title="Jabatan" />
      <CustomSelect
        {...register("position_customer", {
          required: "Required",
        })}
        isSearchable={false}
        isClearable={false}
        options={props.jabatanData}
        getOptionValue={(data: JabatanCustomer) => data.jabatan_customer}
        getOptionLabel={(data: JabatanCustomer) => data.jabatan_customer}
        onChange={(data: JabatanCustomer) => {
          setValue("position_customer", data.jabatan_customer);
          props.onChange(data);
        }}
        value={props.selectedJabatan}
        placeholder="Jabatan"
      />
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.position_customer &&
          (errors.position_customer.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default RencanaKunjunganJabatanFormField;
