import { useState } from "react";
import { ApproveSalesTargetParams } from "../dataProviders/SalesTargetParamType";
import SalesTargetProvider from "../dataProviders/SalesTargetProvider";
import ErrorResponse from "../../../../models/common/ErrorResponse";

type ApproveSalesTargetHandler = {
  onSuccess: () => void;
  onError: (error: ErrorResponse) => void;
};

const useApproveSalesTarget = () => {
  const [loadingApprove, setLoading] = useState<boolean>(false);
  const [errorMessageApprove, setErrorMessage] = useState<ErrorResponse | null>(
    null
  );

  const approveSalesTarget = async (
    data: ApproveSalesTargetParams,
    handler: ApproveSalesTargetHandler
  ) => {
    try {
      setErrorMessage(null);
      setLoading(true);

      await SalesTargetProvider.approveSalesTarget(data);

      setLoading(false);
      handler.onSuccess();
    } catch (error) {
      if (error as ErrorResponse) {
        setErrorMessage(error as ErrorResponse);
      }
      setLoading(false);
      handler.onError(error as ErrorResponse);
    }
  };

  return { approveSalesTarget, loadingApprove, errorMessageApprove };
};

export default useApproveSalesTarget;
