import {
  HStack,
  Spacer,
  VStack,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import useTitle from "../../../../../../../services/useTitle";
import PageTitle from "../../../../../../../components/PageTitle/PageTitle";
import BackButton from "../desktop/parts/BackButton";
import { BAD_LINK_TEXT } from "../../../../constants/constants";
import ErrorPage from "../../../../../../../components/ErrorPage/ErrorPage";

const SalesOutletDetailEmptyId = () => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  useTitle("Sales Outlet Detail");

  if (isMobile) {
    return (
      <VStack alignItems={"stretch"} flex={1}>
        <PageTitle label={"Sales Outlet Detail"} />
        <ErrorPage message={BAD_LINK_TEXT} />
      </VStack>
    );
  } else {
    return (
      <VStack alignItems={"stretch"} flex={1}>
        <HStack>
          <PageTitle label={"Sales Outlet Detail"} />
          <Spacer />
          <BackButton />
        </HStack>
        <ErrorPage message={BAD_LINK_TEXT} />
      </VStack>
    );
  }
};

export default SalesOutletDetailEmptyId;
