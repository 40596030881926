import { Box } from "@chakra-ui/react";
import React from "react";
import DistributorLastUpdateCard from "./DistributorLastUpdateCard";
import BiayaMurniSalesRatioCard from "./BiayaMurniSalesRatioCard";
import BudgetNasionalCard from "./BudgetNasionalCard";
import TargetSalesCard from "./TargetSalesCard";
import { ROLE_ID_ASM, ROLE_ID_SUPER_ADMIN } from "../../../constants/common/RoleConstants";
import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";

const MobileDashboardComponent: React.FC = () => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );
  return (
    <Box display={"flex"} flexDirection={"column"} gap={4}>
      <DistributorLastUpdateCard />
      <BiayaMurniSalesRatioCard />
      <BudgetNasionalCard />
      {(loggedUser?.userRoleId === ROLE_ID_ASM ||
        loggedUser?.userRoleId === ROLE_ID_SUPER_ADMIN) && <TargetSalesCard />}
    </Box>
  );
};

export default MobileDashboardComponent;
