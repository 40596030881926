import { useContext, useState } from "react";
import {
  Box,
  Button,
  Flex,
  useMediaQuery,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import DropdownPeriodeByUserId from "../../../../components/DropdownPeriodeByUserId";
import InputRencanaKunjunganContext from "../../context/InputRencanaKunjunganContext";
import { MdAdd } from "react-icons/md";
import DialogInputRencanaKunjungan from "../DialogInputRencanaKunjungan";
import { DraftRencanaKunjunganCreateBody } from "../../models/CreateDraftRencanaKunjunganModel";
import { useNavigate } from "react-router-dom";
import useCreateDraftRencanaKunjungan from "../../services/useCreateDraftRencanaKunjungan";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../redux/hooks";
import DialogMinimumVisitNotAchieved from "../DialogMinimumVisitNotAchieved";
import DialogKonfimasiSubmitRencanaKunjungan from "../DialogKonfimasiSubmitRencanaKunjungan";
import DateExodus from "../../../../../../models/DateExodus";
import { CommonStatus } from "../../../../../../models/common/Status";

const DesktopInputRencanaKunjunganToolbar: React.FC = () => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const theme = useTheme();
  const toast = useToast();
  const navigate = useNavigate();

  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const {
    periodeData,
    selectedPeriode,
    setSelectedPeriode,
    minimalVisitData,
    key,
    setKey,
  } = useContext(InputRencanaKunjunganContext);

  const [openDialogInput, setOpenDialogInput] = useState<boolean>(false);
  const [openDescriptionDialog, setOpenDescriptionDialog] =
    useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const { createDraftRencanaKunjungan, loading: loadingCreateDraft } =
    useCreateDraftRencanaKunjungan();

  const handleSubmitCreate = (value: DraftRencanaKunjunganCreateBody) => {
    const startPeriod = JSON.stringify(selectedPeriode?.start_period);
    const endPeriod = JSON.stringify(selectedPeriode?.end_period);

    const submittedData: DraftRencanaKunjunganCreateBody = {
      user_id: loggedUser!.userId,
      position_customer: value.position_customer,
      start_date: parseInt(startPeriod),
      end_date: parseInt(endPeriod),
      outlet_id: value.outlet_id,
      customer_id: value.customer_id,
      visit_header_id: selectedPeriode!.header_id,
      purpose:
        value.purpose === "Lain-lain (diisi sendiri)"
          ? value.tujuan_lain!.trim()
          : value.purpose,
      total_visit: parseInt(value.total_visit.toString()),
    };

    createDraftRencanaKunjungan(submittedData, {
      onSuccess: () => {
        toast({
          title: "Berhasil Menginput Draft Rencana Kunjungan",
          status: "success",
        });
        setOpenDialogInput(false);
        navigate("/rencana-kunjungan", { replace: true });
        setKey(key + 1);
      },
      onError: (errorMessage: string) => {
        toast({
          title: `Gagal Menginput Draft Rencana Kunjungan`,
          description: `${errorMessage}`,
          status: "error",
        });
      },
    });
  };

  const handleClickSubmit = () => {
    if (selectedPeriode && minimalVisitData) {
      if (selectedPeriode.total_visit < minimalVisitData.minimalvisit) {
        setOpenDescriptionDialog(true);
      } else {
        setOpenConfirmationDialog(true);
      }
    }
  };

  const disableButtonCreateCondition = (): boolean => {
    if (!selectedPeriode) return true;

    const dateNow = new DateExodus();
    const isDateNowGreaterThanSelectedStartPeriod: boolean =
      parseInt(JSON.stringify(dateNow)) >
      parseInt(JSON.stringify(selectedPeriode.start_period));

    if (selectedPeriode.status_plan !== CommonStatus.Pending) {
      return true;
    } else if (isDateNowGreaterThanSelectedStartPeriod) {
      return true;
    } else {
      return false;
    }
  };

  const disableButtonSubmitCondition = (): boolean => {
    if (!selectedPeriode) return true;

    if (selectedPeriode.header_id === 0) return true;

    if (selectedPeriode.total_visit === 0) return true;

    const dateNow = new DateExodus();
    const isDateNowGreaterThanSelectedStartPeriod: boolean =
      parseInt(JSON.stringify(dateNow)) >
      parseInt(JSON.stringify(selectedPeriode.start_period));

    if (selectedPeriode.status_plan !== CommonStatus.Pending) {
      return true;
    } else if (isDateNowGreaterThanSelectedStartPeriod) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Flex justify="space-between" paddingY="32px" gap={2}>
        <Box width={isDesktopView ? "301px" : "250px"}>
          <DropdownPeriodeByUserId
            options={periodeData}
            value={selectedPeriode}
            onChange={setSelectedPeriode}
          />
        </Box>
        <Flex gap={2} flexWrap="wrap" justify="end">
          <Button
            variant="outline"
            colorScheme="buttonSecondary"
            leftIcon={<MdAdd />}
            width={isDesktopView ? "180px" : "150px"}
            onClick={() => setOpenDialogInput(true)}
            isDisabled={disableButtonCreateCondition()}
          >
            Input
          </Button>
          <Button
            colorScheme="buttonPrimary"
            width={isDesktopView ? "180px" : "150px"}
            onClick={handleClickSubmit}
            isDisabled={disableButtonSubmitCondition()}
          >
            Submit
          </Button>
        </Flex>
      </Flex>

      <DialogInputRencanaKunjungan
        open={openDialogInput}
        onClose={() => setOpenDialogInput(false)}
        onSubmit={handleSubmitCreate}
        isLoadingSubmit={loadingCreateDraft}
      />

      <DialogMinimumVisitNotAchieved
        openDescriptionDialog={openDescriptionDialog}
        onClose={() => setOpenDescriptionDialog(false)}
      />

      <DialogKonfimasiSubmitRencanaKunjungan
        openConfirmationDialog={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      />
    </>
  );
};

export default DesktopInputRencanaKunjunganToolbar;
