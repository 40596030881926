import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { GetExcelSalesDistributorData } from "../model/GetExcelSalesDistributorModel";
import { isEmpty } from "lodash";
import {
  getOutletSectorType,
  getProjectType,
} from "../utils/SalesDistributorUtils";
import SalesDistributorFilterValue from "../model/SalesDistributorFilterValues";
import SalesDistributorDataProvider from "../dataProvider/SalesDistributorDataProvider";
import DateExodus from "../../../../models/DateExodus";
import { useToast } from "@chakra-ui/react";

const useExportDistributorHeader = (
  filterValues: SalesDistributorFilterValue | null
) => {
  const [exportedData, setExportedData] =
    React.useState<GetExcelSalesDistributorData | null>();
  const [isError, setIsError] = React.useState<boolean>(false);
  const [loadingExportHeader, setLoadingExportHeader] =
    React.useState<boolean>(false);
  const [totalHeaderLength, setTotalHeaderLength] = React.useState<number>(-1);
  const [totalDetailLength, setTotalDetailLength] = React.useState<number>(-1);

  const workbook = new ExcelJS.Workbook();
  const worksheetHeader = workbook.addWorksheet();
  const worksheetDetail = workbook.addWorksheet();
  const worksheetFilter = workbook.addWorksheet();
  const toast = useToast();

  const insertNullValueInRowCell = (row: ExcelJS.Row, cell: number) => {
    row.getCell(cell).value = "-";
  };

  const insertDataToCell = (value: any, row: ExcelJS.Row, cell: number) => {
    let insertedValue: string | undefined | null = value;
    if (typeof value === "number") {
      insertedValue = value.toString();
    }

    if (!isEmpty(insertedValue)) {
      row.getCell(cell).value = insertedValue;
    } else {
      insertNullValueInRowCell(row, cell);
    }
  };

  const getExportedData = async () => {
    if (filterValues?.namaOutlet) {
      try {
        const result =
          await SalesDistributorDataProvider.getListAllSalesDistributor({
            start_date: filterValues?.periodeAwal.getEpochDate(),
            end_date: filterValues?.periodeAkhir.getEpochDate(),
            outlet_id: filterValues?.namaOutlet?.outletId,
            distributor_id: filterValues?.namaDistributor?.id,
            sector: filterValues?.sector?.value,
            no_invoice: filterValues?.nomorFaktur,
            project: filterValues?.projectAvailable?.value,
          });

        setIsError(false);
        setExportedData(result!.data);
      } catch (error: any) {
        setIsError(true);
      }
    }
  };

  const handleExportHeaderSheet = () => {
    worksheetHeader.name = "Header";
    worksheetHeader.columns = [
      { key: "A", width: 15 },
      { key: "B", width: 20 },
      { key: "C", width: 30 },
      { key: "D", width: 15 },
      { key: "E", width: 15 },
      { key: "F", width: 30 },
      { key: "G", width: 20 },
      { key: "H", width: 20 },
    ];

    const rowHeaderPosition = 1;
    const rowHeader = worksheetHeader.getRow(rowHeaderPosition);

    rowHeader.getCell(1).value = "Project";
    rowHeader.getCell(2).value = "No. Faktur";
    rowHeader.getCell(3).value = "Distributor";
    rowHeader.getCell(4).value = "Periode Sales";
    rowHeader.getCell(5).value = "Kode Outlet";
    rowHeader.getCell(6).value = "Nama Outlet";
    rowHeader.getCell(7).value = "Sektor";
    rowHeader.getCell(8).value = "Total Sales";

    for (let i = 1; i <= totalHeaderLength; i++) {
      rowHeader.getCell(i).font = {
        size: 10,
        bold: true,
      };
      rowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
    }

    for (let i = 1; i <= 8; i++) {
      for (let j = 1; j <= totalHeaderLength + 1; j++) {
        const cell = worksheetHeader.getCell(j, i);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    for (let i = 0; i < totalHeaderLength; i++) {
      const row = worksheetHeader.getRow(rowHeaderPosition + 1 + i);
      row.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      };

      insertDataToCell(
        getProjectType(exportedData?.headerRecords[i].project),
        row,
        1
      );
      insertDataToCell(exportedData?.headerRecords[i].nomorInvoice, row, 2);
      insertDataToCell(exportedData?.headerRecords[i].distributorName, row, 3);
      if (exportedData?.headerRecords[i].salesPeriode) {
        row.getCell(4).value = new DateExodus(
          exportedData?.headerRecords[i].salesPeriode
        ).getStringDateFormat("YYYY-MM-DD");
      } else {
        insertNullValueInRowCell(row, 4);
      }
      insertDataToCell(exportedData?.headerRecords[i].outletCode, row, 5);
      insertDataToCell(exportedData?.headerRecords[i].outletName, row, 6);
      insertDataToCell(
        getOutletSectorType(exportedData?.headerRecords[i].outletSectorType),
        row,
        7
      );
      insertDataToCell(exportedData?.headerRecords[i].totalSales, row, 8);
    }
  };

  const handleExportDetailSheet = async () => {
    worksheetDetail.name = "Detail";
    worksheetDetail.columns = [
      { key: "A", width: 15 },
      { key: "B", width: 20 },
      { key: "C", width: 30 },
      { key: "D", width: 15 },
      { key: "E", width: 25 },
      { key: "F", width: 15 },
      { key: "G", width: 20 },
      { key: "H", width: 15 },
      { key: "I", width: 20 },
    ];

    const rowHeaderPosition = 1;
    const rowHeader = worksheetDetail.getRow(rowHeaderPosition);

    rowHeader.getCell(1).value = "Project";
    rowHeader.getCell(2).value = "No. Faktur";
    rowHeader.getCell(3).value = "Nama Produk";
    rowHeader.getCell(4).value = "Kode Produk";
    rowHeader.getCell(5).value = "Kode Produk Distributor";
    rowHeader.getCell(6).value = "Qty";
    rowHeader.getCell(7).value = "Harga";
    rowHeader.getCell(8).value = "Diskon Principal";
    rowHeader.getCell(9).value = "Subtotal";

    for (let i = 1; i <= totalDetailLength; i++) {
      rowHeader.getCell(i).font = {
        size: 10,
        bold: true,
      };
      rowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
    }

    for (let i = 1; i <= 9; i++) {
      for (let j = 1; j <= totalDetailLength + 1; j++) {
        const cell = worksheetDetail.getCell(j, i);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    for (let i = 0; i < totalDetailLength; i++) {
      const row = worksheetDetail.getRow(rowHeaderPosition + 1 + i);
      row.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      };

      insertDataToCell(
        getProjectType(exportedData?.detailRecords[i].project),
        row,
        1
      );
      insertDataToCell(exportedData?.detailRecords[i].nomorInvoice, row, 2);
      insertDataToCell(exportedData?.detailRecords[i].productName, row, 3);
      if (!isEmpty(exportedData?.detailRecords[i].productCode?.toString())) {
        row.getCell(4).value = exportedData?.detailRecords[i].productCode
          ?.toString()
          .padStart(6, "0");
      } else {
        insertNullValueInRowCell(row, 4);
      }
      insertDataToCell(
        exportedData?.detailRecords[i].productCodeDistributor,
        row,
        5
      );
      insertDataToCell(exportedData?.detailRecords[i].quantity, row, 6);
      insertDataToCell(exportedData?.detailRecords[i].price, row, 7);
      if (!isEmpty(exportedData?.detailRecords[i].discountPI?.toString())) {
        row.getCell(8).value = exportedData?.detailRecords[i].discountPI + "%";
      } else {
        row.getCell(8).value = "0%";
      }
      insertDataToCell(exportedData?.detailRecords[i].subtotal, row, 9);
    }
  };

  const handleExportFilterBySheet = () => {
    worksheetFilter.name = "Filter By";
    worksheetFilter.columns = [
      { key: "A", width: 15 },
      { key: "B", width: 25 },
      { key: "C", width: 20 },
      { key: "D", width: 20 },
      { key: "E", width: 30 },
      { key: "F", width: 25 },
      { key: "G", width: 20 },
    ];

    const rowHeaderPosition = 1;
    const rowHeader = worksheetFilter.getRow(rowHeaderPosition);

    rowHeader.getCell(1).value = "Project";
    rowHeader.getCell(2).value = "Nomor Faktur";
    rowHeader.getCell(3).value = "Periode Awal";
    rowHeader.getCell(4).value = "Periode Akhir";
    rowHeader.getCell(5).value = "Nama Outlet";
    rowHeader.getCell(6).value = "Nama Distributor";
    rowHeader.getCell(7).value = "Sektor";

    for (let i = 1; i <= 7; i++) {
      rowHeader.getCell(i).font = {
        size: 10,
        bold: true,
      };
      rowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
    }

    for (let i = 1; i <= 7; i++) {
      for (let j = 1; j <= 1 + 1; j++) {
        const cell = worksheetFilter.getCell(j, i);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    const row = worksheetFilter.getRow(rowHeaderPosition + 1);

    for (let i = 0; i < 2; i++) {
      const rowStylePointer = worksheetFilter.getRow(rowHeaderPosition + 1 + i);
      rowStylePointer.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      };
    }

    if (filterValues?.projectAvailable) {
      insertDataToCell(filterValues?.projectAvailable.label, row, 1);
    } else {
      insertNullValueInRowCell(row, 1);
    }
    if (filterValues?.nomorFaktur) {
      insertDataToCell(filterValues?.nomorFaktur, row, 2);
    } else {
      insertNullValueInRowCell(row, 2);
    }
    if (filterValues?.periodeAwal) {
      row.getCell(3).value =
        filterValues?.periodeAwal.getStringDateFormat("YYYY-MM-DD");
    } else {
      insertNullValueInRowCell(row, 3);
    }
    if (filterValues?.periodeAkhir) {
      row.getCell(4).value =
        filterValues?.periodeAkhir.getStringDateFormat("YYYY-MM-DD");
    } else {
      insertNullValueInRowCell(row, 4);
    }
    if (filterValues?.namaOutlet) {
      insertDataToCell(exportedData?.headerRecords[0].outletName, row, 5);
    } else {
      insertNullValueInRowCell(row, 5);
    }
    if (filterValues?.namaDistributor) {
      insertDataToCell(exportedData?.headerRecords[0].distributorName, row, 6);
    } else {
      insertNullValueInRowCell(row, 6);
    }
    if (filterValues?.sector) {
      insertDataToCell(
        getOutletSectorType(exportedData?.headerRecords[0].outletSectorType),
        row,
        7
      );
    } else {
      insertNullValueInRowCell(row, 7);
    }
  };

  const handleExport = async () => {
    if (isError) {
      toast({
        title: "Error Found!",
        description: "Something Went Wrong When Exporting Excel ",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setLoadingExportHeader(true);

      handleExportHeaderSheet();
      handleExportDetailSheet();
      handleExportFilterBySheet();

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `sales_distributor_exported.xlsx`);
      setLoadingExportHeader(false);
    }
  };

  React.useEffect(() => {
    setExportedData(null);
    setTotalHeaderLength(-1);
    setTotalDetailLength(-1);
    getExportedData();
  }, [filterValues]);

  React.useEffect(() => {
    setTotalHeaderLength(exportedData?.headerRecords.length || -1);
    setTotalDetailLength(exportedData?.detailRecords.length || -1);
  }, [exportedData]);

  const exportData = async () => {
    if (exportedData && totalHeaderLength > 0 && totalDetailLength > 0) {
      handleExport();
    } else {
      toast({
        title: "Error Found!",
        description: "Something Went Wrong When Exporting Excel ",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return { exportData, loadingExportHeader };
};

export default useExportDistributorHeader;
