import { useEffect, useState } from "react";
import { OutletModel } from "../../models/Outlet/OutletModel";
import OutletProvider from "../../dataProviders/common/Outlet/OutletProvider";
import { OutletDetailParam } from "../../dataProviders/common/Outlet/OutletParamType";
import ErrorResponse from "../../models/common/ErrorResponse";

const useGetOutletDetail = ({ outletId }: OutletDetailParam) => {
  const [outletData, setData] = useState<OutletModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setError(null);
        setLoading(true);

        const response: OutletModel | undefined =
          await OutletProvider.getOutletDetail({
            outletId: outletId,
          });

        setData(response!);
      } catch (error) {
        setData(null);
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setLoading(false);
      }
    }

    if (outletId) {
      getData();
    }
  }, [outletId]);

  return { outletData, loading, error };
};

export default useGetOutletDetail;
