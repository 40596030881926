import { Box, Flex, useMediaQuery, useTheme } from "@chakra-ui/react";
import FilterBox from "../../../../../components/FilterBox/FilterBox";
import ButtonExportExcelMonitorSalesTarget from "./ButtonExportExcelMonitorSalesTarget";
import ButtonApproveMonitorSalesTarget from "./ButtonApproveMonitorSalesTarget";
import ButtonFilterMonitorSalesTarget from "./ButtonFilterMonitorSalesTarget";
import { useContext } from "react";
import MonitorSalesTargetContext from "../context/MonitorSalesTargetContext";
import DateExodus from "../../../../../models/DateExodus";

const FilterAreaMonitorSalesTarget: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { filterValues } = useContext(MonitorSalesTargetContext);

  const formatEpochToDateString = (epoch: number) => {
    return new DateExodus(epoch).getStringDateFormat("MMMM YYYY");
  };

  return (
    <Flex
      paddingTop={isDesktopView ? "20px" : "12px"}
      gap={3}
      direction={isDesktopView ? "row" : "column"}
      alignItems="flex-start"
    >
      <Box width="100%" display="flex" flexWrap="wrap">
        {filterValues?.idKuartal && (
          <FilterBox
            title="Periode"
            content={`Kuartal ${
              filterValues.idKuartal
            } (${formatEpochToDateString(
              filterValues.periodeKuartalStart!
            )} - ${formatEpochToDateString(filterValues.periodeKuartalEnd!)})`}
          />
        )}

        {filterValues?.jabatan && (
          <FilterBox title="Jabatan" content={filterValues.jabatan.label} />
        )}
        {filterValues?.jabatan && filterValues?.bawahan && (
          <FilterBox title="Nama" content={filterValues.bawahan.name} />
        )}
        {filterValues?.project && (
          <FilterBox title="Project" content={filterValues.project.label} />
        )}
      </Box>

      <Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        justifyContent="flex-end"
        gap={4}
      >
        <ButtonFilterMonitorSalesTarget />
        {isDesktopView && <ButtonExportExcelMonitorSalesTarget />}
        {isDesktopView && <ButtonApproveMonitorSalesTarget />}
      </Box>
    </Flex>
  );
};

export default FilterAreaMonitorSalesTarget;
