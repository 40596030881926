import axios, { CreateAxiosDefaults } from "axios";

const clientConfig: CreateAxiosDefaults = {
  baseURL: import.meta.env.VITE_REACT_APP_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
};

const AxiosBaseClient = axios.create(clientConfig);

export default AxiosBaseClient;
