import DateExodus from "../../../../../models/DateExodus";

/* eslint-disable @typescript-eslint/no-explicit-any */
class DraftRencanaKunjunganModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: DraftRencanaKunjunganModelData[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: DraftRencanaKunjunganModelData[] = [];

    if (json["data"] !== null) {
      for (let i = 0; i < json.data.length; i++) {
        temp.push(new DraftRencanaKunjunganModelData(json.data[i]));
      }
    }

    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = temp;
  }
}

class DraftRencanaKunjunganModelData {
  id!: number;
  outletId!: number;
  outletCode!: string;
  outletName!: string;
  customerId!: number;
  customerCode!: string;
  customerName!: string;
  customerSpecialist!: string;
  visitHeaderId!: number;
  positionCustomer!: string;
  purpose!: string;
  totalVisit!: number;
  createdAt!: DateExodus;
  updatedAt!: DateExodus;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.id = json["ID"];
    this.outletId = json["outlet_id"];
    this.outletCode = json["outlet_code"];
    this.outletName = json["outlet_name"];
    this.customerId = json["customer_id"];
    this.customerCode = json["customer_code"];
    this.customerName = json["customer_name"];
    this.customerSpecialist = json["customer_specialist"];
    this.visitHeaderId = json["visit_header_id"];
    this.positionCustomer = json["position_customer"];
    this.purpose = json["purpose"];
    this.totalVisit = json["total_visit"];
    this.createdAt = DateExodus.fromBackend(json["created_at"]);
    this.updatedAt = DateExodus.fromBackend(json["updated_at"]);
  }
}

export { DraftRencanaKunjunganModelPack, DraftRencanaKunjunganModelData };
