/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Input,
  Stack,
  Text,
  useMediaQuery,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import StepperControlFrame from "../../common/StepperControlFrame";
import { useStepper } from "../../../contexts/StepperContextProvider";
import KlaimSalesDivider from "../../common/KlaimSalesDivider";
import useGetOutletByCodeOrPanel from "../../../../../../services/Outlet/useGetOutletByCodeOrPanel";
import { FormProvider, useForm } from "react-hook-form";
import KlaimSalesDetailOutlet from "./KlaimSalesDetailOutlet";
import useGetAndSaveOutletKlaimSales from "../services/useGetAndSaveOutletKlaimSales";

const SearchOutletKlaimSalesBase = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const {
    fetchOutletData,
    outletData,
    outletError,
    outletLoading,
    outletSuccessFetch,
    apotekPanelData,
    apotekPanelError,
    apotekPanelLoading,
    apotekPanelSuccessFetch,
  } = useGetOutletByCodeOrPanel();

  const {
    saveOutletKlaimSales,
    getStoredOutletKlaimSales,
    storedOutletPanel,
    storedOutletTujuan,
  } = useGetAndSaveOutletKlaimSales();

  const formOutletTujuan = useForm();
  const formOutletPanel = useForm();

  const handleSubmitFormOutletTujuan = (data: any) => {
    if (!data.outlet_tujuan || data.outlet_tujuan.trim() === "") return;

    fetchOutletData({ outletCode: data.outlet_tujuan.trim(), panel: false });
  };

  const handleSubmitFormOutletPanel = (data: any) => {
    if (!data.outlet_panel || data.outlet_panel.trim() === "") return;

    fetchOutletData({ outletCode: data.outlet_panel.trim(), panel: true });
  };

  const {
    actions: { handleNext },
  } = useStepper();

  const onHandleNext = () => {
    if (!outletData || !apotekPanelData) {
      if (!storedOutletTujuan || !storedOutletPanel) {
        toast({
          title: "Mohon pilih outlet panel dan outlet tujuan terlebih dahulu",
          status: "warning",
        });
        return;
      } else {
        saveOutletKlaimSales({
          outletPanel: apotekPanelData ? apotekPanelData : storedOutletPanel,
          outletTujuan: outletData ? outletData : storedOutletTujuan,
        });
        handleNext();
        return;
      }
    }
    saveOutletKlaimSales({
      outletPanel: apotekPanelData,
      outletTujuan: outletData,
    }).then(() => handleNext());
  };

  const generateDataOutletTujuanToShow = () => {
    if (outletData) return outletData;
    if (!outletData && storedOutletTujuan) return storedOutletTujuan;
    return null;
  };

  const generateDataOutletPanelToShow = () => {
    if (apotekPanelData) return apotekPanelData;
    if (!apotekPanelData && storedOutletPanel) return storedOutletPanel;
    return null;
  };

  useEffect(() => {
    if (outletError)
      toast({
        title:
          outletError.status === 404 ? "Data Not Found!" : outletError.message,
        status: "error",
      });
  }, [outletError]);

  useEffect(() => {
    if (apotekPanelError)
      toast({
        title:
          apotekPanelError.status === 404
            ? "Data Not Found!"
            : apotekPanelError.message,
        status: "error",
      });
  }, [apotekPanelError]);

  useEffect(() => {
    if (outletSuccessFetch)
      toast({ title: "Outlet Tujuan Terpilih", status: "success" });
  }, [outletSuccessFetch]);

  useEffect(() => {
    if (apotekPanelSuccessFetch)
      toast({ title: "Outlet Panel Terpilih", status: "success" });
  }, [apotekPanelSuccessFetch]);

  useEffect(() => {
    getStoredOutletKlaimSales();
  }, []);

  return (
    <StepperControlFrame onHandleBack={undefined} onHandleNext={onHandleNext}>
      <Stack
        flexDirection={isDesktopView ? "row" : "column"}
        divider={
          <KlaimSalesDivider
            orientation={isDesktopView ? "vertical" : "horizontal"}
          />
        }
        height="100%"
        width="100%"
      >
        <Card
          width="100%"
          bgColor={theme.colors.exodus.background}
          boxShadow="0px 7px 24px 4px #282c7a26"
          borderRadius="12px"
          color={theme.colors.exodus.fontDefault}
        >
          <CardBody>
            <FormProvider {...formOutletTujuan}>
              <form
                onSubmit={formOutletTujuan.handleSubmit(
                  handleSubmitFormOutletTujuan
                )}
              >
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Text
                    fontSize={isDesktopView ? 24 : 20}
                    fontWeight="semibold"
                    color={theme.colors.exodus.primaryBlue}
                    paddingBottom="8px"
                  >
                    Outlet Tujuan
                  </Text>
                  <Flex direction="row" width="100%" paddingBottom="12px">
                    <Input
                      {...formOutletTujuan.register("outlet_tujuan")}
                      placeholder="Kode Outlet"
                      sx={{
                        borderRightRadius: 0,
                      }}
                    />
                    <Button
                      type="submit"
                      colorScheme="buttonPrimary"
                      isLoading={outletLoading}
                      sx={{
                        borderLeftRadius: 0,
                      }}
                    >
                      Pilih
                    </Button>
                  </Flex>
                  <Text fontSize={12} textAlign="left">
                    Pilih Outlet Tujuan yang diinginkan dengan memasukkan kode
                    outlet.
                    <br />
                    Detail Outlet Tujuan harus muncul terlebih dahulu agar dapat
                    menuju tahap selanjutnya
                  </Text>
                  <Box paddingTop="32px" width="100%">
                    <KlaimSalesDetailOutlet
                      data={generateDataOutletTujuanToShow()}
                    />
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </CardBody>
        </Card>
        <Card
          width="100%"
          bgColor={theme.colors.exodus.background}
          boxShadow="0px 7px 24px 4px #282c7a26"
          borderRadius="12px"
          color={theme.colors.exodus.fontDefault}
        >
          <CardBody>
            <FormProvider {...formOutletPanel}>
              <form
                onSubmit={formOutletPanel.handleSubmit(
                  handleSubmitFormOutletPanel
                )}
              >
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Text
                    fontSize={isDesktopView ? 24 : 20}
                    fontWeight="semibold"
                    color={theme.colors.exodus.primaryBlue}
                    paddingBottom="8px"
                  >
                    Outlet Panel
                  </Text>
                  <Flex direction="row" width="100%" paddingBottom="12px">
                    <Input
                      {...formOutletPanel.register("outlet_panel")}
                      placeholder="Kode Outlet"
                      sx={{
                        borderRightRadius: 0,
                      }}
                    />
                    <Button
                      type="submit"
                      colorScheme="buttonPrimary"
                      isLoading={apotekPanelLoading}
                      sx={{
                        borderLeftRadius: 0,
                      }}
                    >
                      Pilih
                    </Button>
                  </Flex>
                  <Text fontSize={12} textAlign="left">
                    Pilih Outlet Panel yang diinginkan dengan memasukkan kode
                    outlet.
                    <br />
                    Detail Outlet Panel harus muncul terlebih dahulu agar dapat
                    menuju tahap selanjutnya
                  </Text>
                  <Box paddingTop="32px" width="100%">
                    <KlaimSalesDetailOutlet
                      data={generateDataOutletPanelToShow()}
                    />
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </CardBody>
        </Card>
      </Stack>
    </StepperControlFrame>
  );
};

export default SearchOutletKlaimSalesBase;
