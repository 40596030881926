import React, { useState } from "react";
import { MonitoringBudgetingProvider } from "../dataProviders/MonitoringBudgetingProvider";
import MonitoringFilterValues from "../models/MonitoringFilterValues";
import { isEmpty } from "lodash";
import DateExodus from "../../../models/DateExodus";
import filterInitialValue from "../utils/initialFilterValue";
import ErrorResponse from "../../../models/common/ErrorResponse";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";

const useGetFilter = () => {
  const [data, setData] = useState<MonitoringFilterValues | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorGetFilter, setErrorGetFilter] = useState<ErrorResponse | null>(
    null
  );

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);

      try {
        setErrorGetFilter(null);
        const result: MonitoringFilterValues =
          await MonitoringBudgetingProvider.getSavedFilter();
        if (isEmpty(result)) {
          setData(filterInitialValue);
          return;
        }
        setData({
          ...result,
          periodeAkhir: new DateExodus(
            result.periodeAkhir as unknown as number
          ),
          periodeAwal: new DateExodus(result.periodeAwal as unknown as number),
          periodeTahun: new DateExodus(
            result.periodeTahun as unknown as number
          ),
        });
      } catch (error: unknown) {
        setErrorGetFilter(UNKNOWN_ERROR_RESPONSE);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return { data, isLoading, errorGetFilter };
};

export default useGetFilter;
