/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import RencanaKunjunganProvider from "../../dataProviders/RencanaKunjunganProvider";
import {
  DraftRencanaKunjunganAfterCreateData,
  DraftRencanaKunjunganCreateBody,
} from "../models/CreateDraftRencanaKunjunganModel";

type CallResultHandler = {
  onSuccess: (data: DraftRencanaKunjunganAfterCreateData) => void;
  onError: (data: string) => void;
};

/**
 * HOW TO USE:
 * const { functionName } = hookName();
 *
 * functionName(data_1, data_2, data_3, {
 *    onSuccess: (data) => {
 *        Do Something (if you don't want to use data, you can ignore it by () => {} or (_) => {})
 *    },
 *    onError: (data) => {
 *        Do Something (if you don't want to use data, you can ignore it by () => {} or (_) => {})
 *    },
 * })
 */

const useCreateDraftRencanaKunjungan = () => {
  const [result, setResultData] =
    useState<DraftRencanaKunjunganAfterCreateData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const createDraftRencanaKunjungan = async (
    data: DraftRencanaKunjunganCreateBody,
    handler: CallResultHandler
  ) => {
    try {
      setErrorMessage(null);
      setLoading(true);

      const response: DraftRencanaKunjunganAfterCreateData | undefined =
        await RencanaKunjunganProvider.createDraftRencanaKunjungan({
          data: data,
        });

      setResultData(response!);
      setLoading(false);

      handler.onSuccess(response!);
    } catch (error: any) {
      setResultData(null);
      setErrorMessage(error);
      setLoading(false);
      handler.onError(error);
    }
  };

  return { createDraftRencanaKunjungan, result, loading, errorMessage };
};

export default useCreateDraftRencanaKunjungan;
