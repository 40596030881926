import ErrorResponse from "../../models/common/ErrorResponse";

export const COMMON_ERRORS = {
  SOMETHING_WENT_WRONG: "Something went wrong !",
  ERROR_WHEN_FETCHING_DATA: "Error when fetching data !",
  INTERNAL_SERVER_ERROR_STATUS: 500,
  INTERNAL_SERVER_ERROR_CODE: "Internal Server Error",
  ERROR_INDEXED_DB_MESSAGE:
    "Error when getting/saving data from database, try re-login",
};

export const UNKNOWN_ERROR_RESPONSE: ErrorResponse = {
  message: COMMON_ERRORS.SOMETHING_WENT_WRONG,
  status: COMMON_ERRORS.INTERNAL_SERVER_ERROR_STATUS,
  code: COMMON_ERRORS.INTERNAL_SERVER_ERROR_CODE,
};

export const ERROR_GET_FROM_INDEXED_DB: ErrorResponse = {
  message: COMMON_ERRORS.ERROR_INDEXED_DB_MESSAGE,
  status: COMMON_ERRORS.INTERNAL_SERVER_ERROR_STATUS,
  code: COMMON_ERRORS.INTERNAL_SERVER_ERROR_CODE,
};
