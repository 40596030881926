import { useState } from "react";
import { SaveOutletKlaimSalesParam } from "../../../dataProviders/InputKlaimSalesParamType";
import { OutletModel } from "../../../../../../models/Outlet/OutletModel";
import InputKlaimSalesProvider from "../../../dataProviders/InputKlaimSalesProvider";

const useGetAndSaveOutletKlaimSales = () => {
  const [storedOutletTujuan, setStoredOutletTujuan] =
    useState<OutletModel | null>(null);
  const [storedOutletPanel, setStoredOutletPanel] =
    useState<OutletModel | null>(null);
  const [loadingStoredOutlet, setLoading] = useState<boolean>(true);
  const [errorStoredOutlet, setErrorMessage] = useState<string | null>(null);

  const getStoredOutletKlaimSales = async () => {
    try {
      setErrorMessage(null);
      setLoading(true);

      const data = await InputKlaimSalesProvider.getSavedInputKlaimSalesData();

      if (data && data.outlet) {
        setStoredOutletTujuan(data.outlet.outletTujuan.data);
        setStoredOutletPanel(data.outlet.outletPanel.data);
      }

      setLoading(false);
    } catch (error) {
      setErrorMessage(error as string);
      setLoading(false);
    }
  };

  const saveOutletKlaimSales = async (data: SaveOutletKlaimSalesParam) => {
    try {
      setErrorMessage(null);
      setLoading(true);

      await InputKlaimSalesProvider.setSaveOutletKlaimSales(data);

      setLoading(false);
    } catch (error) {
      setErrorMessage(error as string);
      setLoading(false);
    }
  };

  return {
    getStoredOutletKlaimSales,
    saveOutletKlaimSales,
    storedOutletTujuan,
    storedOutletPanel,
    loadingStoredOutlet,
    errorStoredOutlet,
  };
};

export default useGetAndSaveOutletKlaimSales;
