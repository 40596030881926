import { ReactNode, createContext, useContext } from "react";
import ApiGetListResponseMetadata from "../../../../../models/common/ApiGetListResponseMetadata";
import { GetListOutletCardModelData } from "../../models/GetListOutletCardModel";
import { OutletCardListFilterType } from "../models/OutletCardListFilterType";
import useOutletCardListFilter from "../services/useFilter";
import useOutletCardListPaginate from "../services/usePaginate";
import { GetListOutletCardParam } from "../../dataProviders/OutletCardProviderParams";
import useGetOutletCardList from "../services/useGetOutletCardList";
import ErrorResponse from "../../../../../models/common/ErrorResponse";
import useExportExcelList from "../services/useExportExcelList";

/* --------------------------------- Sandbox -------------------------------- */

type ValueType = {
  states: {
    filterValues: OutletCardListFilterType | null;
    isLoadingFilter: boolean | undefined;
    errorFilter: ErrorResponse | null | undefined;
    data: GetListOutletCardModelData[] | undefined;
    isLoadingList: boolean | undefined;
    errorList: ErrorResponse | null | undefined;
    metadata: ApiGetListResponseMetadata | undefined | null;
    isExportLoading: boolean | undefined;
  };
  actions: {
    saveFilter: (filterValues: OutletCardListFilterType) => Promise<void>;
    setLimit: (limit: number) => void;
    exportExcel: () => void;
    setPage: (value: "next" | "prev" | "first") => void;
  };
};

const OutletCardListContext = createContext<ValueType>({
  states: {
    filterValues: null,
    isLoadingFilter: undefined,
    errorFilter: undefined,
    data: undefined,
    isLoadingList: undefined,
    errorList: undefined,
    metadata: undefined,
    isExportLoading: undefined,
  },
  actions: {
    saveFilter: () => Promise.prototype,
    exportExcel: () => {},
    setLimit: () => {},
    setPage: () => {},
  },
});

/* ------------------------------- Components ------------------------------- */
function useOutletCardListContext(): ValueType {
  return useContext(OutletCardListContext);
}

const OutletCardListContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const {
    filterValues,
    isLoading: isLoadingFilter,
    saveFilter,
    error: errorFilter,
  } = useOutletCardListFilter();

  const { paginate, savePagination } = useOutletCardListPaginate();

  const queryParam: GetListOutletCardParam = {
    startDate: filterValues?.startDate!!,
    endDate: filterValues?.endDate!!,
    page: paginate?.page,
    limit: paginate?.limit,
    userId: filterValues?.bawahan?.id,
    salesZoneId: filterValues?.zone?.salesZoneId,
    salesZoneType: filterValues?.zone?.salesZoneType,
    sector: filterValues?.sector ?? undefined,
    outlet: filterValues?.outlet ?? undefined,
    project: filterValues?.project ?? undefined,
  };

  const {
    data,
    isLoading: isLoadingList,
    error: errorList,
  } = useGetOutletCardList(queryParam);

  const { exportData, isExportLoading } = useExportExcelList(filterValues);

  const setPage = (value: "next" | "prev" | "first") => {
    let page: number = 1;
    if (paginate) {
      switch (value) {
        case "next":
          page = paginate.page + 1;
          break;
        case "prev":
          if (paginate.page > 1) {
            page = paginate.page - 1;
          }
          break;
        case "first":
          page = 1;
          break;
        default:
          throw Error("Unrecognized set page value");
      }

      const newValue = {
        page: page,
        limit: paginate.limit,
      };

      savePagination(newValue);
    }
  };

  const setLimit = (limit: number) => {
    if (paginate) {
      const updatedPaginateParam = {
        page: 1,
        limit: limit,
      };
      savePagination(updatedPaginateParam);
    }
  };

  const value: ValueType = {
    states: {
      filterValues,
      isLoadingFilter,
      errorFilter,
      data: data?.records,
      isLoadingList,
      errorList,
      metadata: data?.metadata,
      isExportLoading,
    },
    actions: { saveFilter, setLimit, setPage, exportExcel: exportData },
  };

  return (
    <OutletCardListContext.Provider value={value}>
      {children}
    </OutletCardListContext.Provider>
  );
};

export default OutletCardListContextProvider;
export { useOutletCardListContext };
