class RoleModel {
  id: number;
  name: string;
  label: string;
  parentId: number;
  parentRole: null;

  constructor({
    id,
    name,
    label,
    parentId,
    parentRole,
  }: {
    id: number;
    name: string;
    label: string;
    parentId: number;
    parentRole: null;
  }) {
    this.id = id;
    this.name = name;
    this.label = label;
    this.parentId = parentId;
    this.parentRole = parentRole;
  }

  public static fromLocalDbJson(json: any) {
    return new RoleModel({
      id: json.id,
      name: json.name,
      label: json.label,
      parentId: json.parent_id,
      parentRole: json.parent_role,
    });
  }

  toLocalDbJson() {
    return {
      id: this.id,
      name: this.name,
      label: this.label,
      parent_id: this.parentId,
      parent_role: this.parentRole,
    };
  }
}

export default RoleModel;
