import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
  Center,
  useTheme,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import DateExodus from "../../../../../../models/DateExodus";
import { useBiayaMurniFilterContext } from "../../../../contexts/BiayaMurniFilterContext";
import BiayaMurniFilterModel from "../../../../models/BiayaMurniFilterModel";
import FilterByJabatanAndPekerja from "./fields/FilterByJabatanAndPekerja";
import FilterByPeriode from "./fields/FilterByPeriode";
import { useBiayaMurniListContext } from "../../../../contexts/BiayaMurniListContext";
import FilterByProject from "./fields/FilterByProject";

const BiayaMurniFilterModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const {
    states: { filterValues },
    actions: { saveFilter },
  } = useBiayaMurniFilterContext();

  const {
    actions: { setPage },
  } = useBiayaMurniListContext();

  const methods = useForm<BiayaMurniFilterModel>({
    defaultValues: new BiayaMurniFilterModel({
      project: filterValues?.project ?? undefined,
      jabatan: filterValues?.jabatan ?? undefined,
      pekerja: filterValues?.pekerja ?? undefined,
      periode: {
        awal: filterValues?.periode.awal ?? new DateExodus(),
        akhir: filterValues?.periode.akhir ?? new DateExodus(),
      },
    }),
  });

  useEffect(() => {
    if (filterValues) {
      methods.setValue("project", filterValues.project);
      methods.setValue("jabatan", filterValues.jabatan);
      methods.setValue("pekerja", filterValues.pekerja);
      methods.setValue("periode.awal", filterValues.periode.awal);
      methods.setValue("periode.akhir", filterValues.periode.akhir);
    }
  }, [filterValues]);

  const handleClearAll = () => {
    methods.setValue("project", undefined);
    methods.setValue("jabatan", undefined);
    methods.setValue("pekerja", undefined);
    methods.setValue("periode", {
      awal: new DateExodus(),
      akhir: new DateExodus(),
    });
    handleOnSubmitFilter();
    setPage("first");
  };

  const handleOnSubmitFilter = () => {
    const updatedFilter = new BiayaMurniFilterModel({
      project: methods.getValues("project"),
      jabatan: methods.getValues("jabatan"),
      pekerja: methods.getValues("pekerja"),
      periode: {
        awal: methods.getValues("periode.awal"),
        akhir: methods.getValues("periode.akhir"),
      },
    });
    saveFilter(updatedFilter);
    setPage("first");
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      autoFocus={false}
      size={isDesktopView ? "3xl" : "sm"}
    >
      <FormProvider {...methods}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center
              sx={{
                justifyContent: "space-between",
                display: "flex",
                gap: 10,
              }}
            >
              <Text fontWeight={600} color={theme.colors.exodus.primaryBlue}>
                Filter Data
              </Text>
              <Button
                sx={{ textDecoration: "underline" }}
                variant="ghost"
                onClick={handleClearAll}
              >
                Clear All
              </Button>
            </Center>
          </ModalHeader>
          <ModalBody>
            <Stack
              sx={{
                justifyContent: "space-between",
                display: "flex",
                gap: 4,
              }}
            >
              <FilterByProject />
              <FilterByJabatanAndPekerja />
              <FilterByPeriode />
            </Stack>
          </ModalBody>
          <ModalFooter
            gap={4}
            flexDirection={isMobile ? "column-reverse" : undefined}
          >
            <Button
              colorScheme="blue"
              onClick={onClose}
              sx={{ width: "100% " }}
              variant={"outline"}
            >
              Close
            </Button>
            <Button
              colorScheme={`buttonPrimary`}
              sx={{ width: "100%" }}
              onClick={handleOnSubmitFilter}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};

export default BiayaMurniFilterModal;
