import { Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import useTitle from "../../../../services/useTitle";
import OutletCardListContextProvider from "./contexts/OutletCardListContextProvider";
import OutletCardListDesktop from "./components/desktop/OutletCardListDesktop";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import OutletCardListMobile from "./components/mobile/OutletCardListMobile";

const OutletCardList = () => {
  useTitle("Outlet Card List");
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <OutletCardListContextProvider>
      <Stack>
        <PageTitle label="Outlet Card List" />
        {isDesktopView ? <OutletCardListDesktop /> : <OutletCardListMobile />}
      </Stack>
    </OutletCardListContextProvider>
  );
};

export default OutletCardList;
