import { useEffect, useState } from "react";
import useGetPeriodeByUserId from "../../../services/useGetPeriodeByUserId";
import InputRencanaKunjunganContext from "../context/InputRencanaKunjunganContext";
import useGetDraftRencanaKunjunganList from "../services/useGetDraftRencanaKunjunganList";
import useGetMinimalVisit from "../services/useGetMinimalVisit";
import PeriodeDataModel from "../../../models/PeriodeDataModel";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";
import InputRencanaKunjunganPage from "./InputRencanaKunjunganPage";
import useTitle from "../../../../../services/useTitle";

const InputRencanaKunjunganBase: React.FC = () => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const [selectedPeriode, setSelectedPeriode] =
    useState<PeriodeDataModel | null>(null);
  const [key, setKey] = useState<number>(0);

  const {
    periodeData,
    loading: periodeLoading,
    errorMessage: periodeError,
  } = useGetPeriodeByUserId(key);

  const {
    minimalVisitData,
    loading: minimalVisitLoading,
    errorMessage: minimalVisitError,
  } = useGetMinimalVisit({
    role_id: loggedUser ? loggedUser.userRoleId : null,
  });

  const {
    draftRencanaKunjunganData,
    loading: draftRencanaLoading,
    errorMessage: draftRencanaError,
  } = useGetDraftRencanaKunjunganList(key, {
    header_id: selectedPeriode?.header_id,
  });

  useEffect(() => {
    if (periodeData.length > 0) {
      setSelectedPeriode(periodeData[0]);
    }
  }, [periodeData]);

  useTitle("Input Rencana Kunjungan");

  return (
    <InputRencanaKunjunganContext.Provider
      value={{
        periodeData: periodeData,
        periodeLoading: periodeLoading,
        periodeError: periodeError,
        minimalVisitData: minimalVisitData,
        minimalVisitLoading: minimalVisitLoading,
        minimalVisitError: minimalVisitError,
        draftRencanaKunjunganData: draftRencanaKunjunganData,
        draftRencanaLoading: draftRencanaLoading,
        draftRencanaError: draftRencanaError,
        selectedPeriode: selectedPeriode,
        setSelectedPeriode: setSelectedPeriode,
        key: key,
        setKey: setKey,
      }}
    >
      <InputRencanaKunjunganPage />
    </InputRencanaKunjunganContext.Provider>
  );
};

export default InputRencanaKunjunganBase;
