import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/biaya-murni/dpl-dpf");
  };

  return (
    <Button
      colorScheme="buttonPrimary"
      variant="outline"
      onClick={handleOnClick}
      width={"400px"}
    >
      Kembali ke Halaman Sebelumnya
    </Button>
  );
};

export default BackButton;
