import {
  Center,
  Spinner,
  Stack,
  VStack,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import BiayaMurniFilter from "../../../../components/filter/BiayaMurniFilter";
import BackButton from "../../../../components/desktop/BackButton";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import { useBiayaMurniFilterContext } from "../../../../contexts/BiayaMurniFilterContext";
import BiayaMurniDpTable from "./parts/BiayaMurniDpTable";
import { DpOrManagerContextProvider } from "../../contexts/DpOrManagerContext";
import { useBiayaMurniListContext } from "../../../../contexts/BiayaMurniListContext";
import { BiayaMurniDpData } from "../../../../models/apiResponses/BiayaMurniDpData";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";

const BiayaMurniDpPageDesktop = (): JSX.Element => {
  const {
    states: { error },
  } = useBiayaMurniFilterContext();

  if (error) {
    return (
      <VStack alignItems={"stretch"}>
        <PageTitle label="DP" />
        <ErrorPage message={error.message} />
      </VStack>
    );
  }

  return <ContentUnderFilter />;
};

const ContentUnderFilter = (): JSX.Element => {
  const theme = useTheme();

  const {
    states: { data, error, isLoading },
  } = useBiayaMurniListContext();

  if (isLoading) {
    return (
      <PageFrameWithFilter
        child={
          <Center p={10}>
            <Spinner color={theme.colors.exodus.primaryBlue} />
          </Center>
        }
      />
    );
  }

  if (error) {
    return (
      <PageFrameWithFilter child={<ErrorPage message={error.message} />} />
    );
  }

  if (data) {
    return (
      <DpOrManagerContextProvider
        dpIds={data.records.map((el) => (el as BiayaMurniDpData).dpId)}
      >
        <PageFrameWithFilter child={<BiayaMurniDpTable />} />
      </DpOrManagerContextProvider>
    );
  }

  return <PageFrameWithFilter child={<DataNotFound />} />;
};

const PageFrameWithFilter = ({
  child,
}: {
  child: JSX.Element;
}): JSX.Element => {
  const theme = useTheme();
  const [isSm] = useMediaQuery(
    `(max-width: ${theme.breakpoints.smallMonitor})`
  );

  return (
    <VStack alignItems={"stretch"}>
      <PageTitle label="DP" />
      <Stack
        width={"100%"}
        direction={isSm ? "column-reverse" : "row"}
        alignItems={isSm ? "flex-end" : undefined}
      >
        <BiayaMurniFilter />
        <BackButton />
      </Stack>
      {child}
    </VStack>
  );
};

export default BiayaMurniDpPageDesktop;
