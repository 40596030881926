import { AxiosResponse, isAxiosError } from "axios";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";
import AxiosBaseClient from "../../../dataProviders/config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import { EXODUS_API_VERSION_1 } from "../../../constants/common/RootConstants";
import ErrorResponse from "../../../models/common/ErrorResponse";
import {
  DetailKlaimSalesParam,
  ListKlaimSalesParam,
} from "./KlaimSalesParamType";
import { KlaimSalesListModelPack } from "../models/KlaimSalesListModel";
import { KlaimSalesDetailModelPack } from "../models/KlaimSalesDetailModel";
import DateExodus from "../../../models/DateExodus";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const KlaimSalesProvider = {
  getKlaimSalesList: async (params: ListKlaimSalesParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_1}/claim_sales`,
        {
          params: {
            page: params.page,
            limit: params.limit,
            claim_user_id: params.claimUserId,
            outlet_panel_name: params.outletPanelName,
            outlet_target_name: params.outletTargetName,
            status: params.status,
            start_at: new DateExodus(params.startAt?.getEpochDate()).toJSON(),
            end_at: new DateExodus(params.endAt?.getEpochDate()).toJSON(),
          },
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new KlaimSalesListModelPack(response.data);

          return Promise.resolve(responseData.data);
        } else {
          const errorResponse: ErrorResponse = {
            message: response.data.message,
            status: response.data.status,
            code: response.data.code,
          };

          return Promise.reject(errorResponse);
        }
      }
    } catch (error) {
      return handleOnError(error);
    }
  },
  getDetailKlaimSales: async (param: DetailKlaimSalesParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_1}/claim_sales/${param.id}`
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new KlaimSalesDetailModelPack(response.data);

          return Promise.resolve(responseData.data);
        } else {
          const errorResponse: ErrorResponse = {
            message: response.data.message,
            status: response.data.status,
            code: response.data.code,
          };

          return Promise.reject(errorResponse);
        }
      }
    } catch (error) {
      return handleOnError(error);
    }
  },
};

const handleOnError = (error: unknown) => {
  if (isAxiosError(error)) {
    return Promise.reject({
      message: error.response ? error.response.data.message : error.message,
      status: error.response?.status,
      code: error.response ? error.response.data.code : error.code,
    });
  }
  return Promise.reject(UNKNOWN_ERROR_RESPONSE);
};

export default KlaimSalesProvider;
