import { createContext } from "react";
import { MetadataApprovalRealisasi } from "../models/ApprovalRealisasiIndexDbModels";

type MetadataApprovalRealisasiContextProps = {
  metadataValues: MetadataApprovalRealisasi | null;
  setMetadataValues: (data: MetadataApprovalRealisasi) => void;
  page: number | null | undefined;
  setPage: (data: number | null) => void;
  limit: number | null | undefined;
  setLimit: (data: number | null) => void;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  handleLimitChange: (data: number) => void;
  handleFirstPage: () => void;
};

const defaultValue: MetadataApprovalRealisasiContextProps = {
  metadataValues: null,
  limit: null,
  page: null,
  setPage: () => {},
  setLimit: () => {},
  setMetadataValues: () => {},
  handlePrevPage: () => {},
  handleNextPage: () => {},
  handleLimitChange: () => {},
  handleFirstPage: () => {},
};

const MetadataApprovalRealisasiContext = createContext(defaultValue);

export default MetadataApprovalRealisasiContext;
