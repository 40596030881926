import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useTheme } from "@chakra-ui/react";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import IsTypeOfErrorResponse from "../../../../utils/IsTypeOfErrorResponse";
import MonitorKunjunganProvider from "../dataProviders/MonitorKunjunganProvider";
import {
  MonitorKunjunganModelData,
  MonitorKunjunganModelPack,
} from "../models/MonitorKunjunganDataModel";
import FilterMonitorKunjunganModel from "../models/FilterMonitorKunjunganModel";
import DateExodus from "../../../../models/DateExodus";
import CurrencyIdrFormatter from "../../../../utils/CurrencyIdrFormatter";

type CallResultHandler = {
  onError: (data: string) => void;
};

const useExportExcelMonitorKunjungan = (
  isPsspActive: boolean,
  filterValues: FilterMonitorKunjunganModel | null
) => {
  const theme = useTheme();

  const fetchMonitorKunjunganData = async () => {
    try {
      if (
        !filterValues ||
        !filterValues.selectedBawahanId ||
        !filterValues.periode
      ) {
        return Promise.reject(
          "Gagal Mengunduh Excel. Silahkan pilih user dan periode yang sesuai"
        );
      }

      const responseData: MonitorKunjunganModelPack | undefined =
        await MonitorKunjunganProvider.getMonitorKunjunganList({
          isPsspActive: isPsspActive,
          user_id: filterValues.selectedBawahanId,
          start_period: new DateExodus(filterValues.periode.start_period),
          end_period: new DateExodus(filterValues.periode.end_period),
          project: filterValues.project,
        });

      return Promise.resolve(responseData!.data);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        return Promise.reject(error.message);
      } else {
        return Promise.reject(UNKNOWN_ERROR_RESPONSE.message);
      }
    }
  };

  const setupCurrentPeriodeString = (): string => {
    const startPeriod = new DateExodus(filterValues!.periode!.start_period);
    const endPeriod = new DateExodus(filterValues!.periode!.end_period);

    return `${startPeriod.getStringDateFormat(
      "DD MMM YYYY"
    )} - ${endPeriod.getStringDateFormat("DD MMM YYYY")}`;
  };

  const formattedPeriodePSSP = (
    epoch: DateExodus | null | undefined
  ): string => {
    if (epoch === null || epoch === undefined) {
      return "-";
    } else {
      return epoch.getStringDateFormat("D MMM YYYY");
    }
  };

  const exportExcelMonitorKunjungan = async (handler: CallResultHandler) => {
    try {
      const data: MonitorKunjunganModelData[] =
        await fetchMonitorKunjunganData();

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet();

      worksheet.columns = [
        { key: "A", width: 25 },
        { key: "B", width: 25 },
        { key: "C", width: 25 },
        { key: "D", width: 25 },
        { key: "E", width: 25 },
        { key: "F", width: 25 },
        { key: "G", width: 25 },
        { key: "H", width: 25 },
        { key: "I", width: 25 },
        { key: "J", width: 25 },
        { key: "K", width: 25 },
        { key: "L", width: 30 },
        { key: "M", width: 30 },
      ];

      // merge cell dari A1 sampai M1 (Row title)
      worksheet.mergeCells("A1", "M1");

      const titleCell = worksheet.getRow(1).getCell("A");

      titleCell.alignment = {
        horizontal: "center",
      };

      titleCell.value = `Monitor Kunjungan (${
        isPsspActive ? "PSSP Aktif" : "PSSP Tidak Aktif"
      }) ${setupCurrentPeriodeString()}`;

      // Set font Style
      titleCell.font = {
        bold: true,
        size: 16,
        color: { argb: theme.colors.exodus.white.slice(1) }, //Remove the '#' from the Hex value
      };

      titleCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: theme.colors.exodus.primaryBlue.slice(1) }, //Remove the '#' from the Hex value
      };

      // inisiasi pada baris ke 3 jadi Header table
      const rowHeaderPosition = 3;
      const rowHeader = worksheet.getRow(rowHeaderPosition);

      // Isi data Header
      rowHeader.getCell(1).value = "Project";
      rowHeader.getCell(2).value = "Nama";
      rowHeader.getCell(3).value = "NIP";
      rowHeader.getCell(4).value = "Jabatan";
      rowHeader.getCell(5).value = "Nama Outlet";
      rowHeader.getCell(6).value = "Nama Customer";
      rowHeader.getCell(7).value = "Spesialis";
      rowHeader.getCell(8).value = "Jabatan Customer";
      rowHeader.getCell(9).value = "Dana Keluar";
      rowHeader.getCell(10).value = "Periode Mulai PSSP";
      rowHeader.getCell(11).value = "Periode Selesai PSSP";
      rowHeader.getCell(12).value = "Total Rencana Kunjungan";
      rowHeader.getCell(13).value = "Total Realisasi Kunjungan";

      for (let i = 1; i <= 13; i++) {
        rowHeader.getCell(i).font = {
          size: 14,
          bold: true,
        };
      }

      // Buat datanya menggunakan perulangan
      for (let i = 0; i < data.length; i++) {
        const row = worksheet.getRow(rowHeaderPosition + 1 + i);
        row.alignment = {
          wrapText: true,
        };
        row.getCell(1).value = data[i].project?.label ?? "-";
        row.getCell(2).value = data[i].userData.name;
        row.getCell(3).value = data[i].userData.nip;
        row.getCell(4).value = data[i].userData.roleLabel;
        row.getCell(5).value = data[i].outletName;
        row.getCell(6).value = data[i].customerName;
        row.getCell(7).value = data[i].spesialis;
        row.getCell(8).value = data[i].jabatan;
        row.getCell(9).value = data[i].psspNominal
          ? `${CurrencyIdrFormatter.convertToRp(data[i].psspNominal)}`
          : "";
        row.getCell(10).value = isPsspActive
          ? `${formattedPeriodePSSP(data[i].startMonth)}`
          : "PSSP Tidak Aktif";
        row.getCell(11).value = isPsspActive
          ? `${formattedPeriodePSSP(data[i].endMonth)}`
          : "PSSP Tidak Aktif";
        row.getCell(12).value = `${data[i].totalRencana}`;
        row.getCell(13).value = `${data[i].totalRealisasi}`;
      }

      //Persiapan download excel yang sudah generated
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buf]),
        `Monitor Kunjungan ${setupCurrentPeriodeString()}.xlsx`
      );
    } catch (error) {
      handler.onError(error as string);
    }
  };

  return { exportExcelMonitorKunjungan };
};

export default useExportExcelMonitorKunjungan;
