import { AxiosResponse } from "axios";
import {
  EXODUS_API_VERSION_2,
  EXODUS_API_VERSION_3,
} from "../../../../constants/common/RootConstants";
import {
  SalesOutletGetPrintParam,
  SalesOutletGetListParam,
  SalesOutletGetSummaryParam,
  SalesOutletGetOneParam,
} from "./SalesOutletProviderParams";
import AxiosBaseClient from "../../../../dataProviders/config/AxiosBaseClient";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import { COMMON_ERRORS } from "../../../../constants/common/ErrorConstants";
import ApiGetListSalesOutletResponse from "../pages/list/models/apiResponses/ApiGetListSalesOutletResponse";
import ApiGetPrintSalesOutletResponse from "../pages/list/models/apiResponses/ApiGetPrintSalesOutletResponse";
import { LOCAL_DB_ERROR_RESPONSE } from "../constants/constants";
import SalesOutletListFilterModel from "../pages/list/models/SalesOutletListFilterModel";
import IndexedDatabase from "../../../../database/IndexDB";
import SalesOutletSavedPaginationMetadata from "../models/SalesOutletSavedPaginationMetadata";
import ApiGetSummarySalesOutletResponse from "../pages/list/models/apiResponses/ApiGetSummarySalesOutletResponse";
import { feCustomErrorResponse } from "../utils/utils";
import ApiGetDetailSalesOutletResponse from "../pages/detail/models/apiResponses/ApiGetDetailSalesOutletResponse";
import SalesOutletDetailFilterModel from "../pages/detail/models/SalesOutletDetailFilterModel";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const SalesOutletProvider = {
  getList: async (params: SalesOutletGetListParam) => {
    const url = `${EXODUS_API_VERSION_3}/sales/outlet`;
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          limit: params.limit,
          page: params.page,
          start_date: params.startDate?.startOf("month").toJSON(),
          end_date: params.endDate?.endOf("month").toJSON(),
          outlet_id: params.outlets?.map((x) => x.outletId),
          user_id: params.userId,
          sector: params.sector?.value,
          sales_zone_id: params.zone?.salesZoneId,
          sales_zone_type: params.zone?.salesZoneType,
          project: params.project,
        },
      });
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        if (response.data.data) {
          const data = new ApiGetListSalesOutletResponse(response.data.data);
          return Promise.resolve(data);
        }
        return Promise.resolve(null);
      } else {
        return Promise.reject(
          feCustomErrorResponse(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA)
        );
      }
    } catch (error) {
      return handleCatch(error);
    }
  },
  getPrint: async (params: SalesOutletGetPrintParam) => {
    const url = `${EXODUS_API_VERSION_3}/sales/outlet/print`;
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          start_date: params.startDate?.startOf("month").toJSON(),
          end_date: params.endDate?.endOf("month").toJSON(),
          outlet_id: params.outlets?.map((x) => x.outletId),
          user_id: params.userId,
          sector: params.sector?.value,
          sales_zone_id: params.zone?.salesZoneId,
          sales_zone_type: params.zone?.salesZoneType,
          project: params.project,
        },
      });
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        if (response.data.data) {
          const data = new ApiGetPrintSalesOutletResponse(response.data.data);
          return Promise.resolve(data);
        }
        return Promise.resolve(null);
      } else {
        return Promise.reject(
          feCustomErrorResponse(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA)
        );
      }
    } catch (error) {
      return handleCatch(error);
    }
  },
  getSummary: async (params: SalesOutletGetSummaryParam) => {
    const url = `${EXODUS_API_VERSION_3}/sales/outlet/sales-summary`;
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          start_date: params.startDate?.startOf("month").toJSON(),
          end_date: params.endDate?.endOf("month").toJSON(),
          outlet_id: params.outlets?.map((x) => x.outletId),
          user_id: params.userId,
          sector: params.sector?.value,
          sales_zone_id: params.zone?.salesZoneId,
          sales_zone_type: params.zone?.salesZoneType,
          project: params.project,
        },
      });
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        if (response.data.data) {
          const data = new ApiGetSummarySalesOutletResponse(response.data.data);
          return Promise.resolve(data);
        }
        return Promise.resolve(null);
      } else {
        return Promise.reject(
          feCustomErrorResponse(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA)
        );
      }
    } catch (error) {
      return handleCatch(error);
    }
  },
  getOne: async (params: SalesOutletGetOneParam) => {
    const url = `${EXODUS_API_VERSION_2}/sales/outlet/${params.outletId}`;
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          limit: params.limit,
          page: params.page,
          start_date: params.startDate?.startOf("month").toJSON(),
          end_date: params.endDate?.endOf("month").toJSON(),
          user_id: params.userId,
          project: params.project,
        },
      });
      if (
        response.status === SUCCESS_GET_STATUS &&
        response.data.message === SUCCESS_MESSAGE
      ) {
        if (response.data.data) {
          const data = new ApiGetDetailSalesOutletResponse(response.data.data);
          return Promise.resolve(data);
        }
        return Promise.resolve(null);
      } else {
        return Promise.reject(
          feCustomErrorResponse(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA)
        );
      }
    } catch (error) {
      return handleCatch(error);
    }
  },
  localDb: {
    listFeature: {
      filter: {
        save: async (newData: SalesOutletListFilterModel) => {
          try {
            if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

            const oldData: SalesOutletListFilterModel[] | undefined =
              await IndexedDatabase.salesOutletListFilter.toArray();

            if (oldData && oldData.length > 0) {
              IndexedDatabase.salesOutletListFilter.update(
                oldData[0],
                newData.toJson()
              );
            } else {
              IndexedDatabase.salesOutletListFilter.add(newData.toJson());
            }
            return Promise.resolve();
          } catch (error) {
            return handleCatchLocalDb();
          }
        },
        get: async () => {
          try {
            if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

            const oldData: SalesOutletListFilterModel[] | undefined =
              await IndexedDatabase.salesOutletListFilter.toArray();

            if (oldData && oldData.length > 0) {
              return Promise.resolve(
                SalesOutletListFilterModel.fromJson(oldData[0])
              );
            } else {
              return Promise.resolve(null);
            }
          } catch (error) {
            return handleCatchLocalDb();
          }
        },
      },
      metadata: {
        save: async (newData: SalesOutletSavedPaginationMetadata) => {
          try {
            if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

            const oldData: SalesOutletSavedPaginationMetadata[] | undefined =
              await IndexedDatabase.salesOutletListMeta.toArray();

            if (oldData && oldData.length > 0) {
              IndexedDatabase.salesOutletListMeta.update(
                oldData[0],
                newData.toJson()
              );
            } else {
              IndexedDatabase.salesOutletListMeta.add(newData.toJson());
            }
            return Promise.resolve();
          } catch (error) {
            return handleCatchLocalDb();
          }
        },
        get: async () => {
          try {
            if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

            const oldData: SalesOutletSavedPaginationMetadata[] | undefined =
              await IndexedDatabase.salesOutletListMeta.toArray();

            if (oldData && oldData.length > 0) {
              return Promise.resolve(
                SalesOutletSavedPaginationMetadata.fromJson(oldData[0])
              );
            } else {
              return Promise.resolve(null);
            }
          } catch (error) {
            return handleCatchLocalDb();
          }
        },
      },
    },
    detailFeature: {
      filter: {
        save: async (newData: SalesOutletDetailFilterModel) => {
          try {
            if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

            const oldData: SalesOutletListFilterModel[] | undefined =
              await IndexedDatabase.salesOutletDetailFilter.toArray();

            if (oldData && oldData.length > 0) {
              IndexedDatabase.salesOutletDetailFilter.update(
                oldData[0],
                newData.toJson()
              );
            } else {
              IndexedDatabase.salesOutletDetailFilter.add(newData.toJson());
            }
            return Promise.resolve();
          } catch (error) {
            return handleCatchLocalDb();
          }
        },
        get: async () => {
          try {
            if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

            const oldData: SalesOutletDetailFilterModel[] | undefined =
              await IndexedDatabase.salesOutletDetailFilter.toArray();

            if (oldData && oldData.length > 0) {
              return Promise.resolve(
                SalesOutletDetailFilterModel.fromJson(oldData[0])
              );
            } else {
              return Promise.resolve(null);
            }
          } catch (error) {
            return handleCatchLocalDb();
          }
        },
      },
      metadata: {
        save: async (newData: SalesOutletSavedPaginationMetadata) => {
          try {
            if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

            const oldData: SalesOutletSavedPaginationMetadata[] | undefined =
              await IndexedDatabase.salesOutletDetailMeta.toArray();

            if (oldData && oldData.length > 0) {
              IndexedDatabase.salesOutletDetailMeta.update(
                oldData[0],
                newData.toJson()
              );
            } else {
              IndexedDatabase.salesOutletDetailMeta.add(newData.toJson());
            }
            return Promise.resolve();
          } catch (error) {
            return handleCatchLocalDb();
          }
        },
        get: async () => {
          try {
            if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

            const oldData: SalesOutletSavedPaginationMetadata[] | undefined =
              await IndexedDatabase.salesOutletDetailMeta.toArray();

            if (oldData && oldData.length > 0) {
              return Promise.resolve(
                SalesOutletSavedPaginationMetadata.fromJson(oldData[0])
              );
            } else {
              return Promise.resolve(null);
            }
          } catch (error) {
            return handleCatchLocalDb();
          }
        },
      },
    },
  },
};

const handleCatch = (error: any) => {
  const response: ErrorResponse = {
    message: error.response.data.message,
    status: error.response.data.status,
    code: error.response.data.code,
  };
  return Promise.reject(response);
};

const handleCatchLocalDb = () => {
  return Promise.reject(LOCAL_DB_ERROR_RESPONSE);
};

export default SalesOutletProvider;
