import { Accordion, Center, Spinner, VStack, useTheme } from "@chakra-ui/react";
import { useBiayaMurniListContext } from "../../../../../contexts/BiayaMurniListContext";
import BiayaMurniStandarisasiAccordionItem from "./BiayaMurniStandarisasiAccordionItem";
import { BiayaMurniStandarisasiData } from "../../../../../models/apiResponses/BiayaMurniStandarisasiData";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import { useEffect, useState } from "react";
import { StandarisasiOrManagerContextProvider } from "../../../contexts/StandarisasiOrManagerContext";
import ErrorPage from "../../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../../components/DataNotFound/DataNotFound";

const BiayaMurniStandarisasiList = (): JSX.Element => {
  const theme = useTheme();

  const {
    actions: { setPage, setLimit },
    states: { data, error, isLoading },
  } = useBiayaMurniListContext();

  if (isLoading) {
    return (
      <Center p={10}>
        <Spinner color={theme.colors.exodus.primaryBlue} />
      </Center>
    );
  }

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (data?.records) {
    return (
      <StandarisasiOrManagerContextProvider
        standarisasiIds={data.records.map(
          (el) => (el as BiayaMurniStandarisasiData).standarisasiId
        )}
      >
        <VStack alignItems={"stretch"}>
          <BiayaMurniStandarisasiAccordion
            records={data.records as BiayaMurniStandarisasiData[]}
            hasProject={data.hasProject}
          />
          <Center>
            <Pagination
              handleOnNavigateFirstPage={() => {
                setPage("first");
              }}
              handleOnBack={() => {
                setPage("prev");
              }}
              handleOnNext={() => {
                setPage("next");
              }}
              handleOnLimitChange={(limit) => {
                setLimit(limit);
              }}
              totalData={data.metadata.totalData}
              totalPage={data.metadata.totalPage}
              limit={data.metadata.totalDataPerPage}
              page={data.metadata.currentPage}
            />
          </Center>
        </VStack>
      </StandarisasiOrManagerContextProvider>
    );
  }

  return <DataNotFound />;
};

const BiayaMurniStandarisasiAccordion = ({
  records,
  hasProject,
}: {
  records: BiayaMurniStandarisasiData[];
  hasProject: boolean;
}): JSX.Element => {
  const [accordionIndex, setAccordionIndex] = useState(-1);

  useEffect(() => {
    setAccordionIndex(-1);
  }, [records]);

  return (
    <Accordion allowToggle index={accordionIndex}>
      {records.map((item, i) => (
        <BiayaMurniStandarisasiAccordionItem
          key={i}
          index={i}
          hasProject={hasProject}
          data={item as BiayaMurniStandarisasiData}
          handleChangeAccordion={() => {
            if (i === accordionIndex) {
              setAccordionIndex(-1);
            } else {
              setAccordionIndex(i);
            }
          }}
        />
      ))}
    </Accordion>
  );
};

export default BiayaMurniStandarisasiList;
