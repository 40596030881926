/**
 * Kena error dibawah ini jika pakai replaceAll
 *
 * Property 'replaceAll' does not exist on type 'string'.
 * Do you need to change your target library? Try changing the 'lib' compiler option to 'es2021' or later.
 *
 * Untuk sementara pakai split dan join dulu sampai ada pembahasan/diskusi berikutnya
 */

const convertDecimalString = {
  pointToComma: (v: string) => {
    // return v.replaceAll(".", ",");
    return v.split(".").join(",");
  },
  commaToPoint: (v: string) => {
    // return v.replaceAll(",", ".");
    return v.split(",").join(".");
  },
};

export default convertDecimalString;
