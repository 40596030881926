import ApiGetListResponseMetadata from "../../../../models/common/ApiGetListResponseMetadata";
import CapitalizeFirstWord from "../../../../utils/CapitalizeFirstWord";

class GetListOutletCardModelPack {
  message: string;
  status: number;
  code: string;
  data: GetListOutletCardMetadataAndRecords | null;

  constructor(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = json["data"]
      ? new GetListOutletCardMetadataAndRecords(json["data"])
      : null;
  }
}

class GetListOutletCardMetadataAndRecords {
  metadata: ApiGetListResponseMetadata | null;
  records: GetListOutletCardModelData[];

  constructor(json: any) {
    if(json.metadata){
      this.metadata = new ApiGetListResponseMetadata(json["metadata"]);
    } else {
      this.metadata = null;
    }
    this.records =
      json["records"]?.map(
        (record: any) => new GetListOutletCardModelData(record)
      ) ?? [];
  }
}

class GetListOutletCardModelData {
  outletId: number;
  outletName: string;
  outletCode: string;
  sectorType: string;
  sisaSaldo: number;
  saldoKontribusi: number;
  project: string | null;
  biayaMurni: GetListOutletCardBiayaMurni;
  biayaDasar: GetListOutletCardBiayaDasar;

  constructor(json: any) {
    this.outletId = json["outlet_id"];
    this.outletName = json["outlet_name"];
    this.outletCode = json["outlet_code"];
    this.sectorType = json["sector_type"];
    this.sisaSaldo = json["sisa_saldo"];
    this.saldoKontribusi = json["saldo_kontribusi"];
    this.biayaMurni = new GetListOutletCardBiayaMurni(json["biaya_murni"]);
    this.biayaDasar = new GetListOutletCardBiayaDasar(json["biaya_dasar"]);
    this.project = json.Project ? CapitalizeFirstWord(json.Project) : null;
  }
}

class GetListOutletCardBiayaMurni {
  psspDoctorCost: number;
  psspNonDoctorCost: number;
  entertainCost: number;
  discountCost: number;
  totalCost: number;
  downPaymentCost: number;
  standarisasiCost: number;

  constructor(json: any) {
    this.psspDoctorCost = json["pssp_doctor_cost"];
    this.psspNonDoctorCost = json["pssp_non_doctor_cost"];
    this.entertainCost = json["entertain_cost"];
    this.discountCost = json["discount_cost"];
    this.totalCost = json["total_cost"];
    this.downPaymentCost = json["down_payment"];
    this.standarisasiCost = json["standarisasi"];
  }
}

class GetListOutletCardBiayaDasar {
  psspDoctorCost: number;
  psspNonDoctorCost: number;
  entertainCost: number;
  discountCost: number;
  totalCost: number;
  downPaymentCost: number;
  standarisasiCost: number;

  constructor(json: any) {
    this.psspDoctorCost = json["pssp_doctor_cost"];
    this.psspNonDoctorCost = json["pssp_non_doctor_cost"];
    this.entertainCost = json["entertain_cost"];
    this.discountCost = json["discount_cost"];
    this.totalCost = json["total_cost"];
    this.downPaymentCost = json["down_payment"];
    this.standarisasiCost = json["standarisasi"];
  }
}

export {
  GetListOutletCardModelPack,
  GetListOutletCardMetadataAndRecords,
  GetListOutletCardModelData,
  GetListOutletCardBiayaMurni,
  GetListOutletCardBiayaDasar,
};
