import { createContext } from "react";
import SalesTargetFilterAndPaginationModel from "../../models/SalesTargetFilterAndPaginationModel";
import ErrorResponse from "../../../../../models/common/ErrorResponse";
import {
  ListSalesTargetMetadata,
  ListSalesTargetRecords,
} from "../../models/SalesTargetModelPack";

type MonitorSalesTargetContextProps = {
  filterValues: SalesTargetFilterAndPaginationModel | null;
  loadingFilter: boolean;
  errorFilter: string | null;
  setFilterMonitorSalesTarget: (
    data: SalesTargetFilterAndPaginationModel
  ) => void;
  salesTargetData: ListSalesTargetRecords[];
  metadataSalesTarget: ListSalesTargetMetadata | null;
  salesTargetLoading: boolean;
  salesTargetError: ErrorResponse | null;
  selectedSalesTarget: number[];
  setSelectedSalesTarget: (data: number[]) => void;
  handleChangePageOrLimit: (
    type: "first" | "next" | "prev" | "limit",
    newLimit?: number
  ) => void;
  handleClearFilter: () => void;
  refetchSalesTargetData: () => void;
};

const defaultValue: MonitorSalesTargetContextProps = {
  filterValues: null,
  loadingFilter: false,
  errorFilter: null,
  setFilterMonitorSalesTarget: () => {},
  salesTargetData: [],
  metadataSalesTarget: null,
  salesTargetLoading: true,
  salesTargetError: null,
  selectedSalesTarget: [],
  setSelectedSalesTarget: () => {},
  handleChangePageOrLimit: () => {},
  handleClearFilter: () => {},
  refetchSalesTargetData: () => {},
};

const MonitorSalesTargetContext = createContext(defaultValue);

export default MonitorSalesTargetContext;
