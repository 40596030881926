import { AxiosResponse, isAxiosError } from "axios";
import AxiosBaseClient from "../../../../dataProviders/config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import DateExodus from "../../../../models/DateExodus";
import {
  AllListTargetModelPack,
  ListSalesTargetModelPack,
} from "../models/SalesTargetModelPack";
import {
  AllListSalesTargetParams,
  ApproveSalesTargetParams,
  InputSalesTargetParams,
  ListSalesTargetParams,
  TotalMonthlyParams,
} from "./SalesTargetParamType";
import { TotalMonthlyModel } from "../models/TotalMonthlyModel";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const SalesTargetProvider = {
  getList: async (params: ListSalesTargetParams) => {
    const url = `/v2/target-sales`;

    const queryParam = {
      start_date: new DateExodus(
        params.startDate?.getEpochStartOfMonth()
      ).toJSON(),
      end_date: new DateExodus(params.endDate?.getEpochStartOfMonth()).toJSON(),
      user_id: params.userId,
      page: params.page,
      limit: params.limit,
      project: params.project?.value
    };
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: queryParam,
      });

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new ListSalesTargetModelPack(response.data);
          if (!responseData.data) {
            return {
              data: [],
              metadata: [],
            };
          }
          return {
            data: responseData.data.records,
            metadata: responseData.data.metadata,
          };
        } else {
          const errorResponse: ErrorResponse = {
            message: response.data.message,
            status: response.data.status,
            code: response.data.code,
          };

          return Promise.reject(errorResponse);
        }
      }
    } catch (error) {
      return handleOnError(error);
    }
  },
  getListAll: async (params: AllListSalesTargetParams) => {
    const url = `/v2/target-sales/all`;

    const queryParam = {
      start_date: new DateExodus(
        params.startDate.getEpochStartOfMonth()
      ).toJSON(),
      end_date: new DateExodus(params.endDate.getEpochStartOfMonth()).toJSON(),
      user_id: params.userId,
      project: params.project?.value
    };
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: queryParam,
      });

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new AllListTargetModelPack(response.data);

          return Promise.resolve(responseData);
        } else {
          const errorResponse: ErrorResponse = {
            message: response.data.message,
            status: response.data.status,
            code: response.data.code,
          };

          return Promise.reject(errorResponse);
        }
      }
    } catch (error) {
      return handleOnError(error);
    }
  },
  getTotalMonthly: async (params: TotalMonthlyParams) => {
    const url = `/v2/target-sales/total`;

    const queryParam = {
      start_date: new DateExodus(
        params.startDate?.getEpochStartOfMonth()
      ).toJSON(),
      end_date: new DateExodus(params.endDate?.getEpochStartOfMonth()).toJSON(),
      project: params.project?.value
    };

    try {
      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: queryParam,
      });

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const result: TotalMonthlyModel[] = response.data.data.map(
            (json: any) => {
              return {
                totalValueTarget: json["total_value_target"],
                salesPeriode: DateExodus.fromBackend(json["sales_period"]),
              };
            }
          );
          return Promise.resolve(result);
        } else {
          const errorResponse: ErrorResponse = {
            message: response.data.message,
            status: response.data.status,
            code: response.data.code,
          };
          return Promise.reject(errorResponse);
        }
      }
    } catch (error) {
      return handleOnError(error);
    }
  },
  inputAndEditSalesTarget: async (params: InputSalesTargetParams) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.post(
        "/v2/target-sales",
        params.data,
        {
          params: {
            project: params.project?.value
          }
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          return Promise.resolve();
        } else {
          const errorResponse: ErrorResponse = {
            message: response.data.message,
            status: response.data.status,
            code: response.data.code,
          };

          return Promise.reject(errorResponse);
        }
      }
    } catch (error: any) {
      return handleOnError(error);
    }
  },
  approveSalesTarget: async (params: ApproveSalesTargetParams) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.post(
        "/v2/target-sales/approve",
        params
      );

      if (response.status === SUCCESS_GET_STATUS) {
        return Promise.resolve();
      } else {
        const errorResponse: ErrorResponse = {
          message: response.data.message,
          status: response.data.status,
          code: response.data.code,
        };

        return Promise.reject(errorResponse);
      }
    } catch (error: any) {
      return handleOnError(error);
    }
  },
};

const handleOnError = (error: unknown) => {
  if (isAxiosError(error)) {
    return Promise.reject({
      message: error.response ? error.response.data.message : error.message,
      status: error.response?.status,
      code: error.response ? error.response.data.code : error.code,
    });
  }
  return Promise.reject(UNKNOWN_ERROR_RESPONSE);
};

export default SalesTargetProvider;
