import InputSalesTargetContext from "../context/InputSalesTargetContext";
import InputSalesTargetPage from "./InputSalesTargetPage";
import useTitle from "../../../../../services/useTitle";
import useGetListSalesTarget from "../../services/useGetListSalesTarget";
import useSetFilterValueInputSalesTarget from "../services/useSetFilterValueInputSalesTarget";
import DateExodus from "../../../../../models/DateExodus";

const InputSalesTargetBase: React.FC = () => {
  const { filterValues, loadingFilter, errorFilter, saveDataToIndexedDB } =
    useSetFilterValueInputSalesTarget();

  const convertEpochToDateExodus = (data?: DateExodus | number | null) => {
    if (typeof data === "number") {
      return new DateExodus(data);
    } else if (data === null) {
      return undefined;
    } else {
      return data;
    }
  };

  const { salesTargetData, metadata, loading, error, refetchSalesTargetData } =
    useGetListSalesTarget({
      endDate: convertEpochToDateExodus(filterValues?.periodeKuartalEnd),
      startDate: convertEpochToDateExodus(filterValues?.periodeKuartalStart),
      limit: filterValues?.limit,
      page: filterValues?.page,
      project: filterValues ? filterValues.project : null,
    });

  useTitle("Input Target Sales");

  return (
    <InputSalesTargetContext.Provider
      value={{
        salesTargetData: salesTargetData,
        loadingListSalesTarget: loading,
        errorListSalesTarget: error,
        metadata: metadata,
        filterValues: filterValues,
        loadingFilter: loadingFilter,
        errorFilter: errorFilter,
        saveDataToIndexedDB: saveDataToIndexedDB,
        refetchSalesTargetData: refetchSalesTargetData,
      }}
    >
      <InputSalesTargetPage />
    </InputSalesTargetContext.Provider>
  );
};

export default InputSalesTargetBase;
