export enum RencanaStatus {
  Approved = "Approved",
  Pending = "Pending",
  Request = "Request",
  Rejected = "Rejected",
}

export type ApprovalRencanaStatus = {
  status_id: RencanaStatus;
  status_label: string;
};
