import {
  Center,
  HStack,
  Spacer,
  Spinner,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import ErrorPage from "../../../../../../../components/ErrorPage/ErrorPage";
import PageTitle from "../../../../../../../components/PageTitle/PageTitle";
import { useSalesOutletDetailFilterContext } from "../../contexts/SalesOutletDetailFilterContext";
import { useSalesOutletDetailContext } from "../../contexts/SalesOutletDetailContext";
import SalesOutletDetailSummarySection from "../common/SalesOutletSummarySection";
import BackButton from "./parts/BackButton";
import DataNotFound from "../../../../../../../components/DataNotFound/DataNotFound";
import SalesOutletDetailTable from "./parts/SalesOutletDetailTable";
import ApiGetDetailSalesOutletResponse from "../../models/apiResponses/ApiGetDetailSalesOutletResponse";
import { projectEnumToUi } from "../../../../../../../models/common/Project";

const SalesOutletDetailPageDesktop = (): JSX.Element => {
  const {
    states: { error },
  } = useSalesOutletDetailFilterContext();

  if (error) {
    return (
      <VStack alignItems={"stretch"}>
        <HStack>
          <PageTitle label={"Sales Outlet Detail"} />
          <Spacer />
          <BackButton />
        </HStack>
        <ErrorPage message={error} />
      </VStack>
    );
  }

  return <ContentUnderFilter />;
};

const ContentUnderFilter = (): JSX.Element => {
  const theme = useTheme();

  const {
    states: { data, error, isLoading },
  } = useSalesOutletDetailContext();

  if (isLoading) {
    return (
      <PageFrameWithFilter
        child={
          <Center p={10}>
            <VStack>
              <Spinner color={theme.colors.exodus.primaryBlue} />
              <Text>Loading Detail Sales Outlet</Text>
            </VStack>
          </Center>
        }
      />
    );
  }

  if (error) {
    return (
      <PageFrameWithFilter child={<ErrorPage message={error.message} />} />
    );
  }

  if (data) {
    return (
      <PageFrameWithFilter data={data} child={<SalesOutletDetailTable />} />
    );
  }

  return <PageFrameWithFilter child={<DataNotFound />} />;
};

const PageFrameWithFilter = ({
  child,
  data,
}: {
  child: JSX.Element;
  data?: ApiGetDetailSalesOutletResponse;
}): JSX.Element => {
  return (
    <VStack alignItems={"stretch"} flex={1}>
      <HStack>
        <VStack alignItems={"stretch"} gap={0}>
          {data ? (
            <>
              {data.hasProject && (
                <PageTitle label={projectEnumToUi(data.project)} />
              )}
              <PageTitle
                label={`${data.outlet.outletName} (${data.outlet.outletCode})`}
              />
              <Text fontSize={24} textAlign={"start"} lineHeight={0.8} mb={6}>
                Sektor: {data.outlet.sector.label}
              </Text>
            </>
          ) : (
            <PageTitle label={"Sales Outlet Detail"} />
          )}
          <SalesOutletDetailSummarySection />
        </VStack>
        <Spacer />
        <BackButton />
      </HStack>
      {child}
    </VStack>
  );
};

export default SalesOutletDetailPageDesktop;
