import FilterBox from "../../../../../components/FilterBox/FilterBox";
import { Stack } from "@chakra-ui/react";
import { useSalesProductContext } from "../../contexts/SalesProductContextProvider";
import ProductModel from "../../../../../models/Product/ProductModel";

const FilterBoxArea = () => {
  const {
    states: { filterValues },
  } = useSalesProductContext();

  return (
    <Stack
      flexDirection={"row"}
      overflow={"scroll"}
      maxWidth={"70%"}
      flexWrap={"wrap"}
      overflowWrap={"break-word"}
      maxHeight={"150px"}
      css={{
        "&::-webkit-scrollbar": {
          width: "0px",
          background: "transparent",
          display: "none",
        },
      }}
    >
      {filterValues?.project && (
        <FilterBox title={"Project"} content={filterValues.project.label} />
      )}
      {filterValues?.endPeriod && filterValues.startPeriod && (
        <FilterBox
          title={"Periode"}
          content={`${filterValues.startPeriod.getStringDateFormat(
            "MMMM YYYY"
          )} - ${filterValues.endPeriod.getStringDateFormat("MMMM YYYY")}`}
        />
      )}
      {filterValues?.sector && (
        <FilterBox title={"Sektor"} content={filterValues.sector.label} />
      )}
      {filterValues?.zone && (
        <FilterBox title={"Zona"} content={filterValues.zone.salesZoneName} />
      )}
      {filterValues?.bawahan && (
        <FilterBox
          title={`Nama ${filterValues.role?.name.toUpperCase()}`}
          content={filterValues.bawahan.name}
        />
      )}
      {filterValues?.productList &&
        filterValues.productList.map(
          (eachProduct: ProductModel, key: number) => {
            return (
              <FilterBox
                key={key}
                title={`Product ${key + 1}`}
                content={eachProduct.name}
              />
            );
          }
        )}
    </Stack>
  );
};

export default FilterBoxArea;
