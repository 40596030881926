import { Button, useMediaQuery, useTheme, useToast } from "@chakra-ui/react";
import DialogKonfirmasiRealisasiKunjungan from "./DialogKonfirmasiRealisasiKunjungan";
import { useContext, useState } from "react";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";
import useUpdateStatusRealisasi from "../../Approval/services/useUpdateStatusRealisasi";
import { UpdateStatusRealisasiBodyModel } from "../../Approval/models/UpdateStatusRealisasiBodyModel";
import DateExodus from "../../../../../models/DateExodus";
import { CommonStatus } from "../../../../../models/common/Status";

const ButtonSubmitRealisasiKunjungan: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const { selectedPeriode, key, setKey } = useContext(
    InputRealisasiKunjunganContext
  );

  const { updateStatusRealisasi, loading } = useUpdateStatusRealisasi();

  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const handleSubmitRealisasiKunjungan = () => {
    const submittedData: UpdateStatusRealisasiBodyModel = {
      status: "Request",
    };

    updateStatusRealisasi(selectedPeriode!.header_id, submittedData, {
      onSuccess: () => {
        setOpenConfirmationDialog(false);
        toast({
          title: "Berhasil Submit Realisasi Kunjungan",
          status: "success",
        });
        setKey(key + 1);
      },
      onError: (errorMessage: string) => {
        toast({
          title: "Gagal Submit Realisasi Kunjungan",
          description: `${errorMessage}`,
          status: "error",
        });
      },
    });
  };

  const disableButtonSubmitCondition = (): boolean => {
    if (!selectedPeriode) return true;

    const dateNow = new DateExodus();

    const isDateNowGreaterThanSelectedEndPeriod: boolean =
      parseInt(JSON.stringify(dateNow)) >
      parseInt(JSON.stringify(selectedPeriode.end_period));

    if (
      selectedPeriode.status_realization !== CommonStatus.Pending ||
      selectedPeriode.status_plan !== CommonStatus.Approved
    ) {
      return true;
    } else if (selectedPeriode.realization_is_empty) {
      return true;
    } else if (isDateNowGreaterThanSelectedEndPeriod) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Button
        colorScheme="buttonPrimary"
        width="100%"
        maxWidth={isDesktopView ? "200px" : undefined}
        px={0}
        isDisabled={disableButtonSubmitCondition()}
        onClick={() => setOpenConfirmationDialog(true)}
      >
        Submit
      </Button>

      <DialogKonfirmasiRealisasiKunjungan
        openConfirmationDialog={openConfirmationDialog}
        onSubmit={handleSubmitRealisasiKunjungan}
        onClose={() => setOpenConfirmationDialog(false)}
        loading={loading}
        dialogTitle="Submit Realisasi Kunjungan"
        dialogContent="Apakah Anda yakin mau Submit Realisasi Kunjungan ini ?"
      />
    </>
  );
};

export default ButtonSubmitRealisasiKunjungan;
