import {
  Grid,
  GridItem,
  useTheme,
  Text,
  Button,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import DetailApprovalKlaimSalesContext from "../../../contexts/DetailApprovalKlaimSalesContext";
import ButtonApproveKlaimSales from "../ButtonApproveKlaimSales";

const DesktopDetailApprovalKlaimSalesBasicInformation: React.FC = () => {
  const theme = useTheme();

  const { detailKlaimSalesData: record, openPdf } = useContext(
    DetailApprovalKlaimSalesContext
  );

  if (!record) return null;
  return (
    <>
      <Grid
        templateColumns="repeat(10, 1fr)"
        gap={1}
        marginTop="16px"
        justifyContent="space-between"
      >
        <GridItem colSpan={7}>
          <Grid
            templateColumns="repeat(18, 1fr)"
            marginBottom="8px"
            textAlign={"left"}
          >
            <GridItem colSpan={4}>
              <Text
                fontSize={16}
                fontWeight="bold"
                color={theme.colors.exodus.primaryBlue}
              >
                Outlet Panel
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text textAlign={"center"} fontSize={16}>
                :
              </Text>
            </GridItem>
            <GridItem colSpan={10}>
              <Text fontSize={16}>
                {record.outletPanelName} - {record.outletPanelCode}
              </Text>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(18, 1fr)"
            marginBottom="8px"
            textAlign={"left"}
          >
            <GridItem colSpan={4}>
              <Text
                fontSize={16}
                fontWeight="bold"
                color={theme.colors.exodus.primaryBlue}
              >
                Outlet Tujuan
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text textAlign={"center"} fontSize={16}>
                :
              </Text>
            </GridItem>
            <GridItem colSpan={10}>
              <Text fontSize={16}>
                {record.outletTujuanName} - {record.outletTujuanCode}
              </Text>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(18, 1fr)"
            marginBottom="8px"
            textAlign={"left"}
          >
            <GridItem colSpan={4}>
              <Text
                fontSize={16}
                fontWeight="bold"
                color={theme.colors.exodus.primaryBlue}
              >
                Tanggal Pengajuan
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text textAlign={"center"} fontSize={16}>
                :
              </Text>
            </GridItem>
            <GridItem colSpan={10}>
              <Text fontSize={16}>
                {record.createdAt.getStringDateFormat("D MMMM YYYY")}
              </Text>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(18, 1fr)"
            marginTop={5}
            marginBottom={7}
            textAlign={"left"}
          >
            <GridItem colSpan={4}>
              <Text
                fontSize={16}
                fontWeight="bold"
                color={theme.colors.exodus.primaryBlue}
              >
                Lihat PDF
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text textAlign={"center"} fontSize={16}>
                :
              </Text>
            </GridItem>
            <GridItem colSpan={10}>
              <Button
                colorScheme="buttonPrimary"
                variant="ghost"
                size={"md"}
                sx={{
                  textDecoration: "underline",
                  padding: 0,
                  fontWeight: "normal",
                  height: "24px",
                }}
                onClick={() => openPdf(record.pdfLink)}
                disabled={!record.pdfLink}
              >
                Lihat PDF
              </Button>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem colSpan={3} textAlign={"right"}>
          <ButtonApproveKlaimSales />
        </GridItem>
      </Grid>
    </>
  );
};

export default DesktopDetailApprovalKlaimSalesBasicInformation;
