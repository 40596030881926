import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Text,
  Wrap,
  WrapItem,
  useTheme,
} from "@chakra-ui/react";
import { MdRemoveRedEye } from "react-icons/md";
import HorizontalAccordionDetail from "../../../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import SalesOutletListSingularData from "../../../models/SalesOutletListSingularData";
import CurrencyIdrFormatter from "../../../../../../../../utils/CurrencyIdrFormatter";
import { useNavigate } from "react-router-dom";
import { generateSalesOutletDetailUri } from "../../../../../utils/utils";
import { useSalesOutletListContext } from "../../../contexts/SalesOutletListContext";
import { projectEnumToUi } from "../../../../../../../../models/common/Project";

const SalesOutletAccordionItem = ({
  data,
  handleChangeAccordion,
  hasProject,
}: {
  data: SalesOutletListSingularData;
  handleChangeAccordion: Function;
  hasProject: boolean;
}): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    states: { filterValues },
  } = useSalesOutletListContext();

  return (
    <AccordionItem borderColor={theme.colors.exodus.background}>
      <AccordionButton
        px={0}
        onClick={() => {
          handleChangeAccordion();
        }}
      >
        <Box sx={{ width: "100%", textAlign: "left" }}>
          {hasProject && (
            <Text
              fontSize={14}
              fontWeight={600}
              color={theme.colors.exodus.primaryBlue}
            >
              {projectEnumToUi(data.project)}
            </Text>
          )}
          <Text
            fontSize={14}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
          >
            {data.outletName}
          </Text>
          <Text fontSize={14}>
            {data.outletPharosCode + " - " + data.sector.label}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4} pt={0}>
        <HorizontalAccordionDetail
          leftText={"Sales Value"}
          rightSide={CurrencyIdrFormatter.convertToRp(data.salesValue)}
          size="small"
        />
        <HorizontalAccordionDetail
          leftText={"Estimasi Sales"}
          rightSide={CurrencyIdrFormatter.convertToRp(data.estimatedValue)}
          size="small"
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText={""}
          rightSide={
            <Button
              colorScheme="buttonSecondary"
              variant={"ghost"}
              leftIcon={<MdRemoveRedEye fontSize={16} />}
              onClick={() => {
                navigate(
                  generateSalesOutletDetailUri({
                    outletId: data.outletId,
                    startDate: filterValues?.periode.awal,
                    endDate: filterValues?.periode.akhir,
                    userId: filterValues?.pekerja?.id,
                    project: filterValues?.project,
                  })
                );
              }}
            >
              <Wrap spacing={1}>
                <WrapItem>
                  <Text>Lihat</Text>
                </WrapItem>
                <WrapItem>
                  <Text>Detail</Text>
                </WrapItem>
              </Wrap>
            </Button>
          }
          size="small"
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default SalesOutletAccordionItem;
