import { Box, Center, Divider } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import useMobileBudgetCardStyle from "./cardStyleMobile";
import {
  convertAchievement,
  convertCostRatio,
  dateConverterNumberToString,
} from "../utils/utils";
import { SimpleGrid } from "@chakra-ui/react";
import Budget from "../models/BudgetModel";
import CurrencyIdrFormatter from "../../../utils/CurrencyIdrFormatter";

type DataMonitoringBudgeting = {
  data: Budget;
};

const MobileBudgetCard: React.FC<DataMonitoringBudgeting> = ({ data }) => {
  const {
    cardContainer,
    cardDivider,
    cardRow,
    cardRowEven,
    cardTitle,
    cardSubtitle,
    cardValue,
  } = useMobileBudgetCardStyle();

  return (
    <Box sx={cardContainer}>
      <Text sx={cardTitle}>{dateConverterNumberToString(data.bulan)}</Text>

      {/* Sales/Kumulatif Title Row */}
      <SimpleGrid
        sx={{ ...cardRow, marginTop: "12px" }}
        columns={2}
        spacing={5}
      >
        <Text sx={cardSubtitle}>Sales</Text>
        <Text sx={cardSubtitle}>Kumulatif</Text>
      </SimpleGrid>

      {/* Target Row */}
      <SimpleGrid sx={{ ...cardRow }} columns={2} spacing={5}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <Text sx={cardSubtitle}>Target</Text>
          <Text sx={cardValue}>
            {CurrencyIdrFormatter.convertToRp(data.targetSales)}
          </Text>
        </Box>
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <Text sx={cardValue}>
            {CurrencyIdrFormatter.convertToRp(data.targetKumulatif)}
          </Text>
        </Box>
      </SimpleGrid>

      {/* Estimasi Row */}
      <SimpleGrid sx={cardRowEven} columns={2} spacing={5}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <Text sx={cardSubtitle}>Estimasi</Text>
          <Text sx={cardValue}>
            {CurrencyIdrFormatter.convertToRp(data.estimasiSales)}
          </Text>
        </Box>
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <Text sx={cardValue}>
            {CurrencyIdrFormatter.convertToRp(data.estimasiKumulatif)}
          </Text>
        </Box>
      </SimpleGrid>

      {/* Realisasi Row */}
      <SimpleGrid sx={cardRow} columns={2} spacing={5}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <Text sx={cardSubtitle}>Realisasi</Text>
          <Text sx={cardValue}>
            {CurrencyIdrFormatter.convertToRp(data.realisasiSales)}
          </Text>
        </Box>
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <Text sx={cardValue}>
            {CurrencyIdrFormatter.convertToRp(data.realisasiKumulatif)}
          </Text>
        </Box>
      </SimpleGrid>

      {/* Achievement Row */}
      <SimpleGrid sx={cardRowEven} columns={2} spacing={5}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <Text sx={cardSubtitle}>Achievement</Text>
          <Text sx={cardValue}>
            {convertAchievement(data.achievementSales)}
          </Text>
        </Box>
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <Text sx={cardValue}>
            {convertAchievement(data.achievementKumulatif)}
          </Text>
        </Box>
      </SimpleGrid>

      {/* Divider */}
      <Center>
        <Divider sx={cardDivider} />
      </Center>

      {/* Biaya Title Row */}
      <SimpleGrid sx={cardRow} columns={2} spacing={5}>
        <Box></Box>
        <Text sx={cardSubtitle}>Biaya</Text>
      </SimpleGrid>

      {/* PSSP Row */}
      <SimpleGrid sx={cardRow} columns={2} spacing={5}>
        <Text sx={cardSubtitle}>PSSP</Text>
        <Text sx={cardValue}>
          {CurrencyIdrFormatter.convertToRp(data.biayaPssp)}
        </Text>
      </SimpleGrid>

      {/* Entertain Row */}
      <SimpleGrid sx={cardRowEven} columns={2} spacing={5}>
        <Text sx={cardSubtitle}>Entertain</Text>
        <Text sx={cardValue}>
          {CurrencyIdrFormatter.convertToRp(data.biayaEntertainment)}
        </Text>
      </SimpleGrid>

      {/* DPL/DPF Row */}
      <SimpleGrid sx={cardRow} columns={2} spacing={5}>
        <Text sx={cardSubtitle}>DPL/DPF</Text>
        <Text sx={cardValue}>
          {CurrencyIdrFormatter.convertToRp(data.biayaDplDpf)}
        </Text>
      </SimpleGrid>

      {/* Standarisasi Row */}
      <SimpleGrid sx={cardRowEven} columns={2} spacing={5}>
        <Text sx={cardSubtitle}>Standarisasi</Text>
        <Text sx={cardValue}>
          {CurrencyIdrFormatter.convertToRp(data.biayaStandarisasi)}
        </Text>
      </SimpleGrid>

      {/* DP Row */}
      <SimpleGrid sx={cardRow} columns={2} spacing={5}>
        <Text sx={cardSubtitle}>DP</Text>
        <Text sx={cardValue}>
          {CurrencyIdrFormatter.convertToRp(data.biayaDP)}
        </Text>
      </SimpleGrid>

      {/* Total Biaya Row */}
      <SimpleGrid sx={cardRowEven} columns={2} spacing={5}>
        <Text sx={cardSubtitle}>Total Biaya</Text>
        <Text sx={cardValue}>
          {CurrencyIdrFormatter.convertToRp(data.total)}
        </Text>
      </SimpleGrid>

      {/* Kumulatif Row */}
      <SimpleGrid sx={cardRow} columns={2} spacing={5}>
        <Text sx={cardSubtitle}>Kumulatif</Text>
        <Text sx={cardValue}>
          {CurrencyIdrFormatter.convertToRp(data.kumulatif)}
        </Text>
      </SimpleGrid>

      {/* Divider */}
      <Center>
        <Divider sx={cardDivider} />
      </Center>

      {/* Cost Ratio Row */}
      <SimpleGrid sx={cardRow} columns={2} spacing={5}>
        <Text sx={cardSubtitle}>Cost Ratio (%)</Text>
        <Text sx={cardValue}>
          {convertCostRatio(data.total, data.realisasiSales)}
        </Text>
      </SimpleGrid>
    </Box>
  );
};

export default MobileBudgetCard;
