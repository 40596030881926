import { useEffect, useState } from "react";
import { OutletModel } from "../../models/Outlet/OutletModel";
import OutletProvider from "../../dataProviders/common/Outlet/OutletProvider";
import { OutletByUserIdParam } from "../../dataProviders/common/Outlet/OutletParamType";
import ErrorResponse from "../../models/common/ErrorResponse";

const useGetOutletByUserId = ({
  params,
  startFetching = true,
}: {
  params: OutletByUserIdParam;
  startFetching?: boolean;
}) => {
  const [outletData, setData] = useState<OutletModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setError(null);
        setLoading(true);
        const paramUser =
          params.userId !== undefined ? { userId: params.userId } : {};

        const response: OutletModel[] | undefined =
          await OutletProvider.getOutletByUserId({
            userId: paramUser.userId,
            keyword: params.keyword ?? "",
          });

        setData(response!);
      } catch (error) {
        setData([]);
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setLoading(false);
      }
    }

    if (startFetching) {
      getData();
    }
  }, [params.userId, params.keyword, startFetching]);

  return { outletData, loading, error };
};

export default useGetOutletByUserId;
