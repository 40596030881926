import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
  useTheme,
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import ClearAllButton from "../../../../../components/CustomButtons/ClearAllButton";
import ApprovalRencanaFilterValues from "../models/ApprovalRencanaFilterValues";
import ApprovalRencanaKunjunganContext from "../context/ApprovalRencanaKunjunganContext";
import RencanaKunjunganFilterFormField from "./forms/RencanaKunjunganJabatanDanBawahanFormField";
import IndexedDatabase from "../../../../../database/IndexDB";
import RencanaKunjunganStatusField from "./forms/RencanaKunjunganStatusField";
import useSaveApprovalRencanaMetadata from "../services/useSaveApprovalRencanaMetadata";
import RencanaKunjunganProjectField from "./forms/RencanaKunjunganProjectField";

type DialogProps = {
  onClose: () => void;
  isOpen: boolean;
};

const FilterApprovalRencanaKunjungan = ({ isOpen, onClose }: DialogProps) => {
  const theme = useTheme();

  const {
    setSelectedPeriode,
    setFilterValues,
    selectedPeriode,
    periodeData,
    metadata,
    setPage,
  } = useContext(ApprovalRencanaKunjunganContext);

  const [indexedDBData, setIndexedDBData] = useState<
    ApprovalRencanaFilterValues[]
  >([]);

  const { saveMetadata } = useSaveApprovalRencanaMetadata();

  const methods = useForm({
    defaultValues: {
      jabatan: null,
      bawahan: null,
      periode: periodeData[0],
      status: null,
      project: null,
    },
  });

  const resetForm = async () => {
    methods.reset();
    methods.setValue("periode", periodeData[0]);
    methods.setValue("bawahan", null);
    methods.setValue("jabatan", null);
    methods.setValue("status", null);
    methods.setValue("project", null);
    handleOnSubmitFilter();
  };

  const closeFilter = () => {
    methods.reset();
    onClose();
  };

  const getIndexedDBData = async () => {
    const data = await IndexedDatabase.rencanaKunjunganFilter.toArray();
    setIndexedDBData(data);

    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      await getIndexedDBData();
    };

    fetchData();
  }, []);

  const handleOnSubmitFilter = async () => {
    await IndexedDatabase.open();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updatedFilter: any = {
      jabatan: methods.getValues("jabatan"),
      bawahan: methods.getValues("bawahan"),
      periode: {
        start_period: selectedPeriode?.start_period.getEpochDate(),
        end_period: selectedPeriode?.end_period.getEpochDate(),
      },
      status: methods.getValues("status"),
      project: methods.getValues("project"),
    };
    setFilterValues(updatedFilter);
    setSelectedPeriode(selectedPeriode);

    await saveMetadata({
      limit: metadata!.limit,
      currentPage: 1,
    });
    setPage(1);

    try {
      if (indexedDBData.length === 0 || !indexedDBData) {
        await IndexedDatabase.rencanaKunjunganFilter
          .add(updatedFilter)
          .then(async () => await getIndexedDBData());
      } else {
        await IndexedDatabase.rencanaKunjunganFilter.update(
          indexedDBData[0].id,
          {
            jabatan: updatedFilter.jabatan,
            bawahan: updatedFilter.bawahan,
            status: updatedFilter.status,
            periode: {
              start_period: updatedFilter.periode.start_period,
              end_period: updatedFilter.periode.end_period,
            },
            project: updatedFilter.project,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        autoFocus={false}
        size={"xl"}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleOnSubmitFilter)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Center
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    gap: 10,
                  }}
                >
                  <Text
                    fontSize="2xl"
                    sx={{ color: theme.colors.exodus.primaryBlue }}
                  >
                    Filter Data
                  </Text>
                  <ClearAllButton onClick={resetForm} />
                </Center>
              </ModalHeader>
              <ModalBody>
                <RencanaKunjunganProjectField />
                <RencanaKunjunganFilterFormField />
                <RencanaKunjunganStatusField />
              </ModalBody>
              <ModalFooter>
                <Center
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    sx={{ width: "48%" }}
                    colorScheme="buttonSecondary"
                    variant="outline"
                    onClick={closeFilter}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{ width: "48%" }}
                    colorScheme="buttonPrimary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Center>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default FilterApprovalRencanaKunjungan;
