import { useTheme } from "@chakra-ui/react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useContext } from "react";
import FilterApprovalRealisasiKunjunganContext from "../context/FilterApprovalRealisasiKunjunganContext";
import DateExodus from "../../../../../models/DateExodus";
import useGetListAllApproval from "../../../services/useGetListAllApproval";

type AppliedFilter = {
  key: string;
  value: any;
};

const useExportApprovalKunjungan = () => {
  const theme = useTheme();
  const { filterValues } = useContext(FilterApprovalRealisasiKunjunganContext);
  const {allApprovalData} = useGetListAllApproval({
    kunjungan: "Realisasi",
    start_period: filterValues.periode?.start_period!,
    end_period: filterValues.periode?.end_period!,
    bawahan: filterValues.bawahan ? [filterValues.bawahan.id] : [],
    status:filterValues.status?.status_label ?? undefined,
    project: filterValues.project
  });

  const formattedPeriode = (): string => {
    return `${filterValues.periode?.start_period.getStringDateFormat(
      "D MMMM YYYY"
    )} - ${filterValues.periode?.end_period.getStringDateFormat(
      "D MMMM YYYY"
    )}`;
  };

  const formattedStartPeriode = (): string => {
    return `${filterValues.periode?.start_period.getStringDateFormat(
      "D MMMM YYYY"
    )}`;
  };

  const formattedEndPeriode = (): string => {
    return `${filterValues.periode?.end_period.getStringDateFormat(
      "D MMMM YYYY"
    )}`;
  };

  const formattedApprovalTime = (
    epoch: DateExodus | null | undefined
  ): string => {
    if (epoch === null || epoch === undefined) {
      return "-";
    } else {
      return epoch.getStringDateFormat("D MMM YYYY HH:mm:ss");
    }
  };

  const exportData = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet();

    worksheet.columns = [
      { key: "A", width: 25 },
      { key: "B", width: 25 },
      { key: "C", width: 25 },
      { key: "D", width: 25 },
      { key: "E", width: 30 },
      { key: "F", width: 30 },
      { key: "G", width: 28 },
      { key: "H", width: 28 },
      { key: "I", width: 28 },
      { key: "J", width: 30 },
      { key: "K", width: 30 },
      { key: "L", width: 30 },
    ];

    // merge cell dari A1 sampai L1 (Row title)
    worksheet.mergeCells("A1", "L1");

    const titleCell = worksheet.getRow(1).getCell("A");

    titleCell.alignment = {
      horizontal: "center",
    };

    titleCell.value = `Approval Kunjungan ${formattedPeriode()}`;

    // Set font Style
    titleCell.font = {
      bold: true,
      size: 16,
      color: { argb: theme.colors.exodus.white.slice(1) }, //Remove the '#' from the Hex value
    };

    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: theme.colors.exodus.primaryBlue.slice(1) }, //Remove the '#' from the Hex value
    };

    // inisiasi pada baris ke 3 jadi Header table
    const rowHeaderPosition = 3;
    const rowHeader = worksheet.getRow(rowHeaderPosition);

    // Isi data Header
    rowHeader.getCell(1).value = "Project";
    rowHeader.getCell(2).value = "Nama";
    rowHeader.getCell(3).value = "NIP";
    rowHeader.getCell(4).value = "Jabatan";
    rowHeader.getCell(5).value = "Periode Mulai Kunjungan";
    rowHeader.getCell(6).value = "Periode Selesai Kunjungan";
    rowHeader.getCell(7).value = "Status Approval Rencana";
    rowHeader.getCell(8).value = "Waktu Approval Rencana";
    rowHeader.getCell(9).value = "Status Approval Realisasi";
    rowHeader.getCell(10).value = "Waktu Approval Realisasi";
    rowHeader.getCell(11).value = "Total Rencana Kunjungan";
    rowHeader.getCell(12).value = "Total Realisasi Kunjungan";

    for (let i = 1; i <= 12; i++) {
      rowHeader.getCell(i).font = {
        size: 14,
        bold: true,
      };
    }

    for (let i = 0; i < allApprovalData.length; i++) {
      const row = worksheet.getRow(rowHeaderPosition + 1 + i);
      row.alignment = {
        wrapText: true,
      };

      row.getCell(1).value = allApprovalData[i].project?.label ?? "-";
      row.getCell(2).value = allApprovalData[i].userName;
      row.getCell(3).value = allApprovalData[i].nip;
      row.getCell(4).value = allApprovalData[i].roleLabel;
      row.getCell(5).value = formattedStartPeriode();
      row.getCell(6).value = formattedEndPeriode();
      row.getCell(7).value = allApprovalData[i].approvalPlanStatus;
      row.getCell(8).value = formattedApprovalTime(
        allApprovalData[i].approvalPlanTime
      );
      row.getCell(9).value = allApprovalData[i].approvalRealisasiStatus;
      row.getCell(10).value = formattedApprovalTime(
        allApprovalData[i].approvalRealisasiTime
      );
      row.getCell(11).value = `${allApprovalData[i].totalRencana}`;
      row.getCell(12).value = `${allApprovalData[i].totalRealisasi}`;
    }

    if (
      filterValues &&
      ((filterValues.role && filterValues.bawahan) || filterValues.project || filterValues.status)
    ) {
      const worksheetFilter = workbook.addWorksheet();
      worksheetFilter.name = "Filter";

      const appliedFilter: AppliedFilter[] = [];

      if (filterValues.project) {
        appliedFilter.push({
          key: "Project",
          value: filterValues.project.label,
        });
      }

      if (filterValues.role && filterValues.bawahan) {
        appliedFilter.push({
          key: "Jabatan",
          value: filterValues.role.label,
        });
        appliedFilter.push({
          key: "Nama",
          value: filterValues.bawahan.name,
        });
      }

      if (filterValues.status) {
        appliedFilter.push({
          key: "Status",
          value: filterValues.status.status_label,
        });
      }

      worksheetFilter.columns = [
        { key: "A", width: 14 },
        { key: "B", width: 14 },
      ];

      for (let i = 0; i < appliedFilter.length; i++) {
        const row = worksheetFilter.getRow(i + 1);

        row.getCell(1).value = appliedFilter[i].key;
        row.getCell(2).value = appliedFilter[i].value;
      }
    }

    //Persiapan download excel yang sudah generated
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `Approval Kunjungan ${formattedPeriode()}.xlsx`);
  };

  return { exportData };
};

export default useExportApprovalKunjungan;
