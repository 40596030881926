import { Box, Flex, useMediaQuery, useTheme } from "@chakra-ui/react";
import ButtonFilterMonitorKunjunganUserList from "./ButtonFilterMonitorKunjunganUserList";
import { useContext } from "react";
import MonitorKunjunganUserListContext from "../context/MonitorKunjunganUserListContext";
import FilterBox from "../../../../../components/FilterBox/FilterBox";

const FilterAreaMonitorKunjunganUserList: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { savedFilter } = useContext(MonitorKunjunganUserListContext);

  return (
    <Flex
      gap={isDesktopView ? 5 : 0}
      paddingTop="16px"
      direction={isDesktopView ? "row" : "column"}
    >
      <Box width="100%" display="flex" flexWrap="wrap">
        {savedFilter && savedFilter.jabatan && (
          <FilterBox title="Jabatan" content={savedFilter.jabatan.label} />
        )}
        {savedFilter && savedFilter.user && (
          <FilterBox title="Nama" content={savedFilter.user.name} />
        )}
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <ButtonFilterMonitorKunjunganUserList />
      </Box>
    </Flex>
  );
};

export default FilterAreaMonitorKunjunganUserList;
