import { useState } from "react";
import SalesDistributorFilterValue from "../model/SalesDistributorFilterValues";
import SalesDistributorDataProvider from "../dataProvider/SalesDistributorDataProvider";

const useSaveFilter = () => {
  const [message, setMessage] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);

  const saveFilter = async (filterValues: SalesDistributorFilterValue) => {
    setLoading(true);
    try {
      const savedFilter = {
        ...filterValues,
        periodeAwal: filterValues.periodeAwal?.getEpochDate(),
        periodeAkhir: filterValues.periodeAkhir?.getEpochDate(),
      };

      const result: string =
        await SalesDistributorDataProvider.saveFilter(savedFilter);

      setMessage(result);
    } catch (error: unknown) {
      setMessage(error as string);
    } finally {
      setLoading(false);
    }
  };

  return { message, isLoading, saveFilter };
};

export default useSaveFilter;
