import React from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";
import { useBiayaMurniFilterContext } from "../../../../../contexts/BiayaMurniFilterContext";
import useGetRoleByLoggedUser from "../../../../../../../services/Users/useGetRoleByLoggedUser";
import useGetUserByRole from "../../../../../../../services/Users/useGetUserByRole";
import UserModel from "../../../../../../../models/Users/UserModel";
import RoleModel from "../../../../../../../models/Users/RoleModel";
import CustomSelect from "../../../../../../../components/CustomSelect/CustomSelect";
import FilterFieldFrame from "../FilterFieldFrame";

const FilterByJabatanAndPekerja = () => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const {
    states: { filterValues },
  } = useBiayaMurniFilterContext();

  const [selectedRole, setSelectedRole] = React.useState<RoleModel | null>(
    filterValues?.jabatan ?? null
  );
  const [selectedPekerja, setSelectedPekerja] =
    React.useState<UserModel | null>(filterValues?.pekerja ?? null);

  const { roleData } = useGetRoleByLoggedUser();
  const { userData } = useGetUserByRole({
    roleName: selectedRole?.name ?? "",
  });

  const formContext = useFormContext();

  return (
    <Stack gap={4} direction={isMobile ? "column" : "row"}>
      <Box width={isMobile ? "100%" : "40%"}>
        <FilterFieldFrame title={"Jabatan"}>
          <CustomSelect
            options={roleData}
            onChange={(eachRole: RoleModel | null) => {
              setSelectedRole(eachRole);
              formContext.setValue("jabatan", eachRole);
              setSelectedPekerja(null);
              formContext.setValue("pekerja", null);
            }}
            getOptionLabel={(eachRole: RoleModel) => eachRole.label}
            value={selectedRole}
            placeholder="Jabatan"
            getOptionValue={(eachRole) => eachRole.label}
            isClearable
          />
        </FilterFieldFrame>
      </Box>
      <Box width={isMobile ? "100%" : "60%"}>
        <FilterFieldFrame title={"Cari Nama"}>
          <CustomSelect
            options={userData}
            isDisabled={isEmpty(selectedRole)}
            onChange={(pekerja) => {
              setSelectedPekerja(pekerja);
              formContext.setValue("pekerja", pekerja);
            }}
            getOptionLabel={(pekerja) => `${pekerja.name} - ${pekerja.nip}`}
            getOptionValue={(eachName) => eachName.nip}
            value={selectedPekerja}
            placeholder="Cari Nama"
            isClearable
          />
        </FilterFieldFrame>
      </Box>
    </Stack>
  );
};

export default FilterByJabatanAndPekerja;
