import {
  FormControl,
  FormErrorMessage,
  Input,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { DraftRencanaKunjunganCreateBody } from "../../models/CreateDraftRencanaKunjunganModel";
import DraftRencanaKunjunganUpdateBody from "../../models/UpdateDraftRencanaKunjunganModel";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";

type Props = {
  defaultValue?: number;
};

const RencanaKunjunganTotalVisitFormField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const {
    register,
    formState: { errors },
  } = useFormContext<
    DraftRencanaKunjunganCreateBody | DraftRencanaKunjunganUpdateBody
  >();

  return (
    <FormControl
      width={!isDesktopView ? "100%" : "80%"}
      isInvalid={!!errors.total_visit}
    >
      <FormInputLabel title="Banyaknya Kunjungan" />
      <Input
        {...register("total_visit", {
          required: "Required",
          min: {
            value: 1,
            message: "Minimal 1 kunjungan",
          },
          max: {
            value: 5,
            message: "Maksimal 5 kunjungan",
          },
        })}
        type="number"
        placeholder="Banyaknya Kunjungan"
        defaultValue={props.defaultValue}
      />
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.total_visit && (errors.total_visit.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default RencanaKunjunganTotalVisitFormField;
