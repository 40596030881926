import { ROLE_ID_FF } from "../../../../constants/common/RoleConstants";
import LoggedUser from "../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../redux/hooks";
import BiayaMurniFilterFfRole from "./roleFf/BiayaMurniFilterFfRole";
import BiayaMurniFilterOthersRole from "./roleOthers/BiayaMurniFilterOthersRole";

const BiayaMurniFilter = ({
  isEditable = false,
}: {
  isEditable?: boolean;
}): JSX.Element => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  if (loggedUser?.userRoleId === ROLE_ID_FF) {
    return <BiayaMurniFilterFfRole isEditable={isEditable} />;
  } else {
    return <BiayaMurniFilterOthersRole />;
  }
};

export default BiayaMurniFilter;
