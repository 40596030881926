import { useTheme, useMediaQuery } from "@chakra-ui/react";
import loginBackground from "../../../assets/loginBackground.png";

const useForgotPasswordStyle = () => {
  const theme = useTheme();

  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const rootStyle = {
    backgroundColor: `${theme.colors.exodus.white}`,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${loginBackground})`,
  };

  const emailVerificationCardStyle = {
    backgroundColor: `${theme.colors.exodus.background}`,
    display: "flex",
    p: 6,
    minH: `${isDesktopView ? "534px" : "404px"}`,
    width: `${isDesktopView ? "500px" : "90%"}`,
    flexDirection: "column",
    borderRadius: "16px",
  };

  const newPasswordCardStyle = {
    backgroundColor: `${theme.colors.exodus.background}`,
    display: "flex",
    p: 6,
    minH: `${isDesktopView ? "490px" : "396px"}`,
    width: `${isDesktopView ? "500px" : "90%"}`,
    flexDirection: "column",
    borderRadius: "16px",
  };

  const logoStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mb: `${isDesktopView ? 20 : 10}`,
  };

  return {
    rootStyle,
    emailVerificationCardStyle,
    logoStyle,
    newPasswordCardStyle,
  };
};

export default useForgotPasswordStyle;
