import useTitle from "../../../../services/useTitle";
import DetailMonitorKlaimSalesContext from "../contexts/DetailMonitorKlaimSalesContext";
import useGetDetailKlaimSales from "../../services/useGetDetailKlaimSales";
import DetailMonitorKlaimSalesPage from "./DetailMonitorKlaimSalesPage";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";

const MonitorKlaimSalesBase = () => {
  useTitle("Monitor Klaim Sales Detail");

  const { id } = useParams();
  const idParams = parseInt(id!);
  const toast = useToast();

  const {
    detailKlaimSalesData,
    loading,
    errorResponse,
    refetchGetDetailKlaimSales,
  } = useGetDetailKlaimSales(idParams);

  const openPDF = (url: string) => {
    if (url.includes("http")) {
      window.open(url);
    } else {
      toast({
        title: `PDF Tidak Valid`,
        status: "error",
      });
    }
  };

  return (
    <DetailMonitorKlaimSalesContext.Provider
      value={{
        detailKlaimSalesData: detailKlaimSalesData,
        refetchGetDetailKlaimSales: refetchGetDetailKlaimSales,
        openPDF: openPDF,
        loading: loading,
        errorResponse: errorResponse,
      }}
    >
      <DetailMonitorKlaimSalesPage />
    </DetailMonitorKlaimSalesContext.Provider>
  );
};

export default MonitorKlaimSalesBase;
