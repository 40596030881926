import { useEffect, useState } from "react";
import TargetSalesProgress from "../model/TargetSalesProgress";
import LoggedUser from "../../../models/common/LoggedUser";
import DashboardDataProvider from "../dataProvider/DashboardDataProvider";
import DateExodus from "../../../models/DateExodus";
import CurrencyIdrFormatter from "../../../utils/CurrencyIdrFormatter";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";
import {
  ROLE_ID_FF,
  ROLE_ID_SUPER_ADMIN,
} from "../../../constants/common/RoleConstants";
import dayjs from "dayjs";
import IsTypeOfErrorResponse from "../../../utils/IsTypeOfErrorResponse";
import ProjectType from "../../../models/common/ProjectType";
import { ProjectEnum } from "../../../models/common/Project";

const useTargetSalesProgress = (
  loggedUser: LoggedUser | null,
  project: ProjectType | null
) => {
  const [dataTargetSalesProgress, setData] =
    useState<TargetSalesProgress | null>(null);
  const [loadingTargetSalesProgress, setLoading] = useState<boolean>(false);
  const [errorTargetSalesProgress, setError] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        setError(null);

        const result = await DashboardDataProvider.getTargetSalesProgress(
          new DateExodus(dayjs().startOf("month").unix()).toJSON(),
          project
        );

        const convertedResult: TargetSalesProgress = {
          salesValue: result.data["sales_value_on_period"],
          salesValueUI: CurrencyIdrFormatter.convertToRp(
            result.data["sales_value_on_period"]
          ),
          targetSales: result.data["target_sales_on_period"],
          targetSalesUI: CurrencyIdrFormatter.convertToRp(
            result.data["target_sales_on_period"]
          ),
          progressPercentage:
            result.data["target_sales_on_period"] === 0
              ? result.data["sales_value_on_period"]
              : (
                  (result.data["sales_value_on_period"] /
                    result.data["target_sales_on_period"]) *
                  100
                ).toFixed(2),
          progressPercentageBar:
            result.data["target_sales_on_period"] === 0 &&
            result.data["sales_value_on_period"] === 0
              ? 0
              : result.data["target_sales_on_period"] === 0 &&
                  result.data["sales_value_on_period"] !== 0
                ? 100
                : Math.min(
                    (result.data["sales_value_on_period"] /
                      result.data["target_sales_on_period"]) *
                      100,
                    100
                  ),
        };

        setData(convertedResult);
      } catch (error: any) {
        if (IsTypeOfErrorResponse(error)) {
          setError(error.message);
          return;
        }
        setError(UNKNOWN_ERROR_RESPONSE.message);
      } finally {
        setLoading(false);
      }
    }

    if (
      loggedUser !== null &&
      (loggedUser.userRoleId === ROLE_ID_FF ||
        loggedUser.userRoleId === ROLE_ID_SUPER_ADMIN) &&
      loggedUser?.projects.find((e) => e.value === ProjectEnum.Ethical)
    ) {
      getData();
    }
  }, [loggedUser, project]);

  return {
    dataTargetSalesProgress,
    loadingTargetSalesProgress,
    errorTargetSalesProgress,
  };
};

export default useTargetSalesProgress;
