import { BiayaMurniDplDpfPageProps } from "../../models/BiayaMurniDplDpfDetailPageProps";
import { Accordion, Center, Spinner, VStack, useTheme } from "@chakra-ui/react";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import { useBiayaMurniDplDpfDetailContext } from "../../contexts/BiayaMurniDplDpfDetailContext";
import TotalBiayaStack from "../common/TotalBiayaStack";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import { BiayaMurniDplDpfDetailData } from "../../../../models/apiResponses/BiayaMurniDplDpfDetailData";
import BiayaMurniDplDpfDetailAccordionItem from "./components/BiayaMurniDplDpfDetailAccordionItem";
import { projectEnumToUi } from "../../../../../../models/common/Project";

const BiayaMurniDplDpfDetailPageMobile = ({
  outletDetail,
  isLoadingFetchingOutlet,
  errorFetchingOutlet,
}: BiayaMurniDplDpfPageProps) => {
  const theme = useTheme();
  const {
    states: { data, error: errorGetList, isLoading: isLoadingGetList },
  } = useBiayaMurniDplDpfDetailContext();

  const totalSalesValue: number | undefined = data?.products?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.discountCost,
    0
  );

  if (errorFetchingOutlet || errorGetList) {
    return (
      <VStack alignItems={"stretch"}>
        <PageTitle label="DPL/DPF Detail" />
        <ErrorPage
          message={
            errorFetchingOutlet
              ? errorFetchingOutlet.message
              : errorGetList?.message!!
          }
        />
      </VStack>
    );
  }

  return (
    <VStack alignItems={"stretch"}>
      <PageTitle label="DPL/DPF Detail" />
      {data?.hasProject && <PageTitle label={projectEnumToUi(data?.project)} />}
      <PageTitle label={outletDetail?.outletName!!} />
      <TotalBiayaStack totalSalesValue={totalSalesValue} />
      {isLoadingGetList || isLoadingFetchingOutlet ? (
        <Center>
          <Spinner
            thickness="4px"
            speed="1s"
            emptyColor={theme.colors.exodus.background}
            color={theme.colors.exodus.primaryBlue}
            size="xl"
          />
        </Center>
      ) : data?.products === null ? (
        <DataNotFound />
      ) : (
        <>
          {data?.products && (
            <Accordion allowMultiple>
              {data?.products.map(
                (record: BiayaMurniDplDpfDetailData, key: number) => (
                  <BiayaMurniDplDpfDetailAccordionItem
                    record={record}
                    key={key}
                  />
                )
              )}
            </Accordion>
          )}
        </>
      )}
    </VStack>
  );
};

export default BiayaMurniDplDpfDetailPageMobile;
