import SectorModel from "../../../../../../models/Sector/SectorModel";
import { ProjectEnum } from "../../../../../../models/common/Project";
import { SectorTypeJsonConverter } from "../../../utils/utils";

class SalesOutletListSingularData {
  outletId: number;
  outletPharosCode: string | null;
  outletName: string;
  sector: SectorModel;
  salesValue: number;
  estimatedValue: number;
  project: ProjectEnum;

  constructor(json: any) {
    this.outletId = json.outlet_id;
    this.outletPharosCode = json.outlet_pharos_code;
    this.outletName = json.outlet_name;
    this.sector = SectorTypeJsonConverter.fromJson(json.sector)!!;
    this.salesValue = json.sales_value;
    this.estimatedValue = json.estimated_value;
    this.project = json.project;
  }
}

export default SalesOutletListSingularData;
