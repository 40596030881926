import { useState } from "react";
import { FakturByCodeModelData } from "../../../models/FakturByCodeModel";
import InputKlaimSalesProvider from "../../../dataProviders/InputKlaimSalesProvider";
import { SaveFakturKlaimSalesParam } from "../../../dataProviders/InputKlaimSalesParamType";

const useGetAndSaveFakturKlaimSales = () => {
  const [storedFakturData, setStoredFaktur] = useState<FakturByCodeModelData[]>(
    []
  );
  const [loadingStoredFaktur, setLoading] = useState<boolean>(true);
  const [errorStoredFaktur, setErrorMessage] = useState<string | null>(null);

  const getStoredFakturKlaimSales = async () => {
    try {
      setErrorMessage(null);
      setLoading(true);

      const data = await InputKlaimSalesProvider.getSavedInputKlaimSalesData();

      if (data && data.selectedFaktur) {
        setStoredFaktur(data.selectedFaktur.data);
      }

      setLoading(false);
    } catch (error) {
      setErrorMessage(error as string);
      setLoading(false);
    }
  };

  const saveFakturKlaimSales = async (data: SaveFakturKlaimSalesParam) => {
    try {
      setErrorMessage(null);
      setLoading(true);

      await InputKlaimSalesProvider.setSaveFakturKlaimSales(data);

      setLoading(false);
    } catch (error) {
      setErrorMessage(error as string);
      setLoading(false);
    }
  };

  const removeSavedFakturKlaimSales = async () => {
    try {
      setErrorMessage(null);
      setLoading(true);

      await InputKlaimSalesProvider.removeSavedFakturKlaimSales();

      setLoading(false);
    } catch (error) {
      setErrorMessage(error as string);
      setLoading(false);
    }
  };

  return {
    getStoredFakturKlaimSales,
    saveFakturKlaimSales,
    removeSavedFakturKlaimSales,
    storedFakturData,
    setStoredFaktur,
    loadingStoredFaktur,
    errorStoredFaktur,
  };
};

export default useGetAndSaveFakturKlaimSales;
