import { useMediaQuery, useTheme } from "@chakra-ui/react";
import { BiayaMurniFilterContextProvider } from "../../contexts/BiayaMurniFilterContext";
import useTitle from "../../../../services/useTitle";
import BiayaMurniDplDpfDetailPageDesktop from "./components/desktop/BiayaMurniDplDpfDetailPageDesktop";
import BiayaMurniDplDpfDetailPageMobile from "./components/mobile/BiayaMurniDplDpfDetailPageMobile";
import { BiayaMurniDplDpfDetailContextProvider } from "./contexts/BiayaMurniDplDpfDetailContext";
import { useParams } from "react-router-dom";
import useGetOutletDetail from "../../../../services/Outlet/useGetOutletDetail";

const BiayaMurniDplDpfDetailPage = (): JSX.Element => {
  useTitle("Biaya Murni - DPL/DPF Detail");
  const { outletId } = useParams();

  const theme = useTheme();

  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { outletData, loading, error } = useGetOutletDetail({
    outletId: parseInt(outletId!!),
  });

  return (
    <BiayaMurniFilterContextProvider>
      <BiayaMurniDplDpfDetailContextProvider outletId={outletId}>
        {isDesktopView ? (
          <BiayaMurniDplDpfDetailPageDesktop
            outletDetail={outletData}
            isLoadingFetchingOutlet={loading}
            errorFetchingOutlet={error}
          />
        ) : (
          <BiayaMurniDplDpfDetailPageMobile
            outletDetail={outletData}
            isLoadingFetchingOutlet={loading}
            errorFetchingOutlet={error}
          />
        )}
      </BiayaMurniDplDpfDetailContextProvider>
    </BiayaMurniFilterContextProvider>
  );
};

export default BiayaMurniDplDpfDetailPage;
