import {
  FakturByCodeModelData,
  FakturByCodeModelDetailData,
} from "../../../models/FakturByCodeModel";

/**
 * A model made for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * Constructor uses Step 2's model.
 *
 */
class StoredEditableFakturListModel {
  data!: EditableFakturSalesData[];

  constructor(oriData: FakturByCodeModelData[]) {
    this.data = [];
    for (let i = 0; i < oriData.length; i++) {
      this.data.push(new EditableFakturSalesData(oriData[i], i));
    }
  }
}

/**
 * A model made for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * Constructor uses Step 2's model.
 *
 */
class EditableFakturSalesData {
  /// Internal FE id. Hopefully this also fasten
  /// search on list using number rather than string
  id!: number;

  originalData!: FakturByCodeModelData;
  newDetail!: EditableSalesDetail[];
  isValid!: boolean;

  constructor(oriData: FakturByCodeModelData, id: number) {
    this.id = id;
    this.originalData = oriData;

    this.newDetail = [];
    for (let i = 0; i < oriData.salesDetail.length; i++) {
      if (oriData.salesDetail[i].qty !== 0) {
        this.newDetail.push(new EditableSalesDetail(oriData.salesDetail[i]));
      }
    }

    this.isValid = false;
  }
}

/**
 * A model made for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * Constructor uses Step 2's model.
 *
 */
class EditableSalesDetail {
  originalData!: FakturByCodeModelDetailData;
  selectedQty!: number;
  isSelected!: boolean;

  constructor(oriData: FakturByCodeModelDetailData) {
    this.originalData = oriData;
    this.selectedQty = oriData.qty;
    this.isSelected = false;
  }
}

export {
  StoredEditableFakturListModel,
  EditableFakturSalesData,
  EditableSalesDetail,
};
