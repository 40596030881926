import { VStack } from "@chakra-ui/react";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import BiayaMurniMobilePageSelector from "../../../../components/mobile/BiayaMurniMobilePageSelector";
import BiayaMurniFilter from "../../../../components/filter/BiayaMurniFilter";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import { useBiayaMurniFilterContext } from "../../../../contexts/BiayaMurniFilterContext";
import BiayaMurniStandarisasiList from "./parts/BiayaMurniStandarisasiList";

const BiayaMurniStandarisasiPageMobile = (): JSX.Element => {
  const {
    states: { error },
  } = useBiayaMurniFilterContext();

  if (error) {
    return (
      <VStack alignItems={"stretch"}>
        <PageTitle label="Standarisasi" />
        <ErrorPage message={error.message} />
      </VStack>
    );
  }
  return (
    <VStack alignItems={"stretch"}>
      <PageTitle label="Standarisasi" />
      <BiayaMurniMobilePageSelector />
      <BiayaMurniFilter isEditable />
      <BiayaMurniStandarisasiList />
    </VStack>
  );
};

export default BiayaMurniStandarisasiPageMobile;
