/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from "react";
import { Button, useMediaQuery, useTheme, useToast } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import DialogInputEditExtraCall from "./DialogInputEditExtraCall";
import CreateAndUpdateExtraCallBody from "../models/CreateAndUpdateExtraCallModel";
import useCreateExtraCall from "../services/useCreateExtraCall";
import ImageCompressor from "../../../../../utils/ImageCompressor";
import blobToBase64 from "../../../../../utils/BlobToBase64";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";
import dayjs from "dayjs";
import DateExodus from "../../../../../models/DateExodus";
import { CommonStatus } from "../../../../../models/common/Status";

const ButtonInputExtraCall: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const { selectedPeriode, key, setKey } = useContext(
    InputRealisasiKunjunganContext
  );

  const { createExtraCall, loading: loadingExtraCall } = useCreateExtraCall();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [photoProcessLoading, setPhotoProcessLoading] =
    useState<boolean>(false);

  const handleInputExtraCall = async (value: CreateAndUpdateExtraCallBody) => {
    let photo;
    let compressedImage: File;

    if (value.photo !== null && value.photo !== undefined) {
      setPhotoProcessLoading(true);
      try {
        const image = value.photo as any;

        if (!image[0]) {
          setPhotoProcessLoading(false);
          toast({
            title: "Gagal Melakukan Kompresi Gambar",
            description: "Silahkan pilih gambar terlebih dahulu",
            status: "error",
          });
          return;
        }

        compressedImage = await ImageCompressor(image[0]);
        photo = await blobToBase64(compressedImage);
      } catch (error: any) {
        setPhotoProcessLoading(false);
        toast({
          title: error.toString(),
          status: "error",
        });
        return;
      }

      setPhotoProcessLoading(false);
    }

    const date = new DateExodus(dayjs(value.date).unix());
    const startTime = new DateExodus(dayjs(value.start_time).unix());
    const endTime = new DateExodus(dayjs(value.end_time).unix());

    if (startTime.getEpochDate() > endTime.getEpochDate()) {
      toast({
        title: "Gagal Menginput Extra Call",
        description: "Jam Mulai tidak boleh melebihi Jam Selesai",
        status: "error",
      });
      return;
    }

    const submittedData: CreateAndUpdateExtraCallBody = {
      visit_header_id: selectedPeriode!.header_id,
      outlet_id: value.outlet_id,
      customer_id: value.customer_id,
      purpose_of_visit:
        value.purpose_of_visit === "Lain-lain (diisi sendiri)"
          ? value.tujuan_lain!.trim()
          : value.purpose_of_visit,
      date: parseInt(JSON.stringify(date)),
      start_time: parseInt(JSON.stringify(startTime)),
      end_time: parseInt(JSON.stringify(endTime)),
      visitor_comment: value.visitor_comment.trim(),
      photo: photo,
      visit_type: "extra call",
      position_customer: value.position_customer,
    };

    createExtraCall(submittedData, {
      onSuccess: () => {
        setOpenDialog(false);
        toast({
          title: "Berhasil Menginput Extra Call",
          status: "success",
        });
        setKey(key + 1);
      },
      onError: (errorMessage: string) => {
        toast({
          title: "Gagal Menginput Extra Call",
          description: `${errorMessage}`,
          status: "error",
        });
      },
    });
  };

  const disableButtonInputCondition = (): boolean => {
    if (!selectedPeriode) return true;

    const dateNow = new DateExodus();

    const isDateNowGreaterThanSelectedEndPeriod: boolean =
      parseInt(JSON.stringify(dateNow)) >
      parseInt(JSON.stringify(selectedPeriode.end_period));

    if (
      selectedPeriode.status_realization !== CommonStatus.Pending ||
      selectedPeriode.status_plan !== CommonStatus.Approved
    ) {
      return true;
    } else if (isDateNowGreaterThanSelectedEndPeriod) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Button
        variant="outline"
        colorScheme="buttonSecondary"
        leftIcon={<MdAdd />}
        width="100%"
        maxWidth={isDesktopView ? "200px" : undefined}
        px={0}
        onClick={() => setOpenDialog(true)}
        isDisabled={disableButtonInputCondition()}
      >
        {isDesktopView ? "Tambah Extra Call" : "Extra Call"}
      </Button>

      <DialogInputEditExtraCall
        type="create"
        dialogTitle="Input Extra Call"
        openDialogInputEditExtraCall={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={handleInputExtraCall}
        isLoadingPhotoProcessing={photoProcessLoading}
        isLoadingSubmit={loadingExtraCall}
      />
    </>
  );
};

export default ButtonInputExtraCall;
