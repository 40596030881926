import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useTheme,
  PlacementWithLogical,
  PopoverAnchor,
} from "@chakra-ui/react";

type CustomPopoverProps = {
  triggerType?: "hover" | "click";
  triggerComponent: JSX.Element;
  anchorComponent?: JSX.Element;
  bodyComponent: JSX.Element;
  height?: number;
  width?: number;
  autoFocus?: boolean;
  placement?: PlacementWithLogical;
  strategy?: "fixed" | "absolute";
  closeOnBlur?: boolean;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};

const CustomPopover = ({
  triggerType = "hover",
  triggerComponent,
  anchorComponent,
  bodyComponent,
  height,
  width,
  autoFocus = false,
  placement = "bottom",
  strategy,
  closeOnBlur,
  isOpen,
  onOpen,
  onClose,
}: CustomPopoverProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Popover
      trigger={triggerType}
      autoFocus={autoFocus}
      placement={placement}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      closeOnBlur={closeOnBlur}
      strategy={strategy}
    >
      {anchorComponent && <PopoverAnchor>{anchorComponent}</PopoverAnchor>}
      <PopoverTrigger>{triggerComponent}</PopoverTrigger>
      <PopoverContent
        p={"4px"}
        borderWidth={1}
        borderRadius={"12px"}
        borderColor={theme.colors.exodus.darkGrey}
        background={theme.colors.exodus.background}
        maxWidth={"100vw"}
        maxHeight={"100vh"}
        width={width}
        height={height}
      >
        <PopoverBody>{bodyComponent}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CustomPopover;
