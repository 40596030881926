import { Box, Text, useTheme } from "@chakra-ui/react";
import React from "react";

const KeteranganSalesPopover: React.FC = () => {
  const theme = useTheme();
  return (
    <Box>
      <Text
        fontWeight={600}
        color={theme.colors.exodus.primaryBlue}
        textAlign={"start"}
      >
        Sales Invoice
      </Text>
      <Text textAlign={"start"}>Sales yang terdaftar di Invoice</Text>
      <Text
        fontWeight={600}
        color={theme.colors.exodus.primaryBlue}
        mt={3}
        textAlign={"start"}
      >
        Sales Terdaftar
      </Text>
      <Text textAlign={"start"}>
        Sales untuk produk yang sudah di link dengan product code distributor
      </Text>
    </Box>
  );
};

export default KeteranganSalesPopover;
