import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import UserModel from "../../models/Users/UserModel";
import UserProvider from "../../dataProviders/common/Users/UserProvider";
import { UserByRoleParam } from "../../dataProviders/common/Users/UserParamType";

const useGetUserByRole = ({ roleName }: UserByRoleParam) => {
  const [userData, setData] = useState<UserModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function getData() {
    try {
      setLoading(true);

      const response: UserModel[] | undefined =
        await UserProvider.getUserByRole({
          roleName: roleName,
        });

      setData(response!);
      setLoading(false);
    } catch (error: any) {
      setData([]);
      setErrorMessage(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!isEmpty(roleName)) {
      getData();
    }
  }, [roleName]);

  return { userData, errorMessage, loading };
};

export default useGetUserByRole;
