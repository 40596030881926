import { stepperAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(stepperAnatomy.keys);

const baseStyle = definePartsStyle({
  stepper: {
    gap: 0,
  },
  step: {
    gap: 0,
  },
  indicator: {
    borderRadius: 100,
    borderColor: "#0f4c82",
    "&[data-status=complete]": {
      background: "#f8f8ff",
      border: "2px solid",
      borderColor: "#0f4c82",
      color: "#0f4c82",
    },
    "&[data-status=active]": {
      background: "#0f4c82",
      borderColor: "#0f4c82",
      color: "#ffffff",
    },
    "&[data-status=incomplete]": {
      color: "#0f4c82",
    },
  },
  separator: {
    margin: 0,
    "&[data-orientation=horizontal]": {
      background: "#0f4c82",
      height: "4px",
      margin: 0,
    },
  },
  number: {
    fontSize: 20,
    fontWeight: "semibold",
  },
});

export const StepperTheme = defineMultiStyleConfig({
  baseStyle,
});
