import {
  AccordionButton,
  AccordionItem,
  Stack,
  useTheme,
  Text,
  AccordionIcon,
  Accordion,
  Icon,
} from "@chakra-ui/react";

import SidebarAccordion from "./SidebarAccordion/SidebarAccordion";
import { MenuListType } from "../models/SidebarAccordionType";
import { MdMonitor, MdPostAdd } from "react-icons/md";
import {
  SALES_TARGET,
  MONITOR_SALES_TARGET,
  MONITOR_SALES_PRODUCT_TEXT,
  SALES_DISTRIBUTOR,
  SALES_OUTLET_NAVIGATION_ROOT,
  OUTLET_CARD_PATH,
} from "../../../constants/common/RootConstants";
import verifyPageAccess from "../../../utils/VerifyPageAccess";
import {
  ROUTE_ID_INPUT_TARGET_SALES,
  ROUTE_ID_MONITORING_TARGET_SALES,
  ROUTE_ID_OUTLET_CARD,
  ROUTE_ID_SALES_OUTLET,
  ROUTE_ID_SALES_PRODUCT,
} from "../../../constants/common/RouteIdConstants";
import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";

const PerformanceMenu = ({ onClose }: { onClose: Function }) => {
  const theme = useTheme();

  const PerformanceMenuList: MenuListType[] = [];

  const loggedUser: LoggedUser | null = useAppSelector(
    (store: any) => store.authReducer.loggedUser
  );

  if (
    !verifyPageAccess(loggedUser, ROUTE_ID_SALES_PRODUCT) &&
    !verifyPageAccess(loggedUser, ROUTE_ID_INPUT_TARGET_SALES) &&
    !verifyPageAccess(loggedUser, ROUTE_ID_MONITORING_TARGET_SALES) &&
    !verifyPageAccess(loggedUser, ROUTE_ID_SALES_OUTLET) &&
    !verifyPageAccess(loggedUser, ROUTE_ID_OUTLET_CARD)
  )
    return null;

  if (verifyPageAccess(loggedUser, ROUTE_ID_INPUT_TARGET_SALES))
    PerformanceMenuList.push({
      title: "Sales Target",
      pathName: SALES_TARGET,
      icon: (
        <Icon
          as={MdPostAdd}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });

  if (verifyPageAccess(loggedUser, ROUTE_ID_MONITORING_TARGET_SALES))
    PerformanceMenuList.push({
      title: "Monitor Sales Target",
      pathName: MONITOR_SALES_TARGET,
      icon: (
        <Icon
          as={MdMonitor}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });

  PerformanceMenuList.push({
    title: "Sales Distributor",
    pathName: SALES_DISTRIBUTOR,
    icon: (
      <Icon
        as={MdMonitor}
        color={theme.colors.exodus.primaryBlue}
        boxSize={6}
      />
    ),
  });

  if (verifyPageAccess(loggedUser, ROUTE_ID_SALES_PRODUCT))
    PerformanceMenuList.push({
      title: "Sales Produk",
      pathName: MONITOR_SALES_PRODUCT_TEXT,
      icon: (
        <Icon
          as={MdMonitor}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });

  if (verifyPageAccess(loggedUser, ROUTE_ID_SALES_OUTLET))
    PerformanceMenuList.push({
      title: "Sales Outlet",
      pathName: SALES_OUTLET_NAVIGATION_ROOT,
      icon: (
        <Icon
          as={MdMonitor}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });

  if (verifyPageAccess(loggedUser, ROUTE_ID_OUTLET_CARD))
    PerformanceMenuList.push({
      title: "Outlet Card",
      pathName: OUTLET_CARD_PATH,
      icon: (
        <Icon
          as={MdMonitor}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      <AccordionItem>
        <AccordionButton>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 4,
              }}
            >
              <AccordionIcon />
              <Text
                fontSize={18}
                fontWeight={400}
                sx={{ color: theme.colors.exodus.primaryBlue }}
              >
                Performance
              </Text>
            </Stack>
          </Stack>
        </AccordionButton>
        <SidebarAccordion menuList={PerformanceMenuList} onClose={onClose} />
      </AccordionItem>
    </Accordion>
  );
};

export default PerformanceMenu;
