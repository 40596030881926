import { useState, useEffect } from "react";
import DateExodus from "../../../models/DateExodus";
import BiayaMurniFilterModel from "../models/BiayaMurniFilterModel";
import BiayaMurniProvider from "../dataProviders/BiayaMurniProvider";
import ErrorResponse from "../../../models/common/ErrorResponse";

const filterInitialValue = new BiayaMurniFilterModel({
  periode: {
    awal: new DateExodus(),
    akhir: new DateExodus(),
  },
});

const useFilter = () => {
  const [filterValues, setFilterValues] =
    useState<BiayaMurniFilterModel | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    const initializeFilter = async () => {
      try {
        setLoading(true);
        setError(null);

        const dbFilter: BiayaMurniFilterModel | null =
          await BiayaMurniProvider.localDbGetFilter();

        if (dbFilter) {
          setFilterValues(dbFilter);
        } else {
          setFilterValues(filterInitialValue);
        }
      } catch (error) {
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setLoading(false);
      }
    };

    initializeFilter();
  }, []);

  const saveFilter = async (newFilterValues: BiayaMurniFilterModel) => {
    try {
      setLoading(true);
      setError(null);
      await BiayaMurniProvider.localDbSaveFilter(newFilterValues);
      setFilterValues(newFilterValues);
    } catch (error) {
      if (error as ErrorResponse) {
        setError(error as ErrorResponse);
      }
    } finally {
      setLoading(false);
    }
  };

  return { filterValues, saveFilter, isLoading, error };
};

export default useFilter;

export { filterInitialValue };
