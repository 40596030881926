import { Navigate, Outlet } from "react-router-dom";

type Props = { isAllowed: boolean | undefined; children: any };

const ProtectedPage: React.FC<Props> = (props) => {
  if (props.isAllowed) return props.children ?? <Outlet />;
  return <Navigate to={"/"} replace />;
};

export default ProtectedPage;
