import { useEffect, useState } from "react";
import RoleModel from "../../models/Users/RoleModel";
import UserProvider from "../../dataProviders/common/Users/UserProvider";

const useGetRoleByLoggedUser = () => {
    const [roleData, setData] = useState<RoleModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        async function getData() {
            try {
                setErrorMessage(null);
                setLoading(true);

                const response: RoleModel[] | undefined = await UserProvider.getRoleListByLoggedUser();

                setData(response!);
                setLoading(false);
            } catch (error: any) {
                setData([]);
                setErrorMessage(error);
                setLoading(false);
            }
        }

        getData();
    }, []);

    return { roleData, loading, errorMessage };
}

export default useGetRoleByLoggedUser;