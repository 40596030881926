import {
  Center,
  HStack,
  Spinner,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useBiayaMurniListContext } from "../../../../../contexts/BiayaMurniListContext";
import TableDataComponent from "../../../../../../../components/TableData/TableDataComponent";
import StackedColumn from "../../../../../../../components/TableData/StackedColumnTable";
import { COMMON_PAGINATION_LIMIT_CHOICES } from "../../../../../../../constants/components/PaginationConstants";
import { BiayaMurniStandarisasiData } from "../../../../../models/apiResponses/BiayaMurniStandarisasiData";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import BiayaMurniStandarisasiOrPopover from "./BiayaMurniStandarisasiOrPopover";
import { useStandarisasiOrManagerContext } from "../../../contexts/StandarisasiOrManagerContext";
import { projectEnumToUi } from "../../../../../../../models/common/Project";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";

const BiayaMurniStandarisasiTable = (): JSX.Element => {
  const theme = useTheme();

  const {
    actions: { setPage, setLimit },
    states: { data },
  } = useBiayaMurniListContext();

  const {
    states: { isLoadingInit },
  } = useStandarisasiOrManagerContext();

  if (isLoadingInit) {
    return <Spinner color={theme.colors.exodus.primaryBlue} />;
  }

  const [projectColumn, setProjectColumn] = useState<TableColumn<any>[]>([]);

  useEffect(() => {
    if (data?.hasProject) {
      setProjectColumn([
        {
          name: "Project",
          cell: (row: BiayaMurniStandarisasiData) => (
            <Text textAlign={"start"}>{projectEnumToUi(row.project)}</Text>
          ),
          width: "100px",
        },
      ]);
    } else {
      setProjectColumn([]);
    }
  }, [data]);

  if (data?.records) {
    return (
      <Stack>
        <TableDataComponent
          data={data.records}
          onSelectChange={() => {}}
          paginationLimit={COMMON_PAGINATION_LIMIT_CHOICES.map((val) => {
            return val.value;
          })}
          paginationOn={false}
          tableHeader={[
            ...projectColumn,
            {
              name: "ID",
              cell: (row: BiayaMurniStandarisasiData) => (
                <Text>{`STD - ${row.standarisasiId}`}</Text>
              ),
              width: "10%",
            },
            {
              name: "Nama & Kode Outlet",
              cell: (row: BiayaMurniStandarisasiData) => (
                <StackedColumn
                  topValue={row.outletName}
                  bottomValue={row.outletCode}
                  isTopBoldApplied={true}
                />
              ),
            },
            {
              name: "Nama & Kode Customer",
              cell: (row: BiayaMurniStandarisasiData) => (
                <StackedColumn
                  topValue={row.customerName}
                  bottomValue={row.customerCode}
                  isTopBoldApplied={true}
                />
              ),
            },
            {
              name: "Periode",
              cell: (row: BiayaMurniStandarisasiData) => (
                <Text textAlign={"start"}>{`${row.startDate.getStringDateFormat(
                  "MMMM YYYY"
                )} - ${row.endDate.getStringDateFormat("MMMM YYYY")}`}</Text>
              ),
            },
            {
              name: "Total Bayar",
              cell: (row: BiayaMurniStandarisasiData) => (
                <Text textAlign={"start"}>
                  {CurrencyIdrFormatter.convertToRp(row.biaya)}
                </Text>
              ),
              width: "10%",
            },
            {
              name: "Detail Realisasi",
              cell: (_, rowIndex) => (
                <BiayaMurniStandarisasiOrPopover index={rowIndex} />
              ),
              width: "14%",
            },
          ]}
          checboxOn={false}
        />
        <HStack justifyContent={"flex-end"}>
          <Pagination
            handleOnNavigateFirstPage={() => {
              setPage("first");
            }}
            handleOnNext={() => {
              setPage("next");
            }}
            handleOnBack={() => {
              setPage("prev");
            }}
            handleOnLimitChange={(value) => {
              setLimit(value);
            }}
            page={data.metadata.currentPage}
            limit={data.metadata.totalDataPerPage}
            totalData={data.metadata.totalData}
            totalPage={data.metadata.totalPage}
          />
        </HStack>
      </Stack>
    );
  }

  return (
    <Center>
      <Text fontWeight={600}>Data tidak ditemukan</Text>
    </Center>
  );
};

export default BiayaMurniStandarisasiTable;
