import ProductCodeFormatter from "../../../../utils/ProductCodeFormatter";

export class GetSaldoKontribusiModelData {
  totalSaldo: number;
  jumlahProduk: number;
  detailProduk: GetSaldoKontribusiProdukDetail[];

  constructor(json: any) {
    this.totalSaldo = json["total_saldo"];
    this.jumlahProduk = json["jumlah_produk"];
    this.detailProduk =
      json["detail"]?.map(
        (item: any) => new GetSaldoKontribusiProdukDetail(item)
      ) ?? [];
  }
}

export class GetSaldoKontribusiProdukDetail {
  kontribusiId: number;
  productName: string;
  valueSales: number;
  kontribusi: number;
  saldo: number;
  divisi: string;
  groupName: string;
  productCode: string | null;
  outletCode: string;

  constructor(json: any) {
    this.kontribusiId = json["kontribusi_id"];
    this.productName = json["product_name"];
    this.valueSales = json["value_sales"];
    this.kontribusi = json["kontribusi"];
    this.saldo = json["saldo"];
    this.divisi = json.divisi;
    this.groupName = json.group_name;
    this.productCode = ProductCodeFormatter(json.product_code) ?? null;
    this.outletCode = json.outlet_code;
  }
}
