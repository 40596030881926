import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import DraftRencanaKunjunganUpdateBody from "../models/UpdateDraftRencanaKunjunganModel";
import JabatanCustomerList, {
  JabatanCustomer,
} from "../../../constants/JabatanCustomer";
import useGetOutletByUserId from "../../../../../services/Outlet/useGetOutletByUserId";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";
import useGetCustomerByOutletId from "../../../../../services/Customer/useGetCustomerByOutletId";
import { useEffect, useRef, useState } from "react";
import { OutletModel } from "../../../../../models/Outlet/OutletModel";
import CustomerDataModel from "../../../../../models/Customer/CustomerDataModel";
import useGetTujuanKunjunganList from "../../../services/useGetTujuanKunjunganList";
import TujuanKunjunganDataModel from "../../../models/TujuanKunjunganDataModel";
import { DraftRencanaKunjunganModelData } from "../models/DraftRencanaKunjunganModel";
import RencanaKunjunganCustomerFormField from "./form/RencanaKunjunganCustomerFormField";
import RencanaKunjunganJabatanFormField from "./form/RencanaKunjunganJabatanFormField";
import RencanaKunjunganOutletFormField from "./form/RencanaKunjunganOutletFormField";
import RencanaKunjunganTotalVisitFormField from "./form/RencanaKunjunganTotalVisitFormField";
import RencanaKunjunganTujuanFormField from "./form/RencanaKunjunganTujuanFormField";
import RencanaKunjunganTujuanLainFormField from "./form/RencanaKunjunganTujuanLainFormField";
import { debounce } from "lodash";

type Props = {
  data: DraftRencanaKunjunganModelData;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: DraftRencanaKunjunganUpdateBody) => void;
  isLoadingSubmit: boolean;
};

const DialogEditRencanaKunjungan: React.FC<Props> = (props) => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const form = useForm<DraftRencanaKunjunganUpdateBody>();

  const [selectedOutlet, setSelectedOutlet] = useState<OutletModel | null>(
    null
  );
  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerDataModel | null>(null);
  const [selectedTujuan, setSelectedTujuan] =
    useState<TujuanKunjunganDataModel | null>(null);
  const [selectedJabatan, setSelectedJabatan] =
    useState<JabatanCustomer | null>(null);
  const [tujuanLain, setTujuanLain] = useState<string | null | undefined>(
    undefined
  );

  const [searched, setSearched] = useState<string | undefined>();
  const debouncedOnSearch = useRef(
    debounce(async (value: string | undefined) => {
      if (value && (value.length === 0 || value.length >= 3)) {
        setSearched(value);
      }
    }, 300)
  ).current;

  const { outletData } = useGetOutletByUserId({
    params: {
      userId: loggedUser?.userId,
      keyword: searched,
    },
    startFetching: props.open,
  });

  const { customerData } = useGetCustomerByOutletId(props.open, {
    outlet_id: selectedOutlet ? selectedOutlet.outletId : null,
  });
  const { tujuanKunjunganData } = useGetTujuanKunjunganList(props.open);

  useEffect(() => {
    if (props.open) {
      if (outletData.length > 0) {
        const defaultOutlet = outletData.find(
          (item) => item.outletId === props.data.outletId
        );
        setSelectedOutlet(defaultOutlet ?? null);
        form.setValue(
          "outlet_id",
          defaultOutlet ? defaultOutlet.outletId : null
        );
      }

      if (tujuanKunjunganData.length > 0) {
        const defaultKunjungan = tujuanKunjunganData.find(
          (item) => item.visitName === props.data.purpose
        );
        const lastIndex = tujuanKunjunganData.length - 1;
        setSelectedTujuan(defaultKunjungan ?? tujuanKunjunganData[lastIndex]);

        setTujuanLain(defaultKunjungan ? null : props.data.purpose);
        form.setValue(
          "purpose",
          defaultKunjungan
            ? defaultKunjungan.visitName
            : tujuanKunjunganData[lastIndex].visitName
        );
        form.setValue(
          "tujuan_lain",
          defaultKunjungan ? null : props.data.purpose
        );
      }

      const defaultJabatan = JabatanCustomerList.find(
        (item) => item.jabatan_customer === props.data.positionCustomer
      );
      setSelectedJabatan(defaultJabatan ?? null);
      form.setValue("position_customer", defaultJabatan!.jabatan_customer);
    }
  }, [
    outletData,
    props.data.outletId,
    props.data.positionCustomer,
    props.data.purpose,
    props.open,
    tujuanKunjunganData,
  ]);

  useEffect(() => {
    if (props.open) {
      if (selectedOutlet?.outletId === props.data.outletId) {
        if (customerData.length > 0) {
          const defaultCustomer = customerData.find(
            (item) => item.customer_id === props.data.customerId
          );
          setSelectedCustomer(defaultCustomer ?? null);
          form.setValue(
            "customer_id",
            defaultCustomer ? defaultCustomer.customer_id : null
          );
        }
      }
    }
  }, [
    customerData,
    props.data.customerId,
    props.data.outletId,
    props.open,
    selectedOutlet?.outletId,
  ]);

  useEffect(() => {
    if (customerData.length === 0) {
      setSelectedCustomer(null);
    }
  }, [customerData.length]);

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      isOpen={props.open}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          Edit Rencana Kunjungan
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(props.onSubmit)}>
              {/* Nama Outlet dan Nama Customer */}
              <Flex
                direction={!isDesktopView ? "column" : "row"}
                gap={!isDesktopView ? 0 : 5}
                marginBottom={!isDesktopView ? 0 : "16px"}
              >
                <RencanaKunjunganOutletFormField
                  outletData={outletData}
                  customerData={customerData}
                  selectedOutlet={selectedOutlet}
                  onChange={(data) => {
                    setSelectedOutlet(data);
                    if (!data) {
                      setSelectedCustomer(null);
                    }
                  }}
                  onInputChange={(value) => {
                    debouncedOnSearch(value);
                  }}
                />
                <RencanaKunjunganCustomerFormField
                  customerData={customerData}
                  selectedCustomer={selectedCustomer}
                  onChange={(data) => setSelectedCustomer(data)}
                  isDisabled={!selectedOutlet}
                />
              </Flex>

              {/* Jabatan dan Jumlah Kunjungan */}
              <Flex
                direction={!isDesktopView ? "column" : "row"}
                gap={!isDesktopView ? 0 : 5}
                marginBottom={!isDesktopView ? 0 : "16px"}
              >
                <RencanaKunjunganJabatanFormField
                  jabatanData={JabatanCustomerList}
                  selectedJabatan={selectedJabatan}
                  onChange={(data) => setSelectedJabatan(data)}
                />
                <RencanaKunjunganTotalVisitFormField
                  defaultValue={props.data.totalVisit}
                />
              </Flex>

              {/* Tujuan dan Tujuan Kunjungan Lain */}
              <Flex
                direction={!isDesktopView ? "column" : "row"}
                gap={!isDesktopView ? 0 : 5}
              >
                <RencanaKunjunganTujuanFormField
                  tujuanKunjunganData={tujuanKunjunganData}
                  selectedTujuan={selectedTujuan}
                  onChange={(data) => setSelectedTujuan(data)}
                />
                <RencanaKunjunganTujuanLainFormField
                  selectedTujuan={selectedTujuan}
                  defaultValue={tujuanLain ?? undefined}
                />
              </Flex>

              {/* Button Submit dan Cancel */}
              <Flex
                direction={!isDesktopView ? "column" : "row-reverse"}
                gap={!isDesktopView ? 0 : 5}
                marginTop="48px"
                marginBottom="20px"
              >
                <Button
                  type="submit"
                  isLoading={props.isLoadingSubmit}
                  width="100%"
                  colorScheme="buttonPrimary"
                >
                  Submit
                </Button>
                <Button
                  isDisabled={props.isLoadingSubmit}
                  onClick={() => {
                    form.reset();
                    setSelectedOutlet(null);
                    setSelectedCustomer(null);
                    setSelectedTujuan(null);
                    setSelectedJabatan(null);
                    props.onClose();
                  }}
                  width="100%"
                  variant="outline"
                  colorScheme="buttonSecondary"
                  marginTop={!isDesktopView ? "12px" : 0}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DialogEditRencanaKunjungan;
