import { useCallback, useEffect, useState } from "react";
import useTitle from "../../../../../services/useTitle";
import AllPeriodeDataModel from "../../../models/AllPeriodeDataModel";
import useGetAllPeriodeList from "../../../services/useGetAllPeriodeList";
import { Box, useMediaQuery, useTheme } from "@chakra-ui/react";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import DesktopApprovalRealisasiList from "./desktop/DesktopApprovalRealisasiList";
import MobileApprovalRealisasiList from "./mobile/MobileApprovalRealisasiList";
import ApprovalRealisasiKunjunganFilterValues from "../models/ApprovalRealisasiKunjunganFilterValues";
import FilterApprovalRealisasiKunjunganContext, {
  filterApprovalRealisasiInitialValue,
} from "../context/FilterApprovalRealisasiKunjunganContext";
import AllPeriodeApprovalRealisasiContext from "../context/AllPeriodeApprovalRealisasiContext";
import useGetApprovalRealisasiFilter from "../services/useGetApprovalRealisasiFilter";
import useSaveApprovalRealisasiFilter from "../services/useSaveApprovalRealisasiFilter";
import ApprovalRealisasiKunjunganToolbar from "./ApprovalRealisasiKunjunganToolbar";
import MetadataApprovalRealisasiContext from "../context/MetadataApprovalRealisasiContext";
import { isEmpty } from "lodash";
import { MetadataApprovalRealisasi } from "../models/ApprovalRealisasiIndexDbModels";
import useMetadataApprovalRealisasi from "../services/useMetadataApprovalRealisasi";
import useGetListApproval from "../../../services/useGetListApproval";

const ApprovalRealisasiKunjunganPage = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  useTitle("Approval Realisasi Kunjungan");

  const { data: filterPersistentData } = useGetApprovalRealisasiFilter();
  const {
    allPeriodeData,
    errorMessage: periodeError,
    loading: periodeLoading,
  } = useGetAllPeriodeList();
  const {
    data: persistentMetadata,
    isLoading,
    saveData: saveMetadata,
  } = useMetadataApprovalRealisasi();
  const { saveData } = useSaveApprovalRealisasiFilter();

  const [filterValues, setFilterValues] =
    useState<ApprovalRealisasiKunjunganFilterValues>(
      filterApprovalRealisasiInitialValue
    );
  const [selectedPeriode, setSelectedPeriode] =
    useState<AllPeriodeDataModel | null>(null);
  const [page, setPage] = useState<number | null>(null);
  const [limit, setLimit] = useState<number | null>(null);
  const [metadataValue, setMetadataValue] =
    useState<MetadataApprovalRealisasi | null>(null);

  useEffect(() => {
    const savedFilter = new ApprovalRealisasiKunjunganFilterValues({
      bawahan: filterPersistentData?.bawahan,
      role: filterPersistentData?.role,
      periode: filterPersistentData ? filterPersistentData.periode : null,
      status: filterPersistentData?.status,
      project: filterPersistentData?.project,
    });

    setFilterValues(savedFilter);
  }, [filterPersistentData]);

  useEffect(() => {
    if (filterPersistentData) {
      setSelectedPeriode(filterPersistentData?.periode);
    } else if (!filterPersistentData && allPeriodeData.length > 0) {
      setSelectedPeriode(allPeriodeData[0]);
    }
  }, [allPeriodeData, filterPersistentData]);

  useEffect(() => {
    setMetadataValue(persistentMetadata);
  }, [persistentMetadata]);

  useEffect(() => {
    const fetchData = () => {
      if (persistentMetadata !== null && !isLoading) {
        setPage(persistentMetadata.currentPage);
        setLimit(persistentMetadata.limit);
      } else if (
        !isLoading &&
        isEmpty(persistentMetadata) &&
        isEmpty(metadataValue)
      ) {
        setPage(1);
        setLimit(10);
      }
    };

    fetchData();
  }, [metadataValue, isLoading, persistentMetadata]);

  const changeFilterPeriode = useCallback(async () => {
    if (selectedPeriode) {
      const newPeriode = new ApprovalRealisasiKunjunganFilterValues({
        bawahan: filterValues.bawahan,
        role: filterValues.role,
        periode: selectedPeriode,
        status: filterValues.status,
        project: filterValues.project,
      });

      setFilterValues(newPeriode);
      saveData(newPeriode);
    }
  }, [
    filterValues.bawahan,
    filterValues.project,
    filterValues.role,
    filterValues.status,
    selectedPeriode,
  ]);

  useEffect(() => {
    changeFilterPeriode();
  }, [changeFilterPeriode]);

  const {
    metadata,
    approvalData,
    errorMessage: errorList,
    loading: loadingList,
  } = useGetListApproval({
    kunjungan: "Realisasi",
    page: page!,
    limit: limit!,
    start_period: filterValues.periode
      ? filterValues.periode.start_period
      : null,
    end_period: filterValues.periode ? filterValues.periode.end_period : null,
    jabatan: filterValues.role?.label,
    userId: filterValues.bawahan?.id,
    status: filterValues.status?.status_id,
    project: filterValues.project,
  });

  //================================================= Functions ============================================

  const handleOnLimitChange = (updatedLimit: number) => {
    const updateMetadata = {
      limit: updatedLimit,
      currentPage: 1,
    };
    saveMetadata(updateMetadata);
    setLimit(updatedLimit);
  };

  const handleOnNextPage = () => {
    const updateMetadata = {
      limit: persistentMetadata!.limit,
      currentPage: persistentMetadata!.currentPage + 1,
    };
    saveMetadata(updateMetadata);
    setPage(persistentMetadata!.currentPage + 1);
  };

  const handleOnPrevPage = () => {
    const updateMetadata = {
      limit: persistentMetadata!.limit,
      currentPage: persistentMetadata!.currentPage - 1,
    };
    saveMetadata(updateMetadata);
    setPage(persistentMetadata!.currentPage - 1);
  };

  const handleOnNavigateFirstPage = () => {
    const updateMetadata = {
      limit: persistentMetadata!.limit,
      currentPage: 1,
    };
    saveMetadata(updateMetadata);
    setPage(1);
  };

  return (
    <Box>
      <FilterApprovalRealisasiKunjunganContext.Provider
        value={{
          filterValues: filterValues,
          setFilterValues: setFilterValues,
          periode: selectedPeriode,
          setPeriode: setSelectedPeriode,
          approvalListData: approvalData,
          metadataList: metadata,
          errorList: errorList,
          loadingList: loadingList,
        }}
      >
        <PageTitle label="Approval Realisasi Kunjungan" />
        <AllPeriodeApprovalRealisasiContext.Provider
          value={{
            periodeData: allPeriodeData,
            periodeLoading: periodeLoading,
            periodeError: periodeError,
          }}
        >
          <MetadataApprovalRealisasiContext.Provider
            value={{
              limit: limit,
              setLimit: setLimit,
              page: page,
              setPage: setPage,
              metadataValues: persistentMetadata,
              setMetadataValues: saveMetadata,
              handleFirstPage: handleOnNavigateFirstPage,
              handleLimitChange: handleOnLimitChange,
              handleNextPage: handleOnNextPage,
              handlePrevPage: handleOnPrevPage,
            }}
          >
            <ApprovalRealisasiKunjunganToolbar />

            {isDesktopView ? (
              <DesktopApprovalRealisasiList />
            ) : (
              <MobileApprovalRealisasiList />
            )}
          </MetadataApprovalRealisasiContext.Provider>
        </AllPeriodeApprovalRealisasiContext.Provider>
      </FilterApprovalRealisasiKunjunganContext.Provider>
    </Box>
  );
};

export default ApprovalRealisasiKunjunganPage;
