import {
  Box,
  CircularProgress,
  Spacer,
  useDisclosure,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import ShowFilterButton from "../../../components/CustomButtons/ShowFilterButton";
import ExportExcelButton from "../../../components/CustomButtons/ExportExcelButton";
import SalesDistributorAppliedFilterList from "./component/AppliedFilterList";
import TableDataComponent from "../../../components/TableData/TableDataComponent";
import { TableColumn } from "react-data-table-component";
import SalesDistributorFilterDialog from "./component/FilterDialog";
import Pagination from "../../../components/Pagination/Pagination";
import useTitle from "../../../services/useTitle";
import MobileSalesDistributorList from "./component/MobileSalesDistributorList";
import useSaveFilter from "./service/useSaveFilterDistributor";
import useGetFilter from "./service/useGetFilterDistributor";
import SalesDistributorFilterValue from "./model/SalesDistributorFilterValues";
import SalesDistributorContext from "./context/SalesDistributorFilterContext";
import useGetSalesDistributor from "./service/useGetSalesDistributor";
import StackedColumn from "../../../components/TableData/StackedColumnTable";
import { isEmpty } from "lodash";
import { GetListSalesDistributorRecords } from "./model/GetSalesDistributorModelPack";
import DateExodus from "../../../models/DateExodus";
import {
  getOutletSectorType,
  getProjectType,
} from "./utils/SalesDistributorUtils";
import ErrorPage from "../../../components/ErrorPage/ErrorPage";
import LihatDetailButton from "./component/LihatDetailButton";
import { useNavigate } from "react-router-dom";
import useExportDistributorHeader from "./service/useExportDistributorExcelHeader";
import CurrencyIdrFormatter from "../../../utils/CurrencyIdrFormatter";

const SalesDistributor: React.FC = () => {
  const {
    isOpen: isFilterOpen,
    onClose: onCloseFilter,
    onOpen: onOpenFilter,
  } = useDisclosure();
  const navigate = useNavigate();

  const { saveFilter } = useSaveFilter();
  const { data: filterPersistentData } = useGetFilter();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);

  const [isExportDisabled, setIsExportDisabled] = useState<boolean>(true);

  const [filterValues, setFilterValues] =
    React.useState<SalesDistributorFilterValue | null>(null);

  React.useEffect(() => {
    setFilterValues(filterPersistentData);
  }, [filterPersistentData]);

  const { distributorList, loading, error } = useGetSalesDistributor(
    filterValues,
    page,
    limit
  );

  const { exportData, loadingExportHeader } =
    useExportDistributorHeader(filterValues);

  useEffect(() => {
    if (filterValues?.namaOutlet) {
      setIsExportDisabled(false);
    } else {
      setIsExportDisabled(true);
    }
  }, [filterValues]);

  useTitle("Sales Distributor");

  const theme = useTheme();

  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const handleToFirstPage = () => {
    setPage(1);
  };

  const handleChangeLimit = (updatedLimit: number) => {
    setPage(1);
    setLimit(updatedLimit);
  };

  const tableHeader: TableColumn<any>[] = [
    {
      name: "Project",
      selector: (row: GetListSalesDistributorRecords) =>
        getProjectType(row.project),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: "Nama Distributor",
      cell: (row: GetListSalesDistributorRecords) => (
        <StackedColumn
          topValue={row.distributorName}
          bottomValue={row.nomorInvoice}
          isTopBoldApplied={true}
          isTopColorPrimaryBlue
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1.5,
    },
    {
      name: "Tanggal Faktur",
      selector: (row: GetListSalesDistributorRecords) =>
        new DateExodus(row.salesPeriode).getStringDateFormat("D MMMM YYYY "),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: "ID Distributor",
      selector: (row: GetListSalesDistributorRecords) =>
        row.outletIdDistributor,
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: "Nama Outlet",
      cell: (row: GetListSalesDistributorRecords) => (
        <StackedColumn
          topValue={row.outletName}
          bottomValue={row.outletCode}
          isTopBoldApplied={true}
          isTopColorPrimaryBlue
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1.5,
    },
    {
      name: "Sektor",
      selector: (row: GetListSalesDistributorRecords) =>
        getOutletSectorType(row.outletSectorType),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: "Total Sales",
      selector: (row: GetListSalesDistributorRecords) =>
        CurrencyIdrFormatter.convertToRp(row.total),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 1,
    },
    {
      name: "",
      cell: (row: GetListSalesDistributorRecords) => (
        <LihatDetailButton
          onClick={() =>
            navigate({
              pathname: "/sales-distributor/" + row.salesHeaderId,
            })
          }
        />
      ),
    },
  ];
  return (
    <SalesDistributorContext.Provider
      value={{
        filterValues: filterValues,
        setFilterValues: setFilterValues,
      }}
    >
      <>
        <Box>
          <PageTitle label="Sales Distributor" />
          <Box
            display={"flex"}
            flexDirection={{
              base: "column",
              tablet: "row",
            }}
            gap={4}
            justifyContent={"space-between"}
            alignItems={{
              base: "end",
              tablet: "start",
            }}
            mt={"16.5px"}
          >
            <SalesDistributorAppliedFilterList
              filterValuesDataBox={filterValues}
            />
            <Box display={"flex"} gap={3}>
              <ShowFilterButton onClick={onOpenFilter} />
              {isDesktopView && (
                <ExportExcelButton
                  onClick={exportData}
                  isLoading={loadingExportHeader}
                  isDisabled={isExportDisabled}
                />
              )}
            </Box>
          </Box>
          {!isEmpty(error) ? (
            <ErrorPage message={error.message} />
          ) : loading ? (
            <CircularProgress
              sx={{
                display: "flex",
                marginTop: "32px",
                justifyContent: "center",
              }}
              isIndeterminate
            />
          ) : (
            <Box
              mt={{
                base: "12px",
                tablet: "20px",
              }}
            >
              {isDesktopView ? (
                <TableDataComponent
                  data={isEmpty(distributorList) ? [] : distributorList.records}
                  checboxOn={false}
                  paginationOn={false}
                  tableHeader={tableHeader}
                  paginationLimit={[0]}
                  onSelectChange={() => {}}
                />
              ) : (
                distributorList &&
                distributorList.records.map((eachData) => {
                  return (
                    <MobileSalesDistributorList
                      key={eachData.salesHeaderId}
                      data={eachData}
                    />
                  );
                })
              )}
            </Box>
          )}
          <Box
            display={"flex"}
            justifyContent={{
              base: "center",
              tablet: "end",
            }}
            mt={"12px"}
          >
            <Pagination
              handleOnNavigateFirstPage={handleToFirstPage}
              handleOnLimitChange={handleChangeLimit}
              handleOnBack={handlePrevPage}
              handleOnNext={handleNextPage}
              page={page}
              limit={limit}
              totalData={distributorList?.metadata.totalData}
              totalPage={distributorList?.metadata.totalPage}
              showCurrentPageDetail
              showLimit
              showNavigateFirstPage
            />
          </Box>
        </Box>
        <Spacer />
        {!isDesktopView && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            position="sticky"
            bottom={0}
            paddingY="12px"
            backgroundColor={theme.colors.exodus.background}
          >
            <ExportExcelButton
              onClick={exportData}
              isLoading={loadingExportHeader}
              isDisabled={isExportDisabled}
            />
          </Box>
        )}
        <SalesDistributorFilterDialog
          onClose={onCloseFilter}
          open={isFilterOpen}
          saveData={saveFilter}
          setPageDefault={() => setPage(1)}
        />
      </>
    </SalesDistributorContext.Provider>
  );
};

export default SalesDistributor;
