/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { MonitorKunjunganListParam } from "../dataProviders/MonitorKunjunganParamType";
import {
  MonitorKunjunganModelData,
  MonitorKunjunganModelPack,
} from "../models/MonitorKunjunganDataModel";
import MonitorKunjunganProvider from "../dataProviders/MonitorKunjunganProvider";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import IsTypeOfErrorResponse from "../../../../utils/IsTypeOfErrorResponse";

const useGetMonitorKunjunganList = ({
  isPsspActive,
  user_id,
  start_period,
  end_period,
  project
}: MonitorKunjunganListParam) => {
  const [monitorKunjunganData, setData] = useState<MonitorKunjunganModelData[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: MonitorKunjunganModelPack | undefined =
          await MonitorKunjunganProvider.getMonitorKunjunganList({
            isPsspActive: isPsspActive,
            user_id: user_id,
            start_period: start_period,
            end_period: end_period,
            project: project,
          });

        setData(responseData!.data);

        setLoading(false);
      } catch (error: any) {
        setData([]);
        if (IsTypeOfErrorResponse(error)) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage(UNKNOWN_ERROR_RESPONSE.message);
        }
        setLoading(false);
      }
    }

    if (user_id && start_period && end_period) {
      getData();
    }
  }, [end_period, isPsspActive, start_period, user_id, project]);

  return { monitorKunjunganData, loading, errorMessage };
};

export default useGetMonitorKunjunganList;
