import { useState, useEffect } from "react";
import SalesOutletProvider from "../../../../dataProviders/SalesOutletProvider";
import ApiGetSummarySalesOutletResponse from "../../../list/models/apiResponses/ApiGetSummarySalesOutletResponse";
import { OutletModel } from "../../../../../../../models/Outlet/OutletModel";
import DateExodus from "../../../../../../../models/DateExodus";
import ErrorResponse from "../../../../../../../models/common/ErrorResponse";
import { ProjectEnum } from "../../../../../../../models/common/Project";

type SalesOutletGetOneSummaryParam = {
  outletId?: number;
  startDate?: DateExodus;
  endDate?: DateExodus;
  userId?: number;
  project?: ProjectEnum;
};

const useGetOneSummary = (params: SalesOutletGetOneSummaryParam) => {
  const [data, setData] = useState<ApiGetSummarySalesOutletResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setError(null);
        setIsLoading(true);
        const dummyOutlet: OutletModel = {
          outletId: params.outletId!,
          outletCode: "",
          outletName: "",
          outletLabel: "",
          sectorType: {
            label: "",
            value: "",
          },
          address: "",
          codePost: null,
          groupTerritoryId: null,
          groupTerritoryName: null,
        };

        const result = await SalesOutletProvider.getSummary({
          startDate: params.startDate,
          endDate: params.endDate,
          outlets: [dummyOutlet],
          userId: params.userId,
          project: params?.project,
        });

        setData(result);
      } catch (error) {
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setIsLoading(false);
      }
    }

    if (params.endDate && params.startDate && params.outletId) {
      getData();
    }
  }, [params.endDate, params.startDate, params.outletId]);

  return { data, isLoading, error };
};

export default useGetOneSummary;
