import theme from "../../../layout/theme/theme";

const useDesktopBudgetCardStyle = () => {
  const paddingX: string = "24px";
  const paddingY: string = "24px";

  const cardContainer = {
    paddingY,
    minWidth: "650px",
    maxWidth: "650px",
    backgroundColor: theme.colors.exodus.white,
    borderRadius: "12px",
    boxShadow: "0px 7px 24px 4px #282c7a26",
  };

  const cardTitle = {
    paddingX,
    fontSize: "28px",
    fontWeight: 600,
    color: theme.colors.exodus.primaryBlue,
    textAlign: "left",
  };

  const cardSubtitle = {
    fontSize: "18px",
    fontWeight: 600,
    color: theme.colors.exodus.primaryBlue,
    textAlign: "left",
  };

  const cardValue = {
    fontSize: "18px",
    textAlign: "left",
  };

  const cardRow = {
    paddingX,
    paddingY: "6px",
  };

  const cardRowEven = {
    ...cardRow,
    backgroundColor: theme.colors.exodus.tableDataBackground,
  };

  const cardDivider = {
    border: "1px",
    color: theme.colors.exodus.primaryBlue,
    width: "600px",
    marginY: "12px",
  };

  return {
    cardContainer,
    cardDivider,
    cardRow,
    cardRowEven,
    cardTitle,
    cardSubtitle,
    cardValue,
  };
};

export default useDesktopBudgetCardStyle;
