import ApiGetListResponseMetadata from "../../../../models/common/ApiGetListResponseMetadata";
import { SalesProductRecordModel } from "./SalesProductModel";

class SalesProductListResponse {
  metadata: ApiGetListResponseMetadata | null;
  records: SalesProductRecordModel[];

  constructor(json: { metadata: any; records: any }) {
    this.metadata = json?.metadata
      ? new ApiGetListResponseMetadata(json.metadata)
      : null;

    const tempRecords: SalesProductRecordModel[] = [];
    if (json.records !== null) {
      json.records.map((eachRecord: any) => {
        tempRecords.push({
          productId: eachRecord.product_id,
          productName: eachRecord.product_name,
          productCodeByPharos: eachRecord.product_pharos_code,
          estimatedUnit: eachRecord.estimated_unit,
          estimatedCost: eachRecord.estimated_value,
          salesUnit: eachRecord.sales_unit,
          salesValue: eachRecord.sales_value,
        });
      });
    }

    this.records = tempRecords;
  }
}

export { SalesProductListResponse };
