import {
  Stack,
  Button,
  useToast,
  Card,
  HStack,
  Box,
  Checkbox,
  Text,
  useTheme,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import EmailField from "./components/EmailField";
import PasswordField from "./components/PasswordField";
import { useNavigate } from "react-router-dom";
import React from "react";
import IndexedDatabase from "../../database/IndexDB";
import LoggedUser from "../../models/common/LoggedUser";
import { setLoggedUser } from "../../redux/AuthReducer/AuthReducer";
import useGetUserProfile from "../../services/Authentication/useGetUserProfile";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import usePostLogin from "./services/usePostLogin";
import { UNKNOWN_ERROR_RESPONSE } from "../../constants/common/ErrorConstants";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../constants/common/AxiosResponseConstants";
import useLoginStyle from "./services/useLoginStyle";
import { LoginResponse } from "./models/commonModels";
import { FAILED_TO_LOGIN, SUCCESS_LOGIN } from "./constants/commonConstants";
import pharosSvgLogo from "../../assets/PharosLogo.svg";
import IsTypeOfErrorResponse from "../../utils/IsTypeOfErrorResponse";
import {
  EMAIL_VERIFICATION_NAVIGATION_URL,
  FORGOT_PASSWORD_NAVIGATION_ROOT,
} from "../../constants/common/RootConstants";
import useTitle from "../../services/useTitle";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const LoginPage = () => {
  const methods = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  useTitle("Login");

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isRememberMe, setIsRememberMe] = React.useState<boolean>(false);
  const { rootStyle, cardStyle, logoStyle } = useLoginStyle();

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  React.useEffect(() => {
    if (loggedUser) {
      navigate("/");
    }
  }, [loggedUser]);

  const handleOnResolve = async () => {
    try {
      const data: LoggedUser = await useGetUserProfile();
      dispatch(setLoggedUser(data));
      
      localStorage.clear();
      IndexedDatabase.delete();
      
      if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
      toast({
        title: SUCCESS_LOGIN,
        status: "success",
      });
      navigate("/");
    } catch (error: unknown) {
      if (IsTypeOfErrorResponse(error)) {
        toast({
          title: FAILED_TO_LOGIN,
          description: `${error.status} ${error.code} ${error.message}`,
          status: "error",
        });
      } else {
        toast({
          title: FAILED_TO_LOGIN,
          description: `${error}`,
          status: "error",
        });
      }
    }
  };

  const handleOnReject = (error: unknown) => {
    if (IsTypeOfErrorResponse(error)) {
      toast({
        title: FAILED_TO_LOGIN,
        description: `${error.status} ${error.code} ${error.message}`,
        status: "error",
      });
    } else {
      toast({
        title: FAILED_TO_LOGIN,
        description: `${UNKNOWN_ERROR_RESPONSE.message}`,
        status: "error",
      });
    }
  };

  const handleOnSubmit = async (value: any) => {
    setIsLoading(true);
    const payload = {
      email: value.email,
      password: value.password,
      remember_me: isRememberMe,
    };
    try {
      const response: LoginResponse = await usePostLogin(payload);
      if (
        response.message === SUCCESS_MESSAGE &&
        response.status === SUCCESS_GET_STATUS
      ) {
        handleOnResolve();
      }
    } catch (e: unknown) {
      handleOnReject(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack sx={rootStyle}>
      <Card sx={cardStyle}>
        <Box sx={logoStyle}>
          <Image src={pharosSvgLogo} alt="pharosSvgLogo" boxSize="150px" />
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
            <EmailField />
            <PasswordField />
            <HStack
              sx={{
                mb: 6,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setIsRememberMe(e.target.checked)
                }
              >
                <Text
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                  color={theme.colors.exodus.darkGrey}
                >
                  Tetap Masuk
                </Text>
              </Checkbox>
              <Button
                variant="link"
                onClick={() =>
                  navigate(
                    FORGOT_PASSWORD_NAVIGATION_ROOT +
                      EMAIL_VERIFICATION_NAVIGATION_URL
                  )
                }
              >
                <Text
                  color={theme.colors.exodus.secondaryBlue}
                  fontSize={16}
                  fontWeight={400}
                  sx={{
                    textDecoration: "underline",
                  }}
                >
                  Lupa Password?
                </Text>
              </Button>
            </HStack>
            <Button
              mt={4}
              colorScheme="buttonSuccess"
              isLoading={isLoading}
              type="submit"
              sx={{ width: "100%" }}
            >
              Login
            </Button>
          </form>
        </FormProvider>
        <HStack sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Text
            color={theme.colors.exodus.darkGrey}
            fontSize={isDesktopView ? 14 : 12}
            fontWeight={400}
          >
            v{APP_VERSION}
          </Text>
        </HStack>
      </Card>
    </Stack>
  );
};

export default LoginPage;
