import { useEffect, useState } from "react";
import DataDistributor from "../model/DataDistributor";
import DashboardDataProvider from "../dataProvider/DashboardDataProvider";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import DateExodus from "../../../models/DateExodus";
import IsTypeOfErrorResponse from "../../../utils/IsTypeOfErrorResponse";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const useDistributorLastUpdate = () => {
  const [data, setData] = useState<DataDistributor[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response =
        await DashboardDataProvider.getDistributorLastUpdateSales();
      if (response.status === SUCCESS_GET_STATUS) {
        if (response["message"] === SUCCESS_MESSAGE) {
          const dataResult: DataDistributor[] = response.data.map(
            (json: any) => {
              return {
                id: json["id"],
                distributorName: json["distributor_name"],
                periodSales: DateExodus.fromBackend(
                  json["sales_header"]["period_sales"]
                ),
              };
            }
          );

          setData(dataResult);
        }
      } else {
        setError(response.data["code"]);
      }
    } catch (error: any) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error.message);
        return;
      }
      setError(UNKNOWN_ERROR_RESPONSE.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return { data, error, loading };
};

export default useDistributorLastUpdate;
