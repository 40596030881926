import {
  AccordionPanel,
  AccordionButton,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { SidebarAccordionType } from "../../models/SidebarAccordionType";

const SidebarAccordion = ({ menuList, onClose }: SidebarAccordionType) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleOnClick = (pathName: string) => {
    navigate(pathName);
    onClose();
  };

  return menuList.map((eachMenu, key: number) => (
    <AccordionPanel
      pb={0}
      key={key}
      onClick={() => handleOnClick(eachMenu.pathName)}
    >
      <AccordionButton>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          {eachMenu.icon}
          <Text
            sx={{ width: "100%", textAlign: "left" }}
            fontSize={18}
            fontWeight={"normal"}
            color={theme.colors.exodus.primaryBlue}
          >
            {eachMenu.title}
          </Text>
        </Stack>
      </AccordionButton>
    </AccordionPanel>
  ));
};

export default SidebarAccordion;
