import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import HorizontalAccordionDetail from "../../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import { BiayaMurniStandarisasiData } from "../../../../../models/apiResponses/BiayaMurniStandarisasiData";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";
import { useStandarisasiOrManagerContext } from "../../../contexts/StandarisasiOrManagerContext";
import BiayaMurniStandarisasiOrDialog from "./BiayaMurniStandarisasiOrDialog";
import { useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { projectEnumToUi } from "../../../../../../../models/common/Project";

const BiayaMurniStandarisasiAccordionItem = ({
  index,
  data,
  handleChangeAccordion,
  hasProject,
}: {
  index: number;
  data: BiayaMurniStandarisasiData;
  handleChangeAccordion: Function;
  hasProject: boolean;
}): JSX.Element => {
  const theme = useTheme();

  const [isDialogOpen, setisDialogOpen] = useState<boolean>(false);

  const {
    actions: { fetchOneByIndex },
  } = useStandarisasiOrManagerContext();

  const handleOpenDialog = () => {
    setisDialogOpen(true);
    fetchOneByIndex(index);
  };

  const handleCloseDialog = () => {
    setisDialogOpen(false);
  };

  return (
    <AccordionItem borderColor={theme.colors.exodus.background}>
      <BiayaMurniStandarisasiOrDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        index={index}
      />
      <AccordionButton
        px={0}
        onClick={() => {
          handleChangeAccordion();
        }}
      >
        <Box sx={{ width: "100%", textAlign: "left" }}>
          {hasProject && (
            <Text
              fontSize={14}
              fontWeight={600}
              color={theme.colors.exodus.primaryBlue}
            >
              {projectEnumToUi(data.project)}
            </Text>
          )}
          <Text
            fontSize={14}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
          >
            {data.outletName}
          </Text>
          <Text fontSize={14}>{data.outletCode}</Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4} pt={0}>
        <HorizontalAccordionDetail
          leftText={"ID"}
          rightSide={`STD - ${data.standarisasiId}`}
          size="small"
        />
        <HorizontalAccordionDetail
          leftText={"Nama Customer"}
          rightSide={
            <VStack alignItems={"flex-end"}>
              <Text textAlign={"right"} fontSize={12}>
                {data.customerName}
              </Text>
              <Text textAlign={"right"} fontSize={12}>
                {data.customerCode}
              </Text>
            </VStack>
          }
          isRowColored
          size="small"
        />
        <HorizontalAccordionDetail
          leftText={"Periode"}
          rightSide={`${data.startDate.getStringDateFormat(
            "MMMM YYYY"
          )} - ${data.endDate.getStringDateFormat("MMMM YYYY")}`}
          size="small"
        />
        <HorizontalAccordionDetail
          leftText={"Total Biaya"}
          rightSide={CurrencyIdrFormatter.convertToRp(data.biaya)}
          size="small"
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText={""}
          rightSide={
            <Button
              onClick={handleOpenDialog}
              variant={"ghost"}
              colorScheme="buttonSecondary"
              p={1}
              leftIcon={<MdInfoOutline />}
            >
              <Text
                fontSize={12}
                fontWeight={600}
                color={theme.colors.exodus.primaryBlue}
              >
                Tanggal Realisasi
              </Text>
            </Button>
          }
          size="small"
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default BiayaMurniStandarisasiAccordionItem;
