import React, { useContext } from "react";
import MonitorSalesTargetContext from "../../context/MonitorSalesTargetContext";
import { useTheme, Text, Box, Spinner } from "@chakra-ui/react";
import {
  ListSalesTargetRecords,
  TargetSalesList,
} from "../../../models/SalesTargetModelPack";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";
import { isEmpty } from "lodash";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import Pagination from "../../../../../../components/Pagination/Pagination";
import { useAppSelector } from "../../../../../../redux/hooks";
import { ROLE_ID_SUPER_ADMIN, ROLE_ID_TRADE_TEAM } from "../../../../../../constants/common/RoleConstants";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";
import { TableColumn } from "react-data-table-component";

const DesktopTableMonitorSalesTarget: React.FC = () => {
  const theme = useTheme();
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const {
    salesTargetData,
    salesTargetError,
    salesTargetLoading,
    filterValues,
    handleChangePageOrLimit,
    metadataSalesTarget,
    setSelectedSalesTarget,
  } = useContext(MonitorSalesTargetContext);

  const generateMonthsName = () => {
    if (filterValues && filterValues.idKuartal) {
      if (filterValues.idKuartal === 1) {
        return ["Januari", "Februari", "Maret"];
      } else if (filterValues.idKuartal === 2) {
        return ["April", "Mei", "Juni"];
      } else if (filterValues.idKuartal === 3) {
        return ["Juli", "Agustus", "September"];
      } else if (filterValues.idKuartal === 4) {
        return ["Oktober", "November", "Desember"];
      }
    }

    return [];
  };

  const generateValueTargetPerMonth = (
    data: TargetSalesList[],
    monthName: string
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].salesPeriod.getStringDateFormat("MMMM") === monthName) {
        return CurrencyIdrFormatter.convertToRp(Number(data[i].valueTarget));
      }
    }

    return "-";
  };

  const salesTargetHeader: TableColumn<ListSalesTargetRecords>[] = [
    {
      name: "Field Force",
      minWidth: "200px",
      cell: (row: ListSalesTargetRecords) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={
            !isEmpty(row.fieldForceIdentity)
              ? row.fieldForceIdentity?.fieldForceName
              : "Vacant"
          }
          bottomValue={
            !isEmpty(row.fieldForceIdentity)
              ? row.fieldForceIdentity?.fieldForceNip
              : ""
          }
        />
      ),
    },
    {
      name: "Area GT",
      minWidth: "250px",
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {!isEmpty(row.userZoneName) ? row.userZoneName : "-"}
        </Text>
      ),
    },
    {
      name: "Tanggal Input",
      minWidth: "150px",
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {!isEmpty(row.targetSalesList[0])
            ? row.targetSalesList[0].createdAt.getStringDateFormat(
                "D MMMM YYYY"
              )
            : "-"}
        </Text>
      ),
    },
    {
      name: `Target ${generateMonthsName()[0]}`,
      minWidth: "160px",
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {generateValueTargetPerMonth(
            row.targetSalesList,
            generateMonthsName()[0]
          )}
        </Text>
      ),
    },
    {
      name: `Target ${generateMonthsName()[1]}`,
      minWidth: "160px",
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {generateValueTargetPerMonth(
            row.targetSalesList,
            generateMonthsName()[1]
          )}
        </Text>
      ),
    },
    {
      name: `Target ${generateMonthsName()[2]}`,
      minWidth: "160px",
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {generateValueTargetPerMonth(
            row.targetSalesList,
            generateMonthsName()[2]
          )}
        </Text>
      ),
    },
    {
      name: "Diinput Oleh",
      minWidth: "160px",
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {!isEmpty(row.targetSalesList[0])
            ? row.targetSalesList[0].createdBy?.createdByName
            : "-"}
        </Text>
      ),
    },
    {
      name: "Diapprove Oleh",
      minWidth: "160px",
      cell: (row: ListSalesTargetRecords) => (
        <Text textAlign="left">
          {!isEmpty(row.targetSalesList[0])
            ? row.targetSalesList[0].approvedBy?.approvedByNama ?? "-"
            : "-"}
        </Text>
      ),
    },
  ];

  const allowedApprove = () => {
    if (loggedUser && (loggedUser.userRoleId === ROLE_ID_TRADE_TEAM  || loggedUser.userRoleId === ROLE_ID_SUPER_ADMIN)) return true;
    return false;
  };

  const selectRowDisabledCriteria = (row: ListSalesTargetRecords) => {
    if (row.targetSalesList.length > 0 && !row.targetSalesList[0].approvedBy) {
      return false;
    }
    return true;
  };

  const handleRowSelected = (data: any) => {
    const tempRow: number[] = [];
    for (let i = 0; i < data.selectedRows.length; i++) {
      if (data.selectedRows[i].targetSalesList.length !== 0) {
        for (
          let idx = 0;
          idx < data.selectedRows[i].targetSalesList.length;
          idx++
        ) {
          tempRow.push(data.selectedRows[i].targetSalesList[idx].targetSalesId);
        }
      }
    }
    setSelectedSalesTarget(tempRow);
  };

  if (salesTargetLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (salesTargetError && salesTargetData.length < 0) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={salesTargetError.toString()} />
      </Box>
    );
  }

  if (salesTargetData.length > 0 && salesTargetData[0].project) {
    salesTargetHeader.unshift({
      name: "Project",
      cell: (row: ListSalesTargetRecords) => (
        <Text align="left">{row.project!.label}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    });
  }

  return salesTargetData.length > 0 ? (
    <>
      <Box marginBottom="15px">
        <TableDataComponent
          data={salesTargetData}
          tableHeader={salesTargetHeader}
          onSelectChange={handleRowSelected}
          paginationOn={false}
          paginationLimit={[]}
          checboxOn={allowedApprove()}
          checkBoxDisabled={selectRowDisabledCriteria}
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"}>
        <Pagination
          handleOnLimitChange={(data: number) =>
            handleChangePageOrLimit("limit", data)
          }
          handleOnBack={() => handleChangePageOrLimit("prev")}
          handleOnNext={() => handleChangePageOrLimit("next")}
          page={filterValues?.page}
          limit={filterValues?.limit}
          totalData={metadataSalesTarget?.totalData}
          totalPage={metadataSalesTarget?.totalPage}
          showLimit
          showCurrentPageDetail
          handleOnNavigateFirstPage={() => handleChangePageOrLimit("first")}
        />
      </Box>
    </>
  ) : (
    <Box
      marginTop="24px"
      marginBottom="15px"
      display={"flex"}
      justifyContent={"start"}
    >
      <DataNotFound />
    </Box>
  );
};

export default DesktopTableMonitorSalesTarget;
