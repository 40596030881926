import { useEffect, useState } from "react";
import Budget from "../models/BudgetModel";
import { MonitoringBudgetingProvider } from "../dataProviders/MonitoringBudgetingProvider";
import { COMMON_ERRORS } from "../../../constants/common/ErrorConstants";
import MonitoringFilterValues from "../models/MonitoringFilterValues";
import LoggedUser from "../../../models/common/LoggedUser";
import { isEmpty } from "lodash";

const useMonitoringBudgeting = (
  filterValues: MonitoringFilterValues | null,
  loggedUser: LoggedUser | null
) => {
  const [budgetList, setBudgetList] = useState<Budget[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!isEmpty(filterValues)) {
      fetch();
    }
  }, [filterValues]);

  const fetch = async () => {
    setLoading(true);
    const payload = {
      start_period: filterValues?.periodeAwal.getEpochDate(),
      end_period: filterValues?.periodeAkhir.getEpochDate(),
      project: filterValues?.projectAvailable?.value || "ethical",
      user_id: filterValues?.bawahan?.id
        ? filterValues?.bawahan.id
        : loggedUser?.userId,
    };

    try {
      setError(null);
      const data = await MonitoringBudgetingProvider.getList(payload);
      setBudgetList(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        setError(error.response.data.message);
        return;
      }
      setError(COMMON_ERRORS.ERROR_WHEN_FETCHING_DATA);
    }
  };

  return { budgetList, loading, error };
};

export default useMonitoringBudgeting;
