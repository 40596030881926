import { createContext } from "react";
import { KlaimSalesListModelRecords } from "../../models/KlaimSalesListModel";
import ApiGetListResponseMetadata from "../../../../models/common/ApiGetListResponseMetadata";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import KlaimSalesFilterAndPagination from "../../models/KlaimSalesFilterAndPaginationModel";

type MonitorKlaimSalesProps = {
  filterValues: KlaimSalesFilterAndPagination | null;
  loadingFilter: boolean;
  errorFilter: string | null;
  setFilterMonitorKlaimSales: (data: KlaimSalesFilterAndPagination) => void;
  handleChangePageOrLimit: (
    type: "first" | "next" | "prev" | "limit",
    newLimit?: number
  ) => void;
  handleClearFilter: () => void;
  klaimSalesListData: KlaimSalesListModelRecords[];
  klaimSalesMetadata: ApiGetListResponseMetadata | null;
  loading: boolean;
  errorResponse: ErrorResponse | null;
};

const defaultValue: MonitorKlaimSalesProps = {
  filterValues: null,
  loadingFilter: false,
  errorFilter: null,
  setFilterMonitorKlaimSales: () => {},
  handleChangePageOrLimit: () => {},
  handleClearFilter: () => {},
  klaimSalesListData: [],
  klaimSalesMetadata: null,
  loading: false,
  errorResponse: null,
};

const MonitorKlaimSalesContext = createContext(defaultValue);

export default MonitorKlaimSalesContext;
