import { Box, FormControl } from "@chakra-ui/react";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import {
  ApprovalRencanaStatus,
  RencanaStatus,
} from "../../models/ApprovalRencanaKunjunganStatus";
import { useFormContext } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import ApprovalRencanaKunjunganContext from "../../context/ApprovalRencanaKunjunganContext";

const RencanaKunjunganStatusField = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { filterValues } = useContext(ApprovalRencanaKunjunganContext);

  const [selectedStatus, setSelectedStatus] = useState<
    ApprovalRencanaStatus | null | undefined
  >(filterValues?.status);

  useEffect(() => {
    setValue("status", selectedStatus);
  }, [selectedStatus]);

  const StatusOptions: ApprovalRencanaStatus[] = [
    {
      status_id: RencanaStatus.Pending,
      status_label: RencanaStatus.Pending,
    },
    {
      status_id: RencanaStatus.Request,
      status_label: RencanaStatus.Request,
    },
    {
      status_id: RencanaStatus.Approved,
      status_label: RencanaStatus.Approved,
    },
  ];

  return (
    <Box sx={{ width: "100%", marginBottom: "5px" }}>
      <FormControl width="100%" isInvalid={!!errors.status}>
        <FormInputLabel title="Status" />
        <CustomSelect
          {...register("status")}
          options={StatusOptions}
          isClearable
          getOptionValue={(data: ApprovalRencanaStatus) => data.status_id}
          getOptionLabel={(data: ApprovalRencanaStatus) => data.status_label}
          onChange={(data: ApprovalRencanaStatus) => {
            setValue("status", data ? data : null);
            setSelectedStatus(data);
          }}
          value={selectedStatus}
          placeholder="Status"
        />
      </FormControl>
    </Box>
  );
};

export default RencanaKunjunganStatusField;
