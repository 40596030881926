import { Box } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import RoleModel from "../../../../../models/Users/RoleModel";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import { SECTOR_LIST } from "../../../../../constants/common/SectorConstants";
import SectorModel from "../../../../../models/Sector/SectorModel";
import { isEmpty } from "lodash";

const FilterBySector = () => {
  const formContext = useFormContext();
  const watchSector = formContext.watch("sector");
  const watchProductList = formContext.watch("productList");

  return (
    <Box width={"100%"}>
      <FormInputLabel title="Sektor" />
      <CustomSelect
        options={SECTOR_LIST}
        onChange={(eachSector: SectorModel | null) => {
          formContext.setValue("sector", eachSector);
        }}
        getOptionLabel={(eachSector: RoleModel) => eachSector.label}
        value={watchSector}
        placeholder="Sektor"
        getOptionValue={(eachSector) => eachSector.label}
        isDisabled={!isEmpty(watchProductList)}
        isClearable
      />
    </Box>
  );
};

export default FilterBySector;
