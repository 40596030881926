import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { useStandarisasiOrManagerContext } from "../../../contexts/StandarisasiOrManagerContext";
import { BiayaMurniOrCode } from "../../../../../models/BiayaMurniOrCode";

const BiayaMurniStandarisasiOrDialog = ({
  isOpen,
  onClose,
  index,
}: {
  isOpen: boolean;
  onClose: () => void;
  index: number;
}) => {
  const theme = useTheme();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      autoFocus={false}
      size={"sm"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center
            sx={{
              justifyContent: "space-between",
              display: "flex",
              gap: 10,
            }}
          >
            <Text color={theme.colors.exodus.primaryBlue} fontWeight={600}>
              Tanggal Realisasi
            </Text>
          </Center>
        </ModalHeader>
        <ModalBody>
          <ModalBodyContent index={index} />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="buttonSecondary"
            onClick={onClose}
            sx={{ width: "100% " }}
            variant={"outline"}
          >
            Tutup
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ModalBodyContent = ({ index }: { index: number }): JSX.Element => {
  const theme = useTheme();

  const {
    states: { dataArr, errorArr, isLoadingArr },
  } = useStandarisasiOrManagerContext();

  if (isLoadingArr[index]) {
    return (
      <Center>
        <Spinner color={theme.colors.exodus.primaryBlue} />
      </Center>
    );
  }

  if (errorArr[index]) {
    return (
      <Center>
        <Text color={theme.colors.exodus.red} fontSize={12} fontWeight={600}>
          {errorArr[index]!.message}
        </Text>
      </Center>
    );
  }

  if (dataArr[index]) {
    return (
      <VStack alignItems={"stretch"}>
        <Stack direction={"row"} gap="2" justifyContent={"space-between"}>
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontSize={12}
            fontWeight={600}
          >
            Nama PT/Kode OR
          </Text>
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontSize={12}
            fontWeight={600}
          >
            Tgl Realisasi
          </Text>
        </Stack>
        {dataArr[index]!.map((val) => {
          return <Fields data={val} />;
        })}
      </VStack>
    );
  }

  return (
    <Center>
      <Text fontSize={12} fontWeight={600}>
        Data tidak ditemukan
      </Text>
    </Center>
  );
};

const Fields = ({ data }: { data: BiayaMurniOrCode }): JSX.Element => {
  const theme = useTheme();

  return (
    <Stack
      direction={"row"}
      gap="2"
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack gap={0} width={"70%"}>
        <Text
          color={theme.colors.exodus.primaryBlue}
          fontSize={12}
          fontWeight={500}
        >
          {data.ptFullName}
        </Text>
        <Text fontSize={12} fontWeight={500}>
          {data.orCode}
        </Text>
      </Stack>
      <Text fontSize={12}>
        {data.tglRealisasi.getStringDateFormat("DD-MM-YYYY")}
      </Text>
    </Stack>
  );
};

export default BiayaMurniStandarisasiOrDialog;
