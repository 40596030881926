import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useTheme,
  ModalBody,
  Flex,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import HeaderRecordApprovalRealisasiContext from "../../context/HeaderRecordApprovalRealisasiContext";

type Props = {
  openApprovalDetail: boolean;
  onClose: () => void;
};

const MobileDialogApprovalDetailApprovalRealisasi: React.FC<Props> = ({
  onClose,
  openApprovalDetail,
}) => {
  const theme = useTheme();
  const { headerData } = useContext(HeaderRecordApprovalRealisasiContext);

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={openApprovalDetail}
      onClose={onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="10px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          <Text>User Approve</Text>
        </ModalHeader>
        <ModalBody>
          <Box textAlign={"left"}>
            <Text color={theme.colors.exodus.primaryBlue} fontWeight={"bold"}>
              Approve Realisasi Kunjungan
            </Text>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={2}
            >
              {/* Put nama pemberi approval here */}
              <Text>
                {headerData?.approvalRealisasiTime?.getStringDateFormat(
                  "D MMMM YYYY"
                )}
              </Text>
            </Box>
          </Box>
          <Flex marginTop="20px" marginBottom="20px">
            <Button
              onClick={onClose}
              width="100%"
              variant="outline"
              colorScheme="buttonSecondary"
            >
              Tutup
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MobileDialogApprovalDetailApprovalRealisasi;
