/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import AxiosBaseClient from "../../config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import {
  CustomerByCustomerIdParam,
  CustomerByOutletIdAndCustomerNameParam,
  CustomerByOutletIdParam,
} from "./CustomerParamType";
import CustomerDataModel from "../../../models/Customer/CustomerDataModel";
import ProjectType from "../../../models/common/ProjectType";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const CustomerProvider = {
  getCustomerByOutletId: async (params: CustomerByOutletIdParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/customer/outlet",
        { params: params }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const customerData: CustomerDataModel[] =
            response.data.data.customer.map((json: any) => {
              return {
                customer_id: json["customer_id"],
                customer_name: json["customer_name"],
                customer_code: json["customer_code"],
                specialis: json["specialis"],
              };
            });
          return Promise.resolve(customerData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getCustomerByLoggedUser: async (params: { project: ProjectType | null }) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/customer/outlet/user",
        {
          params: {
            project: params.project?.value,
          },
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const customerData: CustomerDataModel[] = response.data.data.map(
            (json: any) => {
              return {
                customer_id: json["customer_id"],
                customer_name: json["customer_name"],
                customer_code: json["customer_code"],
                specialis: json["specialis"],
              };
            }
          );
          return Promise.resolve(customerData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getCustomerByOutletIdAndCustomerName: async (
    params: CustomerByOutletIdAndCustomerNameParam
  ) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/customer/outlet/search",
        {
          params: params,
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const customerData: CustomerDataModel[] =
            response.data.data.customer.map((json: any) => {
              return {
                customer_id: json["customer_id"],
                customer_name: json["customer_name"],
                customer_code: json["customer_code"],
                specialis: json["specialis"],
              };
            });
          return Promise.resolve(customerData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getCustomerByCustomerId: async (param: CustomerByCustomerIdParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `/v1/customer/${param.customer_id}`
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const customerData: CustomerDataModel = {
            customer_id: response.data.data["ID"],
            customer_name: response.data.data["customer_name"],
            customer_code: response.data.data["customer_code"],
            specialis: response.data.data["specialis"],
          };
          return Promise.resolve(customerData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
};

export default CustomerProvider;
