import { Button, useMediaQuery, useTheme, useToast } from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import { ListSalesTargetRecords } from "../../models/SalesTargetModelPack";
import { useContext, useState } from "react";
import useInputAndEditSalesTarget from "../../services/useInputSalesTarget";
import { InputSalesTargetParams } from "../../dataProviders/SalesTargetParamType";
import { useNavigate } from "react-router-dom";
import InputSalesTargetContext from "../context/InputSalesTargetContext";
import ErrorResponse from "../../../../../models/common/ErrorResponse";
import DialogInputAndEditTargetSales from "./DialogInputAndEditTargetSales";
import DateExodus from "../../../../../models/DateExodus";

type Props = {
  data: ListSalesTargetRecords;
  isButtonDisabled: boolean;
};

const ButtonInputorEditSalesTarget: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const [openDialogEdit, setOpenDialogEdit] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { inputAndEditSalesTarget, loadingInput } =
    useInputAndEditSalesTarget();
  const { filterValues, refetchSalesTargetData } = useContext(
    InputSalesTargetContext
  );

  let firstMonthEpoch: number;
  let secondMonthEpoch: number;
  let thirdMonthEpoch: number;
  const firstMonthValue = filterValues?.periodeKuartalStart;

  if (firstMonthValue) {
    const firstMonthDate = new Date(firstMonthValue * 1000);

    firstMonthDate.setMonth(firstMonthDate.getMonth() + 1);
    const secondMonthValue = Math.floor(firstMonthDate.getTime() / 1000);

    firstMonthDate.setMonth(firstMonthDate.getMonth() + 1);
    const thirdMonthValue = Math.floor(firstMonthDate.getTime() / 1000);

    firstMonthEpoch = firstMonthValue;
    secondMonthEpoch = secondMonthValue;
    thirdMonthEpoch = thirdMonthValue;
  }

  const handleSubmitUpdate = (value: any) => {
    const convertToInteger = (formattedValue: string) => {
      return parseInt(formattedValue.replace(/\D/g, ""), 10);
    };
    const submittedData: InputSalesTargetParams = {
      data: {
        group_territory_id: props.data.userZoneId,
        target_sales: [
          {
            value_target: convertToInteger(value.target_sales[2].value_target),
            sales_period: new DateExodus(thirdMonthEpoch).toJSON(),
          },
          {
            value_target: convertToInteger(value.target_sales[1].value_target),
            sales_period: new DateExodus(secondMonthEpoch).toJSON(),
          },
          {
            value_target: convertToInteger(value.target_sales[0].value_target),
            sales_period: new DateExodus(firstMonthEpoch).toJSON(),
          },
        ],
      },
      project: filterValues ? filterValues.project : null,
    };

    inputAndEditSalesTarget(submittedData, {
      onSuccess: () => {
        toast({
          title: "Berhasil Mengubah Data Sales Target",
          status: "success",
        });
        setOpenDialogEdit(false);
        navigate("/sales-target", { replace: true });
        refetchSalesTargetData();
      },
      onError: (errorMessage: ErrorResponse) => {
        toast({
          title: `Gagal Mengubah Data Sales Target`,
          description: `${errorMessage.message}`,
          status: "error",
        });
        setOpenDialogEdit(false);
      },
    });
  };

  return (
    <>
      <Button
        variant="ghost"
        onClick={() => {
          setOpenDialogEdit(true);
        }}
        isDisabled={props.isButtonDisabled}
        leftIcon={
          <MdEdit
            style={{
              width: !isDesktopView ? "20px" : "24px",
              height: !isDesktopView ? "20px" : "24px",
            }}
          />
        }
        sx={{
          fontSize: !isDesktopView ? 12 : 14,
          fontWeight: "bold",
          color: theme.colors.exodus.secondaryBlue,
          paddingX: !isDesktopView ? "8px" : 0,
        }}
      >
        Input / Edit
      </Button>
      <DialogInputAndEditTargetSales
        open={openDialogEdit}
        onClose={() => setOpenDialogEdit(false)}
        data={props.data}
        onSubmit={handleSubmitUpdate}
        isLoadingSubmit={loadingInput}
      />
    </>
  );
};

export default ButtonInputorEditSalesTarget;
