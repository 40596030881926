import {
  Box,
  CircularProgress,
  useDisclosure,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import DesktopBudgetCard from "./components/DesktopBudgetCard";
import ShowFilterButton from "../../components/CustomButtons/ShowFilterButton";
import ExportExcelButton from "../../components/CustomButtons/ExportExcelButton";
import FilterBox from "../../components/FilterBox/FilterBox";
import useSaveFilter from "./services/useSaveFilter";
import FilterDialog from "./components/MonitorinBudgetingFilterDialog";
import useGetFilter from "./services/useGetFilter";
import MonitoringFilterValues from "./models/MonitoringFilterValues";
import MonitoringBudgetingContext from "./context/MonitoringBudgetingFilterContext";
import { isEmpty } from "lodash";
import useMonitoringBudgeting from "./services/useMonitoringBudgeting";
import LoggedUser from "../../models/common/LoggedUser";
import { useAppSelector } from "../../redux/hooks";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import useExportExcel from "./services/useExportMonitoring";
import useTitle from "../../services/useTitle";
import MobileBudgetCard from "./components/MobileBudgetCard";

const MonitoringBudget: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  useTitle("Monitoring Budgeting");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { saveFilter } = useSaveFilter();
  const { data: filterPersistentData } = useGetFilter();

  const [filterValues, setFilterValues] =
    React.useState<MonitoringFilterValues | null>(null);

  React.useEffect(() => {
    setFilterValues(filterPersistentData);
  }, [filterPersistentData]);

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  const { budgetList, loading, error } = useMonitoringBudgeting(
    filterValues,
    loggedUser
  );

  const { exportData } = useExportExcel(budgetList, filterValues);

  return (
    <>
      <MonitoringBudgetingContext.Provider
        value={{
          filterValues: filterValues,
          setFilterValues: setFilterValues,
        }}
      >
        <Box sx={{ paddingX: "3%", paddingTop: "2%" }}>
          <FilterDialog
            isOpen={isOpen}
            onClose={onClose}
            saveData={saveFilter}
            isDesktopView={isDesktopView}
            userInfo={loggedUser}
          />
          <PageTitle label="Monitoring Budgeting" />
          <Box
            sx={{
              display: "flex",
              flexDirection: isDesktopView ? "row" : "column",
              justifyContent: "space-between",
              paddingTop: "1%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                paddingRight: "1.5%",
              }}
            >
              {!isEmpty(filterValues?.projectAvailable) && (
                <FilterBox
                  title="Project"
                  content={filterValues?.projectAvailable.label}
                />
              )}
              {!isEmpty(filterValues?.periodeTahun) && (
                <FilterBox
                  title="Tahun"
                  content={filterValues?.periodeTahun.getStringDateFormat(
                    "YYYY"
                  )}
                />
              )}
              {!isEmpty(filterValues?.periodeAwal) && (
                <FilterBox
                  title="Bulan Mulai"
                  content={filterValues?.periodeAwal.getStringDateFormat(
                    "MMMM"
                  )}
                />
              )}
              {!isEmpty(filterValues?.periodeAkhir) && (
                <FilterBox
                  title="Bulan Akhir"
                  content={filterValues?.periodeAkhir.getStringDateFormat(
                    "MMMM"
                  )}
                />
              )}
              {!isEmpty(filterValues?.bawahan) && (
                <FilterBox
                  title="Bawahan"
                  content={filterValues?.bawahan.name}
                />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: isDesktopView ? "start" : "end",
              }}
            >
              <Box sx={{ marginRight: "2%" }}>
                <ShowFilterButton onClick={onOpen} />
              </Box>
              {isDesktopView && <ExportExcelButton onClick={exportData} />}
            </Box>
          </Box>

          {loading && (
            <CircularProgress
              sx={{
                display: "flex",
                marginTop: "32px",
                justifyContent: "center",
              }}
              isIndeterminate
            />
          )}
          {!isEmpty(error) ? (
            <ErrorPage message={error!} />
          ) : (
            budgetList && (
              <Box
                sx={{
                  display: "flex",
                  marginY: "32px",
                  paddingBottom: "24px",
                  overflowX: "auto",
                  width: {
                    mobile: "100%",
                    tablet: "94vw",
                  },
                  gap: "24px",
                  flexDirection: isDesktopView ? "row" : "column",
                }}
              >
                {budgetList.map((eachData) => {
                  return isDesktopView ? (
                    <DesktopBudgetCard key={eachData.bulan} data={eachData} />
                  ) : (
                    <MobileBudgetCard key={eachData.bulan} data={eachData} />
                  );
                })}
              </Box>
            )
          )}
          {!isDesktopView && (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              position="sticky"
              bottom={0}
              paddingY="12px"
              backgroundColor={theme.colors.exodus.background}
            >
              <ExportExcelButton onClick={exportData} />
            </Box>
          )}
        </Box>
      </MonitoringBudgetingContext.Provider>
    </>
  );
};

export default MonitoringBudget;
