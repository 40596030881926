import { useEffect } from "react";

/**
 * Call on the page layout component to set browser page title
 *
 * @param title - Title of the page to set the browser page to
 */
const useTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, []);
};

export default useTitle;
