import DateExodus from "../../../../models/DateExodus";
import SalesDistributorFilterValue from "../model/SalesDistributorFilterValues";

const SalesDistributorIntialValue: SalesDistributorFilterValue =
  new SalesDistributorFilterValue({
    nomorFaktur: null,
    namaOutlet: null,
    namaDistributor: null,
    projectAvailable: null,
    sector: null,
    bridgingType: "1",
    periodeAwal: new DateExodus().getEpochStartOfYear(),
    periodeAkhir: new DateExodus().getEpochEndOfYear(),
  });

export default SalesDistributorIntialValue;
