import DateExodus from "../../../models/DateExodus";

class BiayaMurniOrCode {
  ptFullName: string;
  ptInitial: string;
  tglRealisasi: DateExodus;
  orCode: number;

  constructor(
    ptFullName: string,
    ptInitial: string,
    tglRealisasi: DateExodus,
    orCode: number
  ) {
    this.ptFullName = ptFullName;
    this.ptInitial = ptInitial;
    this.tglRealisasi = tglRealisasi;
    this.orCode = orCode;
  }
}

export { BiayaMurniOrCode };
