/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Spinner, Text, useTheme } from "@chakra-ui/react";
import { useContext } from "react";
import MonitorKunjunganUserListContext from "../../context/MonitorKunjunganUserListContext";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import { TableColumn } from "react-data-table-component";
import UserModel from "../../../../../../models/Users/UserModel";
import ButtonLihatDetailMonitorKunjungan from "../ButtonLihatDetailMonitorKunjungan";

const DesktopTableListUser: React.FC = () => {
  const theme = useTheme();

  const { userData, userLoading, userErrorMessage } = useContext(
    MonitorKunjunganUserListContext
  );

  const userDataColumn: TableColumn<any>[] = [
    {
      name: "Nama Field Force",
      selector: (row: UserModel) => row.name,
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 2,
    },
    {
      name: "NIP",
      selector: (row: UserModel) => row.nip,
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "Jabatan",
      // selector: (row: UserModel) => row.role.label!,
      cell: (row: UserModel) => <Text textAlign="left">{row.role.label}</Text>,
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "",
      cell: (row: UserModel) => (
        <ButtonLihatDetailMonitorKunjungan bawahanId={row.id} />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
  ];

  if (userLoading) {
    return (
      <Box paddingTop="24px" paddingBottom="15px">
        <Spinner size="lg" color={theme.colors.exodus.primaryBlue} />
      </Box>
    );
  }

  if (userErrorMessage) {
    return (
      <Box paddingTop="24px" paddingBottom="15px">
        <ErrorPage message={userErrorMessage.toString()} />
      </Box>
    );
  }

  return userData.length > 0 ? (
    <Box paddingTop="24px" paddingBottom="15px">
      <TableDataComponent
        data={userData}
        tableHeader={userDataColumn}
        onSelectChange={() => {}}
        paginationOn={false}
        paginationLimit={[]}
        checboxOn={false}
      />
    </Box>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default DesktopTableListUser;
