// Route ID Kunjungan
export const ROUTE_ID_INPUT_RENCANA_KUNJUNGAN = 1;
export const ROUTE_ID_APPROVAL_RENCANA_KUNJUNGAN = 2;
export const ROUTE_ID_INPUT_REALISASI_KUNJUNGAN = 3;
export const ROUTE_ID_APPROVAL_REALISASI_KUNJUNGAN = 4;
export const ROUTE_ID_MONITORING_KUNJUNGAN = 5;

// Route ID Target sales
export const ROUTE_ID_INPUT_TARGET_SALES = 6;
export const ROUTE_ID_MONITORING_TARGET_SALES = 7;

// Route ID Performance
export const ROUTE_ID_SALES_PRODUCT = 8;
export const ROUTE_ID_SALES_OUTLET = 9;
export const ROUTE_ID_SALES_DISTRIBUTOR = 10;
export const ROUTE_ID_OUTLET_CARD = 11;
export const ROUTE_ID_INSENTIF = 12;

// Route ID Entertain
export const ROUTE_ID_INPUT_KLAIM_ENTERTAIN = 13;
export const ROUTE_ID_APPROVAL_KLAIM_ENTERTAIN = 14;
export const ROUTE_ID_FINANCE_APPROVAL_KLAIM_ENTERTAIN = 15;
export const ROUTE_ID_MONITOR_KLAIM_ENTERTAIN = 16;

// Route ID DPL / DPF
export const ROUTE_ID_REQUEST_CREATE_DPL_DPF = 17;
export const ROUTE_ID_APPROVAL_CREATE_DPL_DPF = 18;
export const ROUTE_ID_REQUEST_EDIT_DPF = 19;
export const ROUTE_ID_HISTORY_REQUEST_EDIT_DPF = 20;
export const ROUTE_ID_APPROVAL_EDIT_DPF = 21;
export const ROUTE_ID_MONITOR_DPL_DPF = 22;
export const ROUTE_ID_DETAIL_DPL_DPF = 23;

// Route ID PSSP
export const ROUTE_ID_INPUT_PSSP = 24;
export const ROUTE_ID_PSSP_REQUEST_LIST = 25;
export const ROUTE_ID_APPROVAL_PSSP = 26;
export const ROUTE_ID_FINANCE_APPROVAL_PSSP = 27;

// Route ID Standarisasi
export const ROUTE_ID_INPUT_STANDARISASI = 28;
export const ROUTE_ID_APPROVAL_STANDARISASI = 29;
export const ROUTE_ID_FINANCE_APPROVAL_STANDARISASI = 30;
export const ROUTE_ID_MONITOR_STANDARISASI = 31;
export const ROUTE_ID_DETAIL_STANDARISASI = 32;

// Route ID DP
export const ROUTE_ID_INPUT_DOWN_PAYMENT = 33;
export const ROUTE_ID_INPUT_ADDENDUM = 34;
export const ROUTE_ID_APPROVAL_DOWN_PAYMENT = 35;
export const ROUTE_ID_APPROVAL_DOWN_PAYMENT_DETAIL = 36;
export const ROUTE_ID_APPROVAL_DOWN_PAYMENT_ADDENDUM = 37;
export const ROUTE_ID_APPROVAL_DOWN_PAYMENT_PERIODE_AKHIR = 38;
export const ROUTE_ID_TRANSFER_DOWN_PAYMENT = 39;
export const ROUTE_ID_TRANSFER_DOWN_PAYMENT_DETAIL = 40;
export const ROUTE_ID_MONITORING_DOWN_PAYMENT = 41;
export const ROUTE_ID_MONITORING_DOWN_PAYMENT_DETAIL = 42;

// Route ID Klaim Sales
export const ROUTE_ID_INPUT_KLAIM_SALES = 43;
export const ROUTE_ID_APPROVAL_KLAIM_SALES = 44;
export const ROUTE_ID_MONITORING_KLAIM_SALES = 45;

// Route ID Biaya Murni
export const ROUTE_ID_BIAYA_MURNI = 46;

// Route ID Monitoring Budgeting
export const ROUTE_ID_MONITORING_BUDGETING = 47;
