import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  useTheme,
} from "@chakra-ui/react";
import HorizontalAccordionDetail from "../../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";
import { BiayaMurniDplDpfDetailData } from "../../../../../models/apiResponses/BiayaMurniDplDpfDetailData";

const BiayaMurniDplDpfDetailAccordionItem = ({
  record,
}: {
  record: BiayaMurniDplDpfDetailData;
}) => {
  const theme = useTheme();
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            fontSize={14}
          >
            {record.productName}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel mx={4}>
        <HorizontalAccordionDetail
          leftText="Sales"
          rightSide={CurrencyIdrFormatter.convertToRp(record.salesValue) ?? "-"}
        />
        <HorizontalAccordionDetail
          leftText="Diskon"
          rightSide={`${(record.discountPercentage * 100).toFixed(2)}%` ?? "-"}
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText="Biaya"
          rightSide={
            CurrencyIdrFormatter.convertToRp(record.discountCost) ?? "-"
          }
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default BiayaMurniDplDpfDetailAccordionItem;
