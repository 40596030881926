class ApiGetCostTotalsResponse {
  psspDoctorCost: number;
  psspNonDoctorCost: number;
  entertainCost: number;
  dplDpfCost: number;
  standarisasiCost: number;
  dpCost: number;
  totalCost: number;

  constructor(json: any) {
    this.psspDoctorCost = json?.pssp_doctor_cost ?? 0;
    this.psspNonDoctorCost = json?.pssp_non_doctor_cost ?? 0;
    this.entertainCost = json?.entertain_cost ?? 0;
    this.dplDpfCost = json?.discount_cost ?? 0;
    this.standarisasiCost = json?.standarisasi ?? 0;
    this.dpCost = json?.down_payment ?? 0;
    this.totalCost = json?.total_cost ?? 0;
  }
}

export { ApiGetCostTotalsResponse };
