import { createContext } from "react";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import KlaimSalesFilterAndPagination from "../../models/KlaimSalesFilterAndPaginationModel";
import { KlaimSalesListModelRecords } from "../../models/KlaimSalesListModel";
import ApiGetListResponseMetadata from "../../../../models/common/ApiGetListResponseMetadata";

type ApprovalKlaimSalesContextProps = {
  filterValues: KlaimSalesFilterAndPagination | null;
  loadingFilter: boolean;
  errorFilter: string | null;
  setFilterApprovalKlaimSales: (data: KlaimSalesFilterAndPagination) => void;
  klaimSalesData: KlaimSalesListModelRecords[];
  klaimSalesLoading: boolean;
  klaimSalesError: ErrorResponse | null;
  handleChangePageOrLimit: (
    type: "first" | "next" | "prev" | "limit",
    newLimit?: number
  ) => void;
  handleClearFilter: () => void;
  metadataKlaimSales: ApiGetListResponseMetadata | null;
};

const defaultValue: ApprovalKlaimSalesContextProps = {
  filterValues: null,
  loadingFilter: false,
  errorFilter: null,
  setFilterApprovalKlaimSales: () => {},
  klaimSalesData: [],
  klaimSalesLoading: true,
  klaimSalesError: null,
  handleChangePageOrLimit: () => {},
  handleClearFilter: () => {},
  metadataKlaimSales: null,
};

const ApprovalKlaimSalesContext = createContext(defaultValue);

export default ApprovalKlaimSalesContext;
