import {
  Box,
  BoxProps,
  HStack,
  Icon,
  Text,
  forwardRef,
  useTheme,
} from "@chakra-ui/react";
import CustomPopover from "../../../../components/CustomPopover/CustomPopover";
import KeteranganSalesPopover from "./KeteranganSalesPopover";
import { MdInfoOutline } from "react-icons/md";
import { GetListSalesDistributorDetailData } from "../model/GetListSalesDistributorDetailModelPack.ts";
import { isEmpty, isNil } from "lodash";
import DateExodus from "../../../../models/DateExodus.ts";
import { getOutletSectorType } from "../utils/SalesDistributorUtils.ts";
import CurrencyIdrFormatter from "../../../../utils/CurrencyIdrFormatter.ts";

type Props = {
  dataDetail: GetListSalesDistributorDetailData | null;
};

const DesktopSalesDistributorDetailInfo = ({ dataDetail }: Props) => {
  const theme = useTheme();
  const KeteranganSalesButton = forwardRef<BoxProps, "div">((props, ref) => (
    <Box ref={ref} {...props}>
      <Icon
        as={MdInfoOutline}
        color={theme.colors.exodus.primaryBlue}
        boxSize={"20px"}
      />
    </Box>
  ));
  return (
    <HStack gap={10} mt={"20px"} alignItems={"start"}>
      <Box display={"flex"} gap={3} flexDirection={"column"}>
        <HStack gap={"32px"}>
          <Text
            textAlign={"start"}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
          >
            Tanggal Faktur
          </Text>
          <HStack>
            <Text>:</Text>
            <Text textAlign={"start"}>
              {!isNil(dataDetail?.salesPeriode) && dataDetail.salesPeriode
                ? new DateExodus(dataDetail.salesPeriode).getStringDateFormat(
                    "D MMMM YYYY "
                  )
                : "-"}
            </Text>
          </HStack>
        </HStack>
        <HStack gap={"47px"}>
          <Text
            textAlign={"start"}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
          >
            Nama Outlet
          </Text>
          <HStack alignItems={"start"}>
            <Text>:</Text>
            <Text textAlign={"start"}>
              {!isEmpty(dataDetail?.outletName) ? dataDetail?.outletName : "-"}
            </Text>
          </HStack>
        </HStack>
        <HStack gap={"97px"}>
          <Text
            textAlign={"start"}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
          >
            Sektor
          </Text>
          <HStack>
            <Text>:</Text>
            <Text textAlign={"start"}>
              {!isEmpty(dataDetail?.outletSectorType)
                ? getOutletSectorType(dataDetail?.outletSectorType)
                : "-"}
            </Text>
          </HStack>
        </HStack>
      </Box>
      <Box display={"flex"} gap={3} flexDirection={"column"}>
        <HStack gap={"32px"}>
          <Text
            textAlign={"start"}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
          >
            Sales Invoice
          </Text>
          <HStack>
            <Text>:</Text>
            <Text textAlign={"start"}>
              {!isNil(dataDetail?.totalSales) && dataDetail.totalSales
                ? CurrencyIdrFormatter.convertToRp(dataDetail.totalSales)
                : "-"}
            </Text>
            <Box display={"flex"} gap={"8px"} alignItems={"center"}>
              <CustomPopover
                bodyComponent={<KeteranganSalesPopover />}
                triggerComponent={<KeteranganSalesButton />}
              />
            </Box>
          </HStack>
        </HStack>
        <HStack gap={"17px"}>
          <Text
            textAlign={"start"}
            fontWeight={600}
            color={theme.colors.exodus.primaryBlue}
          >
            Sales Terdaftar
          </Text>
          <HStack>
            <Text>:</Text>
            <Text textAlign={"start"}>
              {!isNil(dataDetail?.totalSalesBridging) &&
              dataDetail.totalSalesBridging
                ? CurrencyIdrFormatter.convertToRp(
                    dataDetail.totalSalesBridging
                  )
                : "-"}
            </Text>
          </HStack>
        </HStack>
      </Box>
    </HStack>
  );
};

export default DesktopSalesDistributorDetailInfo;
