import imageCompression from 'browser-image-compression';

const ImageCompressor = async (image: any) => {
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }

    try {
        const compressedFile = await imageCompression(image, options);
        return Promise.resolve(compressedFile);
    } catch (error) {
        console.log(error);
        return Promise.reject(new Error("Gagal Melakukan Kompresi Gambar"));
    }
}

export default ImageCompressor;