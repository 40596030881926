import { Box, useMediaQuery, useTheme } from "@chakra-ui/react";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import MonitorKunjunganRadioGroup from "./MonitorKunjunganRadioGroup";
import MonitorKunjunganPeriodeDanButtonExcel from "./MonitorKunjunganPeriodeDanButtonExcel";
import DesktopTableListMonitorKunjungan from "./desktop/DesktopTableListMonitorKunjungan";
import MobileTableListMonitorKunjungan from "./mobile/MobileTableListMonitorKunjungan";
import MobileButtonExportExcel from "./mobile/MobileButtonExportExcel";

const MonitorKunjunganMonitorListPage: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Box
      minHeight={isDesktopView ? "100%" : "100vh"}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <PageTitle label="Monitor Kunjungan" />
        <MonitorKunjunganRadioGroup />
        <MonitorKunjunganPeriodeDanButtonExcel />
        {isDesktopView && <DesktopTableListMonitorKunjungan />}
        {!isDesktopView && <MobileTableListMonitorKunjungan />}
      </Box>

      {!isDesktopView && <MobileButtonExportExcel />}
    </Box>
  );
};

export default MonitorKunjunganMonitorListPage;
