import { Stack, Text, useTheme } from "@chakra-ui/react";

const SingleRowDetail = ({
  leftText,
  rightText,
}: {
  leftText: string;
  rightText: string | null | undefined;
}) => {
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent={"space-between"}>
      <Text
        fontSize={20}
        fontWeight={600}
        color={theme.colors.exodus.primaryBlue}
      >
        {leftText}
      </Text>
      <Text fontSize={20} fontWeight={600} color={theme.colors.exodus.darkGrey}>
        {rightText ?? "-"}
      </Text>
    </Stack>
  );
};

export default SingleRowDetail;
