import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import IndexedDatabase from "../../database/IndexDB";
import LoggedUser from "../../models/common/LoggedUser";

type AuthState = {
  loggedUser: LoggedUser | null;
};

const initialState: AuthState = {
  loggedUser: null,
};

const AuthReducer = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setLoggedUser: (state, action: PayloadAction<any>) => {
      state.loggedUser = action.payload;
    },
    clearLoggedUser: (state) => {
      state.loggedUser = null;
      localStorage.clear();
      IndexedDatabase.delete();
    },
  },
});

export const { setLoggedUser, clearLoggedUser } = AuthReducer.actions;
export default AuthReducer.reducer;
