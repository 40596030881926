import { Box } from "@chakra-ui/react";
import React from "react";
import TargetSalesProgressCard from "./TargetSalesProgressCard";
import BiayaMurniSalesRatioCard from "./BiayaMurniSalesRatioCard";
import DistributorLastUpdateCard from "./DistributorLastUpdateCard";

const FFDashboardComponent: React.FC = () => {
  return (
    <Box display={"flex"} gap={4} flexDirection={"column"}>
      <Box
        display={"flex"}
        gap={4}
        flexDirection={{
          base: "column",
          tablet: "row",
        }}
      >
        <Box
          width={{
            base: "100%",
            tablet: "50%",
          }}
        >
          <TargetSalesProgressCard />
        </Box>
        <Box
          width={{
            base: "100%",
            tablet: "50%",
          }}
        >
          <BiayaMurniSalesRatioCard />
        </Box>
      </Box>
      <Box display={"flex"} gap={4}>
        <Box
          width={{
            base: "100%",
            tablet: "50%",
          }}
        >
          <DistributorLastUpdateCard />
        </Box>
      </Box>
    </Box>
  );
};

export default FFDashboardComponent;
