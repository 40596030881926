import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";
import { AuthenticationProvider } from "../../../dataProviders/common/Authentication/AuthenticationProvider";
import ErrorResponse from "../../../models/common/ErrorResponse";
import { LoginResponse } from "../models/commonModels";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

type PayloadType = {
  email: string;
  password: string;
  remember_me: boolean;
};

const usePostLogin = async (payload: PayloadType) => {
  try {
    const response = await AuthenticationProvider.login(payload);
    if (
      response?.message === SUCCESS_MESSAGE &&
      response?.status === SUCCESS_GET_STATUS
    ) {
      return response as LoginResponse;
    }
    throw UNKNOWN_ERROR_RESPONSE;
  } catch (error: unknown) {
    if (error as ErrorResponse) {
      throw error;
    }
    throw UNKNOWN_ERROR_RESPONSE;
  }
};

export default usePostLogin;
