import React from "react";
import {
  FormControl,
  FormErrorMessage,
  Input,
  useTheme,
  Text,
  Box,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { InputAndEditSalesTargetModel } from "../../../models/SalesTargetInputModel";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";

type Props = {
  defaultValue?: number;
  index: number;
  title: string;
};

const InputSalesTargetFormField: React.FC<Props> = ({
  defaultValue,
  index,
  title,
}) => {
  const theme = useTheme();
  const {
    register,
    formState: { errors },
  } = useFormContext<InputAndEditSalesTargetModel>();

  const fieldName = `target_sales.${index}.value_target` as const;

  const formatNumberToCurrency = (value: number) => {
    return value.toLocaleString("id-ID");
  };

  return (
    <FormControl
      width={"100%"}
      isInvalid={!!errors.target_sales?.[index]?.value_target}
    >
      <FormInputLabel title={title} />
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2}>
        <Text>RP</Text>
        <Input
          {...register(fieldName, {
            required: "Required",
            min: {
              value: 1,
              message: "Tidak bisa kurang dari 1",
            },
          })}
          type="string"
          onChange={(e) => {
            e.target.value = formatNumberToCurrency(
              Number(e.target.value.replace(/\D/g, ""))
            );
          }}
          placeholder="Target Sales"
          defaultValue={
            defaultValue ? formatNumberToCurrency(defaultValue) : undefined
          }
        />
      </Box>
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.target_sales?.[index]?.value_target?.message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default InputSalesTargetFormField;
