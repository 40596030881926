import { createContext } from "react";
import { HeaderApprovalRealisasiKunjunganModelData } from "../models/ApprovalRealisasiKunjunganModel";

type HeaderRecordApprovalRealisasiContextType = {
  headerData: HeaderApprovalRealisasiKunjunganModelData | null;
  headerLoading: boolean;
  headerError: string | null;
  key: number;
  setKey: (data: number) => void;
};

const initialValue: HeaderRecordApprovalRealisasiContextType = {
  headerData: null,
  headerLoading: false,
  headerError: null,
  key: 0,
  setKey: () => {},
};

const HeaderRecordApprovalRealisasiContext =
  createContext<HeaderRecordApprovalRealisasiContextType>(initialValue);

export default HeaderRecordApprovalRealisasiContext;
