import { Stack } from "@chakra-ui/react";
import ShowFilterButton from "../../../../../../components/CustomButtons/ShowFilterButton";
import FilterBoxArea from "../../common/FilterBoxArea";
import useExportExcel from "../../../services/useExportExcel";
import ExportExcelButton from "../../../../../../components/CustomButtons/ExportExcelButton";

const FilterAndExcelTopBar = ({
  handleOnFilterModalChange,
}: {
  handleOnFilterModalChange: () => void;
}) => {
  const { doExportExcel, isLoading } = useExportExcel();

  return (
    <Stack gap={2} flexDirection={"row"} justifyContent={"space-between"}>
      <Stack flexDirection={"column"}>
        <FilterBoxArea />
      </Stack>
      <Stack flexDirection={"row"}>
        <ShowFilterButton onClick={() => handleOnFilterModalChange()} />
        <ExportExcelButton
          onClick={() => doExportExcel()}
          isLoading={isLoading}
        />
      </Stack>
    </Stack>
  );
};

export default FilterAndExcelTopBar;
