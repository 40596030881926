import { useContext } from "react";
import {
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";
import { useNavigate } from "react-router-dom";

const InputRealisasiRadioGroup: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const navigate = useNavigate();

  const { currentKunjungan, setCurrentKunjungan } = useContext(
    InputRealisasiKunjunganContext
  );

  return (
    <Flex
      gap={isDesktopView ? 4 : 2}
      direction={isDesktopView ? "row" : "column"}
      paddingY={isDesktopView ? "16px" : "12px"}
      align={isDesktopView ? "center" : "start"}
    >
      <Text
        fontSize={isDesktopView ? 20 : 16}
        fontWeight="semibold"
        color={theme.colors.exodus.primaryBlue}
      >
        Jenis Kunjungan
      </Text>
      <RadioGroup
        value={currentKunjungan}
        onChange={(data) => {
          setCurrentKunjungan(data);
          navigate(`/realisasi-kunjungan/${data}`);
        }}
      >
        <Stack
          direction={isDesktopView ? "row" : "column"}
          gap={isDesktopView ? 6 : 1}
        >
          <Radio value="rencana">Rencana Kunjungan</Radio>
          <Radio value="extracall">Extra Call</Radio>
        </Stack>
      </RadioGroup>
    </Flex>
  );
};

export default InputRealisasiRadioGroup;
