/* eslint-disable @typescript-eslint/no-explicit-any */

import DateExodus from "../../../models/DateExodus";

class KlaimSalesDetailModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: KlaimSalesDetailModelData;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = new KlaimSalesDetailModelData(json["data"]);
  }
}

class KlaimSalesDetailModelData {
  claimSalesListId!: number;
  outletTujuanId!: number;
  outletTujuanName!: string;
  outletTujuanCode!: string;
  outletPanelId!: number;
  outletPanelName!: string;
  outletPanelCode!: string;
  pdfLink!: string;
  claimUserId!: number;
  claimUserName!: string;
  status!: string;
  createdAt!: DateExodus;
  unprocessedCreatedAt!: number;
  approvalAt!: DateExodus;
  approvedBy!: number;
  approvedByName!: string;
  keterangan!: string | null;
  claimSales!: KlaimSalesDetailListModelData[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: KlaimSalesDetailListModelData[] = [];

    if (json["claim_sales"] !== null) {
      for (let i = 0; i < json.claim_sales.length; i++) {
        temp.push(new KlaimSalesDetailListModelData(json.claim_sales[i]));
      }
    }

    this.claimSalesListId = json["claim_sales_list_id"];
    this.outletTujuanId = json["outlet_tujuan"];
    this.outletTujuanName = json["outlet_tujuan_name"];
    this.outletTujuanCode = json["outlet_tujuan_code"];
    this.outletPanelId = json["outlet_panel"];
    this.outletPanelName = json["outlet_panel_name"];
    this.outletPanelCode = json["outlet_panel_code"];
    this.pdfLink = json["pdf_link"];
    this.claimUserId = json["claim_user_id"];
    this.claimUserName = json["claim_user_name"];
    this.status = json["status"];
    this.approvalAt = DateExodus.fromBackend(json["approval_at"]);
    this.createdAt = DateExodus.fromBackend(json["created_at"]);
    this.unprocessedCreatedAt = json["created_at"];
    this.approvedBy = json["approved_by"];
    this.approvedByName = json["approved_by_name"];
    this.keterangan = json["keterangan"];
    this.claimSales = temp;
  }
}

class KlaimSalesDetailListModelData {
  claimSalesId!: number;
  salesHeaderId!: number;
  nomorFaktur!: string;
  distributorName!: string;
  claimSalesDetail!: KlaimSalesDetailListModelItem[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: KlaimSalesDetailListModelItem[] = [];

    if (json["claim_sales_detail"] !== null) {
      for (let i = 0; i < json.claim_sales_detail.length; i++) {
        temp.push(
          new KlaimSalesDetailListModelItem(json.claim_sales_detail[i])
        );
      }
    }

    this.claimSalesId = json["claim_sales_id"];
    this.salesHeaderId = json["sales_header_id"];
    this.nomorFaktur = json["nomor_faktur"];
    this.distributorName = json["distributor_name"];
    this.claimSalesDetail = temp;
  }
}

class KlaimSalesDetailListModelItem {
  claimSalesId!: number;
  productBridgingId!: number;
  productName!: string;
  productPrice!: number;
  claimQuantity!: number;
  realQuantity!: number;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.claimSalesId = json["claim_sales_id"];
    this.productBridgingId = json["product_bridging_id"];
    this.productName = json["product_name"];
    this.productPrice = json["product_price"];
    this.claimQuantity = json["claim_quantity"];
    this.realQuantity = json["real_quantity"];
  }
}

export {
  KlaimSalesDetailModelPack,
  KlaimSalesDetailModelData,
  KlaimSalesDetailListModelData,
  KlaimSalesDetailListModelItem,
};
