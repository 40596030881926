import { useEffect, useState } from "react";
import DateExodus from "../../../../models/DateExodus";
import SalesTargetQuarterModel from "../models/SalesTargetQuarterModel";
import dayjs from "dayjs";

const useGenerateSalesTargetQuarterValueList = (
  selectedYear?: DateExodus | null
) => {
  const [quarterList, setQuarterList] = useState<SalesTargetQuarterModel[]>([]);

  useEffect(() => {
    function generateData() {
      let year;

      if (selectedYear) {
        year = selectedYear.getDateObject().getFullYear();
      } else {
        year = new DateExodus().getDateObject().getFullYear();
      }

      const temp: SalesTargetQuarterModel[] = [
        {
          id: 1,
          value: {
            start_month: new DateExodus(dayjs(`${year}-01-01`).unix()),
            end_month: new DateExodus(dayjs(`${year}-03-01`).unix()),
          },
          label: "Januari - Maret",
        },
        {
          id: 2,
          value: {
            start_month: new DateExodus(dayjs(`${year}-04-01`).unix()),
            end_month: new DateExodus(dayjs(`${year}-06-01`).unix()),
          },
          label: "April - Juni",
        },
        {
          id: 3,
          value: {
            start_month: new DateExodus(dayjs(`${year}-07-01`).unix()),
            end_month: new DateExodus(dayjs(`${year}-09-01`).unix()),
          },
          label: "Juli - September",
        },
        {
          id: 4,
          value: {
            start_month: new DateExodus(dayjs(`${year}-10-01`).unix()),
            end_month: new DateExodus(dayjs(`${year}-12-01`).unix()),
          },
          label: "Oktober - Desember",
        },
      ];

      setQuarterList(temp);
    }

    generateData();
  }, [selectedYear]);

  return { quarterList };
};

export default useGenerateSalesTargetQuarterValueList;
