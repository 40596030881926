import { Button } from "@chakra-ui/react";
import { MouseEventHandler } from "react";

/** Intended to be used with forms,
 *  which passed the clear form function into this component */
const ClearAllButton = (props: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}): JSX.Element => {
  return (
    <Button
      colorScheme="buttonSecondary"
      variant={"link"}
      onClick={props.onClick}
    >
      Clear All
    </Button>
  );
};

export default ClearAllButton;
