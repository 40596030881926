import { useMediaQuery, useTheme } from "@chakra-ui/react";
import useTitle from "../../../../../../services/useTitle";
import { SalesOutletListContextProvider } from "../contexts/SalesOutletListContext";
import SalesOutletPageDesktop from "./desktop/SalesOutletPageDesktop";
import { SalesOutletSummaryContextProvider } from "../contexts/SalesOutletSummaryContext";
import { SalesOutletPrintContextProvider } from "../contexts/SalesOutletPrintContext";
import SalesOutletPageMobile from "./mobile/SalesOutletPageMobile";

const SalesOutletList = () => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  useTitle("Sales Outlet");

  return (
    <SalesOutletListContextProvider>
      <SalesOutletSummaryContextProvider>
        <SalesOutletPrintContextProvider>
          {isMobile ? <SalesOutletPageMobile /> : <SalesOutletPageDesktop />}
        </SalesOutletPrintContextProvider>
      </SalesOutletSummaryContextProvider>
    </SalesOutletListContextProvider>
  );
};

export default SalesOutletList;
