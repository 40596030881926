import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { EditableSalesDetail } from "../../models/EditableFakturModel";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import {
  formIdCheckboxCounter,
  formIdPrefixCheckbox,
  formIdPrefixTextField,
} from "../../constants/KlaimSalesPilihProdukConstants";
import { usePilihProdukDetail } from "../../contexts/PilihProdukDetailEnabledContextProvider";
import convertDecimalString from "../../../../../../../utils/decimalStringFormat";
import convertNumberNegativity from "../../../../../../../utils/numberNegativityConverter";
import numberOfDecimalPlace from "../../../../../../../utils/numberOfDecimalPlace";

/**
 * Called internally inside this file.
 *
 * @remarks
 * See caller.
 *
 * @returns JSX.Element
 *
 */
const _ThisTextInput = ({
  data,
  itemIdx,
}: {
  data: EditableSalesDetail;
  itemIdx: number;
}): JSX.Element => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const formContext = useFormContext();
  const isChecked: boolean = formContext.watch(formIdPrefixCheckbox + itemIdx);

  const {
    state: { isEditable },
  } = usePilihProdukDetail();

  const isPositive: boolean = data.originalData.qty > 0;

  const rules: RegisterOptions = {
    required: {
      message: "Kosong",
      value: true,
    },
    pattern: {
      value: /^([0-9]*[,])?[0-9]+$/i,
      message: "Angka",
    },
    validate: {
      zero: (value) => {
        if (value == 0) return "Nol";
      },
      decimal: (value) => {
        const val = +convertDecimalString.commaToPoint(value);
        if (val) {
          const decimalPlace = numberOfDecimalPlace(val);
          if (decimalPlace > 2) return "Max 2 belakang koma";
        }
      },
      min: (value) => {
        const val = +convertDecimalString.commaToPoint(value);
        if (val) {
          if (val < 0) {
            if (isPositive) {
              return "Min > 0";
            } else {
              return `Max < 0`;
            }
          }
        }
      },
      max: (value) => {
        const val = +convertDecimalString.commaToPoint(value);
        if (val) {
          if (isPositive) {
            if (val > data.originalData.qty) {
              return `Max ${data.originalData.qty}`;
            }
          } else {
            if (val > convertNumberNegativity(data.originalData.qty)) {
              return `Min > ${data.originalData.qty}`;
            }
          }
        }
      },
    },
  };

  const [activeRules, setActiveRules] = useState<RegisterOptions | undefined>(
    isChecked ? rules : undefined
  );

  useEffect(() => {
    if (isChecked) {
      setActiveRules(rules);
    } else {
      setActiveRules(undefined);
      formContext.clearErrors(formIdPrefixTextField + itemIdx);
      formContext.setValue(
        formIdPrefixTextField + itemIdx,
        isPositive
          ? convertDecimalString.pointToComma(data.selectedQty.toString())
          : convertDecimalString.pointToComma(
              convertNumberNegativity(data.selectedQty).toString()
            )
      );
    }
  }, [isChecked]);

  return (
    <Controller
      name={formIdPrefixTextField + itemIdx.toString()}
      defaultValue={
        isPositive
          ? convertDecimalString.pointToComma(data.selectedQty.toString())
          : convertDecimalString.pointToComma(
              convertNumberNegativity(data.selectedQty).toString()
            )
      }
      rules={activeRules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl width="100%" isInvalid={!!error}>
          <InputGroup>
            <Input
              border="1px solid"
              borderColor={theme.colors.exodus.darkGrey}
              sx={{
                color: theme.colors.exodus.primaryBlue,
                fontSize: isDesktopView ? 16 : 12,
                pl: isPositive ? 4 : "25px",
                pr: isPositive ? 4 : 1,
                _disabled: {
                  backgroundColor: theme.colors.exodus.lightGrey,
                },
              }}
              onChange={(e) => {
                e.target.value = convertDecimalString.pointToComma(
                  e.target.value.trim()
                );
                onChange(e);
              }}
              value={value}
              width="100%"
              variant="outline"
              type="text"
              inputMode="numeric"
              disabled={!isChecked || !isEditable}
            />
            {!isPositive && (
              <InputLeftElement pointerEvents="none">
                <Text color={theme.colors.exodus.primaryBlue}>-</Text>
              </InputLeftElement>
            )}
          </InputGroup>

          <FormErrorMessage color={theme.colors.exodus.red}>
            {error && (error.message as string)}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

/**
 * Called internally inside this file.
 *
 * @remarks
 * See caller.
 *
 * @returns JSX.Element
 *
 */
const _ThisCheckbox = ({
  data,
  itemIdx,
}: {
  data: EditableSalesDetail;
  itemIdx: number;
}): JSX.Element => {
  const theme = useTheme();
  const formContext = useFormContext();

  const {
    state: { isEditable },
  } = usePilihProdukDetail();

  return (
    <Controller
      name={formIdPrefixCheckbox + itemIdx.toString()}
      defaultValue={data.isSelected}
      render={({ field: { onChange, value } }) => (
        <Checkbox
          sx={{
            color: theme.colors.exodus.primaryBlue,
            p: 1,
            m: 0,
          }}
          onChange={(e) => {
            onChange(e);
            if (
              formContext.getValues(formIdPrefixCheckbox + itemIdx.toString())
            ) {
              formContext.setValue(
                formIdCheckboxCounter,
                formContext.getValues(formIdCheckboxCounter) + 1
              );
            } else {
              formContext.setValue(
                formIdCheckboxCounter,
                formContext.getValues(formIdCheckboxCounter) - 1
              );
            }
          }}
          value={value}
          isChecked={value}
          isDisabled={!isEditable}
        />
      )}
    />
  );
};

/**
 * A component made for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * An item component of "faktur" detail's list. Have editable checkbox and text
 * input, which can be enabled or disabled based on [usePilihProdukDetail()]
 * hook.
 *
 * @param isEven - used for container coloring. If set to true, container will
 * have darker color.
 *
 * @param data - this [EditableSalesDetail] item to be viewed or edited.
 *
 * @param itemIdx - this item index from the list, used for identification in
 * form keys.
 *
 * @returns JSX.Element
 *
 */
const FakturDetailItem = ({
  isEven = false,
  data,
  itemIdx,
}: {
  isEven?: boolean;
  data: EditableSalesDetail;
  itemIdx: number;
}): JSX.Element => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const rupiahFormat = (value: number) =>
    new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);

  return (
    <Stack
      direction="row"
      bgColor={isEven ? theme.colors.exodus.tableDataBackground : undefined}
    >
      <Stack
        flex={2}
        px={isDesktopView ? 4 : 3}
        py={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <_ThisCheckbox data={data} itemIdx={itemIdx} />
        <Stack textAlign="left" gap={0}>
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontSize={isDesktopView ? 16 : 12}
            fontWeight="semibold"
          >
            {data.originalData.productName}
          </Text>
          <Text fontSize={isDesktopView ? 14 : 12} fontWeight={500}>
            {rupiahFormat(data.originalData.salePrices)}
          </Text>
        </Stack>
      </Stack>
      <Stack
        flex={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
      >
        <Box width="50%">
          <_ThisTextInput data={data} itemIdx={itemIdx} />
        </Box>
        <Box width="50%">
          <Text fontSize={isDesktopView ? 16 : 12} fontWeight={500}>
            / {data.originalData.qty}
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
};

export default FakturDetailItem;
