import React, { useContext } from "react";
import HeaderRecordApprovalRealisasiContext from "../../context/HeaderRecordApprovalRealisasiContext";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";

const MobileDetailApprovalRealisasiBasicInformation: React.FC = () => {
  const { headerData: record } = useContext(
    HeaderRecordApprovalRealisasiContext
  );

  if (!record) return null;
  return (
    <>
      <StackedColumn
        isTopBoldApplied
        isTopColorPrimaryBlue
        topValue={"Field Force"}
        bottomValue={`${record.userName} ${record.nip}`}
      />
      <StackedColumn
        isTopBoldApplied
        isTopColorPrimaryBlue
        topValue={"Jabatan"}
        bottomValue={record.roleLabel}
      />
      <StackedColumn
        isTopBoldApplied
        isTopColorPrimaryBlue
        topValue={"Periode Rencana Kunjungan"}
        bottomValue={`${record.startDate.getStringDateFormat(
          "D MMMM YYYY"
        )} - ${record.endDate.getStringDateFormat("D MMMM YYYY")}`}
      />

      {record.komentarAtasan !== null && (
          <StackedColumn
            isTopBoldApplied
            isTopColorPrimaryBlue
            topValue={"Komentar Atasan"}
            bottomValue={record.komentarAtasan!}
          />
        )}
    </>
  );
};

export default MobileDetailApprovalRealisasiBasicInformation;
