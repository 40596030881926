import { Accordion, Center, Spinner, VStack, useTheme } from "@chakra-ui/react";
import { useBiayaMurniListContext } from "../../../../../contexts/BiayaMurniListContext";
import BiayaMurniDpAccordionItem from "./BiayaMurniDpAccordionItem";
import { BiayaMurniDpData } from "../../../../../models/apiResponses/BiayaMurniDpData";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import { useEffect, useState } from "react";
import { DpOrManagerContextProvider } from "../../../contexts/DpOrManagerContext";
import ErrorPage from "../../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../../components/DataNotFound/DataNotFound";

const BiayaMurniDpList = (): JSX.Element => {
  const theme = useTheme();

  const {
    actions: { setPage, setLimit },
    states: { data, error, isLoading },
  } = useBiayaMurniListContext();

  if (isLoading) {
    return (
      <Center p={10}>
        <Spinner color={theme.colors.exodus.primaryBlue} />
      </Center>
    );
  }

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (data?.records) {
    return (
      <DpOrManagerContextProvider
        dpIds={data.records.map((el) => (el as BiayaMurniDpData).dpId)}
      >
        <VStack alignItems={"stretch"}>
          <BiayaMurniDpAccordion
            records={data.records as BiayaMurniDpData[]}
            hasProject={data.hasProject}
          />
          <Center>
            <Pagination
              handleOnNavigateFirstPage={() => {
                setPage("first");
              }}
              handleOnBack={() => {
                setPage("prev");
              }}
              handleOnNext={() => {
                setPage("next");
              }}
              handleOnLimitChange={(limit) => {
                setLimit(limit);
              }}
              totalData={data.metadata.totalData}
              totalPage={data.metadata.totalPage}
              limit={data.metadata.totalDataPerPage}
              page={data.metadata.currentPage}
            />
          </Center>
        </VStack>
      </DpOrManagerContextProvider>
    );
  }

  return <DataNotFound />;
};

const BiayaMurniDpAccordion = ({
  records,
  hasProject,
}: {
  records: BiayaMurniDpData[];
  hasProject: boolean;
}): JSX.Element => {
  const [accordionIndex, setAccordionIndex] = useState(-1);

  useEffect(() => {
    setAccordionIndex(-1);
  }, [records]);

  return (
    <Accordion allowToggle index={accordionIndex}>
      {records.map((item, i) => (
        <BiayaMurniDpAccordionItem
          key={i}
          index={i}
          hasProject={hasProject}
          data={item as BiayaMurniDpData}
          handleChangeAccordion={() => {
            if (i === accordionIndex) {
              setAccordionIndex(-1);
            } else {
              setAccordionIndex(i);
            }
          }}
        />
      ))}
    </Accordion>
  );
};

export default BiayaMurniDpList;
