import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useGetRealisasiByHeaderUserIdAndActiveTab from "../../services/useGetRealisasiByHeaderUserIdAndActiveTab";
import { DetailApprovalRealisasiKunjunganModel } from "../../models/ApprovalRealisasiKunjunganModel";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";
import { Box, Spinner, Text, useTheme } from "@chakra-ui/react";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import ExpandedDetailApprovalRealisasiCard from "./ExpandedDetailApprovalRealisasiCard";
import useGetApprovalRealisasiUserId from "../../services/useGetApprovalRealisasiUserId";
import { isEmpty } from "lodash";
import { TableColumn } from "react-data-table-component";
type Props = {
  activeTab: "realization" | "extracall";
};

const DesktopDetailApprovalRealisasiDetailList: React.FC<Props> = ({
  activeTab,
}) => {
  const theme = useTheme();
  const { id } = useParams();
  const location = useLocation();
  const { data: persistentUserId } = useGetApprovalRealisasiUserId();

  const [userId, setUserId] = useState<number | null>(null);

  useEffect(() => {
    if (persistentUserId !== null) {
      setUserId(persistentUserId);
    }
  }, [persistentUserId]);

  const { realisasiDetailData, errorMessage, loading } =
    useGetRealisasiByHeaderUserIdAndActiveTab({
      header_id: Number(id),
      user_id: !isEmpty(location.state) ? location.state.userId : userId,
      activeTab: activeTab,
    });

  const approvalRealisasiDetailList: TableColumn<DetailApprovalRealisasiKunjunganModel>[] =
    [
      {
        name: "Nama Outlet",
        cell: (row: DetailApprovalRealisasiKunjunganModel) => (
          <StackedColumn
            isTopBoldApplied
            isTopColorPrimaryBlue
            topValue={row.outletName}
            bottomValue={row.outletCode}
          />
        ),
        minWidth: "215px",
        maxWidth: "250px",
      },
      {
        name: "Nama Customer",
        cell: (row: DetailApprovalRealisasiKunjunganModel) => (
          <StackedColumn
            isTopBoldApplied
            isTopColorPrimaryBlue
            topValue={row.customerName}
            bottomValue={row.customerCode}
          />
        ),
        minWidth: "215px",
        maxWidth: "250px",
      },
      {
        name: "Spesialis",
        cell: (row: DetailApprovalRealisasiKunjunganModel) => (
          <Text>{row.spesialis}</Text>
        ),
        minWidth: "215px",
        maxWidth: "250px",
      },
      {
        name: "Jabatan",
        cell: (row: DetailApprovalRealisasiKunjunganModel) => (
          <Text>{row.jabatan}</Text>
        ),
        minWidth: "215px",
        maxWidth: "250px",
      },
      {
        name: "Kegiatan",
        cell: (row: DetailApprovalRealisasiKunjunganModel) => (
          <Text>{row.tujuan}</Text>
        ),
        minWidth: "400px",
        maxWidth: "450px",
      },
    ];

  if (loading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (errorMessage && realisasiDetailData.length < 0) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={errorMessage.toString()} />
      </Box>
    );
  }

  if (realisasiDetailData.length > 0 && realisasiDetailData[0].project) {
    approvalRealisasiDetailList.unshift({
      name: "Project",
      cell: (row: DetailApprovalRealisasiKunjunganModel) => (
        <Text textAlign="left">{row.project!.label}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "150px",
      maxWidth: "175px",
    });
  }

  return realisasiDetailData.length > 0 ? (
    <Box marginTop="24px" marginBottom="15px">
      <TableDataComponent
        data={realisasiDetailData}
        tableHeader={approvalRealisasiDetailList}
        onSelectChange={() => {}}
        paginationOn={false}
        paginationLimit={[]}
        checboxOn={false}
        isExpandable
        expandComponent={ExpandedDetailApprovalRealisasiCard}
      />
    </Box>
  ) : (
    <Box
      marginTop="24px"
      marginBottom="15px"
      display={"flex"}
      justifyContent={"start"}
    >
      <DataNotFound />
    </Box>
  );
};

export default DesktopDetailApprovalRealisasiDetailList;
