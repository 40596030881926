import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { DraftRencanaKunjunganCreateBody } from "../models/CreateDraftRencanaKunjunganModel";
import JabatanCustomerList, {
  JabatanCustomer,
} from "../../../constants/JabatanCustomer";
import useGetOutletByUserId from "../../../../../services/Outlet/useGetOutletByUserId";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";
import useGetCustomerByOutletId from "../../../../../services/Customer/useGetCustomerByOutletId";
import { useEffect, useRef, useState } from "react";
import { OutletModel } from "../../../../../models/Outlet/OutletModel";
import CustomerDataModel from "../../../../../models/Customer/CustomerDataModel";
import useGetTujuanKunjunganList from "../../../services/useGetTujuanKunjunganList";
import TujuanKunjunganDataModel from "../../../models/TujuanKunjunganDataModel";
import RencanaKunjunganOutletFormField from "./form/RencanaKunjunganOutletFormField";
import RencanaKunjunganCustomerFormField from "./form/RencanaKunjunganCustomerFormField";
import RencanaKunjunganJabatanFormField from "./form/RencanaKunjunganJabatanFormField";
import RencanaKunjunganTotalVisitFormField from "./form/RencanaKunjunganTotalVisitFormField";
import RencanaKunjunganTujuanFormField from "./form/RencanaKunjunganTujuanFormField";
import RencanaKunjunganTujuanLainFormField from "./form/RencanaKunjunganTujuanLainFormField";
import { debounce } from "lodash";

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: DraftRencanaKunjunganCreateBody) => void;
  isLoadingSubmit: boolean;
};

const DialogInputRencanaKunjungan: React.FC<Props> = (props) => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const form = useForm<DraftRencanaKunjunganCreateBody>();

  const [selectedOutlet, setSelectedOutlet] = useState<OutletModel | null>(
    null
  );
  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerDataModel | null>(null);
  const [selectedTujuan, setSelectedTujuan] =
    useState<TujuanKunjunganDataModel | null>(null);
  const [selectedJabatan, setSelectedJabatan] =
    useState<JabatanCustomer | null>(null);

  const [searched, setSearched] = useState<string | undefined>();
  const debouncedOnSearch = useRef(
    debounce(async (value: string | undefined) => {
      if (value && (value.length === 0 || value.length >= 3)) {
        setSearched(value);
      }
    }, 300)
  ).current;

  const { outletData } = useGetOutletByUserId({
    params: { userId: loggedUser?.userId, keyword: searched },
    startFetching: props.open,
  });

  const { customerData } = useGetCustomerByOutletId(props.open, {
    outlet_id: selectedOutlet ? selectedOutlet.outletId : null,
  });
  const { tujuanKunjunganData } = useGetTujuanKunjunganList(props.open);

  useEffect(() => {
    if (customerData.length === 0) {
      setSelectedCustomer(null);
    }
  }, [customerData.length]);

  const handleSubmitCreate = (value: DraftRencanaKunjunganCreateBody) => {
    props.onSubmit(value);
    form.reset();
    setSelectedOutlet(null);
    setSelectedCustomer(null);
    setSelectedTujuan(null);
    setSelectedJabatan(null);
  };

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      isOpen={props.open}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          Input Rencana Kunjungan
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmitCreate)}>
              {/* Nama Outlet dan Nama Customer */}
              <Flex
                direction={!isDesktopView ? "column" : "row"}
                gap={!isDesktopView ? 0 : 5}
                marginBottom={!isDesktopView ? 0 : "16px"}
              >
                <RencanaKunjunganOutletFormField
                  outletData={outletData}
                  customerData={customerData}
                  selectedOutlet={selectedOutlet}
                  onChange={(data) => {
                    setSelectedOutlet(data);
                    if (!data) {
                      setSelectedCustomer(null);
                    }
                  }}
                  onInputChange={(value) => {
                    debouncedOnSearch(value);
                  }}
                />
                <RencanaKunjunganCustomerFormField
                  customerData={customerData}
                  selectedCustomer={selectedCustomer}
                  onChange={(data) => setSelectedCustomer(data)}
                  isDisabled={!selectedOutlet}
                />
              </Flex>

              {/* Jabatan dan Jumlah Kunjungan */}
              <Flex
                direction={!isDesktopView ? "column" : "row"}
                gap={!isDesktopView ? 0 : 5}
                marginBottom={!isDesktopView ? 0 : "16px"}
              >
                <RencanaKunjunganJabatanFormField
                  jabatanData={JabatanCustomerList}
                  selectedJabatan={selectedJabatan}
                  onChange={(data) => setSelectedJabatan(data)}
                />
                <RencanaKunjunganTotalVisitFormField />
              </Flex>

              {/* Tujuan dan Tujuan Kunjungan Lain */}
              <Flex
                direction={!isDesktopView ? "column" : "row"}
                gap={!isDesktopView ? 0 : 5}
              >
                <RencanaKunjunganTujuanFormField
                  tujuanKunjunganData={tujuanKunjunganData}
                  selectedTujuan={selectedTujuan}
                  onChange={(data) => setSelectedTujuan(data)}
                />
                <RencanaKunjunganTujuanLainFormField
                  selectedTujuan={selectedTujuan}
                />
              </Flex>

              {/* Button Submit dan Cancel */}
              <Flex
                direction={!isDesktopView ? "column" : "row-reverse"}
                gap={!isDesktopView ? 0 : 5}
                marginTop="48px"
                marginBottom="20px"
              >
                <Button
                  type="submit"
                  isLoading={props.isLoadingSubmit}
                  width="100%"
                  colorScheme="buttonPrimary"
                >
                  Submit
                </Button>
                <Button
                  isDisabled={props.isLoadingSubmit}
                  onClick={() => {
                    form.reset();
                    setSelectedOutlet(null);
                    setSelectedCustomer(null);
                    setSelectedTujuan(null);
                    setSelectedJabatan(null);
                    props.onClose();
                  }}
                  width="100%"
                  variant="outline"
                  colorScheme="buttonSecondary"
                  marginTop={!isDesktopView ? "12px" : 0}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DialogInputRencanaKunjungan;
