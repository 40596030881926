import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useTheme,
  Text,
  Button,
  Stack,
  useMediaQuery,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { useForm, FormProvider, FieldValues } from "react-hook-form";
import { isEqual } from "lodash";
import React from "react";

import usePostChangePassword from "../../../services/usePostChangePassword";
import IsTypeOfErrorResponse from "../../../../../utils/IsTypeOfErrorResponse";
import { FAILED_TO_CHANGE_PASSWORD } from "../../../constants/constants";

import OldPasswordInputField from "./OldPasswordInputField";
import NewPasswordInputField from "./NewPasswordInputField";
import ConfirmationPasswordInputField from "./ConfirmationPasswordInputField";

type ChangePasswordModalProps = {
  isModalShown: boolean;
  handleOnChangeIsModalShown: (isOpen: boolean) => void;
};

const ChangePasswordModal = ({
  isModalShown,
  handleOnChangeIsModalShown,
}: ChangePasswordModalProps) => {
  const methods = useForm();
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  React.useEffect(() => {
    if (isModalShown === true) {
      methods.setValue("newPassword", null);
      methods.setValue("oldPassword", null);
      methods.setValue("confirmationPassword", null);
    }
  }, [isModalShown]);

  const handleOnClose = () => {
    handleOnChangeIsModalShown(false);
  };

  const handleOnSubmitValidation = (e: FieldValues) => {
    let isValid = true;
    if (!isEqual(e.confirmationPassword, e.newPassword)) {
      toast({
        title: "Gagal Validasi",
        description: `Password Baru dan Konfirmasi Password Baru Harus Sama !`,
        status: "error",
      });
      isValid = false;
    }
    return isValid;
  };

  const handleOnReject = (error: unknown) => {
    if (IsTypeOfErrorResponse(error)) {
      if (error.status === 404) {
        toast({
          title: FAILED_TO_CHANGE_PASSWORD,
          description: "Mohon periksa kembali password lama anda",
          status: "error",
        });
        return;
      }
      toast({
        title: FAILED_TO_CHANGE_PASSWORD,
        description: `${error.status} ${error.code} ${error.message}`,
        status: "error",
      });
    } else {
      toast({
        title: FAILED_TO_CHANGE_PASSWORD,
        description: `${error}`,
        status: "error",
      });
    }
  };

  const handleOnSubmit = async (e: FieldValues) => {
    const isValid = handleOnSubmitValidation(e);
    if (!isValid) return;

    try {
      const payload = {
        oldPassword: e.oldPassword,
        newPassword: e.newPassword,
      };
      await usePostChangePassword(payload);

      toast({
        title: "Sukses Mengubah Password !",
        status: "success",
      });
    } catch (error: unknown) {
      handleOnReject(error);
    }
  };

  return (
    <Modal
      isOpen={isModalShown}
      onClose={handleOnClose}
      isCentered
      size={isDesktopView ? "lg" : "xs"}
    >
      <ModalOverlay />
      <ModalContent
        sx={{
          padding: 2,
          paddingY: 4,
          backgroundColor: `${theme.colors.exodus.background}`,
          borderRadius: "16px",
        }}
      >
        <ModalHeader
          sx={{
            backgroundColor: `${theme.colors.exodus.background}`,
          }}
        >
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            fontSize={24}
            textAlign={"left"}
          >
            Ganti Password
          </Text>
        </ModalHeader>
        <ModalBody
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
              <OldPasswordInputField />
              <Divider
                borderColor={theme.colors.exodus.darkGrey}
                orientation="horizontal"
                borderWidth={"1px"}
                marginBottom={4}
              />
              <NewPasswordInputField />
              <ConfirmationPasswordInputField />
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: `${isDesktopView ? "row" : "column-reverse"}`,
                  justifyContent: "space-between",
                  width: "100%",
                }}
                gap={3}
              >
                <Button
                  colorScheme="buttonPrimary"
                  variant="outline"
                  sx={{ width: "100%" }}
                  size={isDesktopView ? "lg" : "md"}
                  onClick={handleOnClose}
                >
                  <Text
                    fontWeight={600}
                    fontSize={16}
                    color={theme.colors.exodus.secondaryBlue}
                  >
                    Cancel
                  </Text>
                </Button>
                <Button
                  colorScheme="buttonPrimary"
                  variant="solid"
                  type="submit"
                  sx={{ width: "100%" }}
                  size={isDesktopView ? "lg" : "md"}
                  isLoading={false}
                >
                  <Text fontWeight={600} fontSize={16}>
                    Submit
                  </Text>
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
