/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import ClearAllButton from "../../../../../components/CustomButtons/ClearAllButton";
import { FormProvider, useForm } from "react-hook-form";
import SalesTargetRoleBawahanFormField from "./forms/SalesTargetRoleBawahanFormField";
import { useContext, useEffect, useState } from "react";
import MonitorSalesTargetContext from "../context/MonitorSalesTargetContext";
import SalesTargetFilterAndPaginationModel from "../../models/SalesTargetFilterAndPaginationModel";
import DateExodus from "../../../../../models/DateExodus";
import dayjs from "dayjs";
import CustomDatePicker from "../../../../../components/DatePicker/CustomDatePicker";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import SalesTargetQuarterSelect from "../../components/SalesTargetQuarterSelect";
import SalesTargetQuarterModel from "../../models/SalesTargetQuarterModel";
import SalesTargetSelectProjectFormField from "./forms/SalesTargetSelectProjectFormField";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DialogFilterMonitorSalesTarget: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const [filterReset, setFilterReset] = useState<boolean>(false);

  const {
    filterValues,
    setFilterMonitorSalesTarget,
    handleClearFilter,
    setSelectedSalesTarget,
  } = useContext(MonitorSalesTargetContext);

  const form = useForm();

  const [selectedYear, setSelectedYear] = useState<Date | number>(new Date());
  const [selectedKuartal, setSelectedKuartal] =
    useState<SalesTargetQuarterModel | null>(null);

  const handleSubmitFilter = (data: any) => {
    console.log("Test", data);
    const submittedData: SalesTargetFilterAndPaginationModel = {
      page: 1,
      limit: filterValues ? filterValues.limit : 10,
      jabatan: data.jabatan,
      bawahan: data.bawahan,
      idKuartal: selectedKuartal!.id,
      labelKuartal: selectedKuartal!.label,
      periodeKuartalStart:
        selectedKuartal!.value.start_month.getEpochStartOfMonth(),
      periodeKuartalEnd:
        selectedKuartal!.value.end_month.getEpochStartOfMonth(),
      periodeTahun: new DateExodus(
        dayjs(data.selected_year).unix()
      ).getEpochDate(),
      project: data.project ? data.project : null,
    };
    setSelectedSalesTarget([]);
    setFilterMonitorSalesTarget(submittedData);
    setFilterReset(false);
    props.onClose();
  };

  const onClearFilter = () => {
    handleClearFilter();
    setFilterReset(true);
    form.reset();
    props.onClose();
  };

  useEffect(() => {
    if (filterValues && filterValues.periodeTahun) {
      setSelectedYear(filterValues.periodeTahun);
    }
  }, [filterValues]);

  useEffect(() => {
    if (filterValues && filterValues.idKuartal && !selectedKuartal) {
      setSelectedKuartal({
        id: filterValues.idKuartal,
        value: {
          start_month: new DateExodus(filterValues.periodeKuartalStart!),
          end_month: new DateExodus(filterValues.periodeKuartalEnd!),
        },
        label: filterValues.labelKuartal!,
      });
    }
  }, [filterValues, selectedKuartal]);

  useEffect(() => {
    if (filterReset) {
      setSelectedKuartal({
        id: filterValues!.idKuartal!,
        value: {
          start_month: new DateExodus(filterValues!.periodeKuartalStart!),
          end_month: new DateExodus(filterValues!.periodeKuartalEnd!),
        },
        label: filterValues!.labelKuartal!,
      });
    }
  }, [filterReset]);

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      isOpen={props.isOpen}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontSize={24}
            fontWeight="semibold"
          >
            Filter Data
          </Text>
          <ClearAllButton onClick={onClearFilter} />
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form
              id="filter-monitor-sales-target"
              onSubmit={form.handleSubmit(handleSubmitFilter)}
            >
              <SalesTargetSelectProjectFormField />
              <SalesTargetRoleBawahanFormField />
              <Flex
                direction={isDesktopView ? "row" : "column"}
                paddingTop={isDesktopView ? "12px" : 0}
                gap={isDesktopView ? 4 : 0}
              >
                <Box width={isDesktopView ? "70%" : "100%"}>
                  <FormInputLabel title="Periode Kuartal" />
                  <SalesTargetQuarterSelect
                    value={selectedKuartal}
                    year={
                      typeof selectedYear === "number"
                        ? new DateExodus(selectedYear)
                        : new DateExodus(dayjs(selectedYear).unix())
                    }
                    onChange={(data) => {
                      setSelectedKuartal(data);
                    }}
                  />
                </Box>
                <Box
                  width={isDesktopView ? "30%" : "100%"}
                  marginY={!isDesktopView ? "1%" : 0}
                >
                  <FormInputLabel title="Tahun" />
                  <CustomDatePicker
                    name="selected_year"
                    type="year"
                    onChange={(data) => {
                      if (data) {
                        setSelectedYear(data);
                      }
                    }}
                    defaultValue={
                      typeof selectedYear === "number"
                        ? new DateExodus(selectedYear).getDateObject()
                        : (selectedYear as Date)
                    }
                    selected={
                      typeof selectedYear === "number"
                        ? new DateExodus(selectedYear).getDateObject()
                        : (selectedYear as Date)
                    }
                  />
                </Box>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Flex
            direction={!isDesktopView ? "column" : "row-reverse"}
            width={"100%"}
            gap={!isDesktopView ? 0 : 5}
            paddingBottom="10px"
          >
            <Button
              type="submit"
              width="100%"
              colorScheme="buttonPrimary"
              form="filter-monitor-sales-target"
            >
              Submit
            </Button>
            <Button
              onClick={props.onClose}
              width="100%"
              variant="outline"
              colorScheme="buttonSecondary"
              marginTop={!isDesktopView ? "12px" : 0}
            >
              Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DialogFilterMonitorSalesTarget;
