import { useEffect, useState } from "react";
import InputSalesTargetProvider from "../dataProviders/InputSalesTargetProvider";
import SalesTargetFilterAndPaginationModel from "../../models/SalesTargetFilterAndPaginationModel";
import useGenerateSalesTargetQuarterValueList from "../../services/useGenerateSalesTargetQuarterValueList";
import DateExodus from "../../../../../models/DateExodus";

const useSetFilterValueInputSalesTarget = () => {
  const [filterValues, setFilterValues] =
    useState<SalesTargetFilterAndPaginationModel | null>(null);
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const [errorFilter, setErrorFilter] = useState<string | null>(null);
  const { quarterList } = useGenerateSalesTargetQuarterValueList();

  const initialValue: SalesTargetFilterAndPaginationModel = {
    page: 1,
    limit: 10,
    project: null,
  };

  useEffect(() => {
    getFilterInputSalesTarget();
  }, [quarterList.length]);

  const getFilterInputSalesTarget = async () => {
    try {
      setErrorFilter(null);
      setLoadingFilter(true);

      const data: SalesTargetFilterAndPaginationModel | null =
        await InputSalesTargetProvider.getSavedFilterValueInputSalesTarget();

      if (!data) {
        if (quarterList.length !== 0) {
          setFilterValues({
            ...initialValue,
            idKuartal: quarterList[0].id,
            labelKuartal: quarterList[0].label,
            periodeTahun: new DateExodus().getEpochDate(),
            periodeKuartalStart:
              quarterList[0].value.start_month.getEpochStartOfMonth(),
            periodeKuartalEnd:
              quarterList[0].value.end_month.getEpochStartOfMonth(),
          });
        } else {
          setFilterValues(initialValue);
        }
      } else {
        setFilterValues(data);
      }

      setLoadingFilter(false);
    } catch (error) {
      setErrorFilter(error as string);
      setLoadingFilter(false);
    }
  };

  const saveDataToIndexedDB = async (
    data: SalesTargetFilterAndPaginationModel
  ) => {
    setLoadingFilter(true);
    try {
      await InputSalesTargetProvider.setSavedFilterValueInputSalesTarget(data);
      setFilterValues(data);
    } catch (error: unknown) {
      setErrorFilter(error as string);
    } finally {
      setLoadingFilter(false);
    }
  };

  return {
    errorFilter,
    loadingFilter,
    saveDataToIndexedDB,
    filterValues,
    getFilterInputSalesTarget,
  };
};

export default useSetFilterValueInputSalesTarget;
