export type JabatanCustomer = {
  jabatan_customer: string;
};

const JabatanCustomerList: Array<JabatanCustomer> = [
  {
    jabatan_customer: "Manajemen Rumah Sakit",
  },
  {
    jabatan_customer: "User",
  },
];

export default JabatanCustomerList;
