import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
  Text,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
  Stack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import ClearAllButton from "../../../../../components/CustomButtons/ClearAllButton";
import { FormProvider, useForm } from "react-hook-form";
import NsmFormFieldKlaimSales from "./forms/NsmFormFieldKlaimSales";
import OutletPanelFormFieldKlaimSales from "./forms/OutletPanelFormFieldKlaimSales";
import OutletTujuanFormFieldKlaimSales from "./forms/OutletTujuanFormFieldKlaimSales";
import StatusFormFieldKlaimSales from "./forms/StatusFormFieldKlaimSales";
import PeriodeFormFieldKlaim from "./forms/PeriodeFormFieldKlaim";
import ApprovalKlaimSalesContext from "../../contexts/ApprovalKlaimSalesContext";
import DateExodus from "../../../../../models/DateExodus";
import KlaimSalesFilterAndPagination from "../../../models/KlaimSalesFilterAndPaginationModel";
import KlaimSalesFilterFormType from "../../../models/KlaimSalesFilterForm";
import IdFormFieldKlaimSales from "./forms/IdFormFieldKlaimSales";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DialogFilterApprovalKlaimSales: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const form = useForm<KlaimSalesFilterFormType>();
  const { filterValues, setFilterApprovalKlaimSales, handleClearFilter } =
    useContext(ApprovalKlaimSalesContext);

  const handleSubmitFilter = (data: KlaimSalesFilterFormType) => {
    const tempStartPeriod = DateExodus.fromDateObject(data.startAtKlaimSales);
    const tempEndPeriod = DateExodus.fromDateObject(data.endAtKlaimSales).endOf(
      "month"
    );

    let submittedData: KlaimSalesFilterAndPagination | null = null;

    if (data.idKlaimSales) {
      submittedData = {
        page: 1,
        limit: filterValues ? filterValues.limit : 10,
        claimUser: undefined,
        outletPanelName: undefined,
        outletTargetName: undefined,
        status: undefined,
        startAt: tempStartPeriod.getEpochDate(),
        endAt: tempEndPeriod.getEpochDate(),
        idKlaimSales: data.idKlaimSales,
      };
    } else {
      submittedData = {
        page: 1,
        limit: filterValues ? filterValues.limit : 10,
        claimUser: data.claimUser ?? undefined,
        outletPanelName: data.outletPanel?.trim() ?? undefined,
        outletTargetName: data.outletTujuan?.trim() ?? undefined,
        status: data.klaimSalesStatus?.name ?? undefined,
        startAt: tempStartPeriod.getEpochDate(),
        endAt: tempEndPeriod.getEpochDate(),
        idKlaimSales: undefined,
      };
    }

    setFilterApprovalKlaimSales(submittedData);
    props.onClose();
  };

  const onClearFilter = () => {
    handleClearFilter();
    form.reset();
    props.onClose();
  };

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      isOpen={props.isOpen}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontSize={24}
            fontWeight="semibold"
          >
            Filter Data
          </Text>
          <ClearAllButton onClick={onClearFilter} />
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form
              id="filter-monitor-sales-target"
              onSubmit={form.handleSubmit(handleSubmitFilter)}
            >
              <Stack gap={isDesktopView ? 3 : 2}>
                <IdFormFieldKlaimSales />
                <NsmFormFieldKlaimSales />
                <OutletPanelFormFieldKlaimSales />
                <OutletTujuanFormFieldKlaimSales />
                <StatusFormFieldKlaimSales />
                <PeriodeFormFieldKlaim />
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Flex
            direction={!isDesktopView ? "column" : "row-reverse"}
            width={"100%"}
            gap={!isDesktopView ? 0 : 5}
            paddingBottom="10px"
          >
            <Button
              type="submit"
              width="100%"
              colorScheme="buttonPrimary"
              form="filter-monitor-sales-target"
            >
              Submit
            </Button>
            <Button
              onClick={props.onClose}
              width="100%"
              variant="outline"
              colorScheme="buttonSecondary"
              marginTop={!isDesktopView ? "12px" : 0}
            >
              Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DialogFilterApprovalKlaimSales;
