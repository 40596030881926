import IndexedDatabase from "../../../../../database/IndexDB";
import SalesTargetFilterAndPaginationModel from "../../models/SalesTargetFilterAndPaginationModel";

const InputSalesTargetProvider = {
  getSavedFilterValueInputSalesTarget: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

    try {
      const data: SalesTargetFilterAndPaginationModel[] =
        await IndexedDatabase.inputSalesTargetFilter.toArray();

      if (!data || data.length === 0) {
        return Promise.resolve(null);
      } else {
        return Promise.resolve(data[0]);
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  setSavedFilterValueInputSalesTarget: async (
    params: SalesTargetFilterAndPaginationModel
  ) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

    try {
      const oldData: SalesTargetFilterAndPaginationModel[] =
        await IndexedDatabase.inputSalesTargetFilter.toArray();

      if (!oldData || oldData.length === 0) {
        await IndexedDatabase.inputSalesTargetFilter.add(params);
      } else {
        await IndexedDatabase.inputSalesTargetFilter.update(
          oldData[0].id!,
          params
        );
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
};

export default InputSalesTargetProvider;
