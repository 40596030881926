/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import DistributorProvider from "../../dataProviders/common/Distributor/DistributorProvider";
import DistributorDataModel from "../../models/Distributor/DistributorDataModel";

const useGetListDistributor = () => {
  const [distributorData, setData] = useState<DistributorDataModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: DistributorDataModel[] | undefined =
          await DistributorProvider.getListDistributor();

        setData(responseData!);
        setLoading(false);
      } catch (error: any) {
        setData([]);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    getData();
  }, []);

  return { distributorData, loading, errorMessage };
};

export default useGetListDistributor;
