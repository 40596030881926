import { Stack, Button, Icon, useTheme, Text } from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import { BiayaMurniDplDpfData } from "../../../../../models/apiResponses/BiayaMurniDplDpfData";
import { useNavigate } from "react-router-dom";

const LihatDetailButton = ({ record }: { record: BiayaMurniDplDpfData }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleOnClickLihatDetail = () => {
    navigate(`/biaya-murni/dpl-dpf/${record.outletId}`);
  };

  return (
    <Stack
      justifyContent={"flex-end"}
      alignContent={"flex-end"}
      textAlign={"right"}
      flexDirection={"row"}
      mt={2}
    >
      <Button
        variant={"ghost"}
        colorScheme="secondaryBlue"
        onClick={handleOnClickLihatDetail}
      >
        <Stack gap={2} flexDirection={"row"}>
          <Icon
            as={FaEye}
            boxSize={4}
            color={theme.colors.exodus.primaryBlue}
          />
          <Text fontSize={14} color={theme.colors.exodus.primaryBlue}>
            Lihat Detail
          </Text>
        </Stack>
      </Button>
    </Stack>
  );
};

export default LihatDetailButton;
