import { Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { OutletCardListFilterType } from "../../models/OutletCardListFilterType";
import DateExodus from "../../../../../../models/DateExodus";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import CustomDatePicker from "../../../../../../components/DatePicker/CustomDatePicker";

const FilterByPeriod = () => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const formContext = useFormContext<OutletCardListFilterType>();

  const [tempStartDate, setTempStartDate] = useState<DateExodus>(
    formContext.getValues("startDate")
  );
  const [tempEndDate, setTempEndDate] = useState<DateExodus>(
    formContext.getValues("endDate")
  );

  return (
    <Stack gap={4} direction={isMobile ? "column" : "row"}>
      <Stack sx={{ width: "100%" }}>
        <FormInputLabel title="Periode Awal" />
        <CustomDatePicker
          placeholder="Periode Awal"
          type={"month_year"}
          defaultValue={tempStartDate.getDateObject()}
          onChange={(val) => {
            if (val) {
              setTempStartDate(DateExodus.fromDateObject(val));
              formContext.setValue("startDate", DateExodus.fromDateObject(val));
            }
          }}
          name={"startPeriodJs"}
          maxDate={tempEndDate.getDateObject()}
        />
      </Stack>
      <Stack sx={{ width: "100%" }}>
        <FormInputLabel title="Periode Akhir" />
        <CustomDatePicker
          placeholder="Periode Akhir"
          type={"month_year"}
          defaultValue={tempEndDate.getDateObject()}
          onChange={(val) => {
            if (val) {
              setTempEndDate(DateExodus.fromDateObject(val).endOf("month"));
              formContext.setValue(
                "endDate",
                DateExodus.fromDateObject(val).endOf("month")
              );
            }
          }}
          name={"endPeriodJs"}
          minDate={tempStartDate.getDateObject()}
        />
      </Stack>
    </Stack>
  );
};

export default FilterByPeriod;
