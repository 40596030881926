import { AxiosResponse, isAxiosError } from "axios";
import AxiosBaseClient from "../../../../dataProviders/config/AxiosBaseClient";
import { EXODUS_API_VERSION_1 } from "../../../../constants/common/RootConstants";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import { GetListSalesDistributorDetailModelPack } from "../model/GetListSalesDistributorDetailModelPack.ts";
import IndexedDatabase from "../../../../database/IndexDB.ts";
import { isEmpty } from "lodash";
import { GetListSalesDistributorModelPack } from "../model/GetSalesDistributorModelPack.ts";
import { ExcelSalesDistributorModel } from "../model/GetExcelSalesDistributorModel.ts";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

export const SalesDistributorDataProvider = {
  getList: async (param: any) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        EXODUS_API_VERSION_1 + "/sales/distributor",
        {
          params: param,
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new GetListSalesDistributorModelPack(
            response.data
          );
          return Promise.resolve(responseData.data);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  getListDetail: async (params: string | undefined) => {
    try {
      var url = EXODUS_API_VERSION_1 + "/sales/distributor/detail/";

      var response: AxiosResponse = await AxiosBaseClient.get(url + params);

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data["message"] === SUCCESS_MESSAGE) {
          const data = new GetListSalesDistributorDetailModelPack(
            response.data
          );
          return Promise.resolve(data);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }

      return Promise.reject(error.message);
    }
  },
  getListAllSalesDistributor: async (param: any) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        EXODUS_API_VERSION_1 + "/sales/distributor/excel",
        {
          params: param,
        }
      );
      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data["message"] === SUCCESS_MESSAGE) {
          const data = new ExcelSalesDistributorModel(response.data);
          return Promise.resolve(data);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(error.message);
    }
  },
  saveFilter: async (filterValues: any) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const databaseResponse =
        await IndexedDatabase.salesDistributorFilter.toArray();

      if (isEmpty(databaseResponse)) {
        await IndexedDatabase.salesDistributorFilter.add(filterValues);
        return Promise.resolve(AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_MESSAGE);
      } else if (databaseResponse && databaseResponse.length === 1) {
        await IndexedDatabase.salesDistributorFilter.update(
          databaseResponse[0].id,
          filterValues
        );
        return Promise.resolve(AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_MESSAGE);
      }

      await IndexedDatabase.salesDistributorFilter.clear();
      throw "Error";
    } catch (error: unknown) {
      return Promise.reject("error");
    }
  },
  getSavedFilter: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    const databaseResponse =
      await IndexedDatabase.salesDistributorFilter.toArray();
    if (
      databaseResponse &&
      !isEmpty(databaseResponse) &&
      databaseResponse.length === 1
    ) {
      return Promise.resolve(databaseResponse[0]);
    }
    return Promise.resolve(null);
  },
};

export default SalesDistributorDataProvider;
