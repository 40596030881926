import { Box, Button, useTheme } from "@chakra-ui/react";
import { useContext, useState } from "react";
import InputRencanaKunjunganContext from "../../context/InputRencanaKunjunganContext";
import DialogMinimumVisitNotAchieved from "../DialogMinimumVisitNotAchieved";
import DialogKonfimasiSubmitRencanaKunjungan from "../DialogKonfimasiSubmitRencanaKunjungan";
import DateExodus from "../../../../../../models/DateExodus";
import { CommonStatus } from "../../../../../../models/common/Status";

const MobileSubmitDraftButton: React.FC = () => {
  const theme = useTheme();

  const { selectedPeriode, minimalVisitData } = useContext(
    InputRencanaKunjunganContext
  );

  const [openDescriptionDialog, setOpenDescriptionDialog] =
    useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const handleClickSubmit = () => {
    if (selectedPeriode && minimalVisitData) {
      if (selectedPeriode.total_visit < minimalVisitData.minimalvisit) {
        setOpenDescriptionDialog(true);
      } else {
        setOpenConfirmationDialog(true);
      }
    }
  };

  const disableButtonSubmitCondition = (): boolean => {
    if (!selectedPeriode) return true;

    if (selectedPeriode.header_id === 0) return true;

    if (selectedPeriode.total_visit === 0) return true;

    const dateNow = new DateExodus();
    const isDateNowGreaterThanSelectedStartPeriod: boolean =
      parseInt(JSON.stringify(dateNow)) >
      parseInt(JSON.stringify(selectedPeriode.start_period));

    if (selectedPeriode.status_plan !== CommonStatus.Pending) {
      return true;
    } else if (isDateNowGreaterThanSelectedStartPeriod) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Box
        position="sticky"
        bottom={0}
        bgColor={theme.colors.exodus.background}
      >
        <Button
          colorScheme="buttonPrimary"
          width="100%"
          // marginTop="30px"
          // marginBottom="15px"
          marginY="12px"
          onClick={handleClickSubmit}
          isDisabled={disableButtonSubmitCondition()}
        >
          Submit
        </Button>
      </Box>

      <DialogMinimumVisitNotAchieved
        openDescriptionDialog={openDescriptionDialog}
        onClose={() => setOpenDescriptionDialog(false)}
      />

      <DialogKonfimasiSubmitRencanaKunjungan
        openConfirmationDialog={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      />
    </>
  );
};

export default MobileSubmitDraftButton;
