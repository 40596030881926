import { useContext } from "react";
import MonitorKunjunganMonitorListContext from "../../context/MonitorKunjunganMonitorListContext";
import { Box, Spinner, useTheme } from "@chakra-ui/react";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import GeneratePsspTidakAktifTableColumnData from "../../service/generatePsspTidakAktifTableColumnData";
import GeneratePsspAktifTableColumnData from "../../service/generatePsspAktifTableColumnData";

const DesktopTableListMonitorKunjungan: React.FC = () => {
  const theme = useTheme();

  const {
    currentPsspType,
    monitorKunjunganData,
    loadingMonitorKunjungan,
    errorMessageMonitorKunjungan,
  } = useContext(MonitorKunjunganMonitorListContext);

  if (loadingMonitorKunjungan) {
    return (
      <Box paddingTop="24px" paddingBottom="15px">
        <Spinner size="lg" color={theme.colors.exodus.primaryBlue} />
      </Box>
    );
  }

  if (errorMessageMonitorKunjungan) {
    return (
      <Box paddingTop="24px" paddingBottom="15px">
        <ErrorPage message={errorMessageMonitorKunjungan.toString()} />
      </Box>
    );
  }

  return monitorKunjunganData.length > 0 ? (
    <Box paddingTop="24px" paddingBottom="15px">
      <TableDataComponent
        data={monitorKunjunganData}
        tableHeader={
          currentPsspType === "pssp-tidak-aktif"
            ? GeneratePsspTidakAktifTableColumnData(monitorKunjunganData)
            : GeneratePsspAktifTableColumnData(monitorKunjunganData)
        }
        onSelectChange={() => {}}
        paginationOn={false}
        paginationLimit={[]}
        checboxOn={false}
      />
    </Box>
  ) : (
    <Box paddingTop="24px" paddingBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default DesktopTableListMonitorKunjungan;
