import ExcelJS from "exceljs";
import { AxiosError } from "axios";
import { useState } from "react";
import saveAs from "file-saver";
import { useAppSelector } from "../../../redux/hooks";
import Budget from "../models/BudgetModel";
import { isEmpty } from "lodash";
import {
  convertAchievement,
  convertCostRatio,
  formatMonthYearMonitoring,
} from "../utils/utils";
import { useToast } from "@chakra-ui/react";
import MonitoringFilterValues from "../models/MonitoringFilterValues";

const useExportExcel = (
  budgetList: Array<Budget>,
  filterValues: MonitoringFilterValues | null
) => {
  const toast = useToast();

  const loggedUser: any | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  const exportFirstSheet = async (ExcelWorkBook: ExcelJS.Workbook) => {
    const secondSheet = ExcelWorkBook.addWorksheet();
    secondSheet.name = "Budget";

    secondSheet.columns = [
      { key: "A", width: 15 },
      { key: "B", width: 13 },
      { key: "C", width: 13 },
      { key: "D", width: 13 },
      { key: "E", width: 13 },
      { key: "F", width: 13 },
      { key: "G", width: 13 },
      { key: "H", width: 13 },
      { key: "I", width: 13 },
      { key: "J", width: 13 },
      { key: "K", width: 13 },
      { key: "L", width: 13 },
      { key: "M", width: 13 },
      { key: "N", width: 13 },
      { key: "O", width: 13 },
      { key: "P", width: 13 },
      { key: "Q", width: 13 },
    ];
    const rowHeaderPosition = 1;
    const secondRowHeaderPosition = 2;
    const secondRowHeader = secondSheet.getRow(secondRowHeaderPosition);
    const firstRowHeader = secondSheet.getRow(rowHeaderPosition);
    for (let i = 1; i <= 17; i++) {
      firstRowHeader.getCell(i).font = {
        size: 11,
        bold: true,
      };
      secondRowHeader.getCell(i).font = {
        size: 11,
        bold: true,
      };
      firstRowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      secondRowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      for (let j = 1; j <= budgetList.length + 2; j++) {
        const firstRowCell = secondSheet.getCell(j, i);
        firstRowCell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    secondSheet.mergeCells("A1:A2");
    secondSheet.getCell("A1").value = "Periode";
    secondSheet.mergeCells("B1:E1");
    secondSheet.getCell("B1").value = "Sales";
    secondSheet.mergeCells("F1:I1");
    secondSheet.getCell("F1").value = "Kumulatif";
    secondSheet.mergeCells("J1:P1");
    secondSheet.getCell("J1").value = "Biaya";
    secondSheet.mergeCells("Q1:Q2");
    secondSheet.getCell("Q1").value = "Cost Ratio";

    secondRowHeader.getCell(2).value = "Target";
    secondRowHeader.getCell(3).value = "Estimasi";
    secondRowHeader.getCell(4).value = "Realisasi";
    secondRowHeader.getCell(5).value = "Achievement";
    secondRowHeader.getCell(6).value = "Target";
    secondRowHeader.getCell(7).value = "Estimasi";
    secondRowHeader.getCell(8).value = "Realisasi";
    secondRowHeader.getCell(9).value = "Achievement";
    secondRowHeader.getCell(10).value = "PSSP";
    secondRowHeader.getCell(11).value = "Entertainment";
    secondRowHeader.getCell(12).value = "DPL/DPF";
    secondRowHeader.getCell(13).value = "Standarisasi";
    secondRowHeader.getCell(14).value = "DP";
    secondRowHeader.getCell(15).value = "Total Biaya";
    secondRowHeader.getCell(16).value = "Kumulatif";

    for (let i = 0; i < budgetList.length; i++) {
      const row = secondSheet.getRow(secondRowHeaderPosition + 1 + i);
      row.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      };

      row.getCell(1).value = formatMonthYearMonitoring(
        budgetList[i].bulan,
        filterValues!.periodeTahun!.getStringDateFormat("YYYY")
      );
      row.getCell(2).value = budgetList[i].targetSales;
      row.getCell(3).value = budgetList[i].estimasiSales;
      row.getCell(4).value = budgetList[i].realisasiSales;
      row.getCell(5).value = convertAchievement(budgetList[i].achievementSales);
      row.getCell(6).value = budgetList[i].targetKumulatif;
      row.getCell(7).value = budgetList[i].estimasiKumulatif;
      row.getCell(8).value = budgetList[i].realisasiKumulatif;
      row.getCell(9).value = convertAchievement(
        budgetList[i].achievementKumulatif
      );
      row.getCell(10).value = budgetList[i].biayaPssp;
      row.getCell(11).value = budgetList[i].biayaEntertainment;
      row.getCell(12).value = budgetList[i].biayaDplDpf;
      row.getCell(13).value = budgetList[i].biayaStandarisasi;
      row.getCell(14).value = budgetList[i].biayaDP;
      row.getCell(15).value = budgetList[i].total;
      row.getCell(16).value = budgetList[i].kumulatif;
      row.getCell(17).value = convertCostRatio(
        budgetList[i].total,
        budgetList[i].realisasiSales
      );
    }
  };
  const exportSecondSheet = async (ExcelWorkBook: ExcelJS.Workbook) => {
    const secondSheet = ExcelWorkBook.addWorksheet();
    secondSheet.name = "Applied Filter";

    secondSheet.columns = [
      { key: "A", width: 15 },
      { key: "B", width: 15 },
      { key: "C", width: 15 },
      { key: "D", width: 15 },
      { key: "E", width: 15 },
    ];

    const rowHeaderPosition = 1;
    const rowHeader = secondSheet.getRow(rowHeaderPosition);
    for (let i = 1; i <= 5; i++) {
      rowHeader.getCell(i).font = {
        size: 12,
        bold: true,
      };
      rowHeader.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
    }

    rowHeader.getCell(1).value = "Project";
    rowHeader.getCell(2).value = "Periode Awal";
    rowHeader.getCell(3).value = "Periode Akhir";
    rowHeader.getCell(4).value = "Jabatan";
    rowHeader.getCell(5).value = "Nama Bawahan";

    for (let i = 1; i <= 5; i++) {
      for (let j = 1; j <= 2; j++) {
        const cell = secondSheet.getCell(j, i);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
    const row = secondSheet.getRow(rowHeaderPosition + 1);
    row.alignment = {
      wrapText: true,
      horizontal: "center",
      vertical: "middle",
    };

    row.getCell(1).value = filterValues!.projectAvailable?.label ?? "-";
    row.getCell(2).value = `${filterValues!.periodeAwal!.getStringDateFormat(
      "MM"
    )}-${filterValues!.periodeTahun!.getStringDateFormat("YYYY")}`;
    row.getCell(3).value = `${filterValues!.periodeAkhir!.getStringDateFormat(
      "MM"
    )}-${filterValues!.periodeTahun!.getStringDateFormat("YYYY")}`;
    row.getCell(4).value = filterValues!.jabatan?.label ?? "-";
    row.getCell(5).value = filterValues!.bawahan?.name ?? "-";
  };

  const exportExcel = async () => {
    try {
      const ExcelWorkBook = new ExcelJS.Workbook();
      await exportFirstSheet(ExcelWorkBook);
      await exportSecondSheet(ExcelWorkBook);
      const buf = await ExcelWorkBook.xlsx.writeBuffer();
      saveAs(
        new Blob([buf]),
        `export_monitoring_budgeting_${loggedUser?.userNip}_${loggedUser?.userName}.xlsx`
      );
    } catch (error: any) {
      toast({
        title: "Something Went Wrong When Exporting Excel !",
        description: `${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const exportData = async () => {
    try {
      setIsExportLoading(true);
      if (isEmpty(budgetList)) {
        toast({
          title: "Warning Info !",
          description: "Data tidak lengkap. mohon refresh ulang halaman.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      } else {
        await exportExcel();
      }
    } catch (exception: any) {
      if (exception instanceof AxiosError) {
        toast({
          title: "Error Found !",
          description: `${exception.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error Found !",
          description: `${exception.toString()}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setIsExportLoading(false);
    }
  };

  return { exportData, isExportLoading };
};

export default useExportExcel;
