import {
  Accordion,
  Box,
  Center,
  Divider,
  Spacer,
  Spinner,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import ErrorPage from "../../../../../../../components/ErrorPage/ErrorPage";
import PageTitle from "../../../../../../../components/PageTitle/PageTitle";
import DataNotFound from "../../../../../../../components/DataNotFound/DataNotFound";
import { useSalesOutletListContext } from "../../contexts/SalesOutletListContext";
import SalesOutletExportExcelButton from "../common/SalesOutletExportExcelButton";
import SalesOutletSummarySection from "../common/SalesOutletSummarySection";
import SalesOutletListSingularData from "../../models/SalesOutletListSingularData";
import { useEffect, useState } from "react";
import SalesOutletAccordionItem from "./parts/SalesOutletAccordionItem";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import SalesOutletFilter from "../common/filter/SalesOutletFilter";

const SalesOutletPageMobile = (): JSX.Element => {
  const theme = useTheme();

  const {
    states: { data, error, loading },
    actions: { setPage, setLimit },
  } = useSalesOutletListContext();

  if (loading) {
    return (
      <PageFrameWithFilter
        child={
          <Center p={10}>
            <VStack>
              <Spinner color={theme.colors.exodus.primaryBlue} />
              <Text>Loading daftar Sales Outlet</Text>
            </VStack>
          </Center>
        }
      />
    );
  }

  if (error) {
    return (
      <PageFrameWithFilter child={<ErrorPage message={error.message} />} />
    );
  }

  if (data?.records) {
    return (
      <PageFrameWithFilter
        child={
          <VStack alignItems={"stretch"}>
            <SalesOutletAccordion
              records={data.records}
              hasProject={data.hasProject}
            />
            <Center>
              <Pagination
                handleOnNavigateFirstPage={() => {
                  setPage("first");
                }}
                handleOnBack={() => {
                  setPage("prev");
                }}
                handleOnNext={() => {
                  setPage("next");
                }}
                handleOnLimitChange={(limit) => {
                  setLimit(limit);
                }}
                totalData={data.metadata.totalData}
                totalPage={data.metadata.totalPage}
                limit={data.metadata.totalDataPerPage}
                page={data.metadata.currentPage}
              />
            </Center>
          </VStack>
        }
      />
    );
  }

  return <PageFrameWithFilter child={<DataNotFound />} />;
};

const PageFrameWithFilter = ({
  child,
}: {
  child: JSX.Element;
}): JSX.Element => {
  const theme = useTheme();

  return (
    <VStack alignItems={"stretch"} flex={1}>
      <PageTitle label="Sales Outlet" />
      <SalesOutletFilter />
      <SalesOutletSummarySection />
      <Divider
        sx={{
          marginY: 4,
          border: "1px",
          opacity: 1,
          borderColor: theme.colors.exodus.primaryBlue,
        }}
      />
      {child}
      <Spacer />
      <Box
        sx={{
          position: "sticky",
          pt: 2,
          pb: 2,
          bottom: 0,
          bgColor: theme.colors.exodus.background,
        }}
      >
        <SalesOutletExportExcelButton />
      </Box>
    </VStack>
  );
};

const SalesOutletAccordion = ({
  records,
  hasProject,
}: {
  records: SalesOutletListSingularData[];
  hasProject: boolean;
}): JSX.Element => {
  const [accordionIndex, setAccordionIndex] = useState(-1);

  useEffect(() => {
    setAccordionIndex(-1);
  }, [records]);

  if (records.length === 0) {
    return <DataNotFound />;
  }

  return (
    <Accordion allowToggle index={accordionIndex}>
      {records.map((item, i) => (
        <SalesOutletAccordionItem
          key={i}
          data={item as SalesOutletListSingularData}
          handleChangeAccordion={() => {
            if (i === accordionIndex) {
              setAccordionIndex(-1);
            } else {
              setAccordionIndex(i);
            }
          }}
          hasProject={hasProject}
        />
      ))}
    </Accordion>
  );
};

export default SalesOutletPageMobile;
