import ProjectType from "../../../../../models/common/ProjectType";
import RoleModel from "../../../../../models/Users/RoleModel";
import UserModel from "../../../../../models/Users/UserModel";
import AllPeriodeDataModel from "../../../models/AllPeriodeDataModel";
import { RealisasiStatusType } from "./ApprovalRealisasiStatus";

export type ApprovalRealisasiFilterType = {
  role: RoleModel | null | undefined;
  bawahan: UserModel | null | undefined;
  periode: AllPeriodeDataModel | null;
  status: RealisasiStatusType | null | undefined;
  project: ProjectType | null | undefined;
};
class ApprovalRealisasiKunjunganFilterValues {
  role: RoleModel | null | undefined;
  bawahan: UserModel | null | undefined;
  periode: AllPeriodeDataModel | null ;
  status: RealisasiStatusType | null | undefined;
  project: ProjectType | null | undefined;

  constructor({ role, bawahan, periode, status, project }: ApprovalRealisasiFilterType) {
    this.role = role;
    this.bawahan = bawahan;
    this.periode = periode;
    this.status = status;
    this.project = project;
  }
}

export default ApprovalRealisasiKunjunganFilterValues;
