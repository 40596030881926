import { HStack, Stack, Text, VStack } from "@chakra-ui/react";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import BiayaMurniFilter from "../../../../components/filter/BiayaMurniFilter";
import BackButton from "../../../../components/desktop/BackButton";
import { useBiayaMurniFilterContext } from "../../../../contexts/BiayaMurniFilterContext";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";
import { useBiayaMurniListContext } from "../../../../contexts/BiayaMurniListContext";
import { BiayaMurniPsspData } from "../../../../models/apiResponses/BiayaMurniPsspData";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";
import Pagination from "../../../../../../components/Pagination/Pagination";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { projectEnumToUi } from "../../../../../../models/common/Project";

const BiayaMurniPsspDokterPageDesktop = (): JSX.Element => {
  const {
    states: { error: errorFilterContext },
  } = useBiayaMurniFilterContext();
  const {
    states: { data, error: errorGetList, isLoading: isLoadingGetList },
    actions: { setLimit, setPage },
  } = useBiayaMurniListContext();

  const [projectColumn, setProjectColumn] = useState<TableColumn<any>[]>([]);

  useEffect(() => {
    if (data?.hasProject) {
      setProjectColumn([
        {
          name: "Project",
          cell: (row: BiayaMurniPsspData) => (
            <Text textAlign={"start"}>{projectEnumToUi(row.project)}</Text>
          ),
          width: "100px",
        },
      ]);
    } else {
      setProjectColumn([]);
    }
  }, [data]);

  if (errorFilterContext || errorGetList) {
    return (
      <VStack alignItems={"stretch"}>
        <PageTitle label="PSSP Dokter" />
        <ErrorPage
          message={
            errorFilterContext
              ? errorFilterContext.message
              : errorGetList?.message!!
          }
        />
      </VStack>
    );
  }

  return (
    <VStack alignItems={"stretch"}>
      <PageTitle label="PSSP Dokter" />
      <Stack width={"100%"} direction={"row"}>
        <BiayaMurniFilter />
        <BackButton />
      </Stack>
      {data === null && !isLoadingGetList ? (
        <DataNotFound />
      ) : (
        <>
          <TableDataComponent
            data={data?.records}
            expandComponent={() => {}}
            onSelectChange={() => {}}
            paginationOn={false}
            tableHeader={[
              ...projectColumn,
              {
                cell: (record: BiayaMurniPsspData) => (
                  <StackedColumn
                    topValue={record.outletName}
                    bottomValue={record.outletCode}
                    isTopBoldApplied={true}
                  />
                ),
                name: "Nama Outlet",
                width: "25%",
              },
              {
                cell: (record: BiayaMurniPsspData) => (
                  <StackedColumn
                    topValue={record.customerName}
                    bottomValue={record.customerCode}
                    isTopBoldApplied={true}
                  />
                ),
                name: "Nama Customer",
                width: "20%",
              },
              {
                name: "Spesialis",
                selector: (record: BiayaMurniPsspData) => record.spesialis,
                width: "10%",
              },
              {
                name: "Jabatan",
                selector: (record: BiayaMurniPsspData) => record.posisi,
                width: "8%",
              },
              {
                name: "Biaya Total",
                selector: (record: BiayaMurniPsspData) =>
                  CurrencyIdrFormatter.convertToRp(record.psspValue),
              },
              {
                name: "Periode PSSP",
                cell: (record: BiayaMurniPsspData) => (
                  <Text textAlign={"left"}>{record.psspPeriod}</Text>
                ),
              },
              {
                name: "Tgl. Realisasi",
                cell: (record: BiayaMurniPsspData) => (
                  <Text textAlign={"left"}>
                    {record.tglRealisasiFormat
                      ? record.tglRealisasiFormat?.getStringDateFormat(
                          "DD MMMM YYYY"
                        )
                      : "Belum Proses"}
                  </Text>
                ),
              },
            ]}
            checboxOn={false}
            paginationLimit={[]}
            isLoading={isLoadingGetList}
          />
          <HStack justifyContent={"flex-end"}>
            <Pagination
              handleOnBack={() => setPage("prev")}
              handleOnLimitChange={setLimit}
              handleOnNavigateFirstPage={() => setPage("first")}
              handleOnNext={() => setPage("next")}
              limit={data?.metadata?.totalDataPerPage}
              page={data?.metadata?.currentPage}
              showCurrentPageDetail
              showLimit
              showNavigateFirstPage
              totalData={data?.metadata?.totalData}
              totalPage={data?.metadata?.totalPage}
            />
          </HStack>
        </>
      )}
    </VStack>
  );
};

export default BiayaMurniPsspDokterPageDesktop;
