import { Box, Card, useTheme, Text } from "@chakra-ui/react";
import LoggedUser from "../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../redux/hooks";
import VerticalTextInformation from "../common/VerticalTextInformation";
import ChangePasswordModal from "../common/modal/ChangePasswordModal";
import React from "react";
import ProfileActionButton from "../common/ProfileActionButton";

const DesktopProfileCard = () => {
  const theme = useTheme();
  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  const [isChangePasswordModalShown, setIsChangePasswordModalShown] =
    React.useState<boolean>(false);

  const handleOnChangeModalShown = (isOpen: boolean) => {
    setIsChangePasswordModalShown(isOpen);
  };

  const handleOnClickChangePassword = () => {
    handleOnChangeModalShown(true);
  };

  return (
    <Card
      sx={{
        borderRadius: "16px",
        width: "30%",
        minWidth: "424px",
        margin: "auto",
        padding: 6,
        boxShadow: "0px 7px 24px 4px #282c7a26",
        backgroundColor: theme.colors.exodus.background,
      }}
    >
      <Text
        sx={{
          color: `${theme.colors.exodus.primaryBlue}`,
          fontWeight: "600",
          fontSize: 24,
          textAlign: "left",
          mb: 5,
        }}
      >
        Profile
      </Text>
      {/* <PageTitle label="Profile" /> */}
      <Box marginBottom={4}>
        <VerticalTextInformation
          title={"Nama"}
          value={loggedUser?.userName!!}
        />
      </Box>
      <Box marginBottom={4}>
        <VerticalTextInformation title={"NIP"} value={loggedUser?.userNip!!} />
      </Box>
      <Box marginBottom={4}>
        <VerticalTextInformation
          title={"Jabatan"}
          value={loggedUser?.userRoleLabel!!}
        />
      </Box>
      <Box marginBottom={6}>
        <VerticalTextInformation
          title={"Email"}
          value={loggedUser?.userEmail!!}
        />
      </Box>
      <ProfileActionButton
        handleOnClickChangePassword={handleOnClickChangePassword}
      />
      <ChangePasswordModal
        isModalShown={isChangePasswordModalShown}
        handleOnChangeIsModalShown={handleOnChangeModalShown}
      />
    </Card>
  );
};

export default DesktopProfileCard;
