import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Grid,
  GridItem,
  Heading,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import StatusChip from "../../../../../components/StatusChip/StatusChip";
import { useNavigate } from "react-router-dom";
import { KlaimSalesListModelRecords } from "../../../models/KlaimSalesListModel";
import { getStatusColor } from "../../../utils/getStatusColor";

type Props = {
  record: KlaimSalesListModelRecords;
};

const ApprovalKlaimSalesCard: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const navigate = useNavigate();

  const titleFontSize = () => {
    return isDesktopView ? "md" : "sm";
  };

  const subtitleFontSize = () => {
    return isDesktopView ? "16px" : "12px";
  };

  const ApprovalKlaimSalesCardField: React.FC<{ title: string; value: string }> = (
    props
  ) => {
    return (
      <GridItem
        colSpan={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Text
          fontSize={subtitleFontSize()}
          color={theme.colors.exodus.primaryBlue}
        >
          {props.title}
        </Text>
        <Text
          fontWeight="semibold"
          fontSize={subtitleFontSize()}
          color={theme.colors.exodus.darkGrey}
        >
          {props.value}
        </Text>
      </GridItem>
    );
  };

  const onClickCard = () => {
    navigate(`/klaim-sales-approval/${props.record.id}`);
  };

  return (
    <>
      <Card
        textAlign={"left"}
        onClick={onClickCard}
        _hover={{ cursor: "pointer" }}
        boxShadow={`2px 2px 15px 2px #282c7a26`}
        backgroundColor={theme.colors.exodus.background}
      >
        <CardHeader paddingBottom={0}>
          <Heading
            size={titleFontSize()}
            color={theme.colors.exodus.primaryBlue}
            fontWeight={"semibold"}
          >
            {props.record.userName}
          </Heading>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            paddingTop={isDesktopView ? 2 : 0}
            paddingBottom={2}
          >
            <Text
              fontWeight="semibold"
              fontSize={subtitleFontSize()}
              color={theme.colors.exodus.darkGrey}
            >
              CLAIM-{props.record.id} | {props.record.createdAtUi}
            </Text>
            <StatusChip
              status={getStatusColor(props.record.status)}
              text={props.record.status.toUpperCase()}
            />
          </Box>
          <Divider
            borderColor={theme.colors.exodus.primaryBlue}
            borderWidth={"1.5px"}
          />
        </CardHeader>
        <CardBody paddingTop={2}>
          <Grid templateColumns="repeat(12, 1fr)" gap={3}>
            <ApprovalKlaimSalesCardField
              title="Outlet Panel"
              value={props.record.claimOutletName}
            />
            <ApprovalKlaimSalesCardField
              title="Kode Outlet Panel"
              value={props.record.claimOutletCode}
            />
          </Grid>
          <Grid templateColumns="repeat(12, 1fr)" gap={3}>
            <ApprovalKlaimSalesCardField
              title="Outlet Tujuan"
              value={props.record.targetOutletName}
            />
            <ApprovalKlaimSalesCardField
              title="Kode Outlet Tujuan"
              value={props.record.targetOutletCode}
            />
          </Grid>
        </CardBody>
      </Card>
    </>
  );
};

export default ApprovalKlaimSalesCard;
