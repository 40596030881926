import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Icon,
  Text,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import { MdRemoveRedEye } from "react-icons/md";
import { GetListSalesDistributorRecords } from "../model/GetSalesDistributorModelPack";
import DateExodus from "../../../../models/DateExodus";
import {
  getOutletSectorType,
  getProjectType,
} from "../utils/SalesDistributorUtils";
import { useNavigate } from "react-router-dom";
import { isEmpty, isNil } from "lodash";
import CurrencyIdrFormatter from "../../../../utils/CurrencyIdrFormatter";

type DataSalesDistributor = {
  data: GetListSalesDistributorRecords;
};

const MobileSalesDistributorList: React.FC<DataSalesDistributor> = ({
  data,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton
          display={"flex"}
          justifyContent={"space-between"}
          paddingX={0}
          paddingY={"8px"}
        >
          <Box>
            <Text
              textAlign={"start"}
              fontWeight={600}
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
            >
              {!isEmpty(data.distributorName) ? data.distributorName : "-"}
            </Text>
            <Text textAlign={"start"} fontSize={"14px"}>
              {!isEmpty(data.nomorInvoice) ? data.nomorInvoice : "-"}
            </Text>
            <Text textAlign={"start"} fontSize={"14px"}>
              {!isNil(data.outletIdDistributor)
                ? "ID Dist : " + data.outletIdDistributor
                : "ID Dist : -"}
            </Text>
            <Text textAlign={"start"} fontSize={"14px"}>
              {!isEmpty(data.project)
                ? "Project : " + getProjectType(data.project)
                : "Project : -"}
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
            >
              Tanggal Faktur
            </Text>
            <Text fontSize={"14px"}>
              {!isNil(data.salesPeriode)
                ? new DateExodus(data.salesPeriode).getStringDateFormat(
                    "D MMMM YYYY "
                  )
                : "-"}
            </Text>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
            bgColor={theme.colors.exodus.tableDataBackground}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
            >
              Outlet ID Distributor
            </Text>
            <Text fontSize={"14px"}>
              {!isNil(data.outletIdDistributor)
                ? data.outletIdDistributor
                : "-"}
            </Text>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
            >
              Nama Outlet
            </Text>
            <Text fontSize={"14px"}>
              {!isEmpty(data.outletName) ? data.outletName : "-"}
            </Text>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
            bgColor={theme.colors.exodus.tableDataBackground}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
              bgColor={theme.colors.exodus.tableDataBackground}
            >
              Kode Outlet
            </Text>
            <Text fontSize={"14px"}>
              {!isEmpty(data.outletCode) ? data.outletCode : "-"}
            </Text>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
            >
              Sektor Outlet
            </Text>
            <Text fontSize={"14px"}>
              {!isEmpty(data.outletSectorType)
                ? getOutletSectorType(data.outletSectorType)
                : "-"}
            </Text>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
            bgColor={theme.colors.exodus.tableDataBackground}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
            >
              Total Sales
            </Text>
            <Text fontSize={"14px"}>
              {!isNil(data.total)
                ? CurrencyIdrFormatter.convertToRp(data.total)
                : "-"}
            </Text>
          </Box>
          <Box display={"flex"} justifyContent={"end"} mt={"8px"}>
            <Button
              colorScheme="buttonSecondary"
              leftIcon={<Icon as={MdRemoveRedEye} />}
              variant={"ghost"}
              onClick={() =>
                navigate({
                  pathname: "/sales-distributor/" + data.salesHeaderId,
                })
              }
            >
              Detail
            </Button>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default MobileSalesDistributorList;
