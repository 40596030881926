import { Box, Flex, useMediaQuery, useTheme, useToast } from "@chakra-ui/react";
import DropdownAllPeriode from "../../../components/DropdownAllPeriode";
import { useContext } from "react";
import MonitorKunjunganMonitorListContext from "../context/MonitorKunjunganMonitorListContext";
import ExportExcelButton from "../../../../../components/CustomButtons/ExportExcelButton";
import useExportExcelMonitorKunjungan from "../../services/useExportExcelMonitorKunjungan";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import ProjectType from "../../../../../models/common/ProjectType";

const MonitorKunjunganPeriodeDanButtonExcel: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const {
    currentPsspType,
    savedFilter,
    monitorKunjunganData,
    allPeriodeData,
    selectedPeriode,
    handleChangeSelectedPeriode,
    selectedProject,
    handleChangeSelectedProject,
  } = useContext(MonitorKunjunganMonitorListContext);

  const { exportExcelMonitorKunjungan } = useExportExcelMonitorKunjungan(
    currentPsspType === "pssp-tidak-aktif" ? false : true,
    savedFilter
  );

  const handleClickExportExcel = () => {
    exportExcelMonitorKunjungan({
      onError: (errorMessage: string) => {
        toast({
          title: "Gagal Mengunduh Excel",
          description: errorMessage,
          status: "error",
        });
      },
    });
  };

  return (
    <Flex justify="space-between">
      <Box
        display="flex"
        flexDirection={isDesktopView ? "row" : "column"}
        gap={3}
        width="100%"
      >
        <Box width={isDesktopView ? "250px" : "100%"}>
          <CustomSelect
            options={loggedUser?.projects ?? []}
            isDisabled={loggedUser ? loggedUser.projects.length <= 1 : false}
            getOptionLabel={(data: ProjectType) => data.label}
            getOptionValue={(data: ProjectType) => data.value}
            value={selectedProject}
            placeholder="Project"
            onChange={(data: ProjectType | null) => {
              handleChangeSelectedProject(data);
            }}
          />
        </Box>
        <Box width={isDesktopView ? "301px" : "100%"}>
          <DropdownAllPeriode
            options={allPeriodeData}
            value={selectedPeriode}
            onChange={(data) => handleChangeSelectedPeriode(data!)}
          />
        </Box>
      </Box>

      {isDesktopView && (
        <ExportExcelButton
          onClick={handleClickExportExcel}
          isDisabled={monitorKunjunganData.length === 0}
        />
      )}
    </Flex>
  );
};

export default MonitorKunjunganPeriodeDanButtonExcel;
