import { useState } from "react";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import UpdateStatusKlaimSalesBody from "../../models/UpdateStatusKlaimSalesModel";
import ApprovalKlaimSalesProvider from "../dataProviders/ApprovalKlaimSalesProvider";

type CallResultHandler = {
  onSuccess: (data: string) => void;
  onError: (data: ErrorResponse) => void;
};

const useUpdateStatusKlaimSales = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorResponse, setError] = useState<ErrorResponse | null>(null);

  const updateStatusKlaimSales = async (
    data: UpdateStatusKlaimSalesBody,
    handler: CallResultHandler
  ) => {
    try {
      setError(null);
      setLoading(true);

      const response = await ApprovalKlaimSalesProvider.updateStatusKlaimSales({
        data: data,
      });

      setLoading(false);
      handler.onSuccess(response!);
    } catch (error) {
      setError(error as ErrorResponse);
      setLoading(false);
      handler.onError(error as ErrorResponse);
    }
  };

  return { updateStatusKlaimSales, loading, errorResponse };
};

export default useUpdateStatusKlaimSales;
