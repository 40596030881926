import React from "react";
import useGetListKlaimSales from "../../services/useGetListKlaimSales";
import useFilterKlaimSales from "../services/useFilterKlaimSales";
import useTitle from "../../../../services/useTitle";
import ApprovalKlaimSalesContext from "../contexts/ApprovalKlaimSalesContext";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import ApprovalKlaimSalesList from "./ListApproval/ApprovalKlaimSalesList";
import FilterAreaApprovalKlaimSales from "./ListApproval/FilterAreaApprovalKlaimSales";
import DateExodus from "../../../../models/DateExodus";

const ApprovalKlaimSalesBase: React.FC = () => {
  const {
    filterValues,
    handleClearFilter,
    errorFilter,
    loadingFilter,
    handleChangePageOrLimit,
    setFilterApprovalKlaimSales,
  } = useFilterKlaimSales();

  const {
    klaimSalesListData,
    errorResponse: klaimSalesError,
    loading: klaimSalesLoading,
    klaimSalesMetadata: metadata,
  } = useGetListKlaimSales({
    page: filterValues?.page,
    limit: filterValues?.limit,
    startAt: new DateExodus(filterValues?.startAt),
    endAt: new DateExodus(filterValues?.endAt),
    claimUserId: filterValues?.claimUser?.id,
    outletPanelName: filterValues?.outletPanelName?.trim() ?? undefined,
    outletTargetName: filterValues?.outletTargetName?.trim() ?? undefined,
    status: filterValues?.status,
    idKlaimSales: filterValues?.idKlaimSales,
  });

  useTitle("Approval Klaim Sales");

  return (
    <ApprovalKlaimSalesContext.Provider
      value={{
        filterValues: filterValues,
        loadingFilter: loadingFilter,
        errorFilter: errorFilter,
        setFilterApprovalKlaimSales: setFilterApprovalKlaimSales,
        klaimSalesData: klaimSalesListData,
        klaimSalesLoading: klaimSalesLoading,
        klaimSalesError: klaimSalesError,
        handleChangePageOrLimit: handleChangePageOrLimit,
        handleClearFilter: handleClearFilter,
        metadataKlaimSales: metadata,
      }}
    >
      <PageTitle label="Approval Klaim Sales" />
      <FilterAreaApprovalKlaimSales />
      <ApprovalKlaimSalesList />
    </ApprovalKlaimSalesContext.Provider>
  );
};

export default ApprovalKlaimSalesBase;
