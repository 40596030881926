import { Box, useMediaQuery, useTheme } from "@chakra-ui/react";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import DesktopApprovalRencanaKunjungan from "./desktop/DesktopApprovalRencanaKunjungan";
import MobileApprovalRencanaKunjungan from "./mobile/MobileApprovalRencanaKunjungan";

const ApprovalRencanaKunjunganPage: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Box width={"100%"}>
      <PageTitle label="Approval Rencana Kunjungan" />
      {isDesktopView && <DesktopApprovalRencanaKunjungan />}
      {!isDesktopView && <MobileApprovalRencanaKunjungan />}
    </Box>
  );
};

export default ApprovalRencanaKunjunganPage;
