import React from "react";
import { useParams } from "react-router-dom";
import useGetDetailKlaimSales from "../../services/useGetDetailKlaimSales";
import DetailApprovalKlaimSalesContext from "../contexts/DetailApprovalKlaimSalesContext";
import DetailApprovalKlaimSalesPage from "./DetailApproval/DetailApprovalKlaimSalesPage";
import useTitle from "../../../../services/useTitle";
import { useToast } from "@chakra-ui/react";

const DetailApprovalKlaimSalesBase: React.FC = () => {
  const { id } = useParams();
  const toast = useToast();

  const {
    detailKlaimSalesData,
    errorResponse: error,
    loading,
    refetchGetDetailKlaimSales,
  } = useGetDetailKlaimSales(Number(id));

  const openPdf = (url: string) => {
    if (url.includes("http")) {
      window.open(url);
    } else {
      toast({ title: "PDF Tidak Valid", status: "error" });
    }
  };

  useTitle("Approval Klaim Sales");
  
  return (
    <DetailApprovalKlaimSalesContext.Provider
      value={{
        detailKlaimSalesData: detailKlaimSalesData,
        detailKlaimSalesError: error,
        detailKlaimSalesLoading: loading,
        refetchDetailKlaimSales: refetchGetDetailKlaimSales,
        openPdf: openPdf,
      }}
    >
      <DetailApprovalKlaimSalesPage />
    </DetailApprovalKlaimSalesContext.Provider>
  );
};

export default DetailApprovalKlaimSalesBase;
