import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { SALES_OUTLET_NAVIGATION_ROOT } from "../../../../../../../../constants/common/RootConstants";

const BackButton = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(SALES_OUTLET_NAVIGATION_ROOT);
  };

  return (
    <Button
      colorScheme="buttonSecondary"
      variant="outline"
      onClick={handleOnClick}
      width={"480px"}
    >
      Kembali ke Halaman Sebelumnya
    </Button>
  );
};

export default BackButton;
