import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

// https://chakra-ui.com/docs/components/accordion/theming
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  root: {
    bg: "#f8f8ff",
  },
  icon: {
    bg: "#f8f8ff",
    fontSize: 24,
  },
  container: {
    bg: "#f8f8ff",
  },
  button: {
    bg: "#f8f8ff",
    paddingX: 4,
  },
  panel: {
    bg: "#f8f8ff",
    paddingX: 0,
  },
});

export const AccordionTheme = defineMultiStyleConfig({ baseStyle });
