import { useContext } from "react";
import DesktopApprovalRencanaKunjunganBasicInformation from "./DesktopApprovalRencanaKunjunganBasicInformation";
import DesktopTableDetailApprovalRencanaKunjungan from "./DesktopTableDetailApprovalRencanaKunjungan";
import ApprovalRencanaKunjunganDetailContext from "../../context/ApprovalRencanaKunjunganDetailContext";
import { Box, Spinner, useTheme } from "@chakra-ui/react";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";

const DesktopApprovalRencanaKunjunganDetailPage = () => {
  const { detailLoading, detailError } = useContext(
    ApprovalRencanaKunjunganDetailContext
  );

  const theme = useTheme();

  if (detailLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (detailError) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={detailError.toString()} />
      </Box>
    );
  }

  return (
    <>
      <DesktopApprovalRencanaKunjunganBasicInformation />
      <DesktopTableDetailApprovalRencanaKunjungan />
    </>
  );
};

export default DesktopApprovalRencanaKunjunganDetailPage;
