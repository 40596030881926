import { useTheme, Text, Button } from "@chakra-ui/react";

const ChangePasswordButton = ({
  handleOnClickChangePassword,
}: {
  handleOnClickChangePassword: () => void;
}) => {
  const theme = useTheme();
  return (
    <Button
      colorScheme="buttonPrimary"
      variant="outline"
      borderRadius={8}
      onClick={handleOnClickChangePassword}
    >
      <Text
        color={theme.colors.exodus.secondaryBlue}
        fontWeight={600}
        fontSize={14}
      >
        Ganti Password
      </Text>
    </Button>
  );
};

export default ChangePasswordButton;
