import { VStack, useTheme, Text, Stack, Divider } from "@chakra-ui/react";
import { useOutletCardDetailContext } from "../../../contexts/OutletCardDetailContextProvider";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";

const MobileListProductDetail = () => {
  const { states } = useOutletCardDetailContext();
  const theme = useTheme();

  return (
    <VStack>
      <Stack justifyContent={"space-between"} direction="row" width={"100%"}>
        <Text
          display="flex"
          fontSize={16}
          fontWeight={400}
          color={theme.colors.exodus.primaryBlue}
          textAlign={"left"}
        >
          Jumlah Produk
        </Text>
        <Text
          display="flex"
          fontSize={16}
          fontWeight={400}
          color={theme.colors.exodus.darkGrey}
        >
          {states.saldoKontribusiDetail?.jumlahProduk ?? "-"}
        </Text>
      </Stack>
      <Stack justifyContent={"space-between"} direction="row" width={"100%"}>
        <Text
          display="flex"
          fontSize={16}
          fontWeight={400}
          color={theme.colors.exodus.primaryBlue}
          textAlign={"left"}
        >
          Total Saldo Kontribusi Produk
        </Text>
        <Text
          display="flex"
          fontSize={16}
          fontWeight={400}
          color={theme.colors.exodus.darkGrey}
        >
          {CurrencyIdrFormatter.convertToRp(
            states.saldoKontribusiDetail?.totalSaldo ?? 0
          )}
        </Text>
      </Stack>
      <Divider
        color={theme.colors.exodus.primaryBlue}
        width={"100%"}
        sx={{
          border: "1px",
        }}
      />
    </VStack>
  );
};

export default MobileListProductDetail;
