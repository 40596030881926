import dayjs from "dayjs";
import DateExodus from "../../../../../models/DateExodus";
import { OutletCardListFilterType } from "../models/OutletCardListFilterType";
import { useCallback, useEffect, useState } from "react";
import OutletCardProvider from "../../dataProviders/OutletCardProvider";
import IsTypeOfErrorResponse from "../../../../../utils/IsTypeOfErrorResponse";
import ErrorResponse from "../../../../../models/common/ErrorResponse";

/**
 * Default initial value if no value is found on indexDB
 */
const filterInitialValue: OutletCardListFilterType = {
  startDate: new DateExodus(dayjs().startOf("month").unix()),
  endDate: new DateExodus(dayjs().endOf("month").unix()),
  role: null,
  bawahan: null,
  sector: null,
  zone: null,
  outlet: null,
  project: null,
};

const useOutletCardListFilter = () => {
  const [filterValues, setFilterValues] =
    useState<OutletCardListFilterType | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  const initializeFilter = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const databaseResponse: OutletCardListFilterType | null | undefined =
        await OutletCardProvider.getIndexDbFilter();

      if (databaseResponse) {
        setFilterValues(databaseResponse);
      } else {
        setFilterValues(filterInitialValue);
      }

      setError(null);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    initializeFilter();
  }, []);

  const saveFilter = async (updatedFilterValues: OutletCardListFilterType) => {
    try {
      setLoading(true);
      setError(null);
      await OutletCardProvider.saveIndexDbFilter(updatedFilterValues);
      setFilterValues(updatedFilterValues);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error as ErrorResponse);
      }
    } finally {
      setLoading(false);
    }
  };

  return { filterValues, saveFilter, isLoading, error };
};

export default useOutletCardListFilter;
