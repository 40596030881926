import { useState } from "react";
import ExcelJS from "exceljs";
import SalesOutletProvider from "../../../../dataProviders/SalesOutletProvider";
import ErrorResponse from "../../../../../../../models/common/ErrorResponse";
import ApiGetPrintSalesOutletResponse from "../../models/apiResponses/ApiGetPrintSalesOutletResponse";
import SalesOutletListFilterModel from "../../models/SalesOutletListFilterModel";
import { saveAs } from "file-saver";
import { projectEnumToUi } from "../../../../../../../models/common/Project";

const useGetPrint = (params: SalesOutletListFilterModel | null) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  async function getData() {
    try {
      if (params?.periode.awal && params.periode.akhir) {
        setError(null);
        setIsLoading(true);

        const result = await SalesOutletProvider.getPrint({
          project: params?.project,
          startDate: params?.periode.awal,
          endDate: params?.periode.akhir,
          userId: params?.pekerja?.id,
          outlets: params?.outlets,
          sector: params?.sector,
          zone: params?.zona,
        });
        await exportData(params, result);
      }
    } catch (error) {
      if (error as ErrorResponse) {
        setError(error as ErrorResponse);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return { isLoading, error, getData };
};

const exportData = async (
  filterValues: SalesOutletListFilterModel | null,
  data: ApiGetPrintSalesOutletResponse | null
) => {
  if (data?.records) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet();
    const totalListLength = data.records.length;

    const startDate =
      filterValues?.periode.awal.getStringDateFormat("DD-MM-YYYY");
    const endDate =
      filterValues?.periode.akhir.getStringDateFormat("DD-MM-YYYY");
    worksheet.name = "Sales Outlet";

    const makeTableWithProject = () => {
      worksheet.columns = [
        { key: "A", width: 15 },
        { key: "B", width: 20 },
        { key: "C", width: 20 },
        { key: "D", width: 20 },
        { key: "E", width: 20 },
        { key: "F", width: 20 },
        { key: "G", width: 20 },
        { key: "H", width: 20 },
      ];
      worksheet.getRow(1).height = 20;

      const rowHeaderPosition = 1;
      const rowHeader = worksheet.getRow(rowHeaderPosition);

      rowHeader.getCell(1).value = "Project";
      rowHeader.getCell(2).value = "Periode Start";
      rowHeader.getCell(3).value = "Periode End";
      rowHeader.getCell(4).value = "Nama Outlet";
      rowHeader.getCell(5).value = "Kode PI Outlet";
      rowHeader.getCell(6).value = "Sektor";
      rowHeader.getCell(7).value = "Sales Value";
      rowHeader.getCell(8).value = "Estimasi Value";

      for (let i = 1; i <= 8; i++) {
        rowHeader.getCell(i).font = {
          size: 12,
          bold: true,
        };
        rowHeader.getCell(i).alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF9F" },
        };
        rowHeader.getCell(i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      for (let i = 1; i <= 8; i++) {
        for (let j = 1; j <= totalListLength + 1; j++) {
          const cell = worksheet.getCell(j, i);
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
      for (let i = 0; i < totalListLength; i++) {
        const row = worksheet.getRow(rowHeaderPosition + 1 + i);
        row.alignment = {
          wrapText: true,
          horizontal: "center",
          vertical: "middle",
        };
        row.getCell(1).value = projectEnumToUi(data.records[i].project);
        row.getCell(2).value = startDate;
        row.getCell(3).value = endDate;
        row.getCell(4).value = data.records[i].outletName;
        row.getCell(5).value = data.records[i].outletPharosCode;
        row.getCell(6).value = data.records[i].sector.label;
        row.getCell(7).value = data.records[i].salesValue;
        row.getCell(8).value = data.records[i].estimatedValue;
      }
    };

    const makeTableWithoutProject = () => {
      worksheet.columns = [
        { key: "A", width: 20 },
        { key: "B", width: 20 },
        { key: "C", width: 20 },
        { key: "D", width: 20 },
        { key: "E", width: 20 },
        { key: "F", width: 20 },
        { key: "G", width: 20 },
      ];
      worksheet.getRow(1).height = 20;

      const rowHeaderPosition = 1;
      const rowHeader = worksheet.getRow(rowHeaderPosition);

      rowHeader.getCell(1).value = "Periode Start";
      rowHeader.getCell(2).value = "Periode End";
      rowHeader.getCell(3).value = "Nama Outlet";
      rowHeader.getCell(4).value = "Kode PI Outlet";
      rowHeader.getCell(5).value = "Sektor";
      rowHeader.getCell(6).value = "Sales Value";
      rowHeader.getCell(7).value = "Estimasi Value";

      for (let i = 1; i <= 7; i++) {
        rowHeader.getCell(i).font = {
          size: 12,
          bold: true,
        };
        rowHeader.getCell(i).alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF9F" },
        };
        rowHeader.getCell(i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      for (let i = 1; i <= 7; i++) {
        for (let j = 1; j <= totalListLength + 1; j++) {
          const cell = worksheet.getCell(j, i);
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
      for (let i = 0; i < totalListLength; i++) {
        const row = worksheet.getRow(rowHeaderPosition + 1 + i);
        row.alignment = {
          wrapText: true,
          horizontal: "center",
          vertical: "middle",
        };
        row.getCell(1).value = startDate;
        row.getCell(2).value = endDate;
        row.getCell(3).value = data.records[i].outletName;
        row.getCell(4).value = data.records[i].outletPharosCode;
        row.getCell(5).value = data.records[i].sector.label;
        row.getCell(6).value = data.records[i].salesValue;
        row.getCell(7).value = data.records[i].estimatedValue;
      }
    };

    if (data.hasProject) {
      makeTableWithProject();
    } else {
      makeTableWithoutProject();
    }

    function namaUser(): string {
      if (filterValues?.pekerja) {
        return ` - ${filterValues.pekerja.role.label} ${filterValues.pekerja.name} (${filterValues.pekerja.nip})`;
      } else {
        return "";
      }
    }

    function sector(): string {
      if (filterValues?.sector) {
        return ` - ${filterValues.sector.label}`;
      } else {
        return "";
      }
    }

    const buf = await workbook.xlsx.writeBuffer();
    const filename = "Sales Outlet" + namaUser() + sector() + ".xlsx";

    saveAs(new Blob([buf]), filename);
  } else {
    throw "Data belum tersedia";
  }
};

export default useGetPrint;
