import { ListSalesTargetRecords } from "../../models/SalesTargetModelPack";
import { InputAndEditSalesTargetModel } from "../../models/SalesTargetInputModel";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
  Text,
  Box,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useContext } from "react";
import InputSalesTargetContext from "../context/InputSalesTargetContext";
import InputSalesTargetFormField from "./form/InputSalesTargetFormField";

type Props = {
  data: ListSalesTargetRecords;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: InputAndEditSalesTargetModel) => void;
  isLoadingSubmit: boolean;
};

const DialogInputAndEditTargetSales: React.FC<Props> = (props) => {
  const theme = useTheme();
  const form = useForm<InputAndEditSalesTargetModel>();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const { filterValues } = useContext(InputSalesTargetContext);

  let firstMonth;
  let secondMonth;
  let thirdMonth;
  const monthValue = filterValues?.idKuartal;
  if (monthValue === 1) {
    firstMonth = "Januari";
    secondMonth = "Februari";
    thirdMonth = "Maret";
  } else if (monthValue === 2) {
    firstMonth = "April";
    secondMonth = "Mei";
    thirdMonth = "Juni";
  } else if (monthValue === 3) {
    firstMonth = "Juli";
    secondMonth = "Agustus";
    thirdMonth = "September";
  } else if (monthValue === 4) {
    firstMonth = "Oktober";
    secondMonth = "November";
    thirdMonth = "Desember";
  }

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      isOpen={props.open}
      onClose={props.onClose}
      scrollBehavior="outside"
      size={isDesktopView ? "xl" : "4xl"}
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader>
          <Text
            fontSize={24}
            fontWeight="semibold"
            color={theme.colors.exodus.primaryBlue}
          >
            Target Sales
          </Text>
          <Text
            fontSize={20}
            fontWeight="semibold"
            color={theme.colors.exodus.primaryBlue}
            paddingTop={"10px"}
          >
            {props.data.fieldForceIdentity?.fieldForceNip} -{" "}
            {props.data.fieldForceIdentity?.fieldForceName}
          </Text>
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(props.onSubmit)}>
              <Flex flexDirection={"column"} gap={"10px"}>
                <InputSalesTargetFormField
                  index={0}
                  title={`Target ${firstMonth}`}
                  defaultValue={
                    props?.data?.targetSalesList[0]?.valueTarget as number
                  }
                />
                <InputSalesTargetFormField
                  index={1}
                  title={`Target ${secondMonth}`}
                  defaultValue={
                    props?.data?.targetSalesList[1]?.valueTarget as number
                  }
                />
                <InputSalesTargetFormField
                  index={2}
                  title={`Target ${thirdMonth}`}
                  defaultValue={
                    props?.data?.targetSalesList[2]?.valueTarget as number
                  }
                />
              </Flex>
              <Flex
                direction={!isDesktopView ? "column" : "row-reverse"}
                gap={!isDesktopView ? 0 : 5}
                marginTop="20px"
                marginBottom="20px"
              >
                <Button
                  type="submit"
                  isLoading={props.isLoadingSubmit}
                  width="100%"
                  colorScheme="buttonPrimary"
                >
                  Submit
                </Button>
                <Button
                  isDisabled={props.isLoadingSubmit}
                  onClick={() => {
                    form.reset();
                    props.onClose();
                  }}
                  width="100%"
                  variant="outline"
                  colorScheme="buttonSecondary"
                  marginTop={!isDesktopView ? "12px" : 0}
                >
                  Cancel
                </Button>
              </Flex>
              <Box
                display={"flex"}
                justifyContent={"center"}
                marginBottom={"10px"}
              >
                <Text fontSize={"12px"}>
                  Target Sales yang di submit akan dapat dilihat dan di approve
                  oleh tim Trade
                </Text>
              </Box>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DialogInputAndEditTargetSales;
