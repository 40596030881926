/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text } from "@chakra-ui/react";
import { TableColumn } from "react-data-table-component";
import { FakturByCodeModelDetailData } from "../../../../models/FakturByCodeModel";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";
import TableDataComponent from "../../../../../../../components/TableData/TableDataComponent";
import StackedColumn from "../../../../../../../components/TableData/StackedColumnTable";

type Props = {
  data: FakturByCodeModelDetailData[];
};

const MobileTableDetailFakturKlaimSales: React.FC<Props> = (props) => {
  const dataColumn: TableColumn<any>[] = [
    {
      cell: (row: FakturByCodeModelDetailData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.productName}
          bottomValue={CurrencyIdrFormatter.convertToRp(row.salePrices)}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 2,
    },
    {
      cell: (row: FakturByCodeModelDetailData) => (
        <Text textAlign="left" fontWeight="semibold">
          {row.qty}
        </Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      right: true,
    },
  ];

  return (
    <TableDataComponent
      noTableHead
      data={props.data}
      checboxOn={false}
      tableHeader={dataColumn}
      paginationOn={false}
      paginationLimit={[]}
      onSelectChange={() => {}}
    />
  );
};

export default MobileTableDetailFakturKlaimSales;
