import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../../../../../../components/CustomSelect/CustomSelect";
import RoleModel from "../../../../../../../../../../../models/Users/RoleModel";
import useGetRoleByLoggedUser from "../../../../../../../../../../../services/Users/useGetRoleByLoggedUser";
import SalesOutletListFilterModel from "../../../../../../models/SalesOutletListFilterModel";
import FilterFieldFrame from "../FilterFieldFrame";
import { VStack, HStack, Spinner, useTheme, Box, Text } from "@chakra-ui/react";

const FilterByJabatan = () => {
  const theme = useTheme();

  const { roleData, loading, errorMessage } = useGetRoleByLoggedUser();

  const formContext = useFormContext<SalesOutletListFilterModel>();
  const watchJabatan = formContext.watch("jabatan");

  return (
    <FilterFieldFrame title="Jabatan">
      <VStack width={"100%"} gap={0} alignItems={"stretch"}>
        <HStack width={"100%"} gap={4}>
          {loading ? (
            <Spinner color={theme.colors.exodus.primaryBlue} />
          ) : (
            <></>
          )}
          <Box width={"100%"}>
            <CustomSelect
              options={roleData}
              onChange={(value: RoleModel | null | undefined) => {
                if (value) {
                  formContext.setValue("jabatan", value);
                } else {
                  formContext.setValue("jabatan", undefined);
                }
                formContext.setValue("pekerja", undefined);
                formContext.setValue("zona", undefined);
              }}
              getOptionLabel={(eachRole: RoleModel) => eachRole.label}
              defaultValue={null}
              value={watchJabatan}
              placeholder="Jabatan"
              getOptionValue={(eachRole) => eachRole.label}
              isClearable
            />
          </Box>
        </HStack>
        {errorMessage ? (
          <Text
            color={theme.colors.exodus.red}
            textAlign={"left"}
            pl={2}
            fontSize={14}
          >
            {errorMessage}
          </Text>
        ) : (
          <></>
        )}
      </VStack>
    </FilterFieldFrame>
  );
};

export default FilterByJabatan;
