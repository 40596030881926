import { Text, useMediaQuery, useTheme } from "@chakra-ui/react";

type Props = {
  topValue: string;
  bottomValue: string;
};

const DetailRealisasiOrExtraCallStackedData: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <>
      <Text
        fontWeight="semibold"
        color={theme.colors.exodus.primaryBlue}
        fontSize={isDesktopView ? 16 : 14}
      >
        {props.topValue}
      </Text>
      <Text fontWeight="semibold" fontSize={isDesktopView ? 16 : 14}>
        {props.bottomValue}
      </Text>
    </>
  );
};

export default DetailRealisasiOrExtraCallStackedData;
