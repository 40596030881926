import ErrorResponse from "../../../../../models/common/ErrorResponse";


export const SAVE_TO_DB_ERROR_RESPONSE: ErrorResponse = {
    message: "Error in saving data",
    status: 0,
    code: "",
  };

  export const GET_DATA_DB_ERROR_RESPONSE: ErrorResponse = {
    message: "Error in getting data",
    status: 0,
    code: "",
  };
  