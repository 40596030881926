import { AxiosResponse, isAxiosError } from "axios";
import AxiosBaseClient from "../../../dataProviders/config/AxiosBaseClient";
import {
  EXODUS_API_VERSION_1,
  EXODUS_API_VERSION_2,
} from "../../../constants/common/RootConstants";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";
import { TargetSalesMonthlyModelPack } from "../model/TargetSalesMonthly";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import ProjectType from "../../../models/common/ProjectType";

const DashboardDataProvider: any = {
  getDistributorLastUpdateSales: async (): Promise<any> => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_1}/distributor/last_update_sales`
      );

      if (
        response.status === AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_GET_STATUS
      ) {
        if (
          response.data["message"] ===
          AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_MESSAGE
        ) {
          return response.data;
        } else {
          return Promise.reject(UNKNOWN_ERROR_RESPONSE);
        }
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
  getBudgetNasional: async (): Promise<any> => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_1}/budget_nasional`
      );

      if (
        response.status === AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_GET_STATUS
      ) {
        if (
          response.data["message"] ===
          AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_MESSAGE
        ) {
          return response.data;
        } else {
          return Promise.reject(UNKNOWN_ERROR_RESPONSE);
        }
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
  getTargetSalesProgress: async (period: number, project: ProjectType | null): Promise<any> => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_2}/target-sales/progress`,
        {
          params: {
            period: period,
            project: project?.value
          },
        }
      );

      if (
        response.status === AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_GET_STATUS
      ) {
        if (
          response.data["message"] ===
          AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_MESSAGE
        ) {
          return response.data;
        } else {
          return Promise.reject(UNKNOWN_ERROR_RESPONSE);
        }
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
  getBiayaMurniSalesRatio: async (
    startPeriod: number,
    endPeriod: number
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_1}/cost/ratio`,
        {
          params: {
            start_period: startPeriod,
            end_period: endPeriod,
          },
        }
      );

      if (
        response.status === AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_GET_STATUS
      ) {
        if (
          response.data["message"] ===
          AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_MESSAGE
        ) {
          return response.data;
        } else {
          return Promise.reject(UNKNOWN_ERROR_RESPONSE);
        }
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
  getTargetSalesList: async (period: number, project: ProjectType | null): Promise<any> => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_2}/target-sales/monthly`,
        {
          params: {
            period: period,
            project: project?.value,
          },
        }
      );

      if (
        response.status === AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_GET_STATUS
      ) {
        if (
          response.data["message"] ===
          AXIOS_SUCCESS_MESSAGE_RESPONSE.SUCCESS_MESSAGE
        ) {
          const data = new TargetSalesMonthlyModelPack(response.data);

          return data;
        } else {
          return Promise.reject(new Error("Gagal Memuat Data"));
        }
      } else {
        return Promise.reject(`${response.data.message}`);
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
};

export default DashboardDataProvider;
