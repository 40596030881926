import AllowedPage from "../common/AllowedPage";
import { projectEnumToUi } from "../common/Project";
import ProjectType from "../common/ProjectType";

export const MapUserData = (response: any) => {
  return {
    userId: response.data.data.user_id ?? 0,
    userAuthId: response.data.data.auth_server_id ?? 0,
    userNip: response.data.data.nip ?? "-",
    userEmail: response.data.data.email ?? "-",
    userName: response.data.data.name ?? "-",
    userRoleId: response.data.data.role_id ?? 0,
    userRoleName: response.data.data.role_name ?? 0,
    userRoleLabel: response.data.data.label ?? 0,
    userAllowedPages: response.data.data.allowed_pages?.map((page: any) => {
      let allowedPages: AllowedPage = {
        id: page.id,
        name: page.name,
      };
      return allowedPages;
    }),
    projects: response.data.data.projects?.map((eachProject: any) => {
      const mappedProject: ProjectType = {
        label: projectEnumToUi(eachProject),
        value: eachProject,
      };
      return mappedProject;
    }),
  };
};
