import { useContext } from "react";
import ApprovalRencanaKunjunganDetailContext from "../context/ApprovalRencanaKunjunganDetailContext";
import { Box, Button, useTheme, Text } from "@chakra-ui/react";
import CustomPopover from "../../../../../components/CustomPopover/CustomPopover";
import { MdInfoOutline } from "react-icons/md";

const PopOverDetailRencanaKunjungan = () => {
  const { detailData } = useContext(ApprovalRencanaKunjunganDetailContext);
  const theme = useTheme();
  return (
    <>
      {/* belum ada data customer approve */}
      <Box textAlign={"left"} width={"fit-content"}>
        {detailData?.approvalPlanStatus === "Approved" && (
          <CustomPopover
            triggerType="hover"
            placement="bottom-start"
            triggerComponent={
              <Box marginBottom={"15px"}>
                <Button
                  leftIcon={<MdInfoOutline />}
                  variant={"ghost"}
                  sx={{
                    fontSize: 14,
                    color: theme.colors.exodus.primaryBlue,
                  }}
                >
                  Lihat List Approval
                </Button>
              </Box>
            }
            bodyComponent={
              <Box>
                <Text
                  color={theme.colors.exodus.primaryBlue}
                  fontWeight={"bold"}
                >
                  Approve Rencana Kunjungan
                </Text>
                {detailData?.approvalPlanTime?.getStringDateFormat(
                  "D MMMM YYYY"
                )}
              </Box>
            }
          />
        )}
      </Box>
    </>
  );
};

export default PopOverDetailRencanaKunjungan;
