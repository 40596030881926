import { useState } from "react";
import RencanaKunjunganProvider from "../../dataProviders/RencanaKunjunganProvider";
import ApprovalRencanaMetadata from "../models/ApprovalRencanaMetadata";

const useSaveApprovalRencanaMetadata = () => {
  const [message, setMessage] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);

  const saveMetadata = async (data: ApprovalRencanaMetadata) => {
    setLoading(true);
    try {
      const result: string =
        await RencanaKunjunganProvider.saveMetadataApprovalRencana(data);
      setMessage(result);
    } catch (error: unknown) {
      setMessage(error as string);
    } finally {
      setLoading(false);
    }
  };

  return { message, isLoading, saveMetadata };
};

export default useSaveApprovalRencanaMetadata;
