import DateExodus from "../../../../models/DateExodus";
import { ProjectEnum } from "../../../../models/common/Project";

class BiayaMurniPsspData {
  psspId: number;
  outletId: number;
  outletName: string;
  outletCode: string;
  customerId: number;
  customerName: string;
  customerCode: string;
  spesialis: string;
  posisi: string;
  psspValue: number;
  psspNominal: number;
  psspPeriod: string;
  processedAt: number;
  tglRealisasiFormat: DateExodus | null;
  project: ProjectEnum;

  constructor(json: any) {
    this.psspId = json["pssp_id"];
    this.outletId = json["outlet_id"];
    this.outletName = json["outlet_name"];
    this.outletCode = json["outlet_code"];
    this.customerId = json["customer_id"];
    this.customerName = json["customer_name"];
    this.customerCode = json["customer_code"];
    this.spesialis = json["customer_specialist"];
    this.posisi = json["customer_position"];
    this.psspValue = json["pssp_value"];
    this.psspNominal = json["pssp_nominal"];
    this.psspPeriod = json["pssp_period"];
    this.processedAt = json["or_processed_at"];
    this.tglRealisasiFormat = json["or_processed_at"]
      ? DateExodus.fromBackend(json["or_processed_at"])
      : null;
    this.project = json.project;
  }
}

export { BiayaMurniPsspData };
