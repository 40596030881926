import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const DialogKonfirmasiSubmitKlaimSales: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          Submit Klaim Sales
        </ModalHeader>
        <ModalBody>Anda yakin akan submit Klaim Sales ?</ModalBody>
        <ModalFooter
          flexDirection={!isDesktopView ? "column" : "row-reverse"}
          gap={!isDesktopView ? 0 : 5}
        >
          <Button
            width="100%"
            colorScheme="buttonPrimary"
            onClick={props.onConfirm}
          >
            Confirm
          </Button>
          <Button
            onClick={props.onClose}
            width="100%"
            variant="outline"
            colorScheme="buttonSecondary"
            marginTop={!isDesktopView ? "12px" : 0}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DialogKonfirmasiSubmitKlaimSales;
