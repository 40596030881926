import { useContext, useState } from "react";
import MonitorKlaimSalesContext from "../contexts/MonitorKlaimSalesContext";
import { Box, Spinner, useMediaQuery, useTheme } from "@chakra-ui/react";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import ShowFilterButton from "../../../../components/CustomButtons/ShowFilterButton";
import FilterBox from "../../../../components/FilterBox/FilterBox";
import Pagination from "../../../../components/Pagination/Pagination";
import MonitorKlaimSalesListCard from "./MonitorKlaimSalesListCard";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../components/DataNotFound/DataNotFound";
import DialogFilterMonitorKlaimSales from "./DialogFilterMonitorKlaimSales";
import DateExodus from "../../../../models/DateExodus";

const MonitorKlaimSalesPage = () => {
  const {
    klaimSalesListData,
    klaimSalesMetadata,
    loading,
    errorResponse,
    filterValues,
    handleChangePageOrLimit,
  } = useContext(MonitorKlaimSalesContext);
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClickSales = (id: number) => {
    navigate({
      pathname: "/klaim-sales-monitor/" + id,
    });
  };

  const renderErrorComponent = () => {
    if (!errorResponse) {
      return null;
    }
    if (
      errorResponse.status === 404 &&
      errorResponse.message === "error_id_not_found Not Found"
    ) {
      return (
        <Box marginTop="24px" marginBottom="15px">
          <ErrorPage message={"Klaim Sales tidak ditemukan"} />
        </Box>
      );
    } else {
      return (
        <Box marginTop="24px" marginBottom="15px">
          <ErrorPage message={errorResponse.message.toString()} />
        </Box>
      );
    }
  };

  return (
    <Box>
      <Box>
        <PageTitle label="Monitor Klaim Sales" />
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} flexWrap={"wrap"}>
            {filterValues?.claimUser?.name && (
              <FilterBox title={"NSM"} content={filterValues.claimUser.name} />
            )}
            {filterValues?.outletPanelName && (
              <FilterBox
                title={"Outlet Panel"}
                content={filterValues.outletPanelName}
              />
            )}
            {filterValues?.outletTargetName && (
              <FilterBox
                title={"Outlet Tujuan"}
                content={filterValues.outletTargetName}
              />
            )}
            {filterValues?.startAt &&
              filterValues?.endAt &&
              !filterValues?.idKlaimSales && (
                <FilterBox
                  title={"Tanggal Pengajuan"}
                  content={`${new DateExodus(
                    filterValues.startAt
                  )?.getStringDateFormat("MMMM YYYY")} - ${new DateExodus(
                    filterValues.endAt
                  )?.getStringDateFormat("MMMM YYYY")}`}
                />
              )}
            {filterValues?.status && (
              <FilterBox title={"Status"} content={filterValues.status} />
            )}
            {filterValues?.idKlaimSales && (
              <FilterBox
                title={"Claim Sales ID"}
                content={filterValues.idKlaimSales}
              />
            )}
          </Box>
          <Box>
            <ShowFilterButton onClick={() => setOpenFilterDialog(true)} />
          </Box>
        </Box>
        {errorResponse || loading ? (
          <Box marginTop="24px" marginBottom="15px">
            {loading ? (
              <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
            ) : null}
            {renderErrorComponent()}
          </Box>
        ) : klaimSalesListData.length > 0 ? (
          <>
            <Box display={"flex"} flexWrap={"wrap"} paddingTop={"10px"}>
              {klaimSalesListData.map((item) => (
                <MonitorKlaimSalesListCard
                  key={item.id}
                  item={item}
                  onClick={() => handleClickSales(item.id)}
                />
              ))}
            </Box>
            <Box
              paddingTop={2}
              display={"flex"}
              justifyContent={isDesktopView ? "end" : "center"}
            >
              <Pagination
                handleOnNavigateFirstPage={() =>
                  handleChangePageOrLimit("first")
                }
                handleOnBack={() => handleChangePageOrLimit("prev")}
                handleOnLimitChange={(data: number) =>
                  handleChangePageOrLimit("limit", data)
                }
                handleOnNext={() => handleChangePageOrLimit("next")}
                limit={filterValues?.limit}
                page={filterValues?.page}
                totalData={klaimSalesMetadata?.totalData}
                totalPage={klaimSalesMetadata?.totalPage}
                showCurrentPageDetail
                showLimit
                showNavigateFirstPage
              />
            </Box>
          </>
        ) : (
          <Box marginTop="24px" marginBottom="15px">
            <DataNotFound />
          </Box>
        )}
      </Box>
      <DialogFilterMonitorKlaimSales
        isOpen={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
      />
    </Box>
  );
};

export default MonitorKlaimSalesPage;
