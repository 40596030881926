import {
  Box,
  Flex,
  Spinner,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { PilihProdukAsyncType } from "../../services/usePilihProdukData";
import { usePilihProduk } from "../../contexts/PilihProdukContextProvider";
import { EditableFakturSalesData } from "../../models/EditableFakturModel";
import KlaimSalesSelectableFaktur from "./KlaimSalesSelectableFaktur";

const PilihProdukKlaimSalesFakturList: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const {
    state: { fakturList, awaitState },
  } = usePilihProduk();

  return (
    <Flex gap={2} direction="column" align="flex-start">
      <Text
        color={theme.colors.exodus.primaryBlue}
        fontSize={isDesktopView ? 24 : 20}
        fontWeight="semibold"
        paddingBottom={isDesktopView ? "16px" : "8px"}
      >
        Faktur yang Terpilih
      </Text>
      {awaitState === PilihProdukAsyncType.idle ? (
        fakturList.map((data: EditableFakturSalesData, index) => (
          <Box key={index} sx={{ paddingY: 1, width: "100%" }}>
            <KlaimSalesSelectableFaktur data={data} />
          </Box>
        ))
      ) : (
        <Spinner size="lg" color={theme.colors.exodus.primaryBlue} />
      )}
    </Flex>
  );
};

export default PilihProdukKlaimSalesFakturList;
