import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Icon,
  Stack,
  useTheme,
  Text,
} from "@chakra-ui/react";
import { MenuListType } from "../models/SidebarAccordionType";
import { MdApproval, MdMonitor, MdPostAdd } from "react-icons/md";
import SidebarAccordion from "./SidebarAccordion/SidebarAccordion";
import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";
import verifyPageAccess from "../../../utils/VerifyPageAccess";
import {
  ROUTE_ID_APPROVAL_KLAIM_SALES,
  ROUTE_ID_INPUT_KLAIM_SALES,
  ROUTE_ID_MONITORING_KLAIM_SALES,
} from "../../../constants/common/RouteIdConstants";

const KlaimSalesMenu = ({ onClose }: { onClose: Function }) => {
  const theme = useTheme();

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );
  const allowAccess: boolean | null = useAppSelector(
    (state) => state.klaimSalesReducer.allowAccess
  );

  if (
    !verifyPageAccess(loggedUser, ROUTE_ID_INPUT_KLAIM_SALES) &&
    !verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_KLAIM_SALES) &&
    !verifyPageAccess(loggedUser, ROUTE_ID_MONITORING_KLAIM_SALES)
  )
    return null;

  const KlaimSalesMenuList: MenuListType[] = [];

  if (allowAccess && verifyPageAccess(loggedUser, ROUTE_ID_INPUT_KLAIM_SALES)) {
    KlaimSalesMenuList.push({
      title: "Input Klaim Sales",
      pathName: "klaim-sales-input",
      icon: (
        <Icon
          as={MdPostAdd}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });
  }

  if (verifyPageAccess(loggedUser, ROUTE_ID_APPROVAL_KLAIM_SALES)) {
    KlaimSalesMenuList.push({
      title: "Approval Klaim Sales",
      pathName: "klaim-sales-approval",
      icon: (
        <Icon
          as={MdApproval}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });
  }

  if (verifyPageAccess(loggedUser, ROUTE_ID_MONITORING_KLAIM_SALES)) {
    KlaimSalesMenuList.push({
      title: "Monitor Klaim Sales",
      pathName: "klaim-sales-monitor",
      icon: (
        <Icon
          as={MdMonitor}
          color={theme.colors.exodus.primaryBlue}
          boxSize={6}
        />
      ),
    });
  }

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      <AccordionItem>
        <AccordionButton>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 4,
              }}
            >
              <AccordionIcon />
              <Text
                fontSize={18}
                fontWeight={400}
                sx={{ color: theme.colors.exodus.primaryBlue }}
              >
                Klaim Sales
              </Text>
            </Stack>
          </Stack>
        </AccordionButton>
        <SidebarAccordion menuList={KlaimSalesMenuList} onClose={onClose} />
      </AccordionItem>
    </Accordion>
  );
};

export default KlaimSalesMenu;
