// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import "firebase/firestore";
import { initializeFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = JSON.parse(
  import.meta.env.VITE_REACT_APP_FIREBASE_CONFIG ?? ""
);

// Initialize Firebase
const firebaseClient: FirebaseApp = initializeApp(firebaseConfig);
const db = initializeFirestore(firebaseClient, {});

export default firebaseClient;
export { db as cloudFirestore };
