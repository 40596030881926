import { createContext } from "react";
import BudgetNasional from "../model/BudgetNasional";
import TargetSalesProgress from "../model/TargetSalesProgress";
import { TargetSalesMonthlyModelData } from "../model/TargetSalesMonthly";
import CostSalesRatio from "../model/CostSalesRatio";
import DateExodus from "../../../models/DateExodus";
import ProjectType from "../../../models/common/ProjectType";

type DashboardContextType = {
  budgetNasional: BudgetNasional | null;
  budgetNasionalLoading: boolean;
  budgetNasionalError: string | null;
  targetSalesProgress: TargetSalesProgress | null;
  targetSalesProgressLoading: boolean;
  targetSalesProgressError: string | null;
  targetSales: TargetSalesMonthlyModelData[] | null;
  targetSalesLoading: boolean;
  targetSalesError: string | null;
  biayaMurniSalesRatio: CostSalesRatio | null;
  biayaMurniSalesRatioLoading: boolean;
  biayaMurniSalesRatioError: string | null;
  selectedMonth: DateExodus | null;
  setSelectedMonth: (data: DateExodus | null) => void;
  selectedProject: ProjectType | null;
  setSelectedProject: (data: ProjectType | null) => void;
};

const initialValue: DashboardContextType = {
  budgetNasional: null,
  budgetNasionalError: null,
  budgetNasionalLoading: false,
  targetSalesProgress: null,
  targetSalesProgressError: null,
  targetSalesProgressLoading: false,
  targetSales: null,
  targetSalesLoading: false,
  targetSalesError: null,
  biayaMurniSalesRatio: null,
  biayaMurniSalesRatioLoading: false,
  biayaMurniSalesRatioError: null,
  selectedMonth: null,
  setSelectedMonth: () => {},
  selectedProject: null,
  setSelectedProject: () => {}
};

const DashboardContext = createContext(initialValue);

export default DashboardContext;
