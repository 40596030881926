import { MdDashboard } from "react-icons/md";
import {
  AccordionItem,
  AccordionButton,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@chakra-ui/react";

const DashboardMenu = ({ onClose }: { onClose: Function }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/");
    onClose();
  };

  return (
    <AccordionItem onClick={handleOnClick}>
      <AccordionButton>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 4,
          }}
        >
          <Icon
            as={MdDashboard}
            color={theme.colors.exodus.primaryBlue}
            boxSize={6}
          />
          <Text
            fontSize={18}
            fontWeight={400}
            sx={{ color: theme.colors.exodus.primaryBlue }}
          >
            Dashboard
          </Text>
        </Stack>
      </AccordionButton>
    </AccordionItem>
  );
};

export default DashboardMenu;
