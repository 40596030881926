import { Box, Card, Spinner, Stack, Text, useTheme } from "@chakra-ui/react";
import React, { useContext } from "react";
import BiayaMurniSalesRatioChart from "./BiayaMurniSalesRatioChart";
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";
import dayjs from "dayjs";
import DashboardContext from "../context/DashboardContext";
import { FormProvider, useForm } from "react-hook-form";
import DateExodus from "../../../models/DateExodus";
import { useAppSelector } from "../../../redux/hooks";
import LoggedUser from "../../../models/common/LoggedUser";
import { ProjectEnum } from "../../../models/common/Project";

const BiayaMurniSalesRatioCard = (): JSX.Element => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  if (loggedUser?.projects.find((e) => e.value === ProjectEnum.Ethical)) {
    return <BiayaMurniSalesRatioCardContent />;
  } else {
    return <></>;
  }
};

const BiayaMurniSalesRatioCardContent: React.FC = () => {
  const theme = useTheme();
  const context = useContext(DashboardContext);
  const methods = useForm();

  const percentageBiayaMurniOverSalesValue =
    (
      (context?.biayaMurniSalesRatio?.totalCostMurni!! /
        context?.biayaMurniSalesRatio?.salesValue!!) *
      100
    ).toFixed(2) ?? null;

  return (
    <Card
      padding={{
        base: "14px",
        tablet: "24px",
      }}
      width={"100%"}
      backgroundColor={theme.colors.exodus.background}
      boxShadow="0px 7px 24px 4px #282c7a26"
      borderRadius={"16px"}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ tablet: "center" }}
        gap={{ base: 2, tablet: 6 }}
        flexDirection={{
          base: "column",
          tablet: "row",
        }}
      >
        <Text
          fontSize={{
            base: "16px",
            tablet: "24px",
          }}
          fontWeight={700}
          color={theme.colors.exodus.primaryBlue}
          textAlign={"start"}
        >
          Perbandingan Sales dan Biaya Murni
        </Text>
        <FormProvider {...methods}>
          <Box minWidth={"30%"}>
            <CustomDatePicker
              name={"month"}
              type={"month"}
              onChange={function (data: Date | null): void {
                if (data)
                  context.setSelectedMonth(
                    new DateExodus(data.getTime() / 1000)
                  );
                else context.setSelectedMonth(null);
              }}
              minDate={dayjs().startOf("year").toDate()}
              maxDate={dayjs().endOf("month").toDate()}
              placeholder="Periode terpilih"
              defaultValue={context.selectedMonth?.getDateObject()}
              selected={context.selectedMonth?.getDateObject()}
              isClearable
            />
          </Box>
        </FormProvider>
      </Box>
      {context.biayaMurniSalesRatioError && (
        <Text py={4} color={theme.colors.exodus.red}>
          {context.biayaMurniSalesRatioError}
        </Text>
      )}
      {context.biayaMurniSalesRatioLoading && (
        <Box py={4}>
          <Spinner />
        </Box>
      )}
      {!context.biayaMurniSalesRatioLoading && (
        <Box mt={"24px"} display={"flex"} flexDirection={"column"} gap={"4px"}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Text
              fontWeight={600}
              fontSize={{
                base: "12px",
                tablet: "16px",
              }}
              color={theme.colors.exodus.secondaryBlue}
            >
              {context.biayaMurniSalesRatio?.salesValueUI}
            </Text>
            <Text
              fontWeight={600}
              fontSize={{
                base: "12px",
                tablet: "16px",
              }}
              color={theme.colors.exodus.red}
            >
              {context.biayaMurniSalesRatio?.totalCostMurniUI}
            </Text>
          </Box>
          <Box>
            <BiayaMurniSalesRatioChart data={context.biayaMurniSalesRatio} />
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Text
              fontWeight={600}
              fontSize={{
                base: "12px",
                tablet: "16px",
              }}
              color={theme.colors.exodus.secondaryBlue}
            >
              Sales
            </Text>
            <Stack direction="column">
              <Text
                fontWeight={600}
                fontSize={{
                  base: "12px",
                  tablet: "16px",
                }}
                color={theme.colors.exodus.red}
                textAlign="right"
              >
                Biaya Murni
              </Text>
              <Text
                textAlign="right"
                color={theme.colors.exodus.darkGrey}
                fontWeight={600}
                fontSize={{
                  base: "12px",
                  tablet: "16px",
                }}
              >
                Persentase : {percentageBiayaMurniOverSalesValue}%
              </Text>
            </Stack>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default BiayaMurniSalesRatioCard;
