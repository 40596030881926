import { useEffect, useState } from "react";
import { ZoneListParam } from "../../dataProviders/common/Zone/ZoneParamType";
import ZoneModel from "../../models/Zone/ZoneModel";
import ZoneProvider from "../../dataProviders/common/Zone/ZoneProvider";

const useGetZoneList = (params: ZoneListParam) => {
  const [zoneData, setData] = useState<ZoneModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: ZoneModel[] | undefined =
          await ZoneProvider.getZoneList(params);

        setData(responseData!);
        setLoading(false);
      } catch (error: any) {
        setData([]);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    getData();
  }, [params.salesZoneType, params.userId, params.project]);

  return { zoneData, loading, errorMessage };
};

export default useGetZoneList;
