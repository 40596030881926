/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Input,
  Stack,
  Text,
  useMediaQuery,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import StepperControlFrame from "../../common/StepperControlFrame";
import { useStepper } from "../../../contexts/StepperContextProvider";
import KlaimSalesDivider from "../../common/KlaimSalesDivider";
import { FormProvider, useForm } from "react-hook-form";
import useGetAndSaveOutletKlaimSales from "../../SearchOutlet/services/useGetAndSaveOutletKlaimSales";
import { useEffect } from "react";
import useGetFakturByCode from "../../../services/useGetFakturByCode";
import DetailFakturKlaimSales from "./DetailFakturKlaimSales";
import ListFakturTerpilih from "./ListFakturTerpilih";
import useGetAndSaveFakturKlaimSales from "../services/useGetAndSaveFakturKlaimSales";

const SelectFakturKlaimSalesBase = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const {
    actions: { handleBack, handleNext },
  } = useStepper();

  const {
    getFakturByCode,
    fakturData,
    loading: fakturLoading,
  } = useGetFakturByCode();

  const { getStoredOutletKlaimSales, storedOutletPanel } =
    useGetAndSaveOutletKlaimSales();

  const {
    getStoredFakturKlaimSales,
    storedFakturData,
    setStoredFaktur,
    saveFakturKlaimSales,
    removeSavedFakturKlaimSales,
  } = useGetAndSaveFakturKlaimSales();

  const form = useForm();

  const handleSearchFaktur = (data: any) => {
    if (!data.nomor_faktur || data.nomor_faktur.trim() === "") return;

    getFakturByCode(
      {
        outlet_id: storedOutletPanel!.outletId,
        invoice: data.nomor_faktur.trim(),
      },
      {
        onSuccess: () => {},
        onError: (error) => {
          toast({
            title: error.message,
            status: "error",
          });
        },
      }
    );
  };

  const handleOnAddFaktur = () => {
    if (!fakturData) return;

    if (storedFakturData.find((data) => data.invoice === fakturData.invoice)) {
      toast({
        title: "Faktur sudah terpilih",
        status: "warning",
      });
      return;
    }

    setStoredFaktur([...storedFakturData, fakturData]);
    toast({
      title: "Faktur dipilih",
      status: "info",
    });
  };

  const handleOnRemoveFaktur = (salesHeaderId: number) => {
    setStoredFaktur(
      storedFakturData.filter((data) => data.salesHeaderId !== salesHeaderId)
    );
    toast({
      title: "Faktur dihapus",
      status: "info",
    });
  };

  const onHandleNext = () => {
    if (storedFakturData.length === 0) {
      toast({
        title: "Mohon pilih faktur terlebih dahulu",
        status: "warning",
      });
      return;
    }
    saveFakturKlaimSales({ selectedFaktur: storedFakturData });
    handleNext();
  };

  const onHandleBack = () => {
    removeSavedFakturKlaimSales();
    handleBack();
  };

  useEffect(() => {
    getStoredFakturKlaimSales().then(() => getStoredOutletKlaimSales());
  }, []);

  return (
    <StepperControlFrame
      onHandleBack={onHandleBack}
      onHandleNext={onHandleNext}
    >
      <Stack
        flexDirection={isDesktopView ? "row" : "column"}
        divider={
          <KlaimSalesDivider
            orientation={isDesktopView ? "vertical" : "horizontal"}
          />
        }
        height="100%"
        width="100%"
      >
        {/* BAGIAN KIRI */}
        <Card
          width="100%"
          bgColor={theme.colors.exodus.background}
          boxShadow="0px 7px 24px 4px #282c7a26"
          borderRadius="12px"
          color={theme.colors.exodus.fontDefault}
        >
          <CardBody>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(handleSearchFaktur)}>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Text
                    fontSize={isDesktopView ? 24 : 20}
                    fontWeight="semibold"
                    color={theme.colors.exodus.primaryBlue}
                  >
                    Input Nomor Faktur
                  </Text>
                  <Text fontSize={14} textAlign="left" paddingBottom="16px">
                    Cari No. Faktur yang tersedia pada outlet panel{" "}
                    {storedOutletPanel?.outletName}
                  </Text>
                  <Flex
                    direction="row"
                    width="100%"
                    paddingBottom={isDesktopView ? "24px" : "16px"}
                  >
                    <Input
                      {...form.register("nomor_faktur")}
                      placeholder="No. Faktur"
                      sx={{
                        borderRightRadius: 0,
                      }}
                    />
                    <Button
                      type="submit"
                      colorScheme="buttonPrimary"
                      isLoading={fakturLoading}
                      sx={{
                        borderLeftRadius: 0,
                      }}
                    >
                      Cari
                    </Button>
                  </Flex>
                  <DetailFakturKlaimSales
                    data={fakturData}
                    onAdd={handleOnAddFaktur}
                  />
                </Box>
              </form>
            </FormProvider>
          </CardBody>
        </Card>

        {/* BAGIAN KANAN */}
        <Box width="100%">
          <Text
            fontSize={isDesktopView ? 24 : 20}
            fontWeight="semibold"
            color={theme.colors.exodus.primaryBlue}
            textAlign="left"
          >
            Faktur yang Terpilih
          </Text>
          {/* <Box maxHeight="100vh" overflowY="auto"> */}
          <ListFakturTerpilih
            data={storedFakturData}
            onRemove={handleOnRemoveFaktur}
          />
          {/* </Box> */}
        </Box>
      </Stack>
    </StepperControlFrame>
  );
};

export default SelectFakturKlaimSalesBase;
