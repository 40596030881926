import {
  Stack,
  Card,
  HStack,
  Button,
  Box,
  useToast,
  Image,
  Text,
  useTheme,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useForgotPasswordStyle from "../services/useForgotPasswordStyle";
import pharosSvgLogo from "../../../assets/PharosLogo.svg";
import {
  EMAIL_FOOTER_INFORMATION,
  FAILED_TO_SEND_EMAIL,
  MODAL_SUCCESS_DESCRIPTION,
} from "./constants/constants";
import EmailField from "./components/EmailField";
import IsTypeOfErrorResponse from "../../../utils/IsTypeOfErrorResponse";
import usePostForgotPasswordEmail from "./services/usePostForgotPasswordEmail";
import OnSuccessModal from "../components/OnSuccessModal";
import { LOGIN_NAVIGATION_ROOT } from "../../../constants/common/RootConstants";

const EmailVerificationPage = () => {
  const methods = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isButtonLoading, setIsButtonLoading] = React.useState<boolean>(false);
  const [isSuccessModalShown, setIsSuccessModalShown] =
    React.useState<boolean>(false);

  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const { rootStyle, emailVerificationCardStyle, logoStyle } =
    useForgotPasswordStyle();

  const handleOnChangeIsSuccessModalShown = (e: boolean) => {
    setIsSuccessModalShown(e);
  };

  const handleOnError = (error: unknown) => {
    if (IsTypeOfErrorResponse(error)) {
      toast({
        title: FAILED_TO_SEND_EMAIL,
        description: `${error.status} ${error.code} ${error.message}`,
        status: "error",
      });
    } else {
      toast({
        title: FAILED_TO_SEND_EMAIL,
        description: `${error}`,
        status: "error",
      });
    }
  };

  const handleOnSubmit = async (e: FieldValues) => {
    setIsButtonLoading(true);

    try {
      await usePostForgotPasswordEmail({
        email: e.email,
      });
      setIsSuccessModalShown(true);
    } catch (error: unknown) {
      handleOnError(error);
    } finally {
      setIsButtonLoading(false);
    }
  };

  return (
    <Stack sx={rootStyle}>
      <Card sx={emailVerificationCardStyle}>
        <Box sx={logoStyle}>
          <Image
            src={pharosSvgLogo}
            alt="pharosSvgLogo"
            boxSize={isDesktopView ? "150px" : "80px"}
          />
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
            <EmailField />
            <HStack
              sx={{
                mb: `${isDesktopView ? 4 : 0}`,
                display: "flex",
              }}
            >
              <Text
                color={theme.colors.exodus.primaryBlue}
                fontSize={16}
                fontWeight={400}
                sx={{
                  textAlign: "left",
                }}
              >
                {EMAIL_FOOTER_INFORMATION}
              </Text>
            </HStack>
            <Button
              mt={10}
              colorScheme="buttonSuccess"
              isLoading={isButtonLoading}
              type="submit"
              sx={{ width: "100%" }}
              size={isDesktopView ? "lg" : "md"}
            >
              <Text fontWeight={600} fontSize={16}>
                Kirim link verifikasi
              </Text>
            </Button>
          </form>
        </FormProvider>
        <HStack sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
            colorScheme="buttonPrimary"
            sx={{ width: "100%" }}
            variant="outline"
            onClick={() => navigate(LOGIN_NAVIGATION_ROOT)}
            size={isDesktopView ? "lg" : "md"}
          >
            <Text
              fontWeight={600}
              fontSize={16}
              color={theme.colors.exodus.secondaryBlue}
            >
              Kembali ke halaman awal
            </Text>
          </Button>
        </HStack>
      </Card>
      <OnSuccessModal
        isSuccessModalShown={isSuccessModalShown}
        handleOnChangeIsSuccessModalShown={handleOnChangeIsSuccessModalShown}
        descriptionText={MODAL_SUCCESS_DESCRIPTION}
        isCloseButtonNavigateToLogin={false}
      />
    </Stack>
  );
};

export default EmailVerificationPage;
