import { useCallback, useEffect, useState } from "react";
import { COMMON_PAGINATION_LIMIT_CHOICES } from "../../../../../constants/components/PaginationConstants";
import { OutletCardListPagination } from "../models/OutletCardListFilterType";
import ErrorResponse from "../../../../../models/common/ErrorResponse";
import IsTypeOfErrorResponse from "../../../../../utils/IsTypeOfErrorResponse";
import OutletCardProvider from "../../dataProviders/OutletCardProvider";

const initialValue: OutletCardListPagination = {
  page: 1,
  limit: COMMON_PAGINATION_LIMIT_CHOICES[0].value,
};

const useOutletCardListPaginate = () => {
  const [paginate, setPaginate] = useState<OutletCardListPagination | null>(
    null
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  const initializePagination = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const databaseResponse: OutletCardListPagination | null | undefined =
        await OutletCardProvider.getIndexDbPagination();
        
      if (databaseResponse) {
        setPaginate(databaseResponse);
      } else {
        setPaginate(initialValue);
      }
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error as ErrorResponse);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    initializePagination();
  }, []);

  const savePagination = async (newPaginateParam: OutletCardListPagination) => {
    try {
      setLoading(true);
      setError(null);
      await OutletCardProvider.saveIndexDbPagination(newPaginateParam);
      setPaginate(newPaginateParam);
    } catch (error) {
      if (IsTypeOfErrorResponse(error)) {
        setError(error as ErrorResponse);
      }
    } finally {
      setLoading(false);
    }
  };

  return { paginate, savePagination, isLoading, error };
};

export default useOutletCardListPaginate;
