import { useEffect, useState } from "react";
import ProductModel from "../../models/Product/ProductModel";
import ProductProvider from "../../dataProviders/common/Product/ProductProvider";

const useGetAllProducts = () => {
  const [productData, setData] = useState<ProductModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: ProductModel[] | undefined =
          await ProductProvider.getAllProducts();

        setData(responseData!);
        setLoading(false);
      } catch (error: any) {
        setData([]);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    getData();
  }, []);

  return { productData, loading, errorMessage };
};

export default useGetAllProducts;
