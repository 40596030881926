import { AxiosResponse } from "axios";
import AxiosBaseClient from "../../config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import { ZoneListParam, PaginatedListGTbyGTNameParam } from "./ZoneParamType";
import ZoneModel from "../../../models/Zone/ZoneModel";
import { PaginatedGTRecord } from "../../../models/Zone/PaginatedGTModel";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const ZoneProvider = {
  getZoneList: async (params: ZoneListParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/user/zone",
        {
          params: {
            user_id: params.userId,
            project: params.project?.value,
            sales_zone_type: params.salesZoneType,
          },
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const zoneData: ZoneModel[] = response.data.data.map((json: any) => {
            return {
              salesZoneId: json["sales_zone_id"],
              salesZoneType: json["sales_zone_type"],
              salesZoneName: json["sales_zone_name"],
            };
          });
          return Promise.resolve(zoneData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getGTByGTName: async (params: PaginatedListGTbyGTNameParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/group_territories/search",
        {
          params: {
            limit: params.limit,
            name_query: params.nameQuery,
            page: params.page,
            project: params.project?.value,
          },
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const gtData: PaginatedGTRecord[] = response.data.data.records.map(
            (json: any) => {
              return {
                id: json["id"],
                name: json["name"],
                areaId: json["area_id"],
                areaName: json["area_name"],
                isVacant: json["is_vacant"],
                assignedUserId: json["assigned_user_id"],
                assignedUserName: json["assigned_user_name"],
                groupTerritoryCode: json["groupterritory_code"],
              };
            }
          );
          return Promise.resolve(gtData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
};

export default ZoneProvider;
