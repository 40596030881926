import { useFormContext } from "react-hook-form";
import SalesOutletListFilterModel from "../../../../../../models/SalesOutletListFilterModel";
import FilterFieldFrame from "../FilterFieldFrame";
import CustomMultiselect from "../../../../../../../../../../../components/CustomMultiselectDropdown/CustomMultiselect";
import useGetOutletByUserId from "../../../../../../../../../../../services/Outlet/useGetOutletByUserId";
import { Box, HStack, Spinner, Text, VStack, useTheme } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { debounce } from "lodash";

const FilterByOutlets = () => {
  const theme = useTheme();

  const formContext = useFormContext<SalesOutletListFilterModel>();
  const watchPekerja = formContext.watch("pekerja");
  const watchZona = formContext.watch("zona");
  const watchSektor = formContext.watch("sector");
  const watchOutlets = formContext.watch("outlets");
  const [searched, setSearched] = useState<string | undefined>();

  const { outletData, error, loading } = useGetOutletByUserId({
    params: { userId: watchPekerja?.id, keyword: searched },
  });

  const debouncedOnSearch = useRef(
    debounce(async (value: string | undefined) => {
      if (value && (value.length === 0 || value.length >= 3)) {
        setSearched(value);
      }
    }, 300)
  ).current;

  return (
    <FilterFieldFrame title="Nama Outlet">
      <VStack width={"100%"} gap={0} alignItems={"stretch"}>
        <HStack width={"100%"} gap={4}>
          {loading ? (
            <Spinner color={theme.colors.exodus.primaryBlue} />
          ) : (
            <></>
          )}
          <Box width={"100%"}>
            <CustomMultiselect
              name={"outletsDummy"}
              options={outletData}
              displayValue={"outletLabel"}
              placeholder={"Nama Outlet - Kode Outlet"}
              onChange={function (event: any): void {
                formContext.setValue("outlets", event);
                setSearched(undefined);
              }}
              onSearch={(value) => {
                debouncedOnSearch(value);
              }}
              defaultValue={watchOutlets}
              disabled={watchZona !== undefined || watchSektor !== undefined}
            />
          </Box>
        </HStack>
        {error ? (
          <Text
            color={theme.colors.exodus.red}
            textAlign={"left"}
            pl={2}
            fontSize={14}
          >
            {error.message}
          </Text>
        ) : (
          <></>
        )}
      </VStack>
    </FilterFieldFrame>
  );
};

export default FilterByOutlets;
