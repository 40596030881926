import {
  Accordion,
  Center,
  Divider,
  HStack,
  Spacer,
  Spinner,
  Stack,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import FilterBoxArea from "../common/FilterBoxArea";
import { isEmpty } from "lodash";
import { useOutletCardListContext } from "../../contexts/OutletCardListContextProvider";
import ShowFilterButton from "../../../../../../components/CustomButtons/ShowFilterButton";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import { GetListOutletCardModelData } from "../../../models/GetListOutletCardModel";
import Pagination from "../../../../../../components/Pagination/Pagination";
import OutletCardFilterModal from "../common/OutletCardFilterModal";
import ExportExcelButton from "../../../../../../components/CustomButtons/ExportExcelButton";
import OutletCardListAccordionItem from "./components/OutletCardListAccordionItem";

const OutletCardListMobile = () => {
  const theme = useTheme();
  const [isFilterModalOpen, setIsFilterModalOpen] =
    React.useState<boolean>(false);

  const handleOnFilterModalChange = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const {
    states: { isLoadingList, data, metadata, isExportLoading },
    actions: { setLimit, setPage, exportExcel },
  } = useOutletCardListContext();

  const RenderLoading = () => {
    return (
      <Center>
        <Spinner
          marginTop={6}
          thickness="4px"
          speed="1s"
          emptyColor={theme.colors.exodus.background}
          color={theme.colors.exodus.primaryBlue}
          size="xl"
        />
      </Center>
    );
  };

  return (
    <Stack
      minHeight="90vh"
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Stack>
        <Stack justifyContent="space-between" flexDirection={"row"}>
          <FilterBoxArea />
          <ShowFilterButton onClick={() => handleOnFilterModalChange()} />
        </Stack>
        <Divider
          sx={{
            border: "1px",
            color: theme.colors.exodus.primaryBlue,
            width: "100%",
          }}
        />
        {isLoadingList ? (
          <RenderLoading />
        ) : isEmpty(data) ? (
          <DataNotFound />
        ) : (
          <>
            <Accordion allowMultiple>
              {data?.map(
                (eachData: GetListOutletCardModelData, key: number) => (
                  <OutletCardListAccordionItem record={eachData} key={key} />
                )
              )}
            </Accordion>
            <HStack justifyContent={"center"}>
              <Pagination
                handleOnBack={() => setPage("prev")}
                handleOnLimitChange={setLimit}
                handleOnNavigateFirstPage={() => setPage("first")}
                handleOnNext={() => setPage("next")}
                limit={metadata?.totalDataPerPage}
                page={metadata?.currentPage}
                showCurrentPageDetail
                showLimit
                showNavigateFirstPage
                totalData={metadata?.totalData}
                totalPage={metadata?.totalPage}
              />
            </HStack>
          </>
        )}
      </Stack>
      <Spacer />
      <Stack
        spacing={1}
        sx={{
          position: "sticky",
          pt: 2,
          pb: 2,
          bottom: 0,
          backgroundColor: theme.colors.exodus.background,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ExportExcelButton onClick={exportExcel} isLoading={isExportLoading} />
      </Stack>
      <OutletCardFilterModal
        isOpen={isFilterModalOpen}
        onClose={handleOnFilterModalChange}
      />
    </Stack>
  );
};

export default OutletCardListMobile;
