/* eslint-disable @typescript-eslint/no-explicit-any */
class FakturByCodeModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: FakturByCodeModelData;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = new FakturByCodeModelData(json["data"]);
  }
}

class FakturByCodeModelData {
  salesHeaderId!: number;
  invoice!: string;
  distributorId!: number;
  distributorName!: string;
  totalSales!: number;
  outletCode!: string;
  salesDetail!: FakturByCodeModelDetailData[];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const temp: FakturByCodeModelDetailData[] = [];

    if (json["sales_detail"] !== null) {
      for (let i = 0; i < json.sales_detail.length; i++) {
        temp.push(new FakturByCodeModelDetailData(json.sales_detail[i]));
      }
    }

    this.salesHeaderId = json["sales_header_id"];
    this.invoice = json["invoice"];
    this.distributorId = json["distributor_id"];
    this.distributorName = json["distributor_name"];
    this.totalSales = json["total_sales"];
    this.outletCode = json["outlet_code"];
    this.salesDetail = temp;
  }
}

class FakturByCodeModelDetailData {
  salesDetailId!: number;
  productBridgingId!: number;
  productName!: string;
  qty!: number;
  salePrices!: number;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.salesDetailId = json["sales_detail_id"];
    this.productBridgingId = json["product_bridging_id"];
    this.productName = json["product_name"];
    this.qty = json["qty"];
    this.salePrices = json["sale_prices"];
  }
}

export {
  FakturByCodeModelPack,
  FakturByCodeModelData,
  FakturByCodeModelDetailData,
};
