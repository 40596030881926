import {
  VStack,
  Center,
  Spinner,
  useTheme,
  Text,
  HStack,
  Box,
} from "@chakra-ui/react";
import DataNotFound from "../../../../../../../components/DataNotFound/DataNotFound";
import ErrorPage from "../../../../../../../components/ErrorPage/ErrorPage";
import PageTitle from "../../../../../../../components/PageTitle/PageTitle";
import { useSalesOutletListContext } from "../../contexts/SalesOutletListContext";
import SalesOutletTable from "./parts/SalesOutletTable";
import SalesOutletSummarySection from "../common/SalesOutletSummarySection";
import SalesOutletExportExcelButton from "../common/SalesOutletExportExcelButton";
import SalesOutletFilter from "../common/filter/SalesOutletFilter";

const SalesOutletPageDesktop = (): JSX.Element => {
  const theme = useTheme();

  const {
    states: { data, error, loading },
  } = useSalesOutletListContext();

  if (loading) {
    return (
      <PageFrameWithFilter
        child={
          <Center p={10}>
            <VStack>
              <Spinner color={theme.colors.exodus.primaryBlue} />
              <Text>Loading daftar Sales Outlet</Text>
            </VStack>
          </Center>
        }
      />
    );
  }

  if (error) {
    return (
      <PageFrameWithFilter child={<ErrorPage message={error.message} />} />
    );
  }

  if (data) {
    return <PageFrameWithFilter child={<SalesOutletTable />} />;
  }

  return <PageFrameWithFilter child={<DataNotFound />} />;
};

const PageFrameWithFilter = ({
  child,
}: {
  child: JSX.Element;
}): JSX.Element => {
  return (
    <VStack alignItems={"stretch"}>
      <PageTitle label="Sales Outlet" />
      <HStack alignItems={"flex-start"} pr={4}>
        <Box flex={1}>
          <SalesOutletFilter />
        </Box>
        <SalesOutletExportExcelButton />
      </HStack>
      <SalesOutletSummarySection />
      {child}
    </VStack>
  );
};

export default SalesOutletPageDesktop;
