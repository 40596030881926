import { useEffect, useState } from "react";
import { Wrap } from "@chakra-ui/react";
import FilterBox from "../../../../../../../../../../components/FilterBox/FilterBox";
import { useSalesOutletListContext } from "../../../../../contexts/SalesOutletListContext";
import LoggedUser from "../../../../../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../../../../../redux/hooks";

const SalesOutletFilterBoxes = (): JSX.Element => {
  const {
    states: { filterValues },
  } = useSalesOutletListContext();

  const [boxes, setBoxes] = useState<JSX.Element[]>([]);

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  useEffect(() => {
    const _boxes: JSX.Element[] = [];
    if (filterValues) {
      if (filterValues.periode.akhir && filterValues.periode.awal) {
        _boxes.push(
          <FilterBox
            key={1}
            title="Periode"
            content={`${filterValues.periode.awal.getStringDateFormat(
              "MMMM YYYY"
            )} - ${filterValues.periode.akhir.getStringDateFormat(
              "MMMM YYYY"
            )}`}
          />
        );
      }
      if (filterValues.project) {
        _boxes.push(
          <FilterBox
            key={2}
            title="Project"
            content={
              loggedUser?.projects.find((e) => e.value === filterValues.project)
                ?.label ?? "-"
            }
          />
        );
      }
      if (filterValues.jabatan) {
        _boxes.push(
          <FilterBox
            key={3}
            title="Jabatan"
            content={filterValues.jabatan.label}
          />
        );
      }
      if (filterValues.pekerja) {
        _boxes.push(
          <FilterBox
            key={4}
            title="Nama Pekerja"
            content={`${filterValues.pekerja.nip} - ${filterValues.pekerja.name}`}
          />
        );
      }
      if (filterValues.zona) {
        _boxes.push(
          <FilterBox
            key={5}
            title="Zona"
            content={filterValues.zona.salesZoneName}
          />
        );
      }
      if (filterValues.sector) {
        _boxes.push(
          <FilterBox
            key={6}
            title="Sektor"
            content={filterValues.sector.label}
          />
        );
      }
      if (filterValues.outlets) {
        for (let i = 0; i < filterValues.outlets.length; i++) {
          _boxes.push(
            <FilterBox
              key={7 + i}
              title="Nama Outlet"
              content={filterValues.outlets[i].outletName}
            />
          );
        }
      }
    }
    setBoxes(_boxes);
  }, [filterValues]);

  return <Wrap>{boxes}</Wrap>;
};

export default SalesOutletFilterBoxes;
