import {
  Grid,
  GridItem,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { EditableSalesDetail } from "../../PilihProduk/models/EditableFakturModel";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";

type Props = {
  data: EditableSalesDetail;
  isRowColored?: boolean;
};

const SummaryKlaimSalesCardItem: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Grid
      backgroundColor={
        props.isRowColored
          ? theme.colors.exodus.tableDataBackground
          : theme.colors.exodus.background
      }
      paddingY="6px"
      templateColumns="repeat(12, 1fr)"
      gap={2}
      alignItems="center"
    >
      <GridItem colSpan={9}>
        <Text
          fontSize={isDesktopView ? 16 : 12}
          color={theme.colors.exodus.primaryBlue}
          fontWeight="semibold"
        >
          {props.data.originalData.productName}
        </Text>
        <Text fontSize={isDesktopView ? 14 : 12}>
          {CurrencyIdrFormatter.convertToRp(props.data.originalData.salePrices)}
        </Text>
      </GridItem>
      <GridItem colSpan={3} fontSize={isDesktopView ? 16 : 12}>
        {props.data.selectedQty} / {props.data.originalData.qty}
      </GridItem>
    </Grid>
  );
};

export default SummaryKlaimSalesCardItem;
