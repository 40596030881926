import { Text, VStack, useMediaQuery, useTheme } from "@chakra-ui/react";

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type FilterFieldFrame = _WithChildren<{ title: string }>;

const FilterFieldFrame = ({
  title,
  children,
}: FilterFieldFrame): JSX.Element => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  return (
    <VStack sx={{ width: "100%" }} alignItems={"stretch"} gap={1}>
      <Text
        fontSize={isMobile ? 14 : 16}
        fontWeight={600}
        color={theme.colors.exodus.primaryBlue}
        textAlign={"start"}
      >
        {title}
      </Text>
      {children}
    </VStack>
  );
};

export default FilterFieldFrame;
