import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  VStack,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useOutletCardDetailContext } from "../../contexts/OutletCardDetailContextProvider";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";
import { IoMdEye } from "react-icons/io";
import BiayaDasarBoxSmallBreakpoint from "../mobile/components/BiayaDasarBoxSmallBreakpoint";

const BiayaDasarHeaderArea = ({
  handleOpenModal,
}: {
  handleOpenModal: Function;
}) => {
  const theme = useTheme();
  const {
    states: {
      outletCardDetail,
      isLoadingGetOutletCardDetail,
      errorGetOutletCardDetail,
    },
  } = useOutletCardDetailContext();

  const [isWidthAbove470Px] = useMediaQuery(`(min-width: 470px)`);

  const formattedSisaSaldo = CurrencyIdrFormatter.convertToRp(
    outletCardDetail?.sisaSaldo ?? 0
  );

  if (isLoadingGetOutletCardDetail) {
    return (
      <GridItem
        sx={{
          borderRadius: 12,
          border: `1px solid ${theme.colors.exodus.primaryBlue}`,
        }}
        w="100%"
        h={200}
      >
        <HStack justifyContent="center" mt={6}>
          <Spinner
            thickness="4px"
            speed="1s"
            emptyColor={theme.colors.exodus.background}
            color={theme.colors.exodus.primaryBlue}
            size="xl"
          />
        </HStack>
      </GridItem>
    );
  }

  if (!isWidthAbove470Px) {
    return (
      <BiayaDasarBoxSmallBreakpoint
        title="Biaya Dasar"
        firstText={"Biaya Dasar"}
        secondText={"Saldo Kontribusi"}
        thirdText={"Sisa Saldo"}
        firstValue={CurrencyIdrFormatter.convertToRp(
          outletCardDetail?.biayaDasar.totalCost ?? 0
        )}
        secondValue={CurrencyIdrFormatter.convertToRp(
          outletCardDetail?.saldoKontribusi ?? 0
        )}
        thirdValue={outletCardDetail?.sisaSaldo}
        handleOnClick={() => handleOpenModal()}
      />
    );
  }

  return (
    <GridItem
      sx={{
        borderRadius: 12,
        border: `1px solid ${theme.colors.exodus.primaryBlue}`,
      }}
      w="100%"
      h={200}
    >
      {errorGetOutletCardDetail ? (
        <VStack justifyContent="center" mt={6}>
          <Text fontSize={18} fontWeight={600} color={theme.colors.exodus.red}>
            {errorGetOutletCardDetail.code} {errorGetOutletCardDetail.status}
          </Text>
          <Text fontSize={18} fontWeight={600} color={theme.colors.exodus.red}>
            {errorGetOutletCardDetail.message}
          </Text>
        </VStack>
      ) : (
        <Grid templateColumns="repeat(10, 1fr)" px={4} py={4} h={"100%"}>
          <GridItem colSpan={10} mb={1}>
            <Text
              display="flex"
              fontSize={18}
              fontWeight={600}
              color={theme.colors.exodus.primaryBlue}
            >
              Biaya Dasar
            </Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Text
              display="flex"
              fontSize={16}
              fontWeight={400}
              color={theme.colors.exodus.primaryBlue}
            >
              Biaya Dasar
            </Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Stack justifyContent="space-between" direction="row">
              <Text
                display="flex"
                justifyContent="flex-end"
                fontSize={16}
                fontWeight={400}
                color={theme.colors.exodus.darkGrey}
              >
                :
              </Text>
              <Text
                display="flex"
                justifyContent="flex-end"
                fontSize={16}
                fontWeight={400}
                color={theme.colors.exodus.darkGrey}
              >
                {CurrencyIdrFormatter.convertToRp(
                  outletCardDetail?.biayaDasar.totalCost ?? 0
                )}
              </Text>
            </Stack>
          </GridItem>
          <GridItem colSpan={7}>
            <Text
              display="flex"
              fontSize={16}
              fontWeight={400}
              color={theme.colors.exodus.primaryBlue}
            >
              Saldo Kontribusi
            </Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Stack justifyContent="space-between" direction="row">
              <Text
                display="flex"
                justifyContent="flex-end"
                fontSize={16}
                fontWeight={400}
                color={theme.colors.exodus.darkGrey}
              >
                :
              </Text>
              <Text
                display="flex"
                justifyContent="flex-end"
                fontSize={16}
                fontWeight={400}
                color={theme.colors.exodus.darkGrey}
              >
                {CurrencyIdrFormatter.convertToRp(
                  outletCardDetail?.saldoKontribusi ?? 0
                )}
              </Text>
            </Stack>
          </GridItem>
          <GridItem colSpan={7}>
            <Text
              display="flex"
              fontSize={16}
              fontWeight={400}
              color={theme.colors.exodus.primaryBlue}
            >
              Sisa Saldo
            </Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Stack justifyContent="space-between" direction="row">
              <Text
                display="flex"
                justifyContent="flex-end"
                fontSize={16}
                fontWeight={400}
                color={theme.colors.exodus.darkGrey}
              >
                :
              </Text>
              <Text
                display="flex"
                justifyContent="flex-end"
                fontSize={16}
                fontWeight={400}
                color={theme.colors.exodus.darkGrey}
              >
                {outletCardDetail?.sisaSaldo && outletCardDetail?.sisaSaldo < 0
                  ? `(${formattedSisaSaldo})`
                  : formattedSisaSaldo}
              </Text>
            </Stack>
          </GridItem>
          <GridItem colSpan={10} mt={2}>
            <Box display="flex" flexDirection={"row"} justifyContent="flex-end">
              <Button
                colorScheme="buttonSecondary"
                variant={"ghost"}
                onClick={() => handleOpenModal()}
              >
                <Text
                  fontSize={16}
                  fontWeight={600}
                  color={theme.colors.exodus.secondaryBlue}
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <Icon as={IoMdEye} boxSize={6} />
                  Lihat Detail
                </Text>
              </Button>
            </Box>
          </GridItem>
        </Grid>
      )}
    </GridItem>
  );
};

export default BiayaDasarHeaderArea;
