import { useEffect, useState } from "react";
import ApprovalRencanaKunjunganContext from "../context/ApprovalRencanaKunjunganContext";
import ApprovalRencanaKunjunganPage from "./ApprovalRencanaKunjunganPage";
import useGetListApproval from "../../../services/useGetListApproval";
import AllPeriodeDataModel from "../../../models/AllPeriodeDataModel";
import useGetAllPeriodeList from "../../../services/useGetAllPeriodeList";
import ApprovalRencanaFilterValues from "../models/ApprovalRencanaFilterValues";
import useTitle from "../../../../../services/useTitle";
import IndexedDatabase from "../../../../../database/IndexDB";
import ApprovalRencanaMetadata from "../models/ApprovalRencanaMetadata";
import RencanaKunjunganProvider from "../../dataProviders/RencanaKunjunganProvider";
import ProjectType from "../../../../../models/common/ProjectType";
import { isEmpty } from "lodash";

const ApprovalRencanaKunjunganBase: React.FC = () => {
  const [selectedPeriode, setSelectedPeriode] =
    useState<AllPeriodeDataModel | null>(null);

  const [page, setPage] = useState<number | null>(1);
  const [limit, setLimit] = useState<number | null>(20);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<
    ApprovalRencanaFilterValues | null | undefined
  >(null);
  const [jabatan, setJabatan] = useState<string | null | undefined>(
    filterValues?.jabatan?.label
  );
  const [nama, setNama] = useState<string | null | undefined>(
    filterValues?.bawahan?.name
  );
  const [status, setStatus] = useState<string | null | undefined>(
    filterValues?.status?.status_label
  );
  const [project, setProject] = useState<ProjectType | null | undefined>(
    filterValues?.project
  );
  const [indexedDBData, setIndexedDBData] = useState<
    ApprovalRencanaFilterValues[]
  >([]);
  const [metadataValue, setMetadataValue] =
    useState<ApprovalRencanaMetadata | null>(null);
  const [loading, setLoading] = useState(true);

  useTitle("Approval Rencana Kunjungan");

  const {
    allPeriodeData,
    loading: periodeLoading,
    errorMessage: periodeError,
  } = useGetAllPeriodeList();


  const {
    approvalData,
    metadata,
    loading: draftRencanaLoading,
    errorMessage: draftRencanaError,
  } = useGetListApproval({
    page: page!,
    limit: limit!,
    start_period: selectedPeriode?.start_period ?? null,
    end_period: selectedPeriode?.end_period ?? null,
    kunjungan: "Rencana",
    status: filterValues?.status?.status_label,
    jabatan: filterValues?.jabatan?.label,
    userId: filterValues?.bawahan?.id,
    project: filterValues?.project,
  });

  const getIndexedDBData = async () => {
    const data = await IndexedDatabase.rencanaKunjunganFilter.toArray();
    const metadataValue =
      await RencanaKunjunganProvider.getMetadataApprovalRencana();
    setLoading(false);
    setMetadataValue(metadataValue);
    setIndexedDBData(data ?? null);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getIndexedDBData();
    };

    fetchData();
  }, []);

  //intital value untuk selected periode
  useEffect(() => {
    const fetchData = async () => {
      if (allPeriodeData.length > 0) {
        if (!isEmpty(indexedDBData) && !loading) {
          setSelectedPeriode(
            allPeriodeData.filter(
              (item) =>
                item.start_period.getEpochDate() ===
                (indexedDBData[0]?.periode?.start_period as any)
            )[0]
          );
        } else {
          setSelectedPeriode(allPeriodeData[0]);
        }
      }
    };

    fetchData();
  }, [allPeriodeData, indexedDBData]);

  useEffect(() => {
    setJabatan(filterValues?.jabatan?.label);
    setNama(filterValues?.bawahan?.name);
    setStatus(filterValues?.status?.status_label);
    setProject(filterValues?.project);
  }, [filterValues]);

  useEffect(() => {
    if (indexedDBData) {
      const savedFilter = new ApprovalRencanaFilterValues({
        jabatan: indexedDBData[0]?.jabatan,
        bawahan: indexedDBData[0]?.bawahan,
        status: indexedDBData[0]?.status,
        periode: indexedDBData[0]?.periode?.start_period && indexedDBData[0]?.periode?.end_period && {
          start_period: indexedDBData[0]?.periode.start_period,
          end_period: indexedDBData[0]?.periode.end_period,
        },
        project: indexedDBData[0]?.project,
      });

      setFilterValues(savedFilter);
    }
  }, [indexedDBData]);

  useEffect(() => {
    const fetchData = async () => {
      if (metadataValue !== null && !loading) {
        setPage(metadataValue.currentPage);
        setLimit(metadataValue.limit);
      } else if (
        metadata !== null &&
        (metadataValue === undefined || metadataValue === null)
      ) {
        setPage(1);
        setLimit(20);
      }
    };

    fetchData();
  }, [metadataValue]);

  return (
    <ApprovalRencanaKunjunganContext.Provider
      value={{
        periodeData: allPeriodeData,
        periodeLoading: periodeLoading,
        periodeError: periodeError,
        page: page,
        limit: limit,
        setLimit: setLimit,
        setPage: setPage,
        jabatan: jabatan,
        setJabatan: setJabatan,
        nama: nama,
        setNama: setNama,
        status: status,
        setStatus: setStatus,
        draftApprovalRencanaKunjunganData: approvalData,
        draftRencanaLoading: draftRencanaLoading,
        draftRencanaError: draftRencanaError,
        isDialogOpen: isDialogOpen,
        setIsDialogOpen: setIsDialogOpen,
        filterValues: filterValues,
        setFilterValues: setFilterValues,
        selectedPeriode: selectedPeriode,
        setSelectedPeriode: setSelectedPeriode,
        metadata: metadata,
        project: project,
        setProject: setProject,
      }}
    >
      <ApprovalRencanaKunjunganPage />
    </ApprovalRencanaKunjunganContext.Provider>
  );
};

export default ApprovalRencanaKunjunganBase;
