import {
  FormControl,
  FormErrorMessage,
  Stack,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DateExodus from "../../../../../../models/DateExodus";
import ApprovalKlaimSalesContext from "../../../contexts/ApprovalKlaimSalesContext";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import CustomDatePicker from "../../../../../../components/DatePicker/CustomDatePicker";
import { isEmpty } from "lodash";

const PeriodeFormFieldKlaim: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const {
    setValue,
    watch,
    register,
    formState: { errors },
  } = useFormContext();
  const watchId = watch("idKlaimSales");
  const { filterValues } = useContext(ApprovalKlaimSalesContext);

  const [tempStartDate, setTempStartDate] = useState<DateExodus | null>(
    filterValues?.startAt ? new DateExodus(filterValues.startAt) : null
  );
  const [tempEndDate, setTempEndDate] = useState<DateExodus | null>(
    filterValues?.endAt ? new DateExodus(filterValues.endAt) : null
  );

  useEffect(() => {
    setValue("startAtKlaimSales", tempStartDate?.getDateObject());
    setValue("endAtKlaimSales", tempEndDate?.getDateObject());
  }, []);

  return (
    <Stack gap={0}>
      <FormInputLabel title="Tanggal Pengajuan" />
      <Stack gap={4} direction={isDesktopView ? "row" : "column"}>
        <FormControl width={"100%"} isInvalid={!!errors.startAtKlaimSales}>
          <CustomDatePicker
            placeholder="Periode Awal"
            {...register("startAtKlaimSales", {
              required: !isEmpty(watchId) ? false : "Required",
            })}
            type={"month_year"}
            defaultValue={tempStartDate?.getDateObject()}
            onChange={(data) => {
              if (data) {
                setTempStartDate(DateExodus.fromDateObject(data));
                setValue("startAtKlaimSales", DateExodus.fromDateObject(data));
              }
            }}
            name={"startAtKlaimSales"}
            maxDate={tempEndDate?.getDateObject()}
            disabled={!isEmpty(watchId)}
          />
          <FormErrorMessage color={theme.colors.exodus.red}>
            {errors.startAtKlaimSales &&
              (errors.startAtKlaimSales.message as string)}
          </FormErrorMessage>
        </FormControl>
        <FormControl width={"100%"} isInvalid={!!errors.endAtKlaimSales}>
          <CustomDatePicker
            placeholder="Periode Akhir"
            type={"month_year"}
            defaultValue={tempEndDate?.getDateObject()}
            onChange={(data) => {
              if (data) {
                setTempEndDate(DateExodus.fromDateObject(data).endOf("month"));
                setValue(
                  "endAtKlaimSales",
                  DateExodus.fromDateObject(data).endOf("month")
                );
              }
            }}
            name={"endAtKlaimSales"}
            minDate={tempStartDate?.getDateObject()}
            disabled={!isEmpty(watchId)}
          />
          <FormErrorMessage color={theme.colors.exodus.red}>
            {errors.endAtKlaimSales &&
              (errors.endAtKlaimSales.message as string)}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default PeriodeFormFieldKlaim;
