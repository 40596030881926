import {
  Box,
  Card,
  CardBody,
  Flex,
  Spinner,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useContext } from "react";
import MonitorKunjunganUserListContext from "../../context/MonitorKunjunganUserListContext";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import UserModel from "../../../../../../models/Users/UserModel";
import ButtonLihatDetailMonitorKunjungan from "../ButtonLihatDetailMonitorKunjungan";

const MobileTableListUser: React.FC = () => {
  const theme = useTheme();

  const { userData, userLoading, userErrorMessage } = useContext(
    MonitorKunjunganUserListContext
  );

  if (userLoading) {
    return (
      <Box paddingTop="24px" paddingBottom="15px">
        <Spinner size="lg" color={theme.colors.exodus.primaryBlue} />
      </Box>
    );
  }

  if (userErrorMessage) {
    return (
      <Box paddingTop="24px" paddingBottom="15px">
        <ErrorPage message={userErrorMessage.toString()} />
      </Box>
    );
  }

  return userData.length > 0 ? (
    <Box paddingTop="24px" paddingBottom="15px">
      {userData.map((item: UserModel) => (
        <Card
          variant="unstyled"
          marginBottom="8px"
          paddingY="15px"
          backgroundColor={theme.colors.exodus.background}
          sx={{
            _hover: {
              cursor: "pointer",
              backgroundColor: theme.colors.exodus.lightGrey,
            },
          }}
        >
          <CardBody>
            <Flex justify="space-between" align="center">
              <Box textAlign="left">
                <Text
                  fontSize={14}
                  color={theme.colors.exodus.primaryBlue}
                  fontWeight="bold"
                >
                  {item.name} ({item.nip})
                </Text>
                <Text fontSize={12} color={theme.colors.exodus.fontDefault}>
                  {item.role.label}
                </Text>
              </Box>
              <ButtonLihatDetailMonitorKunjungan bawahanId={item.id} />
            </Flex>
          </CardBody>
        </Card>
      ))}
    </Box>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <DataNotFound />
    </Box>
  );
};

export default MobileTableListUser;
