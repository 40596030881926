import {
  Flex,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
  Text,
  ModalBody,
  Button,
  Textarea,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

type Props = {
  openComment: boolean;
  onClose: () => void;
  isReject: boolean;
  onSubmit: (data: any) => void;
  loading: boolean;
  comment: string;
};

const DialogApproveRejectRealisasiKunjungan: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const form = useForm();

  //================================================= Functions ====================================================

  const onCloseComment = () => {
    form.reset();
    props.onClose();
  };

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={props.openComment}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          <Text>Input Komentar</Text>
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(props.onSubmit)}>
              <FormControl
                width="100%"
                isInvalid={!!form.formState.errors.comment}
              >
                <Textarea
                  {...form.register("comment", {
                    required: "Required",
                    validate: (value) => {
                      if (value && value.trim() === "") {
                        return "Required";
                      }
                    },
                  })}
                  defaultValue={props.comment}
                  placeholder="Isi Keterangan..."
                />
                <FormErrorMessage color={theme.colors.exodus.red}>
                  {form.formState.errors.comment &&
                    (form.formState.errors.comment.message as string)}
                </FormErrorMessage>
              </FormControl>
              <Flex
                direction={!isDesktopView ? "column" : "row-reverse"}
                gap={!isDesktopView ? 0 : 5}
                marginTop="48px"
                marginBottom="20px"
              >
                <Button
                  type="submit"
                  width="100%"
                  colorScheme="buttonPrimary"
                  isLoading={props.loading}
                  isDisabled={props.loading}
                >
                  Submit
                </Button>
                <Button
                  onClick={onCloseComment}
                  width="100%"
                  variant="outline"
                  colorScheme="buttonSecondary"
                  marginTop={!isDesktopView ? "12px" : 0}
                  isDisabled={props.loading}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DialogApproveRejectRealisasiKunjungan;
