import MobileAccordionApprovalRencanaKunjungan from "./MobileAccordionApprovalRencanaKunjungan";
import MobileApprovalRencanaKunjunganToolbar from "./MobileApprovalRencanaKunjunganToolbar";

const MobileApprovalRencanaKunjungan = () => {
  return (
    <>
      <MobileApprovalRencanaKunjunganToolbar />
      <MobileAccordionApprovalRencanaKunjungan />
    </>
  );
};

export default MobileApprovalRencanaKunjungan;
