import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
  Text,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
  Stack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import ClearAllButton from "../../../../components/CustomButtons/ClearAllButton";
import { FormProvider, useForm } from "react-hook-form";
import OutletPanelFormFieldKlaimSales from "./forms/OutletPanelFormFieldKlaimSales";
import OutletTujuanFormFieldKlaimSales from "./forms/OutletTujuanFormFieldKlaimSales";
import StatusFormFieldKlaimSales from "./forms/StatusFormFieldKlaimSales";
import DateExodus from "../../../../models/DateExodus";
import KlaimSalesFilterAndPagination from "../../models/KlaimSalesFilterAndPaginationModel";
import MonitorKlaimSalesContext from "../contexts/MonitorKlaimSalesContext";
import PeriodeFormFieldKlaimSales from "./forms/PeriodeFormFieldKlaimSales";
import KlaimSalesFilterFormType from "../models/KlaimSalesFilterFormType";
import NsmFormFieldKlaimSales from "./forms/NsmFormFieldKlaimSales";
import LoggedUser from "../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../redux/hooks";
import ClaimIdFormFieldKlaimSales from "./forms/ClaimIdFormFieldKlaimSales";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DialogFilterMonitorKlaimSales: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );
  const form = useForm<KlaimSalesFilterFormType>();
  const { filterValues, setFilterMonitorKlaimSales, handleClearFilter } =
    useContext(MonitorKlaimSalesContext);

  const handleSubmitFilter = (data: KlaimSalesFilterFormType) => {
    const tempStartPeriod =
      data.startAtKlaimSales &&
      DateExodus.fromDateObject(data.startAtKlaimSales);
    const tempEndPeriod =
      data.endAtKlaimSales &&
      DateExodus.fromDateObject(data.endAtKlaimSales).endOf("month");

    let submittedData: KlaimSalesFilterAndPagination | null = null;

    if (data.idKlaimSales) {
      submittedData = {
        page: 1,
        limit: filterValues ? filterValues.limit : 10,
        claimUser: undefined,
        outletPanelName: undefined,
        outletTargetName: undefined,
        status: undefined,
        startAt: undefined,
        endAt: undefined,
        idKlaimSales: data.idKlaimSales,
      };
    } else {
      submittedData = {
        page: 1,
        limit: filterValues ? filterValues.limit : 10,
        claimUser: data.claimUser ?? undefined,
        outletPanelName: data.outletPanel?.trim() ?? undefined,
        outletTargetName: data.outletTujuan?.trim() ?? undefined,
        status: data.klaimSalesStatus?.name ?? undefined,
        startAt: tempStartPeriod.getEpochDate(),
        endAt: tempEndPeriod.getEpochDate(),
        idKlaimSales: undefined,
      };
    }
    setFilterMonitorKlaimSales(submittedData);
    props.onClose();
  };

  const onClearFilter = () => {
    handleClearFilter();
    form.reset();
    props.onClose();
  };

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      isOpen={props.isOpen}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontSize={24}
            fontWeight="semibold"
          >
            Filter Data
          </Text>
          <ClearAllButton onClick={onClearFilter} />
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form
              id="filter-monitor-klaim-sales"
              onSubmit={form.handleSubmit(handleSubmitFilter)}
            >
              <Stack gap={isDesktopView ? 3 : 2}>
                <ClaimIdFormFieldKlaimSales />
                {loggedUser?.userRoleName !== "nsm" && (
                  <NsmFormFieldKlaimSales />
                )}
                <OutletPanelFormFieldKlaimSales />
                <OutletTujuanFormFieldKlaimSales />
                <StatusFormFieldKlaimSales />
                <PeriodeFormFieldKlaimSales />
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Flex
            direction={!isDesktopView ? "column" : "row-reverse"}
            width={"100%"}
            gap={!isDesktopView ? 0 : 5}
            paddingBottom="10px"
          >
            <Button
              type="submit"
              width="100%"
              colorScheme="buttonPrimary"
              form="filter-monitor-klaim-sales"
            >
              Submit
            </Button>
            <Button
              onClick={props.onClose}
              width="100%"
              variant="outline"
              colorScheme="buttonSecondary"
              marginTop={!isDesktopView ? "12px" : 0}
            >
              Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DialogFilterMonitorKlaimSales;
