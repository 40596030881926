import { AxiosResponse, isAxiosError } from "axios";
import AxiosBaseClient from "../../config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../constants/common/AxiosResponseConstants";
import { UserByRoleParam, UserBySalesZoneParam } from "./UserParamType";
import UserModel from "../../../models/Users/UserModel";
import DateExodus from "../../../models/DateExodus";
import RoleModel from "../../../models/Users/RoleModel";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const UserProvider = {
  getRoleListByLoggedUser: async () => {
    try {
      const response: AxiosResponse =
        await AxiosBaseClient.get("/v1/role/child");
      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const result: RoleModel[] = response.data.data.map((json: any) => {
            return new RoleModel({
              id: json["role_id"],
              name: json["role_name"],
              label: json["role_label"],
              parentId: json["parent_id"],
              parentRole: json["parent_role"],
            });
          });

          return Promise.resolve(result);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getUserByRole: async (params: UserByRoleParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/user/child",
        {
          params: {
            role_name: params.roleName,
          },
        }
      );
      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const result: UserModel[] = response.data.data.map((json: any) => {
            return new UserModel({
              id: json["user_id"],
              name: json["user_name"],
              nip: json["nip"],
              role: {
                id: json["role_id"],
                label: json["role_label"],
                name: json["role_name"],
              },
              salesZone: {
                id: json["zone_id"],
                type: json["zone_type"],
              },
            });
          });
          return Promise.resolve(result);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getUserBySalesZone: async (params: UserBySalesZoneParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        "/v1/user/zone-status",
        {
          params: {
            sales_zone_id: params.salesZoneId,
            sales_zone_type: params.salesZoneType,
          },
        }
      );
      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const record = response.data.data.records;

          const result: UserModel = new UserModel({
            id: record["user_id"],
            name: record["username"],
            nip: record["user_nip"],
            userZoneId: record["user_zone_id"],
            role: {
              id: record["user_role_id"],
            },
            salesZone: {
              id: record["sales_zone_id"],
              name: record["name_sales_zone"],
              type: record["sales_zone_type"],
            },
            assignedDate: record["assigned_date"]
              ? DateExodus.fromBackend(record["assigned_date"])
              : undefined,
            finishedDate: record["finished_date"]
              ? DateExodus.fromBackend(record["finished_date"])
              : undefined,
          });

          return Promise.resolve(result);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (error.response) {
        return Promise.reject(
          `${error.message}. ${error.response.data.message}`
        );
      }
      return Promise.reject(error.message);
    }
  },
  getUserChildNonVacant: async () => {
    try {
      const response = await AxiosBaseClient.get("/v1/user/child/nonvacant");

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData: UserModel[] = response.data.data.map(
            (json: any) => {
              return new UserModel({
                id: json["user_id"],
                name: json["user_name"],
                nip: json["nip"],
                role: {
                  id: json["role_id"],
                  label: json["role_label"],
                  name: json["role_name"],
                },
                salesZone: {
                  id: json["zone_id"],
                  type: json["zone_type"],
                },
              });
            }
          );
          return Promise.resolve(responseData);
        } else {
          return Promise.reject(UNKNOWN_ERROR_RESPONSE);
        }
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response?.data.message,
          status: error.response?.status,
          code: error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
};

export default UserProvider;
