import { Stack, Spinner, useTheme, Text } from "@chakra-ui/react";

const PageLoading = () => {
  const theme = useTheme();
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      height="99vh"
      bgColor={theme.colors.exodus.background}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor={theme.colors.exodus.background}
        color={theme.colors.exodus.primaryBlue}
        size="xl"
      />
      <Text fontSize={18} color={theme.colors.exodus.darkGrey} fontWeight={600}>
        Harap Menunggu . . .
      </Text>
    </Stack>
  );
};

export default PageLoading;
