import { Box, Grid, GridItem, Text, useTheme } from "@chakra-ui/react";
import InputKlaimSalesStoredDataModel from "../../../../models/InputKlaimSalesStoredDataModel";

type Props = {
  data: InputKlaimSalesStoredDataModel | null;
};

const DesktopSummaryKlaimSalesGeneralInformation: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Box paddingTop="16px" paddingBottom="40px">
      <Grid
        templateColumns="repeat(4, 1fr)"
        textAlign="left"
        fontSize={20}
        gap={5}
      >
        <GridItem colSpan={1}>
          <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
            Outlet Panel
          </Text>
          <Text>{props.data?.outlet?.outletPanel.data?.outletName ?? "-"}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
            Kode Outlet Panel
          </Text>
          <Text>{props.data?.outlet?.outletPanel.data?.outletCode ?? "-"}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
            Outlet Tujuan
          </Text>
          <Text>
            {props.data?.outlet?.outletTujuan.data?.outletName ?? "-"}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Text color={theme.colors.exodus.primaryBlue} fontWeight="semibold">
            Kode Outlet Tujuan
          </Text>
          <Text>
            {props.data?.outlet?.outletTujuan.data?.outletCode ?? "-"}
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default DesktopSummaryKlaimSalesGeneralInformation;
