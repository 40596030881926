import { Box, Spinner, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import DesktopInputRencanaKunjunganToolbar from "./desktop/DesktopInputRencanaKunjunganToolbar";

import KunjunganAlertComponent from "../../../components/KunjunganAlertComponent";
import MinimalVisitAndStatusComponent from "./MinimalVisitAndStatusComponent";
import DesktopTableDraftRencanaKunjungan from "./desktop/DesktopTableDraftRencanaKunjungan";
import MobileInputRencanaKunjunganToolbar from "./mobile/MobileInputRencanaKunjunganToolbar";
import MobileTableDraftRencanaKunjungan from "./mobile/MobileTableDraftRencanaKunjungan";
import MobileSubmitDraftButton from "./mobile/MobileSubmitDraftButton";
import { useContext } from "react";
import InputRencanaKunjunganContext from "../context/InputRencanaKunjunganContext";

const InputRencanaKunjunganPage: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { selectedPeriode, minimalVisitData, periodeLoading } = useContext(
    InputRencanaKunjunganContext
  );

  if (periodeLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
        <Text fontSize={24} fontWeight="semibold">
          Sedang Mendapatkan Data Periode
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <PageTitle label="Input Rencana Kunjungan" />
      {isDesktopView && <DesktopInputRencanaKunjunganToolbar />}
      {!isDesktopView && <MobileInputRencanaKunjunganToolbar />}
      {selectedPeriode && selectedPeriode.total_visit === 0 && (
        <KunjunganAlertComponent
          type="warning"
          title="Peringatan"
          message="Anda belum membuat rencana kunjungan pada periode ini"
        />
      )}
      {selectedPeriode &&
        minimalVisitData &&
        selectedPeriode.total_visit !== 0 &&
        selectedPeriode.total_visit < minimalVisitData.minimalvisit && (
          <KunjunganAlertComponent
            type="warning"
            title="Peringatan"
            message="Rencana kunjungan yang telah dibuat pada periode ini masih dibawah target"
          />
        )}
      {selectedPeriode && selectedPeriode.reject_reason && (
        <KunjunganAlertComponent
          type="warning"
          title="Alasan Penolakan dari Atasan"
          message={`${selectedPeriode.reject_reason}`}
        />
      )}
      <MinimalVisitAndStatusComponent />
      {isDesktopView && <DesktopTableDraftRencanaKunjungan />}
      {!isDesktopView && <MobileTableDraftRencanaKunjungan />}
      {!isDesktopView && <MobileSubmitDraftButton />}
    </Box>
  );
};

export default InputRencanaKunjunganPage;
