import { AxiosResponse, isAxiosError } from "axios";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import { EXODUS_API_VERSION_1 } from "../../../../constants/common/RootConstants";
import AxiosBaseClient from "../../../../dataProviders/config/AxiosBaseClient";
import IndexedDatabase from "../../../../database/IndexDB";
import { UpdateStatusKlaimSalesParam } from "../../dataProviders/KlaimSalesParamType";
import KlaimSalesFilterAndPagination from "../../models/KlaimSalesFilterAndPaginationModel";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const ApprovalKlaimSalesProvider = {
  getSavedFilterApprovalKlaimSales: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

    try {
      const data: KlaimSalesFilterAndPagination[] =
        await IndexedDatabase.approvalKlaimSalesFilter.toArray();

      if (!data || data.length === 0) {
        return Promise.resolve(null);
      } else {
        return Promise.resolve(data[0]);
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  setSaveFilterApprovalKlaimSales: async (
    newData: KlaimSalesFilterAndPagination
  ) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

    try {
      const oldData: KlaimSalesFilterAndPagination[] =
        await IndexedDatabase.approvalKlaimSalesFilter.toArray();

      if (!oldData || oldData.length === 0) {
        await IndexedDatabase.approvalKlaimSalesFilter.add(newData);
      } else {
        await IndexedDatabase.approvalKlaimSalesFilter.update(
          oldData[0].id,
          newData
        );
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  updateStatusKlaimSales: async (params: UpdateStatusKlaimSalesParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.put(
        `${EXODUS_API_VERSION_1}/claim_sales`,
        params.data
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          return Promise.resolve(response.data.message);
        } else {
          const errorResponse: ErrorResponse = {
            message: response.data.message,
            status: response.data.status,
            code: response.data.code,
          };

          return Promise.reject(errorResponse);
        }
      }
    } catch (error) {
      return handleOnError(error);
    }
  },
};

const handleOnError = (error: unknown) => {
  if (isAxiosError(error)) {
    return Promise.reject({
      message: error.response ? error.response.data.message : error.message,
      status: error.response?.status,
      code: error.response ? error.response.data.code : error.code,
    });
  }
  return Promise.reject(UNKNOWN_ERROR_RESPONSE);
};

export default ApprovalKlaimSalesProvider;
