import { Stack, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import CustomDatePicker from "../../../../../../../../../../components/DatePicker/CustomDatePicker";
import { useFormContext } from "react-hook-form";
import DateExodus from "../../../../../../../../../../models/DateExodus";
import { useState } from "react";
import { useSalesOutletListContext } from "../../../../../contexts/SalesOutletListContext";

const FilterByPeriode = ({ handleSubmit }: { handleSubmit: Function }) => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const formContext = useFormContext();

  const [tempStartDate, setTempStartDate] = useState<DateExodus>(
    formContext.getValues("periode.awal")
  );
  const [tempEndDate, setTempEndDate] = useState<DateExodus>(
    formContext.getValues("periode.akhir")
  );

  const {
    states: { loading },
  } = useSalesOutletListContext();

  return (
    <Stack gap={isMobile ? 2 : 4} direction={isMobile ? "column" : "row"}>
      <Stack
        sx={{ width: isMobile ? "100%" : undefined }}
        alignItems={"baseline"}
      >
        <Text>Periode Awal</Text>
        <CustomDatePicker
          key={1}
          placeholder="Periode Awal"
          onChange={(val) => {
            if (val) {
              formContext.setValue(
                "periode.awal",
                DateExodus.fromDateObject(val)
              );
              setTempStartDate(DateExodus.fromDateObject(val));

              handleSubmit();
            }
          }}
          name={"jsPeriodeAwal"}
          type={"month_year"}
          defaultValue={tempStartDate.getDateObject()}
          maxDate={tempEndDate.getDateObject()}
          disabled={loading !== null}
        />
      </Stack>
      <Stack
        sx={{ width: isMobile ? "100%" : undefined }}
        alignItems={"baseline"}
      >
        <Text>Periode Akhir</Text>
        <CustomDatePicker
          key={2}
          placeholder="Periode Akhir"
          onChange={(val) => {
            if (val) {
              formContext.setValue(
                "periode.akhir",
                DateExodus.fromDateObject(val)
              );
              setTempEndDate(DateExodus.fromDateObject(val));

              handleSubmit();
            }
          }}
          name={"jsPeriodeAkhir"}
          type={"month_year"}
          defaultValue={tempEndDate.getDateObject()}
          minDate={tempStartDate.getDateObject()}
          maxDate={new DateExodus().getDateObject()}
          disabled={loading !== null}
        />
      </Stack>
    </Stack>
  );
};

export default FilterByPeriode;
