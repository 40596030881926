import { FormControl } from "@chakra-ui/react";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useContext, useEffect, useState } from "react";
import MonitorSalesTargetContext from "../../context/MonitorSalesTargetContext";
import ProjectType from "../../../../../../models/common/ProjectType";

const SalesTargetSelectProjectFormField: React.FC = () => {
  const { register, setValue } = useFormContext();
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const { filterValues } = useContext(MonitorSalesTargetContext);

  const [selectedValue, setSelectedValue] = useState<ProjectType | null>(
    filterValues ? filterValues.project : null
  );

  useEffect(() => {
    setValue("project", selectedValue);
  }, []);

  return (
    <FormControl paddingBottom="12px">
      <FormInputLabel title="Project" />
      <CustomSelect
        {...register("project")}
        options={loggedUser?.projects ?? []}
        isDisabled={loggedUser ? loggedUser.projects.length <= 1 : false}
        getOptionLabel={(data: ProjectType) => data.label}
        getOptionValue={(data: ProjectType) => data.value}
        value={selectedValue}
        placeholder="Project"
        onChange={(data: ProjectType | null) => {
          if (!data) {
            setValue("project", null);
            setSelectedValue(null);
          } else {
            setValue("project", data);
            setSelectedValue(data);
          }
        }}
      />
    </FormControl>
  );
};

export default SalesTargetSelectProjectFormField;
