const formIdPrefixCheckbox = "chk-";
const formIdPrefixTextField = "tf-";
const formIdCheckboxCounter = "checkbox-counter";
const formIdSelectAll = formIdPrefixCheckbox + "select-all";

const notifyMessages = {
  warning: {
    noSelectedItem: "Mohon pilih salah satu produk",
  },
  info: {
    savedToStorage: "Perubahan Tersimpan",
    removedInStorage: "Penghapusan Tersimpan",
  },
};

const stepperChildMinHeightDesktop = 500;

export {
  formIdPrefixCheckbox,
  formIdPrefixTextField,
  formIdCheckboxCounter,
  formIdSelectAll,
  notifyMessages,
  stepperChildMinHeightDesktop,
};
