import DateExodus from "../../../models/DateExodus";
import RoleModel from "../../../models/Users/RoleModel";
import UserModel from "../../../models/Users/UserModel";
import { ProjectEnum } from "../../../models/common/Project";

class BiayaMurniFilterModel {
  project: undefined | ProjectEnum;
  jabatan: undefined | RoleModel;
  pekerja: undefined | UserModel;
  periode: {
    awal: DateExodus;
    akhir: DateExodus;
  };

  constructor({
    project = undefined,
    jabatan = undefined,
    pekerja = undefined,
    periode,
  }: {
    project?: ProjectEnum;
    jabatan?: undefined | RoleModel;
    pekerja?: undefined | UserModel;
    periode: {
      awal: DateExodus;
      akhir: DateExodus;
    };
  }) {
    this.project = project;
    this.jabatan = jabatan;
    this.pekerja = pekerja;
    this.periode = periode;
  }

  public static fromJson(json: any) {
    return new BiayaMurniFilterModel({
      project: json.project ?? undefined,
      jabatan: json.jabatan,
      pekerja: json.pekerja,
      periode: {
        awal: DateExodus.fromBackend(json.periode.awal),
        akhir: DateExodus.fromBackend(json.periode.akhir),
      },
    });
  }

  toJson() {
    return {
      project: this.project ?? null,
      jabatan: this.jabatan,
      pekerja: this.pekerja,
      periode: {
        awal: this.periode.awal.toJSON(),
        akhir: this.periode.akhir.toJSON(),
      },
    };
  }
}

export default BiayaMurniFilterModel;
