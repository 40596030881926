import { Box, useMediaQuery, useTheme } from "@chakra-ui/react";
import React, { useState } from "react";
import useTitle from "../../services/useTitle";
import PageTitle from "../../components/PageTitle/PageTitle";
import LoggedUser from "../../models/common/LoggedUser";
import { useAppSelector } from "../../redux/hooks";
import { ROLE_ID_FF } from "../../constants/common/RoleConstants";
import DesktopDashboardComponent from "./component/DesktopDashboardComponent";
import MobileDashboardComponent from "./component/MobileDashboardComponent";
import useGetBudgetNasional from "./service/useBudgetNasional";
import DashboardContext from "./context/DashboardContext";
import useTargetSalesProgress from "./service/useTargetSalesProgress";
import useDashboardTargetSales from "./service/useDashboardTargetSales";
import useBiayaMurniSalesRatio from "./service/useBiayaMurniSalesRatio";
import FFDashboardComponent from "./component/FFDashboardComponent";
import DateExodus from "../../models/DateExodus";
import ProjectType from "../../models/common/ProjectType";

const DashboardPage: React.FC = () => {
  const theme = useTheme();

  const [selectedMonth, setSelectedMonth] = useState<DateExodus | null>(null);
  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(
    null
  );

  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const {
    data: dataBudgetNasional,
    loading: loadingBudgetNasional,
    error: errorBudgetNasional,
  } = useGetBudgetNasional(loggedUser);

  const {
    data: dataTargetSales,
    loading: loadingTargetSales,
    error: errorTargetSales,
  } = useDashboardTargetSales(selectedProject);

  const {
    dataTargetSalesProgress,
    errorTargetSalesProgress,
    loadingTargetSalesProgress,
  } = useTargetSalesProgress(loggedUser, selectedProject);

  const { dataCostSalesRatio, errorCostSalesRatio, loadingCostSalesRatio } =
    useBiayaMurniSalesRatio(loggedUser, selectedMonth);

  useTitle("Dashboard");

  return (
    <DashboardContext.Provider
      value={{
        budgetNasional: dataBudgetNasional,
        budgetNasionalError: errorBudgetNasional,
        budgetNasionalLoading: loadingBudgetNasional,
        targetSalesProgress: dataTargetSalesProgress,
        targetSalesProgressError: errorTargetSalesProgress,
        targetSalesProgressLoading: loadingTargetSalesProgress,
        targetSales: dataTargetSales,
        targetSalesError: errorTargetSales,
        targetSalesLoading: loadingTargetSales,
        biayaMurniSalesRatio: dataCostSalesRatio,
        biayaMurniSalesRatioError: errorCostSalesRatio,
        biayaMurniSalesRatioLoading: loadingCostSalesRatio,
        selectedMonth,
        setSelectedMonth,
        selectedProject,
        setSelectedProject,
      }}
    >
      <Box>
        <PageTitle label="Dashboard" />

        <Box mt={7}>
          {loggedUser?.userRoleId === ROLE_ID_FF ? (
            <FFDashboardComponent />
          ) : isDesktopView ? (
            <DesktopDashboardComponent />
          ) : (
            <MobileDashboardComponent />
          )}
        </Box>
      </Box>
    </DashboardContext.Provider>
  );
};

export default DashboardPage;
