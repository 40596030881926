import React, { useContext } from "react";
import Pagination from "../../../../../../components/Pagination/Pagination";
import { ListApprovalKunjunganModelRecords } from "../../../../models/ApprovalListModel";
import { Box, Spinner, useTheme, Text } from "@chakra-ui/react";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";
import StatusChip from "../../../../../../components/StatusChip/StatusChip";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import FilterApprovalRealisasiKunjunganContext from "../../context/FilterApprovalRealisasiKunjunganContext";
import LihatDetailButtonApprovalRealisasi from "../LihatDetailButtonApprovalRealisasi";
import { RealisasiStatus } from "../../models/ApprovalRealisasiStatus";
import MetadataApprovalRealisasiContext from "../../context/MetadataApprovalRealisasiContext";
import { TableColumn } from "react-data-table-component";

const DesktopApprovalRealisasiList: React.FC = () => {
  const theme = useTheme();
  const {
    filterValues,
    approvalListData,
    errorList,
    loadingList,
    metadataList,
  } = useContext(FilterApprovalRealisasiKunjunganContext);
  const {
    metadataValues,
    handleFirstPage,
    handleLimitChange,
    handleNextPage,
    handlePrevPage,
  } = useContext(MetadataApprovalRealisasiContext);

  const getStatusColor = (status: string) => {
    if (status === RealisasiStatus.Approved) {
      return "processed";
    } else if (status === RealisasiStatus.Pending) {
      return "pending";
    } else {
      return "pending";
    }
  };

  const generatePeriodeRealisasi = (): string => {
    return `${filterValues.periode?.start_period.getStringDateFormat(
      "D MMMM YYYY"
    )} - ${filterValues.periode?.end_period.getStringDateFormat(
      "D MMMM YYYY"
    )}`;
  };

  const approvalRealisasiHeader: TableColumn<ListApprovalKunjunganModelRecords>[] =
    [
      {
        name: "Field Force",
        cell: (row: ListApprovalKunjunganModelRecords) => (
          <StackedColumn
            isTopBoldApplied
            isTopColorPrimaryBlue
            topValue={row.userName}
            bottomValue={row.nip}
          />
        ),
        sortable: false,
        ignoreRowClick: false,
        allowOverflow: false,
        minWidth: "200px",
        maxWidth: "250px",
      },
      {
        name: "Jabatan",
        cell: (row: ListApprovalKunjunganModelRecords) => (
          <Text textAlign="left">{row.roleLabel}</Text>
        ),
        sortable: false,
        ignoreRowClick: false,
        allowOverflow: false,
        minWidth: "170px",
        maxWidth: "230px",
      },
      {
        name: "Periode Realisasi Kunjungan",
        selector: () => generatePeriodeRealisasi(),
        sortable: false,
        ignoreRowClick: false,
        allowOverflow: false,
        minWidth: "330px",
        maxWidth: "400px",
      },

      {
        name: "Approval",
        cell: (row: ListApprovalKunjunganModelRecords) => (
          <StatusChip
            status={getStatusColor(row.approvalRealisasiStatus)}
            text={row.approvalRealisasiStatus.toUpperCase()}
          />
        ),
        sortable: false,
        ignoreRowClick: false,
        allowOverflow: false,
        minWidth: "150px",
        maxWidth: "200px",
      },

      {
        name: "",
        cell: (row: ListApprovalKunjunganModelRecords) => (
          <LihatDetailButtonApprovalRealisasi
            id={row.id}
            userId={row.userId}
            isDisabled={
              row.id === 0 ||
              row.approvalRealisasiStatus === RealisasiStatus.Pending
            }
          />
        ),
        sortable: false,
        ignoreRowClick: false,
        allowOverflow: false,
        minWidth: "170px",
      },
    ];

  if (loadingList) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (errorList) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={errorList.toString()} />
      </Box>
    );
  }

  if (approvalListData.length > 0 && approvalListData[0].project) {
    approvalRealisasiHeader.unshift({
      name: "Project",
      cell: (row: ListApprovalKunjunganModelRecords) => (
        <Text textAlign="left">{row.project!.label}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    });
  }

  return approvalListData.length > 0 ? (
    <>
      <Box marginTop="24px" marginBottom="15px">
        <TableDataComponent
          data={approvalListData}
          tableHeader={approvalRealisasiHeader}
          onSelectChange={() => {}}
          paginationOn={false}
          paginationLimit={[]}
          checboxOn={false}
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"}>
        <Pagination
          handleOnLimitChange={handleLimitChange}
          handleOnBack={handlePrevPage}
          handleOnNext={handleNextPage}
          page={metadataValues?.currentPage}
          limit={metadataValues?.limit}
          totalPage={metadataList?.totalPage}
          totalData={metadataList?.totalData}
          showLimit
          showCurrentPageDetail
          handleOnNavigateFirstPage={handleFirstPage}
        />
      </Box>
    </>
  ) : (
    <Box
      marginTop="24px"
      marginBottom="15px"
      display={"flex"}
      justifyContent={"start"}
    >
      <DataNotFound />
    </Box>
  );
};

export default DesktopApprovalRealisasiList;
