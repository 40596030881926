import { BiayaMurniFeatureType } from "./BiayaMurniFeatureType";

class BiayaMurniSavedListMetadata {
  page: number;
  limit: number;
  featureType: BiayaMurniFeatureType;

  constructor({
    page,
    limit,
    featureType,
  }: {
    page: number;
    limit: number;
    featureType: BiayaMurniFeatureType;
  }) {
    this.page = page;
    this.limit = limit;
    this.featureType = featureType;
  }

  public static fromJson(json: any) {
    return new BiayaMurniSavedListMetadata({
      page: json.page,
      limit: json.limit,
      featureType: json.feature_type,
    });
  }

  toJson() {
    return {
      page: this.page,
      limit: this.limit,
      feature_type: this.featureType,
    };
  }
}

export default BiayaMurniSavedListMetadata;
