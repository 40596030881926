import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./AuthReducer/AuthReducer";
import KlaimSalesReducer from "./KlaimSalesReducer/KlaimSalesReducer";

const store = configureStore({
  reducer: {
    authReducer: AuthReducer,
    klaimSalesReducer: KlaimSalesReducer,
  },
  devTools: import.meta.env.MODE === "development",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
