import { createContext, useContext } from "react";
import useGetOneDetail from "../services/dataFetchings/useGetOneDetail";
import { useSalesOutletDetailFilterContext } from "./SalesOutletDetailFilterContext";
import ErrorResponse from "../../../../../../models/common/ErrorResponse";
import ApiGetDetailSalesOutletResponse from "../models/apiResponses/ApiGetDetailSalesOutletResponse";
import SalesOutletSavedPaginationMetadata from "../../../models/SalesOutletSavedPaginationMetadata";

type _Value = {
  states: {
    data: ApiGetDetailSalesOutletResponse | null;
    isLoading: boolean;
    error: ErrorResponse | null;
  };
  actions: {
    setLimit: (limit: number) => void;
    setPage: (value: "next" | "prev" | "first") => void;
  };
};

const SalesOutletDetailContext = createContext<_Value>({
  states: {
    data: null,
    isLoading: false,
    error: null,
  },
  actions: {
    setLimit: () => {},
    setPage: () => {},
  },
});

function useSalesOutletDetailContext(): _Value {
  return useContext(SalesOutletDetailContext);
}

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{}>;

const SalesOutletDetailContextProvider = ({
  children,
}: _InternalProps): JSX.Element => {
  const {
    states: { filterValues, metadataValues },
    actions: { saveMetadata },
  } = useSalesOutletDetailFilterContext();

  const { data, isLoading, error } = useGetOneDetail({
    project: filterValues?.project,
    outletId: filterValues?.outletId,
    startDate: filterValues?.periode.awal,
    endDate: filterValues?.periode.akhir,
    userId: filterValues?.userId,
    limit: metadataValues?.limit,
    page: metadataValues?.page,
  });

  const setPage = (value: "next" | "prev" | "first") => {
    let page: number = 0;
    if (metadataValues) {
      if (value === "next") {
        page = metadataValues.page + 1;
      }
      if (value === "prev") {
        page = metadataValues.page - 1;
      }
      if (value === "first") {
        page = 1;
      }
      saveMetadata(
        new SalesOutletSavedPaginationMetadata({
          limit: metadataValues.limit,
          page: page,
        })
      );
    }
  };

  const setLimit = (limit: number) => {
    if (metadataValues) {
      saveMetadata(
        new SalesOutletSavedPaginationMetadata({
          limit: limit,
          page: 1,
        })
      );
    }
  };

  const value: _Value = {
    states: {
      data: data,
      isLoading: isLoading,
      error: error,
    },
    actions: { setPage, setLimit },
  };

  return (
    <SalesOutletDetailContext.Provider value={value}>
      {children}
    </SalesOutletDetailContext.Provider>
  );
};

export { SalesOutletDetailContextProvider, useSalesOutletDetailContext };
