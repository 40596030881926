import DateExodus from "../../../../models/DateExodus";
import DistributorDataModel from "../../../../models/Distributor/DistributorDataModel";
import { OutletModel } from "../../../../models/Outlet/OutletModel";
import SectorModel from "../../../../models/Sector/SectorModel";
import ProjectType from "../../../../models/common/ProjectType";

type ConstructorProps = {
  nomorFaktur: string | null | undefined;
  namaOutlet: OutletModel | null | undefined;
  namaDistributor: DistributorDataModel | null | undefined;
  projectAvailable: ProjectType | null | undefined;
  sector: SectorModel | null | undefined;
  bridgingType: string | undefined;
  periodeAwal: any;
  periodeAkhir: any;
};

class SalesDistributorFilterValue {
  nomorFaktur: string | null | undefined;
  namaOutlet: OutletModel | null | undefined;
  namaDistributor: DistributorDataModel | null | undefined;
  projectAvailable: ProjectType | null | undefined;
  sector: SectorModel | null | undefined;
  bridgingType: string | undefined;
  periodeAwal: DateExodus;
  periodeAkhir: DateExodus;

  constructor({
    nomorFaktur,
    namaOutlet,
    namaDistributor,
    projectAvailable,
    sector,
    bridgingType,
    periodeAwal,
    periodeAkhir,
  }: ConstructorProps) {
    this.nomorFaktur = nomorFaktur;
    this.namaOutlet = namaOutlet;
    this.namaDistributor = namaDistributor;
    this.projectAvailable = projectAvailable;
    this.sector = sector;
    this.bridgingType = bridgingType;
    this.periodeAwal =
      periodeAwal instanceof DateExodus
        ? periodeAwal
        : new DateExodus(periodeAwal);
    this.periodeAkhir =
      periodeAkhir instanceof DateExodus
        ? periodeAkhir
        : new DateExodus(periodeAkhir);
  }
}

export default SalesDistributorFilterValue;
