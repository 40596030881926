import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Icon,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { IoMdEye } from "react-icons/io";
import { GetListOutletCardModelData } from "../../../../models/GetListOutletCardModel";
import HorizontalAccordionDetail from "../../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useOutletCardListContext } from "../../../contexts/OutletCardListContextProvider";
import { sectorStringToSectorModel } from "../../../../utils/SectorStringToSectorModel";

const OutletCardListAccordionItem = ({
  record,
}: {
  record: GetListOutletCardModelData;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    states: { filterValues },
  } = useOutletCardListContext();

  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            fontSize={14}
          >
            {record.outletName}
          </Text>
          <Text
            color={theme.colors.exodus.darkGrey}
            fontWeight={400}
            fontSize={14}
          >
            {record.outletCode}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel mx={4}>
        <HorizontalAccordionDetail
          leftText="Project"
          rightSide={record.project ?? "-"}
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText="Sektor"
          rightSide={sectorStringToSectorModel(record.sectorType).label ?? "-"}
        />
        <HorizontalAccordionDetail
          leftText="Biaya Dasar"
          rightSide={
            CurrencyIdrFormatter.convertToRp(record.biayaDasar.totalCost) ?? "-"
          }
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText="Saldo Kontribusi"
          rightSide={
            CurrencyIdrFormatter.convertToRp(record.saldoKontribusi) ?? "-"
          }
        />
        <HorizontalAccordionDetail
          leftText="Sisa Saldo"
          rightSide={CurrencyIdrFormatter.convertToRp(record.sisaSaldo) ?? "-"}
          isRowColored
        />
        <HorizontalAccordionDetail
          leftText="Biaya Murni"
          rightSide={
            CurrencyIdrFormatter.convertToRp(record.biayaMurni.totalCost) ?? "-"
          }
        />
        <Stack flexDirection="row" justifyContent="flex-end">
          <Button
            colorScheme="buttonSecondary"
            variant={"ghost"}
            leftIcon={<Icon as={IoMdEye} boxSize={6} />}
            onClick={() => {
              navigate({
                pathname: `/outlet-card/${record.outletId}`,
                search: createSearchParams({
                  startDate: `${filterValues?.startDate?.getEpochDate()}`,
                  endDate: `${filterValues?.endDate?.getEpochDate()}`,
                }).toString(),
              });
            }}
          >
            Lihat Detail
          </Button>
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default OutletCardListAccordionItem;
