import { Box, useTheme } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";

type StackedColumnProps = {
  topValue: string | number;
  bottomValue: string | number;
};

const StackedColumnMonitorKlaimSales = ({
  topValue,
  bottomValue,
}: StackedColumnProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Box sx={{ textAlign: "left" }}>
      <Text
        fontWeight={"semibold"}
        color={theme.colors.exodus.primaryBlue}
        fontSize={14}
      >
        {topValue}
      </Text>
      <Text
        color={theme.colors.exodus.fontDefault}
        fontWeight={600}
        fontSize={14}
      >
        {bottomValue}
      </Text>
    </Box>
  );
};

export default StackedColumnMonitorKlaimSales;
