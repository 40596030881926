import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import BiayaMurniGeneralCard from "./BiayaMurniGeneralCard";
import useGetCostTotals from "../../services/dataFetchings/useGetCostTotals";
import { useBiayaMurniFilterContext } from "../../contexts/BiayaMurniFilterContext";
import DateExodus from "../../../../models/DateExodus";
import LoggedUser from "../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../redux/hooks";
import CurrencyIdrFormatter from "../../../../utils/CurrencyIdrFormatter";
import ErrorPage from "../../../../components/ErrorPage/ErrorPage";
import { ProjectEnum } from "../../../../models/common/Project";
import { isEqual } from "lodash";

const BiayaMurniCostsGrid = (): JSX.Element => {
  const {
    states: { filterValues, error },
  } = useBiayaMurniFilterContext();

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (filterValues && loggedUser) {
    return (
      <BiayaMurniCostsGridContent
        periodeAwal={filterValues.periode.awal}
        periodeAkhir={filterValues.periode.akhir}
        userId={filterValues.pekerja?.id}
        project={filterValues.project}
      />
    );
  }
  return <></>;
};

const BiayaMurniCostsGridContent = ({
  periodeAwal,
  periodeAkhir,
  userId,
  project,
}: {
  periodeAwal: DateExodus;
  periodeAkhir: DateExodus;
  userId: number | undefined;
  project: ProjectEnum | undefined;
}): JSX.Element => {
  const theme = useTheme();
  const { data, error, isLoading } = useGetCostTotals({
    startDate: periodeAwal,
    endDate: periodeAkhir,
    userId: userId,
    project: project,
  });

  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const doesUserHaveEthicalProject = loggedUser?.projects?.some((project) =>
    isEqual(project.value, "ethical")
  );

  if (isLoading) {
    return (
      <Box width={"100%"} m={4}>
        <CircularProgress isIndeterminate />
      </Box>
    );
  }

  if (error) {
    return (
      <Text
        fontSize={24}
        fontWeight={700}
        sx={{ my: 5, wordBreak: "break-word" }}
        color={theme.colors.exodus.red}
      >
        {error.message}
      </Text>
    );
  }

  return (
    <Stack>
      <Text
        fontSize={24}
        fontWeight={700}
        sx={{ my: 5, wordBreak: "break-word" }}
        textAlign={"left"}
        color={theme.colors.exodus.primaryBlue}
      >
        {`Biaya Total Keseluruhan: ${CurrencyIdrFormatter.convertToRp(
          data?.totalCost ?? 0
        )}`}
      </Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {doesUserHaveEthicalProject && (
          <BiayaMurniGeneralCard
            title={"PSSP Dokter"}
            total={data?.psspDoctorCost ?? 0}
            url={"pssp-dokter"}
          />
        )}
        {doesUserHaveEthicalProject && (
          <BiayaMurniGeneralCard
            title={"PSSP Non-Dokter"}
            total={data?.psspNonDoctorCost ?? 0}
            url={"pssp-non-dokter"}
          />
        )}
        {doesUserHaveEthicalProject && (
          <BiayaMurniGeneralCard
            title={"Entertainment"}
            total={data?.entertainCost ?? 0}
            url={"entertainment"}
          />
        )}
        <BiayaMurniGeneralCard
          title={"DPL/DPF"}
          total={data?.dplDpfCost ?? 0}
          url={"dpl-dpf"}
        />
        {doesUserHaveEthicalProject && (
          <BiayaMurniGeneralCard
            title={"Standarisasi"}
            total={data?.standarisasiCost ?? 0}
            url={"standarisasi"}
          />
        )}
        {doesUserHaveEthicalProject && (
          <BiayaMurniGeneralCard
            title={"DP"}
            total={data?.dpCost ?? 0}
            url={"dp"}
          />
        )}
      </Grid>
    </Stack>
  );
};

export default BiayaMurniCostsGrid;
