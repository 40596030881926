import { Button } from "@chakra-ui/react";
import { MouseEventHandler } from "react";
import { MdFilterListAlt } from "react-icons/md";

const ShowFilterButton = (props: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean | undefined;
}): JSX.Element => {
  return (
    <Button
      colorScheme="buttonSecondary"
      variant={"ghost"}
      leftIcon={<MdFilterListAlt />}
      onClick={props.onClick}
      isDisabled={props.isDisabled}
      minWidth={143}
    >
      Show Filter
    </Button>
  );
};

export default ShowFilterButton;
