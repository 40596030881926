import { useState } from "react";
import SalesTargetProvider from "../dataProviders/SalesTargetProvider";
import { InputSalesTargetParams } from "../dataProviders/SalesTargetParamType";
import ErrorResponse from "../../../../models/common/ErrorResponse";

type CallResultHandler = {
  onSuccess: () => void;
  onError: (error: ErrorResponse) => void;
};

const useInputAndEditSalesTarget = () => {
  const [loadingInput, setLoading] = useState<boolean>(false);
  const [errorMessageInput, setErrorMessage] = useState<ErrorResponse | null>(
    null
  );

  const inputAndEditSalesTarget = async (
    data: InputSalesTargetParams,
    handler: CallResultHandler
  ) => {
    try {
      setErrorMessage(null);
      setLoading(true);

      await SalesTargetProvider.inputAndEditSalesTarget(data);

      setLoading(false);
      handler.onSuccess();
    } catch (error) {
      if (error as ErrorResponse) {
        setErrorMessage(error as ErrorResponse);
      }
      setLoading(false);
      handler.onError(error as ErrorResponse);
    }
  };

  return { inputAndEditSalesTarget, loadingInput, errorMessageInput };
};

export default useInputAndEditSalesTarget;
