import { Stack, useMediaQuery, useTheme } from "@chakra-ui/react";
import { BiayaMurniFilterContextProvider } from "../contexts/BiayaMurniFilterContext";
import PageTitle from "../../../components/PageTitle/PageTitle";
import BiayaMurniCostsGrid from "./desktop/BiayaMurniCostsGrid";
import BiayaMurniFilter from "./filter/BiayaMurniFilter";
import { useNavigate } from "react-router-dom";
import useTitle from "../../../services/useTitle";
import { BiayaMurniListContextProvider } from "../contexts/BiayaMurniListContext";

const BiayaMurniPage = (): JSX.Element => {
  const theme = useTheme();
  const [isDesktop] = useMediaQuery(`(min-width: ${theme.breakpoints.tablet})`);

  const navigate = useNavigate();
  if (!isDesktop) {
    navigate(`/biaya-murni/pssp-dokter`);
  }

  useTitle("Biaya Murni");

  return (
    <BiayaMurniFilterContextProvider>
      <BiayaMurniListContextProvider featureType={"general"}>
        <Stack>
          <PageTitle label="Biaya Murni" />
          <BiayaMurniFilter isEditable />
          <BiayaMurniCostsGrid />
        </Stack>
      </BiayaMurniListContextProvider>
    </BiayaMurniFilterContextProvider>
  );
};

export default BiayaMurniPage;
