import { useEffect, useState } from "react";
import RealisasiKunjunganProvider from "../../dataProviders/RealisasiKunjunganProvider";
import { MetadataApprovalRealisasi } from "../models/ApprovalRealisasiIndexDbModels";
import { isEmpty } from "lodash";

const useMetadataApprovalRealisasi = () => {
  const [message, setMessage] = useState<string>("");
  const [loadingSave, setLoadingSave] = useState<boolean>(true);

  const [data, setData] = useState<MetadataApprovalRealisasi | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      try {
        const result: MetadataApprovalRealisasi | null =
          await RealisasiKunjunganProvider.getSavedMetadataApprovalRealisasi();

        if (isEmpty(result)) {
          setData({ currentPage: 1, limit: 10 });
        }
        if (!isEmpty(result)) {
          setData(result);
          return;
        }
      } catch (error: any) {
        setErrorMessage(error);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  const saveData = async (data: MetadataApprovalRealisasi) => {
    setLoadingSave(true);
    try {
      const saveMetadata = {
        ...data,
      };
      const result: string =
        await RealisasiKunjunganProvider.saveMetadataApprovalRealisasi(
          saveMetadata
        );
      setData(data);
      setMessage(result);
    } catch (error: unknown) {
      setMessage(error as string);
    } finally {
      setLoadingSave(false);
    }
  };

  return { data, isLoading, errorMessage, message, loadingSave, saveData };
};

export default useMetadataApprovalRealisasi;
