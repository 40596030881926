import {
  Accordion,
  Center,
  HStack,
  Spinner,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import BiayaMurniMobilePageSelector from "../../../../components/mobile/BiayaMurniMobilePageSelector";
import BiayaMurniFilter from "../../../../components/filter/BiayaMurniFilter";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import { useBiayaMurniFilterContext } from "../../../../contexts/BiayaMurniFilterContext";
import { useBiayaMurniListContext } from "../../../../contexts/BiayaMurniListContext";
import { BiayaMurniDplDpfData } from "../../../../models/apiResponses/BiayaMurniDplDpfData";
import DataNotFound from "../../../../../../components/DataNotFound/DataNotFound";
import Pagination from "../../../../../../components/Pagination/Pagination";
import BiayaMurniDplDpfAccordionItem from "./components/BiayaMurniDplDpfAccordionItem";

const BiayaMurniDplDpfPageMobile = (): JSX.Element => {
  const theme = useTheme();
  const {
    states: { error: errorFilterContext },
  } = useBiayaMurniFilterContext();

  const {
    states: { data, error: errorGetList, isLoading: isLoadingGetList },
    actions: { setLimit, setPage },
  } = useBiayaMurniListContext();

  if (errorFilterContext || errorGetList) {
    return (
      <VStack alignItems={"stretch"}>
        <PageTitle label="DPL/DPF" />
        <ErrorPage
          message={
            errorFilterContext
              ? errorFilterContext.message
              : errorGetList?.message!!
          }
        />
      </VStack>
    );
  }
  return (
    <VStack alignItems={"stretch"}>
      <PageTitle label="DPL/DPF" />
      <BiayaMurniMobilePageSelector />
      <BiayaMurniFilter isEditable />
      {isLoadingGetList ? (
        <Center>
          <Spinner
            thickness="4px"
            speed="1s"
            emptyColor={theme.colors.exodus.background}
            color={theme.colors.exodus.primaryBlue}
            size="xl"
          />
        </Center>
      ) : data === null ? (
        <DataNotFound />
      ) : (
        <>
          {data?.records && (
            <Accordion allowMultiple>
              {(data.records as Array<BiayaMurniDplDpfData>).map(
                (record: BiayaMurniDplDpfData, key: number) => (
                  <BiayaMurniDplDpfAccordionItem
                    record={record}
                    key={key}
                    hasProject={data.hasProject}
                  />
                )
              )}
            </Accordion>
          )}
          <HStack justifyContent={"center"}>
            <Pagination
              handleOnBack={() => setPage("prev")}
              handleOnLimitChange={setLimit}
              handleOnNavigateFirstPage={() => setPage("first")}
              handleOnNext={() => setPage("next")}
              limit={data?.metadata?.totalDataPerPage}
              page={data?.metadata?.currentPage}
              showCurrentPageDetail
              showLimit
              showNavigateFirstPage
              totalData={data?.metadata?.totalData}
              totalPage={data?.metadata?.totalPage}
            />
          </HStack>
        </>
      )}
    </VStack>
  );
};

export default BiayaMurniDplDpfPageMobile;
