import { Box, useTheme } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";

type StackedColumnProps = {
  topValue: string | number;
  bottomValue: string | number;
  isTopBoldApplied: boolean;
  isTopColorPrimaryBlue?: boolean;
};

const StackedColumn = ({
  topValue,
  bottomValue,
  isTopBoldApplied,
  isTopColorPrimaryBlue = false,
}: StackedColumnProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Box sx={{ textAlign: "left" }}>
      <Text
        fontWeight={isTopBoldApplied === true ? "semibold" : "normal"}
        color={
          isTopColorPrimaryBlue
            ? theme.colors.exodus.primaryBlue
            : theme.colors.exodus.fontDefault
        }
        fontSize={14}
      >
        {topValue}
      </Text>
      <Text fontSize={14}>{bottomValue}</Text>
    </Box>
  );
};

export default StackedColumn;
