import { useState } from "react";
import { OutletModel } from "../../models/Outlet/OutletModel";
import OutletProvider from "../../dataProviders/common/Outlet/OutletProvider";
import { OutletByCodeOrPanelParam } from "../../dataProviders/common/Outlet/OutletParamType";
import ErrorResponse from "../../models/common/ErrorResponse";

const useGetOutletByCodeOrPanel = () => {
  const [outletData, setOutletData] = useState<OutletModel | null>(null);
  const [apotekPanelData, setApotekPanelData] = useState<OutletModel | null>(
    null
  );
  const [outletError, setOutletError] = useState<ErrorResponse | null>(null);
  const [apotekPanelError, setApotekPanelError] =
    useState<ErrorResponse | null>(null);
  const [outletSuccessFetch, setOutletSuccessFetch] = useState<string | null>(
    null
  );
  const [apotekPanelSuccessFetch, setApotekSuccessFetch] = useState<
    string | null
  >(null);

  const [outletLoading, setOutletLoading] = useState<boolean>(false);
  const [apotekPanelLoading, setApotekPanelLoading] = useState<boolean>(false);

  async function fetchOutletData({
    outletCode,
    panel,
  }: OutletByCodeOrPanelParam) {
    switch (panel) {
      case true: {
        fetchApotekPanel(outletCode);
        break;
      }
      default: {
        fetchOutlet(outletCode);
        break;
      }
    }
  }

  async function fetchApotekPanel(outletCode: string) {
    setApotekPanelError(null);
    setApotekSuccessFetch(null);
    setApotekPanelLoading(true);
    try {
      const response: OutletModel | undefined =
        await OutletProvider.getOutletByCodeOrPanel({
          outletCode: outletCode,
          panel: true,
        });

      setApotekPanelData(response!);
      setApotekSuccessFetch("Outlet Panel terpilih");
    } catch (error) {
      setApotekPanelData(null);
      if (error as ErrorResponse) {
        setApotekPanelError(error as ErrorResponse);
      }
    } finally {
      setApotekPanelLoading(false);
    }
  }

  async function fetchOutlet(outletCode: string) {
    setOutletError(null);
    setOutletSuccessFetch(null);
    setOutletLoading(true);
    try {
      const response: OutletModel | undefined =
        await OutletProvider.getOutletByCodeOrPanel({
          outletCode: outletCode,
          panel: false,
        });

      setOutletData(response!);
      setOutletSuccessFetch("Outlet Tujuan terpilih");
    } catch (error) {
      setOutletData(null);
      if (error as ErrorResponse) {
        setOutletError(error as ErrorResponse);
      }
    } finally {
      setOutletLoading(false);
    }
  }

  return {
    outletData,
    apotekPanelData,
    outletError,
    apotekPanelError,
    outletSuccessFetch,
    apotekPanelSuccessFetch,
    outletLoading,
    apotekPanelLoading,
    fetchOutletData,
  };
};

export default useGetOutletByCodeOrPanel;
