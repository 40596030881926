import axios, { CreateAxiosDefaults } from "axios";

const clientConfig: CreateAxiosDefaults = {
  baseURL: import.meta.env.VITE_REACT_APP_AUTH_BASE_URL,
  withCredentials: true,
};

const AxiosAuthClient = axios.create(clientConfig);

export default AxiosAuthClient;
