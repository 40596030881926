import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { DraftRencanaKunjunganCreateBody } from "../../models/CreateDraftRencanaKunjunganModel";
import DraftRencanaKunjunganUpdateBody from "../../models/UpdateDraftRencanaKunjunganModel";
import TujuanKunjunganDataModel from "../../../../models/TujuanKunjunganDataModel";

type Props = {
  selectedTujuan: TujuanKunjunganDataModel | null;
  defaultValue?: string;
};

const RencanaKunjunganTujuanLainFormField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const {
    register,
    formState: { errors },
  } = useFormContext<
    DraftRencanaKunjunganCreateBody | DraftRencanaKunjunganUpdateBody
  >();

  return (
    <FormControl
      width="100%"
      marginTop={!isDesktopView ? "16px" : 0}
      isInvalid={!!errors.tujuan_lain}
    >
      <Box height={"100%"} maxHeight={"45px"} />
      <Input
        {...register("tujuan_lain", {
          required: {
            value:
              props.selectedTujuan?.visitName === "Lain-lain (diisi sendiri)"
                ? true
                : false,
            message: "Required",
          },
          validate: (value) => {
            if (
              props.selectedTujuan?.visitName === "Lain-lain (diisi sendiri)"
            ) {
              if (value && value.trim() === "") {
                return "Required";
              }
            }
          },
        })}
        placeholder="Tujuan Kunjungan (Isi jika Lain-lain)"
        disabled={
          props.selectedTujuan?.visitName !== "Lain-lain (diisi sendiri)"
        }
        defaultValue={props.defaultValue}
        _disabled={{
          bgColor: theme.colors.exodus.lightGrey,
          cursor: "not-allowed",
        }}
      />
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.tujuan_lain && (errors.tujuan_lain.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default RencanaKunjunganTujuanLainFormField;
