const blobToBase64 = async (image: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);

    reader.onloadend = () => {
      if (reader.result) {
        const base64Result = reader.result as string;
        resolve(base64Result);
      } else {
        reject(
          new Error(
            "Error converting file to base64. Error detail: " +
              reader.error?.message
          )
        );
      }
    };
  });
};

export default blobToBase64;
