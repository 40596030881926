import { Box, Text } from "@chakra-ui/react";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import MonitoringBudgetingContext from "../context/MonitoringBudgetingFilterContext";
import React from "react";

import theme from "../../../layout/theme/theme";
import ProjectType from "../../../models/common/ProjectType";
import LoggedUser from "../../../models/common/LoggedUser";
import { useAppSelector } from "../../../redux/hooks";

const ProjectAvailableInput = () => {
  const formContext = useFormContext();

  const { filterValues } = React.useContext(MonitoringBudgetingContext);

  const [selectedProject, setSelectedProject] = useState<
    ProjectType | null | undefined
  >(filterValues?.projectAvailable);

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  return (
    <>
      {
        <Box sx={{ marginBottom: "3%" }}>
          <Text fontSize="md" as="b" color={theme.colors.exodus.primaryBlue}>
            Project
          </Text>
          <CustomSelect
            isDisabled={true} //selamanya di disable, special case monitoring budgeting
            isSearchable={true}
            isClearable={true}
            onChange={(selectedData: ProjectType | null) => {
              setSelectedProject(selectedData);
              formContext.setValue("projectAvailable", selectedData);
            }}
            value={selectedProject}
            getOptionLabel={(data) => `${data.label}`}
            getOptionValue={undefined}
            placeholder={"Project Tersedia"}
            options={loggedUser?.projects ? loggedUser?.projects : []}
          />
        </Box>
      }
    </>
  );
};

export default ProjectAvailableInput;
