import { Box } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import ZoneModel from "../../../../../models/Zone/ZoneModel";
import useGetZoneList from "../../../../../services/Zone/useGetZoneList";
import { RoleNameToZoneType } from "../../../../../utils/RoleNameToZoneType";

const FilterByZone = () => {
  const formContext = useFormContext();
  const watchRole = formContext.watch("role");
  const watchZone = formContext.watch("zone");

  const { zoneData } = useGetZoneList({
    userId: null,
    salesZoneType: RoleNameToZoneType(watchRole?.name ?? null),
  });

  return (
    <Box width={"100%"}>
      <FormInputLabel title="Zona" />
      <CustomSelect
        options={zoneData}
        value={watchZone}
        onChange={(eachZone: ZoneModel | null) => {
          if (eachZone) {
            formContext.setValue("zone", eachZone);
            formContext.setValue("bawahan", null);
            formContext.setValue("outlet", null);
          } else {
            formContext.setValue("zone", null);
          }
          formContext.setValue("outlet", null);
        }}
        getOptionLabel={(eachZone: ZoneModel) => eachZone.salesZoneName}
        placeholder="Zona"
        getOptionValue={(eachZone) => eachZone.salesZoneId}
        isClearable
        isDisabled={isEmpty(watchRole)}
      />
    </Box>
  );
};

export default FilterByZone;
