class ExcelSalesDistributorModel {
  message!: string;
  status!: number;
  code!: string;
  data!: GetExcelSalesDistributorData;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = new GetExcelSalesDistributorData(json["data"]);
  }
}

class GetExcelSalesDistributorData {
  headerRecords: ExcelSalesDistributorHeaderRecords[];
  detailRecords: DetailInvoiceProduct[];

  constructor(json: any) {
    this.headerRecords = [];
    this.detailRecords = [];

    if (json && json.length !== 0) {
      this.mapFromJson(json);
    }
  }

  mapFromJson(json: any) {
    this.headerRecords = json.map(
      (item: any) => new ExcelSalesDistributorHeaderRecords(item)
    );
    this.detailRecords = json.flatMap(
      (item: any) =>
        new ExcelSalesDistributorDetailRecords(item).detailInvoiceProduct
    );
  }
}

class ExcelSalesDistributorHeaderRecords {
  salesHeaderId!: number;
  outletBridgingId!: number;
  nomorInvoice!: string;
  salesPeriode!: number;
  distributorId!: number;
  distributorName!: string;
  outletIdDistributor!: string;
  outletId!: number;
  outletName!: string;
  outletCode!: string;
  outletSectorType!: string;
  totalSales!: number;
  totalSalesBridging!: number;
  project!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.salesHeaderId = json["sales_header_id"];
    this.outletBridgingId = json["outlet_bridging_id"];
    this.nomorInvoice = json["nomor_invoice"];
    this.salesPeriode = json["sales_period"];
    this.distributorId = json["distributor_id"];
    this.distributorName = json["distributor_name"];
    this.outletIdDistributor = json["outlet_id_distributor"];
    this.outletId = json["outlet_id"];
    this.outletName = json["outlet_name"];
    this.outletCode = json["outlet_code"];
    this.outletSectorType = json["outlet_sector_type"];
    this.totalSales = json["total_sales"];
    this.totalSalesBridging = json["total_sales_bridging"];
    this.project = json["project"];
  }
}

type DetailInvoiceProduct = {
  nomorInvoice?: string;
  productId?: number;
  productName?: string;
  productCode?: number;
  productCodeDistributor?: string;
  productNameDistributor?: string;
  quantity?: number;
  price?: number;
  discountPI?: number;
  subtotal?: number;
  project?: string;
};

class ExcelSalesDistributorDetailRecords {
  detailInvoiceProduct: DetailInvoiceProduct[] = [];

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    const nomorInvoice = json["nomor_invoice"];

    this.detailInvoiceProduct = json["detail_sales_distributor"].map(
      (detailSalesDistributor: any) => ({
        nomorInvoice: nomorInvoice,
        productId: detailSalesDistributor["product_id"],
        productName: detailSalesDistributor["product_name"],
        productCode: detailSalesDistributor["product_code"],
        productCodeDistributor:
          detailSalesDistributor["product_code_distributor"],
        productNameDistributor:
          detailSalesDistributor["product_name_distributor"],
        quantity: detailSalesDistributor["quantity"],
        price: detailSalesDistributor["sale_price"],
        discountPI: detailSalesDistributor["diskon_pi"],
        subtotal: detailSalesDistributor["subtotal"],
        project: detailSalesDistributor["project"],
      })
    );
  }
}

export { ExcelSalesDistributorModel, GetExcelSalesDistributorData };
