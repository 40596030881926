import { createContext } from "react";
import AllPeriodeDataModel from "../../../models/AllPeriodeDataModel";

type AllPeriodeApprovalRealisasiContextProps = {
    periodeData: AllPeriodeDataModel[];
    periodeLoading: boolean;
    periodeError: string | null;
  };
  
  const defaultValue: AllPeriodeApprovalRealisasiContextProps = {
    periodeData: [],
    periodeLoading: false,
    periodeError: null,
  };
  
  const AllPeriodeApprovalRealisasiContext = createContext(defaultValue);
  
  export default AllPeriodeApprovalRealisasiContext;