import { useEffect, useState } from "react";
import BudgetNasional from "../model/BudgetNasional";
import DashboardDataProvider from "../dataProvider/DashboardDataProvider";
import CurrencyIdrFormatter from "../../../utils/CurrencyIdrFormatter";
import LoggedUser from "../../../models/common/LoggedUser";
import IsTypeOfErrorResponse from "../../../utils/IsTypeOfErrorResponse";
import { UNKNOWN_ERROR_RESPONSE } from "../../../constants/common/ErrorConstants";
import { ProjectEnum } from "../../../models/common/Project";

const useGetBudgetNasional = (loggedUser: LoggedUser | null) => {
  const [data, setData] = useState<BudgetNasional | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        setError(null);

        const result = await DashboardDataProvider.getBudgetNasional();

        const convertedData: BudgetNasional = {
          persentase: result.data["percentage"] * 100,
          budgetNasional: result.data["budget_nasional"],
          budgetNasionalValueUi: CurrencyIdrFormatter.convertToRp(
            result.data["budget_nasional"]
          ),
          totalSales: result.data["sales_nasional"],
          totalSalesValueUi: CurrencyIdrFormatter.convertToRp(
            result.data["sales_nasional"]
          ),
          biayaKumulatif: result.data["cost_murni"],
          biayaKumulatifValueUi: CurrencyIdrFormatter.convertToRp(
            result.data["cost_murni"]
          ),
        };

        setData(convertedData);
      } catch (error: any) {
        if (IsTypeOfErrorResponse(error)) {
          setError(error.message);
          return;
        }
        setError(UNKNOWN_ERROR_RESPONSE.message);
      } finally {
        setLoading(false);
      }
    }

    if (
      loggedUser &&
      loggedUser?.projects.find((e) => e.value === ProjectEnum.Ethical)
    ) {
      getData();
    }
  }, [loggedUser]);

  return {
    data,
    loading,
    error,
  };
};

export default useGetBudgetNasional;
