import React, { useState } from "react";
import SalesDistributorFilterValue from "../model/SalesDistributorFilterValues";
import SalesDistributorDataProvider from "../dataProvider/SalesDistributorDataProvider";
import SalesDistributorIntialValue from "../utils/SalesDistributorInitialValue";
import { isEmpty } from "lodash";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import DateExodus from "../../../../models/DateExodus";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";

const useGetFilter = () => {
  const [data, setData] = useState<SalesDistributorFilterValue | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorGetFilter, setErrorGetFilter] = useState<ErrorResponse | null>(
    null
  );

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);

      try {
        setErrorGetFilter(null);
        const result: SalesDistributorFilterValue =
          await SalesDistributorDataProvider.getSavedFilter();
        if (isEmpty(result)) {
          setData(SalesDistributorIntialValue);
          return;
        }
        setData({
          ...result,
          periodeAkhir: new DateExodus(
            result.periodeAkhir as unknown as number
          ),
          periodeAwal: new DateExodus(result.periodeAwal as unknown as number),
        });
      } catch (error: unknown) {
        setErrorGetFilter(UNKNOWN_ERROR_RESPONSE);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return { data, isLoading, errorGetFilter };
};

export default useGetFilter;
