export const ROLE_ID_FINANCE = 11;
export const ROLE_ID_MSD = 10;
export const ROLE_ID_MARKETING_DIRECTOR = 9;
export const ROLE_ID_FIC = 8;
export const ROLE_ID_FF = 7;
export const ROLE_ID_ASM = 6;
export const ROLE_ID_SM = 5;
export const ROLE_ID_NSM = 4;
export const ROLE_ID_TRADE_TEAM = 3;
export const ROLE_ID_DIRECTOR = 2;
export const ROLE_ID_SUPER_ADMIN = 1;
export const ROLE_ID_UNKNOWN = -1;
