/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, useMediaQuery, useTheme, useToast } from "@chakra-ui/react";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../models/RealisasiAndExtraCallDataModel";
import { MdEdit } from "react-icons/md";
import { useContext, useState } from "react";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";
import DialogInputRealisasiKunjungan from "./DialogInputRealisasiKunjungan";
import DialogInputEditExtraCall from "./DialogInputEditExtraCall";
import useInputRealisasiKunjungan from "../services/useInputRealisasiKunjungan";
import InputRealisasiKunjunganBody from "../models/InputRealisasiKunjunganModel";
import ImageCompressor from "../../../../../utils/ImageCompressor";
import blobToBase64 from "../../../../../utils/BlobToBase64";
import dayjs from "dayjs";
import DateExodus from "../../../../../models/DateExodus";
import CreateAndUpdateExtraCallBody from "../models/CreateAndUpdateExtraCallModel";
import useUpdateExtraCall from "../services/useUpdateExtraCall";
import { CommonStatus } from "../../../../../models/common/Status";

type Props = {
  data: RealisasiAndExtraCallDetailRealizationModelData;
  buttonText: string;
};

const ButtonInputRealisasiAndEditExtraCall: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const toast = useToast();

  const { selectedPeriode, currentKunjungan, key, setKey } = useContext(
    InputRealisasiKunjunganContext
  );

  const { inputRealisasiKunjungan, loading: loadingRealisasi } =
    useInputRealisasiKunjungan();

  const { updateExtraCall, loading: loadingExtraCall } = useUpdateExtraCall();

  const [photoProcessLoading, setPhotoProcessLoading] =
    useState<boolean>(false);
  const [openDialogInputRealisasi, setOpenDialogInputRealisasi] =
    useState<boolean>(false);
  const [openDialogEditExtraCall, setOpenDialogEditExtraCall] =
    useState<boolean>(false);

  const handleCompressImage = async (
    value: InputRealisasiKunjunganBody | CreateAndUpdateExtraCallBody,
    isGagalKunjungan?: boolean
  ) => {
    let photo;
    let compressedImage: File;

    if (value.photo !== null && value.photo !== undefined) {
      setPhotoProcessLoading(true);
      try {
        const image = value.photo as any;

        if (!image[0] && !props.data.photo) {
          setPhotoProcessLoading(false);

          if (isGagalKunjungan) {
            return null;
          }

          toast({
            title: "Gagal Melakukan Kompresi Gambar",
            description: "Silahkan pilih gambar terlebih dahulu",
            status: "error",
          });
          return null;
        } else if (!image[0] && props.data.photo) {
          photo = props.data.photo;
          return photo;
        } else {
          compressedImage = await ImageCompressor(image[0]);
          photo = await blobToBase64(compressedImage);
        }
      } catch (error: any) {
        setPhotoProcessLoading(false);
        toast({
          title: error.toString(),
          status: "error",
        });
        return null;
      }
    }

    setPhotoProcessLoading(false);

    if (isGagalKunjungan) {
      return photo;
    } else {
      if (value.photo !== null && value.photo !== undefined) {
        return photo;
      } else {
        photo = props.data.photo;
        return photo;
      }
    }
  };

  const handleInputOrUpdateRealisasi = async (
    value: InputRealisasiKunjunganBody,
    isGagalKunjungan: boolean
  ) => {
    let submittedData: InputRealisasiKunjunganBody;

    if (isGagalKunjungan) {
      const photo = await handleCompressImage(value, isGagalKunjungan);

      submittedData = {
        visitor_comment: value.visitor_comment.trim(),
        photo: photo,
      };
    } else {
      const date = new DateExodus(dayjs(value.date).unix());
      const startTime = new DateExodus(dayjs(value.start_time).unix());
      const endTime = new DateExodus(dayjs(value.end_time).unix());

      if (startTime.getEpochDate() > endTime.getEpochDate()) {
        toast({
          title: "Gagal Menginput Realisasi Kunjungan",
          description: "Jam Mulai tidak boleh melebihi Jam Selesai",
          status: "error",
        });
        return;
      }

      const photo = await handleCompressImage(value);

      if (photo === null) return;

      submittedData = {
        date: parseInt(JSON.stringify(date)),
        start_time: parseInt(JSON.stringify(startTime)),
        end_time: parseInt(JSON.stringify(endTime)),
        visitor_comment: value.visitor_comment.trim(),
        photo: photo,
      };
    }

    inputRealisasiKunjungan(props.data.id, isGagalKunjungan, submittedData, {
      onSuccess: () => {
        setOpenDialogInputRealisasi(false);
        toast({
          title: `Berhasil Menginput Realisasi Kunjungan`,
          status: "success",
        });
        setKey(key + 1);
      },
      onError: (errorMessage: string) => {
        toast({
          title: `Gagal Menginput Realisasi Kunjungan`,
          description: `${errorMessage}`,
          status: "error",
        });
      },
    });
  };

  const handleEditExtraCall = async (value: CreateAndUpdateExtraCallBody) => {
    const date = new DateExodus(dayjs(value.date).unix());
    const startTime = new DateExodus(dayjs(value.start_time).unix());
    const endTime = new DateExodus(dayjs(value.end_time).unix());

    if (startTime.getEpochDate() > endTime.getEpochDate()) {
      toast({
        title: "Gagal Mengubah Extra Call",
        description: "Jam Mulai tidak boleh melebihi Jam Selesai",
        status: "error",
      });
      return;
    }

    const photo = await handleCompressImage(value);

    const submittedData: CreateAndUpdateExtraCallBody = {
      visit_header_id: selectedPeriode!.header_id,
      outlet_id: value.outlet_id,
      customer_id: value.customer_id,
      purpose_of_visit:
        value.purpose_of_visit === "Lain-lain (diisi sendiri)"
          ? value.tujuan_lain!.trim()
          : value.purpose_of_visit,
      date: parseInt(JSON.stringify(date)),
      start_time: parseInt(JSON.stringify(startTime)),
      end_time: parseInt(JSON.stringify(endTime)),
      visitor_comment: value.visitor_comment.trim(),
      photo: photo ?? props.data.photo,
      visit_type: "extra call",
      position_customer: value.position_customer,
    };

    updateExtraCall(props.data.id, submittedData, {
      onSuccess: () => {
        setOpenDialogEditExtraCall(false);
        toast({
          title: `Berhasil Mengubah Extra Call`,
          status: "success",
        });
        setKey(key + 1);
      },
      onError: (errorMessage: string) => {
        toast({
          title: `Gagal Mengubah Extra Call`,
          description: `${errorMessage}`,
          status: "error",
        });
      },
    });
  };

  const disableButtonInputAndEditCondition = (): boolean => {
    if (!selectedPeriode) return true;

    const dateNow = new DateExodus();

    const isDateNowGreaterThanSelectedEndPeriod: boolean =
      parseInt(JSON.stringify(dateNow)) >
      parseInt(JSON.stringify(selectedPeriode.end_period));

    if (selectedPeriode.status_realization !== CommonStatus.Pending) {
      return true;
    } else if (isDateNowGreaterThanSelectedEndPeriod) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Button
        variant="ghost"
        leftIcon={
          <MdEdit
            style={{
              width: !isDesktopView ? "20px" : "24px",
              height: !isDesktopView ? "20px" : "24px",
            }}
          />
        }
        isDisabled={disableButtonInputAndEditCondition()}
        onClick={() => {
          if (currentKunjungan === "rencana") {
            setOpenDialogInputRealisasi(true);
          }

          if (currentKunjungan === "extracall") {
            setOpenDialogEditExtraCall(true);
          }
        }}
        sx={{
          fontSize: !isDesktopView ? 12 : 14,
          fontWeight: "bold",
          color: theme.colors.exodus.secondaryBlue,
          // paddingX: !isDesktopView ? "8px" : 0,
        }}
      >
        {props.buttonText}
      </Button>

      <DialogInputRealisasiKunjungan
        data={props.data}
        openDialogInputRealisasi={openDialogInputRealisasi}
        onClose={() => setOpenDialogInputRealisasi(false)}
        onSubmit={handleInputOrUpdateRealisasi}
        isLoadingSubmit={loadingRealisasi}
        isLoadingPhotoProcessing={photoProcessLoading}
      />

      <DialogInputEditExtraCall
        data={props.data}
        type="update"
        dialogTitle="Edit Extra Call"
        openDialogInputEditExtraCall={openDialogEditExtraCall}
        onClose={() => setOpenDialogEditExtraCall(false)}
        onSubmit={handleEditExtraCall}
        isLoadingPhotoProcessing={photoProcessLoading}
        isLoadingSubmit={loadingExtraCall}
      />
    </>
  );
};

export default ButtonInputRealisasiAndEditExtraCall;
