import { useMediaQuery, useTheme } from "@chakra-ui/react";
import StepperControlFrame from "../../common/StepperControlFrame";
import { useStepper } from "../../../contexts/StepperContextProvider";
import DesktopPilihProdukKlaimSales from "./desktop/DesktopPilihProdukKlaimSales";
import MobilePilihProdukKlaimSales from "./mobile/MobilePilihProdukKlaimSales";
import {
  PilihProdukProvider,
  usePilihProduk,
} from "../contexts/PilihProdukContextProvider";
import DialogKonfirmasiHapusFaktur from "./DialogKonfirmasiHapusFaktur";

/**
 * Main view for Input Klaim Sales's Pilih Produk (Step 3).
 *
 * @remarks
 * A place to call: This step's logic provider, [StepperControlFrame], and
 * handle the next or back of the stepper. Also decides which to use: Desktop
 * or mobile view.
 *
 * @returns JSX.Element
 *
 */
const PilihProdukKlaimSalesBase = (): JSX.Element => {
  return (
    <PilihProdukProvider>
      <_ThisStepperControlFrame />
    </PilihProdukProvider>
  );
};

const _ThisStepperControlFrame = (): JSX.Element => {
  const {
    actions: { handleNext, handleBack },
  } = useStepper();

  const {
    state: { isValidForNextStep },
    actions: { clearFakturList },
  } = usePilihProduk();

  const onHandleBack = () => {
    clearFakturList();
    handleBack();
  };

  const onHandleNext = () => {
    handleNext();
  };

  return (
    <StepperControlFrame
      onHandleBack={onHandleBack}
      onHandleNext={isValidForNextStep ? onHandleNext : undefined}
    >
      <DialogKonfirmasiHapusFaktur />
      <_ThisView />
    </StepperControlFrame>
  );
};

const _ThisView = (): JSX.Element => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return isDesktopView ? (
    <DesktopPilihProdukKlaimSales />
  ) : (
    <MobilePilihProdukKlaimSales />
  );
};

export default PilihProdukKlaimSalesBase;
