import {
  Box,
  Card,
  IconButton,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { FakturByCodeModelData } from "../../../models/FakturByCodeModel";
import { MdClose } from "react-icons/md";

type Props = {
  data: FakturByCodeModelData[];
  onRemove: (salesHeaderId: number) => void;
};

const ListFakturTerpilih: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  if (props.data.length === 0) {
    return (
      <Box py="220px" width="100%" display="flex" justifyContent="center">
        <Text fontSize="18px">Tidak Ada Faktur yang Terpilih</Text>
      </Box>
    );
  }

  return (
    <Box marginTop={isDesktopView ? "24px" : "16px"}>
      {props.data.map((data: FakturByCodeModelData) => (
        <Card
          key={data.salesHeaderId}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: "24px",
            paddingY: "12px",
            marginBottom: isDesktopView ? "24px" : "16px",
            backgroundColor: theme.colors.exodus.background,
            boxShadow: "0px 7px 24px 5px #282c7a26",
          }}
        >
          <Text
            fontSize={isDesktopView ? 20 : 16}
            fontWeight="semibold"
            color={theme.colors.exodus.primaryBlue}
          >
            {data.invoice}
          </Text>
          <IconButton
            aria-label="close"
            variant="ghost"
            colorScheme="buttonPrimary"
            isRound
            onClick={() => props.onRemove(data.salesHeaderId)}
            icon={<MdClose style={{ fontSize: 32 }} />}
          />
        </Card>
      ))}
    </Box>
  );
};

export default ListFakturTerpilih;
