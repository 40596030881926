import { FormControl, FormErrorMessage, useTheme } from "@chakra-ui/react";
import CustomerDataModel from "../../../../../../models/Customer/CustomerDataModel";
import { useFormContext } from "react-hook-form";
import CreateAndUpdateExtraCallBody from "../../models/CreateAndUpdateExtraCallModel";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";

type Props = {
  customerData: CustomerDataModel[];
  selectedCustomer: CustomerDataModel | null;
  onChange: (data: CustomerDataModel | null) => void;
  isDisabled: boolean;
};

const RealisasiExtraCallCustomerFormField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<CreateAndUpdateExtraCallBody>();

  return (
    <FormControl width="100%" isInvalid={!!errors.customer_id}>
      <FormInputLabel title="Nama Customer" />
      <CustomSelect
        {...register("customer_id", {
          required: "Required",
        })}
        options={props.customerData}
        getOptionValue={(data: CustomerDataModel) =>
          data.customer_id.toString()
        }
        getOptionLabel={(data: CustomerDataModel) =>
          `${data.customer_name} (${data.customer_code})`
        }
        onChange={(data: CustomerDataModel) => {
          setValue("customer_id", data ? data.customer_id : null);
          props.onChange(data);
        }}
        value={props.selectedCustomer}
        placeholder="Nama Customer (Kode Customer)"
        isDisabled={props.isDisabled}
        menuPlacement="auto"
      />
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.customer_id && (errors.customer_id.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default RealisasiExtraCallCustomerFormField;
