import {
  Accordion,
  Center,
  Divider,
  HStack,
  Spinner,
  Stack,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import ShowFilterButton from "../../../../../components/CustomButtons/ShowFilterButton";
import FilterBoxArea from "../common/FilterBoxArea";
import FilterModal from "../common/FilterModal";
import TotalSalesAreaMobile from "./components/TotalSalesAreaMobile";
import { useSalesProductContext } from "../../contexts/SalesProductContextProvider";
import { isEmpty } from "lodash";
import DataNotFound from "../../../../../components/DataNotFound/DataNotFound";
import { SalesProductRecordModel } from "../../models/SalesProductModel";
import SalesProductAccordionItem from "./components/SalesProductAccordionItem";
import Pagination from "../../../../../components/Pagination/Pagination";
import useExportExcel from "../../services/useExportExcel";
import ExportExcelButton from "../../../../../components/CustomButtons/ExportExcelButton";

const SalesProductMobile = () => {
  const theme = useTheme();
  const [isFilterModalOpen, setIsFilterModalOpen] =
    React.useState<boolean>(false);

  const handleOnFilterModalChange = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const { doExportExcel, isLoading: isLoadingExcel } = useExportExcel();
  const {
    states: { isLoadingList, getListData },
    actions: { setLimit, setPage },
  } = useSalesProductContext();

  const RenderLoading = () => {
    return (
      <Center>
        <Spinner
          marginTop={6}
          thickness="4px"
          speed="1s"
          emptyColor={theme.colors.exodus.background}
          color={theme.colors.exodus.primaryBlue}
          size="xl"
        />
      </Center>
    );
  };
  return (
    <Stack
      minHeight="90vh"
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Stack>
        <Stack justifyContent="space-between" flexDirection={"row"}>
          <FilterBoxArea />
          <ShowFilterButton onClick={() => handleOnFilterModalChange()} />
        </Stack>
        <TotalSalesAreaMobile />
        <Divider
          sx={{
            border: "1px",
            color: theme.colors.exodus.primaryBlue,
            width: "100%",
          }}
        />
        {isLoadingList ? (
          <RenderLoading />
        ) : isEmpty(getListData?.records) ? (
          <DataNotFound />
        ) : (
          <Accordion allowMultiple>
            {getListData?.records.map(
              (eachData: SalesProductRecordModel, key: number) => (
                <SalesProductAccordionItem record={eachData} key={key} />
              )
            )}
          </Accordion>
        )}
        {!isLoadingList && (
          <HStack justifyContent={"center"}>
            <Pagination
              handleOnBack={() => setPage("prev")}
              handleOnLimitChange={setLimit}
              handleOnNavigateFirstPage={() => setPage("first")}
              handleOnNext={() => setPage("next")}
              limit={getListData?.metadata?.totalDataPerPage}
              page={getListData?.metadata?.currentPage}
              showCurrentPageDetail
              showLimit
              showNavigateFirstPage
              totalData={getListData?.metadata?.totalData}
              totalPage={getListData?.metadata?.totalPage}
            />
          </HStack>
        )}
      </Stack>
      <Stack
        spacing={1}
        sx={{
          position: "sticky",
          pt: 2,
          pb: 2,
          bottom: 0,
          backgroundColor: theme.colors.exodus.background,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ExportExcelButton
          onClick={() => doExportExcel()}
          isLoading={isLoadingExcel}
        />
      </Stack>
      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={handleOnFilterModalChange}
      />
    </Stack>
  );
};

export default SalesProductMobile;
