import { Box, Button, useTheme, useToast } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import useUpdateStatusKlaimSales from "../../services/useUpdateStatusKlaimSales";
import DetailApprovalKlaimSalesContext from "../../contexts/DetailApprovalKlaimSalesContext";
import { CommonStatus } from "../../../../../models/common/Status";
import ErrorResponse from "../../../../../models/common/ErrorResponse";
import UpdateStatusKlaimSalesBody from "../../../models/UpdateStatusKlaimSalesModel";
import DialogRejectKlaimSales from "./DialogRejectKlaimSales";
import DialogConfirmationApproveRejectKlaimSales from "./DialogConfirmationApproveRejectKlaimSales";

const ButtonApproveKlaimSales: React.FC = () => {
  const theme = useTheme();
  const toast = useToast();

  const { detailKlaimSalesData: record, refetchDetailKlaimSales } = useContext(
    DetailApprovalKlaimSalesContext
  );
  const { updateStatusKlaimSales, loading } = useUpdateStatusKlaimSales();

  const [isApprove, setIsApprove] = useState<boolean>(false);
  const [rejectNote, setRejectNote] = useState<string>("");
  const [openRejectNote, setOpenRejectNote] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  //======================================== Function =============================================
  const disableButton = (): boolean => {
    if (loading) return true;

    switch (record!.status) {
      case CommonStatus.Pending:
        return false;
      case CommonStatus.Approved:
        return true;
      case CommonStatus.Rejected:
        return true;
      default:
        return true;
    }
  };

  const handleOnClickReject = () => {
    setIsApprove(false);
    setOpenRejectNote(true);
  };

  const handleCloseRejectNote = () => {
    setOpenRejectNote(false);
    setRejectNote("");
  };

  const handleSubmitRejectNote = (data: any) => {
    setRejectNote(data.rejectNote);
    setOpenConfirmation(true);
  };

  const handleOnClickApprove = () => {
    setIsApprove(true);
    setOpenConfirmation(true);
  };

  const handleSubmitConfirmation = () => {
    if (record) {
      let status = "";
      const data: UpdateStatusKlaimSalesBody = {
        claim_sales_list_id: record.claimSalesListId,
        approval: isApprove,
      };

      if (!isApprove) {
        data["keterangan"] = rejectNote.trim();
        status = "Reject";
      } else {
        status = "Approve";
      }

      updateStatusKlaimSales(data, {
        onSuccess: () => {
          toast({
            title: `Berhasil ${status} Klaim Sales`,
            status: "success",
          });
          setOpenConfirmation(false);
          if (!isApprove) handleCloseRejectNote();
          refetchDetailKlaimSales();
        },
        onError: (errorMessage: ErrorResponse) => {
          setRejectNote("");
          toast({
            title: `Gagal ${status} Klaim Sales.`,
            description: `${errorMessage.message}`,
            status: "error",
          });
          setOpenConfirmation(false);
        },
      });
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="row-reverse" gap={3}>
        <Button
          type="submit"
          width="100%"
          colorScheme="buttonSuccess"
          onClick={handleOnClickApprove}
          isDisabled={disableButton()}
        >
          Approve
        </Button>
        <Button
          width="100%"
          variant="outline"
          colorScheme="buttonError"
          color={theme.colors.exodus.red}
          borderColor={theme.colors.exodus.red}
          onClick={handleOnClickReject}
          isDisabled={disableButton()}
        >
          Reject
        </Button>
      </Box>
      <DialogRejectKlaimSales
        loading={loading}
        onClose={handleCloseRejectNote}
        openRejectNote={openRejectNote}
        rejectNote={rejectNote}
        onSubmit={handleSubmitRejectNote}
      />
      <DialogConfirmationApproveRejectKlaimSales
        isApprove={isApprove}
        loading={loading}
        openConfirmation={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onSubmit={handleSubmitConfirmation}
      />
    </>
  );
};

export default ButtonApproveKlaimSales;
