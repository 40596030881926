import { Box, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";
import MonitoringBudgetingContext from "../context/MonitoringBudgetingFilterContext";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DateExodus from "../../../models/DateExodus";
import dayjs from "dayjs";
import theme from "../../../layout/theme/theme";

const PeriodeInput = () => {
  const breakPointTheme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${breakPointTheme.breakpoints.tablet})`
  );

  const formContext = useFormContext();
  const { filterValues } = React.useContext(MonitoringBudgetingContext);

  const [startOfYear, setStartOfYear] = useState<Date | undefined>(
    filterValues?.periodeTahun.getDateObjectStartOfYear()
  );

  const [endOfYear, setEndOfYear] = useState<Date | undefined>(
    filterValues?.periodeTahun.getDateObjectEndOfYear()
  );

  const [initialValueYear] = useState<Date | null | undefined>(
    filterValues?.periodeTahun.getDateObject()
  );

  const [selectedMonthAwal, setSelectedMonthAwal] = useState<Date | undefined>(
    filterValues?.periodeAwal.getDateObject()
  );

  const [selectedMonthAkhir, setSelectedMonthAkhir] = useState<
    Date | undefined
  >(filterValues?.periodeAkhir.getDateObject());

  //Fungsi ini untuk get current periodeAwal dan periodeAkhir dari IndexDb
  useEffect(() => {
    setSelectedMonthAwal(filterValues?.periodeAwal.getDateObject());
    setSelectedMonthAkhir(filterValues?.periodeAkhir.getDateObject());
  }, [filterValues?.periodeTahun]);

  return (
    <>
      {
        <Box sx={{ width: "100%", marginTop: isDesktopView ? "3%" : "1%" }}>
          <Text fontSize="md" as="b" color={theme.colors.exodus.primaryBlue}>
            Periode Tahun
          </Text>
          <CustomDatePicker
            name={"test_year"}
            type="year"
            onChange={(data: Date | null) => {
              const newDate = new DateExodus(data?.getTime()! / 1000.0);
              formContext.setValue("periodeTahun", newDate);
              //Merubah state nya menjadi januari dan desember pada ui ketika tanggal diganti
              setSelectedMonthAwal(
                dayjs(newDate.getEpochStartOfMonth() * 1000)
                  .startOf("year")
                  .toDate()
              );
              setSelectedMonthAkhir(
                dayjs(newDate.getEpochEndOfMonth() * 1000)
                  .endOf("year")
                  .toDate()
              );
              //Merubah value context nya mengikuti ui menjadi januari dan desember
              formContext.setValue(
                "periodeAwal",
                new DateExodus(
                  dayjs(newDate.getEpochStartOfMonth() * 1000)
                    .startOf("year")
                    .unix()
                )
              );
              formContext.setValue(
                "periodeAkhir",
                new DateExodus(
                  dayjs(newDate.getEpochEndOfMonth() * 1000)
                    .endOf("year")
                    .unix()
                )
              );
              //Atur min max
              setEndOfYear(
                formContext.getValues("periodeTahun").getDateObjectEndOfYear()
              );
              setStartOfYear(
                formContext.getValues("periodeTahun").getDateObjectStartOfYear()
              );
            }}
            placeholder="Periode Tahun"
            disabled={false}
            defaultValue={initialValueYear}
            rules={undefined}
            maxDate={null}
            minDate={null}
          />
          {!isDesktopView && <Box sx={{ marginY: "1%" }} />}
          <Text
            sx={{ marginTop: "1.5%" }}
            fontSize="md"
            as="b"
            color={theme.colors.exodus.primaryBlue}
          >
            Periode Bulan
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: isDesktopView ? "row" : "column",
            }}
          >
            <CustomDatePicker
              name={"month_awal"}
              type="month"
              onChange={(selectedData: Date | null) => {
                const newPeriodeAwal = new DateExodus(
                  selectedData?.getTime()! / 1000.0
                );
                formContext.setValue("periodeAwal", newPeriodeAwal);
                setSelectedMonthAwal(selectedData!);
              }}
              selected={selectedMonthAwal}
              defaultValue={selectedMonthAwal}
              placeholder="Periode Awal"
              disabled={false}
              rules={undefined}
              maxDate={endOfYear}
              minDate={startOfYear}
            />
            {isDesktopView ? (
              <Box sx={{ marginX: "1.5%" }} />
            ) : (
              <Box sx={{ marginY: "1.5%" }} />
            )}
            <CustomDatePicker
              name={"month_akhir"}
              type="month"
              onChange={(selectedData: Date | null) => {
                const newPeriodeAkhir = new DateExodus(
                  selectedData?.getTime()! / 1000.0
                );
                formContext.setValue(
                  "periodeAkhir",
                  newPeriodeAkhir.getEpochEndOfMonth()
                );
                setSelectedMonthAkhir(selectedData!);
              }}
              selected={selectedMonthAkhir}
              defaultValue={selectedMonthAkhir}
              placeholder="Periode Akhir"
              disabled={false}
              rules={undefined}
              maxDate={endOfYear}
              minDate={startOfYear}
            />
          </Box>
        </Box>
      }
    </>
  );
};

export default PeriodeInput;
