import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  useTheme,
} from "@chakra-ui/react";
import HorizontalAccordionDetail from "../../../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import CurrencyIdrFormatter from "../../../../../../../utils/CurrencyIdrFormatter";
import { BiayaMurniDplDpfData } from "../../../../../models/apiResponses/BiayaMurniDplDpfData";
import LihatDetailButton from "./LihatDetailButton";
import { projectEnumToUi } from "../../../../../../../models/common/Project";

const BiayaMurniDplDpfAccordionItem = ({
  record,
  hasProject,
}: {
  record: BiayaMurniDplDpfData;
  hasProject: boolean;
}) => {
  const theme = useTheme();
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          {hasProject && (
            <Text
              fontSize={14}
              fontWeight={600}
              color={theme.colors.exodus.primaryBlue}
            >
              {projectEnumToUi(record.project)}
            </Text>
          )}
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            fontSize={14}
          >
            {record.outletName}
          </Text>
          <Text
            color={theme.colors.exodus.darkGrey}
            fontWeight={600}
            fontSize={14}
          >
            {record.outletCode}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel mx={4}>
        <HorizontalAccordionDetail
          leftText="Biaya"
          rightSide={CurrencyIdrFormatter.convertToRp(record.total) ?? "-"}
          isRowColored
        />
        <LihatDetailButton record={record} />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default BiayaMurniDplDpfAccordionItem;
