import { useTheme, Text, useMediaQuery, Box } from "@chakra-ui/react";

const VerticalTextInformation = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  return (
    <Box sx={{ gap: 0, mb: 1 }}>
      <Text
        textAlign={"left"}
        color={theme.colors.exodus.primaryBlue}
        fontSize={18}
        fontWeight={isDesktopView ? 400 : 600}
      >
        {title}
      </Text>
      <Text
        textAlign={"left"}
        fontSize={18}
        fontWeight={600}
        color={theme.colors.exodus.darkGrey}
      >
        {value}
      </Text>
    </Box>
  );
};

export default VerticalTextInformation;
