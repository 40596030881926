import { Box, Flex, useMediaQuery, useTheme } from "@chakra-ui/react";
import { useContext } from "react";
import ApprovalRencanaKunjunganContext from "../../context/ApprovalRencanaKunjunganContext";
import ShowFilterButton from "../../../../../../components/CustomButtons/ShowFilterButton";
import ExportExcelButton from "../../../../../../components/CustomButtons/ExportExcelButton";
import DropdownAllPeriode from "../../../../components/DropdownAllPeriode";
import FilterBox from "../../../../../../components/FilterBox/FilterBox";
import FilterApprovalRencanaKunjungan from "../FilterApprovalRencanaKunjungan";
import React from "react";
import useExportApprovalKunjungan from "../../services/useExportApprovalRencanaKunjungan";
import IndexedDatabase from "../../../../../../database/IndexDB";
import AllPeriodeDataModel from "../../../../models/AllPeriodeDataModel";
import useSaveApprovalRencanaMetadata from "../../services/useSaveApprovalRencanaMetadata";

const DesktopApprovalRencanaKunjunganToolbar: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const { saveMetadata } = useSaveApprovalRencanaMetadata();
  const {
    draftApprovalRencanaKunjunganData,
    periodeData,
    selectedPeriode,
    setSelectedPeriode,
    metadata,
    setPage,
    nama,
    jabatan,
    status,
    project,
    isDialogOpen,
    setIsDialogOpen,
  } = useContext(ApprovalRencanaKunjunganContext);

  const handleOpenFilterDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setIsDialogOpen(false);
  };

  const { exportData } = useExportApprovalKunjungan();

  const getIndexedDBData = async () => {
    const data = await IndexedDatabase.rencanaKunjunganFilter.toArray();

    return data;
  };

  const handleChangePeriode = async (value: AllPeriodeDataModel | null) => {
    await saveMetadata({
      limit: metadata!.limit,
      currentPage: 1,
    });
    setPage(1);

    setSelectedPeriode(value);
    await IndexedDatabase.open();
    try {
      const oldData = await getIndexedDBData();

      const newValue: any = {
        jabatan: oldData[0]?.jabatan,
        status: oldData[0]?.status,
        bawahan: oldData[0]?.bawahan,
        periode: {
          start_period: value?.start_period.getEpochDate(),
          end_period: value?.end_period.getEpochDate(),
        },
        project: oldData[0]?.project,
      };

      if (oldData.length === 0 || !oldData) {
        await IndexedDatabase.rencanaKunjunganFilter
          .add(newValue)
          .then(async () => await getIndexedDBData());
      } else {
        await IndexedDatabase.rencanaKunjunganFilter.update(
          oldData[0].id,
          newValue
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Flex justify="space-between" paddingY="32px" gap={2}>
        <Box display={"flex"} flexDirection={"row"} gap={2}>
          <Box width={isDesktopView ? "301px" : "250px"}>
            <DropdownAllPeriode
              options={periodeData}
              value={selectedPeriode}
              onChange={handleChangePeriode}
            />
          </Box>
          <Box textAlign={"left"} display={"flex"} gap={2} flexWrap={"wrap"}>
            {jabatan && <FilterBox title={"Jabatan"} content={jabatan} />}
            {nama && <FilterBox title={"Nama"} content={nama} />}
            {status && <FilterBox title={"Status"} content={status} />}
            {project && <FilterBox title={"Project"} content={project.label} />}
          </Box>
        </Box>
        <Flex gap={2} flexWrap="wrap" justify="end">
          <ShowFilterButton onClick={handleOpenFilterDialog} />
          <ExportExcelButton
            onClick={exportData}
            isDisabled={draftApprovalRencanaKunjunganData.length === 0}
          />
        </Flex>
      </Flex>
      <FilterApprovalRencanaKunjungan
        isOpen={isDialogOpen}
        onClose={handleCloseFilterDialog}
      />
    </>
  );
};

export default DesktopApprovalRencanaKunjunganToolbar;
