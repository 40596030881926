import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useTheme,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const KlaimSalesAccessWarningDialog: React.FC = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      isOpen={true}
      onClose={() => {}}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader color={theme.colors.exodus.primaryBlue}>
          Mohon maaf, akses untuk Klaim Sales telah ditutup
        </ModalHeader>
        <ModalBody>Anda akan diarahkan menuju Dashboard</ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            colorScheme="buttonSecondary"
            width="100%"
            onClick={() => navigate("/", { replace: true })}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default KlaimSalesAccessWarningDialog;
