import { Stack, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import CustomDatePicker from "../../../../../../components/DatePicker/CustomDatePicker";
import { useFormContext } from "react-hook-form";
import BiayaMurniFilterModel from "../../../../models/BiayaMurniFilterModel";
import DateExodus from "../../../../../../models/DateExodus";
import { useState } from "react";

const FilterByPeriode = () => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);

  const formContext = useFormContext<BiayaMurniFilterModel>();

  const [tempStartDate, setTempStartDate] = useState<DateExodus>(
    formContext.getValues("periode.awal")
  );
  const [tempEndDate, setTempEndDate] = useState<DateExodus>(
    formContext.getValues("periode.akhir")
  );

  return (
    <Stack gap={isMobile ? 2 : 4} direction={isMobile ? "column" : "row"}>
      <Stack
        sx={{ width: isMobile ? "100%" : undefined }}
        alignItems={"baseline"}
      >
        <Text>Periode Awal</Text>
        <CustomDatePicker
          placeholder="Periode Awal"
          type={"month_year"}
          defaultValue={tempStartDate.getDateObject()}
          onChange={(val) => {
            if (val) {
              setTempStartDate(DateExodus.fromDateObject(val));
              formContext.setValue(
                "periode.awal",
                DateExodus.fromDateObject(val)
              );
            }
          }}
          name={"jsPeriodeAwal"}
          maxDate={tempEndDate.getDateObject()}
        />
      </Stack>
      <Stack
        sx={{ width: isMobile ? "100%" : undefined }}
        alignItems={"baseline"}
      >
        <Text>Periode Akhir</Text>
        <CustomDatePicker
          placeholder="Periode Akhir"
          type={"month_year"}
          defaultValue={tempEndDate.getDateObject()}
          onChange={(val) => {
            if (val) {
              setTempEndDate(DateExodus.fromDateObject(val));
              formContext.setValue(
                "periode.akhir",
                DateExodus.fromDateObject(val)
              );
            }
          }}
          name={"jsPeriodeAkhir"}
          minDate={tempStartDate.getDateObject()}
        />
      </Stack>
    </Stack>
  );
};

export default FilterByPeriode;
