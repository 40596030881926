import { useEffect } from "react";
import PilihProdukKlaimSalesFakturList from "../FakturList/PilihProdukKlaimSalesFakturList";
import { usePilihProduk } from "../../contexts/PilihProdukContextProvider";
import { useStepper } from "../../../../contexts/StepperContextProvider";
import FakturDetailProduk from "../FakturDetail/FakturDetailProduk";

const MobilePilihProdukKlaimSales: React.FC = () => {
  const {
    state: { currentEditingFaktur },
  } = usePilihProduk();

  const {
    actions: { setIsActionButtonHidden },
  } = useStepper();

  useEffect(() => {
    if (currentEditingFaktur === null) {
      setIsActionButtonHidden(false);
    } else {
      setIsActionButtonHidden(true);
    }
  }, [currentEditingFaktur]);

  return currentEditingFaktur === null ? (
    <PilihProdukKlaimSalesFakturList />
  ) : (
    <FakturDetailProduk />
  );
};

export default MobilePilihProdukKlaimSales;
