import { Button, useMediaQuery, useTheme } from "@chakra-ui/react";
import { useContext } from "react";
import { MdRemoveRedEye } from "react-icons/md";
import MonitorKunjunganUserListContext from "../context/MonitorKunjunganUserListContext";

type Props = {
  bawahanId: number;
};

const ButtonLihatDetailMonitorKunjungan: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { saveSelectedBawahanToIndexedDB } = useContext(
    MonitorKunjunganUserListContext
  );

  return (
    <Button
      variant="ghost"
      colorScheme="buttonSecondary"
      leftIcon={
        <MdRemoveRedEye
          style={{
            width: !isDesktopView ? "20px" : "24px",
            height: !isDesktopView ? "20px" : "24px",
          }}
        />
      }
      onClick={() => saveSelectedBawahanToIndexedDB(props.bawahanId)}
      sx={{
        fontSize: !isDesktopView ? 12 : 14,
        fontWeight: "bold",
      }}
    >
      Lihat Detail
    </Button>
  );
};

export default ButtonLihatDetailMonitorKunjungan;
