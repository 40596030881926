import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import ApprovalKlaimSalesContext from "../../../contexts/ApprovalKlaimSalesContext";
import {
  FormControl,
  Input,
  NumberInput,
} from "@chakra-ui/react";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";

const IdFormFieldKlaimSales: React.FC = () => {
  const { register, setValue, getValues } = useFormContext();
  const { filterValues } = useContext(ApprovalKlaimSalesContext);

  useEffect(() => {
    setValue("idKlaimSales", filterValues?.idKlaimSales);
  }, []);

  return (
    <FormControl width={"100%"}>
      <FormInputLabel title="Claim ID" />
      <NumberInput defaultValue={getValues("idKlaimSales")}>
        <Input
          type="number"
          {...register("idKlaimSales")}
          placeholder="Claim ID"
          onChange={(event: any) => {
            setValue(
              "idKlaimSales",
              event.target.value ? event.target.value : null
            );
          }}
        />
      </NumberInput>
    </FormControl>
  );
};

export default IdFormFieldKlaimSales;
