import { useEffect, useState } from "react";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { SalesProductProvider } from "../dataProviders/SalesProductProvider";
import IsTypeOfErrorResponse from "../../../../utils/IsTypeOfErrorResponse";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import { PayloadParamType } from "../models/ProviderParamType";
import { TotalSalesValueModel } from "../models/TotalSalesValueModel";

const useGetSalesProductTotalSalesValue = (params: PayloadParamType) => {
  const [data, setData] = useState<TotalSalesValueModel | null>(null);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);

        const result = await SalesProductProvider.getTotalSalesValue({
          startDate: params.startDate,
          endDate: params.endDate,
          page: params.page,
          limit: params.limit,
          userId: params.userId,
          salesZoneId: params.salesZoneId,
          salesZoneType: params.salesZoneType,
          products: params.products,
          project: params.project,
        });

        setData(result ?? null);
      } catch (error) {
        if (IsTypeOfErrorResponse(error)) {
          setError(error);
        } else {
          setError(UNKNOWN_ERROR_RESPONSE);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (params.startDate && params.endDate) {
      getData();
    }
  }, [
    params.startDate,
    params.endDate,
    params.products,
    params.salesZoneId,
    params.salesZoneType,
    params.sector,
    params.userId,
    params.project,
  ]);

  return { data, error, isLoading };
};

export default useGetSalesProductTotalSalesValue;
