import { Box, Stack, useTheme } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Appbar from "./Appbar";

const AppbarLayout: React.FC = ({}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Appbar />
      <Stack
        sx={{
          flex: 1,
          p: 6,
          backgroundColor: theme.colors.exodus.background,
        }}
      >
        <Outlet />
      </Stack>
    </Box>
  );
};

export default AppbarLayout;
