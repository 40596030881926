window.global ||= window;
import React, { useContext } from "react";
import DetailApprovalKlaimSalesContext from "../../contexts/DetailApprovalKlaimSalesContext";
import { Accordion, useMediaQuery, useTheme } from "@chakra-ui/react";
import { KlaimSalesDetailListModelData } from "../../../models/KlaimSalesDetailModel";
import DetailApprovalKlaimSalesCard from "./DetailApprovalKlaimSalesCard";
import Masonry from "react-smart-masonry";

const DetailApprovalKlaimSalesList: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { detailKlaimSalesData: record } = useContext(
    DetailApprovalKlaimSalesContext
  );

  if (!record) return null;
  return (
    <Accordion allowMultiple >
      <Masonry
        columns={isDesktopView ? 2 : 1}
        gap={15}
        style={{ paddingBottom: "18px" }}
      >
        {record.claimSales.map((item: KlaimSalesDetailListModelData, index) => (
          <DetailApprovalKlaimSalesCard key={index} data={item} />
        ))}
      </Masonry>
    </Accordion>
  );
};

export default DetailApprovalKlaimSalesList;
