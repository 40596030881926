import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  useTheme,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

const EmailField = () => {
  const theme = useTheme();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl isInvalid={!!errors.email}>
      <FormLabel
        color={theme.colors.exodus.primaryBlue}
        fontWeight={600}
        fontSize={16}
      >
        Verifikasi Email
      </FormLabel>
      <Input
        type="email"
        style={
          errors.email && {
            borderColor: `${theme.colors.exodus.red}`,
            boxShadow: `0 0 1px ${theme.colors.exodus.red}`,
          }
        }
        placeholder={"Email"}
        {...register("email", {
          required: "This is required",
          minLength: {
            value: 5,
            message: "Minimum length should be 5",
          },
        })}
      />
      <FormErrorMessage sx={{ color: theme.colors.exodus.red }}>
        {errors.email && (errors.email.message as string)}
      </FormErrorMessage>
      <Box sx={{ mb: 4 }} />
    </FormControl>
  );
};

export default EmailField;
