import { Box, Card, Progress, Spinner, Text, useTheme } from "@chakra-ui/react";
import React, { useContext } from "react";
import DashboardContext from "../context/DashboardContext";
import DateExodus from "../../../models/DateExodus";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import LoggedUser from "../../../models/common/LoggedUser";
import ProjectType from "../../../models/common/ProjectType";
import { useAppSelector } from "../../../redux/hooks";
import { ProjectEnum } from "../../../models/common/Project";

const TargetSalesProgressCard = (): JSX.Element => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  if (loggedUser?.projects.find((e) => e.value === ProjectEnum.Ethical)) {
    return <TargetSalesProgressCardContent />;
  } else {
    return <></>;
  }
};

const TargetSalesProgressCardContent: React.FC = () => {
  const theme = useTheme();
  const context = useContext(DashboardContext);
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  return (
    <Card
      borderRadius={"16px"}
      overflow={"hidden"}
      backgroundColor={theme.colors.exodus.background}
      boxShadow="0px 7px 24px 4px #282c7a26"
    >
      <Box
        padding={{
          base: "14px",
          tablet: "24px",
        }}
      >
        <Text
          fontSize={{
            base: "16px",
            tablet: "32px",
          }}
          fontWeight={700}
          color={theme.colors.exodus.primaryBlue}
          textAlign={"start"}
        >
          {new DateExodus().getStringDateFormat("MMMM YYYY")}
        </Text>
        <Box paddingTop="12px">
          <CustomSelect
            options={loggedUser?.projects ?? []}
            isDisabled={loggedUser ? loggedUser.projects.length <= 1 : false}
            getOptionLabel={(data: ProjectType) => data.label}
            getOptionValue={(data: ProjectType) => data.value}
            value={context.selectedProject}
            placeholder="Project"
            onChange={(data: ProjectType | null) => {
              if (!data) {
                context.setSelectedProject(null);
              } else {
                context.setSelectedProject(data);
              }
            }}
          />
        </Box>
        <Text
          mt={"16px"}
          fontSize={{
            base: "12px",
            tablet: "20px",
          }}
          textAlign={"start"}
          color={theme.colors.exodus.fontDefault}
          fontWeight={700}
        >
          Total Sales anda saat ini
        </Text>
        {context.targetSalesProgressError && (
          <Text py={4} color={theme.colors.exodus.red}>
            {context.targetSalesProgressError}
          </Text>
        )}
        {context.targetSalesProgressLoading && (
          <Box py={4}>
            <Spinner />
          </Box>
        )}
        {!context.targetSalesProgressLoading && (
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={{ base: "normal", tablet: "end" }}
              flexDirection={{
                base: "column",
                tablet: "row",
              }}
            >
              <Box
                fontSize={{
                  base: "14px",
                  tablet: "28px",
                }}
                textAlign={"start"}
                color={theme.colors.exodus.primaryBlue}
                wordBreak="break-word"
              >
                <p>
                  <b>{context.targetSalesProgress?.salesValueUI}</b> dari{" "}
                  <b>{context.targetSalesProgress?.targetSalesUI}</b>
                </p>
              </Box>
              <Text
                mt={{
                  base: "12px",
                  tablet: "0px",
                }}
                fontSize={{
                  base: "12px",
                  tablet: "20px",
                }}
                color={theme.colors.exodus.fontDefault}
                textAlign={"end"}
              >
                Progress:{" "}
                {context.targetSalesProgress
                  ? `${context.targetSalesProgress.progressPercentage}%`
                  : "-"}
              </Text>
            </Box>
            <Progress
              value={context.targetSalesProgress?.progressPercentageBar}
              size="sm"
              mt={{
                base: "12px",
                tablet: "24px",
              }}
              sx={{
                "& > div": {
                  backgroundColor: theme.colors.exodus.secondaryBlue,
                },
              }}
            />
          </>
        )}
      </Box>
    </Card>
  );
};

export default TargetSalesProgressCard;
