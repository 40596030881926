import { useEffect, useState } from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Grid,
  GridItem,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import SummaryKlaimSalesCardItem from "./SummaryKlaimSalesCardItem";
import {
  EditableFakturSalesData,
  EditableSalesDetail,
} from "../../PilihProduk/models/EditableFakturModel";

type Props = {
  data: EditableFakturSalesData;
};

const SummaryKlaimSalesCard: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const [selectedEditableSalesDetail, setSelectedEditableSalesDetail] =
    useState<EditableSalesDetail[]>([]);

  useEffect(() => {
    setSelectedEditableSalesDetail(
      props.data.newDetail.filter((item) => item.isSelected === true)
    );
  }, [props.data.newDetail]);

  return (
    <AccordionItem
      border="1px solid"
      borderRadius="8px"
      borderColor={theme.colors.exodus.primaryBlue}
    >
      <AccordionButton
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingY="18px"
        borderRadius="8px"
        borderColor={theme.colors.exodus.primaryBlue}
      >
        <Text
          fontSize={isDesktopView ? 20 : 16}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          {props.data.originalData.salesHeaderId} -{" "}
          {props.data.originalData.invoice}
        </Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel marginX="16px">
        <Flex
          paddingTop={isDesktopView ? "12px" : "9px"}
          paddingBottom={isDesktopView ? "40px" : "16px"}
          direction="row"
          gap={2}
          fontWeight="semibold"
          fontSize={isDesktopView ? 20 : 14}
        >
          <Text color={theme.colors.exodus.primaryBlue}>Distributor: </Text>
          <Text>{props.data.originalData.distributorName}</Text>
        </Flex>
        {isDesktopView && (
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={2}
            paddingBottom="24px"
            color={theme.colors.exodus.primaryBlue}
            fontSize={20}
            fontWeight="semibold"
          >
            <GridItem colSpan={9}>List Produk</GridItem>
            <GridItem colSpan={3}>Quantity</GridItem>
          </Grid>
        )}
        {selectedEditableSalesDetail.map(
          (data: EditableSalesDetail, index) =>
            data.isSelected && (
              <SummaryKlaimSalesCardItem
                data={data}
                isRowColored={index % 2 !== 0}
              />
            )
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default SummaryKlaimSalesCard;
