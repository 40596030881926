import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { GetListProducts } from "../model/GetListSalesDistributorDetailModelPack.ts";
import { isEmpty, isNil } from "lodash";
import CurrencyIdrFormatter from "../../../../utils/CurrencyIdrFormatter.ts";

type Props = {
  data: GetListProducts;
};

const MobileSalesDistributorDetailList = ({ data }: Props) => {
  const theme = useTheme();
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton
          display={"flex"}
          justifyContent={"space-between"}
          paddingX={0}
          paddingY={"8px"}
        >
          <Box>
            <Text
              textAlign={"start"}
              fontWeight={600}
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
            >
              {!isEmpty(data.productName)
                ? data.productName
                : data.productNameDistributor
                  ? data.productNameDistributor
                  : "-"}
            </Text>
            <Text textAlign={"start"} fontSize={"14px"}>
              {!isNil(data.productCode)
                ? data.productCode.toString().padStart(6, "0")
                : "-"}
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
              textAlign={"start"}
            >
              Product Code Distributor
            </Text>
            <Text fontSize={"14px"} textAlign={"end"}>
              {!isEmpty(data.productCodeBridging)
                ? data.productCodeBridging
                : "-"}
            </Text>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
            bgColor={theme.colors.exodus.tableDataBackground}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
              textAlign={"start"}
            >
              Quantity
            </Text>
            <Text fontSize={"14px"} textAlign={"end"}>
              {!isNil(data.quantity) ? data.quantity + " pc(s)" : "-"}
            </Text>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
              textAlign={"start"}
            >
              Price / Qty
            </Text>
            <Text fontSize={"14px"} textAlign={"end"}>
              {!isNil(data.salePrice)
                ? CurrencyIdrFormatter.convertToRp(data.salePrice)
                : "-"}
            </Text>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
            bgColor={theme.colors.exodus.tableDataBackground}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
              textAlign={"start"}
            >
              Total
            </Text>
            <Text fontSize={"14px"} textAlign={"end"}>
              {!isNil(data.subTotal)
                ? CurrencyIdrFormatter.convertToRp(data.subTotal)
                : "-"}
            </Text>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
          >
            <Text
              fontSize={"14px"}
              color={theme.colors.exodus.primaryBlue}
              fontWeight={600}
              textAlign={"start"}
            >
              Diskon
            </Text>
            <Text fontSize={"14px"} textAlign={"end"}>
              {!isNil(data.diskonPi) ? data.diskonPi + "%" : "-"}
            </Text>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default MobileSalesDistributorDetailList;
