import { createContext } from "react";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { KlaimSalesDetailModelData } from "../../models/KlaimSalesDetailModel";

type MonitorKlaimSalesProps = {
  detailKlaimSalesData: KlaimSalesDetailModelData | null;
  refetchGetDetailKlaimSales: () => void;
  openPDF: (data: string) => void;
  loading: boolean;
  errorResponse: ErrorResponse | null;
};

const defaultValue: MonitorKlaimSalesProps = {
  detailKlaimSalesData: null,
  refetchGetDetailKlaimSales: () => {},
  openPDF: () => {},
  loading: false,
  errorResponse: null,
};

const DetailMonitorKlaimSalesContext = createContext(defaultValue);

export default DetailMonitorKlaimSalesContext;
