// useSaveApprovalRealisasiUserId
import React, { useState } from "react";
import RealisasiKunjunganProvider from "../../dataProviders/RealisasiKunjunganProvider";
import { isEmpty } from "lodash";
import { PersistentUserId } from "../models/ApprovalRealisasiIndexDbModels";

const useGetApprovalRealisasiUserId = () => {
  const [data, setData] = useState<number | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);

      try {
        const result: PersistentUserId =
          await RealisasiKunjunganProvider.getApprovalRealisasiDetailUserId();

        if (isEmpty(result)) {
          setData(result);
        }
        if (!isEmpty(result)) {
          setData(result.userId);
          return;
        }
      } catch (error: any) {
        setErrorMessage(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return { data, isLoading, errorMessage };
};

export default useGetApprovalRealisasiUserId;
