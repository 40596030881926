import { AxiosResponse, isAxiosError } from "axios";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";
import { EXODUS_API_VERSION_1 } from "../../../../constants/common/RootConstants";
import AxiosBaseClient from "../../../../dataProviders/config/AxiosBaseClient";
import ErrorResponse from "../../../../models/common/ErrorResponse";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import {
  FakturByCodeParam,
  InputKlaimSalesParam,
  SaveFakturKlaimSalesParam,
  SaveOutletKlaimSalesParam,
  SavePilihProdukKlaimSalesParam,
  SaveStepKlaimSalesParam,
} from "./InputKlaimSalesParamType";
import { FakturByCodeModelPack } from "../models/FakturByCodeModel";
import IndexedDatabase from "../../../../database/IndexDB";
import InputKlaimSalesStoredDataModel from "../models/InputKlaimSalesStoredDataModel";
import StoredOutletKlaimSalesModel from "../components/SearchOutlet/models/StoredOutletKlaimSalesModel";
import StoredFakturKlaimSalesModel from "../components/SelectFaktur/models/StoredFakturKlaimSalesModel";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const InputKlaimSalesProvider = {
  inputKlaimSales: async (params: InputKlaimSalesParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.post(
        `${EXODUS_API_VERSION_1}/claim_sales`,
        params.data
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          return Promise.resolve(response.data.message);
        } else {
          const errorResponse: ErrorResponse = {
            message: response.data.message,
            status: response.data.status,
            code: response.data.code,
          };

          return Promise.reject(errorResponse);
        }
      }
    } catch (error) {
      return handleOnError(error);
    }
  },
  getFakturByCode: async (params: FakturByCodeParam) => {
    try {
      const response: AxiosResponse = await AxiosBaseClient.get(
        `${EXODUS_API_VERSION_1}/sales/invoice`,
        {
          params: {
            outlet_id: params.outlet_id,
            invoice: params.invoice,
          },
        }
      );

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new FakturByCodeModelPack(response.data);

          return Promise.resolve(responseData.data);
        } else {
          const errorResponse: ErrorResponse = {
            message: response.data.message,
            status: response.data.status,
            code: response.data.code,
          };

          return Promise.reject(errorResponse);
        }
      }
    } catch (error) {
      return handleOnError(error);
    }
  },
  getSavedInputKlaimSalesData: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();

    try {
      const data: InputKlaimSalesStoredDataModel[] =
        await IndexedDatabase.inputKlaimSalesData.toArray();

      if (!data || data.length === 0) {
        return null;
      } else {
        return data[0];
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  setSaveStepKlaimSales: async (params: SaveStepKlaimSalesParam) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const oldData: InputKlaimSalesStoredDataModel[] =
        await IndexedDatabase.inputKlaimSalesData.toArray();
      if (!oldData || oldData.length === 0) {
        await IndexedDatabase.inputKlaimSalesData.add({
          step: params.step,
        });
      } else {
        await IndexedDatabase.inputKlaimSalesData.update(oldData[0].id, {
          step: params.step,
        });
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  setSaveOutletKlaimSales: async (params: SaveOutletKlaimSalesParam) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const oldData: InputKlaimSalesStoredDataModel[] =
        await IndexedDatabase.inputKlaimSalesData.toArray();
      if (!oldData || oldData.length === 0) {
        await IndexedDatabase.inputKlaimSalesData.add({
          outlet: {
            outletPanel: new StoredOutletKlaimSalesModel(params.outletPanel),
            outletTujuan: new StoredOutletKlaimSalesModel(params.outletTujuan),
          },
        });
      } else {
        await IndexedDatabase.inputKlaimSalesData.update(oldData[0].id, {
          ...oldData[0],
          outlet: {
            outletPanel: new StoredOutletKlaimSalesModel(params.outletPanel),
            outletTujuan: new StoredOutletKlaimSalesModel(params.outletTujuan),
          },
        });
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  setSaveFakturKlaimSales: async (params: SaveFakturKlaimSalesParam) => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const oldData: InputKlaimSalesStoredDataModel[] =
        await IndexedDatabase.inputKlaimSalesData.toArray();
      if (!oldData || oldData.length === 0) {
        await IndexedDatabase.inputKlaimSalesData.add({
          selectedFaktur: new StoredFakturKlaimSalesModel(
            params.selectedFaktur
          ),
        });
      } else {
        await IndexedDatabase.inputKlaimSalesData.update(oldData[0].id, {
          ...oldData[0],
          selectedFaktur: new StoredFakturKlaimSalesModel(
            params.selectedFaktur
          ),
        });
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  removeSavedFakturKlaimSales: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const data: InputKlaimSalesStoredDataModel[] =
        await IndexedDatabase.inputKlaimSalesData.toArray();

      if (!data || data.length === 0) return;

      await IndexedDatabase.inputKlaimSalesData.update(data[0].id, {
        ...data[0],
        selectedFaktur: undefined,
      });
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  setSavePilihProdukKlaimSales: async (
    params: SavePilihProdukKlaimSalesParam
  ) => {
    if (!IndexedDatabase.open()) await IndexedDatabase.open();
    try {
      const oldData: InputKlaimSalesStoredDataModel[] =
        await IndexedDatabase.inputKlaimSalesData.toArray();

      if (!oldData || oldData.length === 0) {
        await IndexedDatabase.inputKlaimSalesData.add({
          pilihProduk: params.produk,
        });
      } else {
        await IndexedDatabase.inputKlaimSalesData.update(oldData[0].id, {
          ...oldData[0],
          pilihProduk: params.produk,
        });
      }
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  removeSavedPilihProdukKlaimSales: async () => {
    if (!IndexedDatabase.isOpen()) await IndexedDatabase.open();
    try {
      const data: InputKlaimSalesStoredDataModel[] =
        await IndexedDatabase.inputKlaimSalesData.toArray();

      if (!data || data.length === 0) return;

      await IndexedDatabase.inputKlaimSalesData.update(data[0].id, {
        ...data[0],
        pilihProduk: undefined,
      });
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
  removeAllInputKlaimSalesData: async () => {
    if (!IndexedDatabase.open()) await IndexedDatabase.open();
    try {
      const data: InputKlaimSalesStoredDataModel[] =
        await IndexedDatabase.inputKlaimSalesData.toArray();

      if (!data || data.length === 0) return;

      await IndexedDatabase.inputKlaimSalesData.delete(data[0].id);
    } catch (error) {
      return Promise.reject("Error. Try re-logging in your account");
    }
  },
};

const handleOnError = (error: unknown) => {
  if (isAxiosError(error)) {
    return Promise.reject({
      message: error.response ? error.response.data.message : error.message,
      status: error.response?.status,
      code: error.response ? error.response.data.code : error.code,
    });
  }
  return Promise.reject(UNKNOWN_ERROR_RESPONSE);
};

export default InputKlaimSalesProvider;
