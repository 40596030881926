import { Stack, Text, useTheme } from "@chakra-ui/react";
import { useSalesProductContext } from "../../../contexts/SalesProductContextProvider";
import {
  ERROR_RETRIEVING_DATA,
  TOTAL_ESTIMATED_PRODUCT,
  TOTAL_SALES_PRODUCT,
} from "../../../constants/textConstants";
import CurrencyIdrFormatter from "../../../../../../utils/CurrencyIdrFormatter";

const TotalSalesAreaMobile = () => {
  const theme = useTheme();
  const {
    states: { salesValue, errorSalesValue },
  } = useSalesProductContext();

  const commonTextProps = {
    textAlign: "left",
    fontSize: 16,
    fontWeight: 600,
  };

  if (errorSalesValue) {
    return (
      <Stack>
        <Text sx={{ ...commonTextProps, color: theme.colors.exodus.red }}>
          {ERROR_RETRIEVING_DATA} {errorSalesValue.message}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Stack gap={0}>
        <Text
          sx={{ ...commonTextProps, color: theme.colors.exodus.primaryBlue }}
        >
          {TOTAL_SALES_PRODUCT}
        </Text>
        <Text sx={{ ...commonTextProps, color: theme.colors.exodus.darkGrey }}>
          {CurrencyIdrFormatter.convertToRp(
            salesValue?.totalSalesProductValue!!
          )}
        </Text>
      </Stack>
      <Stack gap={0}>
        <Text
          sx={{ ...commonTextProps, color: theme.colors.exodus.primaryBlue }}
        >
          {TOTAL_ESTIMATED_PRODUCT}
        </Text>
        <Text sx={{ ...commonTextProps, color: theme.colors.exodus.darkGrey }}>
          {CurrencyIdrFormatter.convertToRp(
            salesValue?.totalEstimatedSalesProductValue!!
          )}
        </Text>
      </Stack>
    </Stack>
  );
};

export default TotalSalesAreaMobile;
