import { useFormContext } from "react-hook-form";
import SalesOutletListFilterModel from "../../../../../../models/SalesOutletListFilterModel";
import FilterFieldFrame from "../FilterFieldFrame";
import CustomSelect from "../../../../../../../../../../../components/CustomSelect/CustomSelect";
import { SECTOR_LIST } from "../../../../../../../../../../../constants/common/SectorConstants";
import SectorModel from "../../../../../../../../../../../models/Sector/SectorModel";

const FilterBySector = () => {
  const formContext = useFormContext<SalesOutletListFilterModel>();
  const watchSector = formContext.watch("sector");

  return (
    <FilterFieldFrame title="Sektor">
      <CustomSelect
        placeholder="Sektor"
        options={SECTOR_LIST}
        onChange={(value: SectorModel | null | undefined) => {
          if (value) {
            formContext.setValue("sector", value);
          } else {
            formContext.setValue("sector", undefined);
          }
          formContext.setValue("outlets", undefined);
        }}
        defaultValue={null}
        value={watchSector}
        getOptionLabel={(data: SectorModel) => data.label}
        getOptionValue={(data: SectorModel) => data.value}
      />
    </FilterFieldFrame>
  );
};

export default FilterBySector;
