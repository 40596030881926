import { Box, Button, Stack, useTheme, useToast } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import HeaderRecordApprovalRealisasiContext from "../../context/HeaderRecordApprovalRealisasiContext";
import DialogApproveRejectRealisasiKunjungan from "../DialogApproveRejectRealisasiKunjungan";
import { ROLE_ID_TRADE_TEAM } from "../../../../../../constants/common/RoleConstants";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../redux/hooks";
import { RealisasiStatus } from "../../models/ApprovalRealisasiStatus";
import useUpdateStatusRealisasi from "../../services/useUpdateStatusRealisasi";
import DialogConfirmationApproveRejectRealisasiKunjungan from "../DialogConfirmationApproveRejectRealisasiKunjungan";
import { UpdateStatusRealisasiBodyModel } from "../../models/UpdateStatusRealisasiBodyModel";

const MobileDetailApprovalRealisasiBottomButtons: React.FC = () => {
  const theme = useTheme();
  const {
    headerData: record,
    setKey,
    key,
  } = useContext(HeaderRecordApprovalRealisasiContext);
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );
  const toast = useToast();

  const [isReject, setIsReject] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [openComment, setOpenComment] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const { updateStatusRealisasi, loading } = useUpdateStatusRealisasi();
  const [status, setStatus] = useState<string>("");
  const [submittedData, setSubmittedData] = useState<UpdateStatusRealisasiBodyModel | null>(null);

  //=========================================Functions================================================

  const allowApproveReject = (): boolean => {
    if (loggedUser?.userRoleId === ROLE_ID_TRADE_TEAM) return false;

    if (record?.approvalRealisasiStatus === RealisasiStatus.Approved) {
      return false;
    }
    return true;
  };

  const setApproveAndRejectButtonDisable = (): boolean => {
    if (record?.approvalRealisasiStatus === RealisasiStatus.Request) {
      return false;
    }
    return true;
  };

  const onClickApprove = () => {
    for (let i = 0; i < record!.detailRealisasi.length; i++) {
      if (
        record!.detailRealisasi[i].komentarKunjungan === null ||
        record!.detailRealisasi[i].komentarKunjungan === ""
      ) {
        toast({
          title: `${record?.userName} belum melengkapi semua realisasi kunjungan`,
          status: "error",
        });

        return;
      }
    }

    setOpenComment(true);
  };

  const onClickReject = () => {
    setIsReject(true);
    setOpenComment(true);
  };

  const handleApproveReject = (data: any) => {
    const temp: any = {};
    if (!isReject) {
      temp["status"] = "Approved";
      temp["senior_comment"] = data.comment.trim();
      setStatus("Approve")
      setSubmittedData(temp);
    } else {
      temp["status"] = "Pending";
      temp["reason"] = data.comment.trim();
      setStatus("Reject")
      setSubmittedData(temp);
    }

    setOpenConfirmation(true);
  };

  const handleSubmitConfirmation = () => {
    if (record && submittedData) {
      updateStatusRealisasi(record.id, submittedData, {
        onSuccess: () => {
          setComment("");
          toast({
            title: `Berhasil ${status} Realisasi Kunjungan`,
            status: "success",
          });
          setOpenConfirmation(false);
          setOpenComment(false);
          setKey(key + 1);
        },
        onError: (errorMessage: string) => {
          setComment("");
          toast({
            title: `Gagal ${status} Realisasi Kunjungan.`,
            description: `${errorMessage}`,
            status: "error",
          });
          setOpenConfirmation(false);
          setOpenComment(false);
          setKey(key + 1);
        },
      });
    }
  };

  return (
    <>
      {allowApproveReject() && (
        <Stack
          spacing={1}
          sx={{
            position: "sticky",
            pt: 2,
            pb: 2,
            bottom: 0,
            backgroundColor: theme.colors.exodus.background,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box display="flex" flexDirection="row-reverse" gap={3}>
            <Button
              type="submit"
              width="100%"
              colorScheme="buttonSuccess"
              onClick={onClickApprove}
              isDisabled={setApproveAndRejectButtonDisable()}
            >
              Approve
            </Button>
            <Button
              width="100%"
              variant="outline"
              colorScheme="buttonError"
              color={theme.colors.exodus.red}
              borderColor={theme.colors.exodus.red}
              onClick={onClickReject}
              isDisabled={setApproveAndRejectButtonDisable()}
            >
              Reject
            </Button>
          </Box>
        </Stack>
      )}

      <DialogApproveRejectRealisasiKunjungan
        comment={comment}
        openComment={openComment}
        onClose={() => setOpenComment(false)}
        isReject={isReject}
        loading={loading}
        onSubmit={handleApproveReject}
      />

      <DialogConfirmationApproveRejectRealisasiKunjungan
        loading={loading}
        onClose={() => setOpenConfirmation(false)}
        openConfirmation={openConfirmation}
        onSubmit={handleSubmitConfirmation}
        isReject={isReject}
      />
    </>
  );
};

export default MobileDetailApprovalRealisasiBottomButtons;
