import SalesOutletListSingularData from "../SalesOutletListSingularData";

class ApiGetPrintSalesOutletResponse {
  records: SalesOutletListSingularData[];
  hasProject: boolean;

  constructor(json: any) {
    const tempRecords: SalesOutletListSingularData[] = [];
    this.hasProject = false;

    if (json && json.length > 0) {
      for (let i = 0; i < json.length; i++) {
        tempRecords.push(new SalesOutletListSingularData(json[i]));
      }
      if (Object.hasOwn(json[0], "project")) {
        this.hasProject = true;
      }
    }
    this.records = tempRecords;
  }
}

export default ApiGetPrintSalesOutletResponse;
