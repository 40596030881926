/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import CustomerProvider from "../../dataProviders/common/Customer/CustomerProvider";
import CustomerDataModel from "../../models/Customer/CustomerDataModel";
import { CustomerByOutletIdParam } from "../../dataProviders/common/Customer/CustomerParamType";

const useGetCustomerByOutletId = (
  isStartFetching: boolean,
  { outlet_id }: CustomerByOutletIdParam
) => {
  const [customerData, setData] = useState<CustomerDataModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setErrorMessage(null);
        setLoading(true);

        const responseData: CustomerDataModel[] | undefined =
          await CustomerProvider.getCustomerByOutletId({
            outlet_id: outlet_id,
          });

        setData(responseData!);
        setLoading(false);
      } catch (error: any) {
        setData([]);
        setErrorMessage(error);
        setLoading(false);
      }
    }

    if (isStartFetching && outlet_id) {
      getData();
    }
  }, [isStartFetching, outlet_id]);

  return { customerData, loading, errorMessage };
};

export default useGetCustomerByOutletId;
