import { Box } from "@chakra-ui/react";
import FilterBox from "../../../../components/FilterBox/FilterBox";
import SalesDistributorFilterValue from "../model/SalesDistributorFilterValues";
import { isEmpty } from "lodash";

type Props = {
  filterValuesDataBox: SalesDistributorFilterValue | null;
};

const SalesDistributorAppliedFilterList = ({ filterValuesDataBox }: Props) => {
  return (
    <Box display={"flex"} gap={1} flexWrap={"wrap"}>
      {!isEmpty(filterValuesDataBox?.nomorFaktur) && (
        <FilterBox
          title="Nomor Faktur"
          content={filterValuesDataBox?.nomorFaktur!}
        />
      )}
      {!isEmpty(filterValuesDataBox?.namaOutlet) && (
        <FilterBox
          title="Nama Outlet"
          content={filterValuesDataBox?.namaOutlet.outletName}
        />
      )}
      {!isEmpty(filterValuesDataBox?.namaDistributor) && (
        <FilterBox
          title="Nama Distributor"
          content={filterValuesDataBox?.namaDistributor.distributorName}
        />
      )}
      {!isEmpty(filterValuesDataBox?.projectAvailable) && (
        <FilterBox
          title="Project"
          content={filterValuesDataBox?.projectAvailable.label}
        />
      )}
      {!isEmpty(filterValuesDataBox?.sector) && (
        <FilterBox
          title="Sektor"
          content={filterValuesDataBox?.sector?.label}
        />
      )}
      {!isEmpty(filterValuesDataBox?.bridgingType) && (
        <FilterBox
          title="Bridging Status"
          content={
            filterValuesDataBox?.bridgingType! === "1"
              ? "All"
              : filterValuesDataBox?.bridgingType! === "2"
                ? "Linked"
                : filterValuesDataBox?.bridgingType! === "3"
                  ? "Unlinked"
                  : "Undefined"
          }
        />
      )}
      {!isEmpty(filterValuesDataBox?.periodeAwal) && (
        <FilterBox
          title="Periode Awal"
          content={filterValuesDataBox?.periodeAwal.getStringDateFormat(
            "D MMMM YYYY "
          )}
        />
      )}
      {!isEmpty(filterValuesDataBox?.periodeAkhir) && (
        <FilterBox
          title="Periode Akhir"
          content={filterValuesDataBox?.periodeAkhir.getStringDateFormat(
            "D MMMM YYYY "
          )}
        />
      )}
    </Box>
  );
};

export default SalesDistributorAppliedFilterList;
