import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useTheme,
  Text,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type OnSuccessModalProps = {
  isSuccessModalShown: boolean;
  handleOnChangeIsSuccessModalShown: (e: boolean) => void;
  descriptionText: string;
  isCloseButtonNavigateToLogin?: boolean;
};
const OnSuccessModal = ({
  isSuccessModalShown,
  handleOnChangeIsSuccessModalShown,
  descriptionText,
  isCloseButtonNavigateToLogin = false,
}: OnSuccessModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleOnClose = () => {
    handleOnChangeIsSuccessModalShown(false);
    isCloseButtonNavigateToLogin && navigate("/login");
  };

  return (
    <Modal
      isOpen={isSuccessModalShown}
      onClose={handleOnClose}
      isCentered
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent
        sx={{
          padding: 2,
          paddingY: 4,
          backgroundColor: `${theme.colors.exodus.background}`,
          borderRadius: "16px",
        }}
      >
        <ModalHeader>
          <Text
            color={theme.colors.exodus.primaryBlue}
            fontWeight={600}
            fontSize={24}
            textAlign={"center"}
          >
            Notice
          </Text>
        </ModalHeader>
        <ModalBody
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Text
            fontSize={16}
            fontWeight={400}
            textAlign={"center"}
            color={theme.colors.exodus.primaryBlue}
          >
            {descriptionText}
          </Text>
          <Button
            variant="outline"
            marginTop={4}
            colorScheme="buttonPrimary"
            onClick={handleOnClose}
          >
            {isCloseButtonNavigateToLogin
              ? "Kembali ke halaman Login"
              : "Tutup"}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OnSuccessModal;
