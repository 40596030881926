import { FakturByCodeModelData } from "../../../models/FakturByCodeModel";

class StoredFakturKlaimSalesModel {
  data: FakturByCodeModelData[];

  constructor(json: any) {
    this.data = json;
  }
}

export default StoredFakturKlaimSalesModel;
