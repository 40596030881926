import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useMediaQuery,
  useTheme,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

type Props = {
  openRejectNote: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  loading: boolean;
  rejectNote: string;
};

const DialogRejectKlaimSales: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const form = useForm();

  const onCloseReject = () => {
    form.reset();
    props.onClose();
  };
  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={props.openRejectNote}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          <Text>Input Keterangan Reject</Text>
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(props.onSubmit)}>
              <FormControl
                width="100%"
                isInvalid={!!form.formState.errors.rejectNote}
              >
                <Textarea
                  {...form.register("rejectNote", {
                    required: "Required",
                    validate: (value) => {
                      if (value && value.trim() === "") {
                        return "Required";
                      }
                    },
                  })}
                  defaultValue={props.rejectNote}
                  placeholder="Isi Keterangan..."
                />
                <FormErrorMessage color={theme.colors.exodus.red}>
                  {form.formState.errors.rejectNote &&
                    (form.formState.errors.rejectNote.message as string)}
                </FormErrorMessage>
              </FormControl>
              <Flex
                direction={!isDesktopView ? "column" : "row-reverse"}
                width={"100%"}
                gap={!isDesktopView ? 0 : 5}
                paddingBottom="10px"
                paddingTop={"24px"}
              >
                <Button
                  type="submit"
                  width="100%"
                  colorScheme="buttonPrimary"
                  isLoading={props.loading}
                  isDisabled={props.loading}
                >
                  Submit
                </Button>
                <Button
                  onClick={onCloseReject}
                  width="100%"
                  variant="outline"
                  colorScheme="buttonSecondary"
                  marginTop={!isDesktopView ? "12px" : 0}
                  isDisabled={props.loading}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DialogRejectKlaimSales;
