import { useState, useEffect } from "react";
import SalesOutletDetailFilterModel from "../models/SalesOutletDetailFilterModel";
import { useParams, useSearchParams } from "react-router-dom";
import DateExodus from "../../../../../../models/DateExodus";
import SalesOutletSavedPaginationMetadata from "../../../models/SalesOutletSavedPaginationMetadata";
import ErrorResponse from "../../../../../../models/common/ErrorResponse";
import { COMMON_PAGINATION_LIMIT_CHOICES } from "../../../../../../constants/components/PaginationConstants";
import SalesOutletProvider from "../../../dataProviders/SalesOutletProvider";
import { BAD_LINK_TEXT } from "../../../constants/constants";
import { ProjectEnum } from "../../../../../../models/common/Project";

const _useDetailMetadata = () => {
  const initialMetadataValue = new SalesOutletSavedPaginationMetadata({
    page: 1,
    limit: COMMON_PAGINATION_LIMIT_CHOICES[0].value,
  });

  const [metadataValues, setMetadataValues] =
    useState<SalesOutletSavedPaginationMetadata | null>(null);
  const [isLoadingMetadata, setIsLoadingMetadata] = useState<boolean>(false);
  const [errorMetadata, setErrorMetadata] = useState<ErrorResponse | null>(
    null
  );

  useEffect(() => {
    const initializeMetadata = async () => {
      try {
        setIsLoadingMetadata(true);
        setErrorMetadata(null);

        const dbMetadata: SalesOutletSavedPaginationMetadata | null =
          await SalesOutletProvider.localDb.detailFeature.metadata.get();

        if (dbMetadata) {
          setMetadataValues(dbMetadata);
        } else {
          setMetadataValues(initialMetadataValue);
        }
      } catch (errorMetadata) {
        if (errorMetadata as ErrorResponse) {
          setErrorMetadata(errorMetadata as ErrorResponse);
        }
      } finally {
        setIsLoadingMetadata(false);
      }
    };

    initializeMetadata();
  }, []);

  const saveMetadata = async (
    newMetadata: SalesOutletSavedPaginationMetadata
  ) => {
    try {
      setIsLoadingMetadata(true);
      setErrorMetadata(null);
      await SalesOutletProvider.localDb.detailFeature.metadata.save(
        newMetadata
      );
      setMetadataValues(newMetadata);
    } catch (errorMetadata) {
      if (errorMetadata as ErrorResponse) {
        setErrorMetadata(errorMetadata as ErrorResponse);
      }
    } finally {
      setIsLoadingMetadata(false);
    }
  };

  const resetMetadata = async () => {
    await saveMetadata(initialMetadataValue);
  };

  return {
    metadataValues,
    saveMetadata,
    resetMetadata,
    isLoadingMetadata,
    errorMetadata,
  };
};

const useDetailFilterAndMetadata = () => {
  const {
    metadataValues,
    saveMetadata,
    resetMetadata,
    isLoadingMetadata,
    errorMetadata,
  } = _useDetailMetadata();

  let { outletId } = useParams();
  let [searchParams] = useSearchParams();

  const [filterValues, setFilterValues] =
    useState<SalesOutletDetailFilterModel | null>(null);
  const [isLoadingRigidFilter, setIsLoadingRigidFilter] =
    useState<boolean>(false);
  const [errorRigidFilter, setErrorRigidFilter] = useState<string | null>(null);

  useEffect(() => {
    const initializeFilter = async () => {
      setIsLoadingRigidFilter(true);
      setErrorRigidFilter(null);

      const startDate = searchParams.get("start-date");
      const endDate = searchParams.get("end-date");
      const userId = searchParams.get("user-id");
      let projectStr = searchParams.get("project");

      let projectEnum: ProjectEnum | undefined;

      switch (projectStr) {
        case ProjectEnum.Ethical:
          projectEnum = ProjectEnum.Ethical;
          break;
        case ProjectEnum.Msa:
          projectEnum = ProjectEnum.Msa;
          break;
        case ProjectEnum.Omega:
          projectEnum = ProjectEnum.Omega;
          break;
        default:
          break;
      }

      if (
        outletId &&
        startDate &&
        endDate &&
        +outletId &&
        +startDate &&
        +endDate &&
        (userId === undefined || userId === null || (userId && +userId))
      ) {
        if (+startDate > +endDate) {
          setErrorRigidFilter(BAD_LINK_TEXT);
          return;
        }

        const filter: SalesOutletDetailFilterModel | null =
          new SalesOutletDetailFilterModel({
            outletId: +outletId,
            periode: {
              awal: DateExodus.fromBackend(+startDate),
              akhir: DateExodus.fromBackend(+endDate),
            },
            userId: userId ? +userId : undefined,
            project: projectEnum,
          });

        const dbFilter: SalesOutletDetailFilterModel | null =
          await SalesOutletProvider.localDb.detailFeature.filter.get();

        if (
          !(
            filter.outletId === dbFilter?.outletId &&
            filter.periode.akhir?.getEpochDate() ===
              dbFilter?.periode.akhir?.getEpochDate() &&
            filter.periode.awal?.getEpochDate() ===
              dbFilter?.periode.awal?.getEpochDate() &&
            filter.userId === dbFilter?.userId
          )
        ) {
          resetMetadata();
          await SalesOutletProvider.localDb.detailFeature.filter.save(filter);
        }

        setFilterValues(filter);
      } else {
        const filter: SalesOutletDetailFilterModel | null =
          new SalesOutletDetailFilterModel({
            outletId: undefined,
            periode: {
              awal: undefined,
              akhir: undefined,
            },
            userId: undefined,
            project: undefined,
          });
        setFilterValues(filter);
        setErrorRigidFilter(BAD_LINK_TEXT);
      }
      setIsLoadingRigidFilter(false);
    };

    initializeFilter();
  }, []);

  return {
    filterValues,
    isLoadingRigidFilter,
    errorRigidFilter,
    metadataValues,
    saveMetadata,
    isLoadingMetadata,
    errorMetadata,
  };
};

export default useDetailFilterAndMetadata;
