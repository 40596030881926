import { CommonStatus } from "../../../models/common/Status";

export const getStatusColor = (status: string) => {
  if (status === CommonStatus.Approved) {
    return "processed";
  } else if (status === CommonStatus.Pending) {
    return "pending";
  } else if (status === CommonStatus.Rejected) {
    return "reject";
  } else {
    return "pending";
  }
};
