import ProjectType from "../../../../../models/common/ProjectType";
import DateExodus from "../../../../../models/DateExodus";

class DetailApprovalRencanaKunjunganModelPack {
  message!: string;
  status!: number;
  code!: string;
  data!: DetailApprovalRencanaKunjunganModelData;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.message = json["message"];
    this.status = json["status"];
    this.code = json["code"];
    this.data = new DetailApprovalRencanaKunjunganModelData(json["data"]);
  }
}

class DetailApprovalRencanaKunjunganModelData {
  id!: number;
  userId!: number;
  userName!: string;
  nip!: string;
  roleId!: number;
  roleLabel!: string;
  approvalPlanId?: number;
  approvalPlanStatus!: string;
  approvalPlanTime?: DateExodus | null;
  approvalRealizationId?: number;
  approvalRealizationStatus!: string;
  approvalRealizationTime?: DateExodus | null;
  description?: string;
  totalSeluruhKunjungan!: number;
  startDate!: DateExodus;
  endDate!: DateExodus;
  createdAt!: DateExodus;
  updatedAt!: DateExodus;
  detailVisitData!: DetailApprovalRencanaKunjunganModelDetailVisitData[];
  periodeKunjungan!: string;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    var temp: DetailApprovalRencanaKunjunganModelDetailVisitData[] = [];

    if (json["detail_visit_data"] !== null) {
      for (let i = 0; i < json.detail_visit_data.length; i++) {
        temp.push(
          new DetailApprovalRencanaKunjunganModelDetailVisitData(
            json.detail_visit_data[i]
          )
        );
      }
    }

    this.id = json["ID"];
    this.userId = json["user_id"];
    this.userName = json["user_name"];
    this.nip = json["nip"];
    this.roleId = json["role_id"];
    this.roleLabel = json["role_label"];
    this.approvalPlanId = json["approval_plan_id"];
    this.approvalPlanStatus = json["approval_plan_status"];
    this.approvalPlanTime = json["approval_plan_status"]
      ? DateExodus.fromBackend(json["approval_plan_time"])
      : null;
    this.approvalRealizationId = json["approval_realization_id"];
    this.approvalRealizationStatus = json["approval_realization_status"];
    this.approvalRealizationTime = json["approval_realization_time"]
      ? DateExodus.fromBackend(json["approval_realization_time"])
      : null;
    this.description = json["description"];
    this.totalSeluruhKunjungan = json["total_visits"];
    this.startDate = DateExodus.fromBackend(json["start_date"]);
    this.endDate = DateExodus.fromBackend(json["end_date"]);
    this.createdAt = DateExodus.fromBackend(json["created_at"]);
    this.updatedAt = DateExodus.fromBackend(json["updated_at"]);
    this.detailVisitData = temp;
    this.periodeKunjungan = this.periodeKunjungan = `${DateExodus.fromBackend(
      json["start_date"]
    ).getStringDateFormat("D MMMM YYYY")} - ${DateExodus.fromBackend(
      json["end_date"]
    ).getStringDateFormat("D MMMM YYYY")}`;
  }
}

class DetailApprovalRencanaKunjunganModelDetailVisitData {
  id!: number;
  outletId!: number;
  outletCode!: string;
  outletName!: string;
  customerId!: number;
  customerCode!: string;
  customerName!: string;
  spesialis!: string;
  visitHeaderId!: number;
  posisi!: string;
  tujuan!: string;
  totalVisit!: number;
  createdAt!: DateExodus;
  updatedAt!: DateExodus;
  project!: ProjectType | null;

  constructor(json: any) {
    this.mapFromJson(json);
  }

  mapFromJson(json: any) {
    this.id = json["ID"];
    this.outletId = json["outlet_id"];
    this.outletCode = json["outlet_code"];
    this.outletName = json["outlet_name"];
    this.customerId = json["customer_id"];
    this.customerCode = json["customer_code"];
    this.customerName = json["customer_name"];
    this.spesialis = json["customer_specialist"];
    this.visitHeaderId = json["visit_header_id"];
    this.posisi = json["position_customer"];
    this.tujuan = json["purpose"];
    this.totalVisit = json["total_visit"];
    this.createdAt = DateExodus.fromBackend(json["created_at"]);
    this.updatedAt = DateExodus.fromBackend(json["updated_at"]);
    this.project = json["project"] ?? null;
  }
}

export {
  DetailApprovalRencanaKunjunganModelPack,
  DetailApprovalRencanaKunjunganModelData,
  DetailApprovalRencanaKunjunganModelDetailVisitData,
};
