import {
  FormControl,
  FormErrorMessage,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import TujuanKunjunganDataModel from "../../../../models/TujuanKunjunganDataModel";
import { DraftRencanaKunjunganCreateBody } from "../../models/CreateDraftRencanaKunjunganModel";
import DraftRencanaKunjunganUpdateBody from "../../models/UpdateDraftRencanaKunjunganModel";

type Props = {
  tujuanKunjunganData: TujuanKunjunganDataModel[];
  selectedTujuan: TujuanKunjunganDataModel | null;
  onChange: (data: TujuanKunjunganDataModel) => void;
};

const RencanaKunjunganTujuanFormField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<
    DraftRencanaKunjunganCreateBody | DraftRencanaKunjunganUpdateBody
  >();

  return (
    <FormControl
      width={!isDesktopView ? "100%" : "80%"}
      isInvalid={!!errors.purpose}
    >
      <FormInputLabel title="Tujuan Kunjungan" />
      <CustomSelect
        {...register("purpose", {
          required: "Required",
        })}
        isSearchable={false}
        isClearable={false}
        options={props.tujuanKunjunganData}
        getOptionValue={(data: TujuanKunjunganDataModel) => data.id.toString()}
        getOptionLabel={(data: TujuanKunjunganDataModel) => data.visitName}
        onChange={(data: TujuanKunjunganDataModel) => {
          setValue("purpose", data.visitName);
          props.onChange(data);
        }}
        value={props.selectedTujuan}
        placeholder="Tujuan Kunjungan"
        menuPlacement="auto"
      />
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.purpose && (errors.purpose.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default RencanaKunjunganTujuanFormField;
