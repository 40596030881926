import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { TotalMonthlyParams } from "../dataProviders/SalesTargetParamType";
import SalesTargetProvider from "../dataProviders/SalesTargetProvider";
import { TotalMonthlyModel } from "../models/TotalMonthlyModel";
import ErrorResponse from "../../../../models/common/ErrorResponse";

const useGetTotalMonthly = ({ endDate, startDate, project }: TotalMonthlyParams) => {
  const [totalMonthlyData, setData] = useState<TotalMonthlyModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setError(null);
        setLoading(true);

        const response = await SalesTargetProvider.getTotalMonthly({
          startDate,
          endDate,
          project
        });

        setData(response || []);
        setLoading(false);
      } catch (error) {
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
        setData([]);
        setLoading(false);
      }
    }

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
      getData();
    }
  }, [JSON.stringify(startDate), JSON.stringify(endDate), project]);

  return { totalMonthlyData, loading, error };
};

export default useGetTotalMonthly;
