import { Box, useTheme, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

type HorizontalAccordionDetailType = {
  isRowColored?: boolean;
  leftText: string;
  rightSide: ReactNode | string;
  size?: "small" | "medium";
};

const HorizontalAccordionDetail = ({
  isRowColored = false,
  size = "medium",
  leftText,
  rightSide,
}: HorizontalAccordionDetailType) => {
  const theme = useTheme();

  return (
    <Box
      width="100%"
      gap={2}
      py="4px"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      bgColor={
        isRowColored
          ? theme.colors.exodus.tableDataBackground
          : theme.colors.exodus.background
      }
    >
      <Text
        fontSize={size === "small" ? 12 : 14}
        fontWeight="600"
        color={theme.colors.exodus.primaryBlue}
        textAlign="left"
      >
        {leftText}
      </Text>
      {typeof rightSide === "string" || "number" ? (
        <Text
          fontSize={size === "small" ? 12 : 14}
          textAlign="right"
          fontWeight="400"
        >
          {rightSide}
        </Text>
      ) : (
        rightSide
      )}
    </Box>
  );
};

export default HorizontalAccordionDetail;
