import { useContext, useState } from "react";
import { Button, useMediaQuery, useTheme } from "@chakra-ui/react";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../models/RealisasiAndExtraCallDataModel";
import { MdRemoveRedEye } from "react-icons/md";
import DialogDetailRealisasiOrExtraCall from "./DialogDetailRealisasiOrExtraCall";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";

type Props = {
  data: RealisasiAndExtraCallDetailRealizationModelData;
};

const ButtonLihatDetailRealisasiAndExtraCall: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const [openDialogDetail, setOpenDialogDetail] = useState<boolean>(false);

  const { currentKunjungan } = useContext(InputRealisasiKunjunganContext);

  return (
    <>
      <Button
        variant="ghost"
        leftIcon={
          <MdRemoveRedEye
            style={{
              width: !isDesktopView ? "20px" : "24px",
              height: !isDesktopView ? "20px" : "24px",
            }}
          />
        }
        onClick={() => setOpenDialogDetail(true)}
        sx={{
          fontSize: !isDesktopView ? 12 : 14,
          fontWeight: "bold",
          color: theme.colors.exodus.secondaryBlue,
        }}
      >
        Lihat
      </Button>

      <DialogDetailRealisasiOrExtraCall
        data={props.data}
        dialogTitle={
          currentKunjungan === "extracall"
            ? "Detail Extra Call"
            : "Detail Realisasi Kunjungan"
        }
        openDialogDetail={openDialogDetail}
        onClose={() => setOpenDialogDetail(false)}
      />
    </>
  );
};

export default ButtonLihatDetailRealisasiAndExtraCall;
