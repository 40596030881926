import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../models/RealisasiAndExtraCallDataModel";
import DetailRealisasiOrExtraCallStackedData from "./DetailRealisasiOrExtraCallStackedData";
import { MdOutlineInsertPhoto } from "react-icons/md";

type Props = {
  dialogTitle: string;
  openDialogDetail: boolean;
  onClose: () => void;
  data: RealisasiAndExtraCallDetailRealizationModelData;
};

const DialogDetailRealisasiOrExtraCall: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  const primaryColor = theme.colors.exodus.primaryBlue;

  const generateWaktuKunjungan = (
    data: RealisasiAndExtraCallDetailRealizationModelData
  ) => {
    if (
      !data.date &&
      !data.startTime &&
      !data.endTime &&
      !data.komentarKunjungan
    ) {
      return "-";
    } else if (
      !data.date &&
      !data.startTime &&
      !data.endTime &&
      data.komentarKunjungan
    ) {
      return "Gagal Kunjungan";
    } else {
      return `${data.date!.getStringDateFormat(
        "dddd, D MMMM YYYY"
      )}, ${data.startTime!.getStringDateFormat(
        "HH:mm"
      )} - ${data.endTime!.getStringDateFormat("HH:mm")}`;
    }
  };

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      isOpen={props.openDialogDetail}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader fontSize={24} fontWeight="semibold" color={primaryColor}>
          {props.dialogTitle}
        </ModalHeader>
        <ModalBody>
          {/* CUSTOMER SECTION */}
          <Box paddingBottom="20px">
            <Text
              fontSize={isDesktopView ? 18 : 16}
              fontWeight="semibold"
              color={primaryColor}
              paddingBottom="6px"
            >
              Customer
            </Text>
            <Grid templateColumns="repeat(4, 1fr)" gap={4}>
              <GridItem colSpan={isDesktopView ? 2 : 4}>
                <DetailRealisasiOrExtraCallStackedData
                  topValue="Nama"
                  bottomValue={props.data.customerName}
                />
              </GridItem>
              <GridItem colSpan={isDesktopView ? 1 : 2}>
                <DetailRealisasiOrExtraCallStackedData
                  topValue="Kode"
                  bottomValue={props.data.customerCode}
                />
              </GridItem>
              <GridItem colSpan={isDesktopView ? 1 : 2}>
                <DetailRealisasiOrExtraCallStackedData
                  topValue="Jabatan"
                  bottomValue={props.data.jabatan}
                />
              </GridItem>
            </Grid>
          </Box>

          {/* OUTLET SECTION */}
          <Box paddingBottom="20px">
            <Text
              fontSize={isDesktopView ? 18 : 16}
              fontWeight="semibold"
              color={primaryColor}
              paddingBottom="6px"
            >
              Outlet
            </Text>
            <Grid templateColumns="repeat(4, 1fr)" gap={4}>
              <GridItem colSpan={isDesktopView ? 2 : 4}>
                <DetailRealisasiOrExtraCallStackedData
                  topValue="Nama Outlet"
                  bottomValue={props.data.outletName}
                />
              </GridItem>
              <GridItem colSpan={isDesktopView ? 2 : 4}>
                <DetailRealisasiOrExtraCallStackedData
                  topValue="Kode Outlet"
                  bottomValue={props.data.outletCode}
                />
              </GridItem>
            </Grid>
          </Box>

          {/* WAKTU KUNJUNGAN SECTION */}
          <Box paddingBottom="20px">
            <DetailRealisasiOrExtraCallStackedData
              topValue="Waktu Kunjungan"
              bottomValue={generateWaktuKunjungan(props.data)}
            />
          </Box>

          {/* JENIS KEGIATAN SECTION */}
          <Box paddingBottom="20px">
            <DetailRealisasiOrExtraCallStackedData
              topValue="Jenis Kegiatan"
              bottomValue={props.data.tujuanKunjungan}
            />
          </Box>

          {/* FOTO SECTION */}
          <Box paddingBottom="20px">
            <Text
              fontWeight="semibold"
              color={theme.colors.exodus.primaryBlue}
              fontSize={isDesktopView ? 16 : 14}
            >
              Foto
            </Text>
            <Button
              variant="ghost"
              isDisabled={!props.data.photo}
              sx={{
                fontSize: !isDesktopView ? 12 : 14,
                fontWeight: "bold",
                color: theme.colors.exodus.secondaryBlue,
                padding: 0,
                textDecoration: "underline",
              }}
              leftIcon={
                <MdOutlineInsertPhoto
                  style={{
                    width: !isDesktopView ? "20px" : "24px",
                    height: !isDesktopView ? "20px" : "24px",
                  }}
                />
              }
              onClick={() => {
                if (props.data.photo) window.open(props.data.photo);
              }}
            >
              Lihat Foto
            </Button>
            {/* <Text fontWeight="semibold" fontSize={isDesktopView ? 16 : 14}>
              {props.bottomValue}
            </Text> */}
          </Box>

          {/* KOMENTAR KUNJUNGAN SECTION */}
          <Box paddingBottom="20px">
            <DetailRealisasiOrExtraCallStackedData
              topValue="Komentar Kunjungan"
              bottomValue={props.data.komentarKunjungan ?? "-"}
            />
          </Box>

          {/* CLOSE DIALOG SECTION */}
          <Button
            variant="outline"
            onClick={props.onClose}
            colorScheme="buttonSecondary"
            width="100%"
            marginBottom="20px"
          >
            Tutup
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DialogDetailRealisasiOrExtraCall;
