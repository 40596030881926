import React from "react";
import { Box } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useOutletCardListContext } from "../../contexts/OutletCardListContextProvider";
import SectorModel from "../../../../../../models/Sector/SectorModel";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import { SECTOR_LIST } from "../../../../../../constants/common/SectorConstants";
import RoleModel from "../../../../../../models/Users/RoleModel";
import { isEmpty } from "lodash";

const FilterBySector = () => {
  const {
    states: { filterValues },
  } = useOutletCardListContext();

  const [selectedSector, setSelectedSector] =
    React.useState<SectorModel | null>(filterValues?.sector ?? null);

  const formContext = useFormContext();
  const watchOutlet = formContext.watch("outlet");

  return (
    <Box width={"100%"}>
      <FormInputLabel title="Sektor" />
      <CustomSelect
        options={SECTOR_LIST}
        onChange={(eachSector: SectorModel | null) => {
          setSelectedSector(eachSector);
          formContext.setValue("sector", eachSector);
        }}
        getOptionLabel={(eachSector: RoleModel) => eachSector.label}
        value={selectedSector}
        placeholder="Sektor"
        getOptionValue={(eachSector) => eachSector.label}
        isDisabled={!isEmpty(watchOutlet)}
        isClearable
      />
    </Box>
  );
};

export default FilterBySector;
