import { useTheme, Box, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";

type FormInputLabelProps = {
  title: string;
};

const FormInputLabel: React.FC<FormInputLabelProps> = ({ title }) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(`(min-width: ${theme.breakpoints.tablet})`);
  return (
    <Box>
      <Text
        fontSize={isDesktopView ? 18 : 16}
        fontWeight="semibold"
        color={theme.colors.exodus.primaryBlue}
        lineHeight={2.5}
      >
        {title}
      </Text>
    </Box>
  );
};

export default FormInputLabel;
