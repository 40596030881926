import { useTheme, useMediaQuery } from "@chakra-ui/react";
import loginBackground from "../../../assets/loginBackground.png";

const useLoginStyle = () => {
  const theme = useTheme();

  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const rootStyle = {
    backgroundColor: `${theme.colors.exodus.white}`,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${loginBackground})`,
  };

  const cardStyle = {
    backgroundColor: `${theme.colors.exodus.background}`,
    display: "flex",
    p: 6,
    minH: "534px",
    width: `${isDesktopView ? "500px" : "90%"}`,
    flexDirection: "column",
    borderRadius: "16px",
  };

  const logoStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mb: 10,
  };

  return { rootStyle, cardStyle, logoStyle };
};

export default useLoginStyle;
