import { ProjectEnum } from "../../../../models/common/Project";
import { BiayaMurniDplDpfDetailData } from "./BiayaMurniDplDpfDetailData";

class ApiGetOneDplDpfResponse {
  products: BiayaMurniDplDpfDetailData[];
  project: ProjectEnum | null;
  hasProject: boolean;

  constructor(json: any) {
    const tempProducts: BiayaMurniDplDpfDetailData[] = [];

    if (json["data"] !== null) {
      for (let i = 0; i < json.data.length; i++) {
        tempProducts.push(new BiayaMurniDplDpfDetailData(json.data[i]));
      }
    }
    this.products = tempProducts;

    this.project = json.project ?? null;
    if (Object.hasOwn(json, "project")) {
      this.hasProject = true;
    } else {
      this.hasProject = false;
    }
  }
}

export { ApiGetOneDplDpfResponse };
