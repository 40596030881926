/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import CreateAndUpdateExtraCallBody from "../models/CreateAndUpdateExtraCallModel";
import { FormProvider, useForm } from "react-hook-form";
import LoggedUser from "../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../redux/hooks";
import CustomerDataModel from "../../../../../models/Customer/CustomerDataModel";
import { OutletModel } from "../../../../../models/Outlet/OutletModel";
import JabatanCustomerList, {
  JabatanCustomer,
} from "../../../constants/JabatanCustomer";
import TujuanKunjunganDataModel from "../../../models/TujuanKunjunganDataModel";
import useGetCustomerByOutletId from "../../../../../services/Customer/useGetCustomerByOutletId";
import useGetOutletByUserId from "../../../../../services/Outlet/useGetOutletByUserId";
import useGetTujuanKunjunganList from "../../../services/useGetTujuanKunjunganList";
import RealisasiExtraCallOutletFormField from "./form/RealisasiExtraCallOutletFormField";
import RealisasiExtraCallCustomerFormField from "./form/RealisasiExtraCallCustomerFormField";
import RealisasiExtraCallJabatanFormField from "./form/RealisasiExtraCallJabatanFormField";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../models/RealisasiAndExtraCallDataModel";
import RealisasiExtraCallDateTimeFormField from "./form/RealisasiExtraCallDateTimeFormField";
import RealisasiExtraCallTujuanFormField from "./form/RealisasiExtraCallTujuanFormField";
import RealisasiExtraCallTujuanLainFormField from "./form/RealisasiExtraCallTujuanLainFormField";
import RealisasiExtraCallKomentarKunjunganField from "./form/RealisasiExtraCallKomentarKunjunganField";
import RealisasiExtraCallImagePickerFormField from "./form/RealisasiExtraCallImagePickerFormField";
import dayjs from "dayjs";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";
import { debounce } from "lodash";

type Props = {
  data?: RealisasiAndExtraCallDetailRealizationModelData;
  dialogTitle: string;
  openDialogInputEditExtraCall: boolean;
  onSubmit: (data: CreateAndUpdateExtraCallBody) => void;
  onClose: () => void;
  type: "create" | "update";
  isLoadingSubmit: boolean;
  isLoadingPhotoProcessing: boolean;
};

const DialogInputEditExtraCall: React.FC<Props> = (props) => {
  const loggedUser: LoggedUser | null = useAppSelector(
    (state) => state.authReducer.loggedUser
  );

  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { selectedPeriode } = useContext(InputRealisasiKunjunganContext);

  const form = useForm<CreateAndUpdateExtraCallBody>();

  const [selectedOutlet, setSelectedOutlet] = useState<OutletModel | null>(
    null
  );
  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerDataModel | null>(null);
  const [selectedTujuan, setSelectedTujuan] =
    useState<TujuanKunjunganDataModel | null>(null);
  const [selectedJabatan, setSelectedJabatan] =
    useState<JabatanCustomer | null>(null);
  const [tujuanLain, setTujuanLain] = useState<string | null | undefined>(
    undefined
  );
  const [dateValue, setDateValue] = useState<Date | number>(
    props.data && props.data.date
      ? props.data.date.getEpochDate() * 1000
      : selectedPeriode
        ? dayjs(selectedPeriode.start_period.getEpochDate() * 1000).toDate()
        : dayjs().startOf("date").toDate()
  );
  const [startTime, setStartTime] = useState<Date | number>(
    props.data && props.data.startTime
      ? props.data.startTime.getEpochDate() * 1000
      : selectedPeriode
        ? dayjs(selectedPeriode!.start_period.getEpochDate() * 1000)
            .startOf("date")
            .toDate()
        : dayjs().startOf("date").toDate()
  );
  const [endTime, setEndTime] = useState<Date | number>(
    props.data && props.data.endTime
      ? props.data.endTime.getEpochDate() * 1000
      : selectedPeriode
        ? dayjs(selectedPeriode!.start_period.getEpochDate() * 1000)
            .endOf("date")
            .toDate()
        : dayjs().endOf("date").toDate()
  );

  const [searched, setSearched] = useState<string | undefined>();
  const debouncedOnSearch = useRef(
    debounce(async (value: string | undefined) => {
      if (value && (value.length === 0 || value.length >= 3)) {
        setSearched(value);
      }
    }, 300)
  ).current;

  const { outletData } = useGetOutletByUserId({
    params: {
      userId: loggedUser?.userId,
      keyword: searched,
    },
    startFetching: props.openDialogInputEditExtraCall,
  });

  const { customerData } = useGetCustomerByOutletId(
    props.openDialogInputEditExtraCall,
    {
      outlet_id: selectedOutlet ? selectedOutlet.outletId : null,
    }
  );
  const { tujuanKunjunganData } = useGetTujuanKunjunganList(
    props.openDialogInputEditExtraCall
  );

  useEffect(() => {
    if (props.openDialogInputEditExtraCall && props.type === "update") {
      if (outletData.length > 0) {
        const defaultOutlet = outletData.find(
          (item) => item.outletId === props.data?.outletId
        );
        setSelectedOutlet(defaultOutlet ?? null);
        form.setValue(
          "outlet_id",
          defaultOutlet ? defaultOutlet.outletId : null
        );
      }

      if (tujuanKunjunganData.length > 0 && props.data) {
        const defaultKunjungan = tujuanKunjunganData.find(
          (item) => item.visitName === props.data?.tujuanKunjungan
        );
        const lastIndex = tujuanKunjunganData.length - 1;
        setSelectedTujuan(defaultKunjungan ?? tujuanKunjunganData[lastIndex]);

        setTujuanLain(defaultKunjungan ? null : props.data?.tujuanKunjungan);
        form.setValue(
          "purpose_of_visit",
          defaultKunjungan
            ? defaultKunjungan.visitName
            : tujuanKunjunganData[lastIndex].visitName
        );
        form.setValue(
          "tujuan_lain",
          defaultKunjungan ? null : props.data?.tujuanKunjungan
        );
      }

      const defaultJabatan = JabatanCustomerList.find(
        (item) => item.jabatan_customer === props.data?.jabatan
      );
      setSelectedJabatan(defaultJabatan ?? null);
      form.setValue(
        "position_customer",
        defaultJabatan ? defaultJabatan.jabatan_customer : ""
      );
    } else {
      setSelectedOutlet(null);
      setSelectedTujuan(null);
      setTujuanLain(null);
      setSelectedJabatan(null);
      form.reset();
    }
  }, [
    outletData,
    props.data?.outletId,
    props.data?.jabatan,
    props.data?.tujuanKunjungan,
    props.openDialogInputEditExtraCall,
    tujuanKunjunganData,
  ]);

  useEffect(() => {
    if (props.openDialogInputEditExtraCall && props.type === "update") {
      if (selectedOutlet?.outletId === props.data?.outletId) {
        if (customerData.length > 0) {
          const defaultCustomer = customerData.find(
            (item) => item.customer_id === props.data?.customerId
          );
          setSelectedCustomer(defaultCustomer ?? null);
          form.setValue(
            "customer_id",
            defaultCustomer ? defaultCustomer.customer_id : null
          );
        }
      }
    } else {
      setSelectedCustomer(null);
    }
  }, [
    customerData,
    props.data?.customerId,
    props.data?.outletId,
    props.openDialogInputEditExtraCall,
    selectedOutlet?.outletId,
  ]);

  useEffect(() => {
    if (customerData.length === 0) {
      setSelectedCustomer(null);
    }
  }, [customerData.length]);

  useEffect(() => {
    if (!props.data && selectedPeriode) {
      setDateValue(
        dayjs(selectedPeriode.start_period.getEpochDate() * 1000).toDate()
      );
      setStartTime(
        dayjs(selectedPeriode!.start_period.getEpochDate() * 1000)
          .startOf("date")
          .toDate()
      );
      setEndTime(
        dayjs(selectedPeriode!.start_period.getEpochDate() * 1000)
          .endOf("date")
          .toDate()
      );
    }
  }, [props.data, selectedPeriode]);

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      isOpen={props.openDialogInputEditExtraCall}
      onClose={props.onClose}
      scrollBehavior="outside"
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          {props.dialogTitle}
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form
              id={`form-extra-call-${props.type}`}
              onSubmit={form.handleSubmit(props.onSubmit)}
            >
              <Box width="100%" marginBottom={isDesktopView ? "20px" : 0}>
                <RealisasiExtraCallOutletFormField
                  outletData={outletData}
                  customerData={customerData}
                  selectedOutlet={selectedOutlet}
                  onChange={(data) => {
                    setSelectedOutlet(data);
                    if (!data) {
                      setSelectedCustomer(null);
                    }
                  }}
                  onInputChange={(value) => {
                    debouncedOnSearch(value);
                  }}
                />
              </Box>
              <Flex
                marginBottom={isDesktopView ? "20px" : 0}
                gap={isDesktopView ? 9 : 0}
                direction={isDesktopView ? "row" : "column"}
              >
                <RealisasiExtraCallCustomerFormField
                  customerData={customerData}
                  selectedCustomer={selectedCustomer}
                  onChange={(data) => setSelectedCustomer(data)}
                  isDisabled={!selectedOutlet}
                />
                <RealisasiExtraCallJabatanFormField
                  jabatanData={JabatanCustomerList}
                  selectedJabatan={selectedJabatan}
                  onChange={(data) => setSelectedJabatan(data)}
                />
              </Flex>
              <Box marginBottom={!isDesktopView ? 0 : "20px"}>
                <RealisasiExtraCallDateTimeFormField
                  value={dateValue}
                  formInputLabel="Tanggal Kunjungan"
                  name="date"
                  type="date"
                  onChange={(data) => {
                    setDateValue(data!);
                    setStartTime(dayjs(data).startOf("day").toDate());
                    setEndTime(dayjs(data).endOf("day").toDate());
                  }}
                  minDate={
                    selectedPeriode
                      ? dayjs(
                          selectedPeriode.start_period.getEpochDate() * 1000
                        ).toDate()
                      : undefined
                  }
                  maxDate={
                    selectedPeriode
                      ? dayjs(
                          selectedPeriode.end_period.getEpochDate() * 1000
                        ).toDate()
                      : undefined
                  }
                />
              </Box>
              <Flex
                marginBottom={!isDesktopView ? 0 : "20px"}
                gap={!isDesktopView ? 0 : 9}
                direction={isDesktopView ? "row" : "column"}
              >
                <RealisasiExtraCallDateTimeFormField
                  value={startTime}
                  formInputLabel="Jam Mulai"
                  name="start_time"
                  type="time"
                  onChange={(data) => {
                    if (data) setStartTime(data);
                  }}
                />
                <RealisasiExtraCallDateTimeFormField
                  value={endTime}
                  formInputLabel="Jam Selesai"
                  name="end_time"
                  type="time"
                  onChange={(data) => {
                    if (data) setEndTime(data);
                  }}
                />
              </Flex>
              <Flex
                marginBottom={isDesktopView ? "20px" : 0}
                gap={isDesktopView ? 9 : 0}
                direction={isDesktopView ? "row" : "column"}
              >
                <RealisasiExtraCallTujuanFormField
                  tujuanKunjunganData={tujuanKunjunganData}
                  selectedTujuan={selectedTujuan}
                  onChange={(data) => setSelectedTujuan(data)}
                />
                <RealisasiExtraCallTujuanLainFormField
                  defaultValue={tujuanLain ?? undefined}
                  selectedTujuan={selectedTujuan}
                />
              </Flex>
              <RealisasiExtraCallImagePickerFormField
                defaultValue={props.data?.photo ?? undefined}
              />
              <RealisasiExtraCallKomentarKunjunganField
                defaultValue={props.data?.komentarKunjungan ?? undefined}
              />
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter
          flexDirection={!isDesktopView ? "column" : "row-reverse"}
          gap={!isDesktopView ? 0 : 5}
        >
          <Button
            type="submit"
            form={`form-extra-call-${props.type}`}
            width="100%"
            colorScheme="buttonPrimary"
            isLoading={props.isLoadingPhotoProcessing || props.isLoadingSubmit}
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              setDateValue(
                props.data?.date
                  ? props.data.date.getEpochDate() * 1000
                  : dayjs(
                      selectedPeriode!.start_period.getEpochDate() * 1000
                    ).toDate()
              );
              setStartTime(
                props.data?.startTime
                  ? props.data.startTime.getEpochDate() * 1000
                  : dayjs(selectedPeriode!.start_period.getEpochDate() * 1000)
                      .startOf("date")
                      .toDate()
              );
              setEndTime(
                props.data?.endTime
                  ? props.data.endTime.getEpochDate() * 1000
                  : dayjs(selectedPeriode!.start_period.getEpochDate() * 1000)
                      .endOf("date")
                      .toDate()
              );
              form.reset();
              props.onClose();
            }}
            width="100%"
            variant="outline"
            colorScheme="buttonSecondary"
            marginTop={!isDesktopView ? "12px" : 0}
            isDisabled={props.isLoadingPhotoProcessing || props.isLoadingSubmit}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DialogInputEditExtraCall;
