import { FormControl, FormErrorMessage, useTheme } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import {
  RealisasiStatus,
  RealisasiStatusType,
} from "../../models/ApprovalRealisasiStatus";
import FilterApprovalRealisasiKunjunganContext from "../../context/FilterApprovalRealisasiKunjunganContext";

const ApprovalRealisasiStatusFormField: React.FC = () => {
  const theme = useTheme();
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { filterValues } = useContext(FilterApprovalRealisasiKunjunganContext);

  const [status, setStatus] = useState<RealisasiStatusType | null | undefined>(
    filterValues?.status
  );
  
  useEffect(() => {
    setValue("realisasi_status", status);
  }, [status]);

  const StatusOptions: RealisasiStatusType[] = [
    {
      status_id: RealisasiStatus.Pending,
      status_label: RealisasiStatus.Pending,
    },
    {
      status_id: RealisasiStatus.Request,
      status_label: RealisasiStatus.Request,
    },{
      status_id: RealisasiStatus.Approved,
      status_label: RealisasiStatus.Approved,
    },
  ];

  return (
    <FormControl width="100%" isInvalid={!!errors.realisasi_status}>
      <FormInputLabel title="Status" />
      <CustomSelect
        {...register("realisasi_status")}
        options={StatusOptions}
        isClearable
        getOptionValue={(data: RealisasiStatusType) => data.status_id}
        getOptionLabel={(data: RealisasiStatusType) => data.status_label}
        onChange={(data: RealisasiStatusType) => {
          setValue("realisasi_status", data ? data : null);

          setStatus(data);
        }}
        value={status}
        placeholder="Status"
      />
      <FormErrorMessage color={theme.colors.exodus.red}>
        {errors.realisasi_status && (errors.realisasi_status.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default ApprovalRealisasiStatusFormField;
