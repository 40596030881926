import ApprovalRencanaFilterValues from "../models/ApprovalRencanaFilterValues";

const initialFilterValue: ApprovalRencanaFilterValues =
  new ApprovalRencanaFilterValues({
    jabatan: null,
    bawahan: null,
    periode: null,
    status: null,
    project: null,
  });

export default initialFilterValue;
