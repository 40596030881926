import React, { useState } from "react";
import { isEmpty } from "lodash";
import ApprovalRealisasiKunjunganFilterValues  from "../models/ApprovalRealisasiKunjunganFilterValues";
import RealisasiKunjunganProvider from "../../dataProviders/RealisasiKunjunganProvider";
import DateExodus from "../../../../../models/DateExodus";

const useGetApprovalRealisasiFilter = () => {
  const [data, setData] = useState<ApprovalRealisasiKunjunganFilterValues | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);

      try {
        const result: ApprovalRealisasiKunjunganFilterValues | null = await RealisasiKunjunganProvider.getSavedApprovalRealisasiFilter();
        
        if (isEmpty(result)) {
          setData(result);
        }
        if (!isEmpty(result)) {
            
        const start_period = new DateExodus(Number(result.periode?.start_period  as unknown));
        const end_period =  new DateExodus(Number(result.periode?.end_period as unknown));
        
        const persistentFilter: ApprovalRealisasiKunjunganFilterValues = {
            ...result,
            periode: {start_period, end_period},
          };
          setData(persistentFilter);
          return;
        }
      } catch (error: any) {
        setErrorMessage(error)
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return { data, isLoading, errorMessage };
};

export default useGetApprovalRealisasiFilter;
