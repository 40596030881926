import { useEffect, useState } from "react";
import RealisasiKunjunganProvider from "../../dataProviders/RealisasiKunjunganProvider";
import { DetailApprovalRealisasiKunjunganModel } from "../models/ApprovalRealisasiKunjunganModel";
import { RealisasiByHeaderUserIdAndActiveTabParam } from "../../dataProviders/RealisasiKunjunganParamType";

const useGetRealisasiByHeaderUserIdAndActiveTab = ({
  header_id,
  user_id,
  activeTab,
}: RealisasiByHeaderUserIdAndActiveTabParam) => {
  const [realisasiDetailData, setData] = useState<
    Array<DetailApprovalRealisasiKunjunganModel>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function getData() {
    try {
      setLoading(true);

      const response =
        await RealisasiKunjunganProvider.getRealisasiByHeaderUserIdAndActiveTab(
          {
            header_id: header_id,
            user_id: user_id,
            activeTab: activeTab,
          }
        );

      setData(response!.data.detailRealisasi);
      setLoading(false);
    } catch (error: any) {
      setData([]);
      setErrorMessage(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (header_id && user_id) {
      getData();
    }
  }, [header_id, user_id, activeTab]);

  return { realisasiDetailData, errorMessage, loading };
};

export default useGetRealisasiByHeaderUserIdAndActiveTab;
