/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableColumn } from "react-data-table-component";
import { RealisasiAndExtraCallDetailRealizationModelData } from "../models/RealisasiAndExtraCallDataModel";
import StackedColumn from "../../../../../components/TableData/StackedColumnTable";
import { Box, Text, useTheme } from "@chakra-ui/react";
import ButtonInputRealisasiAndEditExtraCall from "../components/ButtonInputRealisasiAndEditExtraCall";
import ButtonLihatDetailRealisasiAndExtraCall from "../components/ButtonLihatDetailRealisasiAndExtraCall";

const GenerateRealisasiTableColumnData = () => {
  const theme = useTheme();

  const generateWaktuKunjungan = (
    data: RealisasiAndExtraCallDetailRealizationModelData
  ) => {
    if (
      !data.date &&
      !data.startTime &&
      !data.endTime &&
      !data.komentarKunjungan
    ) {
      return "-";
    } else if (
      !data.date &&
      !data.startTime &&
      !data.endTime &&
      data.komentarKunjungan
    ) {
      return "Gagal Kunjungan";
    } else {
      return `${data.date!.getStringDateFormat("dddd, D MMMM YYYY")}`;
    }
  };

  const generateWaktuKunjunganTextColor = (
    data: RealisasiAndExtraCallDetailRealizationModelData
  ) => {
    if (
      !data.date &&
      !data.startTime &&
      !data.endTime &&
      data.komentarKunjungan
    ) {
      return theme.colors.exodus.red;
    } else {
      return theme.colors.exodus.fontDefault;
    }
  };

  const realisasiDataColumn: TableColumn<any>[] = [
    {
      name: "Nama Outlet",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.outletName}
          bottomValue={row.outletCode}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "250px",
      grow: 1.5,
    },
    {
      name: "Nama Customer",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.customerName}
          bottomValue={row.customerCode}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "250px",
      grow: 1.5,
    },
    {
      name: "Spesialis",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <Text textAlign="left">{row.spesialis}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      minWidth: "200px",
    },
    {
      name: "Jabatan",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <Text textAlign="left">{row.jabatan}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      grow: 0.5,
    },
    {
      name: "Waktu Kunjungan",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <Text textAlign="left" color={generateWaktuKunjunganTextColor(row)}>
          {generateWaktuKunjungan(row)}
        </Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <Box>
          {row.komentarKunjungan && (
            <ButtonLihatDetailRealisasiAndExtraCall data={row} />
          )}
        </Box>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      right: true,
      grow: 0.5,
    },
    {
      name: "",
      cell: (row: RealisasiAndExtraCallDetailRealizationModelData) => (
        <Box>
          {!row.komentarKunjungan && (
            <ButtonInputRealisasiAndEditExtraCall
              data={row}
              buttonText="Input Realisasi"
            />
          )}
          {row.komentarKunjungan && (
            <ButtonInputRealisasiAndEditExtraCall
              data={row}
              buttonText="Edit"
            />
          )}
        </Box>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
      right: true,
      grow: 0.5,
    },
  ];

  return realisasiDataColumn;
};

export default GenerateRealisasiTableColumnData;
