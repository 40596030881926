import { useState } from "react";
import ShowFilterButton from "../../../../../components/CustomButtons/ShowFilterButton";
import DialogFilterMonitorSalesTarget from "./DialogFilterMonitorSalesTarget";

const ButtonFilterMonitorSalesTarget: React.FC = () => {
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);

  return (
    <>
      <ShowFilterButton onClick={() => setOpenFilterDialog(true)} />

      <DialogFilterMonitorSalesTarget
        isOpen={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
      />
    </>
  );
};

export default ButtonFilterMonitorSalesTarget;
