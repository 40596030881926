import { Box } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import LoggedUser from "../../../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../../../redux/hooks";
import ProjectType from "../../../../../../models/common/ProjectType";
import { isEmpty } from "lodash";

const FilterByProject = () => {
  const formContext = useFormContext();
  const watchProject = formContext.watch("project");

  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  return (
    <Box width={"100%"}>
      <FormInputLabel title="Project" />
      <CustomSelect
        options={loggedUser?.projects}
        value={watchProject}
        onChange={(eachProject: ProjectType | null) => {
          formContext.setValue("project", eachProject);
        }}
        getOptionLabel={(eachProject: ProjectType) => eachProject.label}
        placeholder="Project"
        getOptionValue={(eachProject) => eachProject.label}
        isDisabled={loggedUser?.projects?.length === 1 || isEmpty(loggedUser?.projects)}
        isClearable
      />
    </Box>
  );
};

export default FilterByProject;
