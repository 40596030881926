import { useContext } from "react";
import {
  Box,
  Flex,
  Spinner,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import InputRealisasiRadioGroup from "./InputRealisasiRadioGroup";
import InputRealisasiPeriodeAndStatus from "./InputRealisasiPeriodeAndStatus";
import KunjunganAlertComponent from "../../../components/KunjunganAlertComponent";
import DesktopTableRealisasiKunjungan from "./desktop/DesktopTableRealisasiKunjungan";
import MobileTableRealisasiKunjungan from "./mobile/MobileTableRealisasiKunjungan";
import ButtonInputExtraCall from "./ButtonInputExtraCall";
import ButtonSubmitRealisasiKunjungan from "./ButtonSubmitRealisasiKunjungan";

const InputRealisasiKunjunganPage: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const { selectedPeriode, currentKunjungan, periodeLoading } = useContext(
    InputRealisasiKunjunganContext
  );

  if (periodeLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
        <Text fontSize={24} fontWeight="semibold">
          Sedang Mendapatkan Data Periode
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <PageTitle label="Input Realisasi Kunjungan" />
      <InputRealisasiRadioGroup />
      <InputRealisasiPeriodeAndStatus />
      {selectedPeriode && selectedPeriode.senior_comment && (
        <KunjunganAlertComponent
          type="success"
          title="Komentar Atasan"
          message={selectedPeriode.senior_comment}
        />
      )}
      {selectedPeriode && selectedPeriode.realization_reject_reason && (
        <KunjunganAlertComponent
          type="warning"
          title="Alasan Penolakan dari Atasan"
          message={selectedPeriode.realization_reject_reason}
        />
      )}
      {isDesktopView && <DesktopTableRealisasiKunjungan />}
      {!isDesktopView && <MobileTableRealisasiKunjungan />}
      {!isDesktopView && (
        <Flex
          position="sticky"
          bottom={0}
          bgColor={theme.colors.exodus.background}
          paddingY="12px"
          gap={3}
        >
          {currentKunjungan === "extracall" && <ButtonInputExtraCall />}
          <ButtonSubmitRealisasiKunjungan />
        </Flex>
      )}
    </Box>
  );
};

export default InputRealisasiKunjunganPage;
