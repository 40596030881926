import { useEffect, useState } from "react";
import { BiayaMurniOrCode } from "../../../models/BiayaMurniOrCode";
import BiayaMurniProvider from "../../../dataProviders/BiayaMurniProvider";
import ErrorResponse from "../../../../../models/common/ErrorResponse";

const useDpOrManager = (dpIds: number[]) => {
  const [dataArr, setDataArr] = useState<(BiayaMurniOrCode[] | null)[]>([]);
  const [isLoadingArr, setIsLoadingArr] = useState<boolean[]>([]);
  const [errorArr, setErrorArr] = useState<(ErrorResponse | null)[]>([]);
  const [isLoadingInit, setIsLoadingInit] = useState<boolean>(true);

  useEffect(() => {
    const init = () => {
      setIsLoadingInit(true);
      setDataArr(dpIds.map((_) => null));
      setIsLoadingArr(dpIds.map((_) => false));
      setErrorArr(dpIds.map((_) => null));

      setIsLoadingInit(false);
    };

    init();
  }, [dpIds]);

  const fetchOneByIndex = async (index: number) => {
    if (dataArr[index]) {
      return;
    }

    try {
      setErrorArr(
        errorArr.map((err, i) => {
          if (i === index) {
            return null;
          } else {
            return err;
          }
        })
      );
      setIsLoadingArr(
        isLoadingArr.map((ldg, i) => {
          if (i === index) {
            return true;
          } else {
            return ldg;
          }
        })
      );

      const result = await BiayaMurniProvider.getOrCodesOfDp({
        id: dpIds[index],
      });

      setDataArr(
        dataArr.map((data, i) => {
          if (i === index) {
            return result.data;
          } else {
            return data;
          }
        })
      );
    } catch (error) {
      if (error as ErrorResponse) {
        setErrorArr(
          errorArr.map((err, i) => {
            if (i === index) {
              return error as ErrorResponse;
            } else {
              return err;
            }
          })
        );
      }
    } finally {
      setIsLoadingArr(
        isLoadingArr.map((ldg, i) => {
          if (i === index) {
            return false;
          } else {
            return ldg;
          }
        })
      );
    }
  };

  return {
    fetchOneByIndex,
    dataArr,
    isLoadingArr,
    errorArr,
    isLoadingInit,
  };
};

export default useDpOrManager;
