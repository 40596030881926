/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse, isAxiosError } from "axios";
import AxiosBaseClient from "../../../../dataProviders/config/AxiosBaseClient";
import { AXIOS_SUCCESS_MESSAGE_RESPONSE } from "../../../../constants/common/AxiosResponseConstants";
import { MonitorKunjunganListParam } from "./MonitorKunjunganParamType";
import { MonitorKunjunganModelPack } from "../models/MonitorKunjunganDataModel";
import { UNKNOWN_ERROR_RESPONSE } from "../../../../constants/common/ErrorConstants";

const { SUCCESS_GET_STATUS, SUCCESS_MESSAGE } = AXIOS_SUCCESS_MESSAGE_RESPONSE;

const MonitorKunjunganProvider = {
  getMonitorKunjunganList: async (params: MonitorKunjunganListParam) => {
    try {
      let url: string;

      if (params.isPsspActive) {
        url = `/v1/monitor`;
      } else {
        url = `/v1/monitor/non_active`;
      }

      const response: AxiosResponse = await AxiosBaseClient.get(url, {
        params: {
          user_id: params.user_id,
          start_period: parseInt(JSON.stringify(params.start_period)),
          end_period: parseInt(JSON.stringify(params.end_period)),
          project: params.project?.value
        },
      });

      if (response.status === SUCCESS_GET_STATUS) {
        if (response.data.message === SUCCESS_MESSAGE) {
          const responseData = new MonitorKunjunganModelPack(response.data);
          return Promise.resolve(responseData);
        } else {
          return Promise.reject(response.data.message);
        }
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        return Promise.reject({
          message: error.response ? error.response.data.message : error.message,
          status: error.response?.status,
          code: error.response ? error.response.data.code : error.code,
        });
      }
      return Promise.reject(UNKNOWN_ERROR_RESPONSE);
    }
  },
};

export default MonitorKunjunganProvider;
