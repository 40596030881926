import { Box, Stack, useTheme } from "@chakra-ui/react";
import LoggedUser from "../../../../models/common/LoggedUser";
import { useAppSelector } from "../../../../redux/hooks";
import VerticalTextInformation from "../common/VerticalTextInformation";
import ChangePasswordModal from "../common/modal/ChangePasswordModal";
import ProfileActionButton from "../common/ProfileActionButton";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React from "react";

const MobileProfile = () => {
  const theme = useTheme();
  const loggedUser: LoggedUser | null = useAppSelector(
    (store) => store.authReducer.loggedUser
  );

  const [isChangePasswordModalShown, setIsChangePasswordModalShown] =
    React.useState<boolean>(false);

  const handleOnChangeModalShown = (isOpen: boolean) => {
    setIsChangePasswordModalShown(isOpen);
  };

  const handleOnClickChangePassword = () => {
    handleOnChangeModalShown(true);
  };

  return (
    <Box sx={{ height: "90vh", display: "flex", flexDirection: "column" }}>
      <Stack
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          p: 0,
        }}
      >
        <Stack
          sx={{
            flexDirection: "column",
            paddingX: 2,
          }}
        >
          <PageTitle label="Profile" />
          <Box mb={2} />
          <VerticalTextInformation
            title={"Nama"}
            value={loggedUser?.userName!!}
          />
          <VerticalTextInformation
            title={"Jabatan"}
            value={loggedUser?.userRoleLabel!!}
          />
          <VerticalTextInformation
            title={"NIP"}
            value={loggedUser?.userNip!!}
          />
          <VerticalTextInformation
            title={"Email"}
            value={loggedUser?.userEmail!!}
          />
        </Stack>

        <ChangePasswordModal
          isModalShown={isChangePasswordModalShown}
          handleOnChangeIsModalShown={handleOnChangeModalShown}
        />
      </Stack>
      <Stack
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 10,
          backgroundColor: `${theme.colors.exodus.background}`,
          width: "100%",
        }}
      >
        <ProfileActionButton
          handleOnClickChangePassword={handleOnClickChangePassword}
        />
      </Stack>
    </Box>
  );
};

export default MobileProfile;
