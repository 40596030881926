import {
  Box,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useStepper } from "../../contexts/StepperContextProvider";

type _WithChildren<T = {}> = T & { children?: React.ReactNode };

type StepperControlFrameProps = _WithChildren<{
  onHandleBack: (() => void) | undefined;
  onHandleNext: (() => void) | undefined;
  isLoading?: boolean;
}>;

const StepperControlFrame = ({
  onHandleBack,
  onHandleNext,
  isLoading,
  children,
}: StepperControlFrameProps): JSX.Element => {
  const {
    constValue: { maxStepIdx },
    state: { activeStepIdx, isActionButtonHidden },
  } = useStepper();
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  return (
    <Stack
      direction="column"
      width="100%"
      justify={isDesktopView ? "normal" : "space-between"}
    >
      <Box flex={1}>{children}</Box>
      {!isActionButtonHidden && (
        <Stack
          direction={"row"}
          gap={isDesktopView ? 3 : 2}
          my={isDesktopView ? 4 : 2}
          py="12px"
          alignItems={"center"}
          justify={isDesktopView ? "end" : "start"}
          bgColor={
            isDesktopView ? "transparent" : theme.colors.exodus.background
          }
        >
          <Box width={isDesktopView ? 182 : "100%"}>
            <Button
              width="100%"
              variant="outline"
              colorScheme="buttonSecondary"
              onClick={onHandleBack}
              isDisabled={
                onHandleBack === undefined || isLoading || activeStepIdx === 0
              }
            >
              Kembali
            </Button>
          </Box>
          <Box width={isDesktopView ? 182 : "100%"}>
            <Button
              width="100%"
              colorScheme="buttonPrimary"
              onClick={onHandleNext}
              isDisabled={onHandleNext === undefined || isLoading}
              isLoading={isLoading}
            >
              {activeStepIdx === maxStepIdx ? "Submit" : "Selanjutnya"}
            </Button>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default StepperControlFrame;
