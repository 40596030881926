import {
  useMediaQuery,
  useTheme,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import HorizontalAccordionDetail from "../../../../../components/HorizontalAccordionDetail/HorizontalAccordionDetail";
import CurrencyIdrFormatter from "../../../../../utils/CurrencyIdrFormatter";

type Props = {
  key?: any;
  leftText: string;
  rightText: number | null;
};

const SalesTargetTotalMonthly: React.FC<Props> = ({ leftText, rightText }) => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );
  return (
    <>
      {isDesktopView ? (
        <Grid
          templateColumns="repeat(7, 1fr)"
          paddingBottom={3}
          justifyContent="start"
        >
          <GridItem colSpan={1} textAlign={"left"}>
            <Text
              fontWeight="bold"
              fontSize={"16px"}
              color={theme.colors.exodus.primaryBlue}
            >
              Total Target {leftText}
            </Text>
          </GridItem>
          <GridItem colSpan={5}>
            <Grid templateColumns="repeat(25, 1fr)">
              <GridItem colSpan={1} maxWidth={"20px"}>
                <Text fontSize={"16px"}>:</Text>
              </GridItem>
              <GridItem colSpan={20}>
                <Text fontSize={"16px"} textAlign={"start"}>
                  {rightText !== null && rightText >= 0
                    ? CurrencyIdrFormatter.convertToRp(rightText)
                    : "-"}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      ) : (
        <HorizontalAccordionDetail
          leftText={`Total Target ${leftText}`}
          rightSide={
            rightText !== null && rightText >= 0
              ? CurrencyIdrFormatter.convertToRp(rightText)
              : "-"
          }
        />
      )}
    </>
  );
};

export default SalesTargetTotalMonthly;
