import { useContext } from "react";
import StackedColumn from "../../../../../../components/TableData/StackedColumnTable";
import TableDataComponent from "../../../../../../components/TableData/TableDataComponent";
import { DetailApprovalRencanaKunjunganModelDetailVisitData } from "../../models/ApprovalRencanaKunjunganData";
import ApprovalRencanaKunjunganDetailContext from "../../context/ApprovalRencanaKunjunganDetailContext";
import { Text } from "@chakra-ui/react";

const DesktopTableDetailApprovalRencanaKunjungan = () => {
  const { detailData } = useContext(ApprovalRencanaKunjunganDetailContext);
  const tableColumn = [
    {
      name: "Nama Outlet",
      cell: (row: DetailApprovalRencanaKunjunganModelDetailVisitData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.outletName}
          bottomValue={row.outletCode}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "Nama Customer",
      cell: (row: DetailApprovalRencanaKunjunganModelDetailVisitData) => (
        <StackedColumn
          isTopBoldApplied
          isTopColorPrimaryBlue
          topValue={row.customerName}
          bottomValue={row.customerCode}
        />
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "Spesialis",
      cell: (row: DetailApprovalRencanaKunjunganModelDetailVisitData) => (
        <Text textAlign="left">{row.spesialis}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "Jabatan",
      cell: (row: DetailApprovalRencanaKunjunganModelDetailVisitData) => (
        <Text textAlign="left">{row.posisi}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "Kunjungan",
      cell: (row: DetailApprovalRencanaKunjunganModelDetailVisitData) => (
        <Text textAlign="left">{row.totalVisit} Kali</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
    {
      name: "Tujuan",
      cell: (row: DetailApprovalRencanaKunjunganModelDetailVisitData) => (
        <Text textAlign="left">{row.tujuan}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    },
  ];

  if (
    detailData &&
    detailData.detailVisitData.length > 0 &&
    detailData.detailVisitData[0].project
  ) {
    tableColumn.unshift({
      name: "Project",
      cell: (row: DetailApprovalRencanaKunjunganModelDetailVisitData) => (
        <Text textAlign="left">{row.project!.label}</Text>
      ),
      sortable: false,
      ignoreRowClick: false,
      allowOverflow: false,
    });
  }

  return (
    <TableDataComponent
      data={detailData?.detailVisitData}
      tableHeader={tableColumn}
      onSelectChange={() => {}}
      checboxOn={false}
      paginationOn={false}
      paginationLimit={[]}
    />
  );
};

export default DesktopTableDetailApprovalRencanaKunjungan;
