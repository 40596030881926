import { useEffect, useState } from "react";
import useGetPeriodeByUserId from "../../../services/useGetPeriodeByUserId";
import InputRealisasiKunjunganContext from "../context/InputRealisasiKunjunganContext";
import PeriodeDataModel from "../../../models/PeriodeDataModel";
import useGetRealisasiOrExtraCallList from "../services/useGetRealisasiOrExtraCallList";
import InputRealisasiKunjunganPage from "./InputRealisasiKunjunganPage";
import { useNavigate, useParams } from "react-router-dom";
import useTitle from "../../../../../services/useTitle";

const InputRealisasiKunjunganBase: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [key, setKey] = useState<number>(0);
  const [selectedPeriode, setSelectedPeriode] =
    useState<PeriodeDataModel | null>(null);
  const [currentKunjungan, setCurrentKunjungan] = useState<string>("");

  const {
    periodeData,
    loading: periodeLoading,
    errorMessage: periodeError,
  } = useGetPeriodeByUserId(key);

  const {
    realisasiOrExtraCallData,
    loading: realisasiOrExtraCallLoading,
    errorMessage: realisasiOrExtraCallError,
  } = useGetRealisasiOrExtraCallList(key, {
    header_id: selectedPeriode?.header_id,
    isRealisasi: currentKunjungan === "extracall" ? false : true,
  });

  useEffect(() => {
    if (periodeData.length > 0 && !selectedPeriode) {
      setSelectedPeriode(periodeData[0]);
      return;
    }

    if (periodeData.length > 0 && selectedPeriode) {
      setSelectedPeriode(
        periodeData.find(
          (item) => item.start_period === selectedPeriode.start_period
        )!
      );
      return;
    }
  }, [periodeData, selectedPeriode]);

  useEffect(() => {
    if (params["*"] === "") {
      setCurrentKunjungan("rencana");
      navigate("/realisasi-kunjungan/rencana", { replace: true });
    } else if (params["*"] === "rencana") {
      setCurrentKunjungan("rencana");
    } else if (params["*"] === "extracall") {
      setCurrentKunjungan("extracall");
    }
  }, [navigate, params]);

  useTitle("Input Realisasi Kunjungan");

  return (
    <InputRealisasiKunjunganContext.Provider
      value={{
        periodeData: periodeData,
        periodeLoading: periodeLoading,
        periodeError: periodeError,
        realisasiOrExtraCallData: realisasiOrExtraCallData,
        realisasiOrExtraCallLoading: realisasiOrExtraCallLoading,
        realisasiOrExtraCallError: realisasiOrExtraCallError,
        selectedPeriode: selectedPeriode,
        setSelectedPeriode: setSelectedPeriode,
        currentKunjungan: currentKunjungan,
        setCurrentKunjungan: setCurrentKunjungan,
        key: key,
        setKey: setKey,
      }}
    >
      <InputRealisasiKunjunganPage />
    </InputRealisasiKunjunganContext.Provider>
  );
};

export default InputRealisasiKunjunganBase;
