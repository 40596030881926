import { useState } from "react";
import ApprovalRealisasiKunjunganFilterValues  from "../models/ApprovalRealisasiKunjunganFilterValues";
import RealisasiKunjunganProvider from "../../dataProviders/RealisasiKunjunganProvider";

const useSaveApprovalRealisasiFilter = () => {
  const [message, setMessage] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);

  const saveData = async (
    filterValues: ApprovalRealisasiKunjunganFilterValues
  ) => {
    const start_period = filterValues.periode?.start_period.getEpochDate();
    const end_period = filterValues.periode?.end_period.getEpochDate();
    setLoading(true);
    try {
      const saveFilter = {
        ...filterValues,
        periode: {start_period, end_period},
      };

      const result: string =
        await RealisasiKunjunganProvider.saveApprovalRealisasiFilter(
          saveFilter
        );

      setMessage(result);
    } catch (error: unknown) {
      setMessage(error as string);
    } finally {
      setLoading(false);
    }
  };

  return { message, isLoading, saveData };
};

export default useSaveApprovalRealisasiFilter;
