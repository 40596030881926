import { createContext } from "react";
import { DraftRencanaKunjunganModelData } from "../models/DraftRencanaKunjunganModel";
import PeriodeDataModel from "../../../models/PeriodeDataModel";
import MinimalVisitDataModel from "../models/MinimalVisitDataModel";

type InputRencanaKunjunganContextProps = {
  periodeData: PeriodeDataModel[];
  periodeLoading: boolean;
  periodeError: string | null;
  minimalVisitData: MinimalVisitDataModel | null;
  minimalVisitLoading: boolean;
  minimalVisitError: string | null;
  draftRencanaKunjunganData: DraftRencanaKunjunganModelData[];
  draftRencanaLoading: boolean;
  draftRencanaError: string | null;
  selectedPeriode: PeriodeDataModel | null;
  setSelectedPeriode: (data: PeriodeDataModel | null) => void;
  key: number;
  setKey: (data: number) => void;
};

const defaultValue: InputRencanaKunjunganContextProps = {
  periodeData: [],
  periodeLoading: false,
  periodeError: null,
  minimalVisitData: null,
  minimalVisitLoading: false,
  minimalVisitError: null,
  draftRencanaKunjunganData: [],
  draftRencanaLoading: false,
  draftRencanaError: null,
  selectedPeriode: null,
  setSelectedPeriode: () => {},
  key: 0,
  setKey: () => {},
};

const InputRencanaKunjunganContext = createContext(defaultValue);

export default InputRencanaKunjunganContext;
