import { useEffect, useState } from "react";
import FilterMonitorKunjunganModel from "../../models/FilterMonitorKunjunganModel";
import IndexedDatabase from "../../../../../database/IndexDB";
import AllPeriodeDataModel from "../../../models/AllPeriodeDataModel";
import DateExodus from "../../../../../models/DateExodus";
import ProjectType from "../../../../../models/common/ProjectType";

const useFilterStateMonitorKunjunganMonitorList = () => {
  const [savedFilter, setSavedFilter] =
    useState<FilterMonitorKunjunganModel | null>(null);
  const [selectedPeriode, setSelectedPeriode] =
    useState<AllPeriodeDataModel | null>(null);
  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(
    null
  );

  useEffect(() => {
    getSavedFilterValue().then((data: FilterMonitorKunjunganModel) => {
      setSavedFilter(data);

      if (data.periode) {
        setSelectedPeriode({
          start_period: new DateExodus(data.periode.start_period),
          end_period: new DateExodus(data.periode.end_period),
        });
      }

      if (data.project) {
        setSelectedProject(data.project);
      }
    });
  }, []);

  const getSavedFilterValue = async () => {
    const data: FilterMonitorKunjunganModel[] | undefined =
      await IndexedDatabase.filterMonitorKunjungan.toArray();

    setSavedFilter(data[0]);

    return data[0];
  };

  const handleChangeSelectedPeriode = async (
    newPeriode: AllPeriodeDataModel
  ) => {
    setSelectedPeriode(newPeriode);

    const oldData: FilterMonitorKunjunganModel[] | undefined =
      await IndexedDatabase.filterMonitorKunjungan.toArray();

    await IndexedDatabase.filterMonitorKunjungan
      .update(oldData[0].id, {
        periode: {
          start_period: newPeriode.start_period.getEpochDate(),
          end_period: newPeriode.end_period.getEpochDate(),
        },
      })
      .then(() => getSavedFilterValue());
  };

  const handleChangeSelectedProject = async (
    newProject: ProjectType | null
  ) => {
    setSelectedProject(newProject);

    const oldData: FilterMonitorKunjunganModel[] | undefined =
      await IndexedDatabase.filterMonitorKunjungan.toArray();

    await IndexedDatabase.filterMonitorKunjungan
      .update(oldData[0].id, {
        project: newProject,
      })
      .then(() => getSavedFilterValue());
  };

  return {
    savedFilter,
    selectedPeriode,
    handleChangeSelectedPeriode,
    selectedProject,
    handleChangeSelectedProject,
  };
};

export default useFilterStateMonitorKunjunganMonitorList;
