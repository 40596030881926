import { Box, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import useGetRoleByLoggedUser from "../../../services/Users/useGetRoleByLoggedUser";
import { useState } from "react";
import useGetUserByRole from "../../../services/Users/useGetUserByRole";
import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import MonitoringBudgetingContext from "../context/MonitoringBudgetingFilterContext";
import React from "react";
import RoleModel from "../../../models/Users/RoleModel";
import UserModel from "../../../models/Users/UserModel";
import theme from "../../../layout/theme/theme";

const JabatanCariNamaInput = () => {
  const breakPointTheme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${breakPointTheme.breakpoints.tablet})`
  );

  const formContext = useFormContext();

  const { filterValues } = React.useContext(MonitoringBudgetingContext);

  const [selectedRole, setSelectedRole] = useState<
    RoleModel | null | undefined
  >(filterValues?.jabatan);
  const [selectedNama, setSelectedNama] = useState<
    UserModel | null | undefined
  >(filterValues?.bawahan);

  const { roleData } = useGetRoleByLoggedUser();
  const { userData } = useGetUserByRole({
    roleName: !isEmpty(selectedRole) ? selectedRole.name : null,
  });

  return (
    <>
      {
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: isDesktopView ? "row" : "column",
            justifyContent: "space-between",
            marginBottom: "3%",
          }}
        >
          <Box sx={{ width: isDesktopView ? "35%" : "100%" }}>
            <Text fontSize="md" as="b" color={theme.colors.exodus.primaryBlue}>
              Jabatan
            </Text>
            <CustomSelect
              isDisabled={false}
              isSearchable={true}
              isClearable={true}
              onChange={(selectedData: RoleModel | null) => {
                setSelectedRole(selectedData);
                formContext.setValue("jabatan", selectedData);
                setSelectedNama(null);
                formContext.setValue("bawahan", null);
              }}
              value={selectedRole}
              getOptionLabel={(data) => `${data.label}`}
              getOptionValue={(data) => `${data.name}`}
              placeholder={"Jabatan"}
              options={roleData}
            />
          </Box>
          {!isDesktopView && <Box sx={{ marginY: "1%" }} />}
          <Box sx={{ width: isDesktopView ? "60%" : "100%" }}>
            <Text fontSize="md" as="b" color={theme.colors.exodus.primaryBlue}>
              Cari Nama
            </Text>
            <CustomSelect
              isDisabled={!isEmpty(selectedRole) ? false : true}
              isSearchable={true}
              isClearable={true}
              onChange={(selectedData: UserModel | null) => {
                setSelectedNama(selectedData);
                formContext.setValue("bawahan", selectedData);
              }}
              value={selectedNama}
              getOptionLabel={(data) => `${data.name}`}
              getOptionValue={undefined}
              placeholder={"Cari Nama"}
              options={userData}
            />
          </Box>
        </Box>
      }
    </>
  );
};

export default JabatanCariNamaInput;
