import { FormControl, Input } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import FormInputLabel from "../../../../../components/FormInputLabel/FormInputLabel";
import MonitorKlaimSalesContext from "../../contexts/MonitorKlaimSalesContext";
import { isEmpty } from "lodash";

const OutletPanelFormFieldKlaimSales: React.FC = () => {
  const { register, setValue, watch } = useFormContext();
  const watchId = watch("idKlaimSales");
  const { filterValues } = useContext(MonitorKlaimSalesContext);

  useEffect(() => {
    setValue("outletPanel", filterValues?.outletPanelName?.trim());
  }, []);

  return (
    <FormControl width={"100%"}>
      <FormInputLabel title="Outlet Panel" />
      <Input
        {...register("outletPanel")}
        placeholder="Nama Outlet Panel"
        defaultValue={filterValues?.outletPanelName?.trim()}
        isDisabled={!isEmpty(watchId)}
      />
    </FormControl>
  );
};

export default OutletPanelFormFieldKlaimSales;
