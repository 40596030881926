import { Center, HStack, Stack, Text } from "@chakra-ui/react";
import Pagination from "../../../../../../../../components/Pagination/Pagination";
import TableDataComponent from "../../../../../../../../components/TableData/TableDataComponent";
import { COMMON_PAGINATION_LIMIT_CHOICES } from "../../../../../../../../constants/components/PaginationConstants";
import CurrencyIdrFormatter from "../../../../../../../../utils/CurrencyIdrFormatter";
import { useSalesOutletDetailContext } from "../../../contexts/SalesOutletDetailContext";
import SalesOutletDetailProductData from "../../../models/SalesOutletDetailProductData";

const SalesOutletDetailTable = (): JSX.Element => {
  const {
    actions: { setPage, setLimit },
    states: { data },
  } = useSalesOutletDetailContext();

  if (data?.records) {
    return (
      <Stack>
        <TableDataComponent
          data={data.records}
          onSelectChange={() => {}}
          paginationLimit={COMMON_PAGINATION_LIMIT_CHOICES.map((val) => {
            return val.value;
          })}
          paginationOn={false}
          tableHeader={[
            {
              name: "Nama Produk",
              cell: (row: SalesOutletDetailProductData) => (
                <Text textAlign={"start"}>{row.productName}</Text>
              ),
              width: "36%",
            },
            {
              name: "Sales Value",
              cell: (row: SalesOutletDetailProductData) => (
                <Text textAlign={"start"}>
                  {CurrencyIdrFormatter.convertToRp(row.salesValue)}
                </Text>
              ),
              width: "17%",
            },
            {
              name: "Sales Quantity",
              cell: (row: SalesOutletDetailProductData) => (
                <Text textAlign={"start"}>{row.salesUnit} pc(s)</Text>
              ),
              width: "15%",
            },
            {
              name: "Estimated Value",
              cell: (row: SalesOutletDetailProductData) => (
                <Text textAlign={"start"}>
                  {CurrencyIdrFormatter.convertToRp(row.estimatedValue)}
                </Text>
              ),
              width: "17%",
            },
            {
              name: "Estimated Quantity",
              cell: (row: SalesOutletDetailProductData) => (
                <Text textAlign={"start"}>{row.estimatedUnit} pc(s)</Text>
              ),
              width: "15%",
            },
          ]}
          checboxOn={false}
        />
        <HStack justifyContent={"flex-end"}>
          <Pagination
            handleOnNavigateFirstPage={() => {
              setPage("first");
            }}
            handleOnNext={() => {
              setPage("next");
            }}
            handleOnBack={() => {
              setPage("prev");
            }}
            handleOnLimitChange={(value) => {
              setLimit(value);
            }}
            page={data.metadata.currentPage}
            limit={data.metadata.totalDataPerPage}
            totalData={data.metadata.totalData}
            totalPage={data.metadata.totalPage}
          />
        </HStack>
      </Stack>
    );
  }

  return (
    <Center>
      <Text fontWeight={600}>Data daftar sales outlet tidak ditemukan</Text>
    </Center>
  );
};

export default SalesOutletDetailTable;
