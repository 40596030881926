import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { usePilihProduk } from "../contexts/PilihProdukContextProvider";

const DialogKonfirmasiHapusFaktur: React.FC = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(
    `(min-width: ${theme.breakpoints.tablet})`
  );

  const {
    state: { removingFakturAttempt },
    actions: { removeSingularFaktur, removeSingularFakturAttempt },
  } = usePilihProduk();

  return (
    <Modal
      isOpen={removingFakturAttempt !== null}
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount
      isCentered
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent marginX="40px">
        <ModalHeader
          fontSize={24}
          fontWeight="semibold"
          color={theme.colors.exodus.primaryBlue}
        >
          <Text>Hapus Faktur</Text>
        </ModalHeader>
        <ModalBody
          display="flex"
          gap={1}
          fontSize={isDesktopView ? 20 : 14}
          color={theme.colors.exodus.primaryBlue}
        >
          <Text>Hapus</Text>
          <Text fontWeight="semibold">Faktur</Text>
          <Text>dengan nomor</Text>
          <Text fontWeight="semibold">
            {removingFakturAttempt === null
              ? "..."
              : removingFakturAttempt.originalData.invoice}
          </Text>
          <Text>?</Text>
        </ModalBody>
        <ModalFooter
          display="flex"
          flexDirection={isDesktopView ? "row" : "column-reverse"}
          gap={isDesktopView ? 5 : 2}
        >
          <Button
            width="100%"
            variant="outline"
            colorScheme="buttonSecondary"
            onClick={() => removeSingularFakturAttempt(null)}
          >
            Cancel
          </Button>
          <Button
            width="100%"
            colorScheme="buttonPrimary"
            onClick={() => {
              if (removingFakturAttempt !== null) {
                removeSingularFaktur(removingFakturAttempt.id);
              }
            }}
          >
            Hapus
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DialogKonfirmasiHapusFaktur;
