import { Box, Flex, Text, useTheme } from "@chakra-ui/react";
import { MdListAlt } from "react-icons/md";

const DataNotFound = () => {
  const theme = useTheme();
  const darkGrey = theme.colors.exodus.darkGrey;

  return (
    <Box textAlign={"center"} m={3} width={"100%"}>
      <Flex direction={"column"} alignItems={"center"}>
        <MdListAlt style={{ fontSize: "64px", color: darkGrey }} />
        <Text
          fontSize={"24px"}
          fontWeight={"semibold"}
          color={darkGrey}
          marginTop={"12px"}
        >
          Data Tidak Ada/Ditemukan
        </Text>
      </Flex>
    </Box>
  );
};

export default DataNotFound;
