import React, { useContext, useState } from "react";
import HeaderRecordApprovalRealisasiContext from "../../context/HeaderRecordApprovalRealisasiContext";
import { RealisasiStatus } from "../../models/ApprovalRealisasiStatus";
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  useTheme,
} from "@chakra-ui/react";
import ErrorPage from "../../../../../../components/ErrorPage/ErrorPage";
import StatusChip from "../../../../../../components/StatusChip/StatusChip";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import MobileDetailApprovalRealisasiBasicInformation from "./MobileDetailApprovalRealisasiBasicInformation";
import MobileDetailApprovalRealisasiBottomButtons from "./MobileDetailApprovalRealisasiBottomButtons";
import MobileDetailApprovalRealisasiDetailList from "./MobileDetailApprovalRealisasiDetailList";
import FormInputLabel from "../../../../../../components/FormInputLabel/FormInputLabel";
import MobileDialogApprovalDetailApprovalRealisasi from "./MobileDialogApprovalDetailApprovalRealisasi";
import { MdInfoOutline } from "react-icons/md";

const MobileDetailApprovalRealisasiComponent: React.FC = () => {
  const theme = useTheme();
  const { headerData, headerError, headerLoading } = useContext(
    HeaderRecordApprovalRealisasiContext
  );

  const [activeTab, setActiveTab] = useState<"realization" | "extracall">(
    "realization"
  );
  const [openApprovalDetail, setOpenApprovalDetail] = useState<boolean>(false);

  const getStatusColor = (status: string) => {
    if (status === RealisasiStatus.Approved) {
      return "processed";
    } else if (status === RealisasiStatus.Pending) {
      return "pending";
    } else {
      return "pending";
    }
  };

  const closeApprovalDetailDialog = () => {
    setOpenApprovalDetail(false);
  };

  if (headerLoading) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <Spinner color={theme.colors.exodus.primaryBlue} size="lg" />
      </Box>
    );
  }

  if (headerError && headerData === null) {
    return (
      <Box marginTop="24px" marginBottom="15px">
        <ErrorPage message={headerError.toString()} />
      </Box>
    );
  }
  return headerData ? (
    <>
      <Stack
        justifyContent={"space-between"}
        alignItems={"stretch"}
        minHeight={"100vh"}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }} gap={3}>
          <Box>
            <PageTitle label="Approval Realisasi Kunjungan" />
            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <StatusChip
                status={getStatusColor(headerData?.approvalRealisasiStatus)}
                text={headerData.approvalRealisasiStatus.toUpperCase()}
              />
            </Box>
          </Box>
          <MobileDetailApprovalRealisasiBasicInformation />
          {headerData?.approvalRealisasiStatus === RealisasiStatus.Approved && (
            <Stack alignItems={"start"}>
              <Button
                colorScheme="buttonPrimary"
                variant="ghost"
                size={"md"}
                padding={0}
                onClick={() => setOpenApprovalDetail(true)}
                leftIcon={<MdInfoOutline />}
              >
                Lihat List Approval
              </Button>
            </Stack>
          )}

          {headerError ? null : (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                paddingBottom: 5,
              }}
              borderBottomWidth={1}
              borderColor={theme.colors.exodus.primaryBlue}
              gap={0}
            >
              <FormInputLabel title="Jenis Realisasi" />
              <RadioGroup
                onChange={(data: "realization" | "extracall") =>
                  setActiveTab(data)
                }
                value={activeTab}
              >
                <Stack sx={{ display: "flex", flexDirection: "column" }}>
                  <Radio value="realization">Rencana Kunjungan</Radio>
                  <Radio value="extracall">Extra Call</Radio>
                </Stack>
              </RadioGroup>
            </Stack>
          )}
          <MobileDetailApprovalRealisasiDetailList activeTab={activeTab} />
        </Box>

        <MobileDetailApprovalRealisasiBottomButtons />

        <MobileDialogApprovalDetailApprovalRealisasi
          openApprovalDetail={openApprovalDetail}
          onClose={closeApprovalDetailDialog}
        />
      </Stack>
    </>
  ) : (
    <Box marginTop="24px" marginBottom="15px">
      <ErrorPage message={"Error getting data"} />
    </Box>
  );
};

export default MobileDetailApprovalRealisasiComponent;
