import { createContext, useContext } from "react";
import useGetOneDplDpf from "../../../services/dataFetchings/useGetOneDplDpf";
import ErrorResponse from "../../../../../models/common/ErrorResponse";
import { useBiayaMurniFilterContext } from "../../../contexts/BiayaMurniFilterContext";
import { ApiGetOneDplDpfResponse } from "../../../models/apiResponses/ApiGetOneDplDpfResponse";

type ValueType = {
  states: {
    data: ApiGetOneDplDpfResponse | null;
    isLoading: boolean;
    error: ErrorResponse | null;
  };
};

const BiayaMurniDplDpfDetailContext = createContext<ValueType>({
  states: {
    data: null,
    isLoading: false,
    error: null,
  },
});

type _WithChildren<T = {}> = T & { children?: React.ReactNode };
type _InternalProps = _WithChildren<{
  outletId: string | undefined;
}>;

function useBiayaMurniDplDpfDetailContext(): ValueType {
  return useContext(BiayaMurniDplDpfDetailContext);
}

const BiayaMurniDplDpfDetailContextProvider = ({
  children,
  outletId,
}: _InternalProps): JSX.Element => {
  const {
    states: { filterValues },
  } = useBiayaMurniFilterContext();

  const { data, isLoading, error } = useGetOneDplDpf({
    outletId,
    startDate: filterValues?.periode.awal,
    endDate: filterValues?.periode.akhir,
    userId: filterValues?.pekerja?.id,
  });

  const value: ValueType = {
    states: { data, isLoading, error },
  };

  return (
    <BiayaMurniDplDpfDetailContext.Provider value={value}>
      {children}
    </BiayaMurniDplDpfDetailContext.Provider>
  );
};

export {
  BiayaMurniDplDpfDetailContextProvider,
  useBiayaMurniDplDpfDetailContext,
};
