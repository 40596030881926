import { useState, useEffect } from "react";
import BiayaMurniProvider from "../../dataProviders/BiayaMurniProvider";
import { BiayaMurniGetListParam } from "../../dataProviders/BiayaMurniProviderParams";
import { ApiGetListBiayaMurniResponse } from "../../models/apiResponses/ApiGetListBiayaMurniResponse";
import ErrorResponse from "../../../../models/common/ErrorResponse";

const useGetList = (params: BiayaMurniGetListParam) => {
  const [data, setData] = useState<ApiGetListBiayaMurniResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    async function getData() {
      try {
        setError(null);
        setIsLoading(true);

        const result = await BiayaMurniProvider.getList(params);

        setData(result);
      } catch (error) {
        if (error as ErrorResponse) {
          setError(error as ErrorResponse);
        }
      } finally {
        setIsLoading(false);
      }
    }
    if (
      params.endDate &&
      params.startDate &&
      params.featureType &&
      params.limit &&
      params.page &&
      params.featureType !== "general"
    ) {
      getData();
    }
  }, [
    params.endDate,
    params.startDate,
    params.userId,
    params.featureType,
    params.project,
    params.limit,
    params.page,
  ]);

  return { data, isLoading, error };
};

export default useGetList;
