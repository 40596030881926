import { createContext } from "react";
import AllPeriodeDataModel from "../../../models/AllPeriodeDataModel";
import { MonitorKunjunganModelData } from "../../models/MonitorKunjunganDataModel";
import FilterMonitorKunjunganModel from "../../models/FilterMonitorKunjunganModel";
import ProjectType from "../../../../../models/common/ProjectType";

type MonitorKunjunganMonitorListProps = {
  allPeriodeData: AllPeriodeDataModel[];
  loadingAllPeriode: boolean;
  errorMessageAllPeriode: string | null;
  monitorKunjunganData: MonitorKunjunganModelData[];
  loadingMonitorKunjungan: boolean;
  errorMessageMonitorKunjungan: string | null;
  selectedPeriode: AllPeriodeDataModel | null;
  handleChangeSelectedPeriode: (data: AllPeriodeDataModel) => void;
  selectedProject: ProjectType | null;
  handleChangeSelectedProject: (data: ProjectType | null) => void;
  savedFilter: FilterMonitorKunjunganModel | null;
  currentPsspType: string;
  setCurrentPsspType: (data: string) => void;
};

const defaultValue: MonitorKunjunganMonitorListProps = {
  allPeriodeData: [],
  loadingAllPeriode: true,
  errorMessageAllPeriode: null,
  monitorKunjunganData: [],
  loadingMonitorKunjungan: true,
  errorMessageMonitorKunjungan: null,
  selectedPeriode: null,
  handleChangeSelectedPeriode: () => {},
  selectedProject: null,
  handleChangeSelectedProject: () => {},
  savedFilter: null,
  currentPsspType: "",
  setCurrentPsspType: () => {},
};

const MonitorKunjunganMonitorListContext = createContext(defaultValue);

export default MonitorKunjunganMonitorListContext;
